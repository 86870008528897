<template>
    <p v-if="false">Testimonials</p>
</template>

<script>
    export default {
        props: {
            testimonials: { type: Array, required: true },
        },
    }
</script>
