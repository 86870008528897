<template>
    <div class="space-y-20 flex flex-col items-center text-center">
        <IconSuccess class="h-35 w-35 text-green animate-pop" />
        <BaseHeading :heading="$t('pro.clients.import.done.heading')" :level="3" />
        <div class="space-y-20 text-20 text-gray-2 text-center px-20">
            <b>
                {{ $tc('pro.clients.import.done.x-imported', importedCount, { n: importedCount }) }}
            </b>
            <p>{{ $t('pro.clients.import.done.invite') }}</p>
        </div>
    </div>

    <FormButtons>
        <BaseButton :label="$t('pro.clients.import.done.go-to-clients')" route="/pro/clients" />
    </FormButtons>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import IconSuccess from '@/assets/vectors/icon-success.svg'

    export default {
        components: {
            BaseHeading,
            FormButtons,
            BaseButton,
            IconSuccess,
        },

        props: {
            importedCount: { type: Number, default: 0 },
        },
    }
</script>
