import { normalizeFormData } from '@/axios/helpers'
import { transformWorkPlace } from '@/apis/transforms/transformProToApi'

export default (originalData) => {
    const data = normalizeFormData(originalData || {})
    const formData = new FormData()

    formData.append('name', data.name)
    formData.append('description', data.description)
    formData.append('file', originalData.file)
    formData.append('picureTitle', data.picureTitle)
    formData.append('duration', data.duration)
    formData.append('prosPlacePrice', data.prosPlacePrice)
    formData.append('guestsPlacePrice', data.guestsPlacePrice)
    formData.append('workPlace', transformWorkPlace(data.workPlace))
    formData.append('subCategoryIds[]', JSON.stringify(data.subCategoryIds))

    return formData
}
