<template>
    <div ref="inviteButton" class="w-200">
        <BaseButton :label="'Invite Pros'" :level="1" @click="showContextMenu" />
    </div>

    <InviteProModal :is-open="isInviteProOpen" @close="isInviteProOpen = false" />
    <ImportProsUploadingModal :is-open="isSubmitting" />
    <ImportProsDoneModal
        :number-of-contacts="numberOfContacts"
        :is-open="isSubmitted"
        @close="isSubmitted = false"
    />

    <input ref="input" type="file" class="hidden" @change="selectFile" />

    <BaseContextMenu
        ref="popupMenu"
        :top="contextMenuTop"
        :left="contextMenuLeft"
        :width="'200px'"
        :padding="'0'"
        @close="closeContextMenu"
    >
        <div class="flex flex-col w-200">
            <div :class="contextMenuItemClass('top')" @click="openInviteProModal">
                {{ $t('admin.dashboard.newPros.invitePro.addManually') }}
            </div>
            <div :class="contextMenuItemClass('bottom')" @click="triggerSelectFile">
                {{ $t('admin.dashboard.newPros.invitePro.importCsv') }}
            </div>
        </div>
    </BaseContextMenu>
</template>

<script>
    import ApiAdmins from '@/apis/ApiAdmins'
    import MixinForm from '@/mixins/MixinForm'
    import BaseButton from '@/components/base/BaseButton'
    import BaseContextMenu from '@/components/base/BaseContextMenu'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import InviteProModal from '@/components/admin/dashboard/invite-pro/InviteProModal'
    import ImportProsDoneModal from '@/components/admin/dashboard/invite-pro/ImportProsDoneModal'
    import ImportProsUploadingModal from '@/components/admin/dashboard/invite-pro/ImportProsUploadingModal'

    export default {
        components: {
            BaseButton,
            InviteProModal,
            BaseContextMenu,
            ImportProsDoneModal,
            ImportProsUploadingModal,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        data() {
            return {
                contextMenuTop: '',
                contextMenuLeft: '',
                numberOfContacts: 0,
                isInviteProOpen: false,
            }
        },

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },
        },

        methods: {
            showContextMenu(event) {
                event.stopPropagation()

                this.contextMenuLeft = this.getContextMenuLeft()
                this.contextMenuTop = this.getContextMenuTop()

                if (this.$refs.popupMenu.show) {
                    this.$refs.popupMenu.close()
                } else {
                    this.$refs.popupMenu.open()
                }
            },

            getContextMenuTop() {
                const el = this.$refs.inviteButton
                const top = window.pageYOffset + el.getBoundingClientRect().top + 70
                return top + 'px'
            },

            getContextMenuLeft() {
                const el = this.$refs.inviteButton
                let left = el.getBoundingClientRect().left
                if (left + 200 + 30 > window.innerWidth) {
                    left = window.innerWidth - 200 - 30
                }

                return left + 'px'
            },

            closeContextMenu() {
                this.$refs.popupMenu.close()
            },

            contextMenuItemClass(borderRadius) {
                return {
                    'border-1': true,
                    'border-gray-4': true,
                    'p-10': true,
                    'hover:bg-purple': true,
                    'hover:text-white': true,
                    'rounded-b-md': !!(borderRadius === 'bottom'),
                    'rounded-t-md': !!(borderRadius === 'top'),
                }
            },

            openInviteProModal() {
                this.closeContextMenu()
                this.isInviteProOpen = true
            },

            triggerSelectFile() {
                this.closeContextMenu()
                this.$refs.input.click()
            },

            selectFile(event) {
                const formData = new FormData()
                formData.append('file', event.target.files[0])

                this.$refs.input.value = null

                this.handleSubmit(ApiAdmins.importPros(formData))
                    .then(({ numberOfContacts }) => (this.numberOfContacts = numberOfContacts))
                    .catch(() => this.$ui.flash.error(this.$t('pro.clients.import.error')))
            },
        },
    }
</script>
