<template>
    <section v-if="hasCancellationPolicy" class="space-y-30">
        <BaseHeading
            :heading="$t('pro.page.cancellation.heading', { name: pro.firstName })"
            :level="5"
        />

        <section v-for="(place, index) in cancellationPlaces" :key="index" class="space-y-15">
            <header class="flex items-center space-x-15">
                <component :is="place.icon" class="h-28" />
                <span class="font-bold">{{ place.name }}</span>
            </header>

            <BaseText>
                <i18n-t tag="p" keypath="pro.page.cancellation.free-until">
                    <template #hours>
                        <b>{{ $tc('common.n-hours', place.hours, { n: place.hours }) }}</b>
                    </template>
                </i18n-t>
            </BaseText>

            <div class="relative pl-35">
                <IconTime class="h-22 w-22 absolute top-0 left-0" />

                <div class="space-y-01">
                    <i18n-t tag="p" keypath="pro.page.cancellation.before-appointment">
                        <template #hours>
                            <b>{{ $tc('common.n-hours', place.hours, { n: place.hours }) }}</b>
                        </template>
                    </i18n-t>

                    <i18n-t
                        v-if="place.type === 'flat'"
                        tag="p"
                        keypath="pro.page.cancellation.flat"
                    >
                        <template #amount>
                            <b>${{ place.amount }}</b>
                        </template>
                    </i18n-t>
                    <i18n-t
                        v-if="place.type === 'percent'"
                        tag="p"
                        keypath="pro.page.cancellation.percent"
                    >
                        <template #amount>
                            <b>{{ place.amount }}%</b>
                        </template>
                    </i18n-t>
                </div>
            </div>
        </section>
    </section>

    <section v-if="hasNoShowPolicy" class="space-y-30">
        <BaseHeading
            :heading="$t('pro.page.no-show.heading', { name: pro.firstName })"
            :level="5"
        />

        <section v-for="(place, index) in noShowPlaces" :key="index" class="flex">
            <header class="flex items-center space-x-15">
                <component :is="place.icon" class="h-35" />
                <span class="font-bold">{{ place.name }}</span>
            </header>

            <p class="ml-auto font-bold">
                <template v-if="place.type === 'flat'"> ${{ place.amount }}</template>
                <template v-if="place.type === 'percent'"> {{ place.amount }}%</template>
            </p>
        </section>
    </section>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import IconGuest from '@/assets/vectors/icon-guest.svg'
    import IconProNoShow from '@/assets/vectors/icon-pro-no-show.svg'
    import IconGuestNoShow from '@/assets/vectors/icon-guest-no-show.svg'
    import IconTime from '@/assets/vectors/icon-time.svg'

    export default {
        components: {
            BaseHeading,
            BaseText,
            IconTime,
        },

        props: {
            pro: { type: Object, required: true },
        },

        computed: {
            hasCancellationPolicy() {
                return !!this.pro.cancellationPolicy
            },

            hasNoShowPolicy() {
                return !!this.pro.noShowPolicy
            },

            cancellationPlaces() {
                return [
                    {
                        icon: IconPro,
                        name: this.$t('pro.page.cancellation.places.pro'),
                        hours: this.pro.cancellationPolicy?.prosPlaceCancellationTime,
                        amount: this.pro.cancellationPolicy?.prosPlaceFee,
                        type: this.pro.cancellationPolicy?.prosPlaceFeeType,
                    },
                    {
                        icon: IconGuest,
                        name: this.$t('pro.page.cancellation.places.guest'),
                        hours: this.pro.cancellationPolicy?.guestsPlaceCancellationTime,
                        amount: this.pro.cancellationPolicy?.guestsPlaceFee,
                        type: this.pro.cancellationPolicy?.guestsPlaceFeeType,
                    },
                ]
            },

            noShowPlaces() {
                return [
                    {
                        icon: IconProNoShow,
                        name: this.$t('pro.page.no-show.places.pro'),
                        amount: this.pro.noShowPolicy.prosPlaceFee,
                        type: this.pro.noShowPolicy.prosPlaceFeeType,
                    },
                    {
                        icon: IconGuestNoShow,
                        name: this.$t('pro.page.no-show.places.guest'),
                        amount: this.pro.noShowPolicy.guestsPlaceFee,
                        type: this.pro.noShowPolicy.guestsPlaceFeeType,
                    },
                ]
            },
        },
    }
</script>
