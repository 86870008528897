<template>
    <div class="space-y-30">
        <div v-for="(detail, index) in details" :key="index" class="space-y-5">
            <div class="flex">
                <ProProfileSetting
                    :icon="detail.icon"
                    :heading="detail.heading"
                    :subheading="detail.subheading"
                    :value="detail.amount"
                    class="w-full"
                />

                <button
                    v-if="isEditable"
                    type="button"
                    class="ml-10"
                    @click="$emit('edit', detail.slug)"
                >
                    <IconModify class="h-18 w-18" />
                </button>
            </div>

            <div v-if="detail.time" class="flex">
                <div class="w-45 pr-15 flex justify-end">
                    <IconTime class="h-22 w-22" />
                </div>
                <p class="font-bold">
                    {{
                        $tc('pro.profile.cancellation.index.x-hours-before', detail.time, {
                            n: detail.time,
                        })
                    }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import ProProfileSetting from '@/components/pro/profile/ProProfileSetting'
    import IconModify from '@/assets/vectors/icon-modify.svg'
    import IconTime from '@/assets/vectors/icon-time.svg'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import IconProNoShow from '@/assets/vectors/icon-guest-no-show.svg'
    import IconGuest from '@/assets/vectors/icon-guest.svg'
    import IconGuestNoShow from '@/assets/vectors/icon-guest-no-show.svg'

    export default {
        components: {
            ProProfileSetting,
            IconModify,
            IconTime,
        },

        props: {
            cancellationPolicy: { type: Object, required: true },
            noShowPolicy: { type: Object, default: null },
            isEditable: { type: Boolean, default: false },
        },

        emits: ['edit'],

        computed: {
            details() {
                const cancellationDetails = [
                    {
                        icon: IconPro,
                        heading: this.$t('common.fees.cancellation'),
                        subheading: this.$t('pro.profile.cancellation.index.pro'),
                        amount: this.formatAmount(
                            this.cancellationPolicy?.prosPlaceFeeType,
                            this.cancellationPolicy?.prosPlaceFee,
                        ),
                        time: this.cancellationPolicy?.prosPlaceCancellationTime,
                        slug: 'cancellation-pro',
                    },
                    {
                        icon: IconGuest,
                        heading: this.$t('common.fees.cancellation'),
                        subheading: this.$t('pro.profile.cancellation.index.guest'),
                        amount: this.formatAmount(
                            this.cancellationPolicy?.guestsPlaceFeeType,
                            this.cancellationPolicy?.guestsPlaceFee,
                        ),
                        time: this.cancellationPolicy?.guestsPlaceCancellationTime,
                        slug: 'cancellation-guest',
                    },
                ]
                const noShowDetails = [
                    {
                        icon: IconProNoShow,
                        heading: this.$t('common.fees.no-show'),
                        subheading: this.$t('pro.profile.cancellation.index.pro'),
                        amount: this.formatAmount(
                            this.noShowPolicy?.prosPlaceFeeType,
                            this.noShowPolicy?.prosPlaceFee,
                        ),
                        slug: 'no-show-pro',
                    },
                    {
                        icon: IconGuestNoShow,
                        heading: this.$t('common.fees.no-show'),
                        subheading: this.$t('pro.profile.cancellation.index.guest'),
                        amount: this.formatAmount(
                            this.noShowPolicy?.guestsPlaceFeeType,
                            this.noShowPolicy?.guestsPlaceFee,
                        ),
                        slug: 'no-show-guest',
                    },
                ]

                return this.noShowPolicy
                    ? [].concat(cancellationDetails, noShowDetails)
                    : cancellationDetails
            },
        },

        methods: {
            formatAmount(type, amount) {
                switch (type) {
                    case 'percent':
                        return `${amount}%`
                    case 'flat':
                        return `$${amount}`
                    default:
                        return amount
                }
            },
        },
    }
</script>
