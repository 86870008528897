<template>
    <LayoutOverlay :back-button="true" @back="back">
        <ProSection
            :heading="$t('pro.profile.places.guest.padding.heading')"
            :description="
                $t('pro.profile.places.guest.padding.description', {
                    zipCode: $auth.getUser().areaZipCode,
                })
            "
        >
            <FormWrapper>
                <BaseRow
                    :label="$t('pro.profile.places.guest.padding.label')"
                    :is-value-auto="true"
                >
                    <FieldSelect
                        :placeholder="$t('common.specify')"
                        :options="paddingOptions"
                        :form-data="formData"
                        value-path="preparationTime"
                        @change="change"
                    />
                </BaseRow>

                <slot name="buttons" :is-submittable="isSubmittable">
                    <FormButtons>
                        <BaseButton
                            :label="submitLabel"
                            :is-disabled="!isSubmittable"
                            :is-processing="isSubmitting"
                            @click="save"
                        />
                    </FormButtons>
                </slot>
            </FormWrapper>
        </ProSection>
    </LayoutOverlay>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import BaseRow from '@/components/base/BaseRow'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldSelect from '@/components/form-fields/FieldSelect'

    export default {
        components: {
            BaseRow,
            BaseButton,
            FormWrapper,
            FormButtons,
            FieldSelect,
        },

        mixins: [MixinProProfileSection],

        computed: {
            isSubmittable() {
                return true
            },

            isRedirectingHome() {
                return false
            },

            submitLabel() {
                return this.$t('common.save')
            },

            paddingOptions() {
                return Array(25)
                    .fill(1)
                    .map((value, index) => {
                        value = index * 5

                        return { label: this.$tc('common.n-minutes', value, value), value }
                    })
            },
        },

        methods: {
            apiCall() {
                return ApiPros.update(this.$auth.getUser().id, 'guestsPlaceData', this.formData)
            },
        },
    }
</script>
