<template>
    <LayoutApp back-button="/auth/detect">
        <BaseHeaderSection
            :heading="$t('guest.profile.heading')"
            :is-center="false"
            class="mb-30"
        />

        <GuestProfile :sections="sections" class="mb-100" />
    </LayoutApp>
</template>

<script>
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import GuestProfile from '@/components/guest/GuestProfile'
    import GuestProfilePicture from '@/components/guest/profile/GuestProfilePicture'
    import GuestProfileVerification from '@/components/guest/profile/GuestProfileVerification'
    import GuestProfileInfo from '@/components/guest/profile/GuestProfileInfo'
    import GuestProfilePayment from '@/components/guest/profile/GuestProfilePayment'
    import GuestProfilePreferences from '@/components/guest/profile/GuestProfilePreferences'

    export default {
        components: {
            LayoutApp,
            BaseHeaderSection,
            GuestProfile,
        },

        computed: {
            sections() {
                return [
                    GuestProfilePicture,
                    GuestProfileVerification,
                    GuestProfileInfo,
                    GuestProfilePayment,
                    GuestProfilePreferences,
                ]
            },
        },
    }
</script>
