import get from 'lodash/get'
import MixinFormFocusable from '@/mixins/MixinFormFocusable'
import MixinFormChangable from '@/mixins/MixinFormChangable'

export default {
    mixins: [MixinFormFocusable, MixinFormChangable],

    props: {
        label: { type: String, default: null },
        error: { type: String, default: null },
        formErrors: { type: Object, default: null },
        hasAutofocus: { type: Boolean, default: false },
        isLabelFixed: { type: Boolean, default: false },
        isRequired: { type: Boolean, default: false },
        isDisabled: { type: Boolean, default: false },
        isReadonly: { type: Boolean, default: false },
        isInverted: { type: Boolean, default: false },
    },

    computed: {
        value() {
            return get(this.formData, this.valuePath)
        },

        errorMessage() {
            return this.error || get(this.formErrors?.errors, this.valuePath)
        },

        fieldProps() {
            return {
                label: this.label,
                value: this.value,
                error: this.errorMessage,
                isLabelFixed: this.isLabelFixed,
                isDisabled: this.isDisabled,
                isFocused: this.isFocused,
                isInvalid: !!this.errorMessage,
                isInverted: this.isInverted,
            }
        },

        inputProps() {
            return {
                value: this.value,
                hasAutofocus: this.hasAutofocus,
                isRequired: this.isRequired,
                isDisabled: this.isDisabled,
                isReadonly: this.isReadonly,
                isInvalid: !!this.errorMessage,
                isInverted: this.isInverted,
            }
        },
    },
}
