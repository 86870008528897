<template>
    <FormField v-bind="fieldProps">
        <InputChoices v-bind="choicesInputProps" @focus="focus" @blur="blur" @change="change" />
    </FormField>
</template>

<script>
    import MixinFormField from '@/mixins/MixinFormField'
    import MixinFormOptionable from '@/mixins/MixinFormOptionable'
    import FormField from '@/components/form-elements/FormField'
    import InputChoices from '@/components/form-inputs/InputChoices'

    export default {
        components: {
            FormField,
            InputChoices,
        },

        mixins: [MixinFormField, MixinFormOptionable],

        props: {
            level: { type: Number, default: 1 },
        },

        computed: {
            choicesInputProps() {
                return {
                    ...this.inputProps,
                    options: this.options,
                    level: this.level,
                }
            },
        },
    }
</script>
