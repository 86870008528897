<template>
    <article class="flex items-center space-x-15">
        <InputCheckbox v-if="isSelectable" :value="isSelected" @change="$emit('toggle-select')" />

        <RouterLink :to="clientRoute" class="w-full flex items-center space-x-15">
            <BaseAvatar
                :photo="client.profilePicture"
                :name="client"
                class="h-50 w-50 rounded-full"
            />

            <div>
                <h5 class="font-bold leading-tight">{{ fullName }}</h5>
                <p v-if="client.address" class="text-13 text-gray-2 leading-tight">
                    {{ client.address?.address }}
                    <br />
                    {{ client.address?.zipCode }}
                    {{ client.address?.city }},
                    {{ client.address?.state }}
                </p>
            </div>
        </RouterLink>
    </article>
</template>

<script>
    import { composeFullName } from '@/helpers'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import InputCheckbox from '@/components/form-inputs/InputCheckbox'

    export default {
        components: {
            BaseAvatar,
            InputCheckbox,
        },

        props: {
            client: { type: Object, required: true },
            isSelectable: { type: Boolean, default: false },
            isSelected: { type: Boolean, default: false },
        },

        emits: ['toggle-select'],

        computed: {
            clientRoute() {
                return `/pro/clients/${this.client.id}`
            },

            fullName() {
                return composeFullName(this.client)
            },
        },
    }
</script>
