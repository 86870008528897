<template>
    <div class="space-y-40">
        <template v-if="!isRated">
            <BaseHeading :heading="$t('guest.appointments.rate.already-debited')" :level="3" />

            <div class="flex w-full items-center">
                <label class="text-16 font-black uppercase">
                    {{ $t('guest.appointments.rate.total-paid') }}
                </label>

                <div class="ml-auto text-24 font-bold">$ {{ appointmentPrice }}</div>
            </div>

            <GuestStripe :is-preview="true" />
        </template>

        <BaseHeading
            :heading="
                $t('guest.appointments.rate.question.how-went', {
                    name: appointment.pro.firstName,
                })
            "
            :level="2"
            class="text-center"
        />

        <section class="space-y-20 flex flex-col items-center text-center">
            <BaseAvatar
                :photo="appointment.pro.profilePicture"
                class="rounded-full h-70 w-70 mx-auto"
            />

            <BaseHeading
                :heading="$t('guest.appointments.rate.rate-x', { name: appointment.pro.firstName })"
                :level="5"
            />

            <FieldRating :form-data="formData" value-path="rating" @change="change" />
        </section>

        <section v-if="isRated" class="space-y-20">
            <BaseHeaderDivider :heading="$t('guest.appointments.rate.write-review.heading')" />

            <FieldTextarea
                :placeholder="$t('guest.appointments.rate.write-review.hint')"
                :has-autofocus="true"
                :rows="5"
                :has-bottom-border="false"
                :form-data="formData"
                value-path="note"
                @change="change"
            />
        </section>
    </div>

    <FormButtons>
        <BaseButton
            v-if="isRated"
            :label="$t('common.confirm')"
            :is-processing="isSubmitting"
            @click="goToNextStep"
        />
    </FormButtons>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import GuestStripe from '@/components/guest/GuestStripe'
    import FieldRating from '@/components/form-fields/FieldRating'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'
    import FormButtons from '@/components/form-elements/FormButtons'

    export default {
        components: {
            BaseAvatar,
            BaseHeading,
            BaseButton,
            BaseHeaderDivider,
            GuestStripe,
            FieldRating,
            FieldTextarea,
            FormButtons,
        },

        mixins: [MixinFormStep, MixinFormSubmittable, MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
        },

        computed: {
            isRated() {
                return !!this.formData.rating
            },
        },
    }
</script>
