<template>
    <ProOnboardingStep
        :heading="$t('pro.onboarding.subcategories.heading')"
        @submit="saveAndGoToNextStep"
    >
        <FormFields class="space-y-60">
            <GuestCategoryPills
                v-for="category in selectedCategories"
                :key="category.id"
                :category="category"
                :form-data="formData"
                value-path="subCategoryIds"
                @change="change"
            />
        </FormFields>

        <FormButtons>
            <BaseButton
                :label="$t('common.next')"
                :is-disabled="!isSubmittable"
                :is-processing="isSubmitting"
            />
        </FormButtons>
    </ProOnboardingStep>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProOnboardingStep from '@/components/pro/onboarding/ProOnboardingStep'
    import GuestCategoryPills from '@/components/guest/GuestCategoryPills'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            ProOnboardingStep,
            GuestCategoryPills,
            FormFields,
            FormButtons,
            BaseButton,
        },

        mixins: [MixinFormStep],

        computed: {
            isSubmittable() {
                return (this.formData.subCategoryIds || []).length
            },

            selectedCategories() {
                return this.$store.getters['dictionaries/getCategories'].filter(({ id }) =>
                    this.formData.categories.includes(id),
                )
            },
        },

        methods: {
            saveAndGoToNextStep() {
                this.handleSubmit(
                    ApiPros.onboard(this.$auth.getUser().id, this.formData).then(() =>
                        this.$auth.loadUser(true),
                    ),
                ).then(this.goToNextStep)
            },
        },
    }
</script>
