<template>
    <div>
        <LandingAccordionItem
            v-for="(item, index) in items"
            :key="index"
            :heading="item.heading"
            :content="item.content"
            :class="itemClass(index)"
        />
    </div>
</template>

<script>
    import LandingAccordionItem from '@/components/landing/LandingAccordionItem'

    export default {
        components: {
            LandingAccordionItem,
        },

        props: {
            items: { type: Array, required: true },
        },

        methods: {
            itemClass(index) {
                return {
                    'border-b-1': true,
                    'border-gray-5': true,
                    'pb-15': true,
                    'sm:pb-20': true,
                    'md:pb-30': true,
                    'mt-15': index > 0,
                    'sm:mt-20': index > 0,
                    'md:mt-30': index > 0,
                }
            },
        },
    }
</script>
