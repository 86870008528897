import transformProExperienceFromApi from '@/apis/transforms/transformProExperienceFromApi'
import transformProAddOnFromApi from '@/apis/transforms/transformProAddOnFromApi'
import transformProPortfolioFromApi from '@/apis/transforms/transformProPortfolioFromApi'

export const transformWorkPlace = (value) => {
    return {
        ProsPlace: ['pro'],
        GuestsPlace: ['guest'],
        Both: ['pro', 'guest'],
    }[value]
}

const transformCancelationNoShowPolicy = (data) => {
    const transformType = (value) => {
        return {
            Percentage: 'percent',
            FlatRate: 'flat',
        }[value]
    }

    return {
        ...data,
        guestsPlaceFeeType: transformType(data.guestsPlaceFeeType),
        prosPlaceFeeType: transformType(data.prosPlaceFeeType),
    }
}

export const transformPro = (data) => {
    return {
        ...data,
        workPlace: data.workPlace && transformWorkPlace(data.workPlace),
        experiences: data.experiences && transformProExperienceFromApi(data.experiences),
        addOns: data.addOns && transformProAddOnFromApi(data.addOns),
        portfolio: data.portfolio && transformProPortfolioFromApi(data.portfolio),
        cancellationPolicy:
            data.cancellationPolicy && transformCancelationNoShowPolicy(data.cancellationPolicy),
        noShowPolicy: data.noShowPolicy && transformCancelationNoShowPolicy(data.noShowPolicy),
        profilePicture: data.profilePicture?.pictureLocation,
    }
}

export default (data) => {
    return Array.isArray(data) ? data.map(transformPro) : transformPro(data)
}
