import MixinForm from '@/mixins/MixinForm'
import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
import ProSection from '@/components/pro/ProSection'
import FormWrapper from '@/components/form-elements/FormWrapper'
import FormFields from '@/components/form-elements/FormFields'
import FormButtons from '@/components/form-elements/FormButtons'
import BaseButton from '@/components/base/BaseButton'
import TransitionSlide from '@/components/transitions/TransitionSlide'

export default {
    components: {
        ProSection,
        FormWrapper,
        FormFields,
        FormButtons,
        BaseButton,
        TransitionSlide,
    },

    mixins: [MixinForm, MixinFormSubmittable],

    props: {
        client: { type: Object, required: true },
        screenData: { type: Object, required: true },
    },

    emits: ['save'],

    computed: {
        isSubmittable() {
            return false
        },

        changes() {
            return []
        },
    },

    created() {
        this.initForm(this.screenData)
    },

    methods: {
        save() {
            this.apiCall
                ? this.handleSubmit(this.apiCall).then(() => this.$emit('save', this.changes))
                : this.$emit('save', this.changes)
        },
    },
}
