<template>
    <LayoutAdmin>
        <div class="flex justify-center mt-80 mb-30">
            <BaseHeaderDivider :heading="$t('admin.issues.heading')" class="w-11/12 text-16" />
        </div>

        <div
            v-for="user in users"
            :key="user.id"
            class="flex flex-col w-11/12 mx-auto border-b-1 border-gray-5 pt-10 pb-10"
        >
            <div class="flex flex-row cursor-pointer" @click="expandItem(user.id)">
                <div class="flex items-center mr-10 ml-10">
                    <BaseAvatar :photo="user.profilePicture" class="h-50 w-50 rounded-full" />
                </div>

                <div class="flex items-center text-14 font-bold ml-50">
                    {{ user.firstName + ' ' + user.lastName }}
                </div>

                <div class="flex items-center text-14 ml-100">
                    {{ user.email }}
                </div>
            </div>

            <div v-if="expand == user.id && !user.status" class="flex flex-row mt-20 mb-20">
                <div class="w-6/12 flex items-center justify-center">
                    {{ $t('admin.issues.uploadedPhoto') }}
                </div>
                <div class="w-6/12 flex items-center justify-center">
                    {{ $t('admin.issues.uploadedPhotoId') }}
                </div>
            </div>

            <div v-if="expand == user.id && !user.status" class="flex flex-row">
                <div class="w-6/12 flex items-center justify-center">
                    <img :src="user.idPicture" class="w-500" />
                </div>
                <div class="w-6/12 flex items-center justify-center">
                    <img :src="user.portraitPicture" class="w-500" />
                </div>
            </div>

            <div
                v-if="expand == user.id && !user.status"
                class="flex flex-row mt-20 mb-20 justify-center"
            >
                <div class="w-300 mr-20">
                    <BaseButton
                        :label="$t('admin.issues.reject')"
                        :level="2"
                        @click="saveManualVerifications(user, false)"
                    />
                </div>
                <div class="w-300 ml-20">
                    <BaseButton
                        :label="$t('admin.issues.verify')"
                        :level="1"
                        @click="saveManualVerifications(user, true)"
                    />
                </div>
            </div>

            <div v-if="expand == user.id && user.status" class="flex flex-col items-center mx-auto">
                <BaseAvatar :photo="user.profilePicture" class="h-50 w-50 rounded-full" />
                <div class="flex items-center text-14 font-bold mt-10">
                    {{ user.firstName + ' ' + user.lastName }}
                </div>
                <div class="flex items-center text-14 mt-10">
                    {{ user.email }}
                </div>

                <div v-if="user.status == 'accepted'" class="flex flex-col items-center mt-50">
                    <IconSuccess class="h-35 w-35 text-green" />

                    <div class="text-40 font-bold">
                        {{ $t('admin.issues.userVerified') }}
                    </div>
                </div>

                <div v-else class="flex flex-col items-center mt-50">
                    <IconInvalid class="h-35 w-35 text-red" />

                    <div class="flex items-center text-40 font-bold">
                        {{ $t('admin.issues.userRejected') }}
                    </div>
                </div>
            </div>
        </div>
    </LayoutAdmin>
</template>

<script>
    import ApiAdmins from '@/apis/ApiAdmins'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import LayoutAdmin from '@/components/layouts/LayoutAdmin'
    import BaseButton from '../../components/base/BaseButton.vue'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import IconInvalid from '@/assets/vectors/icon-invalid.svg'
    import IconSuccess from '@/assets/vectors/icon-success.svg'

    export default {
        components: {
            BaseAvatar,
            BaseButton,
            LayoutAdmin,
            BaseHeaderDivider,
            IconInvalid,
            IconSuccess,
        },

        data() {
            return {
                expand: null,
                status: null,
            }
        },

        async loadData() {
            return {
                users: await ApiAdmins.getManualVerifications(),
            }
        },

        methods: {
            async saveManualVerifications(user, accept) {
                user.status = accept ? 'accepted' : 'rejected'
                await ApiAdmins.saveManualVerifications(user.id, accept)
            },

            expandItem(id) {
                this.expand = this.expand != id ? id : null
            },
        },
    }
</script>
