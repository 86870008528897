<template>
    <nav>
        <ul class="flex">
            <li v-for="item in items" :key="item.label">
                <RouterLink v-if="item.route" :to="item.route" :class="itemClass(item)">
                    <component :is="item.icon" class="w-20 h-20 mr-10" />
                    <BaseLabel :content="item.label" />
                </RouterLink>
                <button v-else :class="itemClass(item)" @click="$emit('change', item)">
                    <component :is="item.icon" class="w-20 h-20 mr-10" />
                    <BaseLabel :content="item.label" />
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
    import BaseLabel from '@/components/base/BaseLabel'

    export default {
        components: {
            BaseLabel,
        },

        props: {
            items: { type: Array, required: true },
        },

        emits: ['change'],

        methods: {
            itemClass({ isSelected }) {
                return {
                    'flex': true,
                    'items-center': true,
                    'px-20': true,
                    'h-40': true,
                    'border-b-4': true,
                    'text-gray-2': !isSelected,
                    'border-transparent': !isSelected,
                    'hoverable:hover:text-purple': !isSelected,
                    'text-purple': isSelected,
                    'border-current': isSelected,
                }
            },
        },
    }
</script>
