<template>
    <BaseText :content="$t('guest.confirm.message')" :level="2" />

    <div class="flex text-gray-2">
        <IconEmail class="h-20 flex-shrink-0 mr-10" />

        <BaseText>
            <p class="font-bold text-16">
                {{ $t('guest.confirm.cant-find') }}
            </p>

            <i18n-t tag="p" keypath="guest.confirm.resend.text">
                <template #resend>
                    <template v-if="isSendingVerificationEmail || isVerificationEmailSent">
                        <b>{{ sendButtonLabel }}</b>
                    </template>
                    <template v-else>
                        <BaseLink :label="sendButtonLabel" @click="sendVerificationEmail" />
                    </template>
                </template>
            </i18n-t>
        </BaseText>
    </div>
</template>

<script>
    import ApiAuth from '@/apis/ApiAuth'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'
    import IconEmail from '@/assets/vectors/icon-email.svg'

    export default {
        components: {
            BaseText,
            BaseLink,
            IconEmail,
        },

        data() {
            return {
                isSendingVerificationEmail: false,
                isVerificationEmailSent: false,
            }
        },

        computed: {
            sendButtonLabel() {
                if (this.isSendingVerificationEmail) {
                    return this.$t('guest.confirm.resend.sending')
                }

                if (this.isVerificationEmailSent) {
                    return this.$t('guest.confirm.resend.sent')
                }

                return this.$t('guest.confirm.resend.send')
            },
        },

        methods: {
            sendVerificationEmail() {
                if (this.isSendingVerificationEmail || this.isVerificationEmailSent) {
                    return
                }

                this.isSendingVerificationEmail = true

                ApiAuth.sendVerificationEmailToGuest(this.$auth.getUser().id).then(() => {
                    this.isSendingVerificationEmail = false
                    this.isVerificationEmailSent = true
                })
            },
        },
    }
</script>
