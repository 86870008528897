<template>
    <p class="text-40 whitespace-nowrap text-center">
        $<b class="text-bold">{{ stats.totalEarned }}</b>
    </p>

    <div class="flex items-center justify-between w-full">
        <div class="flex flex-col items-center text-center w-3/12 text-11">
            <IconPro class="w-35 h-35 mb-5" />
            <p class="text-24 font-bold text-purple">${{ stats.earnedAtProsPlace }}</p>
            <p>({{ earnedProPercentage.toFixed(0) }}%)</p>
            <p class="font-medium">{{ $t('pro.dashboard.places.pro') }}</p>
        </div>

        <BaseChartPlaceSplit
            :pro="earnedProPercentage"
            :guest="earnedGuestPercentage"
            class="h-100 w-100"
        />

        <div class="flex flex-col items-center text-center w-3/12 text-11">
            <IconGuest class="w-35 h-35 mb-5" />
            <p class="text-24 font-bold text-magenta">${{ stats.earnedAtGuestsPlace }}</p>
            <p>({{ earnedGuestPercentage.toFixed(0) }}%)</p>
            <p class="font-medium">{{ $t('pro.dashboard.places.guest') }}</p>
        </div>
    </div>
</template>

<script>
    import BaseChartPlaceSplit from '@/components/base/BaseChartPlaceSplit'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import IconGuest from '@/assets/vectors/icon-guest.svg'

    export default {
        components: {
            BaseChartPlaceSplit,
            IconPro,
            IconGuest,
        },

        props: {
            stats: { type: Object, required: true },
        },

        computed: {
            earnedProPercentage() {
                if (this.stats.totalEarned === 0) {
                    return 50
                }

                return (this.stats.earnedAtProsPlace / this.stats.totalEarned) * 100
            },

            earnedGuestPercentage() {
                if (this.stats.totalEarned === 0) {
                    return 50
                }

                return (this.stats.earnedAtGuestsPlace / this.stats.totalEarned) * 100
            },
        },
    }
</script>
