<template>
    <div class="flex flex-col items-center">
        <BaseHeaderDivider :heading="$t('admin.dashboard.topSellers')" class="w-11/12 text-16" />
        <div class="flex flex-row">
            <div
                v-for="(pro, index) in topSellers"
                :key="index"
                class="flex-grow w-200 flex flex-col justify-center items-center mt-40"
            >
                <div class="flex items-center mr-10 ml-10">
                    <BaseAvatar :photo="pro.profilePicture" class="h-50 w-50 rounded-full" />
                </div>

                <div class="h-22 font-bold mt-15">{{ pro.name }}</div>

                <BaseRating v-if="pro.rating" :rating="pro.rating" :level="1" />

                <div class="flex flex-row font-bold mt-15">
                    <span class="text-20 mb-2 pr-5">$</span>
                    <span class="text-20">{{ pro.totalIncome }}</span>
                </div>

                <component :is="categoryIcon(pro.topCategory)" class="h-30 text-black mt-15" />

                <div class="text-16 mt-15">
                    {{ pro.workPlacePercentage + '% ' + pro.workPlace }}
                </div>

                <div class="flex flex-row mt-15">
                    <IconPin class="h-22 text-gray-2" />
                    <span class="text-16 text-gray-2 ml-10">{{
                        pro.address.city + ' ' + pro.address.zipCode
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCategoryIcon } from '@/helpers'
    import IconPin from '@/assets/vectors/icon-pin.svg'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseRating from '@/components/base/BaseRating'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'

    export default {
        components: {
            IconPin,
            BaseAvatar,
            BaseRating,
            getCategoryIcon,
            BaseHeaderDivider,
        },

        props: {
            topSellers: { type: Array, required: true },
        },

        methods: {
            categoryIcon(category) {
                return getCategoryIcon({ name: category })
            },
        },
    }
</script>
