<template>
    <div class="min-h-full flex flex-col">
        <slot />

        <div class="mt-auto">
            <slot name="footer" />
            <LandingFooter />
        </div>
    </div>
</template>

<script>
    import LandingFooter from '@/components/landing/LandingFooter'

    export default {
        components: {
            LandingFooter,
        },
    }
</script>
