<template>
    <FormField v-bind="selectFieldProps">
        <InputSelect v-bind="selectInputProps" @focus="focus" @blur="blur" @change="change" />
    </FormField>
</template>

<script>
    import MixinFormField from '@/mixins/MixinFormField'
    import MixinFormOptionable from '@/mixins/MixinFormOptionable'
    import FormField from '@/components/form-elements/FormField'
    import InputSelect from '@/components/form-inputs/InputSelect'

    export default {
        components: {
            FormField,
            InputSelect,
        },

        mixins: [MixinFormField, MixinFormOptionable],

        props: {
            placeholder: { type: [String, Number], default: null },
            isNullable: { type: Boolean, default: false },
        },

        computed: {
            selectFieldProps() {
                return {
                    ...this.fieldProps,
                    isLabelFixed: true,
                }
            },

            selectInputProps() {
                return {
                    ...this.inputProps,
                    placeholder: this.placeholder,
                    options: this.options,
                    isNullable: this.isNullable,
                }
            },
        },
    }
</script>
