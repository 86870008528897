<template>
    <RouterLink v-if="route" :to="route" :class="buttonClass" :tabindex="isHoverable ? 0 : -1">
        <span :class="backgroundClass" class="absolute inset-0 pointer-events-none" />
        <span :class="processingClass" class="absolute inset-0 pointer-events-none" />
        <span :class="labelClass" class="relative">{{ label }}</span>
    </RouterLink>

    <component
        :is="buttonComponent"
        v-else
        :to="route"
        :href="href"
        :disabled="isDisabled"
        :class="buttonClass"
        :type="isSubmitting ? 'submit' : 'button'"
        :tabindex="isHoverable ? 0 : -1"
    >
        <span :class="backgroundClass" class="absolute inset-0 pointer-events-none" />
        <span :class="processingClass" class="absolute inset-0 pointer-events-none" />
        <span :class="labelClass" class="relative">{{ label }}</span>
    </component>
</template>

<script>
    export default {
        props: {
            label: { type: String, required: true },
            level: { type: Number, default: 1 },
            route: { type: [Object, String], default: null },
            height: { type: String, default: 'h-60' },
            href: { type: String, default: null },
            isProcessing: { type: Boolean, default: false },
            isSubmitting: { type: Boolean, default: true },
            isFullWidth: { type: Boolean, default: true },
            isDisabled: { type: Boolean, default: false },
        },

        computed: {
            isHoverable() {
                return !this.isDisabled && !this.isProcessing
            },

            buttonComponent() {
                return this.href ? 'a' : 'button'
            },

            buttonClass() {
                const result = {
                    'appearance-none': true,
                    'group': true,
                    'flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'block': true,
                    'relative': true,
                    'font-medium': true,
                    'uppercase': true,
                    'tracking-widest': true,
                    'px-20': true,
                    'rounded-md': true,
                    'w-full': this.isFullWidth,
                    'text-white': this.level === 1,
                    'cursor-not-allowed': this.isDisabled,
                    'cursor-wait': this.isProcessing,
                }

                result[this.height] = true
                return result
            },

            backgroundClass() {
                if (this.level === 1) {
                    return {
                        'rounded-md': true,
                        'bg-purple': true,
                        'hoverable:group-hover:inset-4': this.isHoverable,
                        'hoverable:group-focus:inset-4': this.isHoverable,
                        'bg-disabled': !this.isHoverable,
                    }
                }

                return {
                    'rounded-md': true,
                    'border-black': true,
                    'border-1': true,
                    'hoverable:group-hover:border-4': this.isHoverable,
                    'hoverable:group-focus:border-4': this.isHoverable,
                    'opacity-50': !this.isHoverable,
                }
            },

            processingClass() {
                const isProcessing = this.level === 1 && this.isProcessing

                return {
                    'rounded-md': true,
                    'bg-transparent': true,
                    'width-full': true,
                    'bg-purple': isProcessing,
                    'animate-loading': isProcessing,
                }
            },

            labelClass() {
                return {
                    'text-gray-4': this.isDisabled,
                }
            },
        },
    }
</script>
