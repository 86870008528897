import { transformWorkPlace } from '@/apis/transforms/transformProFromApi'

export const transformExperience = (data) => {
    return {
        ...data,
        workPlace: data.workPlace && transformWorkPlace(data.workPlace),
        picture: data.workSample?.picture?.pictureLocation,
    }
}

export default (data) => {
    return Array.isArray(data) ? data.map(transformExperience) : transformExperience(data)
}
