import ServiceAuth from '@/services/ServiceAuth'

export default function(to, from, next) {
    ServiceAuth.loadUser().then(() => {
        if (ServiceAuth.isLoggedIn() && ServiceAuth.getUser()?.isOnBoarded) {
            return next()
        }

        next('/auth/detect')
    })
}
