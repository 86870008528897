<template>
    <ProSection :heading="$t('pro.clients.show.screens.address.heading')">
        <FormWrapper>
            <FormFields>
                <UserFormAddress
                    :has-autofocus="true"
                    :form-data="formData"
                    data-prefix="address."
                    @change="change"
                />
            </FormFields>

            <FormButtons>
                <BaseButton
                    :label="$t('common.save')"
                    :is-processing="isSubmitting"
                    :is-disabled="!isSubmittable"
                    @click="save"
                />
            </FormButtons>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinProClientsScreen from '@/mixins/MixinProClientsScreen'
    import UserFormAddress from '@/components/user/UserFormAddress'

    export default {
        components: {
            UserFormAddress,
        },

        mixins: [MixinProClientsScreen],

        computed: {
            isSubmittable() {
                return (
                    this.formData.address?.address &&
                    this.formData.address?.city &&
                    this.formData.address?.state &&
                    this.formData.address?.zipCode
                )
            },

            changes() {
                return [{ path: 'address', value: this.formData.address }]
            },
        },
    }
</script>
