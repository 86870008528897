import app from '@/app'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_SENTRY_ENV,
        integrations: [new Integrations.BrowserTracing()],
    })

    app.config.errorHandler = (error) => Sentry.captureException(error)
    window.addEventListener('error', (event) => Sentry.captureException(event))
    window.addEventListener('unhandledrejection', (event) => Sentry.captureException(event))
}
