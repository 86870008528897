<template>
    <div
        class="bg-sand-light overflow-hidden lg:overflow-visible md:bg-transparent md:px-20 lg:mb-200"
    >
        <LandingContainer class="pt-30 pb-40 lg:pb-80 md:pt-50 md:bg-sand-light md:rounded-xl">
            <section class="text-center space-y-20 md:space-y-40 md:text-left md:w-8/12 lg:w-6/12">
                <LandingHeading :level="2" class="text-purple">
                    {{ $t('landing.index.safety.heading') }}
                </LandingHeading>

                <LandingText>
                    <p>
                        {{ $t('landing.index.safety.description') }}

                        <LandingLink
                            :label="$t('landing.index.more')"
                            route="/safety"
                            class="hidden md:inline-block"
                        />
                    </p>
                </LandingText>

                <LandingButton
                    :label="$t('landing.index.safety.book')"
                    route="/book"
                    class="hidden md:inline-flex"
                />
            </section>

            <div class="flex mt-20 md:mt-0 lg:mt-80">
                <section
                    class="flex flex-col w-7/12 space-y-20 md:space-y-40 md:flex-row lg:w-3/12 lg:ml-3/12"
                >
                    <div
                        class="relative w-9/12 mb-2/12 md:mb-0 md:-ml-2/12 md:mr-1/12 md:mt-1/12 lg:absolute lg:w-3/12 lg:-bottom-50 lg:left-0 lg:-ml-50"
                    >
                        <PictureLandingSingleQuote
                            class="absolute text-dusty-pink inset-0 w-full"
                        />

                        <img
                            :src="personPicture"
                            class="relative mt-3/12 ml-4/12 w-9/12 rounded-xl"
                        />
                    </div>

                    <LandingQuote
                        :content="$t('landing.index.safety.quote.content')"
                        :author="$t('landing.index.safety.quote.author')"
                        :level="2"
                        class="space-y-20"
                    />

                    <LandingLink
                        :label="$t('landing.index.more')"
                        route="/safety"
                        class="md:hidden"
                    />
                </section>

                <div
                    class="w-8/12 h-auto -mr-3/12 md:absolute md:top-1/12 md:-right-1/12 md:mr-0 md:w-5/12 lg:mr-0 lg:w-4/12 lg:right-1/12 lg:-bottom-2/12"
                >
                    <img :src="appPicture" />
                </div>
            </div>

            <LandingButton
                :label="$t('landing.index.safety.book')"
                route="/book"
                class="mt-30 md:hidden"
            />
        </LandingContainer>
    </div>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingText from '@/components/landing/LandingText'
    import LandingButton from '@/components/landing/LandingButton'
    import LandingLink from '@/components/landing/LandingLink'
    import LandingQuote from '@/components/landing/LandingQuote'
    import PictureLandingSingleQuote from '@/assets/vectors/picture-landing-single-quote.svg'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingText,
            LandingButton,
            LandingLink,
            LandingQuote,
            PictureLandingSingleQuote,
        },

        computed: {
            personPicture() {
                return require('@/assets/images/picture-landing-safety-person.jpg').default
            },

            appPicture() {
                return require('@/assets/images/picture-landing-safety-app.png').default
            },
        },
    }
</script>
