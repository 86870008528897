import { shallowRef } from 'vue'

const initialState = () => {
    return {
        isOpen: false,
        message: null,
        note: null,
        cancel: null,
        confirm: null,
        resolve: null,
    }
}

export default {
    namespaced: true,

    state: initialState(),

    mutations: {
        open(state, options) {
            state.isOpen = true
            state.message = options.message
            state.note = typeof options.note === 'object' ? shallowRef(options.note) : options.note
            state.cancel = options.cancel
            state.confirm = options.confirm
            state.resolve = options.resolve
        },

        close(state) {
            const newState = initialState()
            Object.keys(newState).forEach((key) => {
                state[key] = newState[key]
            })
        },
    },

    actions: {
        confirm({ state, commit }) {
            if (state.isOpen) {
                state.resolve && state.resolve(true)
                commit('close')
            }
        },

        cancel({ state, commit }) {
            if (state.isOpen) {
                state.resolve && state.resolve(false)
                commit('close')
            }
        },
    },
}
