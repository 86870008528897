<template>
    <ProSection
        :heading="$t('pro.clients.show.screens.image.heading')"
        :description="$t('pro.clients.show.screens.image.description')"
    >
        <FormWrapper>
            <InputPhoto
                :is-select-displayed-initially="true"
                :value="formData.file"
                @change="selectFile"
                @preview="loadPreview"
            >
                <template #preview>
                    <ProWork
                        v-if="formData.picture"
                        :picture="formData.picture"
                        :is-picture-deletable="true"
                        @delete-picture="removeFile"
                    />
                </template>
            </InputPhoto>

            <TransitionSlide from="bottom">
                <FormButtons v-if="isSubmittable">
                    <BaseButton
                        :label="$t('common.save')"
                        :is-processing="isSubmitting"
                        @click="save"
                    />
                </FormButtons>
            </TransitionSlide>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinProClientsScreen from '@/mixins/MixinProClientsScreen'
    import InputPhoto from '@/components/form-inputs/InputPhoto'
    import ProWork from '@/components/pro/ProWork'
    import TransitionSlide from '@/components/transitions/TransitionSlide'

    export default {
        components: {
            InputPhoto,
            ProWork,
            TransitionSlide,
        },

        mixins: [MixinProClientsScreen],

        computed: {
            isSubmittable() {
                return !!this.formData.file
            },

            imageIndex() {
                return this.screenData.index
            },

            changes() {
                return [{ path: `images.${this.imageIndex}`, value: this.formData }]
            },
        },

        created() {
            this.initForm(this.screenData.image)
        },

        methods: {
            selectFile(file) {
                this.change({ path: 'file', value: file })
            },

            removeFile() {
                this.change({ path: 'file', value: null })
                this.change({ path: 'picture', value: null })
            },

            loadPreview(picture) {
                this.change({ path: 'picture', value: picture })
            },
        },
    }
</script>
