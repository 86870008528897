<script>
    import ProProfileCancellationStep1 from '@/components/pro/profile/cancellation/ProProfileCancellationStep1'

    export default {
        extends: ProProfileCancellationStep1,

        computed: {
            placeSlug() {
                return 'pro'
            },

            typePath() {
                return 'noShowPolicy.prosPlaceFeeType'
            },

            feePath() {
                return 'noShowPolicy.prosPlaceFee'
            },

            heading() {
                return this.$t(`pro.profile.cancellation.no-show.heading.${this.placeSlug}`)
            },
        },
    }
</script>
