<template>
    <component
        :is="isSubmittable ? 'form' : 'div'"
        class="flex-grow flex flex-col"
        @submit.prevent="$emit('submit', $event)"
    >
        <slot></slot>
    </component>
</template>

<script>
    export default {
        props: {
            isSubmittable: { type: Boolean, default: true },
        },

        emits: ['submit'],
    }
</script>
