import dayjs from 'dayjs'
import ServiceAuth from '@/services/ServiceAuth'
import i18n from '@/i18n'
import store from '@/store'
import { timeToDateTime, getCategoryIcon } from '@/helpers'

let categoriesBySubCategoryIds

export const appointmentCategoryIcon = (appointment) => {
    // TODO: Instead of searching for category icon, category should be present in API response.
    // TODO: Optimize this.

    if (!categoriesBySubCategoryIds) {
        categoriesBySubCategoryIds = store.getters['dictionaries/getCategories'].reduce(
            (categoriesBySubCategoryIds, category) => {
                category.subCategories.forEach(({ id }) => {
                    categoriesBySubCategoryIds[id] = category
                })

                return categoriesBySubCategoryIds
            },
            {},
        )
    }

    const appointmentCategories = appointment.experiences
        .reduce((subCategories, experience) => {
            return subCategories.concat(experience.subCategories)
        }, [])
        .map(({ id }) => {
            return categoriesBySubCategoryIds[id]
        })
        .filter((category, index, categories) => {
            return categories.findIndex(({ id }) => id === category.id) === index
        })

    return getCategoryIcon(appointmentCategories.length === 1 ? appointmentCategories[0] : null)
}

export const appointmentStartDateTime = (appointment) => {
    const startTimeMinutes = timeToDateTime(appointment.startTime).diff(
        dayjs().startOf('day'),
        'minutes',
    )

    return dayjs(appointment.date)
        .startOf('day')
        .add(startTimeMinutes, 'minutes')
}

export const appointmentEndDateTime = (appointment) => {
    const endTimeMinutes = timeToDateTime(appointment.endTime).diff(
        dayjs().startOf('day'),
        'minutes',
    )

    return dayjs(appointment.date)
        .startOf('day')
        .add(endTimeMinutes, 'minutes')
}

export const isCancelled = ({ status }) => {
    return status === 'Cancelled'
}

export const isReserved = ({ status }) => {
    return status === 'Reserved'
}

export const isRated = ({ status }) => {
    return ['Rated', 'PaidAndRated'].includes(status)
}

export const isRepeatable = (appointment) => {
    return isConcluded(appointment)
}

export const isReschedulable = (appointment) => {
    return !isConcluded(appointment) && isFuture(appointment)
}

export const isRatable = (appointment) => {
    return (
        !isFuture(appointment) &&
        !isNoShow(appointment) &&
        !isCancelled(appointment) &&
        !isRated(appointment)
    )
}

export const isChargable = (appointment) => {
    return (
        !isFuture(appointment) &&
        !isNoShow(appointment) &&
        !isCancelled(appointment) &&
        !isPaid(appointment)
    )
}

export const isPaid = ({ status }) => {
    return ['PaidByPro', 'PaidAndRated'].includes(status)
}

export const isNoShow = ({ status }) => {
    return ['StylistNotShowedUp', 'GuestNotShowedUp'].includes(status)
}

export const isGuestNoShow = ({ status }) => {
    return status === 'GuestNotShowedUp'
}

export const isProNoShow = ({ status }) => {
    return status === 'StylistNotShowedUp'
}

export const isFuture = (appointment) => {
    return !isCancelled(appointment) && appointmentEndDateTime(appointment).isAfter(dayjs())
}

export const isPrevious = (appointment) => {
    return !isFuture(appointment) && !isCancelled(appointment)
}

export const isConcluded = (appointment) => {
    return (
        isRated(appointment) ||
        isPaid(appointment) ||
        isNoShow(appointment) ||
        isCancelled(appointment)
    )
}

export const hasStarted = (appointment) => {
    return !isCancelled(appointment) && appointmentStartDateTime(appointment).isBefore(dayjs())
}

export const inProsPlace = ({ workPlace }) => {
    return workPlace === 'pro'
}

export const inGuestsPlace = ({ workPlace }) => {
    return workPlace === 'guest'
}

export const appointmentDuration = (appointment) => {
    const startTime = timeToDateTime(appointment.startTime)
    const endTime = timeToDateTime(appointment.endTime)

    return endTime.diff(startTime, 'minute')
}

export const appointmentAddress = (appointment) => {
    // TODO: This fallback to ServiceAuth.getUser() should be addressed somehow. It's just that
    // in some places Pro's address is not present in Pro-related responses from API.
    return inProsPlace(appointment)
        ? (appointment.pro || ServiceAuth.getUser()).address
        : appointment.address
}

export const appointmentPrices = (appointment, hasServiceFee) => {
    return [
        ...appointment.experiences.map((experience) => ({
            name: experience.name,
            price: addOnOrExperiencePrice(experience, appointment.workPlace),
        })),
        ...appointment.addOns.map((addOns) => ({
            name: addOns.name,
            price: addOnOrExperiencePrice(addOns, appointment.workPlace),
        })),
        ...(hasServiceFee
            ? [
                  {
                      name: i18n.global.t('common.fees.service'),
                      price: store.getters['dictionaries/getServiceFee'],
                  },
              ]
            : []),
    ]
}

export const cancellationPrices = (appointment, hasServiceFee) => {
    return [
        {
            name: i18n.global.t('common.fees.cancellation'),
            price: cancellationFee(appointment),
        },
        ...(hasServiceFee
            ? [
                  {
                      name: i18n.global.t('common.fees.service'),
                      price: store.getters['dictionaries/getServiceFee'],
                  },
              ]
            : []),
    ]
}

export const noShowPrices = (appointment, hasServiceFee) => {
    return [
        {
            name: i18n.global.t('common.fees.no-show'),
            price: noShowFee(appointment),
        },
        ...(hasServiceFee
            ? [
                  {
                      name: i18n.global.t('common.fees.service'),
                      price: store.getters['dictionaries/getServiceFee'],
                  },
              ]
            : []),
    ]
}

export const cancellationFee = (appointment) => {
    if (appointment.cancellationFee) {
        return appointment.cancellationFee
    }

    const policy = appointment.pro?.cancellationPolicy
    const type = inProsPlace(appointment) ? policy?.prosPlaceFeeType : policy?.guestsPlaceFeeType
    const fee = inProsPlace(appointment) ? policy?.prosPlaceFee : policy?.guestsPlaceFee

    return type === 'percent' ? appointment.price * (fee / 100) : fee
}

export const noShowFee = (appointment) => {
    const policy = appointment.pro?.noShowPolicy
    const type = inProsPlace(appointment) ? policy?.prosPlaceFeeType : policy?.guestsPlaceFeeType
    const fee = inProsPlace(appointment) ? policy?.prosPlaceFee : policy?.guestsPlaceFee

    return type === 'percent' ? appointment.price * (fee / 100) : fee
}

export const isCancellationFeeActive = (appointment) => {
    const cancellationTime = inProsPlace(appointment)
        ? appointment.pro?.cancellationPolicy?.prosPlaceCancellationTime
        : appointment.pro?.cancellationPolicy?.guestsPlaceCancellationTime

    return appointmentStartDateTime(appointment).diff(dayjs(), 'hour') < cancellationTime
}

export const addOnOrExperiencePrice = (addOnOrExperience, workPlace) => {
    if (addOnOrExperience.price) {
        // If price was already defined, just take it.
        return addOnOrExperience.price
    }

    if (workPlace === 'pro' && addOnOrExperience.prosPlacePrice) {
        return addOnOrExperience.prosPlacePrice
    }

    if (workPlace === 'guest' && addOnOrExperience.guestsPlacePrice) {
        return addOnOrExperience.guestsPlacePrice
    }

    return 0
}

export const appointmentPrice = (appointment) => {
    // TODO: Does it need to also take into account extras, tips & discount?

    // if (appointment.price) {
    //     return appointment.price + serviceFee
    // }

    if (!appointment?.pro) {
        return 0
    }

    const experiencesPrice = (appointment.experiences || []).reduce(
        (experiencesPrice, experience) =>
            experiencesPrice + addOnOrExperiencePrice(experience, appointment.workPlace),
        0,
    )

    const addOnsPrice = (appointment.addOns || []).reduce(
        (addOnsPrice, addOn) => addOnsPrice + addOnOrExperiencePrice(addOn, appointment.workPlace),
        0,
    )

    return experiencesPrice + addOnsPrice
}
