<template>
    <LayoutApp back-button="/pro/profile">
        <ProSection class="mb-20">
            <template #heading>
                <p>{{ $t('pro.profile.portfolio.index.heading') }}</p>
            </template>

            <RouterLink v-for="(work, index) in portfolio" :key="index" :to="editRoute(work)">
                <ProProfilePortfolioWork :work="work" />
            </RouterLink>
        </ProSection>

        <FormButtons class="-shadow-md">
            <ProProfileAdd
                :label="$t('pro.profile.portfolio.add-another')"
                route="/pro/profile/portfolio/add"
            />
        </FormButtons>
    </LayoutApp>
</template>

<script>
    import LayoutApp from '@/components/layouts/LayoutApp'
    import ProSection from '@/components/pro/ProSection'
    import ProProfilePortfolioWork from '@/components/pro/profile/portfolio/ProProfilePortfolioWork'
    import ProProfileAdd from '@/components/pro/profile/ProProfileAdd'
    import FormButtons from '@/components/form-elements/FormButtons'

    export default {
        components: {
            LayoutApp,
            ProSection,
            ProProfilePortfolioWork,
            ProProfileAdd,
            FormButtons,
        },

        loadData({ $router, $auth }) {
            const portfolio = $auth.getUser().portfolio

            if (portfolio.length === 0) {
                $router.push('/pro/profile/portfolio/add')
            }

            return {
                portfolio,
            }
        },

        methods: {
            editRoute({ id }) {
                return `/pro/profile/portfolio/${id}/edit`
            },
        },
    }
</script>
