<template>
    <FieldText
        :label="$t('common.fields.address')"
        :has-autofocus="hasAutofocus"
        :form-data="formData"
        :form-errors="formErrors"
        :value-path="valuePath('address')"
        @change="change"
    />

    <FieldText
        :label="$t('common.fields.city')"
        :form-data="formData"
        :form-errors="formErrors"
        :value-path="valuePath('city')"
        :is-disabled="isCityLocked"
        @change="change"
    />

    <FieldSelect
        :label="$t('common.fields.state')"
        :options="states"
        :form-data="formData"
        :form-errors="formErrors"
        :value-path="valuePath('state')"
        :is-disabled="isCityLocked"
        @change="change"
    />

    <FieldText
        :label="$t('common.fields.zip-code')"
        :form-data="formData"
        :form-errors="formErrors"
        :value-path="valuePath('zipCode')"
        :is-disabled="isCityLocked"
        @change="change"
    />
</template>

<script>
    import FieldSelect from '@/components/form-fields/FieldSelect'
    import FieldText from '@/components/form-fields/FieldText'

    export default {
        components: {
            FieldSelect,
            FieldText,
        },

        props: {
            formData: { type: Object, required: true },
            formErrors: { type: Object, default: null },
            dataPrefix: { type: String, default: undefined },
            hasAutofocus: { type: Boolean, default: false },
            isCityLocked: { type: Boolean, default: false },
        },

        emits: ['change'],

        computed: {
            states() {
                return this.$store.getters['dictionaries/getStates']
            },
        },

        methods: {
            valuePath(path) {
                return this.dataPrefix ? `${this.dataPrefix}${path}` : path
            },

            change(payload) {
                this.$emit('change', payload)
            },
        },
    }
</script>
