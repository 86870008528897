<template>
    <FormField v-bind="switchFieldProps">
        <InputSwitch v-bind="switchInputProps" @focus="focus" @blur="blur" @change="change" />
    </FormField>
</template>

<script>
    import MixinFormField from '@/mixins/MixinFormField'
    import FormField from '@/components/form-elements/FormField'
    import InputSwitch from '@/components/form-inputs/InputSwitch'

    export default {
        components: {
            FormField,
            InputSwitch,
        },

        mixins: [MixinFormField],

        computed: {
            switchFieldProps() {
                return {
                    ...this.fieldProps,
                    label: undefined,
                }
            },

            switchInputProps() {
                return {
                    ...this.inputProps,
                    label: this.label,
                }
            },
        },
    }
</script>
