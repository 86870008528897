<template>
    <BaseDatetime :datetime="appointmentStartDateTime">
        {{ formattedDatetime }}
    </BaseDatetime>
</template>

<script>
    import dayjs from 'dayjs'
    import { timeToDateTime } from '@/helpers'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import BaseDatetime from '@/components/base/BaseDatetime'

    export default {
        components: {
            BaseDatetime,
        },

        mixins: [MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
        },

        computed: {
            formattedDatetime() {
                const startDatetime = timeToDateTime(this.appointment.startTime)
                const endDatetime = timeToDateTime(this.appointment.endTime)

                return this.$t('pro.appointments.show.datetime-format', {
                    date: dayjs(this.appointment.date).format('MMMM YYYY'),
                    startTime: startDatetime.format('H:mm A'),
                    endTime: endDatetime.format('H:mm A'),
                })
            },
        },
    }
</script>
