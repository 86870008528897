<script>
    import GuestAppointmentsRepeatStep6 from '@/components/guest/appointments/repeat/GuestAppointmentsRepeatStep6'

    export default {
        extends: GuestAppointmentsRepeatStep6,

        computed: {
            heading() {
                return this.$t('guest.appointments.rescheduled')
            },
        },
    }
</script>
