<template>
    <section class="flex items-center">
        <div v-if="icon" class="relative h-30 w-45">
            <component :is="icon" class="h-full" />
        </div>

        <div>
            <h5 class="text-16 font-bold">{{ heading }}</h5>

            <p v-if="subheading" class="font-medium text-14 text-gray-3">
                {{ subheading }}
            </p>
        </div>

        <div class="ml-auto text-24">
            {{ value }}
        </div>

        <button v-if="isEditable" type="button" class="ml-10 p-5 block" @click="$emit('edit')">
            <IconModify class="h-20 w-20" />
        </button>
    </section>
</template>

<script>
    import { getWorkPlaceOptions } from '@/helpers'
    import BaseChoice from '@/components/base/BaseChoice'
    import IconModify from '@/assets/vectors/icon-modify.svg'

    export default {
        components: {
            BaseChoice,
            IconModify,
        },

        props: {
            icon: { type: Object, default: null },
            heading: { type: String, required: true },
            subheading: { type: String, default: null },
            value: { type: String, default: null },
            isEditable: { type: Boolean, default: false },
        },

        emits: ['edit'],

        computed: {
            place() {
                return getWorkPlaceOptions()
                    .map(({ icon, label, value: slug }) => ({ icon, label, slug }))
                    .find(({ slug }) => slug === this.placeSlug)
            },
        },
    }
</script>
