export function simulateApiCall(response, maxTime = 100) {
    return new Promise((resolve) =>
        setTimeout(() => resolve(response), Math.floor(Math.random() * maxTime) + maxTime / 10),
    )
}

export const normalizeFormData = (formData) => {
    // If an option selected from any form component.
    if (formData?.label && formData?.value) {
        return formData.value
    }

    //  If dayjs date.
    if (typeof formData?.format === 'function') {
        return formData.format('YYYY-MM-DD HH:mm:ss')
    }

    if (Array.isArray(formData)) {
        return formData.map((item) => normalizeFormData(item))
    }

    if (formData !== null && typeof formData === 'object') {
        return Object.keys(formData).reduce((newFormData, key) => {
            newFormData[key] = normalizeFormData(formData[key])
            return newFormData
        }, {})
    }

    return formData
}
