<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProProfilePlacesGuestArea from '@/components/pro/profile/places/guest/ProProfilePlacesGuestArea'

    export default {
        extends: ProProfilePlacesGuestArea,

        mixins: [MixinFormStep],
    }
</script>
