<template>
    <BaseHeaderSection
        :heading="$t('pro.dashboard.custom-period.heading')"
        :subheading="subheading"
        class="pb-10 mb-10 sticky z-floating top-70 bg-white"
    />

    <BaseCalendarList :selected-dates="selectedDates" @click-date="clickDate" />

    <TransitionSlide :is-appear="false" from="bottom">
        <div
            v-if="isBottomOpen"
            class="-shadow-md z-layout bg-white py-15 px-20 sticky -mx-20 bottom-65 space-y-25 mt-auto"
        >
            <GuestBookStep3Dates
                v-if="displayedDates.length"
                :dates="displayedDates"
                :is-cancelable="areDatesCancelable"
                @cancel="cancelPeriod"
            />

            <BaseButton
                :label="$t('common.continue')"
                :is-disabled="!isSubmittable"
                @click="selectPeriod"
            />
        </div>
    </TransitionSlide>
</template>

<script>
    import dayjs from 'dayjs'
    import GuestBookStep3Dates from '@/components/guest/book/step-3/GuestBookStep3Dates'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseButton from '@/components/base/BaseButton'
    import BaseCalendarList from '@/components/base/BaseCalendarList'
    import TransitionSlide from '@/components/transitions/TransitionSlide'

    export default {
        components: {
            BaseHeaderSection,
            GuestBookStep3Dates,
            BaseButton,
            BaseCalendarList,
            TransitionSlide,
        },

        emits: ['select-period'],

        data() {
            return {
                startDate: null,
                endDate: null,
            }
        },

        computed: {
            subheading() {
                return this.startDate
                    ? this.$t('pro.dashboard.custom-period.subheading.end')
                    : this.$t('pro.dashboard.custom-period.subheading.start')
            },

            isSubmittable() {
                return this.startDate && this.endDate
            },

            isBottomOpen() {
                return !!this.startDate
            },

            areDatesCancelable() {
                return !!(this.startDate && this.endDate)
            },

            selectedDates() {
                if (this.startDate && this.endDate) {
                    return [
                        {
                            from: dayjs(this.startDate),
                            to: dayjs(this.endDate),
                        },
                    ]
                }

                if (this.startDate) {
                    return [
                        {
                            from: dayjs(this.startDate),
                            to: dayjs(this.startDate),
                        },
                    ]
                }

                return []
            },

            displayedDates() {
                return [
                    {
                        label: this.$t('pro.dashboard.custom-period.start-date'),
                        value: dayjs(this.startDate).format('MMM D, YYYY'),
                    },
                    {
                        label: this.$t('pro.dashboard.custom-period.end-date'),
                        value: this.endDate
                            ? dayjs(this.endDate).format('MMM D, YYYY')
                            : this.$t('pro.dashboard.custom-period.select'),
                    },
                ]
            },
        },

        methods: {
            cancelPeriod() {
                this.startDate = null
                this.endDate = null
            },

            selectPeriod() {
                this.$emit('select-period', { startDate: this.startDate, endDate: this.endDate })
            },

            clickDate(value) {
                if (this.startDate && !this.endDate) {
                    if (this.startDate.isSameOrBefore(value)) {
                        this.endDate = value
                    } else {
                        this.endDate = this.startDate
                        this.startDate = value
                    }
                } else {
                    this.startDate = value
                    this.endDate = null
                }
            },
        },
    }
</script>
