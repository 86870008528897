<template>
    <GuestBookStep
        v-if="pros?.length === 0"
        :heading="$t('guest.book.who.empty.heading')"
        :subheading="$t('guest.book.who.empty.subheading')"
        :has-progress="hasProgress"
    >
        <template #default>
            <BaseButton :label="$t('guest.book.who.empty.cta')" :level="2" @click="goToStart" />
        </template>
    </GuestBookStep>

    <GuestBookStep
        v-else
        :heading="$t('guest.book.who.heading')"
        :subheading="$t('guest.book.who.subheading')"
        :has-progress="hasProgress"
    >
        <template #top>
            <BaseTabs
                v-if="isSearchingForEitherOrProsPlace"
                :items="viewTabs"
                class="mb-10"
                @change="changeView"
            />
        </template>

        <template #default>
            <component :is="viewComponent" :pros="pros" @click-pro="saveAndGoToNextStep" />
        </template>
    </GuestBookStep>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinBookStep from '@/mixins/MixinBookStep'
    import BaseTabs from '@/components/base/BaseTabs'
    import BaseButton from '@/components/base/BaseButton'
    import GuestBookStep4Grid from '@/components/guest/book/step-4/GuestBookStep4Grid'
    import GuestBookStep4Map from '@/components/guest/book/step-4/GuestBookStep4Map'
    import IconGrid from '@/assets/vectors/icon-grid.svg'
    import IconMap from '@/assets/vectors/icon-map.svg'

    export default {
        components: {
            BaseTabs,
            BaseButton,
        },

        mixins: [MixinBookStep],

        data() {
            return {
                areFiltersShown: false,
                viewTab: 'grid',
                pros: null,
            }
        },

        computed: {
            isSubmittable() {
                return false
            },

            isSearchingForEitherOrProsPlace() {
                return this.formData.search?.workPlace?.includes('pro')
            },

            viewTabs() {
                return [
                    {
                        label: this.$t('common.grid'),
                        slug: 'grid',
                        icon: IconGrid,
                        isSelected: this.viewTab === 'grid',
                    },
                    {
                        label: this.$t('common.map'),
                        slug: 'map',
                        icon: IconMap,
                        isSelected: this.viewTab === 'map',
                    },
                ]
            },

            viewComponent() {
                return {
                    grid: GuestBookStep4Grid,
                    map: GuestBookStep4Map,
                }[this.viewTab]
            },
        },

        mounted() {
            ApiPros.search(this.formData.search).then((pros) => (this.pros = pros))
            this.change({ path: 'appointment.pro', value: null })
        },

        methods: {
            changeView({ slug }) {
                this.viewTab = slug
            },

            saveAndGoToNextStep(pro) {
                this.change({ path: 'appointment.pro', value: pro })
                this.goToNextStep()
            },
        },
    }
</script>
