<template>
    <article :class="appointmentClass">
        <div
            v-if="isChargable"
            class="-mt-15 -mx-15 mb-15 h-30 px-15 flex items-center justify-between bg-yellow-dark text-purple font-bold text-12"
        >
            <p class="uppercase">{{ $t('pro.appointments.ended.question') }}</p>
            <p>{{ $t('pro.appointments.ended.wrap') }} &rarr;</p>
        </div>

        <div class="flex">
            <RouterLink :to="appointmentRoute" class="absolute inset-0 z-above" />

            <BaseAvatar :photo="appointment.guest.profilePicture" class="w-60 h-60 rounded-full" />

            <div class="mx-15 relative">
                <p v-if="isCancelled" class="text-14 font-medium whitespace-nowrap text-red">
                    {{ $t('pro.appointments.cancelled') }}
                </p>

                <h4 class="font-bold text-20">
                    {{ clientName }}
                </h4>

                <p :class="dateClass">
                    {{ appointmentDate }}
                </p>

                <p class="text-12">
                    {{ appointmentAddress?.address }}
                    <br />
                    {{ appointmentAddress?.zipCode }}
                    {{ appointmentAddress?.city }},
                    {{ appointmentAddress?.state }}
                </p>
            </div>

            <div class="ml-auto min-h-full flex flex-col items-end justify-between">
                <component :is="appointmentCategoryIcon" class="h-35 w-35" />

                <p>
                    <em class="text-20 not-italic mr-3">$</em>
                    <strong class="font-bold text-22">
                        {{ appointmentPrice }}
                    </strong>
                </p>
            </div>
        </div>
    </article>
</template>

<script>
    import { composeFullName } from '@/helpers'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import BaseAvatar from '@/components/base/BaseAvatar'

    export default {
        components: {
            BaseAvatar,
        },

        mixins: [MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
        },

        computed: {
            clientName() {
                return composeFullName(this.appointment.guest)
            },

            appointmentDate() {
                return this.appointmentStartDateTime.format('MMMM D | H:mm A')
            },

            appointmentRoute() {
                return `/pro/appointments/${this.appointment.id}`
            },

            appointmentPrice() {
                return this.isCancelled
                    ? this.appointment.cancellationFee || 0
                    : this.appointment.price
            },

            appointmentClass() {
                return {
                    'p-15': true,
                    'bg-white': true,
                    'shadow-lg': true,
                    'relative': true,
                    'border-l-4': this.isCancelled,
                    'border-red': this.isCancelled,
                    'pl-6': this.isCancelled,
                }
            },

            dateClass() {
                return {
                    'text-14': true,
                    'mt-5': true,
                    'mb-10': true,
                    'text-gray-2': true,
                    'font-medium': true,
                    'line-through': this.isCancelled,
                }
            },
        },
    }
</script>
