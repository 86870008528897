<template>
    <LayoutOverlay v-if="isScreenOpen" :back-button="true" @back="back">
        <component
            :is="screenComponent"
            v-if="isScreenOpen"
            :client="client"
            :screen-data="screenData"
            @save="closeScreen"
        />
    </LayoutOverlay>

    <LayoutApp v-else back-button="/pro/clients">
        <FormWrapper>
            <div class="sticky bg-white top-70 pb-20 z-above">
                <BaseHeaderSection
                    :heading="$t('pro.clients.heading')"
                    :subheading="$t('pro.clients.show.add-client')"
                />
            </div>

            <FormFields class="pb-20">
                <ProClientsAddSectionsPicture
                    :client="client"
                    :is-editable="true"
                    @add="openScreen('picture', formData)"
                    @edit="openScreen('picture', formData)"
                />

                <ProClientsAddSectionsInfo
                    :client="client"
                    :is-editable="true"
                    :is-edited="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    @change="change"
                />

                <ProClientsAddSectionsAddress
                    :client="client"
                    :is-editable="true"
                    @add="openScreen('address', formData)"
                    @edit="openScreen('address', formData)"
                />

                <ProClientsAddSectionsNotes
                    :client="client"
                    :is-editable="true"
                    @add="openScreen('note', $event)"
                    @edit="openScreen('note', $event)"
                    @delete="deleteNote($event)"
                />

                <ProClientsAddSectionsImages
                    :client="client"
                    :is-editable="true"
                    @add="openScreen('image', $event)"
                    @edit="openScreen('image', $event)"
                    @delete="deleteImage($event)"
                />
            </FormFields>

            <TransitionSlide from="bottom">
                <FormButtons v-if="isDirty">
                    <BaseButton
                        :label="$t('common.save')"
                        :is-processing="isSubmitting"
                        :is-disabled="!isSubmittable"
                        @click="save"
                    />
                </FormButtons>
            </TransitionSlide>
        </FormWrapper>
    </LayoutApp>
</template>

<script>
    import { shallowRef } from 'vue'
    import ApiProClients from '@/apis/ApiProClients'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import TransitionSlide from '@/components/transitions/TransitionSlide'
    import ProClientsAddSectionsInfo from '@/components/pro/clients/add/sections/ProClientsAddSectionsInfo'
    import ProClientsAddSectionsPicture from '@/components/pro/clients/add/sections/ProClientsAddSectionsPicture'
    import ProClientsAddSectionsAddress from '@/components/pro/clients/add/sections/ProClientsAddSectionsAddress'
    import ProClientsAddSectionsNotes from '@/components/pro/clients/add/sections/ProClientsAddSectionsNotes'
    import ProClientsAddSectionsImages from '@/components/pro/clients/add/sections/ProClientsAddSectionsImages'
    import ProClientsAddScreensPicture from '@/components/pro/clients/add/screens/ProClientsAddScreensPicture'
    import ProClientsAddScreensAddress from '@/components/pro/clients/add/screens/ProClientsAddScreensAddress'
    import ProClientsAddScreensImage from '@/components/pro/clients/add/screens/ProClientsAddScreensImage'
    import ProClientsAddScreensNote from '@/components/pro/clients/add/screens/ProClientsAddScreensNote'

    export default {
        components: {
            LayoutApp,
            LayoutOverlay,
            BaseHeaderSection,
            BaseButton,
            FormWrapper,
            FormFields,
            FormButtons,
            TransitionSlide,
            ProClientsAddSectionsInfo,
            ProClientsAddSectionsPicture,
            ProClientsAddSectionsAddress,
            ProClientsAddSectionsNotes,
            ProClientsAddSectionsImages,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        data() {
            return {
                screenComponent: null,
                screenData: null,
            }
        },

        computed: {
            isSubmittable() {
                return (
                    this.formData.firstName &&
                    this.formData.lastName &&
                    this.formData.phone &&
                    this.formData.email
                )
            },

            isScreenOpen() {
                return !!this.screenComponent
            },

            client() {
                return this.formData
            },
        },

        methods: {
            back() {
                this.screenComponent = null
                this.screenData = null
            },

            closeScreen(changes) {
                changes.forEach(this.change)
                this.back()
            },

            openScreen(slug, data) {
                this.screenData = data
                this.screenComponent = shallowRef(
                    {
                        picture: ProClientsAddScreensPicture,
                        address: ProClientsAddScreensAddress,
                        image: ProClientsAddScreensImage,
                        note: ProClientsAddScreensNote,
                    }[slug],
                )
            },

            deleteNote({ index }) {
                const notes = Object.values(this.client.notes)
                notes.splice(index, 1)
                this.change({ path: 'notes', value: notes })
            },

            deleteImage({ index }) {
                const images = Object.values(this.client.images)
                images.splice(index, 1)
                this.change({ path: 'images', value: images })
            },

            save() {
                this.handleSubmit(
                    ApiProClients.create(this.$auth.getUser().id, this.formData),
                ).then(() => this.$router.push('/pro/clients'))
            },
        },
    }
</script>
