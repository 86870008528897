<template>
    <component
        :is="linkComponent"
        :to="route"
        :class="linkClass"
        @click="overlayComponent && (isOverlayOpen = true)"
    >
        <div :class="bodyClass">
            <BaseHeading :level="5">
                <slot name="heading">{{ heading }}</slot>
            </BaseHeading>

            <p v-if="content" :class="contentClass">
                <slot name="content">{{ content }}</slot>
            </p>

            <p v-if="description" class="text-14 text-gray-3">
                <slot name="description">{{ description }}</slot>
            </p>
        </div>

        <div v-if="isRequired || isSuggested" class="ml-auto pl-20">
            <ProProfileRequired v-if="isRequired" />
            <ProProfileSuggested v-if="isSuggested" />
        </div>

        <IconNext
            v-if="route || overlayComponent"
            class="absolute h-13 text-gray-2 right-0 top-6/12 transform -translate-y-6/12"
        />
    </component>

    <BaseScreen v-if="isOverlayOpen">
        <component :is="overlayComponent" v-bind="overlayProps || {}" @back="back" />
    </BaseScreen>
</template>

<script>
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseScreen from '@/components/base/BaseScreen'
    import ProProfileRequired from '@/components/pro/profile/ProProfileRequired'
    import ProProfileSuggested from '@/components/pro/profile/ProProfileSuggested'
    import IconNext from '@/assets/vectors/icon-next.svg'

    export default {
        components: {
            LayoutOverlay,
            BaseHeading,
            BaseScreen,
            ProProfileRequired,
            ProProfileSuggested,
            IconNext,
        },

        props: {
            heading: { type: String, default: null },
            route: { type: [Object, String], default: null },
            overlayComponent: { type: Object, default: null },
            overlayProps: { type: Object, default: null },
            description: { type: String, default: null },
            content: { type: String, default: null },
            isRequired: { type: Boolean, default: false },
            isSuggested: { type: Boolean, default: false },
            isTruncated: { type: Boolean, default: false },
        },

        emits: ['back'],

        data() {
            return {
                isOverlayOpen: false,
            }
        },

        computed: {
            linkComponent() {
                return this.route ? 'RouterLink' : 'section'
            },

            linkClass() {
                return {
                    'min-h-80': true,
                    'py-20': true,
                    'first:border-t-1': true,
                    'border-b-1': true,
                    'border-gray-5': true,
                    'flex': true,
                    'items-center': true,
                    'relative': true,
                    'pr-20': this.route || this.overlayComponent,
                }
            },

            bodyClass() {
                return {
                    'space-y-5': true,
                    'truncate': this.isTruncated,
                }
            },

            contentClass() {
                return {
                    'text-14': true,
                    'font-medium': true,
                    'text-gray-2': true,
                    'truncate': this.isTruncated,
                }
            },
        },

        methods: {
            back() {
                this.isOverlayOpen = false
                this.$emit('back')
            },
        },
    }
</script>
