import '@/sentry'

import app from '@/app'
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'
import ServiceAuth from '@/services/ServiceAuth'
import ServiceUi from '@/services/ServiceUi'

import '@/native'
import '@/dayjs'
import '@/axios'
import '@/mixpanel'
import '@/directives/click-outside'
import '@/assets/styles/main.css'

app.config.globalProperties.$auth = ServiceAuth
app.config.globalProperties.$ui = ServiceUi

app.use(store)
    .use(router)
    .use(i18n)
    .mount('#app')
