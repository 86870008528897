<template>
    <RouterLink
        v-if="route"
        :to="route"
        class="flex items-center p-20 -m-20 hoverable:hover:text-purple"
    >
        <IconPrev class="h-20 w-20 mr-10" />
        <BaseLabel content="Back" />
    </RouterLink>

    <button
        v-else
        class="flex items-center p-20 -m-20 hoverable:hover:text-purple"
        @click="$emit('click')"
    >
        <IconPrev class="h-20 w-20 mr-10" />
        <BaseLabel content="Back" />
    </button>
</template>

<script>
    import BaseLabel from '@/components/base/BaseLabel'
    import IconPrev from '@/assets/vectors/icon-prev.svg'

    export default {
        components: {
            BaseLabel,
            IconPrev,
        },

        props: {
            route: { type: [Object, String], default: null },
        },

        emits: ['click'],
    }
</script>
