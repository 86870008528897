<template>
    <LayoutAdmin @select-period="loadNewGuests">
        <div class="h-full p-30 flex flex-col justfy-between">
            <div class="flex flex-col">
                <div class="text-center text-16 font-black tracking-widest uppercase">
                    {{ totalGuests }} {{ $t('admin.dashboard.newGuests.label') }}
                </div>
                <div class="flex flex-row justify-between items-center mt-30">
                    <InputSelect
                        :options="options"
                        :placeholder="$t('admin.dashboard.showAll')"
                        :value="filter"
                        class="w-200"
                        @change="onFilterChange"
                    />

                    <div class="flex flex-col justify-center items-center">
                        <div class="flex flex-row tracking-wider">
                            <IconMenuPayments class="color-black w-20" />
                            <span class="ml-10">{{ $t('admin.dashboard.ticketAverage') }}</span>
                        </div>
                        <span class="text-35 font-bold">$ {{ ticketAverage }}</span>
                    </div>

                    <div class="flex justify-end w-200 cursor-pointer">
                        <IconExport class="w-40" @click="exportNewGuests" />
                    </div>
                </div>
            </div>

            <BaseTable ref="table" :headers="tableHeaders" class="mt-30" @goToPage="loadNewGuests">
                <tr v-for="guest in guests" :key="guest.id" class="border-b-1 border-gray-4">
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <BaseAvatar
                                :photo="guest.profilePicture"
                                class="h-50 min-w-50 w-50 rounded-full"
                            />
                            <div class="flex flex-col ml-10">
                                <div class="font-bold uppercase">{{ guest.name }}</div>
                                <div class="text-gray-3">
                                    {{ `${$t('admin.dashboard.joinedOn')} ${guest.joinedOn}` }}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="p-5">{{ guest.email }}</td>
                    <td class="p-5">{{ guest.phone }}</td>
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <IconPin v-if="guest.location !== '-'" class="h-22" />
                            <span class="ml-10">{{ guest.location }}</span>
                        </div>
                    </td>
                    <td class="p-5">{{ guest.hasBooked.join(', ') }}</td>
                    <td class="p-5 text-center">
                        <div class="flex flex-col ml-10">
                            <div class="font-bold uppercase">{{ guest.bookingsCount }}</div>
                            <div class="text-gray-3">${{ guest.completedBookings }}</div>
                        </div>
                    </td>
                </tr>
            </BaseTable>
        </div>
    </LayoutAdmin>
</template>

<script>
    import dayjs from 'dayjs'
    import ApiAdmins from '@/apis/ApiAdmins'
    import BaseTable from '@/components/base/BaseTable'
    import IconPin from '@/assets/vectors/icon-pin.svg'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import IconExport from '@/assets/vectors/icon-export.svg'
    import LayoutAdmin from '@/components/layouts/LayoutAdmin'
    import InputSelect from '@/components/form-inputs/InputSelect'
    import IconMenuPayments from '@/assets/vectors/icon-menu-payments.svg'

    export default {
        components: {
            IconPin,
            BaseTable,
            IconExport,
            BaseAvatar,
            LayoutAdmin,
            InputSelect,
            IconMenuPayments,
        },

        data() {
            return {
                filter: 'All',
                totalGuests: 0,
                ticketAverage: 0,
                guests: [],
                startDate: dayjs(),
                endDate: dayjs(),
            }
        },

        computed: {
            options() {
                return [
                    { label: this.$t('admin.dashboard.showAll'), value: 'All' },
                    { label: this.$t('admin.dashboard.haveBooked'), value: 'HaveBooked' },
                    { label: this.$t('admin.dashboard.haveNotBooked'), value: 'HaveNotBooked' },
                ]
            },

            tableHeaders() {
                return [
                    { label: this.$t('admin.dashboard.table.guestName'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.email'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.phone'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.location'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.hasBooked'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.completedBookings') },
                ]
            },
        },

        methods: {
            async onFilterChange(option) {
                await this.loadNewGuests({ filter: option })
            },

            async loadNewGuests({ startDate, endDate, page, filter }) {
                if (startDate) {
                    this.startDate = startDate
                }
                if (endDate) {
                    this.endDate = endDate
                }
                if (filter) {
                    this.filter = filter
                }

                this.$refs.table.currentPage = page ? page : 1
                const result = await ApiAdmins.getNewGuests({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    page: this.$refs.table.currentPage,
                    filter: this.filter,
                })

                this.$refs.table.maxPage = result.maxPage

                this.guests = result.newGuests
                this.totalGuests = result.totalGuests
                this.ticketAverage = result.ticketAverage

                this.$refs.table.calculatePages()
            },

            async exportNewGuests() {
                const response = await ApiAdmins.exportNewGuests({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    filter: this.filter,
                })
                var fileLink = document.createElement('a')

                fileLink.href =
                    'data:text/csv;charset=utf-8, ' + escape(Object.values(response.data).join(''))

                const format = 'DD.MM.YY'
                const start = this.startDate.format(format)
                const end = this.endDate.format(format)
                const fileName = `New_Guests_${start}-${end}.csv`
                fileLink.setAttribute('download', fileName)
                document.body.appendChild(fileLink)

                fileLink.click()
            },
        },
    }
</script>
