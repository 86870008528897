<template>
    <LayoutApp back-button="/pro/profile">
        <ProSection
            :heading="$t('pro.profile.link.heading')"
            :description="$t('pro.profile.link.description')"
        >
            <FormWrapper @submit="save">
                <FieldText
                    :has-autofocus="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="userName"
                    @change="change"
                >
                    <template #prefix>
                        <span class="text-gray-2 text-18">
                            {{ prefix }}
                        </span>
                    </template>
                </FieldText>

                <FormButtons>
                    <BaseButton
                        :label="$t('common.save')"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                    />
                </FormButtons>
            </FormWrapper>
        </ProSection>
    </LayoutApp>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import FieldText from '@/components/form-fields/FieldText'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'

    export default {
        components: {
            LayoutApp,
            FieldText,
            BaseButton,
            FormWrapper,
            FormButtons,
        },

        mixins: [MixinProProfileSection],

        computed: {
            isSubmittable() {
                return !!this.formData.userName
            },

            prefix() {
                return `${process.env.VUE_APP_URL}/p/`
            },
        },

        created() {
            this.initForm(this.$auth.getUser())
        },

        methods: {
            apiCall() {
                return ApiPros.update(this.$auth.getUser().id, 'userName', this.formData)
            },
        },
    }
</script>
