<template>
    <ProSection
        :heading="$t('pro.profile.licenses.steps.1.heading')"
        :description="$t('pro.profile.licenses.steps.1.description')"
    >
        <FormWrapper @submit="save">
            <FormFields>
                <FieldText
                    :label="$t('pro.profile.licenses.steps.1.name')"
                    :has-autofocus="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="name"
                    @change="change"
                />
                <FieldText
                    :label="$t('pro.profile.licenses.steps.1.number')"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="licenseNumber"
                    @change="change"
                />

                <FieldSelect
                    :label="$t('pro.profile.licenses.steps.1.state')"
                    :options="states"
                    :form-data="formData"
                    value-path="state"
                    @change="change"
                />

                <FieldText
                    :label="$t('pro.profile.licenses.steps.1.expiration.label')"
                    :placeholder="$t('pro.profile.licenses.steps.1.expiration.placeholder')"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="expirationDate"
                    @change="change"
                />

                <button
                    v-if="isEdited"
                    type="button"
                    class="flex items-center text-red space-x-10 text-14 mx-auto"
                    @click="confirmDelete"
                >
                    <IconDelete class="w-16" />
                    <span>{{ $t('pro.profile.licenses.steps.1.delete') }}</span>
                </button>
            </FormFields>

            <FormButtons>
                <BaseButton
                    :label="$t('pro.profile.licenses.steps.1.save')"
                    :is-processing="isSubmitting"
                    :is-disabled="!isSubmittable"
                />
            </FormButtons>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import ApiProLicenses from '@/apis/ApiProLicenses'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldSelect from '@/components/form-fields/FieldSelect'
    import FieldText from '@/components/form-fields/FieldText'
    import BaseButton from '@/components/base/BaseButton'
    import IconDelete from '@/assets/vectors/icon-delete.svg'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormFields,
            FormButtons,
            FieldSelect,
            FieldText,
            BaseButton,
            IconDelete,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isEdited() {
                return !!this.formData.id
            },

            isSubmittable() {
                return (
                    this.formData.name &&
                    this.formData.licenseNumber &&
                    this.formData.state &&
                    this.formData.expirationDate
                )
            },

            states() {
                return this.$store.getters['dictionaries/getStates']
            },
        },

        methods: {
            confirmDelete() {
                this.$ui
                    .confirm({
                        message: this.$t('pro.profile.licenses.steps.1.delete-sure'),
                    })
                    .then((isConfirmed) => isConfirmed && this.deleteAndClose())
            },

            deleteAndClose() {
                ApiProLicenses.delete(this.$auth.getUser().id, this.formData.id).then(() =>
                    this.goToStep(3),
                )
            },

            save() {
                return this.handleSubmit(
                    this.isEdited
                        ? ApiProLicenses.update(
                              this.$auth.getUser().id,
                              this.formData.id,
                              this.formData,
                          )
                        : ApiProLicenses.create(this.$auth.getUser().id, this.formData),
                ).then(() => this.goToStep(2))
            },
        },
    }
</script>
