<template>
    <ul class="flex justify-between">
        <li v-for="bar in bars" :key="bar.date" class="w-full h-170">
            <div class="h-full flex flex-col justify-end items-center px-2 max-w-20">
                <div
                    v-if="areViewsDisplayed"
                    class="bg-magenta-light-2 w-full "
                    :style="viewsStyle(bar)"
                />

                <div
                    v-if="areAppointmentsDisplayed"
                    class="bg-purple w-full "
                    :style="appointmentsStyle(bar)"
                />
            </div>
        </li>
    </ul>

    <div class="flex">
        <button
            class="flex items-center space-x-10 w-full"
            @click="areViewsDisplayed = !areViewsDisplayed"
        >
            <div class="h-30 w-30 flex items-center justify-center bg-magenta-light-2 text-white">
                <IconCheck v-if="areViewsDisplayed" class="w-20 h-20" />
            </div>
            <span class="text-14">{{ $t('pro.dashboard.chart.views') }}</span>
        </button>

        <button
            class="flex items-center space-x-10 w-full"
            @click="areAppointmentsDisplayed = !areAppointmentsDisplayed"
        >
            <div class="h-30 w-30 flex items-center justify-center bg-purple text-white">
                <IconCheck v-if="areAppointmentsDisplayed" class="w-20 h-20" />
            </div>
            <span class="text-14">{{ $t('pro.dashboard.chart.bookings') }}</span>
        </button>
    </div>
</template>

<script>
    import IconCheck from '@/assets/vectors/icon-check.svg'

    export default {
        components: {
            IconCheck,
        },

        props: {
            stats: { type: Object, required: true },
        },

        data() {
            return {
                areViewsDisplayed: true,
                areAppointmentsDisplayed: true,
            }
        },

        computed: {
            bars() {
                return this.stats.appointmentsPerDay.map(({ date, appointments }, index) => ({
                    date,
                    appointments,
                    views: this.stats.profileViewsPerDay[index]?.views,
                }))
            },

            highestBar() {
                return this.bars.reduce((highestBar, { appointments, views }) => {
                    const currentBarHeight = appointments + views
                    return highestBar < currentBarHeight ? currentBarHeight : highestBar
                }, 0)
            },
        },

        methods: {
            viewsStyle({ views }) {
                return {
                    height: `${(views / this.highestBar) * 100}%`,
                }
            },

            appointmentsStyle({ appointments }) {
                return {
                    height: `${(appointments / this.highestBar) * 100}%`,
                }
            },
        },
    }
</script>
