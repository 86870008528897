<template>
    <form :class="bookClass" @submit.prevent="initializeBook">
        <FieldSuggest
            :placeholder="$t('landing.book.subcategory')"
            :suggestions-call="getSubCategoryAndProSuggestions"
            :is-required="true"
            :is-readonly="!isSubCategoryFocused"
            :is-inverted="isInverted"
            :class="subCategoryClass"
            :form-data="formData"
            :form-errors="formErrors"
            value-path="subCategory"
            @focus="isSubCategoryFocused = true"
            @blur="isSubCategoryFocused = false"
            @input="clearSubCategory"
            @change="selectSubCategoryOrProAndChange"
        >
            <template #prefix>
                <IconSearch
                    :class="{ 'text-gray-2': isInverted }"
                    class="flex-shrink-0 w-25 h-25 mx-15 text-current"
                />
            </template>

            <template #option="{ option, isHighlighted }">
                <GuestBookStep1Suggestion :option="option" :is-highlighted="isHighlighted" />
            </template>

            <template #suffix>
                <div class="flex-shrink-0 w-20" />
            </template>
        </FieldSuggest>

        <FieldSuggest
            :placeholder="$t('landing.book.address')"
            :suggestions-call="getAddressSuggestions"
            :is-required="true"
            :is-readonly="!isAddressFocused"
            :is-inverted="isInverted"
            :class="addressClass"
            :form-data="formData"
            :form-errors="formErrors"
            value-path="address"
            @focus="isAddressFocused = true"
            @blur="isAddressFocused = false"
            @change="selectAddressAndChange"
        >
            <template #prefix>
                <div class="flex-shrink-0 w-20" />
            </template>

            <template #suffix>
                <div class="flex-shrink-0 w-20" />
            </template>
        </FieldSuggest>

        <button :disabled="!isSubmittable" :class="buttonClass">
            <IconSearch class="w-40 h-40" />
        </button>
    </form>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import ApiGoogle from '@/apis/ApiGoogle'
    import ApiPros from '@/apis/ApiPros'
    import FieldSuggest from '@/components/form-fields/FieldSuggest'
    import GuestBookStep1Suggestion from '@/components/guest/book/step-1/GuestBookStep1Suggestion'
    import IconSearch from '@/assets/vectors/icon-search.svg'

    export default {
        components: {
            FieldSuggest,
            GuestBookStep1Suggestion,
            IconSearch,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        props: {
            isInverted: { type: Boolean, default: false },
        },

        data() {
            return {
                isSubCategoryFocused: false,
                isAddressFocused: false,
            }
        },

        computed: {
            getAddressSuggestions() {
                return ApiGoogle.getAddressSuggestions
            },

            availableSubCategories() {
                return this.$store.getters['dictionaries/getCategories']
                    .reduce(
                        (subCategories, category) => subCategories.concat(category.subCategories),
                        [],
                    )
                    .map(({ id: value, name: label }) => ({ label, value }))
            },

            isSubmittable() {
                return this.formData.search?.subCategoryIds && this.formData.search?.areaZipCode
            },

            bookClass() {
                return {
                    'flex': true,
                    'h-50': this.isInverted,
                    'space-x-2': !this.isInverted,
                    'h-60': !this.isInverted,
                }
            },

            suggestClass() {
                return {
                    'h-full': true,
                    'transition-colors': true,
                    'duration-250': true,
                    'border-1': this.isInverted,
                    'border-gray-2': this.isInverted,
                }
            },

            subCategoryClass() {
                return {
                    ...this.suggestClass,
                    'w-7/12': true,
                    'rounded-l-md': true,
                    'bg-yellow-naples': !this.isInverted && !this.isSubCategoryFocused,
                }
            },

            addressClass() {
                return {
                    ...this.suggestClass,
                    'w-5/12': true,
                    'border-l-0': this.isInverted,
                    'rounded-r-md': this.isInverted,
                    'bg-yellow-naples': !this.isInverted && !this.isAddressFocused,
                }
            },

            buttonClass() {
                return {
                    'px-20': true,
                    'bg-purple': true,
                    'text-white': true,
                    'rounded-r-md': true,
                }
            },
        },

        methods: {
            initializeBook() {
                this.$store.commit('continuity/setBook', this.formData)
                this.$router.push('/book')
            },

            async getSubCategoryAndProSuggestions(query) {
                const pros = await ApiPros.searchByName(query)
                const subCategories = this.availableSubCategories.filter(
                    ({ label }) => label.toLowerCase().indexOf(query.toLowerCase()) !== -1,
                )

                return Promise.resolve([...subCategories, ...pros.map((pro) => ({ pro }))])
            },

            clearSubCategory() {
                this.change({ path: 'search.subCategoryIds', value: null })
                this.change({ path: 'subCategory', value: null })
            },

            selectSubCategoryOrProAndChange(payload) {
                if (payload.value?.pro) {
                    return this.$router.push(`/p/${payload.value?.pro?.id}`)
                }

                this.change({ path: 'search.subCategoryIds', value: [payload.value.value] })
                this.change(payload)
            },

            selectAddressAndChange({ path, value: { label, value } }) {
                this.changeErrors()

                ApiGoogle.getAddressZipCode(value)
                    .then((zipCode) => {
                        this.change({ path: 'search.areaZipCode', value: zipCode })
                        this.change({ path, value: { label, value } })
                    })
                    .catch((error) => {
                        this.focusAutofocusInput()
                        this.changeErrors({ errors: { address: error } })
                    })
            },
        },
    }
</script>
