<template>
    <ul class="flex text-10 font-bold">
        <li v-for="(step, index) in steps" :key="index" :class="stepClass(index)">
            <p :class="indexClass(index)">
                <span>{{ index + 1 }}</span>
            </p>
            <p class="uppercase h-30 flex items-center">
                <span>{{ step.label }}</span>
            </p>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            currentStep: { type: Number, default: 1 },
        },

        computed: {
            steps() {
                return [
                    { label: this.$t('guest.verify.steps.0') },
                    { label: this.$t('guest.verify.steps.1') },
                    { label: this.$t('guest.verify.steps.2') },
                ]
            },
        },

        methods: {
            stepClass(index) {
                const isNext = index + 1 > this.currentStep
                const isCurrent = index + 1 === this.currentStep

                return {
                    'flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'space-x-7': true,
                    'w-full': true,
                    'text-purple': !isNext,
                    'text-gray-3': isNext,
                    'border-b-3': isCurrent,
                    'border-current': isCurrent,
                }
            },

            indexClass(index) {
                const isNext = index + 1 > this.currentStep

                return {
                    'flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'border-1': true,
                    'rounded-full': true,
                    'h-18': true,
                    'w-18': true,
                    'leading-none': true,
                    'text-purple': !isNext,
                    'bg-purple': !isNext,
                    'border-purple': !isNext,
                    'text-white': !isNext,
                    'text-gray-3': isNext,
                    'border-current': isNext,
                }
            },
        },
    }
</script>
