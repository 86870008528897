<template>
    <div class="flex-grow flex flex-col space-y-30">
        <BaseHeading :level="3">
            <span>{{ $t('guest.appointments.rate.payment-processed') }}</span>
            <IconSuccess
                class="inline-block h-35 w-35 text-green animate-pop ml-15 align-baseline -mb-5"
            />
        </BaseHeading>

        <div class="space-y-10">
            <BaseHeaderDivider :heading="$t('pro.appointments.finish.payment-details')" />
            <BaseReceipt :positions="appointmentPrices" :is-total-only="true" />
            <ProPayoutMethod class="border-t-1 border-gray-5 pt-15 pb-5" />
            <BaseText
                :content="$t('pro.appointments.finish.available-soon')"
                :level="2"
                :is-light="true"
            />
        </div>

        <ProAddClient
            v-if="isGuestInClientBook"
            :guest="appointment.guest"
            class="flex-grow justify-center"
        />
    </div>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import MixinProAppointmentsCharge from '@/mixins/MixinProAppointmentsCharge'
    import MixinProClientInBook from '@/mixins/MixinProClientInBook'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseReceipt from '@/components/base/BaseReceipt'
    import BaseText from '@/components/base/BaseText'
    import ProPayoutMethod from '@/components/pro/ProPayoutMethod'
    import ProAddClient from '@/components/pro/ProAddClient'
    import IconSuccess from '@/assets/vectors/icon-success.svg'

    export default {
        components: {
            BaseHeading,
            BaseHeaderDivider,
            BaseReceipt,
            BaseText,
            ProPayoutMethod,
            ProAddClient,
            IconSuccess,
        },

        mixins: [MixinFormStep, MixinAppointment, MixinProAppointmentsCharge, MixinProClientInBook],

        props: {
            appointment: { type: Object, required: true },
        },
    }
</script>
