import axios from 'axios'
import transformProPortfolioFromApi from '@/apis/transforms/transformProPortfolioFromApi'
import transformProPortfolioToApi from '@/apis/transforms/transformProPortfolioToApi'

const transforms = {
    transformRequest: [transformProPortfolioToApi, ...axios.defaults.transformRequest],
    transformResponse: [...axios.defaults.transformResponse, transformProPortfolioFromApi],
}

export default {
    create(id, data) {
        return axios.post(`/stylists/${id}/workSamples`, data, transforms)
    },

    update(id, portfolioId, data) {
        return axios.patch(`/stylists/${id}/workSamples/${portfolioId}`, data, {
            transformResponse: transforms.transformResponse,
        })
    },

    delete(id, portfolioId) {
        return axios.delete(`/stylists/${id}/workSamples/${portfolioId}`)
    },
}
