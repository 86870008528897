<template>
    <div>
        <ProProfileLink
            v-for="(subCategoryLink, index) in subCategoryLinks"
            :key="index"
            :route="subCategoryLink.route"
            :content="subCategoryLink.content"
            :description="subCategoryLink.description"
            :is-required="subCategoryLink.isRequired"
            :is-truncated="true"
        >
            <template #heading>
                <div class="flex space-x-5 w-full">
                    <span class="truncate">{{ subCategoryLink.heading }}</span>
                    <span>({{ subCategoryLink.experiencesCount }})</span>
                </div>
            </template>
        </ProProfileLink>
    </div>
</template>

<script>
    import ProProfileLink from '@/components/pro/profile/ProProfileLink'

    export default {
        components: {
            ProProfileLink,
        },

        props: {
            subCategories: { type: Array, required: true },
            experiences: { type: Array, required: true },
        },

        computed: {
            subCategoryLinks() {
                return this.subCategories.map((subCategory) => {
                    const experiences = this.experiences.filter((experience) =>
                        experience.subCategories.map(({ id }) => id).includes(subCategory.id),
                    )

                    return {
                        heading: subCategory.name,
                        experiencesCount: experiences.length,
                        content: experiences.map(({ name }) => name).join(', '),
                        description:
                            experiences.length === 0
                                ? this.$t('pro.profile.experiences.index.add-experiences')
                                : null,
                        isRequired: experiences.length === 0,
                        route: `/pro/profile/experiences/subcategories/${subCategory.id}`,
                    }
                })
            },
        },
    }
</script>
