<template>
    <header class="flex justify-between">
        <h5 v-for="(weekday, index) in weekdays" :key="index" :class="weekdayClass(weekday)">
            {{ weekday.label }}
        </h5>
    </header>
</template>

<script>
    import dayjs from 'dayjs'

    export default {
        computed: {
            weekdays() {
                return Array(7)
                    .fill(null)
                    .map((day, index) => ({
                        number: index + 1,
                        label: dayjs()
                            .day(index + 1)
                            .format('dd'),
                    }))
            },
        },

        methods: {
            weekdayClass(weekday) {
                const isCurrent = weekday.number === dayjs().weekday()

                return {
                    'w-1/7': true,
                    'h-40': true,
                    'flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'text-gray-2': !isCurrent,
                    'font-medium': !isCurrent,
                    'text-gray-1': isCurrent,
                    'font-bold': isCurrent,
                }
            },
        },
    }
</script>
