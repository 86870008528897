<template>
    <section class="bg-purple md:mt-150">
        <LandingContainer class="pt-40 flex flex-col md:flex-row-reverse md:py-60 lg:pb-80">
            <div
                class="text-white text-center pb-30 md:pb-0 md:text-left md:ml-auto md:w-6/12 lg:w-5/12"
            >
                <LandingHeading :level="1" class="mb-20">
                    {{ $t('landing.index.pros.heading.1') }}
                    <br class="hidden md:block" />
                    {{ $t('landing.index.pros.heading.2') }}
                </LandingHeading>

                <LandingText class="text-purple-light-3 font-medium">
                    <p>
                        {{ $t('landing.index.pros.description') }}

                        <LandingLink
                            :label="$t('landing.index.more')"
                            :is-inverted="true"
                            route="/for-pros"
                            class="hidden md:inline-block"
                        />
                    </p>
                </LandingText>

                <LandingButton
                    :label="$t('landing.index.pros.signup')"
                    :level="2"
                    route="/auth/register/pro"
                    class="md:w-300 mt-30 md:mt-40"
                />

                <LandingLink
                    :label="$t('landing.index.more')"
                    :is-inverted="true"
                    route="/for-pros"
                    class="mt-30 md:hidden"
                />
            </div>

            <PictureLandingPros
                class="pointer-events-none md:w-5/12 md:absolute lg:w-450 xs:left-40 lg:left-1/12 bottom-0"
            />
        </LandingContainer>
    </section>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingText from '@/components/landing/LandingText'
    import LandingButton from '@/components/landing/LandingButton'
    import LandingLink from '@/components/landing/LandingLink'
    import PictureLandingPros from '@/assets/vectors/picture-landing-pros.svg'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingText,
            LandingButton,
            LandingLink,
            PictureLandingPros,
        },
    }
</script>
