<template>
    <div class="space-y-30">
        <BaseHeading
            :heading="$t('guest.appointments.rate.add-tip')"
            :level="2"
            class="text-center"
        />

        <BaseAvatar
            :photo="appointment.pro.profilePicture"
            class="rounded-full h-70 w-70 mx-auto"
        />

        <FieldAmount
            :form-data="formData"
            value-path="tip"
            class="w-120 mx-auto"
            @change="change"
        />
    </div>

    <FormButtons>
        <div class="h-60 w-full flex items-center justify-center">
            <BaseLink :label="$t('guest.appointments.rate.no-thanks')" @click="resetAndGoTo" />
        </div>

        <BaseButton
            :label="$t('common.confirm')"
            :is-disabled="!isSubmittable"
            :is-processing="isSubmitting"
            @click="goToStep(4)"
        />
    </FormButtons>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import BaseLink from '@/components/base/BaseLink'
    import FieldAmount from '@/components/form-fields/FieldAmount'
    import FormButtons from '@/components/form-elements/FormButtons'

    export default {
        components: {
            BaseAvatar,
            BaseHeading,
            BaseButton,
            BaseLink,
            FieldAmount,
            FormButtons,
        },

        mixins: [MixinFormStep, MixinFormSubmittable],

        props: {
            appointment: { type: Object, required: true },
        },

        computed: {
            isSubmittable() {
                return this.formData.tip
            },
        },

        methods: {
            resetAndGoTo() {
                this.change({ path: 'tip', value: null })
                this.goToStep(3)
            },
        },
    }
</script>
