<template>
    <LandingContainer class="pt-50 md:pt-80">
        <LandingHeading :level="3" class="text-center mb-30 md:mb-60 mx-auto md:max-w-7/12">
            {{ $t('landing.pros.tools.heading') }}
        </LandingHeading>

        <LandingFeatures :features="features" />
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingFeatures from '@/components/landing/LandingFeatures'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingFeatures,
        },

        computed: {
            features() {
                return Array(7)
                    .fill(0)
                    .map((value, index) => this.$t(`landing.pros.tools.features.${index}`))
            },
        },
    }
</script>
