export default {
    props: {
        formData: { type: Object, required: true },
        valuePath: { type: [Number, String], default: null },
    },

    emits: ['change'],

    methods: {
        change(value) {
            this.$emit('change', { path: this.valuePath, value })
        },
    },
}
