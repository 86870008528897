<template>
    <div :class="layoutClass">
        <slot name="top" />
        <slot />

        <AppConfirm />
    </div>
</template>

<script>
    import AppConfirm from '@/components/app/AppConfirm'

    export default {
        components: {
            AppConfirm,
        },

        computed: {
            layoutClass() {
                return {
                    'relative': true,
                    'min-h-full': true,
                    'max-w-500': true,
                    'mx-auto': true,
                    'w-full': true,
                    'px-20': true,
                    'flex': true,
                    'flex-col': true,
                    'safe-top': true,
                }
            },
        },
    }
</script>
