import axios from 'axios'
import transformProFromApi from '@/apis/transforms/transformProFromApi'
import transformProExperienceToApi from '@/apis/transforms/transformProExperienceToApi'

const transforms = {
    transformRequest: [transformProExperienceToApi, ...axios.defaults.transformRequest],
    transformResponse: [...axios.defaults.transformResponse, transformProFromApi],
}

export default {
    updateSubCategories(id, data) {
        return axios.post(`/stylists/${id}/subCategories`, data, {
            transformResponse: transforms.transformResponse,
        })
    },

    create(id, data) {
        return axios.post(`/stylists/${id}/experiences`, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            ...transforms,
        })
    },

    update(id, experienceId, data) {
        return axios.put(`/stylists/${id}/experiences/${experienceId}`, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            ...transforms,
        })
    },

    delete(id, experienceId) {
        return axios.delete(`/stylists/${id}/experiences/${experienceId}`)
    },
}
