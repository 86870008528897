<template>
    <div v-click-outside="close" :class="selectClass">
        <select
            ref="input"
            class="absolute pointer-events-none top-0 opacity-0"
            :value="value"
            :autofocus="hasAutofocus"
            :required="isRequired"
            :disabled="isDisabled"
            @keydown.up.prevent="handleUpArrow"
            @keydown.down.prevent="handleDownArrow"
            @keydown.enter.prevent="handleEnter"
            @keydown.esc.prevent="close"
            @keydown.space.prevent="toggle"
            @click.prevent="toggle"
            @blur="blur"
            @focus="focus"
            @change="select"
        >
            <option v-if="isNullable"></option>

            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.label }}
            </option>
        </select>

        <div :class="valueClass" @click="click">
            <span>{{ inputLabel }}</span>
            <IconArrows class="w-19 pl-10 ml-auto" />
        </div>

        <div ref="scrollable" :class="optionsClass" @mouseleave="hoverOption(null)">
            <button
                v-for="(option, index) in options"
                :key="option.value"
                :ref="(el) => el && (optionRefs[index] = el)"
                :class="optionClass(index)"
                type="button"
                tabindex="-1"
                @mouseover="hoverOption(index)"
                @click="select(option.value)"
            >
                {{ option.label }}
            </button>
        </div>
    </div>
</template>

<script>
    import { isNodeDescendant } from '@/helpers'
    import MixinFormInput from '@/mixins/MixinFormInput'
    import MixinFormOptionable from '@/mixins/MixinFormOptionable'
    import MixinIndicateScroll from '@/mixins/MixinIndicateScroll'
    import IconArrows from '@/assets/vectors/icon-arrows.svg'

    export default {
        components: {
            IconArrows,
        },

        mixins: [MixinFormInput, MixinFormOptionable, MixinIndicateScroll],

        props: {
            placeholder: { type: String, default: null },
            value: { type: [Number, String, Object], default: null },
            options: { type: Array, required: true },
            isNullable: { type: Boolean, default: false },
            isInverted: { type: Boolean, default: false },
        },

        emits: ['blur', 'focus'],

        data() {
            return {
                isOpen: false,
                isFocused: false,
                highlightedOption: null,
                optionRefs: [],
            }
        },

        computed: {
            selectClass() {
                return {
                    'relative': true,
                    'cursor-pointer': true,
                    'pointer-events-none': this.isDisabled,
                    'opacity-30': this.isDisabled,
                    'cursor-not-allowed': this.isDisabled,
                }
            },

            optionsClass() {
                const hasOptions = !!this.options.length

                return {
                    'absolute': true,
                    'z-floating': true,
                    'top-full': true,
                    'left-0': true,
                    'w-full': true,
                    'border-1': true,
                    'border-t-0': true,
                    'border-gray-4': true,
                    'bg-white': true,
                    'max-h-225': true,
                    'overflow-y-auto': true,
                    'transition': true,
                    'shadow-lg': true,
                    'pointer-events-none': !this.isOpen || !hasOptions,
                    'opacity-0': !this.isOpen || !hasOptions,
                    'opacity-100': this.isOpen && hasOptions,
                }
            },

            valueClass() {
                return {
                    'px-10': true,
                    'flex': true,
                    'items-center': true,
                    'transition': true,
                    'bg-gray-6': !this.isFocused && !this.isOpen,
                    'bg-white': this.isFocused || this.isOpen,
                    'border-1': true,
                    'border-gray-4': true,
                    'h-50': true,
                    'hoverable:hover:shadow-lg': !this.isOpen,
                }
            },

            inputLabel() {
                return this.value ? this.selectedOption.label : this.placeholder
            },

            selectedOption() {
                return this.selectedIndex > -1 ? this.options[this.selectedIndex] : {}
            },

            selectedIndex() {
                return this.options.findIndex(
                    ({ value }) => JSON.stringify(this.value) === JSON.stringify(value),
                )
            },

            hasOptions() {
                return this.optionRefs?.length
            },
        },

        methods: {
            optionClass(index) {
                return {
                    'block': true,
                    'min-h-45': true,
                    'w-full': true,
                    'text-left': true,
                    'p-10': true,
                    'border-t-1': true,
                    'first:border-t-0': true,
                    'border-gray-4': true,
                    'transition-none': true,
                    'bg-purple': index === this.highlightedOption,
                    'text-white': index === this.highlightedOption,
                    'text-black': index !== this.highlightedOption,
                }
            },

            hoverOption(index) {
                this.highlightedOption = index
            },

            scrollToHighlightedOption() {
                if (!this.hasOptions) {
                    return
                }

                this.optionRefs[Math.max(this.highlightedOption, 0)].scrollIntoView({
                    block: 'nearest',
                })
            },

            switchHighlightedOption(direction = 1) {
                if (this.highlightedOption === null) {
                    this.highlightedOption = direction === 1 ? 0 : this.options.length - 1
                } else {
                    const position = this.highlightedOption + direction

                    if (position < 0) {
                        this.highlightedOption = this.options.length - 1
                    } else {
                        this.highlightedOption = position % this.options.length
                    }
                }

                this.scrollToHighlightedOption()
            },

            blur(event) {
                if (event.relatedTarget && !isNodeDescendant(event.relatedTarget, this.$el)) {
                    this.close()
                }

                this.$emit('blur', event)
            },

            focus() {
                this.isFocused = true
                this.highlightedOption = this.selectedIndex
                this.scrollToHighlightedOption()

                this.$refs.input.focus()
                this.$emit('focus', event)
            },

            click() {
                this.$refs.input.focus()
                this.$refs.input.click()
            },

            close() {
                this.isOpen = false
                this.isFocused = false
            },

            toggle() {
                this.isOpen = !this.isOpen
            },

            select(option) {
                this.close()
                this.change(option)

                this.$refs.input.focus()
            },

            handleUpArrow() {
                if (this.isOpen) {
                    this.switchHighlightedOption(-1)
                } else {
                    this.toggle()
                }
            },

            handleDownArrow() {
                if (this.isOpen) {
                    this.switchHighlightedOption(1)
                } else {
                    this.toggle()
                }
            },

            handleEnter() {
                if (this.isOpen && this.hasOptions) {
                    this.select(this.options[this.highlightedOption].value)
                } else {
                    this.toggle()
                }
            },
        },
    }
</script>
