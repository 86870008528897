<template>
    <header class="flex items-center py-30">
        <BaseHeading :heading="$t('pro.profile.availability.heading.preview')" :level="4" />

        <BaseLink class="flex ml-auto" @click="edit">
            <IconModify class="h-18 w-18 mr-10" />
            <span>{{ $t('common.modify') }} </span>
        </BaseLink>
    </header>

    <div class="space-y-20">
        <ProProfileAvailabilityDay
            v-for="(availability, index) in availabilities"
            :key="index"
            :availability="availability"
            :has-labels="index === 0"
        />
    </div>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseLink from '@/components/base/BaseLink'
    import ProProfileAvailabilityDay from '@/components/pro/profile/availability/ProProfileAvailabilityDay'
    import IconModify from '@/assets/vectors/icon-modify.svg'

    export default {
        components: {
            BaseHeading,
            BaseLink,
            ProProfileAvailabilityDay,
            IconModify,
        },

        props: {
            availabilities: { type: Array, required: true },
        },

        emits: ['edit'],

        methods: {
            edit() {
                this.$emit('edit')
            },
        },
    }
</script>
