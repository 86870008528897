<template>
    <button class="flex items-center space-x-10 text-gray-2 px-10" @click="downloadIcs">
        <IconCalendarDownload class="h-25" />
        <BaseLabel :content="$t('guest.appointments.add-to-calendar.cta')" />
    </button>
</template>

<script>
    import { composeFullName } from '@/helpers'
    import * as HelperAppointment from '@/helpers/appointment'
    import BaseLabel from '@/components/base/BaseLabel'
    import IconCalendarDownload from '@/assets/vectors/icon-calendar-download.svg'

    export default {
        components: {
            BaseLabel,
            IconCalendarDownload,
        },

        props: {
            appointments: { type: Array, required: true },
        },

        methods: {
            convertDatetimeToIcal(date) {
                // dayjs format for iCal date. It's similar to ISO but without special characters.
                // - ISO: 2017-05-31T11:46:54.216Z
                // - ICAL: 20170531T114654Z
                return [date.format('YYYYMMDD'), 'T', date.format('HHmmss'), 'Z'].join('')
            },

            createIcs() {
                let lines = [
                    'BEGIN:VCALENDAR',
                    'CALSCALE:GREGORIAN',
                    'METHOD:PUBLISH',
                    'PRODID:-//Test Cal//EN',
                    'VERSION:2.0',
                ]

                this.appointments.forEach((appointment) => {
                    lines = lines.concat([
                        'BEGIN:VEVENT',
                        `UID:${appointment.id}`,
                        'DTSTART;VALUE=DATE:',
                        this.convertDatetimeToIcal(
                            HelperAppointment.appointmentStartDateTime(appointment),
                        ),
                        'DTEND;VALUE=DATE:',
                        this.convertDatetimeToIcal(
                            HelperAppointment.appointmentEndDateTime(appointment),
                        ),
                        'SUMMARY:',
                        this.$t('guest.appointments.add-to-calendar.summary', {
                            pro: composeFullName(appointment.pro),
                        }),
                        // 'DESCRIPTION:',
                        // description,
                        'END:VEVENT',
                    ])
                })

                lines = lines.concat(['END:VCALENDAR']).join('\n')

                const data = new File([lines], { type: 'text/plain' })
                const icsFile = window.URL.createObjectURL(data)

                return icsFile
            },

            downloadIcs() {
                const link = document.createElement('a')

                link.setAttribute('href', this.createIcs())
                link.setAttribute('download', 'events.ics')
                link.style.display = 'none'

                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            },
        },
    }
</script>
