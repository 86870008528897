<template>
    <LayoutApp back-button="/">
        <div v-if="isSubmitted" class="flex-grow flex flex-col justify-center">
            <div class="space-y-30">
                <header class="space-y-20 flex flex-col items-center text-center">
                    <IconSuccess class="h-35 w-35 text-green animate-pop" />
                    <BaseHeading :heading="$t('auth.forgot-password.index.done')" :level="3" />
                </header>

                <BaseMessage type="success">
                    <BaseText
                        :content="$t('auth.forgot-password.index.sent', { email: formData.email })"
                    />
                </BaseMessage>

                <BaseText
                    :content="$t('auth.forgot-password.index.spam')"
                    :is-light="true"
                    class="text-center"
                />
            </div>

            <FormButtons>
                <BaseButton :label="$t('auth.forgot-password.back')" route="/auth/login" />
            </FormButtons>
        </div>

        <template v-else>
            <header class="pb-30 space-y-15">
                <BaseHeaderSection
                    :heading="$t('auth.forgot-password.index.heading')"
                    :subheading="$t('auth.forgot-password.index.subheading')"
                />
            </header>

            <FormWrapper @submit="submitForm">
                <FormFields>
                    <FieldText
                        :label="$t('common.fields.email')"
                        :is-required="true"
                        :has-autofocus="true"
                        :form-data="formData"
                        :form-errors="formErrors"
                        :error="generalError"
                        value-path="email"
                        type="email"
                        @change="change"
                    />
                </FormFields>

                <FormButtons>
                    <BaseButton
                        :label="$t('auth.forgot-password.index.submit')"
                        :is-processing="isSubmitting"
                        :is-disabled="!isSubmittable"
                    />
                </FormButtons>
            </FormWrapper>
        </template>
    </LayoutApp>
</template>

<script>
    import ApiAuth from '@/apis/ApiAuth'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import BaseMessage from '@/components/base/BaseMessage'
    import BaseText from '@/components/base/BaseText'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldText from '@/components/form-fields/FieldText'
    import IconSuccess from '@/assets/vectors/icon-success.svg'

    export default {
        components: {
            LayoutApp,
            BaseHeaderSection,
            BaseHeading,
            BaseButton,
            BaseMessage,
            BaseText,
            FormWrapper,
            FormFields,
            FormButtons,
            FieldText,
            IconSuccess,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },

            isSubmittable() {
                return this.formData.email
            },

            generalError() {
                if (this.formErrors?.message && !this.formErrors?.errors) {
                    return this.formErrors?.message
                }

                return null
            },
        },

        methods: {
            submitForm() {
                this.handleSubmit(ApiAuth.sendResetPasswordEmail(this.formData))
            },
        },
    }
</script>
