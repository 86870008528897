<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="$emit('close')">
        <div class="p-20 space-y-30 text-center">
            <BaseHeading :heading="$t('guest.book.when.place.whos-place')" :level="2" />
            <BaseButton
                :label="$t('guest.book.when.place.my-place')"
                :level="2"
                @click="$emit('select', ['guest'])"
            />
            <BaseButton
                :label="$t('guest.book.when.place.your-place')"
                :level="2"
                @click="$emit('select', ['pro'])"
            />
            <BaseButton
                :label="$t('guest.book.when.place.either')"
                :level="2"
                @click="$emit('select', ['pro', 'guest'])"
            />
        </div>
    </BaseDrawer>
</template>

<script>
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            BaseDrawer,
            BaseHeading,
            BaseButton,
        },

        props: {
            isOpen: { type: Boolean, default: false },
        },

        emits: ['select', 'close'],
    }
</script>
