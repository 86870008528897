<template>
    <LayoutOverlay :back-button="backButton" @back="handleBack">
        <component
            :is="currentStep.component"
            :step-data="formData"
            :is-initial-set-up="isInitialSetUp"
            @change="changeData"
            @go-to="handleGoTo"
            @go-to-start="goToStart"
        >
            <template #buttons="{ isSubmittable }">
                <FormButtons>
                    <BaseButton
                        :label="submitLabel"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                        @click="handleNext"
                    />
                </FormButtons>
            </template>
        </component>
    </LayoutOverlay>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import ProProfileCancellationStep1 from '@/components/pro/profile/cancellation/ProProfileCancellationStep1'
    import ProProfileCancellationStep2 from '@/components/pro/profile/cancellation/ProProfileCancellationStep2'
    import ProProfileCancellationStep3 from '@/components/pro/profile/cancellation/ProProfileCancellationStep3'
    import ProProfileCancellationStep4 from '@/components/pro/profile/cancellation/ProProfileCancellationStep4'
    import ProProfileCancellationStep5 from '@/components/pro/profile/cancellation/ProProfileCancellationStep5'
    import ProProfileCancellationStep6 from '@/components/pro/profile/cancellation/ProProfileCancellationStep6'
    import ProProfileCancellationStep7 from '@/components/pro/profile/cancellation/ProProfileCancellationStep7'
    import ProProfileCancellationStep8 from '@/components/pro/profile/cancellation/ProProfileCancellationStep8'

    export default {
        components: {
            LayoutOverlay,
            FormButtons,
            BaseButton,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormSteppable, MixinProExperiences],

        data() {
            return {
                isInitialSetUp: false,
                rememberedStep: null,
            }
        },

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },

            steps() {
                return [
                    { component: ProProfileCancellationStep1 },
                    { component: ProProfileCancellationStep2 },
                    { component: ProProfileCancellationStep3 },
                    { component: ProProfileCancellationStep4 },
                    { component: ProProfileCancellationStep5 },
                    { component: ProProfileCancellationStep6 },
                    { component: ProProfileCancellationStep7 },
                    { component: ProProfileCancellationStep8 },
                ]
            },

            backButton() {
                if (this.rememberedStep) {
                    return true
                }

                return this.isFirstStep || this.isLastStep ? '/pro/profile' : true
            },

            submitLabel() {
                if (this.rememberedStep) {
                    return this.$t('common.save')
                }

                if (this.isLastStep) {
                    return this.$t('common.go-to-profile')
                }

                if (
                    (this.step === 5 && this.formData.isNoShowSameAsCancellation) ||
                    this.step === 7
                ) {
                    return this.$t('common.save')
                }

                return this.$t('common.next')
            },
        },

        created() {
            this.isInitialSetUp = !(
                this.$auth.getUser().cancellationPolicy && this.$auth.getUser().noShowPolicy
            )

            if (!this.isInitialSetUp) {
                this.step = 8
                this.initForm({
                    cancellationPolicy: this.$auth.getUser().cancellationPolicy,
                    noShowPolicy: this.$auth.getUser().noShowPolicy,
                })
            }
        },

        methods: {
            handleGoTo(slug) {
                const step = {
                    'cancellation-pro': 1,
                    'cancellation-guest': 3,
                    'no-show-pro': 6,
                    'no-show-guest': 7,
                }[slug]

                this.isInitialSetUp = false
                this.rememberedStep = this.step
                this.goToStep(step)
            },

            handleBack() {
                if (this.rememberedStep) {
                    this.goToStep(this.rememberedStep)
                    return (this.rememberedStep = null)
                }

                if (this.step === 5 && this.formData.isGuestCancellationSameAsSalon) {
                    return this.goToStep(3)
                }

                this.goToPreviousStep()
            },

            handleNext() {
                if (this.rememberedStep) {
                    this.rememberedStep = null
                    return this.save()
                }

                if (this.isLastStep) {
                    return this.$router.push('/pro/profile')
                }

                if (this.step === 3 && this.formData.isGuestCancellationSameAsSalon) {
                    return this.goToStep(5)
                }

                if (
                    (this.step === 5 && this.formData.isNoShowSameAsCancellation) ||
                    this.step === 7
                ) {
                    return this.save()
                }

                this.goToNextStep()
            },

            save() {
                return this.handleSubmit(
                    ApiPros.updateCancellationPolicy(this.$auth.getUser().id, this.formData),
                ).then((user) => {
                    this.$store.commit('auth/setUser', user)
                    this.goToStep(8)
                })
            },
        },
    }
</script>
