<template>
    <LayoutApp back-button="/pro/profile">
        <article class="space-y-40 pb-20">
            <ProLocations :pro="pro" class="-mb-20" />

            <ProTile :pro="pro" :level="1" class="pt-1/12 pb-full" />

            <BaseText :content="pro.introduction" />

            <section v-if="licenses.length" class="space-y-30">
                <BaseHeading
                    :heading="$t('pro.page.x-licenses', { name: pro.firstName })"
                    :level="5"
                />

                <div v-for="(license, index) in licenses" :key="index">
                    <ProLicense :license="license" />
                </div>
            </section>

            <section class="space-y-30">
                <BaseHeading
                    :heading="$t('pro.page.x-offers', { name: pro.firstName })"
                    :level="5"
                />

                <template v-for="(category, index) in experienceCategories" :key="index">
                    <header class="flex items-center space-x-10">
                        <component :is="category.icon" class="w-30" />
                        <h5 class="text-20 font-bold">{{ category.name }}</h5>
                    </header>

                    <ProOffering :experiences="category.experiences" />
                </template>
            </section>

            <section v-if="pro.address" class="space-y-20">
                <BaseHeading
                    :heading="$t('pro.page.x-salon', { name: pro.firstName })"
                    :level="4"
                />

                <BaseMapAddress
                    id="salon"
                    :address="pro.address"
                    :is-icon-highlighted="true"
                    class="pb-8/12"
                >
                    <template #address>
                        <b class="uppercase font-bold">{{ brandName }}</b>
                        <br />
                    </template>
                </BaseMapAddress>
            </section>

            <section v-if="portfolio.length > 0" class="space-y-20">
                <BaseHeading :heading="$t('pro.page.x-work', { name: pro.firstName })" :level="4" />

                <ProGallery :items="portfolio" />
            </section>

            <ProTestimonials v-if="portfolio.length > 0" :testimonials="testimonials" />

            <ProPolicies :pro="pro" />
        </article>
    </LayoutApp>
</template>

<script>
    import intersection from 'lodash/intersection'
    import { getCategoryIcon } from '@/helpers'
    import ApiProLicenses from '@/apis/ApiProLicenses'
    import MixinProPreview from '@/mixins/MixinProPreview'
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'

    export default {
        components: {
            LayoutApp,
            BaseHeading,
            BaseText,
        },

        mixins: [MixinProPreview, MixinProExperiences],

        async loadData({ $auth }) {
            const licenses = await ApiProLicenses.get($auth.getUser().id)

            return { licenses }
        },

        computed: {
            pro() {
                return this.$auth.getUser()
            },

            experienceCategories() {
                return this.proCategories.reduce((categories, category) => {
                    const experiences = this.experiencesInCategory(category)

                    if (experiences.length > 0) {
                        categories.push({
                            name: category.name,
                            icon: getCategoryIcon(category),
                            experiences,
                        })
                    }

                    return categories
                }, [])
            },
        },

        methods: {
            experiencesInCategory(category) {
                return this.proExperiences.filter(
                    (experience) =>
                        intersection(
                            category.subCategories.map(({ id }) => id),
                            experience.subCategories.map(({ id }) => id),
                        ).length > 0,
                )
            },
        },
    }
</script>
