import store from '@/store'
import jwtDecode from 'jwt-decode'
import ApiAdmins from '@/apis/ApiAdmins'
import ApiPros from '@/apis/ApiPros'
import ApiGuests from '@/apis/ApiGuests'

export default {
    logIn(token) {
        localStorage.setItem('authToken', token)
    },

    getToken() {
        return localStorage.getItem('authToken')
    },

    getTokenRole() {
        const { role } = jwtDecode(this.getToken())
        return role
    },

    loadUser(force) {
        if (!this.isLoggedIn()) {
            this.logOut(true)
            return Promise.resolve()
        }

        if (this.getUser()?.id && !force) {
            return Promise.resolve()
        }

        const { id } = jwtDecode(this.getToken())

        const apiClass = {
            Admin: ApiAdmins,
            SuperAdmin: ApiAdmins,
            Pro: ApiPros,
            Guest: ApiGuests,
        }[this.getTokenRole()]

        return apiClass.get(id).then((user) => store.commit('auth/setUser', user))
    },

    logOut(isClearingStore = false) {
        localStorage.removeItem('authToken')

        if (isClearingStore) {
            store.commit('auth/clearToken')
            store.commit('auth/clearUser')
            store.commit('ui/alerts/closeAll')
        }
    },

    isLoggedIn() {
        return Boolean(this.getToken())
    },

    isAdmin() {
        return this.isLoggedIn() && this.getTokenRole() === 'Admin'
    },

    isSuperAdmin() {
        return this.isLoggedIn() && this.getTokenRole() === 'SuperAdmin'
    },

    isPro() {
        return this.isLoggedIn() && this.getTokenRole() === 'Pro'
    },

    isGuest() {
        return this.isLoggedIn() && this.getTokenRole() === 'Guest'
    },

    getUser() {
        const result = store.getters['auth/getUser'] || {}
        return result
    },
}
