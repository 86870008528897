<template>
    <LayoutAdmin @select-period="loadNewPros" @click="closeInviteProMenu">
        <div class="h-full p-30 flex flex-col justfy-between">
            <div class="flex flex-col">
                <div class="flex flex-row justify-between">
                    <div class="w-220"></div>
                    <div class="text-center text-16 font-black tracking-widest uppercase">
                        {{ totalPros }} {{ $t('admin.dashboard.newPros.label') }}
                    </div>

                    <InvitePro ref="invitePro" />
                </div>
                <div class="flex flex-row justify-between items-center mt-30">
                    <InputMultiSelect
                        :options="options"
                        :placeholder="$t('admin.dashboard.selectCategory')"
                        :value="filter"
                        :select-all-option="selectAllOption"
                        class="w-250"
                        @change="onFilterChange"
                    />

                    <div class="flex flex-col justify-center items-center">
                        <div class="flex flex-row tracking-wider">
                            <IconMenuPayments class="color-black w-20" />
                            <span class="ml-10 uppercase">
                                {{ $t('admin.dashboard.ticketAverage') }}
                            </span>
                        </div>
                        <span class="text-35 font-bold">$ {{ ticketAverage }}</span>
                    </div>

                    <div class="flex justify-end w-250 cursor-pointer">
                        <IconExport class="w-40" @click="exportNewPros" />
                    </div>
                </div>
            </div>

            <BaseTable ref="table" :headers="tableHeaders" class="mt-30" @goToPage="loadNewPros">
                <tr
                    v-for="(pro, index) in pros"
                    :key="pro.id"
                    class="border-b-1 border-gray-4 cursor-pointer"
                    @click="goToProProfile(pro)"
                >
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <BaseAvatar
                                :photo="pro.profilePicture"
                                class="h-50 min-w-50 w-50 rounded-full"
                            />
                            <div
                                v-if="pro.requiredInfo.length"
                                :ref="`requiredInfoIcon_${index}`"
                                class="z-above -ml-15 -mt-35"
                            >
                                <IconWarning
                                    ref="warning"
                                    class="w-20 h-20 text-notification cursor-pointer"
                                    @mouseenter="
                                        showContextMenu(
                                            $event,
                                            index,
                                            'requiredInfoIcon',
                                            'requiredInfo',
                                            300,
                                        )
                                    "
                                    @mouseleave="closeContextMenu($event, 'requiredInfo')"
                                />
                            </div>
                            <div class="flex flex-col ml-10">
                                <div class="font-bold uppercase">{{ pro.name }}</div>
                                <div class="text-gray-3">
                                    {{ `${$t('admin.dashboard.joinedOn')} ${pro.joinedOn}` }}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="p-5">{{ pro.email }}</td>
                    <td class="p-5">{{ pro.phone }}</td>
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <IconPin v-if="pro.location !== '-'" class="h-22" />
                            <span class="ml-10">{{ pro.location }}</span>
                        </div>
                    </td>
                    <td class="p-5">
                        {{ pro.categories.length > 0 ? pro.categories.join(', ') : '-' }}
                    </td>
                    <td class="p-5">
                        <BaseRating v-if="pro.rating" :rating="pro.rating" :level="1" />
                        <span v-else>-</span>
                    </td>
                    <td class="p-5 text-center">
                        <div
                            v-if="pro.stripeAccountStatus !== 'Enabled'"
                            :ref="`stripeIcon_${index}`"
                            class="flex justify-center"
                        >
                            <IconStripe
                                class="min-w-20 w-20 h-20 cursor-pointer"
                                @mouseenter="
                                    showContextMenu($event, index, 'stripeIcon', 'stripeInfo', 150)
                                "
                                @mouseleave="closeContextMenu($event, 'stripeInfo')"
                            />
                        </div>
                        <div v-else class="flex flex-col ml-10">
                            <div class="font-bold uppercase">{{ pro.bookingsCount }}</div>
                            <div class="text-gray-3">${{ pro.completedBookings }}</div>
                        </div>
                    </td>
                </tr>
            </BaseTable>
        </div>

        <BaseContextMenu
            ref="requiredInfo"
            :width="'350px'"
            :top="contextMenuTop"
            :left="contextMenuLeft"
            :bottom="contextMenuBottom"
        >
            <BaseHeading :heading="$t('admin.dashboard.newPros.requiredInfo.heading')" :level="4" />
            <RequiredInfo :required-infos="currentPro.requiredInfo" />
        </BaseContextMenu>

        <BaseContextMenu
            ref="stripeInfo"
            :width="'350px'"
            :top="contextMenuTop"
            :left="contextMenuLeft"
            :bottom="contextMenuBottom"
        >
            <StripeDetails
                v-if="currentPro"
                :stripe-account-status="currentPro.stripeAccountStatus"
            />
        </BaseContextMenu>
    </LayoutAdmin>
</template>

<script>
    import dayjs from 'dayjs'
    import ApiAdmins from '@/apis/ApiAdmins'
    import IconPin from '@/assets/vectors/icon-pin.svg'
    import BaseTable from '@/components/base/BaseTable'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseRating from '@/components/base/BaseRating'
    import BaseHeading from '@/components/base/BaseHeading'
    import IconExport from '@/assets/vectors/icon-export.svg'
    import IconStripe from '@/assets/vectors/icon-stripe.svg'
    import LayoutAdmin from '@/components/layouts/LayoutAdmin'
    import IconWarning from '@/assets/vectors/icon-warning.svg'
    import BaseContextMenu from '@/components/base/BaseContextMenu'
    import RequiredInfo from '@/components/admin/dashboard/RequiredInfo'
    import StripeDetails from '@/components/admin/dashboard/StripeDetails'
    import IconMenuPayments from '@/assets/vectors/icon-menu-payments.svg'
    import InputMultiSelect from '@/components/form-inputs/InputMultiSelect'
    import InvitePro from '@/components/admin/dashboard/invite-pro/InvitePro'

    export default {
        components: {
            IconPin,
            InvitePro,
            BaseTable,
            IconExport,
            IconStripe,
            BaseRating,
            BaseAvatar,
            BaseHeading,
            LayoutAdmin,
            IconWarning,
            RequiredInfo,
            StripeDetails,
            BaseContextMenu,
            InputMultiSelect,
            IconMenuPayments,
        },

        data() {
            return {
                filter: [],
                totalPros: 0,
                ticketAverage: 0,
                pros: [],
                startDate: dayjs(),
                endDate: dayjs(),
                currentPro: 0,
                contextMenuLeft: '',
                contextMenuTop: '',
                contextMenuBottom: '',
                isInviteProOpen: false,
            }
        },

        computed: {
            selectAllOption() {
                return {
                    label: this.$t('admin.dashboard.all'),
                    value: 'all',
                }
            },

            tableHeaders() {
                return [
                    { label: this.$t('admin.dashboard.table.proName'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.email'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.phone'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.location'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.offers'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.rating'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.completedBookings') },
                ]
            },
            categories() {
                return this.$store.getters['dictionaries/getCategories'].map((category) => ({
                    value: category.id,
                    label: category.name,
                }))
            },

            options() {
                return [this.selectAllOption, ...this.categories]
            },
        },

        created() {
            this.filter = [this.selectAllOption, ...this.categories]
        },

        methods: {
            async onFilterChange(options) {
                await this.loadNewPros({
                    filter: options,
                })
            },

            async loadNewPros({ startDate, endDate, page, filter }) {
                if (startDate) {
                    this.startDate = startDate
                }
                if (endDate) {
                    this.endDate = endDate
                }
                if (filter) {
                    this.filter = filter
                }

                this.$refs.table.currentPage = page ? page : 1
                const result = await ApiAdmins.getNewPros({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    page: this.$refs.table.currentPage,
                    categoryIds: this.filter?.filter(
                        (item) => item.value != this.selectAllOption.value,
                    ),
                })

                this.$refs.table.maxPage = result.maxPage

                this.pros = result.newStylists
                this.totalPros = result.totalStylists
                this.ticketAverage = result.ticketAverage

                this.$refs.table.calculatePages()
            },

            async exportNewPros() {
                const response = await ApiAdmins.exportNewPros({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    categoryIds: this.filter.filter(
                        (item) => item.value != this.selectAllOption.value,
                    ),
                })
                var fileLink = document.createElement('a')

                fileLink.href =
                    'data:text/csv;charset=utf-8, ' + escape(Object.values(response.data).join(''))

                const format = 'DD.MM.YY'
                const start = this.startDate.format(format)
                const end = this.endDate.format(format)
                const fileName = `New_Pros_${start}-${end}.csv`
                fileLink.setAttribute('download', fileName)
                document.body.appendChild(fileLink)

                fileLink.click()
            },

            showContextMenu(event, index, refName, menuRef, maxHeight) {
                event.stopPropagation()

                this.currentPro = this.pros[index]

                this.getContextMenuLeft(index, refName)
                this.getContextMenuTop(index, refName, maxHeight)

                if (this.$refs[menuRef].show) {
                    this.$refs[menuRef].close()
                } else {
                    this.$refs[menuRef].open()
                }
            },

            getContextMenuTop(index, refName, maxHeight) {
                const el = this.$refs[`${refName}_${index}`]
                let top = window.pageYOffset + el.getBoundingClientRect().bottom + 10
                if (top + maxHeight + 10 > window.innerHeight + window.pageYOffset) {
                    this.contextMenuBottom =
                        window.innerHeight -
                        el.getBoundingClientRect().top -
                        window.pageYOffset +
                        10 +
                        'px'
                    this.contextMenuTop = ''
                    return
                }
                this.contextMenuTop = top + 'px'
                this.contextMenuBottom = ''
            },

            getContextMenuLeft(index, refName) {
                const el = this.$refs[`${refName}_${index}`]
                let left = el.getBoundingClientRect().left
                if (left + 350 + 30 > window.innerWidth) {
                    left = window.innerWidth - 350 - 30
                }

                this.contextMenuLeft = left + 'px'
            },

            closeContextMenu(event, menuRef) {
                if (!this.$refs[menuRef].$el.contains(event.target)) {
                    this.$refs[menuRef].close()
                }
            },

            closeInviteProMenu() {
                this.$refs.invitePro?.closeContextMenu()
            },

            goToProProfile(pro) {
                const profileId = pro.userName ? pro.userName : pro.id
                this.$router.push(`/p/${profileId}`)
            },
        },
    }
</script>
