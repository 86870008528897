<template>
    <FormField v-bind="fieldProps">
        <InputRating v-bind="inputProps" @focus="focus" @blur="blur" @change="change" />
    </FormField>
</template>

<script>
    import MixinFormField from '@/mixins/MixinFormField'
    import FormField from '@/components/form-elements/FormField'
    import InputRating from '@/components/form-inputs/InputRating'

    export default {
        components: {
            FormField,
            InputRating,
        },

        mixins: [MixinFormField],
    }
</script>
