<template>
    <LayoutAdmin>
        <div class="flex justify-center mt-80">
            <BaseHeaderDivider
                :heading="$t('admin.usersAndRoles.heading')"
                class="w-11/12 text-16"
            />
        </div>
        <div class="flex flex-row flex-wrap justify-center">
            <div
                v-for="admin in admins"
                :key="admin.email"
                class="w-200 flex flex-col justify-center items-center mt-40"
            >
                <div class="flex items-center mr-10 ml-10">
                    <BaseAvatar :photo="admin.profilePicture" class="h-80 w-80 rounded-full" />
                </div>
                <div class="h-22 font-bold mt-15">
                    {{
                        admin.id == $auth.getUser().id
                            ? $t('admin.usersAndRoles.you')
                            : admin.firstName + ' ' + admin.lastName
                    }}
                </div>
                <div class="h-22 font-bold mt-15 text-gray-3">{{ admin.title }}</div>
                <BaseLink
                    :label="$t('admin.usersAndRoles.modify')"
                    class="inline-block mt-25"
                    @click="openEditUserModal(admin)"
                />
            </div>
        </div>
        <div class="flex flex-col items-center mt-100 mb-50">
            <div class="w-400">
                <BaseButton
                    :label="$t('admin.inviteUser')"
                    :level="1"
                    :is-submitting="false"
                    @click="isInviteUserOpen = true"
                />
            </div>
        </div>
    </LayoutAdmin>

    <EditUser
        v-if="selectedAdmin"
        :admin="selectedAdmin"
        :is-open="isEditUserOpen"
        @close="closeEditUserModal"
        @save="saveUser"
    />

    <InviteUser :is-open="isInviteUserOpen" @close="closeInviteUserModal" @save="inviteUser" />
</template>

<script>
    import ApiAdmins from '@/apis/ApiAdmins'
    import BaseLink from '@/components/base/BaseLink'
    import EditUser from '@/components/admin/EditUser'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import InviteUser from '@/components/admin/InviteUser'
    import LayoutAdmin from '@/components/layouts/LayoutAdmin'
    import BaseButton from '../../components/base/BaseButton.vue'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'

    export default {
        components: {
            BaseLink,
            EditUser,
            BaseAvatar,
            BaseButton,
            InviteUser,
            LayoutAdmin,
            BaseHeaderDivider,
        },

        data() {
            return {
                isEditUserOpen: false,
                isInviteUserOpen: false,
                selectedAdmin: null,
            }
        },

        async loadData() {
            return {
                admins: await ApiAdmins.getAll(),
            }
        },

        methods: {
            openEditUserModal(admin) {
                this.selectedAdmin = admin
                this.isEditUserOpen = true
            },

            closeEditUserModal() {
                this.isEditUserOpen = false
            },

            async saveUser() {
                this.closeEditUserModal()
                this.admins = await ApiAdmins.getAll()
            },

            closeInviteUserModal() {
                this.isInviteUserOpen = false
            },

            async inviteUser() {
                this.closeInviteUserModal()
                this.admins = await ApiAdmins.getAll()
            },
        },
    }
</script>
