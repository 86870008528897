import MixinFormFocusable from '@/mixins/MixinFormFocusable'

export default {
    mixins: [MixinFormFocusable],

    props: {
        value: { default: null },
        hasAutofocus: { type: Boolean, default: false },
        isRequired: { type: Boolean, default: false },
        isDisabled: { type: Boolean, default: false },
        isInvalid: { type: Boolean, default: false },
        isReadonly: { type: Boolean, default: false },
    },

    emits: ['change'],

    methods: {
        change(value) {
            this.$emit('change', value instanceof Event ? value.target.value : value)
        },
    },
}
