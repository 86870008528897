<template>
    <div class="relative">
        <div
            class="flex max-w-8/12 xs:max-w-6/12 md:max-w-400 mx-auto space-x-4 rounded-lg overflow-hidden"
        >
            <div v-for="(icon, index) in [left, right]" :key="index" :class="itemClass(index)">
                <component :is="icon.thin" :class="iconClass" class="hidden md:block" />
                <component :is="icon.thick" :class="iconClass" class="md:hidden" />
            </div>
        </div>

        <IconPinAlt
            class="absolute h-6/12 md:h-5/12 left-6/12 top-0 transform -translate-x-6/12 -translate-y-6/12"
        />
    </div>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingText from '@/components/landing/LandingText'
    import IconPinAlt from '@/assets/vectors/icon-pin-alt.svg'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingText,
            IconPinAlt,
        },

        props: {
            left: { type: Object, required: true },
            right: { type: Object, required: true },
        },

        computed: {
            iconClass() {
                return 'absolute w-6/12 h-6/12 left-6/12 top-6/12 transform -translate-x-6/12 -translate-y-6/12'
            },
        },

        methods: {
            itemClass(index) {
                return {
                    'relative': true,
                    'w-full': true,
                    'pb-6/12': true,
                    'bg-sand-light': index === 0,
                    'bg-clay': index === 1,
                }
            },
        },
    }
</script>
