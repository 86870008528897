import dayjs from 'dayjs'
import { normalizeFormData } from '@/axios/helpers'
import { transformWorkPlace } from '@/apis/transforms/transformProToApi'

export default (data) => {
    const startDate =
        data.startDate &&
        dayjs(data.startDate)
            .startOf('day')
            .format('YYYY-MM-DD')

    const endDate =
        data.endDate &&
        dayjs(data.endDate)
            .startOf('day')
            .format('YYYY-MM-DD')

    const startTime =
        data.startTime &&
        dayjs()
            .startOf('day')
            .hour(data.startTime)
            .format('HH:mm')

    data = normalizeFormData(data || {})

    return {
        zipCode: data.areaZipCode,
        subCategoryIds: data.subCategoryIds,
        startDate,
        endDate,
        startTime,
        workPlace: data.workPlace && transformWorkPlace(data.workPlace),
    }
}
