<script>
    import ApiProClients from '@/apis/ApiProClients'
    import ProClientsAddScreensPicture from '@/components/pro/clients/add/screens/ProClientsAddScreensPicture'

    export default {
        extends: ProClientsAddScreensPicture,

        computed: {
            apiCall() {
                return ApiProClients.updateProfilePicture(
                    this.$auth.getUser().id,
                    this.client.id,
                    this.formData,
                )
            },
        },
    }
</script>
