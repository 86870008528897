<template>
    <LayoutApp back-button="/">
        <div v-if="isLinkInvalid" class="flex-grow flex flex-col justify-center">
            <header class="space-y-20 flex flex-col items-center text-center">
                <BaseHeading :heading="$t('auth.forgot-password.invalid.heading')" :level="3" />

                <BaseText>
                    <p>{{ $t('auth.forgot-password.invalid.info') }}</p>
                    <i18n-t tag="p" keypath="auth.forgot-password.invalid.resend.text">
                        <template #resend>
                            <BaseLink
                                :label="$t('auth.forgot-password.invalid.resend.link')"
                                route="/auth/forgot-password"
                            />
                        </template>
                    </i18n-t>
                </BaseText>
            </header>

            <FormButtons>
                <BaseButton :label="$t('auth.forgot-password.back')" route="/auth/login" />
            </FormButtons>
        </div>

        <div v-else-if="isSubmitted" class="flex-grow flex flex-col justify-center">
            <header class="space-y-20 flex flex-col items-center text-center">
                <IconSuccess class="h-35 w-35 text-green animate-pop" />
                <BaseHeading :heading="$t('auth.forgot-password.reset.changed')" :level="3" />
            </header>

            <FormButtons>
                <BaseButton :label="$t('auth.forgot-password.back')" route="/auth/login" />
            </FormButtons>
        </div>

        <template v-else>
            <header class="pb-30 space-y-15">
                <BaseHeaderSection
                    :heading="$t('auth.forgot-password.reset.heading')"
                    :subheading="$t('auth.forgot-password.reset.subheading')"
                />
            </header>

            <FormWrapper @submit="submitForm">
                <FormFields>
                    <FieldText
                        :label="$t('auth.forgot-password.reset.new')"
                        :has-autofocus="true"
                        :is-required="true"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="password"
                        type="password"
                        @change="change"
                    />
                </FormFields>

                <FormButtons>
                    <BaseButton
                        :label="$t('auth.forgot-password.reset.submit')"
                        :is-processing="isSubmitting"
                        :is-disabled="!isSubmittable"
                    />
                </FormButtons>
            </FormWrapper>
        </template>
    </LayoutApp>
</template>

<script>
    import ApiAuth from '@/apis/ApiAuth'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldText from '@/components/form-fields/FieldText'

    export default {
        components: {
            LayoutApp,
            BaseHeaderSection,
            BaseHeading,
            BaseButton,
            BaseText,
            BaseLink,
            FormWrapper,
            FormFields,
            FormButtons,
            FieldText,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        data() {
            return {
                isLinkInvalid: false,
            }
        },

        async loadData({ $route }) {
            const isLinkInvalid = await ApiAuth.checkResetPasswordToken({
                token: $route.query.token,
            })

            return { isLinkInvalid }
        },

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },

            isSubmittable() {
                return this.formData.password
            },

            generalError() {
                if (this.formErrors?.message && !this.formErrors?.errors) {
                    return this.formErrors?.message
                }

                return null
            },
        },

        methods: {
            submitForm() {
                this.handleSubmit(
                    ApiAuth.resetPassword({
                        ...this.formData,
                        token: this.$route.query.token,
                    }),
                )
            },
        },
    }
</script>
