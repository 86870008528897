<template>
    <div class="relative">
        <InputPhoto :value="file" :is-select-displayed-initially="isEditable" @change="selectFile">
            <template #preview="{ preview }">
                <div class="overflow-hidden w-full pt-full pb-1/12 bg-gray-5 text-gray-3 relative">
                    <IconProfile
                        class="w-6/12 absolute top-6/12 left-6/12 transform -translate-x-6/12 -translate-y-6/12"
                    />
                    <img
                        v-if="preview || profilePicture"
                        :src="preview || profilePicture"
                        class="absolute inset-0 h-full w-full object-cover"
                    />
                </div>
            </template>

            <template #default="{ preview, openSelect }">
                <div class="overflow-hidden w-full pt-full pb-1/12 bg-gray-5 text-gray-3 relative">
                    <IconProfile
                        class="w-6/12 absolute top-6/12 left-6/12 transform -translate-x-6/12 -translate-y-6/12"
                    />
                    <img
                        v-if="preview || profilePicture"
                        :src="preview || profilePicture"
                        class="absolute inset-0 h-full w-full object-cover"
                    />
                </div>

                <footer class="absolute bottom-15 right-15">
                    <BaseLink
                        :label="changeButtonLabel"
                        :class="changeButtonClass"
                        @click="isEditable && openSelect()"
                    />

                    <div v-if="$slots.bottom">
                        <slot name="bottom" />
                    </div>
                </footer>
            </template>
        </InputPhoto>
    </div>
</template>

<script>
    import MixinGuestProfileSection from '@/mixins/MixinGuestProfileSection'
    import InputPhoto from '@/components/form-inputs/InputPhoto'
    import BaseLink from '@/components/base/BaseLink'
    import IconProfile from '@/assets/vectors/icon-profile.svg'

    export default {
        components: {
            InputPhoto,
            BaseLink,
            IconProfile,
        },

        mixins: [MixinGuestProfileSection],

        props: {
            file: { type: Object, default: null },
            isEditable: { type: Boolean, default: true },
        },

        emits: ['select-file'],

        computed: {
            profilePicture() {
                return this.$auth.getUser().profilePicture
            },

            changeButtonLabel() {
                return this.profilePicture
                    ? this.$t('pro.profile.picture.edit')
                    : this.$t('pro.profile.picture.add')
            },

            changeButtonClass() {
                return {
                    'text-white': this.profilePicture,
                    'text-shadow-lg': this.profilePicture,
                }
            },
        },

        methods: {
            selectFile(file) {
                this.$emit('select-file', file)
            },
        },
    }
</script>
