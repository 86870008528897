import MiddlewareRequireLoggedOut from '@/router/middlewares/MiddlewareRequireLoggedOut'

export default [
    {
        path: '/auth/detect',
        view: `auth/detect`,
    },
    {
        path: '/auth/login',
        view: 'auth/login',
        middlewares: [MiddlewareRequireLoggedOut],
    },
    {
        path: '/auth/forgot-password',
        view: 'auth/forgot-password/index',
        middlewares: [MiddlewareRequireLoggedOut],
    },
    {
        path: '/auth/forgot-password/reset',
        view: 'auth/forgot-password/reset',
        middlewares: [MiddlewareRequireLoggedOut],
    },
    {
        path: '/auth/register',
        view: 'auth/register',
        middlewares: [MiddlewareRequireLoggedOut],
    },
    ...['pro', 'guest'].map((type) => ({
        path: `/auth/register/${type}`,
        view: `auth/register/${type}`,
        middlewares: [MiddlewareRequireLoggedOut],
    })),
]
