<template>
    <LayoutLanding>
        <LandingHeader :has-search="true" />
        <LandingLegal
            :heading="$t('landing.terms.heading')"
            :content="$t('landing.terms.content')"
        />
    </LayoutLanding>
</template>

<script>
    import LayoutLanding from '@/components/layouts/LayoutLanding'
    import LandingHeader from '@/components/landing/LandingHeader'
    import LandingLegal from '@/components/landing/LandingLegal'

    export default {
        components: {
            LayoutLanding,
            LandingHeader,
            LandingLegal,
        },
    }
</script>
