import { normalizeFormData } from '@/axios/helpers'

export default (originalData) => {
    const data = normalizeFormData(originalData || {})
    const formData = new FormData()

    formData.append('firstName', data.firstName)
    formData.append('lastName', data.lastName)
    formData.append('phone', data.phone)
    formData.append('email', data.email)

    if (data.address) {
        formData.append('address', JSON.stringify(data.address))
    }

    if (originalData.profilePicture) {
        formData.append('profilePicture', originalData.profilePicture)
    }

    Object.values(data.notes || []).forEach(
        ({ content }) => content && formData.append('notes[]', content),
    )

    Object.values(originalData.images || []).forEach(
        ({ file }) => file && formData.append('images', file),
    )

    return formData
}
