<template>
    <div
        v-show="show"
        class="absolute bg-white z-overlay outline-none cursor-pointer shadow-md"
        :style="styles"
        tabindex="0"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'CmpContextMenu',
        props: {
            top: { type: String, default: '' },
            left: { type: String, default: '' },
            bottom: { type: String, default: '' },
            width: { type: String, default: '300px' },
            padding: { type: String, default: '30px 20px' },
        },
        data() {
            return {
                show: false, // affect display of context menu
            }
        },
        computed: {
            // get position of context menu
            styles() {
                return {
                    top: this.top,
                    left: this.left,
                    bottom: this.bottom,
                    width: this.width,
                    padding: this.padding,
                }
            },
        },
        methods: {
            close() {
                this.show = false
            },
            open() {
                this.show = true
            },
        },
    }
</script>
