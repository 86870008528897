<script>
    import get from 'lodash/get'
    import ProProfileCancellationStep6 from '@/components/pro/profile/cancellation/ProProfileCancellationStep6'

    export default {
        extends: ProProfileCancellationStep6,

        computed: {
            placeSlug() {
                return 'guest'
            },

            typePath() {
                return 'noShowPolicy.guestsPlaceFeeType'
            },

            feePath() {
                return 'noShowPolicy.guestsPlaceFee'
            },

            sameAsLabel() {
                return this.$t('pro.profile.cancellation.policy.same-as-pro')
            },

            sameAsPath() {
                return 'isGuestNoShowSameAsSalon'
            },

            isSameAsDisplayed() {
                return this.isInitialSetUp
            },
        },

        created() {
            const isSameAsAlreadySpecified =
                typeof get(this.formData, this.sameAsPath) === 'boolean'

            if (!this.isInitialSetUp || isSameAsAlreadySpecified) {
                return
            }

            this.changeSameAs({ path: this.sameAsPath, value: true })
        },

        methods: {
            changeSameAs({ path, value }) {
                this.change({
                    path: this.typePath,
                    value: value ? get(this.formData, 'noShowPolicy.prosPlaceFeeType') : null,
                })
                this.change({
                    path: this.feePath,
                    value: value ? get(this.formData, 'noShowPolicy.prosPlaceFee') : null,
                })
                this.change({ path, value })
            },
        },
    }
</script>
