// The code below removes :hover interaction when clicking on the buttons, links and inputs.
// This is to prevent from showing :hover state when clicking or tapping (on mobile device)
// on the element. It does not interfere with navigating the site through TAB key.

document.addEventListener('click', (event) => {
    if (
        // Do nothing if 'click' was invoked by keyboard.
        (event.screenX === 0 && event.screenY === 0) ||
        // In older browsers (eg. IE11), there's no .closest(). Check it first.
        typeof event.target.closest !== 'function'
    ) {
        return
    }

    const elements = ['a', 'button', 'input[type="checkbox"]', 'input[type="radio"]']
    const buttonOrLink = event.target.closest(elements.join(', '))

    if (buttonOrLink) {
        document.activeElement.blur()
        buttonOrLink.blur()
    }
})
