<template>
    <BaseText :content="copy" :is-light="true" class="text-center mb-20" />

    <InputPhoto
        :value="photo"
        :is-select-displayed-initially="true"
        @change="$emit('select', $event)"
    >
        <template #select>
            <slot name="select" />
        </template>

        <template #preview="{ preview }">
            <img :src="preview" />

            <div class="mt-20 space-y-20 flex flex-col items-center pb-20">
                <BaseButton :label="$t('common.submit')" @click="$emit('confirm')" />
                <BaseLink :label="$t('guest.verify.change-photo')" @click="$emit('select')" />
            </div>
        </template>
    </InputPhoto>
</template>

<script>
    import BaseText from '@/components/base/BaseText'
    import BaseButton from '@/components/base/BaseButton'
    import BaseLink from '@/components/base/BaseLink'
    import InputPhoto from '@/components/form-inputs/InputPhoto'

    export default {
        components: {
            BaseText,
            BaseButton,
            BaseLink,
            InputPhoto,
        },

        props: {
            copyDefault: { type: String, required: true },
            copyProvided: { type: String, required: true },
            photo: { type: Object, default: null },
        },

        emits: ['select', 'confirm'],

        computed: {
            isProvided() {
                return !!this.photo
            },

            copy() {
                return this.isProvided ? this.copyProvided : this.copyDefault
            },
        },
    }
</script>
