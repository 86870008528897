<template>
    <section>
        <nav class="flex mb-20 justify-center items-center text-purple font-bold">
            <button
                type="button"
                class="py-10 -my-10 w-full flex justify-end"
                @click="changeMonth(-1)"
            >
                <IconPrev class="w-20 h-20" />
            </button>
            <p class="mx-20">
                {{ monthLabel }}
            </p>
            <button type="button" class="py-10 -my-10 w-full" @click="changeMonth(1)">
                <IconNext class="w-20 h-20" />
            </button>
        </nav>

        <BaseCalendarWeekdays />

        <BaseCalendarDays
            :year="year"
            :month="month"
            :selected-dates="selectedDates"
            :disabled-dates="disabledDates"
            @click-date="$emit('click-date', $event)"
        />
    </section>
</template>

<script>
    import dayjs from 'dayjs'
    import BaseCalendarWeekdays from '@/components/base/BaseCalendarWeekdays'
    import BaseCalendarDays from '@/components/base/BaseCalendarDays'
    import IconPrev from '@/assets/vectors/icon-prev.svg'
    import IconNext from '@/assets/vectors/icon-next.svg'

    export default {
        components: {
            BaseCalendarWeekdays,
            BaseCalendarDays,
            IconPrev,
            IconNext,
        },

        props: {
            year: { type: Number, required: true },
            month: { type: Number, required: true },
            selectedDates: { type: Array, default: () => [] },
            disabledDates: { type: Array, default: () => [] },
        },

        emits: ['change-month', 'click-date'],

        computed: {
            monthLabel() {
                return dayjs()
                    .month(this.month)
                    .format('MMMM')
            },
        },

        methods: {
            changeMonth(direction) {
                const newDate = dayjs()
                    .year(this.year)
                    .month(this.month)
                    .add(direction, 'month')

                this.$emit('change-month', {
                    month: newDate.month(),
                    year: newDate.year(),
                })
            },
        },
    }
</script>
