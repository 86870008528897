<template>
    <GuestProfileSection
        :heading="$t('guest.profile.info.heading')"
        :edit-label="$t('common.modify')"
        :save-label="saveLabel"
        :is-editing="isEditing"
        :is-submitting="isSubmitting"
        :is-disabled="isDisabled"
        :is-submittable="isSubmittable"
        @edit="edit"
        @save="save"
        @cancel="cancel"
    >
        <template #preview>
            <BaseAttributes :attributes="attributes" />
        </template>

        <template v-if="isEditingPassword" #edit>
            <FormFields>
                <FieldText
                    key="password-old"
                    :label="$t('guest.profile.info.password.old')"
                    :has-autofocus="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="oldPassword"
                    type="password"
                    @change="change"
                />

                <FieldText
                    key="password-new"
                    :label="$t('guest.profile.info.password.new')"
                    :has-autofocus="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="newPassword"
                    type="password"
                    @change="change"
                />
            </FormFields>
        </template>

        <template v-else #edit>
            <FormFields>
                <FieldText
                    :label="$t('common.fields.first-name')"
                    :has-autofocus="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="firstName"
                    @change="change"
                />

                <FieldText
                    :label="$t('common.fields.last-name')"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="lastName"
                    @change="change"
                />

                <FieldText
                    :label="$t('common.fields.phone')"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="phone"
                    type="tel"
                    @change="change"
                />

                <FieldText
                    :label="$t('common.fields.password')"
                    :is-readonly="true"
                    :form-data="{ password: '*********' }"
                    value-path="password"
                    type="password"
                >
                    <template #suffix>
                        <BaseLink :label="$t('common.modify')" @click="isEditingPassword = true" />
                    </template>
                </FieldText>

                <BaseNote>
                    <i18n-t keypath="guest.profile.info.email.text">
                        <template #contact>
                            <BaseLink
                                :href="emailHref"
                                :label="$t('guest.profile.info.email.contact')"
                                target="_new"
                            />
                        </template>
                    </i18n-t>
                </BaseNote>

                <UserFormAddress
                    :form-data="formData"
                    :form-errors="formErrors"
                    data-prefix="address."
                    @change="change"
                />
            </FormFields>
        </template>
    </GuestProfileSection>
</template>

<script>
    import ApiGuests from '@/apis/ApiGuests'
    import MixinGuestProfileSection from '@/mixins/MixinGuestProfileSection'
    import BaseAttributes from '@/components/base/BaseAttributes'
    import BaseNote from '@/components/base/BaseNote'
    import BaseLink from '@/components/base/BaseLink'
    import FormFields from '@/components/form-elements/FormFields'
    import FieldText from '@/components/form-fields/FieldText'
    import UserFormAddress from '@/components/user/UserFormAddress'

    export default {
        components: {
            BaseAttributes,
            BaseNote,
            BaseLink,
            FormFields,
            FieldText,
            UserFormAddress,
        },

        mixins: [MixinGuestProfileSection],

        data() {
            return {
                isEditingPassword: false,
            }
        },

        computed: {
            isSubmittable() {
                if (this.isEditingPassword) {
                    return !!(this.formData.oldPassword && this.formData.newPassword)
                }

                return this.isEditingAddress
                    ? !!(
                          this.formData.firstName &&
                          this.formData.lastName &&
                          this.formData.phone &&
                          this.formData.address?.address &&
                          this.formData.address?.city &&
                          this.formData.address?.zipCode &&
                          this.formData.address?.state
                      )
                    : !!(this.formData.firstName && this.formData.lastName && this.formData.phone)
            },

            isEditingAddress() {
                return (
                    this.formData.address?.address ||
                    this.formData.address?.city ||
                    this.formData.address?.zipCode ||
                    this.formData.address?.state
                )
            },

            saveLabel() {
                return this.isEditingPassword
                    ? this.$t('guest.profile.info.save.password')
                    : this.$t('guest.profile.info.save.info')
            },

            attributes() {
                return [
                    {
                        label: this.$t('common.fields.first-name'),
                        value: this.$auth.getUser().firstName,
                        align: 'left',
                    },
                    {
                        label: this.$t('common.fields.last-name'),
                        value: this.$auth.getUser().lastName,
                        align: 'right',
                    },
                    {
                        label: this.$t('common.fields.email'),
                        value: this.$auth.getUser().email,
                        align: 'left',
                    },
                    {
                        label: this.$t('common.fields.password'),
                        value: '*********',
                        align: 'right',
                    },
                    {
                        label: this.$t('common.fields.phone'),
                        value: this.$auth.getUser().phone,
                        align: 'left',
                    },
                    {
                        label: this.$t('common.fields.address'),
                        value: this.$auth.getUser().address
                            ? `
                                ${this.$auth.getUser().address?.address},
                                ${this.$auth.getUser().address?.zipCode}
                                ${this.$auth.getUser().address?.city},
                                ${this.$auth.getUser().address?.state}
                            `
                            : null,
                        isWide: true,
                        align: 'left',
                    },
                ]
            },

            emailHref() {
                const email = process.env.VUE_APP_CONTACT_EMAIL
                const subject = this.$t('guest.profile.info.email.message.subject')
                const body = this.$t('guest.profile.info.email.message.body')

                return `mailto:${email}?subject=${subject}&body=${body}`
            },
        },

        watch: {
            isEditingPassword() {
                this.focusAutofocusInput()
            },
        },

        methods: {
            apiCall() {
                if (this.isEditingPassword) {
                    return ApiGuests.update(this.$auth.getUser().id, 'password', {
                        oldPassword: this.formData.oldPassword,
                        newPassword: this.formData.newPassword,
                    })
                }

                return this.isEditingAddress
                    ? ApiGuests.update(this.$auth.getUser().id, 'accountInfo', {
                          firstName: this.formData.firstName,
                          lastName: this.formData.lastName,
                          phone: this.formData.phone,
                      })
                          .then(() =>
                              ApiGuests.update(
                                  this.$auth.getUser().id,
                                  'address',
                                  this.formData.address,
                              ),
                          )
                          .then(() => this.$auth.loadUser(true))
                    : ApiGuests.update(this.$auth.getUser().id, 'accountInfo', {
                          firstName: this.formData.firstName,
                          lastName: this.formData.lastName,
                          phone: this.formData.phone,
                      }).then(() => this.$auth.loadUser(true))
            },
        },
    }
</script>
