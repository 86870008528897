import ServiceAuth from '@/services/ServiceAuth'

export default function(to, from, next) {
    ServiceAuth.loadUser().then(() => {
        ServiceAuth.isLoggedIn()
            ? next()
            : next({
                  path: '/auth/login',
                  query: { redirect: to.fullPath !== '/' ? to.fullPath : undefined },
              })
    })
}
