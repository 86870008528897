<template>
    <GuestBookStep :heading="heading" :has-progress="hasProgress">
        <template #default>
            <div class="space-y-20">
                <BaseMessage v-if="!$auth.getUser().isEmailVerified" type="success">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-html="$t('guest.book.confirmed.note')" />
                </BaseMessage>

                <ProAddress :pro="appointment.pro" :address="appointmentAddress" />
                <BaseDatetime :datetime="appointmentStartDateTime" />
                <BaseReceipt :positions="appointmentPrices(true)" />

                <BaseNote :content="$t('guest.book.charge-info')" />
            </div>
        </template>

        <template #bottom>
            <BaseButton
                :label="$t('guest.book.confirmed.go-to')"
                route="/guest/appointments/future"
            />
        </template>
    </GuestBookStep>

    <GuestBookStep11Message
        :appointment="appointment"
        :is-open="isMessagePanelOpen"
        @close="isMessagePanelOpen = false"
    />
</template>

<script>
    import MixinBookStep from '@/mixins/MixinBookStep'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import ProAddress from '@/components/pro/ProAddress'
    import BaseDatetime from '@/components/base/BaseDatetime'
    import BaseReceipt from '@/components/base/BaseReceipt'
    import BaseMessage from '@/components/base/BaseMessage'
    import BaseNote from '@/components/base/BaseNote'
    import BaseButton from '@/components/base/BaseButton'
    import GuestAlertVerifyId from '@/components/guest/alerts/GuestAlertVerifyId'
    import GuestBookStep11Message from '@/components/guest/book/step-11/GuestBookStep11Message'

    export default {
        components: {
            ProAddress,
            BaseDatetime,
            BaseReceipt,
            BaseMessage,
            BaseNote,
            BaseButton,
            GuestBookStep11Message,
        },

        mixins: [MixinBookStep, MixinAppointment],

        data() {
            return {
                isMessagePanelOpen: false,
            }
        },

        computed: {
            heading() {
                return this.$t('guest.book.confirmed.heading')
            },
        },

        mounted() {
            this.isMessagePanelOpen = !!this.appointment.pro.locationQuestions

            const isIDVerificationRequired =
                this.appointment.workPlace === 'guest' &&
                this.$auth.getUser().isIdVerified !== 'Verified'

            if (isIDVerificationRequired) {
                this.$ui.alerts.open('verify-id', GuestAlertVerifyId)
            }
        },
    }
</script>
