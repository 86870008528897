<template>
    <ProSection :heading="heading" :description="description">
        <FormWrapper>
            <InputPhoto
                v-show="!isFileSelected"
                :is-select-displayed-initially="true"
                :value="formData.file"
                @change="selectFile"
                @preview="loadPreview"
            />

            <FormFields v-if="isFileSelected">
                <ProWork
                    v-if="formData.picture"
                    :picture="formData.picture"
                    :is-picture-deletable="isPictureDeletable"
                    @delete-picture="removeFile"
                />

                <FieldText
                    :placeholder="$t('pro.profile.experiences.experience.steps.3.add-label')"
                    :has-autofocus="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="title"
                    @change="change"
                />
            </FormFields>

            <slot name="buttons" :is-submittable="isSubmittable" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import ProWork from '@/components/pro/ProWork'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import InputPhoto from '@/components/form-inputs/InputPhoto'
    import FieldText from '@/components/form-fields/FieldText'

    export default {
        components: {
            ProSection,
            ProWork,
            FormWrapper,
            FormFields,
            InputPhoto,
            FieldText,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isSubmittable() {
                return this.isFileSelected
            },

            isFileSelected() {
                return !!this.formData.file
            },

            isPictureDeletable() {
                return true
            },

            heading() {
                return this.isFileSelected ? null : this.$t('pro.profile.portfolio.steps.1.heading')
            },

            description() {
                return this.isFileSelected
                    ? null
                    : this.$t('pro.profile.portfolio.steps.1.description')
            },
        },

        methods: {
            selectFile(file) {
                this.change({ path: 'file', value: file })
                this.focusAutofocusInput()
            },

            removeFile() {
                this.change({ path: 'file', value: null })
                this.change({ path: 'preview', value: null })
                this.change({ path: 'title', value: null })
            },

            loadPreview(preview) {
                this.change({ path: 'picture', value: preview })
            },
        },
    }
</script>
