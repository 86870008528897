<template>
    <FormField v-bind="rateFieldProps">
        <InputAmount v-bind="rateInputProps" @focus="focus" @blur="blur" @change="change" />
    </FormField>
</template>

<script>
    import MixinFormField from '@/mixins/MixinFormField'
    import FormField from '@/components/form-elements/FormField'
    import InputAmount from '@/components/form-inputs/InputAmount'

    export default {
        components: {
            FormField,
            InputAmount,
        },

        mixins: [MixinFormField],

        props: {
            placeholder: { type: [String, Number], default: 0 },
        },

        computed: {
            rateFieldProps() {
                return {
                    ...this.fieldProps,
                    isLabelFixed: false,
                    isErrorLower: false,
                }
            },

            rateInputProps() {
                return {
                    ...this.inputProps,
                    placeholder: this.placeholder,
                }
            },
        },
    }
</script>
