<template>
    <button :class="verificationClass" @click="performAction(verificationData)">
        <component
            :is="verificationData.icon"
            :class="verificationData.iconColor"
            class="h-20 w-20 my-3"
        />
        <span class="text-gray-2">{{ verificationData.label }}</span>
    </button>
</template>

<script>
    import IconVerifyValid from '@/assets/vectors/icon-verify-valid.svg'
    import IconTime from '@/assets/vectors/icon-time.svg'
    import IconWarning from '@/assets/vectors/icon-warning.svg'

    export default {
        props: {
            guest: { type: Object, required: true },
            isWrappable: { type: Boolean, default: false },
        },

        computed: {
            verificationClass() {
                return {
                    'leading-none': true,
                    'flex-wrap': this.isWrappable,
                    'text-12': this.isWrappable,
                    'flex': !this.isWrappable,
                    'justify-center': !this.isWrappable,
                    'items-center': !this.isWrappable,
                    'text-14': !this.isWrappable,
                    'space-x-10': !this.isWrappable,
                }
            },

            verificationData() {
                const status = this.guest.isIdVerified

                switch (status) {
                    case 'PendingManualVerification':
                        return {
                            label: this.$t('guest.verify.status-label.reviewing'),
                            icon: IconTime,
                            iconColor: 'text-orange',
                            action: this.showReviewing,
                        }
                    case 'Verified':
                        return {
                            label: this.$t('guest.verify.status-label.verified'),
                            icon: IconVerifyValid,
                            iconColor: 'text-green-2',
                        }
                    default:
                        // case 'NotVerified':
                        // case 'VerificationFailed':
                        return {
                            label: this.$t('guest.verify.status-label.required'),
                            icon: IconWarning,
                            iconColor: 'text-red',
                            action: this.showRequired,
                        }
                }
            },
        },

        methods: {
            performAction(data) {
                if (this.$auth.getUser().id !== this.guest.id || !data.action) {
                    return
                }

                data.action()
            },

            showRequired() {
                this.$ui
                    .confirm({
                        message: this.$t('guest.verify.details.required.message'),
                        note: this.$t('guest.verify.details.required.note'),
                        confirm: this.$t('guest.verify.details.required.start'),
                        cancel: this.$t('guest.verify.details.required.close'),
                    })
                    .then((isConfirmed) => isConfirmed && this.$router.push('/guest/verify'))
            },

            showReviewing() {
                this.$ui.confirm({
                    message: this.$t('guest.verify.details.reviewing.message'),
                    note: this.$t('guest.verify.details.reviewing.note'),
                    confirm: false,
                    cancel: this.$t('guest.verify.details.reviewing.close'),
                })
            },
        },
    }
</script>
