<template>
    <BaseDrawer :is-open="isOpen" slide-from="right" @close="close">
        <div class="flex min-w-300 flex-col p-30 space-y-20">
            <button class="p-20 ml-auto -m-30 hoverable:hover:text-purple" @click="close">
                <IconClose class="w-30 h-30" />
            </button>

            <BaseLinks :items="menuLinks" class="uppercase text-14" @click-item="clickItem">
                <template #label="{ item }">
                    <template v-if="item.isComingSoon">
                        <span class="whitespace-nowrap">{{ item.label }}</span>

                        <div class="ml-auto pl-10">
                            <BaseTag
                                :label="$t('common.menu.coming-soon')"
                                class="whitespace-nowrap border-gray-4 text-gray-4"
                            />
                        </div>
                    </template>
                </template>
            </BaseLinks>

            <div class="border-b-1 border-gray-4" />

            <template v-if="$auth.isLoggedIn()">
                <div class="flex items-center space-x-15">
                    <BaseAvatar
                        :photo="$auth.getUser().profilePicture"
                        class="h-60 w-60 rounded-full"
                    />
                    <div class="space-y-5">
                        <p class="font-bold">
                            {{ $t('pro.menu.welcome', { name: $auth.getUser().firstName }) }}
                        </p>
                        <BaseLink
                            :label="$t('pro.menu.profile')"
                            :route="profileRoute"
                            class="inline-block"
                        />
                    </div>
                </div>

                <BaseLinks :items="userLinks" class="uppercase text-14" @click-item="clickItem" />
            </template>

            <div v-else class="space-y-20">
                <RouterLink
                    to="/auth/login"
                    class="font-bold text-24 text-center h-30 flex items-center justify-center"
                >
                    <span>{{ $t('common.sign-in') }}</span>
                </RouterLink>
                <BaseButton :label="$t('common.sign-up')" route="/auth/register" />
            </div>
        </div>
    </BaseDrawer>
</template>

<script>
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseLink from '@/components/base/BaseLink'
    import BaseLinks from '@/components/base/BaseLinks'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseButton from '@/components/base/BaseButton'
    import BaseTag from '@/components/base/BaseTag'
    import IconClose from '@/assets/vectors/icon-close.svg'

    export default {
        components: {
            BaseDrawer,
            BaseLink,
            BaseLinks,
            BaseAvatar,
            BaseButton,
            BaseTag,
            IconClose,
        },

        props: {
            isOpen: { type: Boolean, default: false },
        },

        emits: ['close'],

        computed: {
            menuLinks() {
                if (this.$auth.isPro()) {
                    return [
                        {
                            label: this.$t('common.menu.marketing-tools'),
                            href: '#',
                            isComingSoon: true,
                        },
                        {
                            label: this.$t('common.menu.masterclass'),
                            href: '#',
                            isComingSoon: true,
                        },
                        {
                            label: this.$t('common.menu.my-community'),
                            href: '#',
                            isComingSoon: true,
                        },
                        { label: this.$t('common.menu.ecommerce'), href: '#', isComingSoon: true },
                        { label: this.$t('common.menu.support'), route: '/contact' },
                    ]
                }

                if (this.$auth.isGuest()) {
                    return [
                        ...this.$store.getters['dictionaries/getCategories'].map((category) => ({
                            label: category.name,
                            route: { path: '/book', query: { category: category.id } },
                        })),
                        { label: this.$t('common.menu.support'), route: '/contact' },
                    ]
                }

                return [
                    ...this.$store.getters['dictionaries/getCategories'].map((category) => ({
                        label: category.name,
                        route: { path: '/book', query: { category: category.id } },
                    })),
                    { label: this.$t('common.menu.support'), route: '/contact' },
                    { label: this.$t('common.menu.are-you-pro'), route: '/for-pros' },
                ]
            },

            userLinks() {
                if (this.$auth.isPro()) {
                    return [
                        {
                            label: this.$t('common.menu.appointments'),
                            route: '/pro/appointments/previous',
                            badge: this.$store.getters['appointments/getUnread']?.length,
                        },
                        { label: this.$t('common.sign-out'), event: this.logOut },
                    ]
                }

                if (this.$auth.isGuest()) {
                    return [
                        {
                            label: this.$t('common.menu.appointments'),
                            route: '/guest/appointments/future',
                        },
                        { label: this.$t('common.sign-out'), event: this.logOut },
                    ]
                }

                return []
            },

            profileRoute() {
                return this.$auth.isPro() ? '/pro/profile' : '/guest/profile'
            },
        },

        methods: {
            close() {
                this.$emit('close')
            },

            clickItem(item) {
                if (item.route && this.$route.path === (item.route?.path || item.route)) {
                    this.close()
                }

                item.event && item.event()
            },

            logOut() {
                this.$auth.logOut()
                this.close()
                this.$router.push('/')
            },
        },
    }
</script>
