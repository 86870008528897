<template>
    <RouterLink v-if="route" :to="route" :class="linkClass" :target="target">
        <slot>{{ label }}</slot>
    </RouterLink>
    <a v-else-if="href" :href="href" :class="linkClass" :target="target">
        <slot>{{ label }}</slot>
    </a>
    <button v-else :class="linkClass" type="button" @click="$emit('click', $event)">
        <slot>{{ label }}</slot>
    </button>
</template>

<script>
    export default {
        props: {
            label: { type: String, default: null },
            route: { type: [Object, String], default: null },
            href: { type: String, default: null },
            target: { type: String, default: null },
        },

        emits: ['click'],

        computed: {
            linkClass() {
                // TODO: Add hover effect for links.
                return 'text-purple underline font-bold'
            },
        },
    }
</script>
