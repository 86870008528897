<template>
    <GuestBookStep
        :heading="$t('guest.book.message.heading', { name: appointment.pro.firstName })"
        :has-progress="hasProgress"
        @submit="saveAndGoToNextStep"
    >
        <template #default>
            <BaseHeaderDivider
                :heading="$t('guest.book.message.label', { name: appointment.pro.firstName })"
                class="mb-30"
            />

            <FieldTextarea
                :placeholder="$t('guest.book.message.placeholder')"
                :has-autofocus="true"
                :form-data="formData"
                :rows="8"
                :has-bottom-border="false"
                value-path="appointment.message"
                @change="change"
            />
        </template>

        <template #bottom>
            <BaseButton :label="bookLabel" />
        </template>
    </GuestBookStep>
</template>

<script>
    import MixinBookStep from '@/mixins/MixinBookStep'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseButton from '@/components/base/BaseButton'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'

    export default {
        components: {
            BaseHeaderDivider,
            BaseButton,
            FieldTextarea,
        },

        mixins: [MixinBookStep],

        computed: {
            bookLabel() {
                return this.$t('guest.book.message.book-with', {
                    name: this.appointment.pro.firstName,
                })
            },
        },

        methods: {
            saveAndGoToNextStep() {
                if (this.$auth.isLoggedIn()) {
                    return this.goToNextStep()
                }

                this.$store.commit('continuity/setBook', this.formData)
                this.$router.push('/auth/register/guest')
            },
        },
    }
</script>
