<template>
    <div class="space-y-20 mb-20">
        <BaseLink v-if="isSelectable" :label="toggleLabel" @click="toggleAllClients" />

        <ProClientsIndexClient
            v-for="(client, index) in clients"
            :key="index"
            :client="client"
            :is-selectable="isSelectable"
            :is-selected="isSelectable && isClientSelected(client)"
            @toggle-select="toggleClient(client)"
        />
    </div>
</template>

<script>
    import BaseLink from '@/components/base/BaseLink'
    import ProClientsIndexClient from '@/components/pro/clients/index/ProClientsIndexClient'

    export default {
        components: {
            BaseLink,
            ProClientsIndexClient,
        },

        props: {
            clients: { type: Array, required: true },
            isSelectable: { type: Boolean, default: false },
        },

        data() {
            return {
                selectedIds: [],
            }
        },

        computed: {
            areAllClientsSelected() {
                return !this.clients.some((client) => !this.isClientSelected(client))
            },

            toggleLabel() {
                return this.areAllClientsSelected
                    ? this.$t('common.deselect-all')
                    : this.$t('common.select-all')
            },
        },

        methods: {
            toggleAllClients() {
                this.selectedIds = this.areAllClientsSelected
                    ? []
                    : this.clients.map(({ id }) => id)
            },

            toggleClient(client) {
                this.isClientSelected(client)
                    ? (this.selectedIds = this.selectedIds.filter((id) => id !== client.id))
                    : this.selectedIds.push(client.id)
            },

            isClientSelected({ id }) {
                return this.selectedIds.includes(id)
            },
        },
    }
</script>
