<template>
    <BaseLink :route="editRoute" class="inline-flex space-x-10 mx-auto">
        <IconModify class="h-20 w-20" />
        <span>{{ $t('common.modify') }}</span>
    </BaseLink>
</template>

<script>
    import BaseLink from '@/components/base/BaseLink'
    import IconModify from '@/assets/vectors/icon-modify.svg'

    export default {
        components: {
            BaseLink,
            IconModify,
        },

        props: {
            category: { type: Object, required: true },
        },

        computed: {
            editRoute() {
                return `/pro/profile/experiences/categories/${this.category.id}/edit`
            },
        },
    }
</script>
