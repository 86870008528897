<template>
    <div class="h-5 bg-gray-5 flex">
        <div :style="barStyle" class="h-full bg-green transition-all duration-150 ease-out" />
    </div>
</template>

<script>
    export default {
        props: {
            percentage: { type: Number, required: true },
        },

        computed: {
            barStyle() {
                return {
                    width: `${this.percentage}%`,
                }
            },
        },
    }
</script>
