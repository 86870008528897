<script>
    import ApiProExperiences from '@/apis/ApiProExperiences'
    import PagesProProfileExperiencesAdd from '@/pages/pro/profile/experiences/add'

    const INITIAL_STEP = 10

    export default {
        extends: PagesProProfileExperiencesAdd,

        loadData({ $auth, $route }) {
            const experience = $auth.getUser().experiences.find(({ id }) => id == $route.params.id)

            if (!experience) {
                // TODO: Throw error if category does not exist.
            }

            return { experience }
        },

        data() {
            return {
                step: INITIAL_STEP,
            }
        },

        computed: {
            backButton() {
                return this.rememberedStep ? true : '/pro/profile/experiences'
            },

            submitLabel() {
                return this.step !== INITIAL_STEP ? this.$t('common.save') : null
            },
        },

        created() {
            this.initForm({
                ...this.experience,
                subCategoryIds: this.experience.subCategories.map(({ id }) => id),
            })
        },

        methods: {
            handleGoTo(slug) {
                const step = {
                    'name': 1,
                    'description': 2,
                    'picture': 3,
                    'duration': 4,
                    'price-pro': 6,
                    'price-guest': 8,
                    'subcategories': 9,
                }[slug]

                this.rememberedStep = this.step
                this.goToStep(step)
            },

            handleBack() {
                this.goToStep(this.rememberedStep)
                this.rememberedStep = null
            },

            handleNext() {
                this.save()
                this.rememberedStep = null
            },

            save() {
                return this.handleSubmit([
                    ApiProExperiences.update(
                        this.$auth.getUser().id,
                        this.experience.id,
                        this.formData,
                    ).then(() => this.$auth.loadUser(true)),
                ]).then(() => this.goToStep(INITIAL_STEP))
            },
        },
    }
</script>
