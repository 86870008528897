<template>
    <ProSection :heading="$t('pro.clients.show.screens.note.heading')">
        <FormWrapper>
            <FormFields>
                <FieldTextarea
                    :placeholder="$t('pro.clients.show.screens.note.placeholder')"
                    :has-autofocus="true"
                    :rows="5"
                    :has-bottom-border="false"
                    :form-data="formData"
                    value-path="note"
                    @change="change"
                />
            </FormFields>

            <FormButtons>
                <BaseButton
                    :label="$t('common.save')"
                    :is-processing="isSubmitting"
                    :is-disabled="!isSubmittable"
                    @click="save"
                />
            </FormButtons>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinProClientsScreen from '@/mixins/MixinProClientsScreen'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'

    export default {
        components: {
            FieldTextarea,
        },

        mixins: [MixinProClientsScreen],

        computed: {
            isSubmittable() {
                return this.formData?.note
            },

            noteIndex() {
                return this.screenData.index
            },

            changes() {
                return [{ path: `notes.${this.noteIndex}`, value: this.formData }]
            },
        },

        created() {
            this.initForm(this.screenData.note)
        },
    }
</script>
