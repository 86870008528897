<template>
    <ProSection :heading="$t('pro.profile.experiences.experience.steps.1.heading')">
        <FormWrapper>
            <FieldText
                :placeholder="$t('pro.profile.experiences.experience.steps.1.placeholder')"
                :has-autofocus="true"
                :form-data="formData"
                :form-errors="formErrors"
                value-path="name"
                @change="change"
            />

            <slot name="buttons" :is-submittable="isSubmittable">
                <FormButtons>
                    <BaseButton
                        :label="$t('common.save')"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                        @click="save"
                    />
                </FormButtons>
            </slot>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldText from '@/components/form-fields/FieldText'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormButtons,
            FieldText,
            BaseButton,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isSubmittable() {
                return !!this.formData.name
            },
        },
    }
</script>
