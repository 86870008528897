<template>
    <ProSection :heading="heading" :description="description">
        <template #top>
            <component :is="placeIcon" class="h-50 w-50 mx-auto" />
        </template>

        <template #default>
            <FormWrapper>
                <BaseRow :label="formData.name">
                    <FieldAmount
                        :has-autofocus="true"
                        :form-data="formData"
                        :value-path="valuePath"
                        @change="change"
                    />
                </BaseRow>

                <slot name="buttons" :is-submittable="isSubmittable" />
            </FormWrapper>
        </template>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FieldAmount from '@/components/form-fields/FieldAmount'
    import BaseRow from '@/components/base/BaseRow'
    import IconPro from '@/assets/vectors/icon-pro.svg'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FieldAmount,
            BaseRow,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isSubmittable() {
                return !!this.formData[this.valuePath]
            },

            heading() {
                return this.$t('pro.profile.addons.steps.5.heading', {
                    name: this.formData.name,
                })
            },

            description() {
                return this.$t('pro.profile.addons.steps.5.description')
            },

            valuePath() {
                return 'prosPlacePrice'
            },

            placeIcon() {
                return IconPro
            },
        },
    }
</script>
