<script>
    import ApiProLicenses from '@/apis/ApiProLicenses'
    import PagesProProfileLicensesAdd from '@/pages/pro/profile/licenses/add'

    export default {
        extends: PagesProProfileLicensesAdd,

        async loadData({ $auth, $route }) {
            const license = await ApiProLicenses.getOne($auth.getUser().id, $route.params.id)

            if (!license) {
                // TODO: Throw error if license does not exist.
            }

            return { license }
        },

        created() {
            this.initForm(this.license)
        },
    }
</script>
