import MixinForm from '@/mixins/MixinForm'
import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'

export default {
    props: {
        stepData: { type: Object, default: null },
    },

    mixins: [MixinForm, MixinFormSubmittable],

    emits: ['change', 'go-to-start', 'go-to', 'go-to-previous', 'go-to-next'],

    watch: {
        formData: {
            deep: true,
            handler(formData) {
                this.$emit('change', formData)
            },
        },
    },

    created() {
        this.initForm(this.stepData)
    },

    methods: {
        goToStep(step) {
            this.$emit('go-to', step)
        },

        goToPreviousStep() {
            this.$emit('go-to-back')
        },

        goToNextStep() {
            this.$emit('go-to-next')
            window.scrollTo({ top: 0, left: 0 })
        },

        goToStart() {
            this.$emit('go-to-start')
        },
    },
}
