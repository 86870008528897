import { normalizeFormData } from '@/axios/helpers'

export default (originalData) => {
    const data = normalizeFormData(originalData || {})
    const formData = new FormData()

    formData.append('file', originalData.file)
    data.title && formData.append('title', data.title)
    formData.append('experienceIds[]', JSON.stringify(data.experienceIds || []))

    return formData
}
