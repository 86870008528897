<template>
    <div class="flex flex-col justify-between items-center space-y-10">
        <BaseAvatar :photo="rating.guest?.profilePicture" class="h-50 w-50 rounded-full" />
        <IconQuoteMark class="h-22 text-gold" />
        <div>{{ rating.note }}</div>
        <div>
            <span class="font-bold text-14">{{ rating.guest?.shortName }}</span>
            <span class="text-11 text-gray-1"> | {{ rating.city }}, </span>
            <span class="text-11 text-gray-1">{{ rating.date }}</span>
        </div>
        <BaseRating :rating="rating.rating" :level="1" />
    </div>
</template>

<script>
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseRating from '@/components/base/BaseRating'
    import IconQuoteMark from '@/assets/vectors/icon-quote-mark.svg'

    export default {
        components: {
            BaseAvatar,
            BaseRating,
            IconQuoteMark,
        },

        props: {
            rating: { type: Object, required: true },
        },
    }
</script>
