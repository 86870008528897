<template>
    <GuestBookStep
        :heading="$t('guest.book.when.heading')"
        :subheading="$t('guest.book.when.subheading')"
        :has-progress="hasProgress"
        @submit="isPlaceSelectorOpen = true"
    >
        <template #top>
            <GuestBookStep3When
                :options="whenOptions"
                :current="formData.search.when"
                class="pb-15"
                @select="selectWhen"
            />
        </template>

        <template #default>
            <template v-if="isCalendarDisplayed">
                <BaseCalendarList
                    :selected-dates="selectedDates"
                    :disabled-dates="disabledDates"
                    @click-date="clickDate"
                />
            </template>
        </template>

        <template v-if="isBottomOpen" #bottom>
            <GuestBookStep3Dates
                v-if="displayedDates.length"
                :dates="displayedDates"
                :is-cancelable="areDatesCancelable"
                class="pt-10"
                @cancel="cancelRange"
            />

            <template v-if="isTimeSwitchOpen">
                <div class="w-full h-1 bg-gray-5 relative -top-13 -mb-25" />

                <GuestBookStep3Time :value="formData.search.startTime" @change="changeTime" />
            </template>

            <BaseButton :label="$t('common.continue')" :is-disabled="!isSubmittable" />
        </template>
    </GuestBookStep>

    <GuestBookStep3Place
        :is-open="isPlaceSelectorOpen"
        @select="saveAndGoToNextStep"
        @close="isPlaceSelectorOpen = false"
    />
</template>

<script>
    import dayjs from 'dayjs'
    import MixinBookStep from '@/mixins/MixinBookStep'
    import GuestBookStep3When from '@/components/guest/book/step-3/GuestBookStep3When'
    import GuestBookStep3Dates from '@/components/guest/book/step-3/GuestBookStep3Dates'
    import GuestBookStep3Place from '@/components/guest/book/step-3/GuestBookStep3Place'
    import GuestBookStep3Time from '@/components/guest/book/step-3/GuestBookStep3Time'
    import BaseButton from '@/components/base/BaseButton'
    import BaseCalendarList from '@/components/base/BaseCalendarList'

    export default {
        components: {
            GuestBookStep3When,
            GuestBookStep3Dates,
            GuestBookStep3Place,
            GuestBookStep3Time,
            BaseButton,
            BaseCalendarList,
        },

        mixins: [MixinBookStep],

        data() {
            return {
                isPlaceSelectorOpen: false,
            }
        },

        computed: {
            isSubmittable() {
                return (
                    this.formData.search.when !== 'date-range' ||
                    (this.formData.search.startDate && this.formData.search.endDate)
                )
            },

            isBottomOpen() {
                return !!this.formData.search.startDate || this.formData.search.when === 'flexible'
            },

            isCalendarDisplayed() {
                return this.formData.search.when === 'date-range'
            },

            isTimeSwitchOpen() {
                return this.formData.search.when === 'tomorrow'
            },

            areDatesCancelable() {
                return !!(
                    this.formData.search.when === 'date-range' &&
                    this.formData.search.startDate &&
                    this.formData.search.endDate
                )
            },

            whenOptions() {
                return ['now', 'tomorrow', 'next-days', 'date-range', 'flexible'].map((slug) => ({
                    label: this.$t(`guest.book.when.options.${slug}`),
                    value: slug,
                }))
            },

            disabledDates() {
                return [{ from: dayjs().subtract(1, 'years'), to: dayjs().subtract(1, 'days') }]
            },

            selectedDates() {
                if (this.formData.search.startDate && this.formData.search.endDate) {
                    return [
                        {
                            from: dayjs(this.formData.search.startDate),
                            to: dayjs(this.formData.search.endDate),
                        },
                    ]
                }

                if (this.formData.search.startDate) {
                    return [
                        {
                            from: dayjs(this.formData.search.startDate),
                            to: dayjs(this.formData.search.startDate),
                        },
                    ]
                }

                return []
            },

            displayedDates() {
                switch (this.formData.search.when) {
                    case 'tomorrow':
                        return [
                            {
                                label: this.$t('guest.book.when.dates.tomorrow'),
                                value: dayjs(this.formData.search.startDate).format('MMM D, YYYY'),
                            },
                        ]
                    case 'next-days':
                        return [
                            {
                                label: this.$t('guest.book.when.dates.start-date'),
                                value: dayjs(this.formData.search.startDate).format('MMM D, YYYY'),
                            },
                            {
                                label: this.$t('guest.book.when.dates.end-date'),
                                value: dayjs(this.formData.search.endDate).format('MMM D, YYYY'),
                            },
                        ]
                    case 'date-range':
                        return [
                            {
                                label: this.$t('guest.book.when.dates.start-date'),
                                value: dayjs(this.formData.search.startDate).format('MMM D, YYYY'),
                            },
                            {
                                label: this.$t('guest.book.when.dates.end-date'),
                                value: this.formData.search.endDate
                                    ? dayjs(this.formData.search.endDate).format('MMM D, YYYY')
                                    : this.$t('guest.book.when.dates.select'),
                            },
                        ]
                    default:
                        return []
                }
            },
        },

        methods: {
            selectWhen(value) {
                this.change({ path: 'search.when', value })

                switch (this.formData.search.when) {
                    case 'now':
                        this.change({ path: 'search.startDate', value: dayjs() })
                        this.change({ path: 'search.endDate', value: dayjs() })
                        this.change({ path: 'search.startTime', value: null })
                        break
                    case 'tomorrow':
                        this.change({ path: 'search.startDate', value: dayjs().add(1, 'd') })
                        this.change({ path: 'search.endDate', value: dayjs().add(1, 'd') })
                        break
                    case 'next-days':
                        this.change({ path: 'search.startDate', value: dayjs() })
                        this.change({ path: 'search.endDate', value: dayjs().add(1, 'w') })
                        this.change({ path: 'search.startTime', value: null })
                        break
                    case 'date-range':
                    case 'flexible':
                        this.change({ path: 'search.startDate', value: undefined })
                        this.change({ path: 'search.endDate', value: undefined })
                        this.change({ path: 'search.startTime', value: null })
                        break
                }
            },

            cancelRange() {
                this.change({ path: 'search.startDate', value: undefined })
                this.change({ path: 'search.endDate', value: undefined })
            },

            clickDate(value) {
                if (this.formData.search.startDate && !this.formData.search.endDate) {
                    if (this.formData.search.startDate.isSameOrBefore(value)) {
                        this.change({ path: 'search.endDate', value })
                    } else {
                        this.change({
                            path: 'search.endDate',
                            value: this.formData.search.startDate,
                        })
                        this.change({ path: 'search.startDate', value })
                    }
                } else {
                    this.change({ path: 'search.startDate', value })
                    this.change({ path: 'search.endDate', value: undefined })
                }
            },

            changeTime(value) {
                this.change({ path: 'search.startTime', value })
            },

            saveAndGoToNextStep(value) {
                this.change({ path: 'search.workPlace', value })
                this.goToNextStep()
            },
        },
    }
</script>
