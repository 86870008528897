<script>
    import ApiGuestAppointments from '@/apis/ApiGuestAppointments'
    import GuestAppointmentsRepeatStep4 from '@/components/guest/appointments/repeat/GuestAppointmentsRepeatStep4'

    export default {
        extends: GuestAppointmentsRepeatStep4,

        computed: {
            confirmLabel() {
                return this.$t('guest.appointments.reschedule')
            },
        },

        methods: {
            saveAndGoToNextStep() {
                this.handleSubmit(
                    ApiGuestAppointments.reschedule(
                        this.$auth.getUser(),
                        this.appointment.id,
                        this.appointment,
                    ),
                ).then(this.goToNextStep)
            },
        },
    }
</script>
