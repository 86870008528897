import ApiProClients from '@/apis/ApiProClients'
import ProAddClient from '@/components/pro/ProAddClient'

export default {
    components: {
        ProAddClient,
    },

    data() {
        return {
            isGuestInClientBook: false,
        }
    },

    mounted() {
        this.checkIfGuestInClientBook()
    },

    methods: {
        checkIfGuestInClientBook() {
            ApiProClients.checkIfGuestInClientBook(
                this.$auth.getUser().id,
                this.appointment.guestId,
            ).then((result) => (this.isGuestInClientBook = result))
        },
    },
}
