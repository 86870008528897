import { createRouter, createWebHistory } from 'vue-router'
import { parseRoutes, applyDefaults, runMiddlewares } from '@/router/helpers'
import MiddlewareLoadCommonData from '@/router/middlewares/MiddlewareLoadCommonData'
import MiddlewareLoadPageData from '@/router/middlewares/MiddlewareLoadPageData'
import MiddlewareFlashShowPostponed from '@/router/middlewares/MiddlewareFlashShowPostponed'
import MiddlewareAlertsGuest from '@/router/middlewares/MiddlewareAlertsGuest'
import MiddlewareClearUi from '@/router/middlewares/MiddlewareClearUi'

const router = createRouter({
    history: createWebHistory(process.env.VUE_BASE_PATH),
    routes: parseRoutes(
        require
            .context('@/router/routes/', true, /^\.\/.*\.js$/)
            .keys()
            .reduce((routes, file) => {
                const route = file.replace('./', '').replace('.js', '')
                return routes.concat(require(`@/router/routes/${route}`).default)
            }, [])
            .concat([
                {
                    path: '/:path(.*)*',
                    view: '404',
                },
            ]),
    ),
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { top: 0 }
    },
})

router.beforeEach((to, from, next) => {
    to = applyDefaults(to)

    if (from.matched.length) {
        router.previousRoute = { ...from }
    }

    // Always load session user first + load async data to the page.
    const middlewares = [
        MiddlewareLoadCommonData,
        MiddlewareClearUi,
        MiddlewareLoadPageData,
        MiddlewareFlashShowPostponed,
        MiddlewareAlertsGuest,
        ...(to.meta.middlewares || []),
    ]

    return runMiddlewares(middlewares, { to, from, next })
})

export default router
