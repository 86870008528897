<template>
    <ProOnboardingStep
        :heading="$t('pro.onboarding.welcome.heading', { name: $auth.getUser().firstName })"
        :description="$t('pro.onboarding.welcome.description')"
    >
        <FormButtons>
            <BaseButton :label="$t('common.go-to-profile')" route="/pro/profile" />
        </FormButtons>
    </ProOnboardingStep>
</template>

<script>
    import ProOnboardingStep from '@/components/pro/onboarding/ProOnboardingStep'
    import BaseButton from '@/components/base/BaseButton'
    import FormButtons from '@/components/form-elements/FormButtons'

    export default {
        components: {
            ProOnboardingStep,
            BaseButton,
            FormButtons,
        },
    }
</script>
