<script>
    import ProProfileExperiencesIndexAlertSome from '@/components/pro/profile/experiences/index/ProProfileExperiencesIndexAlertSome'

    export default {
        extends: ProProfileExperiencesIndexAlertSome,

        computed: {
            message() {
                return this.$t('pro.profile.experiences.index.alert.none')
            },
        },
    }
</script>
