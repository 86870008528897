<template>
    <ProSection
        :heading="$t('pro.profile.experiences.experience.steps.3.heading', { name: formData.name })"
        :description="
            $t('pro.profile.experiences.experience.steps.3.description', {
                name: formData.name,
            })
        "
    >
        <FormWrapper>
            <InputPhoto
                :is-select-displayed-initially="true"
                :value="formData.file"
                @change="selectFile"
                @preview="loadPreview"
            >
                <template #preview>
                    <FormFields>
                        <ProWork
                            v-if="formData.picture"
                            :picture="formData.picture"
                            :is-picture-deletable="true"
                            @delete-picture="removeFile"
                        />

                        <FieldText
                            :placeholder="
                                $t('pro.profile.experiences.experience.steps.3.add-label')
                            "
                            :has-autofocus="true"
                            :form-data="formData"
                            :form-errors="formErrors"
                            value-path="pictureTitle"
                            @change="change"
                        />
                    </FormFields>
                </template>
            </InputPhoto>

            <slot name="buttons" :is-submittable="isSubmittable">
                <FormButtons>
                    <BaseButton
                        :label="$t('common.save')"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                        @click="save"
                    />
                </FormButtons>
            </slot>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FormFields from '@/components/form-elements/FormFields'
    import InputPhoto from '@/components/form-inputs/InputPhoto'
    import FieldText from '@/components/form-fields/FieldText'
    import BaseButton from '@/components/base/BaseButton'
    import ProWork from '@/components/pro/ProWork'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormButtons,
            FormFields,
            InputPhoto,
            FieldText,
            BaseButton,
            ProWork,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isSubmittable() {
                return !!this.formData.file
            },
        },

        methods: {
            selectFile(file) {
                this.change({ path: 'file', value: file })
                this.focusAutofocusInput()
            },

            removeFile() {
                this.change({ path: 'file', value: null })
                this.change({ path: 'picture', value: null })
                this.change({ path: 'pictureTitle', value: null })
            },

            loadPreview(picture) {
                this.change({ path: 'picture', value: picture })
            },
        },
    }
</script>
