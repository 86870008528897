<template>
    <div class="bg-gold-light-3 min-h-50 p-10 text-14 flex items-center space-x-10">
        <IconPin class="h-25" />

        <i18n-t v-if="isAvailableForBoth" tag="p" keypath="pro.page.locations.both">
            <template #salon>
                <BaseLink :label="$t('pro.page.locations.salon')" href="#salon" />
            </template>
        </i18n-t>

        <i18n-t v-else-if="isAvailableForPro" tag="p" keypath="pro.page.locations.pro">
            <template #salon>
                <BaseLink :label="$t('pro.page.locations.salon')" href="#salon" />
            </template>
        </i18n-t>

        <i18n-t v-else-if="isAvailableForGuest" tag="p" keypath="pro.page.locations.guest" />
    </div>
</template>

<script>
    import BaseLink from '@/components/base/BaseLink'
    import IconPin from '@/assets/vectors/icon-pin.svg'

    export default {
        components: {
            BaseLink,
            IconPin,
        },

        props: {
            pro: { type: Object, required: true },
        },

        computed: {
            isAvailableForPro() {
                return this.pro.workPlace?.includes('pro')
            },

            isAvailableForGuest() {
                return this.pro.workPlace?.includes('guest')
            },

            isAvailableForBoth() {
                return this.isAvailableForPro && this.isAvailableForGuest
            },
        },
    }
</script>
