<template>
    <LandingContainer :level="2" class="py-50 md:py-80 md:flex md:flex-col items-center">
        <LandingHeading :level="3" class="text-center">
            {{ $t('landing.pros.enough.heading') }}
        </LandingHeading>

        <LandingButton
            :label="$t('landing.pros.join')"
            :level="1"
            route="/auth/register/pro"
            class="mt-30"
        />
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingButton from '@/components/landing/LandingButton'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingButton,
        },
    }
</script>
