<template>
    <LayoutApp :back-button="backButton">
        <div class="sticky z-floating top-70 bg-white -mx-20 px-20 mb-20">
            <BaseHeaderSection
                :heading="$t('guest.appointments.heading')"
                :is-center="false"
                class="mb-20"
            />

            <div class="flex">
                <BaseTabs :items="listTabs" />
                <slot name="actions" />
            </div>
        </div>

        <div class="flex-grow flex flex-col pb-20">
            <slot />
        </div>

        <slot name="bottom" />
    </LayoutApp>
</template>

<script>
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseTabs from '@/components/base/BaseTabs'

    export default {
        components: {
            LayoutApp,
            BaseHeaderSection,
            BaseTabs,
        },

        props: {
            backButton: { type: [Object, String, Boolean], default: false },
            currentType: { type: String, required: true },
        },

        computed: {
            listTabs() {
                return ['future', 'previous', 'cancelled'].map((type) => ({
                    label: this.$t(`common.appointment-types.${type}`),
                    route: { path: `/guest/appointments/${type}` },
                    isSelected: this.currentType === type,
                }))
            },
        },
    }
</script>
