import { transformWorkPlace } from '@/apis/transforms/transformProFromApi'

const transformAddOn = (data) => {
    return {
        ...data,
        workPlace: data.workPlace && transformWorkPlace(data.workPlace),
    }
}

export default (data) => {
    return Array.isArray(data) ? data.map(transformAddOn) : transformAddOn(data)
}
