<template>
    <div
        v-if="isCustomPeriodView"
        class="w-135 text-purple mx-auto relative"
        @click="$emit('click-custom-period')"
    >
        <PictureDateRange class="w-110 mx-auto" />

        <p class="absolute top-10 pt-3 w-40 text-center font-bold left-10">
            {{ formattedCustomPeriod.startDay }}
        </p>
        <p class="absolute top-10 pt-3 w-40 text-center font-bold right-10">
            {{ formattedCustomPeriod.endDay }}
        </p>

        <div class="flex justify-between mt-5">
            <p class="text-10 font-bold uppercase whitespace-nowrap w-60 text-center">
                {{ formattedCustomPeriod.startDate }}
            </p>
            <p class="text-10 font-bold uppercase whitespace-nowrap w-60 text-center">
                {{ formattedCustomPeriod.endDate }}
            </p>
        </div>
    </div>

    <div v-else class="flex items-center text-20 font-bold leading-heading text-purple h-50">
        <div class="relative flex items-center justify-center h-40 w-40">
            <component :is="iconComponent" class="absolute inset-0" />
            <p v-if="isDayView" class="pt-10">{{ formattedDayNumber }}</p>
        </div>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="ml-15" v-html="formattedDate" />
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import PictureDateRange from '@/assets/vectors/picture-date-range.svg'
    import IconCalendarDay from '@/assets/vectors/icon-calendar-day.svg'
    import IconCalendarWeek from '@/assets/vectors/icon-calendar-week.svg'

    export default {
        components: {
            PictureDateRange,
        },
        props: {
            view: { type: String, required: true },
            startDate: { type: Object, default: null },
            endDate: { type: Object, default: null },
        },

        emits: ['click-custom-period'],

        computed: {
            isDayView() {
                return this.view === 'day'
            },

            isCustomPeriodView() {
                return this.view === 'custom'
            },

            iconComponent() {
                return this.isDayView ? IconCalendarDay : IconCalendarWeek
            },

            formattedDayNumber() {
                return dayjs().format('D')
            },

            formattedCustomPeriod() {
                return {
                    startDay: dayjs(this.startDate).format('D'),
                    startDate: dayjs(this.startDate).format('MMM YYYY'),
                    endDay: dayjs(this.endDate).format('D'),
                    endDate: dayjs(this.endDate).format('MMM YYYY'),
                }
            },

            formattedDate() {
                if (this.isDayView) {
                    return dayjs().format('dddd,<br /> MMMM YYYY')
                }

                if (this.view === 'week') {
                    return [this.$t('common.week'), dayjs().format('w,<br /> MMMM YYYY')].join(' ')
                }

                if (this.view === 'month') {
                    return dayjs().format('MMMM YYYY')
                }

                return null
            },
        },
    }
</script>
