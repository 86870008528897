const initialState = () => {
    return {
        isOpen: false,
        type: null,
        message: null,
        close: null,
        resolve: null,
    }
}

export default {
    namespaced: true,

    state: initialState(),

    mutations: {
        open(state, config) {
            state.isOpen = true
            state.type = config.type
            state.message = config.message
            state.close = config.close
            state.resolve = config.resolve
        },

        close(state) {
            const newState = initialState()
            Object.keys(newState).forEach((key) => {
                state[key] = newState[key]
            })
        },
    },

    actions: {
        close({ state, commit }) {
            if (state.isOpen) {
                state.resolve && state.resolve()
                commit('close')
            }
        },
    },
}
