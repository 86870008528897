import { normalizeFormData } from '@/axios/helpers'

export const transformWorkPlace = (value) => {
    if (value.includes('pro') && value.includes('guest')) {
        return 'Both'
    }

    if (value.includes('pro')) {
        return 'ProsPlace'
    }

    if (value.includes('guest')) {
        return 'GuestsPlace'
    }
}

const transformAddons = (value) => {
    return value.map((addon) => ({
        categoryId: addon?.category?.id,
        name: addon?.name,
        price: addon?.price,
    }))
}

const transformAvailabilities = (value) => {
    return value.reduce((availabilities, availability) => {
        return availability
            ? availabilities.concat({
                  dayOfWeek: availability.dayOfWeek,
                  startTime: availability.startTime && `${availability.startTime}`,
                  endTime: availability.endTime && `${availability.endTime}`,
              })
            : availabilities
    }, [])
}

const transformCancelationNoShowPolicy = (data) => {
    const transformType = (value) => {
        return {
            percent: 'Percentage',
            flat: 'FlatRate',
        }[value]
    }

    return {
        ...data,
        guestsPlaceFeeType: transformType(data.guestsPlaceFeeType),
        prosPlaceFeeType: transformType(data.prosPlaceFeeType),
    }
}

export default (data) => {
    data = normalizeFormData(data || {})

    return {
        ...data,
        areaRadius: data.areaRadius && parseInt(data.areaRadius),
        addOns: data.addOns && transformAddons(data.addOns),
        availabilities: data.availabilities && transformAvailabilities(data.availabilities),
        cancellationPolicy:
            data.cancellationPolicy && transformCancelationNoShowPolicy(data.cancellationPolicy),
        noShowPolicy: data.noShowPolicy && transformCancelationNoShowPolicy(data.noShowPolicy),
        workPlace: data.workPlace && transformWorkPlace(data.workPlace),
    }
}
