import MixinForm from '@/mixins/MixinForm'
import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
import LayoutOverlay from '@/components/layouts/LayoutOverlay'
import ProSection from '@/components/pro/ProSection'

export default {
    components: {
        LayoutOverlay,
        ProSection,
    },

    mixins: [MixinForm, MixinFormSubmittable],

    props: {
        initialFormData: { type: Object, default: null },
    },

    emits: ['back'],

    computed: {
        isSubmittable() {
            return false
        },

        isRedirectingHome() {
            return true
        },

        stopProcessingAfterSubmitted() {
            return !this.isRedirectingHome
        },
    },

    watch: {
        initialFormData: {
            deep: true,
            handler(initialFormData) {
                this.initForm(initialFormData || {})
            },
        },
    },

    created() {
        this.initialFormData && this.initForm(this.initialFormData)
    },

    methods: {
        back() {
            this.$emit('back')
        },

        save() {
            return this.handleSubmit(this.apiCall()).then((user) => {
                this.$store.commit('auth/setUser', user)
                this.isRedirectingHome ? this.$router.push('/pro/profile') : this.$emit('back')
            })
        },
    },
}
