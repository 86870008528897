<template>
    <svg :height="items.length * 55 + 20 + 'px'">
        <g v-for="(item, index) in items" :key="index">
            <text x="0" :y="index * 55" dy=".9em">
                {{ item.label }}
            </text>

            <rect
                :y="index * 55 + 20"
                :width="getWidth(item.percentage)"
                height="20px"
                class="fill-current text-purple"
            />

            <text
                :x="getWidth(item.percentage)"
                :y="index * 55 + 20"
                class="font-bold"
                dx="5px"
                dy=".9em"
            >
                {{ item.percentage }}%
            </text>
        </g>
    </svg>
</template>

<script>
    export default {
        props: {
            items: { type: Array, required: true },
        },

        methods: {
            getWidth(percentage) {
                return percentage + '%'
            },
        },
    }
</script>
