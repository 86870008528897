<template>
    <header :class="headerClass">
        <BaseHeading :heading="heading" :level="3">
            <slot name="heading" />
        </BaseHeading>

        <slot v-if="subheading || $slots.subheading" name="subheading">
            <BaseSubheading :subheading="subheading" />
        </slot>

        <div v-if="description || $slots.description" class="text-16 text-gray-2">
            <slot name="description">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="space-y-10" v-html="sanitizeAndFormatText(description)" />
            </slot>
        </div>
    </header>
</template>

<script>
    import { sanitizeAndFormatText } from '@/helpers'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseSubheading from '@/components/base/BaseSubheading'

    export default {
        components: {
            BaseHeading,
            BaseSubheading,
        },

        props: {
            heading: { type: String, default: null },
            subheading: { type: String, default: null },
            description: { type: String, default: null },
            isCenter: { type: Boolean, default: true },
        },

        computed: {
            headerClass() {
                return {
                    'space-y-10': true,
                    'text-center': this.isCenter,
                }
            },
        },

        methods: {
            sanitizeAndFormatText,
        },
    }
</script>
