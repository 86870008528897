<template>
    <div class="flex flex-col space-y-10">
        <div
            v-for="(requiredInfo, index) in requiredInfos"
            :key="requiredInfo"
            :class="requiredInfos.length - 1 > index ? 'border-b-1 border-gray-4' : ''"
            class="flex flex-row p-10"
        >
            <BaseHeading :heading="$t(`pro.profile.links.${requiredInfo}.heading`)" :level="5" />

            <ProProfileRequired />
        </div>
    </div>
</template>
<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import ProProfileRequired from '@/components/pro/profile/ProProfileRequired'

    export default {
        components: {
            BaseHeading,
            ProProfileRequired,
        },

        props: {
            requiredInfos: { type: Array, default: null },
        },
    }
</script>
