<template>
    <div :class="buttonsClass">
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            isFixed: { type: Boolean, default: false },
            isRevealing: { type: Boolean, default: false },
        },

        computed: {
            buttonsClass() {
                return {
                    'sticky': !this.isFixed,
                    '-mx-20': !this.isFixed,
                    'fixed': this.isFixed,
                    'bottom-0': true,
                    'inset-x-0': true,
                    'py-15': true,
                    'px-20': true,
                    'mt-auto': true,
                    'bg-white': true,
                    'z-layout': true,
                }
            },
        },
    }
</script>
