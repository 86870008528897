<template>
    <ProSection :heading="$t('pro.profile.portfolio.steps.2.question')">
        <template #top>
            <ProWork :picture="formData.picture" :title="formData.title" />
        </template>

        <template #default>
            <FormWrapper>
                <FormFields>
                    <ProProfileChecklist
                        :options="experienceOptions"
                        :value="formData.experienceIds"
                        @change="changeExperienceIds"
                    />
                </FormFields>
            </FormWrapper>

            <slot name="buttons" :is-submittable="isSubmittable" />
        </template>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import ProProfileChecklist from '@/components/pro/profile/ProProfileChecklist'
    import ProWork from '@/components/pro/ProWork'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'

    export default {
        components: {
            ProSection,
            ProProfileChecklist,
            ProWork,
            FormWrapper,
            FormFields,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isSubmittable() {
                return (this.formData.experienceIds || []).length > 0
            },

            experienceOptions() {
                return (this.$auth.getUser().experiences || []).map((experience) => ({
                    label: experience.name,
                    value: experience.id,
                }))
            },
        },

        methods: {
            changeExperienceIds(value) {
                this.change({ path: 'experienceIds', value })
            },
        },
    }
</script>
