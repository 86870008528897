<template>
    <div>
        <ul :class="choicesClass">
            <li v-for="option in options" :key="option.label" :class="optionClass">
                <BaseChoice
                    :icon="option.icon"
                    :label="option.label"
                    :level="level"
                    :is-selected="isSelected(option)"
                    @click="toggleSelect(option)"
                />
            </li>
        </ul>
    </div>
</template>

<script>
    import InputPills from '@/components/form-inputs/InputPills'
    import BaseChoice from '@/components/base/BaseChoice'

    export default {
        components: {
            BaseChoice,
        },

        extends: InputPills,

        props: {
            level: { type: Number, default: 1 },
        },

        computed: {
            choicesClass() {
                return {
                    'flex': true,
                    'flex-wrap': true,
                    'justify-center': true,
                    'cursor-not-allowed': this.isDisabled,
                }
            },

            optionClass() {
                return {
                    'transition-opacity': true,
                    'w-4/12': true,
                    'pointer-events-none': this.isDisabled,
                    'opacity-30': this.isDisabled,
                }
            },
        },

        methods: {
            toggleSelect(option) {
                this.isSelected(option) ? this.deselect(option) : this.select(option)
            },
        },
    }
</script>
