import axios from 'axios'
import transformProAvailabilityToApi from '@/apis/transforms/transformProAvailabilityToApi'
import transformProAvailabilityFromApi from '@/apis/transforms/transformProAvailabilityFromApi'

export default {
    getMonth(id, data) {
        return axios.post(`/stylists/${id}/availabilityPerMonth`, data, {
            transformRequest: [transformProAvailabilityToApi, ...axios.defaults.transformRequest],
        })
    },

    getSlots(id, data) {
        return axios.post(`/stylists/${id}/availableTimeSlots`, data, {
            transformRequest: [transformProAvailabilityToApi, ...axios.defaults.transformRequest],
            transformResponse: [
                ...axios.defaults.transformResponse,
                transformProAvailabilityFromApi,
            ],
        })
    },
}
