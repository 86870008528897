<template>
    <ProSection>
        <FormWrapper @submit="save">
            <FormFields>
                <FormLegend :label="$t('pro.profile.experiences.category.choose-subcategories')" />

                <BaseChoice
                    :icon="category.icon"
                    :label="category.name"
                    :level="1"
                    :is-selected="true"
                />

                <div>
                    <FieldPills
                        :options="subCategoryOptions"
                        :form-data="formData"
                        value-path="subCategoryIds"
                        @change="change"
                    />
                </div>
            </FormFields>

            <TransitionSlide from="bottom">
                <FormButtons v-if="isSubmittable">
                    <BaseButton :label="$t('common.save')" :is-processing="isSubmitting" />
                </FormButtons>
            </TransitionSlide>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import intersection from 'lodash/intersection'
    import { getCategoryIcon, getCategoryOptions } from '@/helpers'
    import ApiProExperiences from '@/apis/ApiProExperiences'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormLegend from '@/components/form-elements/FormLegend'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldPills from '@/components/form-fields/FieldPills'
    import BaseButton from '@/components/base/BaseButton'
    import BaseChoice from '@/components/base/BaseChoice'
    import TransitionSlide from '@/components/transitions/TransitionSlide'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormFields,
            FormLegend,
            FormButtons,
            FieldPills,
            BaseButton,
            BaseChoice,
            TransitionSlide,
        },

        mixins: [MixinFormStep],

        computed: {
            isSubmittable() {
                const selectedIds = this.formData.subCategoryIds
                const allIds = this.subCategoryOptions.map(({ value }) => value)

                return intersection(selectedIds, allIds).length > 0
            },

            category() {
                const category = this.$store.getters['dictionaries/getCategories'].find(
                    (category) => category.id === this.formData.category,
                )

                return {
                    ...category,
                    icon: getCategoryIcon(category),
                }
            },

            subCategoryOptions() {
                return getCategoryOptions(
                    this.$store.getters['dictionaries/getCategories'],
                    this.category,
                )
            },
        },

        mounted() {
            this.change({
                path: 'subCategoryIds',
                value: this.$auth.getUser().subCategories.map(({ id }) => id),
            })
        },

        methods: {
            save() {
                this.handleSubmit(
                    ApiProExperiences.updateSubCategories(this.$auth.getUser().id, this.formData),
                ).then((user) => {
                    this.$store.commit('auth/setUser', user)
                    this.goToNextStep()
                })
            },
        },
    }
</script>
