export default {
    emits: ['focus', 'blur'],

    data() {
        return {
            isFocused: false,
        }
    },

    methods: {
        focus(event) {
            this.isFocused = true
            this.$emit('focus', event)
        },

        blur(event) {
            this.isFocused = false
            this.$emit('blur', event)
        },

        focusAutofocusInput(index = 0, element = document) {
            setTimeout(() => {
                if (!element || element.nodeType === 8) {
                    return
                }

                const inputs = element.querySelectorAll('[autofocus]')

                if (inputs[index]) {
                    if (inputs[index].focus) {
                        inputs[index].focus()
                    } else if (inputs[index].select) {
                        inputs[index].select()
                    }
                }
            })
        },
    },

    mounted() {
        setTimeout(this.focusAutofocusInput)
    },
}
