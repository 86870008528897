<template>
    <section v-if="isPresent" class="space-y-20">
        <template v-if="appointment.message">
            <BaseHeaderDivider :heading="$t('pro.appointments.show.message-from-x', { name })" />
            <BaseNote :content="appointment.message" />
        </template>

        <template v-if="$auth.getUser().locationQuestions">
            <BaseHeaderDivider
                :heading="$t('pro.appointments.show.your-questions')"
                class="w-full"
            />
            <BaseText :content="$auth.getUser().locationQuestions" />

            <BaseHeaderDivider :heading="$t('pro.appointments.show.answer-from-x', { name })" />
            <BaseNote :content="appointment.locationQuestionAnswer" />
        </template>
    </section>
</template>

<script>
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseText from '@/components/base/BaseText'
    import BaseNote from '@/components/base/BaseNote'

    export default {
        components: {
            BaseHeaderDivider,
            BaseText,
            BaseNote,
        },

        props: {
            appointment: { type: Object, required: true },
        },

        computed: {
            name() {
                return this.appointment.guest.firstName
            },

            isPresent() {
                return this.appointment.message || this.$auth.getUser().locationQuestions
            },
        },
    }
</script>
