<template>
    <LayoutApp :back-button="backButton" @back="handleGoToPreviousStep">
        <component
            :is="currentStep.component"
            :step-data="formData"
            :has-progress="!!currentStep.progress"
            v-bind="currentStep.props || {}"
            @change="changeData"
            @go-to-start="goToStart"
            @go-to-next="handleGoToNextStep"
        />

        <GuestBookProgress
            v-if="currentStep.progress"
            :current-step="currentStep.progress"
            :sub-categories-count="selectedSubCategoriesCount"
            :pro="selectedPro"
            class="sticky bottom-0 z-layout -mx-20 mt-auto"
        />
    </LayoutApp>
</template>

<script>
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import GuestBookStep1 from '@/components/guest/book/GuestBookStep1'
    import GuestBookStep2 from '@/components/guest/book/GuestBookStep2'
    import GuestBookStep3 from '@/components/guest/book/GuestBookStep3'
    import GuestBookStep4 from '@/components/guest/book/GuestBookStep4'
    import GuestBookStep5 from '@/components/guest/book/GuestBookStep5'
    import GuestBookStep6 from '@/components/guest/book/GuestBookStep6'
    import GuestBookStep7 from '@/components/guest/book/GuestBookStep7'
    import GuestBookStep8 from '@/components/guest/book/GuestBookStep8'
    import GuestBookStep9 from '@/components/guest/book/GuestBookStep9'
    import GuestBookStep10 from '@/components/guest/book/GuestBookStep10'
    import GuestBookStep11 from '@/components/guest/book/GuestBookStep11'
    import GuestBookProgress from '@/components/guest/book/GuestBookProgress'

    export default {
        components: {
            LayoutApp,
            GuestBookProgress,
        },

        mixins: [MixinFormSteppable],

        beforeRouteLeave(to, from, next) {
            if (this.$auth.isLoggedIn() || this.step !== 8) {
                this.$store.commit('continuity/setBook', null)
            }

            next()
        },

        computed: {
            backButton() {
                return this.isFirstStep ? '/' : true
            },

            isProBook() {
                return false
            },

            isFlexibleDate() {
                return this.formData.search.when === 'flexible'
            },

            category() {
                return this.$store.getters['dictionaries/getCategories'].find(
                    ({ id }) => id == this.$route.query.category,
                )
            },

            steps() {
                if (this.isProBook) {
                    return [
                        { component: GuestBookStep5 },
                        { component: GuestBookStep6 },
                        { component: GuestBookStep7 },
                        { component: GuestBookStep8 },
                        { component: GuestBookStep9 },
                        { component: GuestBookStep10 },
                        { component: GuestBookStep11 },
                    ]
                }

                return [
                    { component: GuestBookStep1, progress: 1, props: { category: this.category } },
                    { component: GuestBookStep2, progress: 2 },
                    { component: GuestBookStep3, progress: 3 },
                    { component: GuestBookStep4, progress: 4 },
                    { component: GuestBookStep5, progress: 4 },
                    { component: GuestBookStep6, progress: 4 },
                    { component: GuestBookStep7, progress: 4 },
                    { component: GuestBookStep8, progress: 4 },
                    { component: GuestBookStep9, progress: 4 },
                    { component: GuestBookStep10, progress: 5 },
                    { component: GuestBookStep11, progress: false },
                ]
            },

            selectedSubCategoriesCount() {
                return this.formData.search?.subCategoryIds?.length || 0
            },

            selectedPro() {
                return this.formData.appointment?.pro
            },
        },

        created() {
            this.initializeFromContinuity()
        },

        methods: {
            handleGoToPreviousStep() {
                // If user is on slots step and goes back…
                if (this.step === 7) {
                    return this.isProBook || this.isFlexibleDate
                        ? // … we show them date picker when Pro book or flexible dates…
                          this.goToPreviousStep()
                        : // … otherwise we just go back in a regular way (skipping date picker).
                          this.goToStep(5)
                }

                this.goToPreviousStep()
            },

            handleGoToNextStep() {
                // If user is on profile step and goes next…
                if (this.step === 5) {
                    return this.isProBook || this.isFlexibleDate
                        ? // … we show them date picker when Pro book or flexible dates…
                          this.goToNextStep()
                        : // … otherwise we just go back in a regular way (skipping date picker).
                          this.goToStep(7)
                }

                this.goToNextStep()
            },

            initializeFromContinuity() {
                if (!this.$store.getters['continuity/getBook']) {
                    return
                }

                this.formData = this.$store.getters['continuity/getBook']

                if (this.formData.appointment?.startTime && this.formData.appointment?.endTime) {
                    return (this.step = 8)
                }

                if (this.formData.appointment?.pro) {
                    return (this.step = 7)
                }

                if (this.formData.appointment?.date) {
                    return (this.step = 4)
                }

                if (this.formData.search?.areaZipCode) {
                    return (this.step = 3)
                }
            },
        },
    }
</script>
