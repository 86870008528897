<template>
    <component
        :is="pillComponent"
        :class="pillClass"
        type="button"
        class="group relative max-w-full flex items-center px-15 h-30 text-lg rounded-full whitespace-nowrap leading-none appearance-none"
    >
        <span :class="backgroundClass" />
        <slot>
            <span class="relative truncate overflow-ellipsis">{{ label }}</span>
        </slot>
        <button
            v-if="isClosable"
            type="button"
            class="p-5 -m-5 ml-0 hoverable:hover:text-purple-light-3"
            @click="$emit('close', $event)"
        >
            <IconCloseAlt class="w-14 h-14" />
        </button>
    </component>
</template>

<script>
    import IconCloseAlt from '@/assets/vectors/icon-close-alt.svg'

    export default {
        components: {
            IconCloseAlt,
        },

        props: {
            label: { type: String, default: null },
            isSelected: { type: Boolean, default: false },
            isClosable: { type: Boolean, default: false },
        },

        emits: ['close'],

        computed: {
            pillComponent() {
                return this.isClosable ? 'div' : 'button'
            },

            pillClass() {
                return this.isSelected ? 'bg-purple text-white' : 'text-purple'
            },

            backgroundClass() {
                return this.isSelected
                    ? ''
                    : 'absolute inset-0 rounded-full transition-none border-1 border-purple hoverable:group-hover:border-2 hoverable:group-focus:border-2'
            },
        },
    }
</script>
