import axios from 'axios'

export default {
    sendVerificationEmailToPro(stylistId) {
        return axios.post('/auth/sendConfirmationEmail', {}, { params: { stylistId } })
    },

    sendVerificationEmailToGuest(guestId) {
        return axios.post('/auth/sendConfirmationEmail', {}, { params: { guestId } })
    },

    login(data) {
        return axios.post('/auth/login', data)
    },

    sendResetPasswordEmail(data) {
        return axios.post('/auth/sendResetPasswordEmail', data)
    },

    checkResetPasswordToken(data) {
        return axios.post('/auth/checkResetPasswordToken', data)
    },

    resetPassword(data) {
        return axios.post('/auth/resetPassword', data)
    },
}
