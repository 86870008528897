<template>
    <transition
        enter-from-class="scale-95 opacity-0"
        leave-to-class="scale-95 opacity-0"
        enter-active-class="transform transition duration-250"
        leave-active-class="transform transition duration-500"
        enter-to-class="scale-100 opacity-100"
        leave-from-class="scale-100 opacity-100"
        appear
    >
        <slot />
    </transition>
</template>

<script>
    export default {
        props: {
            isAppear: { type: Boolean, default: true },
        },
    }
</script>
