<template>
    <img
        :src="require('@/assets/images/picture-landing-intro.jpg').default"
        class="absolute pointer-events-none top-0 right-0 w-7/12 xs:w-5/12 xl:w-600"
    />

    <LandingContainer class="py-20 md:pt-60 md:pb-80 lg:pb-100 md:px-50 lg:px-70">
        <div class="max-w-8/12 xs:max-w-6/12 md:max-w-8/12">
            <LandingHeading :level="1" class="mb-20 text-purple">
                {{ $t('landing.index.intro.heading.1') }}
                <br class="xs:hidden" />
                {{ $t('landing.index.intro.heading.2') }}
                <br class="xs:hidden" />
                {{ $t('landing.index.intro.heading.3') }}
                <br class="hidden md:block" />
                {{ $t('landing.index.intro.heading.4') }}
                <br class="xs:hidden" />
                {{ $t('landing.index.intro.heading.5') }}
            </LandingHeading>

            <LandingText
                :content="$t('landing.index.intro.description')"
                class="md:text-22 md:pr-50 lg:pr-100 xl:pr-150"
            />

            <LandingBook class="mt-60 hidden md:flex md:mr-1/12 2xl:mr-0" />
        </div>

        <div class="pt-30 text-center md:hidden">
            <LandingButton :label="$t('landing.book.cta')" :level="1" route="/book" />

            <p class="text-gray-2 pt-40">{{ $t('landing.index.intro.pro.question') }}</p>

            <LandingLink
                :label="$t('landing.index.intro.pro.join')"
                route="/auth/register/pro"
                class="pt-10"
            />
        </div>
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingText from '@/components/landing/LandingText'
    import LandingButton from '@/components/landing/LandingButton'
    import LandingLink from '@/components/landing/LandingLink'
    import LandingBook from '@/components/landing/LandingBook'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingText,
            LandingButton,
            LandingLink,
            LandingBook,
        },
    }
</script>
