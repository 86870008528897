<script>
    import ApiPros from '@/apis/ApiPros'
    import PageBook from '@/pages/book'

    export default {
        extends: PageBook,

        async loadData({ $route }) {
            const pro = isNaN($route.params.username)
                ? await ApiPros.getByUsername($route.params.username)
                : await ApiPros.get($route.params.username)

            return {
                formData: {
                    appointment: {
                        experienceIds: [],
                        addOns: [],
                        pro,
                    },
                    search: {
                        subCategoryIds: [],
                        workPlace: ['guest', 'pro'],
                    },
                },
            }
        },

        computed: {
            isProBook() {
                return true
            },
        },
    }
</script>
