export default {
    props: {
        formData: { type: Object, default: null },
        formErrors: { type: Object, default: null },
    },

    emits: ['change'],

    methods: {
        change(payload) {
            return this.$emit('change', payload)
        },
    },
}
