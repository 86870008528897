<template>
    <BaseText>
        <p>{{ message }}</p>
        <p>
            <BaseLink
                :label="$t('pro.profile.experiences.index.alert.cta')"
                route="/pro/profile/experiences/add"
            />
        </p>
    </BaseText>
</template>

<script>
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'

    export default {
        components: {
            BaseText,
            BaseLink,
        },

        emits: ['add-category'],

        computed: {
            message() {
                return this.$t('pro.profile.experiences.index.alert.some')
            },
        },
    }
</script>
