<template>
    <BaseText :content="$t('guest.verify.verifying')" :is-light="true" class="text-center mb-20" />

    <img
        :src="require('@/assets/images/icon-verifying.gif').default"
        class="h-80 w-70 my-30 mx-auto"
    />
</template>

<script>
    import ApiGuests from '@/apis/ApiGuests'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import BaseText from '@/components/base/BaseText'

    export default {
        components: {
            BaseText,
        },

        mixins: [MixinFormStep],

        emits: ['succeeded', 'failed'],

        mounted() {
            const formData = new FormData()

            formData.append('picture', this.formData.picture)
            formData.append('ID', this.formData.ID)

            ApiGuests.verifyId(this.$auth.getUser().id, formData)
                .then((user) => {
                    this.$store.commit('auth/setUser', user)

                    if (user.isIdVerified === 'Verified') {
                        return this.$emit('succeeded')
                    }

                    return Promise.reject()
                })
                .catch(() => this.$emit('failed'))
        },
    }
</script>
