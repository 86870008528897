import { normalizeFormData } from '@/axios/helpers'
import { transformWorkPlace } from '@/apis/transforms/transformProToApi'

export default (data) => {
    data = normalizeFormData(data || {})

    return {
        ...data,
        workPlace: data.workPlace && transformWorkPlace(data.workPlace),
    }
}
