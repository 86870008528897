<template>
    <div class="space-y-60">
        <template v-for="{ date, slots: timeSlots } in slots" :key="date">
            <section v-if="timeSlots.length" class="space-y-10">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <h3 class="font-bold text-24" v-html="dayHeading(date)" />

                <ol class="space-y-10">
                    <li v-for="(slot, index) in timeSlots" :key="index">
                        <button
                            class="flex items-center h-35 text-white bg-green px-10 shadow-md w-full"
                            type="button"
                            @click="$emit('click-slot', { date, slot })"
                        >
                            <p class="text-12">
                                {{ formatTime(slot.startTime) }} – {{ formatTime(slot.endTime) }}
                            </p>
                            <p class="ml-auto text-14 font-bold tracking-widest">${{ price }}</p>
                        </button>
                    </li>
                </ol>
            </section>
        </template>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import { timeToDateTime } from '@/helpers'

    export default {
        props: {
            slots: { type: Array, required: true },
            price: { type: Number, required: true },
        },

        emits: ['click-slot'],

        methods: {
            dayHeading(date) {
                return dayjs(date).format('dddd,<br /> MMMM D, YYYY')
            },

            formatTime(time) {
                return timeToDateTime(time).format('hh:mm A')
            },
        },
    }
</script>
