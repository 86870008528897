<template>
    <div :class="noteClass">
        <p v-if="hasHeading" class="font-bold text-black">{{ $t('common.note') }}</p>

        <slot>
            <BaseText :content="content" />
        </slot>
    </div>
</template>

<script>
    import BaseText from '@/components/base/BaseText'

    export default {
        components: {
            BaseText,
        },

        props: {
            content: { type: String, default: null },
            level: { type: Number, default: 2 },
            hasHeading: { type: Boolean, default: false },
        },

        computed: {
            noteClass() {
                return {
                    'font-medium': true,
                    'text-gray-2': true,
                    'p-15': true,
                    'text-16': this.level === 1,
                    'bg-sand-light': this.level === 1,
                    'space-y-10': this.level === 1,
                    'py-10': this.level === 2,
                    'text-14': this.level === 2,
                    'bg-gray-6': this.level === 2,
                    'space-y-5': this.level === 2,
                }
            },
        },
    }
</script>
