<template>
    <LayoutPro :back-button="backButton">
        <div class="flex-grow flex flex-col space-y-30">
            <BaseText :content="info" class="text-center" />

            <template v-if="inProsPlace">
                <div class="space-y-10">
                    <BaseHeaderDivider :heading="$t('pro.appointments.finish.payment-details')" />
                    <BaseReceipt :positions="noShowPrices(true)" />
                    <BaseText
                        :content="$t('pro.appointments.finish.available-soon')"
                        :level="2"
                        :is-light="true"
                    />
                </div>
            </template>

            <template v-else>
                <BaseNote
                    :content="$t('pro.appointments.finish.no-show.pro.note')"
                    :level="1"
                    :has-heading="true"
                />
            </template>

            <ProAddClient
                v-if="!isGuestInClientBook"
                :guest="appointment.guest"
                class="flex-grow justify-center"
            />
        </div>
    </LayoutPro>
</template>

<script>
    import * as HelperAppointment from '@/helpers/appointment'
    import ApiProAppointments from '@/apis/ApiProAppointments'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import MixinProClientInBook from '@/mixins/MixinProClientInBook'
    import LayoutPro from '@/components/layouts/LayoutPro'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseText from '@/components/base/BaseText'
    import BaseNote from '@/components/base/BaseNote'
    import BaseReceipt from '@/components/base/BaseReceipt'

    export default {
        components: {
            LayoutPro,
            BaseHeaderDivider,
            BaseText,
            BaseNote,
            BaseReceipt,
        },

        mixins: [MixinAppointment, MixinProClientInBook],

        async loadData({ $auth, $route, $router }) {
            const appointment = await ApiProAppointments.getOne($auth.getUser(), $route.params.id)

            if (!HelperAppointment.isChargable(appointment)) {
                $router.push(`/pro/appointments/${appointment.id}`)
            }

            return { appointment }
        },

        computed: {
            backButton() {
                return `/pro/appointments/${this.appointment.id}`
            },

            info() {
                return this.inProsPlace
                    ? this.$t('pro.appointments.finish.no-show.guest.info', {
                          name: this.appointment.guest.firstName,
                      })
                    : this.$t('pro.appointments.finish.no-show.pro.info', {
                          name: this.appointment.guest.firstName,
                      })
            },
        },

        created() {
            if (this.isConlcuded) {
                return this.$router.push(this.backButton)
            }
        },

        mounted() {
            this.saveNoShow()
        },

        methods: {
            saveNoShow() {
                ApiProAppointments.markAsNoShow(
                    this.$auth.getUser(),
                    this.appointment.id,
                    this.inProsPlace ? 'guest' : 'pro',
                )
            },
        },
    }
</script>
