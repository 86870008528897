<template>
    <div class="space-y-20">
        <ProOfferingExperience
            v-for="(experience, index) in experiences"
            :key="index"
            :experience="experience"
            :work-place="workPlace"
            :is-selected="(selectedIds || []).includes(experience.id)"
            @toggle="$emit('toggle', $event)"
        />
    </div>
</template>

<script>
    import ProOfferingExperience from '@/components/pro/ProOfferingExperience'

    export default {
        components: {
            ProOfferingExperience,
        },

        props: {
            experiences: { type: Array, required: true },
            selectedIds: { type: Array, default: null },
            workPlace: { type: Array, default: () => ['pro', 'guest'] },
        },

        emits: ['toggle'],
    }
</script>
