<template>
    <div class="flex">
        <div v-for="({ label, value }, index) in dates" :key="index" class="space-y-5 mr-40">
            <h5 class="text-gray-3 font-bold uppercase text-11">{{ label }}</h5>
            <p class="font-bold">{{ value }}</p>
        </div>

        <BaseLink
            v-if="isCancelable"
            :label="$t('common.cancel')"
            class="ml-auto"
            @click="$emit('cancel')"
        />
    </div>
</template>

<script>
    import BaseLink from '@/components/base/BaseLink'

    export default {
        components: {
            BaseLink,
        },

        props: {
            dates: { type: Array, default: null },
            isCancelable: { type: Boolean, default: false },
        },

        emits: ['cancel'],
    }
</script>
