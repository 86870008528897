import axios from 'axios'
import transformAdminToApi, { transformParams } from '@/apis/transforms/transformAdminToApi'
import transformAdminFromApi from '@/apis/transforms/transformAdminFromApi'

const transforms = {
    transformRequest: [transformAdminToApi, ...axios.defaults.transformRequest],
    transformResponse: [...axios.defaults.transformResponse, transformAdminFromApi],
}

export default {
    get(id) {
        return axios.get(`/admins/${id}`, transforms)
    },

    getAll() {
        return axios.get('/admins', transforms)
    },

    login(data) {
        return axios.post('/admins/login', data)
    },

    update(id, data) {
        return axios.put(`/admins/${id}`, data)
    },

    invite(data) {
        return axios.post('/admins/invite', data)
    },

    getServiceFee() {
        return axios.get('/constants/serviceFee', transforms)
    },

    updateServiceFee(serviceFee) {
        return axios.patch('/constants/serviceFee', { value: serviceFee })
    },

    getTransactionFee() {
        return axios.get('/constants/transactionFee', transforms)
    },

    updateTransactionFee(transactionFee) {
        return axios.patch('/constants/transactionFee', { value: transactionFee })
    },

    getStatistics(params) {
        return axios.get('/admins/statistics', {
            params: transformParams(params),
            ...transforms,
        })
    },

    getNewGuests(params) {
        return axios.get('/admins/statistics/newGuests', {
            params: transformParams(params),
            transformResponse: transforms.transformResponse,
        })
    },

    exportNewGuests(params) {
        return axios.get('/admins/statistics/exportNewGuests', {
            params: transformParams(params),
            transformResponse: transforms.transformResponse,
        })
    },

    getNewPros(params) {
        return axios.get('/admins/statistics/newStylists', {
            params: transformParams(params),
        })
    },

    exportNewPros(params) {
        return axios.get('/admins/statistics/exportNewStylists', {
            params: transformParams(params),
        })
    },

    getNewReservations(params) {
        return axios.get('/admins/statistics/newReservations', {
            params: transformParams(params),
            transformResponse: transforms.transformResponse,
        })
    },

    exportNewReservations(params) {
        return axios.get('/admins/statistics/exportNewReservations', {
            params: transformParams(params),
        })
    },

    getSales(params) {
        return axios.get('/admins/statistics/sales', {
            params: transformParams(params),
            transformResponse: transforms.transformResponse,
        })
    },

    exportSales(params) {
        return axios.get('/admins/statistics/exportSales', {
            params: transformParams(params),
        })
    },

    getAppointmentInfo(appointmentId) {
        return axios.get(`/appointments/${appointmentId}`, {
            transformResponse: transforms.transformResponse,
        })
    },

    getOverallSatisfaction(params) {
        return axios.get('/admins/statistics/overallSatisfaction', {
            params: transformParams(params),
            transformResponse: transforms.transformResponse,
        })
    },

    exportOverallSatisfaction(params) {
        return axios.get('/admins/statistics/exportOverallSatisfaction', {
            params: transformParams(params),
        })
    },

    getManualVerifications() {
        return axios.get('/manualVerifications', transforms)
    },

    saveManualVerifications(id, accept) {
        return axios.post(`/manualVerifications/${id}`, { accept: accept })
    },

    invitePro(data) {
        return axios.post('/auth/inviteStylist', data)
    },

    importPros(data) {
        return axios.post('/auth/importStylists', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },
}
