export default {
    menu: {
        welcome: 'Hi, {name}',
        profile: 'View profile',
    },
    navigation: {
        business: 'Business',
        calendar: 'Calendar',
        payments: 'Payments',
        clients: 'Clients',
    },
    onboarding: {
        confirm: {
            'heading': 'Well done!',
            'confirm-email': {
                'text-1':
                    'We’ve sent you an email to {email} with a link to confirm your account and complete the registration process.',
                'text-2': 'If you haven’t received it, {send}.',
                'send': 'resend the confirmation email',
                'sending': 'sending…',
                'sent': 'sent! Check your inbox',
            },
            'check-inbox': 'Check your email',
        },
        places: {
            heading: 'Your account is now active',
            instruction: 'Set up your profile to start recieving books.',
            question: 'Where would you like to offer your services?',
        },
        address: {
            header: {
                heading: 'Enter your address',
            },
            places: {
                question: 'Where would you like to offer your services?',
            },
        },
        area: {
            'heading': 'In which area code?',
            'instruction': 'Where would you like to offer your services?',
            'how-far': 'How far are you willing to travel?',
            'within-radius': 'Within a radius of:',
            'x-miles': '{number} miles',
        },
        experiences: {
            heading: 'What experiences do you offer?',
        },
        subcategories: {
            heading: 'Select your specialties',
        },
        welcome: {
            heading: 'Welcome on board, {name}!',
            description: 'Complete your profile now and start receiving bookings!',
        },
    },
    profile: {
        'heading': 'Your FreeByrdz profile',
        'preview': 'Preview profile',
        'required': 'Required',
        'suggested': 'Suggested',
        'place-names': {
            pro: 'my place',
            guest: 'their place',
        },
        'hint': {
            top: 'To get started, complete your profile.',
            bottom:
                'Complete the {required} fields to activate your profile. Pro Tip: The more info you provide, the more likely you are to meet new clients.',
        },
        'delete': {
            'cta': 'Delete {name}',
            'question': 'Which one you want to delete?',
            'select-both': 'Select both',
            'deselect-both': 'Deselect both',
            'confirm': {
                message: {
                    pro: 'Are you sure you want to stop offering {name} in salon?',
                    guest: 'Are you sure you want to stop offering {name} on location?',
                    both: 'Are you sure you want to completely delete {name}?',
                },
            },
        },
        'links': {
            info: {
                heading: 'Account info',
            },
            intro: {
                heading: 'Intro/bio',
                description: 'Introduce yourself',
            },
            places: {
                heading: 'Offering experiences at…',
                description: '',
            },
            experiences: {
                heading: 'Core experiences',
                empty: 'You currently have no experiences defined. Add at least one now',
            },
            availability: {
                heading: 'Availability',
                description: 'Set your availability',
            },
            stripe: {
                heading: 'Payment info',
                description: 'Enter your payment method',
                content: 'Stripe account enabled',
            },
            addons: {
                heading: 'Add-ons',
                description: 'Add your extras',
            },
            link: {
                heading: 'Custom link',
                description: 'Set a customized profile link',
            },
            portfolio: {
                heading: 'Portfolio',
                description: 'Show off your skills',
                content: '{n} picture added | {n} pictures added',
            },
            licenses: {
                heading: 'License',
                description: 'Add your professional licence',
                content: '{name} #{number} · {state} · Exp. {expiration}',
            },
            cancellation: {
                heading: 'Cancellation & no-show policy',
                description: 'Enter your cancellation policy',
            },
        },
        'picture': {
            heading: 'Add a profile picture',
            description:
                'Pro Tip: Your pic helps personalize your profile. People want to know who they are working with. Profiles with pics tend to get more bookings.',
            add: 'Add picture',
            edit: 'Change picture',
            save: 'Save picture',
            upload: 'Upload from your library',
            take: 'Take a picture',
        },
        'info': {
            heading: 'Account info',
            email: {
                text: 'If you need to change your primary email, please {contact}',
                contact: 'contact our staff',
                message: {
                    subject: 'Email address change request',
                    body:
                        'I’d like to change the email address I use to log in to my account. Please contact me to organise this. Thanks!',
                },
            },
            save: {
                info: 'Save account info',
                password: 'Save password',
            },
            password: {
                old: 'Enter old password',
                new: 'Enter new password',
            },
        },
        'intro': {
            heading: 'Introduce yourself',
            placeholder:
                'Eg. Hi, I’m Mary. I enjoy working with my clients to create unique looks that they feel great in. From blunt bobs dyed dark to long rainbow locks with undercuts, I’ve colored and cut it all. My work has been featured in City Mag.',
        },
        'experiences': {
            'add-another': 'Add another experience',
            'go-to-experiences': 'Go to your experiences',
            'index': {
                'heading': 'Experiences',
                'actions': {
                    add: 'Add new category',
                    delete: 'Delete <b>{name}</b> category',
                },
                'delete-category': {
                    message: 'Do you really want to delete this category?',
                    note:
                        'This action will also delete all your experiences that are connected only to this category.',
                },
                'add-experiences': 'Add experiences',
                'alert': {
                    some:
                        'You havent added services to some of your categories yet. Dont forget to complete your profile.',
                    none: 'You don’t have experiences set yet. Add your experiences now!',
                    cta: 'Add experience',
                },
            },
            'category': {
                'choose-category': 'Choose category',
                'choose-subcategories': 'Choose specialties',
                'category-added': '{name} added!',
            },
            'experience': {
                steps: {
                    1: {
                        heading: 'Name your service',
                        placeholder: 'E.G. Man Bun',
                    },
                    2: {
                        heading: 'Describe {name}',
                        placeholder:
                            'E.G. Simple, practical, and easy to style, the classic man bun works best on shoulder-length or longer hair.',
                    },
                    3: {
                        'heading': 'Showcase your {name}',
                        'description':
                            'Pro Tip: Add a pic of your service. Services with pics have a higher likelihood of booking.',
                        'add-label': 'Add label (Optional)',
                    },
                    4: {
                        heading: 'How long does a {name} take to complete?',
                        description: 'We will use this infomation to set your available slots.',
                        duration: 'Average duration',
                    },
                    5: {
                        heading: 'Do you provide {name} at your place?',
                    },
                    6: {
                        heading: 'Enter {name} price at your place',
                        description: 'Make sure to include all your costs.',
                    },
                    7: {
                        heading: 'Do you also provide {name} at your clients’ location?',
                    },
                    8: {
                        heading: 'Enter {name} price at your clients’ location',
                        description: 'Make sure to include all your costs.',
                    },
                    9: {
                        heading: 'Pick a category for {name}',
                        description: 'Choose at least one.',
                    },
                    10: {
                        'offered-with': 'Offered with',
                        'delete': 'Delete {name}',
                        'drawer': {
                            'question': 'Which one you want to delete?',
                            'select-both': 'Select both',
                        },
                    },
                    11: {
                        added: '{name} saved!',
                    },
                },
            },
        },
        'addons': {
            'add-another': 'Add another add-on',
            'your-addons': 'Your add-ons',
            'go-to-addons': 'Go to your add-ons',
            'steps': {
                1: {
                    heading: 'Name your add-on',
                    placeholder: 'E.G. Scalp massage',
                },
                2: {
                    heading: 'Describe {name}',
                    placeholder:
                        'E.G. Many times, tension is felt within the head and neck, so scalp massages can be very effective as a stress reducer.',
                },
                3: {
                    heading: 'How long does a {name} take to complete?',
                    description: 'We will use this infomation to set your available slots.',
                    duration: 'Average duration',
                },
                4: {
                    heading: 'Do you provide {name} at your place?',
                },
                5: {
                    heading: 'How much does a {name} at your place cost? ',
                    description: 'Enter your total price for this service.',
                },
                6: {
                    heading: 'Do you also provide {name} at your clients’ location?',
                },
                7: {
                    heading: 'Enter {name} price at your clients’ location',
                    description: 'Make sure to include all your costs.',
                },
                8: {
                    heading: 'When is this add-on offered?',
                    description:
                        'Add-ons are great opportunities for more revenue. Here you can choose when your add-on is offered to your clients. Choose at least one.',
                },
                9: {
                    'offered-with': 'Offered with',
                    'delete': 'Delete {name}',
                    'drawer': {
                        'question': 'Which one you want to delete?',
                        'select-both': 'Select both',
                    },
                },
                10: {
                    added: '{name} saved!',
                },
            },
        },
        'link': {
            heading: 'Custom link',
            description: `
                A memorable and unique link that can make it to easy to share your profile on business cards, websites, or social media.

                Eg. FreeByrdz/p/mary-goodwin
            `,
        },
        'places': {
            heading: 'Manage your location options',
            description:
                'Freebyrdz allows you offer services at your place or visit your clients at their place (like their office, home, special event or editorial shoot).',
            status: {
                active: 'Active option',
                inactive: 'Inactive option',
            },
            confirm: {
                message: 'Are you sure to stop offering your services at this location?',
                note: `
                    It will only apply to future bookings.

                    Already booked appointments will remain active.
                `,
            },
            pro: {
                links: {
                    address: {
                        heading: 'Your Studio address',
                    },
                    questions: {
                        heading: 'Questions for clients',
                        description: 'Add pre-booking questions',
                    },
                },
                address: {
                    heading: 'Where are you located? (Your place)',
                },
                questions: {
                    heading: 'Questions for Clients coming to your place',
                    placeholder:
                        'You might have specific questions for your clients before their visit. Use this space to ask your clients questions at the time of booking. You can also use this space to provide additional info. For example: Sour Patch Kids are my favorite candy. Just sayin.',
                },
            },
            guest: {
                links: {
                    area: {
                        heading: 'Area code',
                    },
                    radius: {
                        heading: 'Travel distance radius',
                    },
                    padding: {
                        heading: 'Schedule padding',
                    },
                    questions: {
                        heading: 'Questions for clients',
                        placeholder: 'Add pre-booking questions',
                    },
                },
                area: {
                    heading: 'Pick your area code',
                    description: 'Tell us the area code where you’d like to offer services.',
                },
                padding: {
                    heading: 'Enter schedule padding',
                    description: `
                        You can specify additional time for prep and travel when visting a client in the {zipCode} area.

                        We'll use this info to set your availability in addition to calculating your approximate journey time based on traffic conditions.
                    `,
                    label: 'Padding',
                },
                radius: {
                    heading: 'How far are you willing to travel?',
                },
                questions: {
                    heading: 'On location questions for your Clients',
                    placeholder:
                        'We know that when you visit your clients, you might have specific questions before your visit. Use this space to ask your clients questions at the time of booking. For instance, is free parking available? Do you have a cat, I am allergic?',
                },
            },
        },
        'availability': {
            heading: {
                preview: 'Your availability',
                edit: 'Set your schedule',
            },
            set: {
                start: 'Set start',
                end: 'Set end',
            },
        },
        'stripe': {
            'initial': {
                heading: 'Setup a Stripe account',
                description:
                    'Let’s get you paid! Set up your Stripe payments account. We use Stripe to process payments. You tell Stripe where you want your money.',
            },
            'account-created': {
                heading: 'Stripe account created',
                description: `
                    Bingo! Your Stripe account is created!

                    Now you can sit back and watch the money roll in. Ok, not really, but now your clients can pay you.
                `,
            },
            'done-onboarding': {
                heading: 'Onboarding done',
                description: `
                    Bingo! Your Stripe account is created!

                    Now you can sit back and watch the money roll in. Ok, not really, but now your clients can pay you.
                `,
            },
            'pending-verification': {
                heading: 'Stripe account is pending verification',
                description: `
                    Your Stripe account is pending verification.

                    Stripe needs to work some magic on their end. Once your account is verified, you're good to go to accept payments.
                `,
            },
            'missing-information': {
                heading: 'We’re missing some info',
                description:
                    'Oops! It looks like we need some additional info or something is missing. Please visit your Stripe account.',
            },
            'enabled': {
                heading: 'Stripe account enabled',
                description: 'If you want to modify it please go to Stripe.',
            },
            'goto': 'Go to',
            'card': 'Credit card',
            'wire': 'Bank transfer',
        },
        'portfolio': {
            'go-to-portfolio': 'Go to your portfolio',
            'add-another': 'Add another picture',
            'index': {
                heading: 'Your portfolio',
            },
            'steps': {
                1: {
                    'heading': 'Show off your skills',
                    'description':
                        'Pro Tip: Increase your chances of getting booked by adding photos of your services. You should add photos that show the service you are advertising. This will help clarify what you are offering and reinforce your brand.',
                    'add-label': 'Add label (Optional)',
                },
                2: {
                    question:
                        'Would you like to use this image to showcase an experience you offer?',
                },
                3: {
                    delete: {
                        message: 'Are you sure to delete this picture?',
                    },
                },
                4: {
                    added: 'Picture saved!',
                },
            },
        },
        'licenses': {
            'add-new': 'Add new license',
            'your-licenses': 'Your licenses',
            'go-to-licenses': 'Go to your licenses',
            'steps': {
                1: {
                    'heading': 'Your professional license',
                    'description':
                        'If your services require a license, show it off. Clients will feel safer knowing you are licensed pro in your field.',
                    'name': 'License name',
                    'number': 'License number',
                    'state': 'Issued by',
                    'expiration': {
                        label: 'Expiration Date',
                        placeholder: 'MM.DD.YYYY',
                    },
                    'save': 'Save license',
                    'delete': 'Delete license',
                    'delete-sure': 'Are you sure to delete this license?',
                },
                2: {
                    added: '"{name}" license saved!',
                },
                3: {
                    deleted: 'License deleted!',
                },
            },
        },
        'cancellation': {
            'types': {
                flat: {
                    label: 'Flat fee',
                    note:
                        'Pro Tip: Cancellation policies help reduce no shows and last minute cancellations.',
                    input: 'Cancellation fee',
                },
                percent: {
                    label: 'Percentage',
                    note:
                        'Pro Tip: Cancellation policies help reduce no shows and last minute cancellations.',
                    input: '% total booking amount',
                },
            },
            'index': {
                'all-set': 'Awesome! Your Cancellation & No-Show policies are set!',
                'heading': 'Your cancellation & no-show policies',
                'pro': 'Your place',
                'guest': 'Their place',
                'x-hours-before': '{n} hour before appointment | {n} hours before appointment',
            },
            'policy': {
                'heading': {
                    pro: 'Set your cancellation policy for in salon appointments',
                    guest: 'Set cancellation policy for on location appointments',
                },
                'question':
                    'Would you like to charge a flat fee or percentage of the total booking amount?',
                'same-as-pro': 'Same policy as salon',
            },
            'time': {
                'heading': {
                    pro: 'Set your time cancellation term for in salon appointments',
                    guest: 'Set your time cancellation term for on location appointments',
                },
                'question': 'Within how many hours of the reservation can the client cancel?',
                'note': {
                    1: 'This setting means that all cancellations made up {before} will qualify for a full refund. In other words, no cancellation fee will apply.',
                    2: 'The Freebyrdz fee will be charged to all cancellations made within 24 hours of the reservation time. By creating your cancellation terms you, you can create less stringent cancellation policies.',
                },
                'hours-before':
                    'up until {n} hour before the reservation time | up until {n} hours before the reservation time',
            },
            'no-show': {
                'heading': {
                    pro: 'Set your no-show fee for experiences that happen at your place',
                    guest: 'Set no-show fee for on location appointments',
                },
                'same-as-pro': 'Same fee as salon',
            },
            'same-as-cancellation': {
                question: 'Would you like to charge the same fees for no-shows?',
                note: 'The fees will be charged if you state that client hasn’t shown up.',
            },
        },
    },
    appointments: {
        'no-show': 'Appointment did not occur',
        'cancelled': 'Appointment cancelled',
        'ended': {
            question: 'How did it go?',
            wrap: 'Wrap it up, get paid',
        },
        'index': {
            heading: 'Your Bookings',
            blank: 'No bookings to show.',
        },
        'unread': {
            heading: 'Hi {name}!',
            subheading: 'You have 1 new booking | You have {number} new bookings',
        },
        'show': {
            'datetime-format': '{date} · {startTime} – {endTime}',
            'message-from-x': 'Message from {name}',
            'your-questions': 'Your questions',
            'answer-from-x': 'Response from {name}',
            'call-x': 'Call {name}',
            'view-google-maps': 'View Google Maps directions',
            'view-apple-maps': 'View Apple Maps directions',
        },
        'finish': {
            'question': {
                'how-went': 'How did the appointment with {name} go?',
                'all-good': 'All good',
                'no-show': {
                    pro: 'Didn’t show up',
                    guest: 'I didn’t go',
                },
            },
            'payment-details': 'Payment details',
            'available-soon':
                'The payment will be available on your default credit card in 2-3 business days.',
            'charge': {
                charged: 'Your payment has been processed',
                revise: 'Revise and edit the ticket if necessary before confirming the payment.',
                confirm: 'Confirm ${amount}',
                discount: {
                    apply: 'Apply a discount',
                    add: '+ Add',
                    edit: '+ Edit',
                    flat: 'A fixed amount',
                    percentage: 'A ticket percentage',
                    quantity: 'Discunt quantity',
                },
            },
            'no-show': {
                pro: {
                    info: 'In accordance with our policy we won’t charge {name} for this booking.',
                    note: `
                        Keep your cancellation/no-show rate as lower as possible.
                        High cancellation/no-show rate can affect your FreeByrdz ranking.
                    `,
                },
                guest: {
                    info:
                        'In accordance with your cancellation policy we’ve transferred to your Stripe account the cancellation fee.',
                },
            },
        },
    },
    calendar: {
        heading: 'Hi {name}!',
        subheading: {
            day: 'Your daily appointments',
            week: 'Your weekly appointments',
        },
        view: {
            time: 'Time view',
            map: 'Map view',
        },
        event: {
            'delete': 'Delete event',
            'delete-sure': 'Are you sure to delete this event?',
            'event-title': 'Event title',
            'add-notes': 'Add notes',
            'client-appointment': 'It’s a client appointment',
        },
    },
    payments: {
        heading: 'Payments',
        total: 'Total earnings to date',
        future: 'Future earings *',
        view: 'View on',
        details: '* Future Earnings are based on bookings received to date.',
        blank: `
            It looks like there are
            no payments to show.
        `,
        inactive: {
            message: `
                It looks like you haven’t set your Stripe account yet.

                In order to process the payments you need to set up your payment methods in Stripe.
            `,
            cta: 'Set up your Stripe account now!',
        },
    },
    clients: {
        'heading': 'Clients',
        'index': {
            subheading: 'Manage your client book',
            empty: 'Your client book is empy.',
            actions: {
                choose: 'Choose an option',
                import: 'Import CSV',
                manually: 'Add contact manually',
            },
            search: {
                'placeholder': 'Search clients…',
                'no-results': {
                    message: `
                        We couldn’t find {query}
                        in your client book :-(

                        Check your spelling
                        or add {query} manually to the client book.
                    `,
                    cta: 'Add manually',
                },
            },
        },
        'import': {
            select: {
                heading: 'Your CSV file must have the following format',
                convinience: 'For your convinience you can download our template.',
                download: 'Download template',
                upload: 'Upload your CSV file',
            },
            uploading: 'Uploading file...',
            done: {
                'heading': 'File uploaded!',
                'x-imported': '{n} client imported | {n} clients imported',
                'invite':
                    'Any clients not already members of FreeByrdz have been automatically invited on your behalf.',
                'go-to-clients': 'Go to clients list',
            },
            error: 'Invalid format. Make sure your file has the right format.',
        },
        'show': {
            'add-client': 'Add new client',
            'add-picture': 'Add picture',
            'add-address': 'Add address',
            'add-notes': 'Add notes',
            'add-images': 'Add images',
            'save-contact': 'Save contact',
            'delete-note': 'Are you sure to delete this note?',
            'delete-image': 'Are you sure to delete this image?',
            'delete-client': 'Are you sure to delete {name} from your client list?',
            'deleted': 'Client successfully deleted.',
            'sections': {
                info: 'Basic Info',
                address: 'Address',
                notes: 'Notes',
                images: 'images',
            },
            'screens': {
                picture: {
                    heading: 'Add a picture',
                    description: 'Add a picture of the client.',
                },
                info: {
                    heading: 'Add info',
                },
                address: {
                    heading: 'Add address',
                },
                note: {
                    heading: 'Enter notes',
                    placeholder: 'Add some notes that describe this experience.',
                },
                image: {
                    heading: 'Add an image',
                    description: 'Add a image of this experience.',
                },
            },
        },
        'not-in': '{name} is not in your client book yet.',
        'add-to': 'Add {name} to client book',
        'add-guest': {
            success: '{name} is now in your client book.',
            error: '{name} is already in your client book.',
        },
    },
    dashboard: {
        'heading': 'Hi {name}!',
        'subheading': 'You’re doing great!',
        'earned': 'You earned',
        'places': {
            pro: 'Your place',
            guest: 'Guest’s place',
        },
        'views': {
            day: 'Day View',
            week: 'Week View',
            month: 'Month View',
            custom: 'Custom Preiod',
        },
        'performance': 'Performance',
        'profile-views': 'Profile<br />Views',
        'bookings-received': 'Bookings<br />Received',
        'experiences-completed': 'Experiences<br />Completed',
        'add-ons-sold': 'Add-Ons<br />Sold',
        'tips-received': 'Tips<br />Received',
        'rating': 'Rating<br />(1 review) | Rating<br />({count} reviews)',
        'custom-period': {
            'heading': 'Custom period',
            'subheading': {
                start: 'Select start date',
                end: 'Select end date',
            },
            'start-date': 'Start date',
            'end-date': 'End date',
            'select': 'Select',
        },
        'chart': {
            views: 'Profile Views',
            bookings: 'Bookings',
        },
    },
    page: {
        'x-licenses': '{name}’s license',
        'x-salon': '{name}’s spot',
        'x-work': '{name}’s work',
        'x-offers': '{name} offers',
        'x-also-offers': '{name} also offers',
        'licenses': {
            details: `
                Issued by {state} · #{number}
                Expiration #{expiration}
            `,
        },
        'locations': {
            salon: 'Salon',
            both: '{salon} books & home visits possible',
            pro: '{salon} books possible',
            guest: 'Good news! Mary can come to you!',
        },
        'offering': {
            locations: {
                both: 'Salon & home visits',
                pro: 'Salon only',
                guest: 'Home visits only',
            },
        },
        'cancellation': {
            'heading': '{name}’s Cancellation policy',
            'free-until': 'Free cancellation until {hours} before appointment time.',
            'before-appointment': '{hours} before appointment',
            'flat': '{amount} Cancellation fee',
            'percent': '{amount} of total booking amount',
            'places': {
                guest: 'Your place',
                pro: 'Their place',
            },
        },
        'no-show': {
            heading: '{name}’s No-Show policy',
            places: {
                guest: 'No show at your place',
                pro: 'No show at their place',
            },
        },
    },
}
