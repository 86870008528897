<template>
    <ProSection>
        <header class="space-y-20 flex flex-col items-center text-center">
            <IconSuccess class="h-35 w-35 text-green animate-pop" />
            <BaseHeading :heading="$t('pro.profile.portfolio.steps.4.added')" :level="3" />
        </header>

        <div class="flex-grow">
            <ProProfileAdd
                :label="$t('pro.profile.portfolio.add-another')"
                class="mx-auto"
                @click="goToStart"
            />
        </div>

        <FormButtons>
            <BaseButton
                :label="$t('pro.profile.portfolio.go-to-portfolio')"
                route="/pro/profile/portfolio"
            />
        </FormButtons>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import BaseHeading from '@/components/base/BaseHeading'
    import ProProfileAdd from '@/components/pro/profile/ProProfileAdd'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import IconSuccess from '@/assets/vectors/icon-success.svg'

    export default {
        components: {
            ProSection,
            BaseHeading,
            ProProfileAdd,
            FormButtons,
            BaseButton,
            IconSuccess,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],
    }
</script>
