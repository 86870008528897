<template>
    <GuestProfileSection
        :heading="$t('guest.profile.preferences.heading')"
        :description="$t('guest.profile.preferences.description')"
        :add-label="$t('guest.profile.preferences.add')"
        :edit-label="$t('common.modify')"
        :save-label="$t('guest.profile.preferences.save')"
        :is-editing="isEditing"
        :is-submitting="isSubmitting"
        :is-disabled="isDisabled"
        @edit="edit"
        @save="save"
        @cancel="cancel"
    >
        <template v-if="$auth.getUser().preferences" #preview>
            <BaseText :content="$auth.getUser().preferences" />
        </template>

        <template #edit>
            <FieldTextarea
                :has-autofocus="true"
                :form-data="formData"
                value-path="preferences"
                @change="change"
            />
        </template>
    </GuestProfileSection>
</template>

<script>
    import ApiGuests from '@/apis/ApiGuests'
    import MixinGuestProfileSection from '@/mixins/MixinGuestProfileSection'
    import BaseText from '@/components/base/BaseText'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'

    export default {
        components: {
            BaseText,
            FieldTextarea,
        },

        mixins: [MixinGuestProfileSection],

        methods: {
            apiCall() {
                return ApiGuests.update(
                    this.$auth.getUser().id,
                    'preferences',
                    this.formData,
                ).then(() => this.$auth.loadUser(true))
            },
        },
    }
</script>
