<template>
    <div class="space-y-20">
        <label v-for="option in options" :key="option.value" :class="radioClass">
            <input
                :checked="isOptionChecked(option)"
                :data-checked="isOptionChecked(option)"
                :value="option.value"
                :required="isRequired"
                :disabled="isDisabled"
                type="radio"
                class="absolute opacity-0 pointer-events-none"
                @focus="focus"
                @blur="blur"
                @change="change(option.value)"
            />

            <BaseCheck
                :is-selected="isOptionChecked(option)"
                :is-rounded="true"
                class="pointer-events-none"
            >
                <div class="w-12 h-12 bg-white rounded-full" />
            </BaseCheck>

            <span class="font-bold ml-15">
                {{ option.label }}
            </span>
        </label>
    </div>
</template>

<script>
    import MixinFormInput from '@/mixins/MixinFormInput'
    import MixinFormOptionable from '@/mixins/MixinFormOptionable'
    import BaseCheck from '@/components/base/BaseCheck'

    export default {
        components: {
            BaseCheck,
        },

        mixins: [MixinFormInput, MixinFormOptionable],

        computed: {
            radioClass() {
                return {
                    'flex': true,
                    'items-center': true,
                    'transition-opacity': true,
                    'group': !this.isDisabled,
                    'cursor-pointer': !this.isDisabled,
                    'cursor-not-allowed': this.isDisabled,
                    'opacity-30': this.isDisabled,
                }
            },
        },

        methods: {
            isOptionChecked({ value }) {
                if ([null, undefined].includes(this.value)) {
                    return false
                }

                return JSON.stringify(this.value) === JSON.stringify(value)
            },
        },
    }
</script>
