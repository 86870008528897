<script>
    import ProProfileExperiencesExperiencesStep6 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep6'
    import IconGuest from '@/assets/vectors/icon-guest.svg'

    export default {
        extends: ProProfileExperiencesExperiencesStep6,

        computed: {
            heading() {
                return this.$t('pro.profile.experiences.experience.steps.8.heading', {
                    name: this.formData.name,
                })
            },

            description() {
                return this.$t('pro.profile.experiences.experience.steps.8.description')
            },

            valuePath() {
                return 'guestsPlacePrice'
            },

            placeIcon() {
                return IconGuest
            },
        },
    }
</script>
