<template>
    <div class="border-t-1 border-gray-5 pt-30 flex justify-center">
        <button type="button" class="flex space-x-15 text-gray-2 font-medium" @click="invokeDelete">
            <span>{{ $t('pro.profile.delete.cta', { name }) }}</span>
            <IconDelete class="h-20" />
        </button>

        <BaseDrawer
            :is-open="isDeletePanelOpen"
            slide-from="bottom"
            @close="isDeletePanelOpen = false"
        >
            <div class="p-20 space-y-20">
                <BaseText :content="$t('pro.profile.delete.question')" class="text-center" />

                <ProProfileChecklist
                    :options="placeOptions"
                    :value="formData.places"
                    :select-label="$t('pro.profile.delete.select-both')"
                    :deselect-label="$t('pro.profile.delete.deselect-both')"
                    :has-separators="false"
                    @change="changePlaces"
                >
                    <template #label="{ option }">
                        <ProProfileSetting
                            :icon="option.icon"
                            :heading="option.heading"
                            :subheading="option.subheading"
                        />
                    </template>
                </ProProfileChecklist>

                <BaseButton
                    :label="$t('common.delete')"
                    :is-disabled="!isSubmittable"
                    @click="triggerConfirm(false)"
                />
            </div>
        </BaseDrawer>
    </div>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseText from '@/components/base/BaseText'
    import BaseButton from '@/components/base/BaseButton'
    import ProProfileSetting from '@/components/pro/profile/ProProfileSetting'
    import ProProfileChecklist from '@/components/pro/profile/ProProfileChecklist'
    import IconDelete from '@/assets/vectors/icon-delete.svg'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import IconGuest from '@/assets/vectors/icon-guest.svg'

    export default {
        components: {
            BaseDrawer,
            BaseText,
            BaseButton,
            ProProfileSetting,
            ProProfileChecklist,
            IconDelete,
        },

        mixins: [MixinForm],

        props: {
            name: { type: String, required: true },
            isProEnabled: { type: Boolean, default: false },
            isGuestEnabled: { type: Boolean, default: false },
        },

        emits: ['remove-place', 'delete'],

        data() {
            return {
                isDeletePanelOpen: false,
            }
        },

        computed: {
            placeOptions() {
                return [
                    {
                        icon: IconPro,
                        heading: this.name,
                        subheading: this.$t('pro.profile.place-names.pro'),
                        value: 'pro',
                    },
                    {
                        icon: IconGuest,
                        heading: this.name,
                        subheading: this.$t('pro.profile.place-names.guest'),
                        value: 'guest',
                    },
                ]
            },

            isSubmittable() {
                return this.isProPlaceSelected || this.isGuestPlaceSelected
            },

            isProPlaceSelected() {
                return (this.formData.places || []).includes('pro')
            },

            isGuestPlaceSelected() {
                return (this.formData.places || []).includes('guest')
            },

            areBothPlacesSelected() {
                return this.isProPlaceSelected && this.isGuestPlaceSelected
            },
        },

        methods: {
            changePlaces(value) {
                this.change({ path: 'places', value })
            },

            triggerConfirm(isDeleted) {
                const isToDeleteCompletely = this.areBothPlacesSelected || isDeleted
                const message = this.$t(
                    isToDeleteCompletely
                        ? 'pro.profile.delete.confirm.message.both'
                        : `pro.profile.delete.confirm.message.${this.formData.places?.[0]}`,
                    { name: this.name },
                )

                this.$ui.confirm({ message }).then((isConfirmed) => {
                    if (!isConfirmed) {
                        return
                    }

                    isToDeleteCompletely
                        ? this.$emit('delete')
                        : this.$emit('remove-place', this.formData.places?.[0])
                    this.isDeletePanelOpen = false
                })
            },

            invokeDelete() {
                if (
                    (this.isProEnabled && !this.isGuestEnabled) ||
                    (!this.isProEnabled && this.isGuestEnabled)
                ) {
                    return this.triggerConfirm(true)
                }

                this.isDeletePanelOpen = true
            },
        },
    }
</script>
