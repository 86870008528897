<template>
    <div class="relative bg-gray-7 border-t-1 border-gray-5">
        <ol class="absolute inset-0 overflow-y-auto">
            <li v-for="hour in hours" :key="hour" class="h-70 pl-60 flex flex-col">
                <h5 class="relative -ml-60 h-20 flex items-center">
                    <div class="absolute top-6/12 inset-x-0 bg-gray-5 h-1" />
                    <span
                        class="relative block text-12 text-gray-2 font-medium bg-gray-7 pr-5 text-right w-60 leading-none"
                    >
                        {{ formatHour(hour) }}
                    </span>
                </h5>

                <div class="flex-grow flex">
                    <div v-for="day in days" :key="day" :class="dayClass">
                        <!-- Event-generation buttons -->
                        <div class="flex flex-col absolute inset-x-0 top-10 -bottom-10">
                            <button
                                v-for="index in clickableSlotsPerHour"
                                :key="index"
                                class="w-full h-6/12 text-8"
                                @click="clickSlot(day, hour, index)"
                            ></button>
                        </div>

                        <RouterLink
                            v-if="appointmentInHour(day, hour)"
                            :to="appointmentRoute(day, hour)"
                        >
                            <ProCalendarAppointment
                                :appointment="appointmentInHour(day, hour)"
                                :size="appointmentSize(day, hour)"
                                :style="appointmentStyle(day, hour)"
                                class="absolute inset-x-0 top-0"
                            />
                        </RouterLink>

                        <ProCalendarEvent
                            v-if="eventInHour(day, hour)"
                            :event="eventInHour(day, hour)"
                            :style="eventStyle(day, hour)"
                            class="absolute inset-x-0 top-0"
                            @click="$emit('click-event', eventInHour(day, hour))"
                        />
                    </div>
                </div>
            </li>
        </ol>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import ProCalendarAppointment from '@/components/pro/calendar/ProCalendarAppointment'
    import ProCalendarEvent from '@/components/pro/calendar/ProCalendarEvent'
    import { timeToDateTime } from '@/helpers'
    import { appointmentDuration } from '@/helpers/appointment'

    export default {
        components: {
            ProCalendarAppointment,
            ProCalendarEvent,
        },

        props: {
            date: { type: Object, required: true },
            appointments: { type: Array, required: true },
            events: { type: Array, required: true },
            isWeekView: { type: Boolean, default: false },
            startHour: { type: Number, default: 0 },
            endHour: { type: Number, default: 24 },
            clickableSlotsPerHour: { type: Number, default: 2 },
        },

        emits: ['click-slot', 'click-event'],

        computed: {
            days() {
                if (!this.isWeekView) {
                    return [this.date]
                }

                const startOfWeek = this.date.startOf('week')

                return Array(7)
                    .fill(0)
                    .map((value, index) => startOfWeek.add(index, 'days'))
            },

            hours() {
                const numberOfHours = Math.abs(this.endHour - this.startHour)

                return Array(numberOfHours)
                    .fill(0)
                    .map((value, index) =>
                        dayjs()
                            .hour(this.startHour)
                            .startOf('hour')
                            .add(index, 'hours'),
                    )
            },

            dayClass() {
                return {
                    '-mt-20': true,
                    'pt-10': true,
                    '-mb-10': true,
                    'relative': true,
                    'z-above': true,
                    'w-full': !this.isWeekView,
                    'pl-10': !this.isWeekView,
                    'pr-20': !this.isWeekView,
                    'w-1/7': this.isWeekView,
                    'border-gray-5': this.isWeekView,
                    'border-l-1': this.isWeekView,
                }
            },
        },

        methods: {
            formatHour(hour) {
                return dayjs(hour).format('H A')
            },

            appointmentInHour(date, hour) {
                // TODO: Memoize this.
                return this.appointments.find(({ date: appointmentDate, startTime }) => {
                    const isSameDate = date.isSame(appointmentDate, 'day')
                    const isWithinHour = hour.isSame(timeToDateTime(startTime), 'hour')

                    return isSameDate && isWithinHour
                })
            },

            appointmentDuration,

            appointmentRoute(date, hour) {
                const appointment = this.appointmentInHour(date, hour)

                return `/pro/appointments/${appointment.id}`
            },

            appointmentStyle(date, hour) {
                const appointment = this.appointmentInHour(date, hour)
                const startTime = timeToDateTime(appointment.startTime)
                const duration = this.appointmentDuration(appointment)

                return {
                    top: `${(startTime.minute() / 60) * 100}%`,
                    height: `${(duration / 60) * 100}%`,
                }
            },

            appointmentSize(date, hour) {
                if (!this.isWeekView) {
                    return 'medium'
                }

                const appointment = this.appointmentInHour(date, hour)
                const duration = this.appointmentDuration(appointment)

                return duration < 60 ? 'mini' : 'small'
            },

            eventInHour(date, hour) {
                // TODO: Memoize this.
                return this.events.find(({ date: eventDate, startTime }) => {
                    const isSameDate = date.isSame(eventDate, 'day')
                    const isWithinHour = hour.isSame(timeToDateTime(startTime), 'hour')

                    return isSameDate && isWithinHour
                })
            },

            eventStyle(date, hour) {
                const event = this.eventInHour(date, hour)
                const startTime = timeToDateTime(event.startTime)
                const duration = this.appointmentDuration(event)

                return {
                    top: `${(startTime.minute() / 60) * 100}%`,
                    height: `${(duration / 60) * 100}%`,
                }
            },

            clickSlot(date, hour, index) {
                this.$emit('click-slot', {
                    date,
                    startTime: hour
                        .add((60 / this.clickableSlotsPerHour) * (index - 1), 'minute')
                        .format('HH:mm'),
                    endTime: hour
                        .add((60 / this.clickableSlotsPerHour) * index, 'minute')
                        .format('HH:mm'),
                })
            },
        },
    }
</script>
