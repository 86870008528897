<template>
    <section class="flex-grow flex flex-col space-y-30 pt-20">
        <slot name="top" />

        <BaseHeading v-if="heading || $slots.heading" :heading="heading" :level="4">
            <slot name="heading" />
        </BaseHeading>

        <BaseText
            v-if="description || $slots.description"
            :content="description"
            class="text-gray-2"
        >
            <slot name="description" />
        </BaseText>

        <slot />
    </section>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'

    export default {
        components: {
            BaseHeading,
            BaseText,
        },

        props: {
            heading: { type: String, default: null },
            description: { type: String, default: null },
        },
    }
</script>
