import mixpanel from 'mixpanel-browser'
import router from '@/router'

if (process.env.VUE_APP_MIXPANEL_TOKEN) {
    mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
        debug: process.env.NODE_ENV === 'development',
    })

    mixpanel.register({ Platform: 'Web' })

    router.beforeEach((to, from) => {
        mixpanel.track('Page view', { path: to.path, from: from.path })
    })
}
