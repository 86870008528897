<script>
    import ApiProClients from '@/apis/ApiProClients'
    import ProClientsAddScreensImage from '@/components/pro/clients/add/screens/ProClientsAddScreensImage'

    export default {
        extends: ProClientsAddScreensImage,

        computed: {
            apiCall() {
                return ApiProClients.createImage(
                    this.$auth.getUser().id,
                    this.client.id,
                    this.formData.file,
                )
            },
        },
    }
</script>
