<template>
    <LayoutOverlay :back-button="backButton" @back="handleBack">
        <component
            :is="currentStep.component"
            :step-data="formData"
            @change="rememberFileAndChangeData"
            @go-to="handleGoTo"
            @go-to-start="forgetFileAndGoToStart"
        >
            <template #buttons="{ isSubmittable }">
                <FormButtons>
                    <BaseLink
                        v-if="currentStep.isSkippable && !isSubmittable"
                        :label="$t('common.skip')"
                        class="p-15 block mx-auto"
                        @click="goToNextStep"
                    />

                    <BaseButton
                        v-else-if="submitLabel"
                        :label="submitLabel"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                        @click="handleNext"
                    />
                </FormButtons>
            </template>
        </component>
    </LayoutOverlay>
</template>

<script>
    import ApiProPortfolio from '@/apis/ApiProPortfolio'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import BaseLink from '@/components/base/BaseLink'
    import ProProfilePortfolioAdd1 from '@/components/pro/profile/portfolio/ProProfilePortfolioAdd1'
    import ProProfilePortfolioAdd2 from '@/components/pro/profile/portfolio/ProProfilePortfolioAdd2'
    import ProProfilePortfolioAdd3 from '@/components/pro/profile/portfolio/ProProfilePortfolioAdd3'
    import ProProfilePortfolioAdd4 from '@/components/pro/profile/portfolio/ProProfilePortfolioAdd4'

    export default {
        components: {
            LayoutOverlay,
            FormButtons,
            BaseButton,
            BaseLink,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormSteppable, MixinProExperiences],

        data() {
            return {
                file: null,
                rememberedStep: null,
            }
        },

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },

            steps() {
                return [
                    { component: ProProfilePortfolioAdd1, isSkippable: false },
                    { component: ProProfilePortfolioAdd2, isSkippable: true },
                    { component: ProProfilePortfolioAdd3, isSkippable: false },
                    { component: ProProfilePortfolioAdd4, isSkippable: false },
                ]
            },

            backButton() {
                const backLink = (this.$auth.getUser().portfolio || []).length
                    ? '/pro/profile/portfolio'
                    : '/pro/profile'

                return this.isFirstStep || this.isLastStep ? backLink : true
            },

            submitLabel() {
                if (this.rememberedStep) {
                    return this.$t('common.confirm')
                }

                if (this.isLastStep) {
                    return this.$t('pro.profile.portfolio.go-to-portfolio')
                }

                if (this.step === 3) {
                    return this.$t('common.save')
                }

                return this.$t('common.next')
            },
        },

        methods: {
            rememberFileAndChangeData(data) {
                data.file?.name && (this.file = data.file)
                this.changeData(data)
            },

            forgetFileAndGoToStart() {
                this.file = null
                this.goToStart()
            },

            handleGoTo(step) {
                this.rememberedStep = this.step
                this.goToStep(step)
            },

            handleBack() {
                if (this.rememberedStep) {
                    this.goToStep(this.rememberedStep)
                    return (this.rememberedStep = null)
                }

                this.goToPreviousStep()
            },

            handleNext() {
                if (this.rememberedStep) {
                    this.goToStep(this.rememberedStep)
                    return (this.rememberedStep = null)
                }

                if (this.step === 3) {
                    return this.save()
                }

                this.goToNextStep()
            },

            save() {
                return this.handleSubmit([
                    ApiProPortfolio.create(this.$auth.getUser().id, {
                        ...this.formData,
                        file: this.file,
                    }).then(() => this.$auth.loadUser(true)),
                ]).then(() => this.goToStep(4))
            },
        },
    }
</script>
