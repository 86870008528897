<template>
    <h5 class="text-16 text-gray-2 text-center leading-none">
        <slot>{{ label }}</slot>
    </h5>
</template>

<script>
    export default {
        props: {
            label: { type: String, default: null },
        },
    }
</script>
