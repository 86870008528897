<template>
    <LayoutOverlay :back-button="true" @back="back">
        <ProSection :heading="$t('pro.profile.places.pro.address.heading')">
            <FormWrapper>
                <FormFields>
                    <UserFormAddress
                        :form-data="formData"
                        :form-errors="formErrors"
                        data-prefix="address."
                        @change="change"
                    />
                </FormFields>

                <slot name="buttons" :is-submittable="isSubmittable">
                    <FormButtons>
                        <BaseButton
                            :label="submitLabel"
                            :is-disabled="!isSubmittable"
                            :is-processing="isSubmitting"
                            @click="save"
                        />
                    </FormButtons>
                </slot>
            </FormWrapper>
        </ProSection>
    </LayoutOverlay>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import UserFormAddress from '@/components/user/UserFormAddress'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'

    export default {
        components: {
            UserFormAddress,
            BaseButton,
            FormWrapper,
            FormFields,
            FormButtons,
        },

        mixins: [MixinProProfileSection],

        computed: {
            maxCharacters() {
                return parseInt(process.env.VUE_APP_PRO_MAXLENGTH)
            },

            isRedirectingHome() {
                return false
            },

            isSubmittable() {
                return true
            },

            submitLabel() {
                return this.$t('common.save')
            },
        },

        methods: {
            apiCall() {
                return ApiPros.update(this.$auth.getUser().id, 'prosPlaceData', this.formData)
            },
        },
    }
</script>
