<script>
    import ProProfileAddOnsStep5 from '@/components/pro/profile/addons/ProProfileAddOnsStep5'
    import IconGuest from '@/assets/vectors/icon-pro.svg'

    export default {
        extends: ProProfileAddOnsStep5,

        computed: {
            valuePath() {
                return 'guestsPlacePrice'
            },

            placeIcon() {
                return IconGuest
            },
        },
    }
</script>
