export default {
    data() {
        return {
            isSubmitting: false,
            isSubmitted: false,
            formErrors: {},
        }
    },

    computed: {
        stopProcessingAfterSubmitted() {
            return false
        },
    },
    methods: {
        triggerAutofocusInput() {
            if (this.focusAutofocusInput) {
                this.focusAutofocusInput()
            }
        },

        changeErrors(formErrors) {
            this.formErrors = formErrors
        },

        handleSubmit(promise) {
            this.isSubmitting = true
            this.isSubmitted = false

            return (Array.isArray(promise) ? Promise.all(promise) : promise)
                .then((response) => {
                    if (this.stopProcessingAfterSubmitted) {
                        this.isSubmitting = false
                        this.isDirty = false
                        this.isSubmitted = true
                        this.formErrors = {}
                    }

                    return response
                })
                .catch((error) => {
                    this.isSubmitting = false
                    this.changeErrors(error.response)
                    this.triggerAutofocusInput()
                    return Promise.reject(error)
                })
        },
    },
}
