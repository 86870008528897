<template>
    <div class="flex items-center whitespace-nowrap truncate h-50 -my-10">
        <template v-if="option.pro">
            <BaseAvatar
                :photo="option.pro.profilePicture"
                class="flex-shrink-0 h-35 w-35 rounded-full"
            />

            <span class="pl-10 truncate">
                {{ proName }}

                <span v-if="option.pro.city" :class="{ 'text-gray-2': !isHighlighted }">
                    <span class="inline-block mx-5">|</span> {{ option.pro.city }}
                </span>
            </span>
        </template>
        <template v-else>
            {{ option.label }}
        </template>
    </div>
</template>

<script>
    import { composeFullName } from '@/helpers'
    import BaseAvatar from '@/components/base/BaseAvatar'

    export default {
        components: {
            BaseAvatar,
        },

        props: {
            option: { type: Object, required: true },
            isHighlighted: { type: Boolean, default: false },
        },

        computed: {
            proName() {
                return composeFullName(this.option.pro)
            },
        },
    }
</script>
