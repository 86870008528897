<template>
    <div class="flex">
        <div v-for="day in days" :key="day.number" class="w-1/7 text-center">
            <p class="text-gray-2 text-11 font-medium h-15">{{ day.weekday }}</p>
            <p class="text-22 font-medium h-35 flex items-center justify-center">
                <BaseCircle
                    v-if="isDayCurrent(day)"
                    :label="day.number"
                    :level="2"
                    :is-selected="true"
                    class="text-purple"
                />

                <span v-else-if="hasDayAppointments(day)" class="text-purple underline">
                    {{ day.number }}
                </span>

                <span v-else class="text-gray-4">
                    {{ day.number }}
                </span>
            </p>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import BaseCircle from '@/components/base/BaseCircle'

    export default {
        components: {
            BaseCircle,
        },

        props: {
            date: { type: Object, required: true },
            appointments: { type: Array, required: true },
        },

        computed: {
            days() {
                const startOfWeek = this.date.startOf('week')

                return Array(7)
                    .fill(0)
                    .map((value, index) => {
                        const date = startOfWeek.add(index, 'days')

                        return {
                            date,
                            weekday: date.format('dd'),
                            number: date.format('D'),
                        }
                    })
            },
        },

        methods: {
            isDayCurrent({ date }) {
                return this.date.isSame(date, 'day')
            },

            hasDayAppointments({ date }) {
                return this.appointments.some((appointment) =>
                    date.isSame(dayjs(appointment.date), 'day'),
                )
            },
        },
    }
</script>
