<template>
    <div class="flex space-x-15">
        <IconLicense class="h-35" />

        <div class="space-y-5">
            <BaseHeading :heading="license.name" :level="5" />
            <BaseText :content="details" :level="2" :is-light="true" />
        </div>
    </div>
</template>

<script>
    import { getStateNameByCode } from '@/helpers'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'
    import IconLicense from '@/assets/vectors/icon-license.svg'

    export default {
        components: {
            BaseHeading,
            BaseText,
            IconLicense,
        },

        props: {
            license: { type: Object, required: true },
        },

        computed: {
            details() {
                return this.$t('pro.page.licenses.details', {
                    state: getStateNameByCode(this.license.state),
                    number: this.license.licenseNumber,
                    expiration: this.license.expirationDate,
                })
            },
        },
    }
</script>
