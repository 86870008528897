import { normalizeFormData } from '@/axios/helpers'

const transformDiscountType = (value) => {
    return {
        Percentage: 'percent',
        FlatRate: 'flat',
    }[value]
}

export default (data) => {
    data = normalizeFormData(data || {})

    return {
        ...data,
        discountType: transformDiscountType(data.discountType),
        experiences: Object.values(data.experiences),
        addOns: Object.values(data.addOns),
    }
}
