<script>
    import GuestBookStep9 from '@/components/guest/book/GuestBookStep9'

    export default {
        extends: GuestBookStep9,

        computed: {
            confirmLabel() {
                return this.$t('guest.appointments.repeat')
            },
        },
    }
</script>
