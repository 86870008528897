<template>
    <ProSection>
        <template #heading>
            <div class="flex">
                <span>{{ formData.name }}</span>

                <button type="button" class="p-5 block ml-auto" @click="goToStep('name')">
                    <IconModify class="h-20 w-20" />
                </button>
            </div>
        </template>

        <template #description>
            <div class="flex items-start">
                <span>{{ formData.description }}</span>

                <button type="button" class="p-5 block ml-auto" @click="goToStep('description')">
                    <IconModify class="h-20 w-20" />
                </button>
            </div>
        </template>

        <template #default>
            <FormWrapper>
                <FormFields class="flex-grow">
                    <ProWork
                        v-if="formData.picture"
                        :picture="formData.picture"
                        :title="formData.pictureTitle"
                        :is-picture-editable="true"
                        @edit-picture="goToStep('picture')"
                    />

                    <ProProfileExperiencesExperiencesDetails
                        :experience="formData"
                        :is-editable="true"
                        @edit="goToStep"
                    />

                    <ProProfileDelete
                        v-if="isEdited"
                        :name="formData.name"
                        :is-pro-enabled="isOfferedAt('pro')"
                        :is-guest-enabled="isOfferedAt('guest')"
                        @remove-place="removePlace"
                        @delete="deleteExperience"
                    />
                </FormFields>

                <slot name="buttons" :is-submittable="true" />
            </FormWrapper>
        </template>
    </ProSection>
</template>

<script>
    import ApiProExperiences from '@/apis/ApiProExperiences'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import ProWork from '@/components/pro/ProWork'
    import IconModify from '@/assets/vectors/icon-modify.svg'
    import ProProfileExperiencesExperiencesDetails from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesDetails'
    import ProProfileDelete from '@/components/pro/profile/ProProfileDelete'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormFields,
            ProWork,
            IconModify,
            ProProfileExperiencesExperiencesDetails,
            ProProfileDelete,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isEdited() {
                return !!this.formData.id
            },
        },

        methods: {
            isOfferedAt(place) {
                return this.formData.workPlace.includes(place)
            },

            deleteExperience() {
                ApiProExperiences.delete(this.$auth.getUser().id, this.formData.id).then(() => {
                    this.$router.push('/pro/profile/experiences')
                })
            },

            removePlace(place) {
                const isPro = place === 'pro'

                this.change({ path: 'workPlace', value: isPro ? ['guest'] : ['pro'] })

                ApiProExperiences.update(
                    this.$auth.getUser().id,
                    this.formData.id,
                    this.formData,
                ).then((addOn) => this.initForm(addOn))
            },
        },
    }
</script>
