import axios from 'axios'

export default {
    get(proId, params) {
        return axios.get(`/stylists/${proId}/licenses`, { params })
    },

    getOne(proId, licenseId) {
        return axios.get(`/stylists/${proId}/licenses/${licenseId}`)
    },

    create(proId, data) {
        return axios.post(`/stylists/${proId}/licenses`, data)
    },

    update(proId, clientId, data) {
        return axios.put(`/stylists/${proId}/licenses/${clientId}`, data)
    },

    delete(proId, clientId) {
        return axios.delete(`/stylists/${proId}/licenses/${clientId}`)
    },
}
