<template>
    <div :class="switchClass">
        <div :class="circleWrapperClass">
            <div :class="circleClass" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isSelected: { type: Boolean, default: false },
        },

        computed: {
            switchClass() {
                return {
                    'group': true,
                    'h-30': true,
                    'w-60': true,
                    'rounded-full': true,
                    'transition-colors': true,
                    'border-1': true,
                    'hoverable:hover:border-2': true,
                    'hoverable:focus:border-2': true,
                    'hoverable:group-hover:border-2': true,
                    'hoverable:group-focus:border-2': true,
                    'border-black': !this.isSelected,
                    'border-purple': this.isSelected,
                    'bg-purple': this.isSelected,
                }
            },

            circleWrapperClass() {
                return {
                    'transition-all': true,
                    'ml-30': this.isSelected,
                }
            },

            circleClass() {
                return {
                    'transition-colors': true,
                    'rounded-full': true,
                    'h-24': true,
                    'w-24': true,
                    'bg-white': true,
                    'm-2': true,
                    'hoverable:group-hover:m-1': true,
                    'hoverable:group-focus:m-1': true,
                    'border-1': true,
                    'hoverable:group-hover:border-2': true,
                    'hoverable:group-focus:border-2': true,
                    'border-black': !this.isSelected,
                    'border-transparent': this.isSelected,
                }
            },
        },
    }
</script>
