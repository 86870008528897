<template>
    <BaseOverlay :is-open="isOpen" class="flex items-center justify-center" @close="close">
        <TransitionScale>
            <div v-if="isOpen" class="relative p-50 bg-white shadow-md flex flex-col">
                <button class="ml-auto -mt-30 -mr-30" @click="close">
                    <IconClose class="w-25 h-25" />
                </button>
                <p class="w-300 font-bold text-30">
                    {{ $t('admin.dashboard.newPros.invitePro.label') }}
                </p>

                <FormWrapper class="space-y-25" @submit="submitAndClose">
                    <FieldText
                        :label="$t('common.fields.first-name')"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="firstName"
                        class="w-300"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.last-name')"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="lastName"
                        class="w-300"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.email')"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="email"
                        type="email"
                        class="w-300"
                        @change="change"
                    />

                    <BaseButton
                        class="w-300"
                        :label="$t('admin.sendInvite')"
                        :level="1"
                        :is-submitting="true"
                        :is-disabled="!isSubmittable"
                        @click="$store.dispatch('ui/confirm/confirm')"
                    />
                </FormWrapper>
            </div>
        </TransitionScale>
    </BaseOverlay>

    <InviteProSentModal :is-open="isInviteProSent" :pro="pro" @close="isInviteProSent = false" />
    <InviteProFailedModal
        :is-open="isInviteProFailed"
        :pro="pro"
        @close="isInviteProFailed = false"
    />
</template>

<script>
    import ApiAdmins from '@/apis/ApiAdmins'
    import MixinForm from '@/mixins/MixinForm'
    import BaseButton from '@/components/base/BaseButton'
    import BaseOverlay from '@/components/base/BaseOverlay'
    import FieldText from '@/components/form-fields/FieldText'
    import IconClose from '@/assets/vectors/icon-close.svg'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import TransitionScale from '@/components/transitions/TransitionScale'
    import InviteProSentModal from '@/components/admin/dashboard/invite-pro/InviteProSentModal'
    import InviteProFailedModal from '@/components/admin/dashboard/invite-pro/InviteProFailedModal'

    export default {
        components: {
            FieldText,
            BaseButton,
            BaseOverlay,
            IconClose,
            FormWrapper,
            TransitionScale,
            InviteProSentModal,
            InviteProFailedModal,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        props: {
            isOpen: { type: Boolean, default: false },
        },

        emits: ['close', 'succeeded', 'failed'],

        data() {
            return {
                pro: null,
                isInviteProSent: false,
                isInviteProFailed: false,
            }
        },

        computed: {
            isSubmittable() {
                return this.formData.email
            },

            generalError() {
                if (this.formErrors?.message && !this.formErrors?.errors) {
                    return this.formErrors?.message
                }

                return null
            },
        },

        methods: {
            close() {
                this.formErrors = {}
                this.formData = {}
                this.$emit('close')
            },

            submitAndClose() {
                this.handleSubmit(ApiAdmins.invitePro(this.formData))
                    .then(() => {
                        this.pro = this.formData
                        this.close()
                        this.isInviteProSent = true
                    })
                    .catch(() => {
                        this.pro = this.formData
                        this.close()
                        this.isInviteProFailed = true
                    })
            },
        },
    }
</script>
