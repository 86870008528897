import MiddlewareRequireLoggedIn from '@/router/middlewares/MiddlewareRequireLoggedIn'
import MiddlewareRequireOnboarded from '@/router/middlewares/MiddlewareRequireOnboarded'
import MiddlewareRequirePro from '@/router/middlewares/MiddlewareRequirePro'
import MiddlewareRequireNotOnboarded from '@/router/middlewares/MiddlewareRequireNotOnboarded'

const usualMiddlewares = [
    MiddlewareRequireLoggedIn,
    MiddlewareRequireOnboarded,
    MiddlewareRequirePro,
]

const profileSections = [
    { path: 'info', view: 'info' },
    { path: 'intro', view: 'intro' },
    { path: 'places', view: 'places' },
    { path: 'availability', view: 'availability' },
    { path: 'stripe', view: 'stripe' },
    { path: 'link', view: 'link' },
    { path: 'cancellation', view: 'cancellation' },
    { path: 'preview', view: 'preview' },
    { path: 'picture', view: 'picture' },
]

export default [
    {
        path: '/pro/onboarding',
        view: 'pro/onboarding',
        middlewares: [
            MiddlewareRequireLoggedIn,
            MiddlewareRequirePro,
            MiddlewareRequireNotOnboarded,
        ],
    },
    {
        path: '/pro/dashboard',
        view: 'pro/dashboard',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/payments',
        view: 'pro/payments',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/clients',
        view: 'pro/clients/index',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/clients/import',
        view: 'pro/clients/import',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/clients/add',
        view: 'pro/clients/add',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/clients/add-guest/:id(\\d+)',
        view: 'pro/clients/add-guest',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/clients/:id(\\d+)',
        view: 'pro/clients/_id',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/calendar',
        view: 'pro/calendar',
        middlewares: usualMiddlewares,
    },
    ...['future', 'previous', 'cancelled'].map((type) => ({
        path: `/pro/appointments/:type(${type})`,
        view: `pro/appointments/${type}`,
        middlewares: usualMiddlewares,
    })),
    {
        path: '/pro/appointments/:id(\\d+)',
        view: 'pro/appointments/_id/index',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/appointments/:id(\\d+)/no-show',
        view: 'pro/appointments/_id/no-show',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/appointments/:id(\\d+)/charge',
        view: 'pro/appointments/_id/charge',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile',
        view: 'pro/profile/index',
        middlewares: usualMiddlewares,
    },
    ...profileSections.map(({ path, view }) => ({
        path: `/pro/profile/${path}`,
        view: `pro/profile/${view}`,
        middlewares: usualMiddlewares,
    })),
    {
        path: '/pro/profile/experiences',
        view: 'pro/profile/experiences/index',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/experiences/add',
        view: 'pro/profile/experiences/add',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/experiences/:id(\\d+)/edit',
        view: 'pro/profile/experiences/_id/edit',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/experiences/categories/add',
        view: 'pro/profile/experiences/categories/add',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/experiences/categories/:id(\\d+)/edit',
        view: 'pro/profile/experiences/categories/_id/edit',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/experiences/subcategories/:id(\\d+)',
        view: 'pro/profile/experiences/subcategories/_id',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/addons',
        view: 'pro/profile/addons/index',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/addons/add',
        view: 'pro/profile/addons/add',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/addons/:id(\\d+)/edit',
        view: 'pro/profile/addons/_id/edit',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/licenses',
        view: 'pro/profile/licenses/index',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/licenses/add',
        view: 'pro/profile/licenses/add',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/licenses/:id(\\d+)/edit',
        view: 'pro/profile/licenses/_id/edit',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/portfolio',
        view: 'pro/profile/portfolio/index',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/portfolio/add',
        view: 'pro/profile/portfolio/add',
        middlewares: usualMiddlewares,
    },
    {
        path: '/pro/profile/portfolio/:id(\\d+)/edit',
        view: 'pro/profile/portfolio/_id/edit',
        middlewares: usualMiddlewares,
    },
]
