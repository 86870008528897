<template>
    <FormField v-bind="textFieldProps">
        <InputText v-bind="textInputProps" @focus="focus" @blur="blur" @change="change">
            <template #prefix>
                <slot name="prefix" />
            </template>
            <template #suffix>
                <slot name="suffix" />
            </template>
        </InputText>
    </FormField>
</template>

<script>
    import MixinFormField from '@/mixins/MixinFormField'
    import FormField from '@/components/form-elements/FormField'
    import InputText from '@/components/form-inputs/InputText'

    export default {
        components: {
            FormField,
            InputText,
        },

        mixins: [MixinFormField],

        props: {
            placeholder: { type: [String, Number], default: null },
            type: { type: String, default: 'text' },
            maxLength: { type: Number, default: null },
        },

        computed: {
            textFieldProps() {
                return {
                    ...this.fieldProps,
                    isLabelFixed: false,
                    isErrorLower: false,
                }
            },

            textInputProps() {
                // Show placeholder  only when label is small (above the field). There are three
                // cases when it happens - when input is focused, if label is specifically set
                // as small (fixed), or when there's a value.
                const hasPlaceholder = this.isFocused || this.isLabelFixed || this.value

                return {
                    ...this.inputProps,
                    placeholder: hasPlaceholder && this.placeholder,
                    type: this.type,
                    maxLength: this.maxLength,
                }
            },
        },
    }
</script>
