<template>
    <LayoutOverlay :back-button="backButton" @back="handleBack">
        <component
            :is="currentStep.component"
            :step-data="formData"
            @change="rememberFileAndChangeData"
            @go-to="handleGoTo"
            @go-to-start="forgetFileAndGoToStart"
        >
            <template #buttons="{ isSubmittable }">
                <FormButtons>
                    <BaseLink
                        v-if="currentStep.isSkippable && !isSubmittable"
                        :label="$t('common.skip')"
                        class="p-15 block mx-auto"
                        @click="goToNextStep"
                    />

                    <BaseButton
                        v-else-if="submitLabel"
                        :label="submitLabel"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                        @click="handleNext"
                    />
                </FormButtons>
            </template>
        </component>
    </LayoutOverlay>
</template>

<script>
    import ApiProExperiences from '@/apis/ApiProExperiences'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import BaseLink from '@/components/base/BaseLink'
    import ProProfileExperiencesExperiencesStep1 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep1'
    import ProProfileExperiencesExperiencesStep2 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep2'
    import ProProfileExperiencesExperiencesStep3 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep3'
    import ProProfileExperiencesExperiencesStep4 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep4'
    import ProProfileExperiencesExperiencesStep5 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep5'
    import ProProfileExperiencesExperiencesStep6 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep6'
    import ProProfileExperiencesExperiencesStep7 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep7'
    import ProProfileExperiencesExperiencesStep8 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep8'
    import ProProfileExperiencesExperiencesStep9 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep9'
    import ProProfileExperiencesExperiencesStep10 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep10'
    import ProProfileExperiencesExperiencesStep11 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep11'

    export default {
        components: {
            LayoutOverlay,
            FormButtons,
            BaseButton,
            BaseLink,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormSteppable, MixinProExperiences],

        data() {
            return {
                file: null,
                rememberedStep: null,
            }
        },

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },

            steps() {
                return [
                    { component: ProProfileExperiencesExperiencesStep1, isSkippable: false },
                    { component: ProProfileExperiencesExperiencesStep2, isSkippable: false },
                    { component: ProProfileExperiencesExperiencesStep3, isSkippable: true },
                    { component: ProProfileExperiencesExperiencesStep4, isSkippable: false },
                    { component: ProProfileExperiencesExperiencesStep5, isSkippable: false },
                    { component: ProProfileExperiencesExperiencesStep6, isSkippable: false },
                    { component: ProProfileExperiencesExperiencesStep7, isSkippable: false },
                    { component: ProProfileExperiencesExperiencesStep8, isSkippable: false },
                    { component: ProProfileExperiencesExperiencesStep9, isSkippable: false },
                    { component: ProProfileExperiencesExperiencesStep10, isSkippable: false },
                    { component: ProProfileExperiencesExperiencesStep11, isSkippable: false },
                ]
            },

            backButton() {
                return this.isFirstStep || this.isLastStep ? '/pro/profile/experiences' : true
            },

            submitLabel() {
                if (this.rememberedStep) {
                    return this.$t('common.confirm')
                }

                if (this.isLastStep) {
                    return this.$t('common.go-to-profile')
                }

                if (this.step === 10) {
                    return this.$t('common.save')
                }

                return this.$t('common.next')
            },
        },

        methods: {
            rememberFileAndChangeData(data) {
                data.file?.name && (this.file = data.file)
                this.changeData(data)
            },

            forgetFileAndGoToStart() {
                this.file = null
                this.goToStart()
            },

            handleGoTo() {},

            handleBack() {
                if (this.rememberedStep) {
                    this.goToStep(this.rememberedStep)
                    return (this.rememberedStep = null)
                }

                if (this.step === 8) {
                    return this.formData.isOfferedInPro ? this.goToStep(7) : this.goToStep(5)
                }

                if (this.step === 9) {
                    return this.formData.isOfferedInGuest ? this.goToStep(8) : this.goToStep(7)
                }

                this.goToPreviousStep()
            },

            handleNext() {
                if (this.rememberedStep) {
                    this.goToStep(this.rememberedStep)
                    return (this.rememberedStep = null)
                }

                if (this.step === 5) {
                    if (this.formData.isOfferedInPro) {
                        return this.goToStep(6)
                    }

                    this.change({ path: 'isOfferedInGuest', value: true })
                    this.change({ path: 'workPlace', value: ['guest'] })

                    return this.goToStep(8)
                }

                if (this.step === 7) {
                    return this.formData.isOfferedInGuest ? this.goToStep(8) : this.goToStep(9)
                }

                if (this.step === 10) {
                    return this.save()
                }

                this.goToNextStep()
            },

            save() {
                return this.handleSubmit([
                    ApiProExperiences.create(this.$auth.getUser().id, {
                        ...this.formData,
                        file: this.file,
                    }).then(() => this.$auth.loadUser(true)),
                ]).then(() => this.goToStep(11))
            },
        },
    }
</script>
