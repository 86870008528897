<template>
    <ul class="flex flex-wrap -m-5">
        <li v-for="(pro, index) in pros" :key="pro.id" class="p-5 w-6/12">
            <ProTile
                :pro="pro"
                :level="3"
                :class="tileClass(index)"
                @click="$emit('click-pro', pro)"
            />
        </li>
    </ul>
</template>

<script>
    import ProTile from '@/components/pro/ProTile'

    export default {
        components: {
            ProTile,
        },

        props: {
            pros: { type: Array, default: null },
        },

        emits: ['click-pro'],

        computed: {
            isProsCountOdd() {
                return this.pros.length % 2 === 1
            },
        },

        methods: {
            tileClass(index) {
                if (this.isProsCountOdd) {
                    return {
                        'pb-full': true,
                        'pt-2/12': index > 0,
                        '-mt-2/12': index > 0 && index % 2 === 0,
                    }
                }

                const isLast = index === this.pros.length - 1

                return {
                    'pb-full': true,
                    'pt-2/12': index > 0 && !isLast,
                    '-mt-2/12': index > 0 && !isLast && index % 2 === 0,
                }
            },
        },
    }
</script>
