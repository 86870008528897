<template>
    <section class="flex flex-col items-center">
        <BaseHeaderSection :heading="heading" class="mb-40" />

        <component :is="icon" :class="iconColor" class="mb-30 h-40 w-60" />

        <BaseText :content="copy" :is-light="true" class="text-center mb-30" />

        <BaseButton :label="ctaLabel" :route="ctaRoute" :level="2" />
    </section>
</template>

<script>
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseText from '@/components/base/BaseText'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            BaseHeaderSection,
            BaseText,
            BaseButton,
        },

        props: {
            heading: { type: String, required: true },
            icon: { type: Object, required: true },
            iconColor: { type: String, required: true },
            copy: { type: String, required: true },
            ctaLabel: { type: String, required: true },
            ctaRoute: { type: [Object, String], required: true },
        },
    }
</script>
