<template>
    <LayoutApp :back-button="backButton" @back="$emit('back')">
        <div class="flex-grow flex flex-col">
            <slot />

            <div class="sticky z-layout bottom-0 mt-auto -mx-20">
                <slot name="bottom" />
                <BaseNavigation :items="navigationItems" />
            </div>
        </div>
    </LayoutApp>
</template>

<script>
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseNavigation from '@/components/base/BaseNavigation'
    import IconMenuProgress from '@/assets/vectors/icon-menu-progress.svg'
    import IconMenuProgressFull from '@/assets/vectors/icon-menu-progress-full.svg'
    import IconMenuCalendar from '@/assets/vectors/icon-menu-calendar.svg'
    import IconMenuCalendarFull from '@/assets/vectors/icon-menu-calendar-full.svg'
    import IconMenuPayments from '@/assets/vectors/icon-menu-payments.svg'
    import IconMenuPaymentsFull from '@/assets/vectors/icon-menu-payments-full.svg'
    import IconMenuClients from '@/assets/vectors/icon-menu-clients.svg'
    import IconMenuClientsFull from '@/assets/vectors/icon-menu-clients-full.svg'

    export default {
        components: {
            LayoutApp,
            BaseNavigation,
        },

        props: {
            backButton: { type: [Boolean, String, Object], default: false },
        },

        emits: ['back'],

        computed: {
            navigationItems() {
                return [
                    {
                        label: this.$t('pro.navigation.business'),
                        route: '/pro/dashboard',
                        icons: {
                            default: IconMenuProgress,
                            selected: IconMenuProgressFull,
                        },
                    },
                    {
                        label: this.$t('pro.navigation.calendar'),
                        route: '/pro/calendar',
                        badge: this.$store.getters['appointments/getUnread']?.length,
                        icons: {
                            default: IconMenuCalendar,
                            selected: IconMenuCalendarFull,
                        },
                    },
                    {
                        label: this.$t('pro.navigation.payments'),
                        route: '/pro/payments',
                        icons: {
                            default: IconMenuPayments,
                            selected: IconMenuPaymentsFull,
                        },
                    },
                    {
                        label: this.$t('pro.navigation.clients'),
                        route: '/pro/clients',
                        icons: {
                            default: IconMenuClients,
                            selected: IconMenuClientsFull,
                        },
                    },
                ].map((item) => ({ ...item, isSelected: this.$route.path === item.route }))
            },
        },
    }
</script>
