<template>
    <template v-if="isEdited">
        <FieldText
            :label="$t('common.fields.first-name')"
            :form-data="formData"
            :form-errors="formErrors"
            value-path="firstName"
            @change="change"
        />

        <FieldText
            :label="$t('common.fields.last-name')"
            :form-data="formData"
            :form-errors="formErrors"
            value-path="lastName"
            @change="change"
        />

        <FieldText
            :label="$t('common.fields.phone')"
            :form-data="formData"
            :form-errors="formErrors"
            value-path="phone"
            @change="change"
        />

        <FieldText
            :label="$t('common.fields.email')"
            :form-data="formData"
            :form-errors="formErrors"
            value-path="email"
            type="email"
            @change="change"
        />
    </template>

    <section v-else-if="isEditable" class="space-y-15">
        <header class="flex items-center">
            <BaseHeading :heading="$t('pro.clients.show.sections.info')" :level="5" />
            <BaseButtonEdit class="ml-auto" @click="$emit('edit')" />
        </header>

        <BaseAttributes :attributes="attributes" />
    </section>

    <BaseAttributes v-else :attributes="attributes" />
</template>

<script>
    import MixinFormFragment from '@/mixins/MixinFormFragment'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseAttributes from '@/components/base/BaseAttributes'
    import BaseButtonEdit from '@/components/base/BaseButtonEdit'
    import FieldText from '@/components/form-fields/FieldText'

    export default {
        components: {
            BaseHeading,
            BaseAttributes,
            BaseButtonEdit,
            FieldText,
        },

        mixins: [MixinFormFragment],

        props: {
            client: { type: Object, required: true },
            isEditable: { type: Boolean, default: false },
            isEdited: { type: Boolean, default: false },
        },

        emits: ['edit'],

        computed: {
            attributes() {
                if (this.isEditable) {
                    return [
                        {
                            label: this.$t('common.fields.first-name'),
                            value: this.client.firstName,
                            align: 'left',
                        },
                        {
                            label: this.$t('common.fields.last-name'),
                            value: this.client.lastName,
                        },
                        {
                            label: this.$t('common.fields.email'),
                            value: this.client.email,
                            isWide: true,
                        },
                        {
                            label: this.$t('common.fields.phone'),
                            value: this.client.phone,
                            isWide: true,
                        },
                    ]
                }

                const attributes = [
                    {
                        label: this.$t('common.fields.email'),
                        value: this.client.email,
                        isWide: true,
                    },
                    {
                        label: this.$t('common.fields.phone'),
                        value: this.client.phone,
                        isWide: true,
                    },
                ]

                if (!this.client.address) {
                    return attributes
                }

                return [
                    ...attributes,
                    {
                        label: this.$t('common.fields.address'),
                        value: this.client.address
                            ? `
                                ${this.client.address?.address},
                                ${this.client.address?.zipCode}
                                ${this.client.address?.city},
                                ${this.client.address?.state}
                            `
                            : null,
                        isWide: true,
                    },
                ]
            },
        },
    }
</script>
