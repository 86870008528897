<template>
    <LandingContainer class="py-40 md:py-1/12">
        <img
            :src="require('@/assets/images/picture-landing-explore.jpg').default"
            class="absolute top-0 left-0 w-5/12 hidden md:block"
        />

        <div class="md:pl-6/12">
            <ul
                class="hidden md:flex flex-wrap w-full mb-1/12 lg:mb-2/12 lg:flex-nowrap lg:mt-1/12 justify-center lg:justify-between"
            >
                <li
                    v-for="category in categories"
                    :key="category.label"
                    class="text-purple w-4/12 mb-20 lg:mb-0 lg:w-auto"
                >
                    <button class="relative w-full flex flex-col items-center justify-center">
                        <component :is="category.icon" class="h-35 mb-10 xl:h-40 xl:mb-15" />
                        <BaseLabel :content="category.label" />
                    </button>
                </li>
            </ul>

            <LandingHeading
                :level="2"
                class="text-purple md:border-l-1 md:border-black md:pl-1/12 md:pb-1/12 md:w-1/12"
            >
                {{ $t('landing.index.explore.heading') }}
            </LandingHeading>
        </div>
    </LandingContainer>

    <div class="overflow-hidden">
        <LandingContainer id="splide" class="splide mb-40 md:mb-1/12">
            <div class="splide__track overflow-visible -mx-15">
                <ul class="splide__list flex">
                    <li
                        v-for="experience in experiences"
                        :key="experience.label"
                        class="splide__slide"
                    >
                        <button
                            class="rounded-xl overflow-hidden bg-white shadow-lg mx-15"
                            @click="initializeBook(experience.subCategoryId)"
                        >
                            <img :src="experience.picture" class="w-full" />

                            <p class="h-60 text-14 font-bold flex items-center px-15">
                                <span>{{ experience.label }}</span>
                            </p>
                        </button>
                    </li>
                </ul>
            </div>
        </LandingContainer>
    </div>
</template>

<script>
    import { getCategoryIcon } from '@/helpers'
    import Splide from '@splidejs/splide'
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import BaseLabel from '@/components/base/BaseLabel'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            BaseLabel,
        },

        computed: {
            categories() {
                return this.$store.getters['dictionaries/getCategories'].map((category) => ({
                    label: category.name,
                    icon: getCategoryIcon(category),
                }))
            },

            experiences() {
                return [
                    { slug: 'massage', id: 61 },
                    { slug: 'makeup', id: 41 },
                    { slug: 'lashes', id: 40 },
                    { slug: 'skincare', id: 32 },
                    { slug: 'waxing', id: 26 },
                    { slug: 'yoga', id: 52 },
                    { slug: 'blowout', id: 6 },
                    { slug: 'haircut', id: 45 },
                    { slug: 'training', id: 55 },
                    { slug: 'cycling', id: 58 },
                    { slug: 'nutrition', id: 60 },
                ].map(({ slug, id }) => ({
                    label: this.$t(`landing.index.explore.experiences.${slug}`),
                    picture: require(`@/assets/images/picture-landing-experiences-${slug}.jpg`)
                        .default,
                    subCategoryId: id,
                }))
            },
        },

        mounted() {
            this.initializeSplide()
        },

        methods: {
            initializeSplide() {
                new Splide('#splide', {
                    perPage: 4,
                    pagination: false,
                    breakpoints: {
                        1024: { perPage: 3 },
                        768: { perPage: 2 },
                        480: { perPage: 1 },
                    },
                }).mount()
            },

            initializeBook(subCategoryId) {
                this.$store.commit('continuity/setBook', {
                    search: { subCategoryIds: [subCategoryId] },
                })
                this.$router.push('/book')
            },
        },
    }
</script>
