<template>
    <LandingContainer :level="2" class="py-50 md:py-80">
        <LandingHeading :level="3" class="text-center">
            {{ $t('landing.safety.search.heading') }}
        </LandingHeading>

        <LandingBook class="mt-20 md:mt-30 hidden md:flex" />
        <LandingButton
            :label="$t('landing.book.cta')"
            :level="1"
            route="/book"
            class="mt-30 md:hidden"
        />
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingBook from '@/components/landing/LandingBook'
    import LandingButton from '@/components/landing/LandingButton'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingBook,
            LandingButton,
        },
    }
</script>
