<template>
    <div class="flex flex-col">
        <div class="flex flex-row">
            <BaseCalendarSwitch
                :year="year"
                :month="month"
                :selected-dates="selectedDates"
                class="h-400"
                @change-month="changeStartMonth"
                @click-date="clickDate"
            />
            <BaseCalendarSwitch
                :year="endYear"
                :month="endMonth"
                :selected-dates="selectedDates"
                class="ml-50 h-400"
                @change-month="changeEndMonth"
                @click-date="clickDate"
            />
        </div>

        <div>
            <GuestBookStep3When
                :options="whenOptions"
                :current="when"
                class="pb-15"
                @select="selectWhen"
            />
        </div>

        <div class="bg-white py-15 px-20 -mt-20 flex flex-row items-center">
            <GuestBookStep3Dates
                v-if="displayedDates.length"
                :dates="displayedDates"
                :is-cancelable="areDatesCancelable"
                class="flex-grow"
                @cancel="cancelPeriod"
            />

            <div class="w-200 ml-50">
                <BaseButton
                    :label="$t('common.apply')"
                    :is-disabled="!isSubmittable"
                    @click="selectPeriod"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import BaseButton from '@/components/base/BaseButton'
    import BaseCalendarSwitch from '@/components/base/BaseCalendarSwitch'
    import GuestBookStep3When from '@/components/guest/book/step-3/GuestBookStep3When'
    import GuestBookStep3Dates from '@/components/guest/book/step-3/GuestBookStep3Dates'

    export default {
        components: {
            BaseButton,
            BaseCalendarSwitch,
            GuestBookStep3When,
            GuestBookStep3Dates,
        },

        props: {
            isOpen: { type: Boolean, default: false },
        },

        emits: ['select-period', 'cancel-period'],

        data() {
            return {
                startDate: null,
                endDate: null,
                when: null,
                year: dayjs().year(),
                month: dayjs().month(),
                endYear: dayjs()
                    .add(1, 'M')
                    .year(),
                endMonth: dayjs()
                    .add(1, 'M')
                    .month(),
            }
        },

        computed: {
            subheading() {
                return this.startDate
                    ? this.$t('pro.dashboard.custom-period.subheading.end')
                    : this.$t('pro.dashboard.custom-period.subheading.start')
            },

            isSubmittable() {
                return this.startDate && this.endDate
            },

            areDatesCancelable() {
                return !!(this.startDate && this.endDate)
            },

            selectedDates() {
                if (this.startDate && this.endDate) {
                    return [
                        {
                            from: dayjs(this.startDate),
                            to: dayjs(this.endDate),
                        },
                    ]
                }

                if (this.startDate) {
                    return [
                        {
                            from: dayjs(this.startDate),
                            to: dayjs(this.startDate),
                        },
                    ]
                }

                return []
            },

            whenOptions() {
                return ['lastWeek', 'lastMonth', 'lastYear', 'yearToDate', 'allTime'].map(
                    (slug) => ({
                        label: this.$t(`admin.dashboard.dateRangePicker.when.options.${slug}`),
                        value: slug,
                    }),
                )
            },

            displayedDates() {
                return [
                    {
                        label: this.$t('pro.dashboard.custom-period.start-date'),
                        value: this.startDate
                            ? dayjs(this.startDate).format('MMM D, YYYY')
                            : this.$t('pro.dashboard.custom-period.select'),
                    },
                    {
                        label: this.$t('pro.dashboard.custom-period.end-date'),
                        value: this.endDate
                            ? dayjs(this.endDate).format('MMM D, YYYY')
                            : this.$t('pro.dashboard.custom-period.select'),
                    },
                ]
            },
        },

        methods: {
            cancelPeriod() {
                this.startDate = null
                this.endDate = null
                this.when = null
                this.$emit('cancel-period', { startDate: this.startDate, endDate: this.endDate })
            },

            selectPeriod() {
                this.when = null
                this.$emit('select-period', { startDate: this.startDate, endDate: this.endDate })
            },

            clickDate(value) {
                if (this.startDate && !this.endDate) {
                    if (this.startDate.isSameOrBefore(value)) {
                        this.endDate = value
                    } else {
                        this.endDate = this.startDate
                        this.startDate = value
                    }
                } else {
                    this.startDate = value
                    this.endDate = null
                }
            },

            changeStartMonth({ year, month }) {
                this.year = year
                this.month = month
                this.endMonth = month == 11 ? 0 : month + 1
                this.endYear = month == 11 ? year + 1 : year
            },

            changeEndMonth({ year, month }) {
                this.endYear = year
                this.endMonth = month
                this.month = month == 0 ? 11 : month - 1
                this.year = month == 0 ? year - 1 : year
            },

            selectWhen(value) {
                this.when = value

                switch (this.when) {
                    case 'lastWeek':
                        this.startDate = dayjs()
                            .subtract(1, 'w')
                            .add(1, 'd')
                        this.endDate = dayjs()
                        break
                    case 'lastMonth':
                        this.startDate = dayjs()
                            .subtract(1, 'M')
                            .add(1, 'd')
                        this.endDate = dayjs()
                        break
                    case 'lastYear':
                        this.startDate = dayjs()
                            .month(0)
                            .date(1)
                            .subtract(1, 'y')
                        this.endDate = dayjs()
                            .month(0)
                            .date(0)
                        break
                    case 'yearToDate':
                        this.startDate = dayjs()
                            .month(0)
                            .date(1)
                        this.endDate = dayjs()
                        break
                    case 'allTime':
                        this.startDate = dayjs('2021-01-01') // Since this is the starting date of our application
                        this.endDate = dayjs()
                        break
                }

                this.$emit('select-period', { startDate: this.startDate, endDate: this.endDate })
            },
        },
    }
</script>
