<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="$emit('close')">
        <BaseLinks :items="links" class="uppercase text-14 px-20 py-15" @click-item="clickView" />
    </BaseDrawer>
</template>

<script>
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseLinks from '@/components/base/BaseLinks'

    export default {
        components: {
            BaseDrawer,
            BaseLinks,
        },

        props: {
            isOpen: { type: Boolean, default: false },
        },

        emits: ['close', 'switch-view'],

        computed: {
            links() {
                return [
                    {
                        label: this.$t('pro.dashboard.views.day'),
                        event: 'day',
                    },
                    {
                        label: this.$t('pro.dashboard.views.week'),
                        event: 'week',
                    },
                    {
                        label: this.$t('pro.dashboard.views.month'),
                        event: 'month',
                    },
                    {
                        label: this.$t('pro.dashboard.views.custom'),
                        event: 'custom',
                    },
                ]
            },
        },

        methods: {
            clickView({ event }) {
                event && this.$emit('switch-view', event)
            },
        },
    }
</script>
