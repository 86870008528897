<template>
    <FormField v-bind="fieldProps">
        <InputPills v-bind="pillsInputProps" @focus="focus" @blur="blur" @change="change" />
    </FormField>
</template>

<script>
    import MixinFormField from '@/mixins/MixinFormField'
    import MixinFormOptionable from '@/mixins/MixinFormOptionable'
    import FormField from '@/components/form-elements/FormField'
    import InputPills from '@/components/form-inputs/InputPills'

    export default {
        components: {
            FormField,
            InputPills,
        },

        mixins: [MixinFormField, MixinFormOptionable],

        computed: {
            pillsInputProps() {
                return {
                    ...this.inputProps,
                    options: this.options,
                }
            },
        },
    }
</script>
