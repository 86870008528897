<template>
    <GuestVerifyUpload
        :copy-default="$t('guest.verify.provide-photo')"
        :copy-provided="$t('guest.verify.your-photo')"
        :photo="formData.picture"
        @select="selectPhoto"
        @confirm="goToNextStep"
    >
        <template #select>
            <div class="flex mb-40 space-x-10">
                <div class="w-full">
                    <div class="pt-full relative">
                        <img
                            :src="require('@/assets/images/picture-verify-valid.png').default"
                            class="absolute inset-0"
                        />
                        <span :class="iconClass">
                            <IconSuccess class="h-35 w-35 text-green-2" />
                        </span>
                    </div>
                </div>

                <div class="w-full">
                    <div class="pt-full relative">
                        <img
                            :src="require('@/assets/images/picture-verify-invalid.png').default"
                            class="absolute inset-0"
                        />
                        <span :class="iconClass">
                            <IconInvalid class="h-35 w-35 text-red" />
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </GuestVerifyUpload>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import GuestVerifyUpload from '@/components/guest/verify/GuestVerifyUpload'
    import IconSuccess from '@/assets/vectors/icon-success.svg'
    import IconInvalid from '@/assets/vectors/icon-invalid.svg'

    export default {
        components: {
            GuestVerifyUpload,
            IconSuccess,
            IconInvalid,
        },

        mixins: [MixinFormStep],

        computed: {
            iconClass() {
                return 'absolute left-6/12 top-full transform -translate-y-6/12 -translate-x-6/12 bg-white p-3 rounded-full'
            },
        },

        methods: {
            selectPhoto(value) {
                this.change({ path: 'picture', value })
            },
        },
    }
</script>
