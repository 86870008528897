import app from '@/app'

app.directive('click-outside', {
    beforeMount(el, binding) {
        el.clickOutsideEvent = (event) => {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value(event)
            }
        }

        document.addEventListener('click', el.clickOutsideEvent)
    },

    unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent)
    },
})
