<script>
    import GuestBookStep11 from '@/components/guest/book/GuestBookStep11'

    export default {
        extends: GuestBookStep11,

        computed: {
            heading() {
                return this.$t('guest.appointments.repeated')
            },
        },
    }
</script>
