<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="close">
        <header class="flex px-20 py-15">
            <button
                v-if="isEdited"
                class="flex items-center text-red space-x-10 text-14"
                @click="confirmDelete"
            >
                <IconDelete class="w-16" />
                <span>{{ $t('pro.calendar.event.delete') }}</span>
            </button>

            <BaseLink :label="$t('common.cancel')" class="ml-auto" @click="close" />
        </header>

        <FormWrapper class="px-20" @submit="submitAndClose">
            <div class="space-y-30">
                <FieldText
                    :label="$t('pro.calendar.event.event-title')"
                    :form-data="formData"
                    value-path="title"
                    @change="change"
                />

                <div class="space-y-20">
                    <BaseRow :label="$t('common.from')" :is-value-auto="true">
                        <div class="flex items-center justify-end space-x-10">
                            <span>{{ formattedDate }}</span>

                            <FieldSelect
                                :options="timeOptions"
                                :form-data="formData"
                                value-path="startTime"
                                class="w-110"
                                @change="change"
                            />
                        </div>
                    </BaseRow>

                    <div class="border-b-1 border-gray-5" />

                    <BaseRow :label="$t('common.to')" :is-value-auto="true">
                        <div class="flex items-center justify-end space-x-10">
                            <span>{{ formattedDate }}</span>

                            <FieldSelect
                                :options="timeOptions"
                                :form-data="formData"
                                value-path="endTime"
                                class="w-110"
                                @change="change"
                            />
                        </div>
                    </BaseRow>
                </div>

                <BaseHeaderDivider :heading="$t('pro.calendar.event.add-notes')" />

                <FieldTextarea
                    :placeholder="$t('pro.calendar.event.add-notes')"
                    :rows="4"
                    :form-data="formData"
                    value-path="note"
                    :has-bottom-border="false"
                    @change="change"
                />
            </div>

            <FormButtons>
                <BaseButton
                    :label="$t('common.save')"
                    :is-processing="isSubmitting"
                    :is-disabled="!isSubmittable"
                />
            </FormButtons>
        </FormWrapper>
    </BaseDrawer>
</template>

<script>
    import { timeToDateTime } from '@/helpers'
    import dayjs from 'dayjs'
    import ApiProEvents from '@/apis/ApiProEvents'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldText from '@/components/form-fields/FieldText'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'
    import FieldSelect from '@/components/form-fields/FieldSelect'
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseRow from '@/components/base/BaseRow'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseLink from '@/components/base/BaseLink'
    import BaseButton from '@/components/base/BaseButton'
    import IconDelete from '@/assets/vectors/icon-delete.svg'

    const timeOptions = () => {
        // Number of hours * 15 minute slots (4 in hour.)
        return Array(24 * 4)
            .fill(1)
            .map((value, index) => {
                const time = dayjs()
                    .hour(parseInt(index / 4))
                    .minute((index % 4) * 15)

                return {
                    label: time.format('hh:mm A'),
                    value: time.format('HH:mm'),
                }
            })
    }

    export default {
        components: {
            FormWrapper,
            FormButtons,
            FieldText,
            FieldTextarea,
            FieldSelect,
            BaseDrawer,
            BaseRow,
            BaseHeaderDivider,
            BaseLink,
            BaseButton,
            IconDelete,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        props: {
            appointments: { type: Array, required: true },
            eventData: { type: Object, default: null },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['close', 'save'],

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },

            isEdited() {
                return !!this.eventData.id
            },

            isSubmittable() {
                return (
                    this.formData.title &&
                    this.formData.date &&
                    this.formData.startTime &&
                    this.formData.endTime
                )
            },

            formattedDate() {
                return dayjs(this.eventData.day).format('MMMM D, YYYY')
            },

            timeOptions,
        },

        watch: {
            isOpen(isOpen) {
                if (!isOpen) {
                    return
                }

                this.change({ path: 'title', value: this.eventData.title })
                this.change({ path: 'note', value: this.eventData.note || undefined })
                this.change({ path: 'date', value: this.eventData.date })
                this.change({
                    path: 'startTime',
                    value: timeToDateTime(this.eventData.startTime).format('HH:mm'),
                })
                this.change({
                    path: 'endTime',
                    value: timeToDateTime(this.eventData.endTime).format('HH:mm'),
                })
            },
        },

        methods: {
            close(event) {
                this.$emit('close', event)
            },

            confirmDelete() {
                this.$ui
                    .confirm({
                        message: this.$t('pro.calendar.event.delete-sure'),
                    })
                    .then((isConfirmed) => isConfirmed && this.deleteAndClose())
            },

            deleteAndClose() {
                ApiProEvents.delete(this.$auth.getUser().id, this.eventData.id).then(
                    this.$emit('save'),
                )
            },

            submitAndClose() {
                const request = this.isEdited
                    ? ApiProEvents.update(this.$auth.getUser().id, this.eventData.id, this.formData)
                    : ApiProEvents.create(this.$auth.getUser().id, this.formData)

                this.handleSubmit(request).then(this.$emit('save'))
            },
        },
    }
</script>
