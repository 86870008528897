<template>
    <GuestBookStep
        :heading="$t('guest.book.summary.heading')"
        :subheading="$t('guest.book.summary.subheading')"
        :has-progress="hasProgress"
    >
        <template #default>
            <div class="space-y-20">
                <BaseText v-if="arePoliciesAvailable" :level="2">
                    <i18n-t tag="p" keypath="guest.book.summary.disclaimer.text">
                        <template #name>
                            {{ appointment.pro.firstName }}
                        </template>

                        <template #cancellation>
                            <BaseLink
                                :label="$t('guest.book.summary.disclaimer.cancellation')"
                                @click="isPoliciesPanelOpen = true"
                            />
                        </template>
                    </i18n-t>
                </BaseText>

                <ProAddress :pro="appointment.pro" :address="appointmentAddress" />
                <BaseDatetime :datetime="appointmentStartDateTime" />
                <BaseReceipt :positions="appointmentPrices(true)" />

                <BaseNote :content="$t('guest.book.charge-info')" />
            </div>
        </template>

        <template #bottom>
            <BaseButton :label="confirmLabel" @click="saveAndGoToNextStep" />
        </template>
    </GuestBookStep>

    <GuestBookStep9Policies
        :appointment="appointment"
        :is-open="isPoliciesPanelOpen"
        @confirm="saveAndGoToNextStep"
        @close="isPoliciesPanelOpen = false"
    />
</template>

<script>
    import MixinBookStep from '@/mixins/MixinBookStep'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import ProAddress from '@/components/pro/ProAddress'
    import BaseDatetime from '@/components/base/BaseDatetime'
    import BaseReceipt from '@/components/base/BaseReceipt'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'
    import BaseNote from '@/components/base/BaseNote'
    import BaseButton from '@/components/base/BaseButton'
    import GuestBookStep9Policies from '@/components/guest/book/step-9/GuestBookStep9Policies'

    export default {
        components: {
            ProAddress,
            BaseDatetime,
            BaseReceipt,
            BaseText,
            BaseLink,
            BaseNote,
            BaseButton,
            GuestBookStep9Policies,
        },

        mixins: [MixinBookStep, MixinAppointment],

        data() {
            return {
                isPoliciesPanelOpen: false,
            }
        },

        computed: {
            arePoliciesAvailable() {
                return !!(
                    this.appointment.pro.cancellationPolicy || this.appointment.pro.noShowPolicy
                )
            },

            confirmLabel() {
                return this.$t('guest.book.summary.confirm', {
                    price:
                        this.appointmentPrice + this.$store.getters['dictionaries/getServiceFee'],
                })
            },
        },
    }
</script>
