<template>
    <article class="h-140 p-10 flex relative">
        <RouterLink :to="appointmentRoute()" class="absolute inset-0 z-above" />
        <BaseAvatar :photo="appointment.pro?.profilePicture" class="-m-10 mr-15 h-140 w-110" />
        <div class="flex flex-col justify-between text-11 text-gray-2">
            <h4 class="text-20 text-black leading-none font-bold">
                <span class="block">{{ appointment.pro?.firstName }}</span>
                <span class="block">{{ appointment.pro?.lastName }}</span>
            </h4>
            <p :class="dateClass">{{ appointmentDate }}</p>
            <p v-if="appointmentAddress">
                {{ appointmentAddress?.address }}
                <br />
                {{ appointmentAddress?.zipCode }}
                {{ appointmentAddress?.city }},
                {{ appointmentAddress?.state }}
            </p>
        </div>
        <div class="flex flex-col ml-auto items-end">
            <component :is="appointmentCategoryIcon" class="h-35 w-35" />
            <GuestVerificationStatus
                v-if="!isCancelled && isRequiringVerificationFirst"
                :is-wrappable="true"
                :guest="$auth.getUser()"
                class="mt-auto relative z-above w-60 text-right flex flex-col items-end"
            />

            <GuestAppointmentsIndexAction :appointment="appointment" />
        </div>
    </article>
</template>

<script>
    import MixinAppointment from '@/mixins/MixinAppointment'
    import GuestVerificationStatus from '@/components/guest/GuestVerificationStatus'
    import GuestAppointmentsIndexAction from '@/components/guest/appointments/index/GuestAppointmentsIndexAction'
    import BaseAvatar from '@/components/base/BaseAvatar'

    export default {
        components: {
            GuestVerificationStatus,
            GuestAppointmentsIndexAction,
            BaseAvatar,
        },
        mixins: [MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
        },

        emits: ['repeat'],

        computed: {
            dateClass() {
                return {
                    'line-through': this.isCancelled,
                }
            },

            isRequiringVerificationFirst() {
                return this.inGuestsPlace && this.$auth.getUser().isIdVerified !== 'Verified'
            },

            appointmentDate() {
                return this.appointmentStartDateTime.format('MMMM D | H:mm A')
            },
        },

        methods: {
            appointmentRoute(isForRepeating = false) {
                return {
                    path: `/guest/appointments/${this.appointment.id}`,
                    query: isForRepeating ? { repeat: true } : undefined,
                }
            },
        },
    }
</script>
