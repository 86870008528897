<template>
    <ProSection :heading="$t('pro.clients.import.select.heading')">
        <div class="overflow-x-auto">
            <img
                :src="require('@/assets/images/picture-csv-preview.png').default"
                class="h-120 max-w-none"
            />
        </div>

        <BaseText :content="$t('pro.clients.import.select.convinience')" :is-light="true" />

        <BaseButton
            :label="$t('pro.clients.import.select.download')"
            href="https://chezmoi-bitbucket-codedeploy-bucket.s3.amazonaws.com/project-files/client.csv"
            download
        />

        <input ref="input" type="file" class="hidden" @change="selectFile" />

        <BaseButton
            :label="$t('pro.clients.import.select.upload')"
            :level="2"
            @click="triggerSelectFile"
        />
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import BaseText from '@/components/base/BaseText'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            ProSection,
            BaseText,
            BaseButton,
        },
        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        emits: ['select'],

        methods: {
            triggerSelectFile() {
                this.$refs.input.click()
            },

            selectFile(event) {
                this.$emit('select', event.target.files[0])
            },
        },
    }
</script>
