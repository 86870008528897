const transformClient = (data) => {
    return {
        ...data,
        profilePicture: data.profilePicture?.pictureLocation,
        images: (data.images || []).map(({ id, pictureLocation: picture }) => ({ id, picture })),
    }
}

export default (data) => {
    return Array.isArray(data) ? data.map(transformClient) : transformClient(data)
}
