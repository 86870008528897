export const transformPortfolio = (data) => {
    return {
        ...data,
        picture: data.picture?.pictureLocation,
    }
}

export default (data) => {
    return Array.isArray(data) ? data.map(transformPortfolio) : transformPortfolio(data)
}
