<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProProfilePlacesGuestRadius from '@/components/pro/profile/places/guest/ProProfilePlacesGuestRadius'

    export default {
        extends: ProProfilePlacesGuestRadius,

        mixins: [MixinFormStep],
    }
</script>
