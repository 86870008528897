<template>
    <svg viewBox="0 0 20 20">
        <g class="text-magenta">
            <circle
                ref="reservations"
                v-bind="reservationsProps"
                fill="transparent"
                stroke-width="1"
                class="stroke-current origin-center"
                style="transform: rotate(-90deg)"
                stroke-linecap="round"
            />
        </g>
        <g class="text-gold-dark">
            <circle
                ref="completed"
                v-bind="completedProps"
                fill="transparent"
                stroke-width="1"
                class="stroke-current origin-center"
                style="transform: rotate(-90deg)"
                stroke-linecap="round"
            />
        </g>
        <g class="text-notification">
            <circle
                ref="cancellations"
                v-bind="cancellationsProps"
                fill="transparent"
                stroke-width="1"
                class="stroke-current origin-center"
                style="transform: rotate(-90deg)"
                stroke-linecap="round"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        props: {
            reservations: { type: Number, required: true },
            completed: { type: Number, required: true },
            cancellations: { type: Number, required: true },
        },

        data() {
            return {
                reservationsRadius: 6.4,
                completedRadius: 5.2,
                cancellationsRadius: 4,
            }
        },

        computed: {
            reservationsProps() {
                return {
                    r: this.reservationsRadius,
                    cx: 10,
                    cy: 10,
                }
            },

            completedProps() {
                return {
                    r: this.completedRadius,
                    cx: 10,
                    cy: 10,
                }
            },

            cancellationsProps() {
                return {
                    r: this.cancellationsRadius,
                    cx: 10,
                    cy: 10,
                }
            },
        },

        watch: {
            reservations() {
                this.showPercentages()
            },

            completed() {
                this.showPercentages()
            },

            cancellations() {
                this.showPercentages()
            },
        },

        mounted() {
            this.showPercentages()
        },

        methods: {
            showPercentages() {
                this.generateChart('reservations', this.reservations, this.reservationsRadius)
                this.generateChart('completed', this.completed, this.completedRadius)
                this.generateChart('cancellations', this.cancellations, this.cancellationsRadius)
            },

            generateChart(ref, percentage, radius) {
                const CIRCUMFERENCE = 2 * Math.PI * radius

                this.$refs[ref].style.strokeDasharray = CIRCUMFERENCE
                this.$refs[ref].style.strokeDashoffset = CIRCUMFERENCE * (1 - percentage / 100)
            },
        },
    }
</script>
