<template>
    <LandingContainer class="pt-30 md:pt-80">
        <div class="relative z-above w-8/12 sm:w-7/12 md:w-8/12 lg:w-7/12 xl:w-6/12">
            <LandingHeading :level="1" class="mb-20 text-purple">
                {{ $t('landing.safety.intro.heading') }}
            </LandingHeading>

            <LandingText :content="$t('landing.safety.intro.description')" />
        </div>

        <div class="relative z-above xs:w-10/12 md:w-8/12 lg:w-7/12 xl:w-6/12 flex pt-40 md:pt-80">
            <div class="w-3/12 flex-shrink-0 lg:w-4/12">
                <img :src="quotePicture" class="rounded-xl" />
            </div>

            <LandingQuote
                :content="$t('landing.index.safety.quote.content')"
                :author="$t('landing.index.safety.quote.author')"
                :level="1"
                class="w-full ml-30 sm:ml-40 md:ml-1/12 md:w-8/12"
            />
        </div>

        <div
            class="absolute w-6/12 -right-2/12 top-0 md:w-5/12 md:-right-1/12 md:top-1/12 lg:right-0 lg:ml-auto"
        >
            <img :src="sectionPicture" />
        </div>
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingText from '@/components/landing/LandingText'
    import LandingQuote from '@/components/landing/LandingQuote'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingText,
            LandingQuote,
        },

        computed: {
            sectionPicture() {
                return require('@/assets/images/picture-landing-safety-identity.jpg').default
            },

            quotePicture() {
                return require('@/assets/images/picture-landing-safety-person.jpg').default
            },
        },
    }
</script>
