<template>
    <ProOnboardingStep
        :heading="$t('pro.onboarding.area.heading')"
        :description="$t('pro.onboarding.area.instruction')"
        @submit="goToNextStep"
    >
        <FormFields>
            <template v-if="isAreaSelected">
                <div class="flex justify-center">
                    <BasePill
                        :label="formData.areaZipCode"
                        :is-selected="true"
                        :is-closable="true"
                        @close="clearFormData"
                    />
                </div>

                <FormLegend :label="$t('pro.onboarding.area.how-far')" />

                <FieldRange
                    :label="$t('pro.onboarding.area.within-radius')"
                    :step="1"
                    :range-from="0"
                    :range-to="100"
                    :label-from="$t('pro.onboarding.area.x-miles', { number: 0 })"
                    :label-to="$t('pro.onboarding.area.x-miles', { number: 100 })"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="areaRadius"
                    @change="change"
                />

                <BaseMap ref="map" :is-draggable="false" class="h-300" />
            </template>

            <FieldSuggest
                v-else
                :label="$t('common.fields.zip-code')"
                :has-autofocus="true"
                :suggestions-call="addressSuggestions"
                :form-data="formData"
                :form-errors="formErrors"
                value-path="areaZipCode"
                @select="selectAddressAndChange"
            />
        </FormFields>

        <FormButtons>
            <BaseButton :label="$t('common.next')" :is-disabled="!isSubmittable" />
        </FormButtons>
    </ProOnboardingStep>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import MixinFormFocusable from '@/mixins/MixinFormFocusable'
    import MixinProProfileArea from '@/mixins/MixinProProfileArea'
    import ProOnboardingStep from '@/components/pro/onboarding/ProOnboardingStep'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import ApiGoogle from '@/apis/ApiGoogle'
    import BasePill from '@/components/base/BasePill'
    import FormLegend from '@/components/form-elements/FormLegend'
    import FieldSuggest from '@/components/form-fields/FieldSuggest'
    import FieldRange from '@/components/form-fields/FieldRange'
    import BaseMap from '@/components/base/BaseMap'

    export default {
        components: {
            ProOnboardingStep,
            FormFields,
            FormButtons,
            BaseButton,
            BasePill,
            FormLegend,
            FieldSuggest,
            FieldRange,
            BaseMap,
        },

        mixins: [MixinFormStep, MixinFormFocusable, MixinProProfileArea],

        computed: {
            isSubmittable() {
                return this.formData.areaZipCode && this.formData.areaRadius
            },

            addressSuggestions() {
                return ApiGoogle.getAddressSuggestions
            },
        },

        methods: {
            selectAddressAndChange({ value: placeId }) {
                ApiGoogle.getAddressZipCode(placeId)
                    .then((zipCode) => {
                        this.change({ path: 'areaZipCode', value: zipCode })
                        this.change({
                            path: 'areaRadius',
                            value: process.env.VUE_APP_AREA_RADIUS_DEFAULT,
                        })
                    })
                    .catch((errors) => {
                        this.focusAutofocusInput()
                        this.changeErrors(errors)
                    })
            },

            clearFormData() {
                this.change({ path: 'areaZipCode', value: null })
                this.change({ path: 'areaRadius', value: null })
            },
        },
    }
</script>
