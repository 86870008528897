<template>
    <LayoutOverlay v-if="isScreenOpen" :back-button="true" @back="back">
        <component
            :is="screenComponent"
            v-if="isScreenOpen"
            :client="client"
            :screen-data="screenData"
            @save="closeScreen"
        />
    </LayoutOverlay>

    <LayoutApp v-else back-button="/pro/clients">
        <FormWrapper class="relative">
            <ProClientsShowActions
                :client="client"
                class="absolute top-0 right-0"
                @edit="isEditable = true"
                @delete="deleteClient"
            />

            <FormFields class="pb-20">
                <ProClientsShowSectionsPicture
                    :client="client"
                    :is-editable="isEditable"
                    @add="openScreen('picture', {})"
                    @edit="openScreen('picture', {})"
                />

                <ProClientsShowSectionsInfo
                    :client="client"
                    :is-editable="isEditable"
                    :is-edited="isEdited"
                    :form-data="formData"
                    :form-errors="formErrors"
                    @change="change"
                    @edit="isEdited = true"
                />

                <ProClientsShowSectionsAddress
                    :client="client"
                    :is-editable="isEditable"
                    @add="openScreen('address', formData)"
                    @edit="openScreen('address', formData)"
                />

                <ProClientsShowSectionsNotes
                    :client="client"
                    :is-editable="isEditable"
                    @add="openScreen('note', $event)"
                    @edit="openScreen('note', $event)"
                    @delete="deleteNote($event)"
                />

                <ProClientsShowSectionsImages
                    :client="client"
                    :is-editable="isEditable"
                    @add="openScreen('image', $event)"
                    @edit="openScreen('image', $event)"
                    @delete="deleteImage($event)"
                />
            </FormFields>

            <TransitionSlide from="bottom">
                <FormButtons v-if="isDirty">
                    <BaseButton
                        :label="$t('common.save')"
                        :is-processing="isSubmitting"
                        :is-disabled="!isSubmittable"
                        @click="save"
                    />
                </FormButtons>
            </TransitionSlide>
        </FormWrapper>
    </LayoutApp>
</template>

<script>
    import { shallowRef } from 'vue'
    import ApiProClients from '@/apis/ApiProClients'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import TransitionSlide from '@/components/transitions/TransitionSlide'
    import ProClientsShowSectionsPicture from '@/components/pro/clients/show/sections/ProClientsShowSectionsPicture'
    import ProClientsShowActions from '@/components/pro/clients/show/ProClientsShowActions'
    import ProClientsShowSectionsInfo from '@/components/pro/clients/show/sections/ProClientsShowSectionsInfo'
    import ProClientsShowSectionsAddress from '@/components/pro/clients/show/sections/ProClientsShowSectionsAddress'
    import ProClientsShowSectionsNotes from '@/components/pro/clients/show/sections/ProClientsShowSectionsNotes'
    import ProClientsShowSectionsImages from '@/components/pro/clients/show/sections/ProClientsShowSectionsImages'
    import ProClientsShowScreensPicture from '@/components/pro/clients/show/screens/ProClientsShowScreensPicture'
    import ProClientsShowScreensAddress from '@/components/pro/clients/show/screens/ProClientsShowScreensAddress'
    import ProClientsShowScreensImage from '@/components/pro/clients/show/screens/ProClientsShowScreensImage'
    import ProClientsShowScreensNote from '@/components/pro/clients/show/screens/ProClientsShowScreensNote'

    export default {
        components: {
            LayoutApp,
            LayoutOverlay,
            BaseHeaderSection,
            BaseButton,
            FormWrapper,
            FormFields,
            FormButtons,
            TransitionSlide,
            ProClientsShowSectionsPicture,
            ProClientsShowActions,
            ProClientsShowSectionsInfo,
            ProClientsShowSectionsAddress,
            ProClientsShowSectionsNotes,
            ProClientsShowSectionsImages,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        async loadData({ $auth, $route }) {
            const client = await ApiProClients.getOne($auth.getUser().id, $route.params.id)

            return { formData: client }
        },

        data() {
            return {
                screenComponent: null,
                screenData: null,
                isEditable: false,
                isEdited: false,
            }
        },

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },

            isSubmittable() {
                return (
                    this.formData.firstName &&
                    this.formData.lastName &&
                    this.formData.phone &&
                    this.formData.email
                )
            },

            isScreenOpen() {
                return !!this.screenComponent
            },

            client() {
                return this.formData
            },
        },

        methods: {
            back() {
                this.screenComponent = null
                this.screenData = null
            },

            closeScreen(changes) {
                changes.forEach((payload) => this.change(payload, false))
                this.back()
            },

            openScreen(slug, data) {
                this.screenData = data
                this.screenComponent = shallowRef(
                    {
                        picture: ProClientsShowScreensPicture,
                        address: ProClientsShowScreensAddress,
                        image: ProClientsShowScreensImage,
                        note: ProClientsShowScreensNote,
                    }[slug],
                )
            },

            deleteNote({ index, note }) {
                const notes = Object.values(this.client.notes)
                notes.splice(index, 1)
                this.change({ path: 'notes', value: notes }, false)

                ApiProClients.deleteNote(this.$auth.getUser().id, this.client.id, note.id)
            },

            deleteImage({ index, image }) {
                const images = Object.values(this.client.images)
                images.splice(index, 1)
                this.change({ path: 'images', value: images }, false)

                ApiProClients.deleteImage(this.$auth.getUser().id, this.client.id, image.id)
            },

            deleteClient() {
                ApiProClients.delete(this.$auth.getUser().id, this.client.id).then(() => {
                    this.$ui.flash.success(this.$t('pro.clients.show.deleted'), true)
                    this.$router.push('/pro/clients')
                })
            },

            save() {
                this.handleSubmit(
                    ApiProClients.update(
                        this.$auth.getUser().id,
                        this.client.id,
                        'info',
                        this.formData,
                    ),
                ).then(() => (this.isEdited = false))
            },
        },
    }
</script>
