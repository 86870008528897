<template>
    <BaseText :content="$t('pro.payments.inactive.message')" />

    <div class="mt-10">
        <BaseLink :label="$t('pro.payments.inactive.cta')" route="/pro/stripe" />
    </div>
</template>

<script>
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'

    export default {
        components: {
            BaseText,
            BaseLink,
        },
    }
</script>
