<template>
    <article :class="eventClass">
        <div class="absolute -inset-y-1 -left-1 w-4 bg-purple" />
        <div class="w-full">
            <h4 class="font-bold text-13">
                {{ event.title }}
            </h4>
            <p class="text-12">
                {{ event.note }}
            </p>
        </div>
    </article>
</template>

<script>
    import { timeToDateTime } from '@/helpers'

    export default {
        props: {
            event: { type: Object, required: true },
        },

        computed: {
            eventDuration() {
                const startTime = timeToDateTime(this.event.startTime)
                const endTime = timeToDateTime(this.event.endTime)

                return endTime.diff(startTime, 'minute')
            },

            eventClass() {
                return {
                    'flex': true,
                    'items-start': true,
                    'w-full': true,
                    'relative': true,
                    'px-5': true,
                    'py-5': this.eventDuration >= 30,
                    'pl-10': true,
                    'bg-purple-light-3': true,
                    'border-1': true,
                    'border-purple-light-3': true,
                    'text-purple-dark': true,
                    'text-left': true,
                }
            },
        },
    }
</script>
