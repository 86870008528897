export default {
    methods: {
        indicateScrollability() {
            this.$nextTick(() => {
                if (!this.$refs.scrollable) {
                    return
                }

                const initialScrollTop = this.$refs.scrollable.scrollTop
                this.$refs.scrollable.scrollTo(0, initialScrollTop + 1)
                this.$refs.scrollable.scrollTo(0, initialScrollTop)
            })
        },
    },

    watch: {
        isOpen(isOpen) {
            if (isOpen) {
                this.indicateScrollability()
            }
        },
    },
}
