import intersection from 'lodash/intersection'

export default {
    computed: {
        pro() {
            return this.$auth.getUser()
        },

        proSubCategories() {
            return this.pro.subCategories || []
        },

        proSubCategoryIds() {
            return this.proSubCategories.map(({ id }) => id)
        },

        proCategories() {
            return this.$store.getters['dictionaries/getCategories'].filter(
                ({ subCategories }) =>
                    intersection(
                        subCategories.map(({ id }) => id),
                        this.proSubCategoryIds,
                    ).length > 0,
            )
        },

        proExperiences() {
            return this.pro.experiences || []
        },

        proAddOns() {
            return this.pro.addOns || []
        },

        proExperienceSubCategoryIds() {
            return this.proExperiences.reduce(
                (subCategoryIds, { subCategories }) =>
                    subCategoryIds.concat(subCategories.map(({ id }) => id)),
                [],
            )
        },
    },
}
