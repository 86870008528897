<template>
    <div class="bg-purple relative overflow-hidden">
        <LandingContainer>
            <div class="relative z-above text-white pt-100 pb-30 md:pt-150 md:100 xl:pb-150">
                <LandingHeading :level="1" class="w-9/12 sm:w-6/12 md:w-8/12">
                    {{ $t('landing.pros.intro.heading.1') }}
                    <br class="hidden xl:block" />
                    {{ $t('landing.pros.intro.heading.2') }}
                </LandingHeading>

                <LandingText
                    :content="$t('landing.pros.intro.description')"
                    class="mt-20 w-6/12 xs:w-8/12 sm:w-7/12 md:w-5/12"
                />

                <LandingButton
                    :label="$t('landing.pros.join')"
                    :is-inverted="true"
                    route="/auth/register/pro"
                    class="mt-30 md:inline-flex"
                />
            </div>
        </LandingContainer>

        <div class="absolute top-70 h-7/12 sm:h-auto sm:top-0 -right-1/12 bottom-0 lg:right-0">
            <img :src="sectionPicture" class="h-full" />
        </div>
    </div>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingText from '@/components/landing/LandingText'
    import LandingButton from '@/components/landing/LandingButton'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingText,
            LandingButton,
        },

        computed: {
            sectionPicture() {
                return require('@/assets/images/picture-landing-pros-intro.jpg').default
            },
        },
    }
</script>
