<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="$emit('close')">
        <div class="px-20 pt-20">
            <div class="space-y-20">
                <button
                    v-for="(place, index) in places"
                    :key="index"
                    type="button"
                    class="flex w-full border-b-1 border-gray-5 pb-15"
                    @click="selectedPlace = place.slug"
                >
                    <div>
                        <div class="flex space-x-10 items-center">
                            <BaseCheck
                                :is-selected="selectedPlace === place.slug"
                                :is-rounded="true"
                            >
                                <div class="w-12 h-12 bg-white rounded-full" />
                            </BaseCheck>

                            <p class="font-bold">{{ place.heading }}</p>
                        </div>

                        <div class="mt-10 ml-40 text-gray-3 text-14 font-medium flex space-x-10">
                            <IconTime class="h-18 w-18" />
                            <p>
                                {{
                                    $tc('common.n-minutes', experience.duration, {
                                        n: experience.duration,
                                    })
                                }}
                            </p>
                            <component :is="place.icon" class="h-18 w-18" />
                            <p>{{ place.details }}</p>
                        </div>
                    </div>

                    <p class="ml-auto font-bold text-20">${{ place.price }}</p>
                </button>
            </div>

            <div class="h-90">
                <TransitionSlide from="bottom">
                    <FormButtons v-if="confirmLabel">
                        <BaseButton
                            :label="confirmLabel"
                            :is-disabled="!isSubmittable"
                            @click="$emit('confirm', selectedPlace)"
                        />
                    </FormButtons>
                </TransitionSlide>
            </div>
        </div>
    </BaseDrawer>
</template>

<script>
    import TransitionSlide from '@/components/transitions/TransitionSlide'
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseButton from '@/components/base/BaseButton'
    import BaseCheck from '@/components/base/BaseCheck'
    import FormButtons from '@/components/form-elements/FormButtons'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import IconGuest from '@/assets/vectors/icon-guest.svg'
    import IconTime from '@/assets/vectors/icon-time.svg'

    export default {
        components: {
            TransitionSlide,
            BaseDrawer,
            BaseButton,
            BaseCheck,
            FormButtons,
            IconTime,
        },

        props: {
            experience: { type: Object, default: null },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['confirm', 'close'],

        data() {
            return {
                selectedPlace: null,
            }
        },

        computed: {
            isSubmittable() {
                return !!this.selectedPlace
            },

            confirmLabel() {
                if (this.selectedPlace === 'pro') {
                    return this.$t('guest.book.who.place.confirm')
                }

                if (this.selectedPlace === 'guest') {
                    return this.$t('guest.book.who.place.confirm')
                }

                return false
            },

            places() {
                return [
                    {
                        heading: this.$t('guest.book.who.place.headings.pro', {
                            name: this.experience.name,
                        }),
                        icon: IconPro,
                        details: this.$t('guest.book.who.place.details.pro'),
                        price: this.experience.prosPlacePrice,
                        slug: 'pro',
                    },
                    {
                        heading: this.$t('guest.book.who.place.headings.guest', {
                            name: this.experience.name,
                        }),
                        icon: IconGuest,
                        details: this.$t('guest.book.who.place.details.guest'),
                        price: this.experience.guestsPlacePrice,
                        slug: 'guest',
                    },
                ]
            },
        },

        watch: {
            isOpen(isOpen) {
                isOpen && (this.selectedPlace = null)
            },
        },
    }
</script>
