<template>
    <ul class="flex flex-wrap">
        <li v-for="(item, index) in items" :key="index" :class="itemClass(index)">
            <p class="text-18 font-bold h-25 flex items-center">
                <span>{{ item.value }}</span>
            </p>

            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="text-gray-2 text-12" v-html="item.label" />
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            stats: { type: Object, required: true },
        },

        computed: {
            items() {
                return [
                    {
                        label: this.$t('pro.dashboard.profile-views'),
                        value: this.stats.totalProfileViews,
                    },
                    {
                        label: this.$t('pro.dashboard.bookings-received'),
                        value: this.stats.allAppointmentsCount,
                    },
                    {
                        label: this.$t('pro.dashboard.experiences-completed'),
                        value: this.stats.completedAppointmentsCount,
                    },
                    { label: this.$t('pro.dashboard.add-ons-sold'), value: this.stats.addOnsSold },
                    {
                        label: this.$t('pro.dashboard.tips-received'),
                        value: this.stats.tipsReceived,
                    },
                    {
                        label: this.$tc('pro.dashboard.rating', this.stats.reviewsCount, {
                            count: this.stats.reviewsCount,
                        }),
                        value: `${this.stats.rating} ★`,
                    },
                ]
            },
        },

        methods: {
            itemClass(index) {
                return {
                    'w-4/12': true,
                    'pr-20': true,
                    'mt-30': index > 2,
                    'border-t-1': index > 2,
                    'border-gray-5': index > 2,
                    'pt-30': index > 2,
                }
            },
        },
    }
</script>
