import cloneDeep from 'lodash/cloneDeep'
import MixinFormFocusable from '@/mixins/MixinFormFocusable'

function setValue(object, path, value) {
    let settablePath = path

    if (path.indexOf('.') !== -1) {
        const parts = path.split('.')
        settablePath = parts.splice(-1, 1)
        parts.forEach((part) => {
            if (!object[part]) {
                object[part] = {}
            }

            object = object[part]
        })
    }

    object[settablePath] = value
}

export default {
    mixins: [MixinFormFocusable],

    data() {
        return {
            formData: {},
            isDirty: false,
        }
    },

    methods: {
        initForm(data) {
            this.formData = cloneDeep(data)
        },

        change({ path, value }, markAsDirty = true) {
            setValue(this.formData, path, value)
            markAsDirty && (this.isDirty = true)
        },
    },
}
