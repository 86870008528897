<template>
    <ol class="flex flex-wrap -mx-5 -my-10">
        <li
            v-for="attribute in attributes"
            :key="attribute.label"
            :class="attributeClass(attribute)"
        >
            <p class="text-11 font-bold tracking-widest uppercase pb-5">
                {{ attribute.label }}
            </p>
            <p class="truncate overflow-ellipsis text-gray-1">
                {{ attribute.value || '—' }}
            </p>
        </li>
    </ol>
</template>

<script>
    export default {
        props: {
            attributes: { type: Array, required: true },
        },

        methods: {
            attributeClass({ align, isWide }) {
                return {
                    'w-6/12': !isWide,
                    'w-full': isWide,
                    'px-5': true,
                    'py-10': true,
                    'text-right': align === 'right',
                }
            },
        },
    }
</script>
