import axios from 'axios'
import transformProFromApi from '@/apis/transforms/transformProFromApi'
import transformProAddOnToApi from '@/apis/transforms/transformProAddOnToApi'

const transforms = {
    transformRequest: [transformProAddOnToApi, ...axios.defaults.transformRequest],
    transformResponse: [...axios.defaults.transformResponse, transformProFromApi],
}

export default {
    create(id, data) {
        return axios.post(`/stylists/${id}/addOns`, data, transforms)
    },

    update(id, addOnId, data) {
        return axios.put(`/stylists/${id}/addOns/${addOnId}`, data, transforms)
    },

    delete(id, addOnId) {
        return axios.delete(`/stylists/${id}/addOns/${addOnId}`)
    },
}
