import { transformPro } from '@/apis/transforms/transformProFromApi'
import { transformGuest } from '@/apis/transforms/transformGuestFromApi'

export const transformWorkPlace = (workPlace) => {
    return {
        ProsPlace: 'pro',
        GuestsPlace: 'guest',
    }[workPlace]
}

const transformAppointment = (data, additional) => {
    return {
        ...data,
        stylist: undefined,
        pro: data.stylist && transformPro(data.stylist),
        guest: data.guest && transformGuest(data.guest),
        experiences: data.experiences || [],
        addOns: data.addOns || [],
        workPlace: data.workPlace && transformWorkPlace(data.workPlace),
        ...additional,
    }
}

export default (additional) => (data) => {
    return Array.isArray(data)
        ? data.map((appointment) => transformAppointment(appointment, additional))
        : transformAppointment(data, additional)
}
