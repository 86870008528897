<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="text-20 text-gray-3 space-y-10" v-html="sanitizeAndFormatText(subheading)" />
</template>

<script>
    import { sanitizeAndFormatText } from '@/helpers'

    export default {
        props: {
            subheading: { type: String, default: null },
        },

        methods: {
            sanitizeAndFormatText,
        },
    }
</script>
