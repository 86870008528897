<template>
    <div class="sticky z-floating top-70 bg-white px-20 -mx-20">
        <BaseHeaderSection
            :heading="heading"
            :subheading="subheading"
            :description="description"
            class="mb-20"
        />

        <slot name="top" />
    </div>

    <FormWrapper @submit="$emit('submit')">
        <div class="pb-20">
            <slot />
        </div>

        <TransitionSlide :is-appear="false" from="bottom">
            <div
                v-if="$slots.bottom"
                :class="{
                    '-shadow-md': true,
                    'z-layout': true,
                    'bg-white': true,
                    'py-10': true,
                    'px-20': true,
                    'sticky': true,
                    '-mx-20': true,
                    'bottom-70': hasProgress,
                    'bottom-0': !hasProgress,
                    'space-y-20': true,
                    'mt-auto': true,
                }"
            >
                <slot name="bottom" />
            </div>
        </TransitionSlide>

        <!-- INFO: Below dynamic classes has to be here as they depend on $slots and $slots are not detected by computed values. -->
        <div
            v-if="hasProgress"
            :class="{
                'transition': true,
                'duration-250': true,
                'bg-white': true,
                'h-10': true,
                '-mx-20': true,
                'fixed': true,
                'inset-x-0': true,
                'z-drawer': true,
                'bottom-70': true,
                'opacity-0': !$slots.bottom,
            }"
        />
    </FormWrapper>
</template>

<script>
    import TransitionSlide from '@/components/transitions/TransitionSlide'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'

    export default {
        components: {
            TransitionSlide,
            BaseHeaderSection,
            FormWrapper,
        },

        props: {
            heading: { type: String, default: null },
            subheading: { type: String, default: null },
            description: { type: String, default: null },
            hasProgress: { type: Boolean, required: true },
        },

        emits: ['submit'],
    }
</script>
