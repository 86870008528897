<template>
    <div ref="map" />
</template>

<script>
    import Leaflet from 'leaflet'

    export default {
        props: {
            zoom: { type: Number, default: 13 },
            center: { type: Array, default: () => [0, 0] },
            isDraggable: { type: Boolean, default: true },
            hasZoomControl: { type: Boolean, default: false },
        },

        data() {
            return {
                leaflet: null,
            }
        },

        watch: {
            zoom(value) {
                this.leaflet.setZoom(value)
            },

            isDraggable() {
                // TODO: Add support for modifying isDraggable via prop later on.
            },
        },

        mounted() {
            this.leaflet = Leaflet.map(this.$refs.map, {
                attributionControl: false,
                zoomControl: this.hasZoomControl,
                scrollWheelZoom: false,
                center: this.center,
                zoom: this.zoom,
                zoomSnap: 0.1,
                dragging: this.isDraggable,
                maxZoom: 18,
            })

            Leaflet.tileLayer(
                'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
                {
                    maxZoom: 18,
                    id: 'mapbox/light-v10',
                    tileSize: 512,
                    zoomOffset: -1,
                },
            ).addTo(this.leaflet)
        },
    }
</script>
