<template>
    <LayoutApp :back-button="backButton" @back="isEditingPassword = false">
        <ProSection :heading="$t('pro.profile.info.heading')">
            <FormWrapper @submit="save">
                <FormFields v-if="isEditingPassword">
                    <FieldText
                        key="password-old"
                        :label="$t('pro.profile.info.password.old')"
                        :has-autofocus="true"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="oldPassword"
                        type="password"
                        @change="change"
                    />

                    <FieldText
                        key="password-new"
                        :label="$t('pro.profile.info.password.new')"
                        :has-autofocus="true"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="newPassword"
                        type="password"
                        @change="change"
                    />
                </FormFields>

                <FormFields v-else>
                    <FieldText
                        :label="$t('common.fields.first-name')"
                        :has-autofocus="true"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="firstName"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.last-name')"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="lastName"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.brand-name')"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="brandName"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.phone')"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="phone"
                        type="tel"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.password')"
                        :is-readonly="true"
                        :form-data="{ password: '*********' }"
                        value-path="password"
                        type="password"
                    >
                        <template #suffix>
                            <BaseLink
                                :label="$t('common.modify')"
                                @click="isEditingPassword = true"
                            />
                        </template>
                    </FieldText>

                    <BaseNote>
                        <i18n-t keypath="pro.profile.info.email.text">
                            <template #contact>
                                <BaseLink
                                    :href="emailHref"
                                    :label="$t('pro.profile.info.email.contact')"
                                    target="_new"
                                />
                            </template>
                        </i18n-t>
                    </BaseNote>
                </FormFields>

                <FormButtons>
                    <BaseButton
                        :label="$t('common.save')"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                    />
                </FormButtons>
            </FormWrapper>
        </ProSection>
    </LayoutApp>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseNote from '@/components/base/BaseNote'
    import BaseLink from '@/components/base/BaseLink'
    import FormFields from '@/components/form-elements/FormFields'
    import FieldText from '@/components/form-fields/FieldText'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'

    export default {
        components: {
            LayoutApp,
            BaseNote,
            BaseLink,
            FormFields,
            FieldText,
            BaseButton,
            FormWrapper,
            FormButtons,
        },

        mixins: [MixinProProfileSection],

        data() {
            return {
                isEditingPassword: false,
            }
        },

        computed: {
            backButton() {
                return this.isEditingPassword ? true : '/pro/profile'
            },

            isSubmittable() {
                return this.isEditingPassword
                    ? Boolean(this.formData.oldPassword && this.formData.newPassword)
                    : Boolean(this.formData.firstName && this.formData.lastName)
            },

            saveLabel() {
                return this.isEditingPassword
                    ? this.$t('pro.profile.info.save.password')
                    : this.$t('pro.profile.info.save.info')
            },

            emailHref() {
                const email = process.env.VUE_APP_CONTACT_EMAIL
                const subject = this.$t('pro.profile.info.email.message.subject')
                const body = this.$t('pro.profile.info.email.message.body')

                return `mailto:${email}?subject=${subject}&body=${body}`
            },
        },

        created() {
            this.initForm(this.$auth.getUser())
        },

        methods: {
            apiCall() {
                return ApiPros.update(this.$auth.getUser().id, 'accountInfo', this.formData)
            },
        },
    }
</script>
