import dayjs from 'dayjs'

export default {
    namespaced: true,

    state: {
        startDate: dayjs(),
        endDate: dayjs(),
    },

    getters: {
        getStartDate(state) {
            return state.startDate
        },

        getEndDate(state) {
            return state.endDate
        },
    },

    mutations: {
        setStartDate(state, startDate) {
            state.startDate = startDate
        },

        setEndDate(state, endDate) {
            state.endDate = endDate
        },
    },
}
