<template>
    <div class="flex flex-col items-center">
        <BaseHeaderDivider
            :heading="$t('admin.dashboard.reservationSummary')"
            class="w-11/12 text-16 mx-auto"
        />

        <BaseMultilevelDonutChart
            :reservations="totalAppointmentsPercentage"
            :completed="completedAppointmentsPercentage"
            :cancellations="cancelledAppointmentsPercentage"
            class="h-200 w-200"
        />

        <div class="flex flex-col">
            <div class="flex flex-row">
                <div class="flex flex-col p-10">
                    <p class="text-35 font-bold text-purple">
                        {{ profileViews }}
                    </p>
                    <p class="h-20" />
                    <p class="text-14 text-gray-2">
                        {{ $t('admin.dashboard.profileViews') }}
                    </p>
                </div>
                <div class="flex flex-col p-10">
                    <p class="text-35 font-bold text-magenta">{{ totalAppointmentsPercentage }}%</p>
                    <p class="h-20 text-14 font-bold text-gray-2">
                        {{ totalAppointments }}
                    </p>
                    <p class="text-14 text-gray-2">
                        {{ $t('admin.dashboard.reservations.label') }}
                    </p>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="flex flex-col p-10">
                    <p class="text-35 font-bold text-gold-dark">
                        {{ completedAppointmentsPercentage }}%
                    </p>
                    <p class="h-20 text-14 font-bold text-gray-2">
                        {{ completedAppointments }}
                    </p>
                    <p class="text-14 text-gray-2">
                        {{ $t('admin.dashboard.cancellations') }}
                    </p>
                </div>
                <div class="flex flex-col p-10">
                    <p class="text-35 font-bold text-notification">
                        {{ cancelledAppointmentsPercentage }}%
                    </p>
                    <p class="h-20 text-14 font-bold text-gray-2">
                        {{ cancelledAppointments }}
                    </p>
                    <p class="text-14 text-gray-2">
                        {{ $t('admin.dashboard.completed') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseMultilevelDonutChart from '@/components/base/BaseMultilevelDonutChart'

    export default {
        components: {
            BaseHeaderDivider,
            BaseMultilevelDonutChart,
        },

        props: {
            profileViews: { type: Number, required: true },
            totalAppointments: { type: Number, required: true },
            totalAppointmentsPercentage: { type: Number, required: true },
            completedAppointments: { type: Number, required: true },
            completedAppointmentsPercentage: { type: Number, required: true },
            cancelledAppointments: { type: Number, required: true },
            cancelledAppointmentsPercentage: { type: Number, required: true },
        },
    }
</script>
