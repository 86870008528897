import MiddlewareRequireLoggedInAdmin from '@/router/middlewares/MiddlewareRequireLoggedInAdmin'
import MiddlewareRequireLoggedOut from '@/router/middlewares/MiddlewareRequireLoggedOut'
import MiddlewareRequireAdmin from '@/router/middlewares/MiddlewareRequireAdmin'
import MiddlewareRequireSuperAdmin from '@/router/middlewares/MiddlewareRequireSuperAdmin'

export default [
    {
        path: '/admin/login',
        view: 'admin/login',
        middlewares: [MiddlewareRequireLoggedOut],
    },
    {
        path: '/admin/dashboard',
        view: 'admin/dashboard/dashboard',
        middlewares: [MiddlewareRequireLoggedInAdmin, MiddlewareRequireAdmin],
    },
    {
        path: '/admin/dashboard/new-guests',
        view: 'admin/dashboard/new-guests',
        middlewares: [MiddlewareRequireLoggedInAdmin, MiddlewareRequireAdmin],
    },
    {
        path: '/admin/dashboard/new-pros',
        view: 'admin/dashboard/new-pros',
        middlewares: [MiddlewareRequireLoggedInAdmin, MiddlewareRequireAdmin],
    },
    {
        path: '/admin/dashboard/reservations',
        view: 'admin/dashboard/reservations',
        middlewares: [MiddlewareRequireLoggedInAdmin, MiddlewareRequireAdmin],
    },
    {
        path: '/admin/dashboard/sales',
        view: 'admin/dashboard/sales',
        middlewares: [MiddlewareRequireLoggedInAdmin, MiddlewareRequireAdmin],
    },
    {
        path: '/admin/dashboard/overall-satisfaction',
        view: 'admin/dashboard/overall-satisfaction',
        middlewares: [MiddlewareRequireLoggedInAdmin, MiddlewareRequireAdmin],
    },
    {
        path: '/admin/issues',
        view: 'admin/issues',
        middlewares: [MiddlewareRequireLoggedInAdmin, MiddlewareRequireAdmin],
    },
    {
        path: '/admin/payments',
        view: 'admin/payments',
        middlewares: [MiddlewareRequireLoggedInAdmin, MiddlewareRequireAdmin],
    },
    {
        path: '/admin/users',
        view: 'admin/users',
        middlewares: [MiddlewareRequireLoggedInAdmin, MiddlewareRequireSuperAdmin],
    },
]
