<script>
    import ApiProClients from '@/apis/ApiProClients'
    import ProClientsAddScreensNote from '@/components/pro/clients/add/screens/ProClientsAddScreensNote'

    export default {
        extends: ProClientsAddScreensNote,

        computed: {
            apiCall() {
                return ApiProClients.createNote(
                    this.$auth.getUser().id,
                    this.client.id,
                    this.formData,
                )
            },
        },
    }
</script>
