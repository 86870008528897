<template>
    <BaseOverlay :is-open="isOpen" class="flex items-center justify-center" @close="close">
        <TransitionScale>
            <div v-if="isOpen" class="relative p-50 bg-white shadow-md flex flex-col">
                <button class="ml-auto -mt-30 -mr-30" @click="close">
                    <IconClose class="w-25 h-25" />
                </button>
                <p class="w-300 font-bold text-30">
                    {{ $t('admin.editUser') }}
                </p>

                <BaseAvatar
                    :photo="admin.profilePicture"
                    class="h-60 w-60 rounded-full mt-25 self-center"
                />

                <p class="font-bold mt-25 self-center">
                    {{ admin.firstName + ' ' + admin.lastName }}
                </p>

                <FormWrapper class="space-y-25" @submit="submitAndClose">
                    <FieldText
                        :label="$t('common.fields.first-name')"
                        :form-data="formData"
                        :form-errors="formErrors"
                        :error="generalError"
                        value-path="firstName"
                        class="w-300"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.last-name')"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="lastName"
                        class="w-300"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.email')"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="email"
                        type="email"
                        class="w-300"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('admin.role')"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="title"
                        class="w-300"
                        @change="change"
                    />

                    <BaseButton
                        class="w-300"
                        :label="$t('common.save')"
                        :level="1"
                        :is-submitting="true"
                        :is-disabled="!isSubmittable"
                        @click="$store.dispatch('ui/confirm/confirm')"
                    />
                </FormWrapper>
            </div>
        </TransitionScale>
    </BaseOverlay>
</template>

<script>
    import ApiAdmins from '@/apis/ApiAdmins'
    import MixinForm from '@/mixins/MixinForm'
    import BaseButton from '@/components/base/BaseButton'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseOverlay from '@/components/base/BaseOverlay'
    import FieldText from '@/components/form-fields/FieldText'
    import IconClose from '@/assets/vectors/icon-close.svg'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import TransitionScale from '@/components/transitions/TransitionScale'

    export default {
        components: {
            FieldText,
            BaseButton,
            BaseAvatar,
            BaseOverlay,
            IconClose,
            FormWrapper,
            TransitionScale,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        props: {
            isOpen: { type: Boolean, default: false },
            admin: { type: Object, default: null },
        },

        emits: ['close', 'save'],

        computed: {
            isSubmittable() {
                return this.formData.firstName && this.formData.lastName && this.formData.email
            },

            stopProcessingAfterSubmitted() {
                return true
            },

            generalError() {
                if (this.formErrors?.message && !this.formErrors?.errors) {
                    return this.formErrors?.message
                }

                return null
            },
        },

        watch: {
            isOpen: {
                immediate: true,
                handler(isOpen) {
                    if (!isOpen) {
                        return
                    }

                    this.change({ path: 'firstName', value: this.admin.firstName })
                    this.change({ path: 'lastName', value: this.admin.lastName })
                    this.change({ path: 'email', value: this.admin.email })
                    this.change({ path: 'title', value: this.admin.title || undefined })
                },
            },
        },

        methods: {
            close() {
                this.formErrors = {}
                this.$emit('close')
            },

            submitAndClose() {
                this.handleSubmit(ApiAdmins.update(this.admin.id, this.formData)).then(() =>
                    this.$emit('save'),
                )
            },
        },
    }
</script>
