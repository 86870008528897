<script>
    import ProProfileLicensesStep2 from '@/components/pro/profile/licenses/ProProfileLicensesStep2'

    export default {
        extends: ProProfileLicensesStep2,

        computed: {
            isAddNewDisplayed() {
                return true
            },

            heading() {
                return this.$t('pro.profile.licenses.steps.3.deleted')
            },
        },
    }
</script>
