<template>
    <slot v-if="isFileSelected" name="preview" :preview="preview" />

    <template v-else-if="isSelectDisplayed || isSelectDisplayedInitially">
        <slot name="select" />

        <div class="space-y-30 -inset-20 flex flex-col items-center justify-center">
            <label class="flex flex-col items-center space-y-15">
                <input
                    type="file"
                    :accept="acceptFiles"
                    class="absolute opacity-0 pointer-events-none"
                    @change="selectFile"
                />
                <IconImage class="h-45" />
                <BaseLabel :content="$t('guest.profile.picture.upload')" />
            </label>

            <BaseHeaderDivider class="w-6/12">
                {{ $t('common.or') }}
            </BaseHeaderDivider>

            <label class="flex flex-col items-center space-y-15">
                <input
                    type="file"
                    :accept="acceptFiles"
                    capture="camera"
                    class="absolute opacity-0 pointer-events-none"
                    @change="selectFile"
                />
                <IconCamera class="h-45" />
                <BaseLabel :content="$t('guest.profile.picture.take')" />
            </label>
        </div>
    </template>

    <slot v-else name="default" :preview="preview" :open-select="openSelect" />
</template>

<script>
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseLabel from '@/components/base/BaseLabel'
    import IconImage from '@/assets/vectors/icon-image.svg'
    import IconCamera from '@/assets/vectors/icon-camera.svg'

    export default {
        components: {
            BaseHeaderDivider,
            BaseLabel,
            IconImage,
            IconCamera,
        },

        props: {
            value: { type: Object, default: null },
            picture: { type: String, default: null },
            isSelectDisplayedInitially: { type: Boolean, default: false },
        },

        emits: ['change', 'preview'],

        data() {
            return {
                isSelectDisplayed: this.isSelectDisplayedInitially,
                preview: null,
            }
        },

        computed: {
            isFileSelected() {
                return !!this.value
            },

            acceptFiles() {
                return ['image/jpg', 'image/jpeg', 'image/png']
            },
        },

        watch: {
            value(file) {
                if (!file) {
                    return (this.preview = null)
                }

                const reader = new FileReader()
                reader.onload = (event) => (this.preview = event.target.result)
                reader.readAsDataURL(file)
            },

            preview(preview) {
                this.$emit('preview', preview)
            },
        },

        methods: {
            change(value) {
                this.$emit('change', value instanceof Event ? value.target.value : value)
            },

            openSelect() {
                this.isSelectDisplayed = true
            },

            selectFile(event) {
                this.isSelectDisplayed = false
                this.change(event.target.files[0])
            },

            resetFieldAndCancel() {
                this.preview = null
                this.change(null)
            },
        },
    }
</script>
