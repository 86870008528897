<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="$emit('close')">
        <div class="p-20 space-y-30">
            <BaseHeaderSection
                :heading="$t('guest.book.summary.policies.heading')"
                :subheading="$t('guest.book.summary.policies.subheading')"
            />

            <section v-for="(policy, index) in policies" :key="index" class="flex space-x-15">
                <component :is="policy.icon" class="h-26 w-26 flex-shrink-0" />

                <BaseText :level="2">
                    <i18n-t :keypath="policy.keypath">
                        <template #amount>
                            <b>{{ policy.amount }}</b>
                        </template>

                        <template #fee>
                            <b>${{ serviceFee }}</b>
                        </template>
                    </i18n-t>
                </BaseText>
            </section>

            <BaseButton :label="confirmLabel" @click="$emit('confirm')" />
        </div>
    </BaseDrawer>
</template>

<script>
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseButton from '@/components/base/BaseButton'
    import BaseText from '@/components/base/BaseText'
    import IconTimeNoShow from '@/assets/vectors/icon-time-no-show.svg'
    import IconMenuCalendar from '@/assets/vectors/icon-menu-calendar.svg'

    export default {
        components: {
            BaseDrawer,
            BaseHeaderSection,
            BaseButton,
            BaseText,
        },

        props: {
            appointment: { type: Object, required: true },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['confirm', 'close'],

        data() {
            return {
                selectedAddOnIds: [],
            }
        },

        computed: {
            serviceFee() {
                return this.$store.getters['dictionaries/getServiceFee']
            },

            confirmLabel() {
                return this.$t('guest.book.summary.confirm')
            },

            policies() {
                const cancellationFeeType =
                    this.appointment.workPlace === 'pro'
                        ? this.appointment.pro?.cancellationPolicy?.prosPlaceFeeType
                        : this.appointment.pro?.cancellationPolicy?.guestsPlaceFeeType
                const cancellationFee =
                    this.appointment.workPlace === 'pro'
                        ? this.appointment.pro?.cancellationPolicy?.prosPlaceFee
                        : this.appointment.pro?.cancellationPolicy?.guestsPlaceFee
                const noShowFeeType =
                    this.appointment.workPlace === 'pro'
                        ? this.appointment.pro?.noShowPolicy?.prosPlaceFeeType
                        : this.appointment.pro?.noShowPolicy?.guestsPlaceFeeType
                const noShowFee =
                    this.appointment.workPlace === 'pro'
                        ? this.appointment.pro?.noShowPolicy?.prosPlaceFee
                        : this.appointment.pro?.noShowPolicy?.guestsPlaceFee

                return [
                    {
                        keypath: `guest.book.summary.policies.cancellation.${cancellationFeeType}`,
                        icon: IconMenuCalendar,
                        amount:
                            cancellationFeeType === 'percent'
                                ? `${cancellationFee}%`
                                : `$${cancellationFee}`,
                    },
                    {
                        keypath: `guest.book.summary.policies.no-show.${noShowFeeType}`,
                        icon: IconTimeNoShow,
                        amount: noShowFeeType === 'percent' ? `${noShowFee}%` : `$${noShowFee}`,
                    },
                ]
            },
        },
    }
</script>
