export const transformAvailability = (data) => {
    return {
        date: data.date,
        slots: data.availableTimeSlots,
    }
}

export default (data) => {
    return data.map(transformAvailability)
}
