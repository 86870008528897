<template>
    <ProProfilePlacesWizard
        v-if="isWizardOpen"
        :initial-form-data="$auth.getUser()"
        @back="isWizardOpen = false"
    />
    <ProProfilePlacesIndex
        v-else
        :initial-form-data="$auth.getUser()"
        @open-wizard="isWizardOpen = true"
    />
</template>

<script>
    import ProProfilePlacesIndex from '@/components/pro/profile/places/ProProfilePlacesIndex'
    import ProProfilePlacesWizard from '@/components/pro/profile/places/ProProfilePlacesWizard'

    export default {
        components: {
            ProProfilePlacesIndex,
            ProProfilePlacesWizard,
        },

        data() {
            return {
                isWizardOpen: false,
            }
        },
    }
</script>
