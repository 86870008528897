<template>
    <component :is="errorComponent" v-if="errorComponent" />
    <RouterView v-else />

    <AppFlash />
    <AppMessage />
    <AppAlerts />
</template>

<script>
    import AppFlash from '@/components/app/AppFlash'
    import AppMessage from '@/components/app/AppMessage'
    import AppAlerts from '@/components/app/AppAlerts'
    import ErrorsNotFound from '@/components/errors/ErrorsNotFound'
    import ErrorsNoAccess from '@/components/errors/ErrorsNoAccess'
    import ErrorsInternal from '@/components/errors/ErrorsInternal'

    export default {
        components: {
            AppFlash,
            AppMessage,
            AppAlerts,
        },

        computed: {
            errorComponent() {
                return {
                    404: ErrorsNotFound,
                    403: ErrorsNoAccess,
                    500: ErrorsInternal,
                }[this.$store.getters['ui/errors/getGlobalErrorCode']]
            },
        },
    }
</script>
