export default {
    namespaced: true,

    state: {
        book: null,
        appointment: null,
    },

    getters: {
        getBook(state) {
            return state.book
        },

        getAppointment(state) {
            return state.appointment
        },
    },

    mutations: {
        setBook(state, book) {
            state.book = book
        },

        setAppointment(state, appointment) {
            state.appointment = appointment
        },
    },
}
