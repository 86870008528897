<template>
    <LandingContainer :level="2" class="pt-50 md:pt-80">
        <LandingHeading :level="2" class="text-center mb-30 md:mb-60">
            {{ $t('landing.pros.faq.heading') }}
        </LandingHeading>

        <LandingAccordion :items="questions" />
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingAccordion from '@/components/landing/LandingAccordion'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingAccordion,
        },

        computed: {
            questions() {
                return Array(2)
                    .fill(0)
                    .map((value, index) => ({
                        heading: this.$t(`landing.safety.faq.questions.${index}.q`),
                        content: this.$t(`landing.safety.faq.questions.${index}.a`),
                    }))
            },
        },
    }
</script>
