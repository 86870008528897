import * as HelperAppointment from '@/helpers/appointment'

export default {
    computed: {
        appointmentCategoryIcon() {
            return HelperAppointment.appointmentCategoryIcon(this.appointment)
        },

        appointmentStartDateTime() {
            return HelperAppointment.appointmentStartDateTime(this.appointment)
        },

        appointmentEndDateTime() {
            return HelperAppointment.appointmentEndDateTime(this.appointment)
        },

        isCancelled() {
            return HelperAppointment.isCancelled(this.appointment)
        },

        isReserved() {
            return HelperAppointment.isReserved(this.appointment)
        },

        isRated() {
            return HelperAppointment.isRated(this.appointment)
        },

        isPaid() {
            return HelperAppointment.isPaid(this.appointment)
        },

        isNoShow() {
            return HelperAppointment.isNoShow(this.appointment)
        },

        isGuestNoShow() {
            return HelperAppointment.isGuestNoShow(this.appointment)
        },

        isProNoShow() {
            return HelperAppointment.isProNoShow(this.appointment)
        },

        isFuture() {
            return HelperAppointment.isFuture(this.appointment)
        },

        isPrevious() {
            return HelperAppointment.isPrevious(this.appointment)
        },

        isConcluded() {
            return HelperAppointment.isConcluded(this.appointment)
        },

        hasStarted() {
            return HelperAppointment.hasStarted(this.appointment)
        },

        isRepeatable() {
            return HelperAppointment.isRepeatable(this.appointment)
        },

        isReschedulable() {
            return HelperAppointment.isReschedulable(this.appointment)
        },

        isRatable() {
            return HelperAppointment.isRatable(this.appointment)
        },

        isChargable() {
            return HelperAppointment.isChargable(this.appointment)
        },

        inProsPlace() {
            return HelperAppointment.inProsPlace(this.appointment)
        },

        inGuestsPlace() {
            return HelperAppointment.inGuestsPlace(this.appointment)
        },

        appointmentDuration() {
            return HelperAppointment.appointmentDuration(this.appointment)
        },

        appointmentAddress() {
            return HelperAppointment.appointmentAddress(this.appointment)
        },

        cancellationFee() {
            return HelperAppointment.cancellationFee(this.appointment)
        },

        noShowFee() {
            return HelperAppointment.noShowFee(this.appointment)
        },

        isCancellationFeeActive() {
            return HelperAppointment.isCancellationFeeActive(this.appointment)
        },

        appointmentPrice() {
            return HelperAppointment.appointmentPrice(this.appointment)
        },
    },

    methods: {
        addOnOrExperiencePrice: HelperAppointment.addOnOrExperiencePrice,

        appointmentPrices(hasServiceFee) {
            return HelperAppointment.appointmentPrices(this.appointment, hasServiceFee)
        },

        cancellationPrices(hasServiceFee) {
            return HelperAppointment.cancellationPrices(this.appointment, hasServiceFee)
        },

        noShowPrices(hasServiceFee) {
            return HelperAppointment.noShowPrices(this.appointment, hasServiceFee)
        },
    },
}
