<template>
    <ProSection :heading="$t('pro.clients.show.screens.note.heading')">
        <FormWrapper>
            <FormFields>
                <FieldTextarea
                    :placeholder="$t('pro.clients.show.screens.note.placeholder')"
                    :has-autofocus="true"
                    :rows="5"
                    :has-bottom-border="false"
                    :form-data="formData"
                    value-path="notes.0.content"
                    @change="change"
                />
            </FormFields>

            <slot name="buttons" :is-submittable="isSubmittable" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormFields,
            FieldTextarea,
        },

        mixins: [MixinFormStep],

        computed: {
            isSubmittable() {
                return this.formData.notes?.[0]?.content
            },
        },
    }
</script>
