<template>
    <LayoutBase>
        <template #top>
            <header class="flex sticky top-0 -mx-20 h-70 bg-white px-20 z-layout">
                <RouterLink
                    to="/"
                    class="p-10 mx-auto absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                    <LogoFreeByrdz class="text-magenta w-40" />
                </RouterLink>
            </header>
        </template>

        <template #default>
            <slot />
        </template>
    </LayoutBase>
</template>

<script>
    import LayoutBase from '@/components/layouts/LayoutBase'
    import LogoFreeByrdz from '@/assets/vectors/logo-freebyrdz.svg'

    export default {
        components: {
            LayoutBase,
            LogoFreeByrdz,
        },

        props: {
            backButton: { type: [Boolean, String, Object], default: false },
        },

        emits: ['back'],
    }
</script>
