<script>
    import * as HelperAppointment from '@/helpers/appointment'
    import ApiGuestAppointments from '@/apis/ApiGuestAppointments'
    import PageGuestAppointmentRepeat from '@/pages/guest/appointments/_id/repeat'
    import GuestAppointmentsRescheduleStep1 from '@/components/guest/appointments/reschedule/GuestAppointmentsRescheduleStep1'
    import GuestAppointmentsRescheduleStep2 from '@/components/guest/appointments/reschedule/GuestAppointmentsRescheduleStep2'
    import GuestAppointmentsRescheduleStep3 from '@/components/guest/appointments/reschedule/GuestAppointmentsRescheduleStep3'
    import GuestAppointmentsRescheduleStep4 from '@/components/guest/appointments/reschedule/GuestAppointmentsRescheduleStep4'

    export default {
        extends: PageGuestAppointmentRepeat,

        async loadData({ $auth, $route, $router }) {
            const appointment = await ApiGuestAppointments.getOne($auth.getUser(), $route.params.id)

            if (!HelperAppointment.isReschedulable(appointment)) {
                $router.push(`/guest/appointments/${$route.params.id}`)
            }
            return { appointment }
        },

        computed: {
            steps() {
                return [
                    { component: GuestAppointmentsRescheduleStep1 },
                    { component: GuestAppointmentsRescheduleStep2 },
                    { component: GuestAppointmentsRescheduleStep3 },
                    { component: GuestAppointmentsRescheduleStep4 },
                ]
            },
        },
    }
</script>
