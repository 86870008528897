<template>
    <ProSection>
        <FormWrapper>
            <ProProfilePortfolioWork
                :work="work"
                :is-picture-deletable="true"
                :is-title-editable="true"
                :is-experiences-editable="true"
                @delete-picture="deletePicture"
                @edit-title="goToStep(1)"
                @edit-experiences="goToStep(2)"
            />

            <slot name="buttons" :is-submittable="true" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import ProProfilePortfolioWork from '@/components/pro/profile/portfolio/ProProfilePortfolioWork'
    import FormWrapper from '@/components/form-elements/FormWrapper'

    export default {
        components: {
            ProSection,
            ProProfilePortfolioWork,
            FormWrapper,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            work() {
                return {
                    picture: this.formData.picture,
                    title: this.formData.title,
                    experiences: (this.$auth.getUser().experiences || []).filter(({ id }) =>
                        (this.formData.experienceIds || []).includes(id),
                    ),
                }
            },
        },

        methods: {
            deletePicture() {
                this.change({ path: 'file', value: null })
                this.change({ path: 'preview', value: null })
                this.goToStep(1)
            },
        },
    }
</script>
