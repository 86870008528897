<template>
    <BaseOverlay :is-open="isOpen" z-index="drawer" @close="$emit('close')">
        <TransitionSlide :from="slideFrom">
            <div v-if="isOpen" :class="panelClass" class="absolute overflow-y-auto bg-white">
                <slot />
            </div>
        </TransitionSlide>
    </BaseOverlay>
</template>

<script>
    import BaseOverlay from '@/components/base/BaseOverlay'
    import TransitionSlide from '@/components/transitions/TransitionSlide'

    export default {
        components: {
            BaseOverlay,
            TransitionSlide,
        },

        props: {
            isOpen: { type: Boolean, default: false },
            isFullscreen: { type: Boolean, default: false },
            slideFrom: { type: String, default: 'bottom' },
        },

        emits: ['close'],

        computed: {
            panelClass() {
                return {
                    'top-0': this.slideFrom !== 'bottom',
                    'right-0': this.slideFrom !== 'left',
                    'bottom-0': this.slideFrom !== 'top',
                    'left-0': this.slideFrom !== 'right',
                    'shadow-md': this.slideFrom !== 'bottom',
                    '-shadow-md': this.slideFrom === 'bottom',
                    'max-w-11/12': ['left', 'right'].includes(this.slideFrom) && !this.isFullscreen,
                    'max-h-11/12': ['top', 'bottom'].includes(this.slideFrom) && !this.isFullscreen,
                    'rounded-t-lg': this.slideFrom === 'bottom' && !this.isFullscreen,
                    'rounded-b-lg': this.slideFrom === 'top' && !this.isFullscreen,
                }
            },
        },
    }
</script>
