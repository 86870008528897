<template>
    <BaseLink
        :route="route"
        class="flex justify-center items-center space-x-15 h-50 transform -translate-x-10"
        @click="$emit('click')"
    >
        <span class="h-35 w-35 border-2 border-current flex items-center justify-center">
            <IconPlus class="h-15 w-15" />
        </span>
        <span>{{ label }}</span>
    </BaseLink>
</template>

<script>
    import BaseLink from '@/components/base/BaseLink'
    import IconPlus from '@/assets/vectors/icon-plus.svg'

    export default {
        components: {
            BaseLink,
            IconPlus,
        },

        props: {
            label: { type: String, required: true },
            route: { type: [String, Object], default: null },
        },

        emits: ['click'],
    }
</script>
