<template>
    <div id="splide" class="splide h-140 overflow-hidden relative">
        <div class="splide__track">
            <ol class="overflow-y-auto splide__list">
                <li v-for="pro in pros" :key="pro.id" class="splide__slide">
                    <article class="flex items-center py-5" @click="$emit('click-pro', pro)">
                        <BaseAvatar
                            :photo="pro.profilePicture"
                            class="h-60 w-60 rounded-full rounded-full"
                        />

                        <div class="space-y-5 ml-20">
                            <h4 class="text-16 font-bold leading-heading">
                                {{ proFullName(pro) }}
                            </h4>
                            <BaseRating v-if="pro.rating" :rating="pro.rating" :level="2" />
                        </div>

                        <div class="flex flex-col ml-auto space-y-2">
                            <span class="text-12">{{ $t('common.from') }}</span>
                            <span class="text-18 font-bold leading-heading">
                                ${{ pro.lowestPrice }}
                            </span>
                        </div>
                    </article>
                </li>
            </ol>
        </div>

        <div
            class="absolute pointer-events-none z-above bg-gradient-to-t from-transparent to-white opacity-70 top-0 right-0 left-0 h-35"
        />
        <div
            class="absolute pointer-events-none z-above bg-gradient-to-b from-transparent to-white opacity-70 bottom-0 right-0 left-0 h-35"
        />
    </div>
</template>

<script>
    import { composeFullName } from '@/helpers'
    import Splide from '@splidejs/splide'
    import BaseRating from '@/components/base/BaseRating'
    import BaseAvatar from '@/components/base/BaseAvatar'

    export default {
        components: {
            BaseRating,
            BaseAvatar,
        },

        props: {
            pros: { type: Object, required: true },
        },

        emits: ['click-pro'],

        computed: {
            listsMultiplePros() {
                return this.pros.length > 1
            },
        },

        watch: {
            pros(pros) {
                pros?.length && this.initializeSplide()
            },
        },

        mounted() {
            this.pros?.length && this.initializeSplide()
        },

        methods: {
            initializeSplide() {
                new Splide('#splide', {
                    direction: 'ttb',
                    type: this.listsMultiplePros ? 'loop' : 'slide',
                    height: '0',
                    autoHeight: true,
                    arrows: false,
                    pagination: false,
                    padding: {
                        top: '35px',
                        bottom: '35px',
                    },
                }).mount()
            },

            proFullName(pro) {
                return composeFullName(pro)
            },
        },
    }
</script>
