<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="$emit('close')">
        <FormWrapper class="p-30 space-y-30" @submit="sendMessage">
            <BaseHeading
                :heading="
                    $t('guest.book.confirmed.questions.heading', {
                        name: appointment.pro.firstName,
                    })
                "
                :level="2"
                class="text-center"
            />

            <div class="mx-auto bg-gray-5 h-1 w-40" />

            <BaseNote :content="appointment.pro.locationQuestions" />

            <BaseHeaderDivider :heading="$t('guest.book.confirmed.questions.enter')" />

            <FieldTextarea
                :placeholder="$t('guest.book.confirmed.questions.type')"
                :has-autofocus="true"
                :form-data="formData"
                :rows="8"
                value-path="locationQuestionAnswer"
                @change="change"
            />

            <BaseButton
                :label="$t('guest.book.confirmed.questions.send')"
                :is-processing="isSubmitting"
                :is-disabled="!isSubmittable"
            />
        </FormWrapper>
    </BaseDrawer>
</template>

<script>
    import ApiGuestAppointments from '@/apis/ApiGuestAppointments'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import BaseNote from '@/components/base/BaseNote'
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseButton from '@/components/base/BaseButton'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'

    export default {
        components: {
            FormWrapper,
            BaseNote,
            BaseDrawer,
            BaseHeading,
            BaseHeaderDivider,
            BaseButton,
            FieldTextarea,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        props: {
            appointment: { type: Object, required: true },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['close'],

        computed: {
            isSubmittable() {
                return !!this.formData.locationQuestionAnswer
            },
        },

        methods: {
            sendMessage() {
                this.handleSubmit(
                    ApiGuestAppointments.answerLocationQuestion(
                        this.$auth.getUser(),
                        this.formData.locationQuestionAnswer,
                    ),
                ).then(this.$emit('close'))
            },
        },
    }
</script>
