<template>
    <ProSection
        :heading="$t('pro.profile.experiences.experience.steps.4.heading', { name: formData.name })"
        :description="$t('pro.profile.experiences.experience.steps.4.description')"
    >
        <FormWrapper>
            <BaseRow :label="$t('pro.profile.experiences.experience.steps.4.duration')">
                <FieldSelect
                    :placeholder="$t('common.specify')"
                    :options="durationOptions"
                    :form-data="formData"
                    value-path="duration"
                    @change="change"
                />
            </BaseRow>

            <slot name="buttons" :is-submittable="isSubmittable">
                <FormButtons>
                    <BaseButton
                        :label="$t('common.save')"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                        @click="save"
                    />
                </FormButtons>
            </slot>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldSelect from '@/components/form-fields/FieldSelect'
    import BaseButton from '@/components/base/BaseButton'
    import BaseRow from '@/components/base/BaseRow'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormButtons,
            FieldSelect,
            BaseButton,
            BaseRow,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isSubmittable() {
                return this.formData.duration
            },

            durationOptions() {
                return Array(24)
                    .fill(1)
                    .map((value, index) => {
                        value = (index + 1) * 5

                        return { label: this.$tc('common.n-minutes', value, value), value }
                    })
            },
        },
    }
</script>
