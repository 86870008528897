<template>
    <ProCalendarWeekdays :date="date" :appointments="appointments" class="ml-40 -mr-20" />

    <ProCalendarTimeline
        :date="date"
        :appointments="appointments"
        :events="events"
        :is-week-view="true"
        class="-mx-20 flex-grow"
        @click-slot="$emit('click-slot', $event)"
        @click-event="$emit('click-event', $event)"
    />
</template>

<script>
    import ProCalendarWeekdays from '@/components/pro/calendar/ProCalendarWeekdays'
    import ProCalendarTimeline from '@/components/pro/calendar/ProCalendarTimeline'

    export default {
        components: {
            ProCalendarWeekdays,
            ProCalendarTimeline,
        },

        props: {
            date: { type: Object, required: true },
            appointments: { type: Array, required: true },
            events: { type: Array, required: true },
        },

        emits: ['click-slot', 'click-event'],
    }
</script>
