<template>
    <GuestProfileSection
        :save-label="saveLabel"
        :is-editing="isEditing"
        :is-submitting="isSubmitting"
        :is-disabled="isDisabled"
        @edit="edit"
        @save="save"
        @cancel="resetFieldAndCancel"
    >
        <template #top>
            <InputPhoto :value="file" @change="selectFile">
                <template #preview="{ preview }">
                    <div class="relative bg-black">
                        <img :src="preview" class="w-full opacity-50" />

                        <svg viewBox="0 0 200 200" class="absolute inset-0 w-full h-full">
                            <g fill="none" fill-rule="evenodd">
                                <mask id="b" fill="#fff">
                                    <circle id="a" cx="100" cy="100" r="100" />
                                </mask>
                                <image
                                    :href="preview"
                                    mask="url(#b)"
                                    x="0"
                                    y="0"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid slice"
                                />
                            </g>
                        </svg>
                    </div>
                </template>

                <template #default="{ preview, openSelect }">
                    <div
                        class="h-100 w-100 overflow-hidden rounded-full relative bg-gray-5 text-gray-3"
                    >
                        <IconProfile
                            class="w-6/12 absolute top-6/12 left-6/12 transform -translate-x-6/12 -translate-y-6/12"
                        />
                        <img
                            v-if="preview || profilePicture"
                            :src="preview || profilePicture"
                            class="absolute inset-0 h-full w-full object-cover"
                        />
                    </div>

                    <BaseLink :label="changeButtonLabel" @click="openSelect" />
                </template>
            </InputPhoto>
        </template>
    </GuestProfileSection>
</template>

<script>
    import ApiGuests from '@/apis/ApiGuests'
    import MixinGuestProfileSection from '@/mixins/MixinGuestProfileSection'
    import BaseLink from '@/components/base/BaseLink'
    import IconProfile from '@/assets/vectors/icon-profile.svg'
    import InputPhoto from '@/components/form-inputs/InputPhoto'

    export default {
        components: {
            BaseLink,
            IconProfile,
            InputPhoto,
        },

        mixins: [MixinGuestProfileSection],

        props: {
            picture: { type: String, default: null },
        },

        data() {
            return {
                file: null,
            }
        },

        computed: {
            profilePicture() {
                return this.$auth.getUser().profilePicture
            },

            saveLabel() {
                return this.file ? this.$t('guest.profile.picture.save') : null
            },

            changeButtonLabel() {
                return this.profilePicture
                    ? this.$t('guest.profile.picture.edit')
                    : this.$t('guest.profile.picture.add')
            },

            stopProcessingAfterSubmitted() {
                return true
            },
        },

        methods: {
            selectFile(file) {
                this.file = file
                this.edit()
            },

            resetFieldAndCancel() {
                this.file = null
                this.cancel()
            },

            apiCall() {
                const formData = new FormData()
                formData.append('file', this.file)

                return ApiGuests.updateProfilePicture(this.$auth.getUser().id, formData)
                    .then(() => this.$auth.loadUser(true))
                    .then(() => (this.file = null))
            },
        },
    }
</script>
