<template>
    <div class="text-center">
        <BaseHeading :heading="$t('guest.appointments.rate.thank-you')" :level="3" />

        <!-- INFO: Below component is hidden as we do not yet support recurrent appointments. -->
        <GuestAppointmentsRateRecurrent
            v-if="false"
            :appointment="appointment"
            class="mt-60 mb-30"
        />
    </div>

    <FormButtons>
        <BaseButton
            :label="$t('guest.appointments.my-appointments')"
            route="/guest/appointments/previous"
        />
    </FormButtons>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import FormButtons from '@/components/form-elements/FormButtons'
    import GuestAppointmentsRateRecurrent from '@/components/guest/appointments/rate/GuestAppointmentsRateRecurrent'

    export default {
        components: {
            BaseHeading,
            BaseButton,
            FormButtons,
            GuestAppointmentsRateRecurrent,
        },

        props: {
            appointment: { type: Object, required: true },
        },
    }
</script>
