<template>
    <LayoutPro back-button="/auth/detect">
        <div class="space-y-30">
            <BaseHeaderSection :heading="$t('pro.profile.heading')" :is-center="false" />

            <RouterLink to="/pro/profile/picture">
                <ProProfilePicture :is-editable="false" class="my-30">
                    <template v-if="!hasProfilePicture" #bottom>
                        <ProProfileRequired class="mt-5 float-right" />
                    </template>
                </ProProfilePicture>
            </RouterLink>

            <div class="flex justify-center">
                <BaseLink
                    :label="$t('pro.profile.preview')"
                    route="/pro/profile/preview"
                    class="block"
                />
            </div>

            <div>
                <ProProfileLink v-for="link in links" :key="link.heading" v-bind="link" />
            </div>
        </div>
    </LayoutPro>
</template>

<script>
    import { getStateNameByCode, timeToDateTime } from '@/helpers'
    import dayjs from 'dayjs'
    import intersection from 'lodash/intersection'
    import ApiProLicenses from '@/apis/ApiProLicenses'
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import LayoutPro from '@/components/layouts/LayoutPro'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseLink from '@/components/base/BaseLink'
    import ProProfilePicture from '@/components/pro/profile/ProProfilePicture'
    import ProProfileLink from '@/components/pro/profile/ProProfileLink'
    import ProProfileRequired from '@/components/pro/profile/ProProfileRequired'
    import ProProfileAlert from '@/components/pro/profile/ProProfileAlert'

    export default {
        components: {
            LayoutPro,
            BaseHeaderSection,
            BaseLink,
            ProProfilePicture,
            ProProfileLink,
            ProProfileRequired,
        },

        mixins: [MixinProExperiences],

        async loadData({ $auth }) {
            // TODO: Replace with loading licenses to global session store.
            const licenses = await ApiProLicenses.get($auth.getUser().id)

            return { licenses }
        },

        computed: {
            isAlertOpen() {
                return !(
                    this.hasProfilePicture &&
                    this.hasAllCoreExperiences &&
                    this.hasAvailabilities &&
                    this.hasStripe &&
                    this.hasCancellation
                )
            },

            coreExperiencesPreview() {
                // Example: Hair (4), Men’s Grooming (4), Nails (3).
                return this.hasAnyCoreExperiences
                    ? this.proCategories
                          .map((category) => {
                              const subCategoryIds = category.subCategories.map(({ id }) => id)
                              const experiencesCount = this.proExperiences.filter(
                                  (experience) =>
                                      intersection(
                                          subCategoryIds,
                                          experience.subCategories.map(({ id }) => id),
                                      ).length > 0,
                              ).length

                              return `${category.name} (${experiencesCount})`
                          })
                          .join(', ')
                    : this.$t('pro.profile.links.experiences.empty')
            },

            addOnsPreview() {
                const showedCount = 2
                const names = this.proAddOns
                    .map(({ name }) => name)
                    .slice(0, showedCount)
                    .join(', ')
                const count = this.proAddOns.length

                return count <= showedCount ? names : `${names} + ${count - showedCount}`
            },

            portfolioPreview() {
                const n = (this.pro.portfolio || []).length
                return this.$tc('pro.profile.links.portfolio.content', n, { n })
            },

            licensesPreview() {
                return this.$t('pro.profile.links.licenses.content', {
                    name: this.licenses?.[0]?.name,
                    number: this.licenses?.[0]?.licenseNumber,
                    state: getStateNameByCode(this.licenses?.[0]?.state),
                    expiration: this.licenses?.[0]?.expirationDate,
                })
            },

            availabilitiesPreview() {
                return (this.pro.availabilities || [])
                    .map((availability) => {
                        const day = dayjs()
                            .day(availability.dayOfWeek + 1)
                            .format('dd')
                        const start = timeToDateTime(availability.startTime).format('hh:mm A')
                        const end = timeToDateTime(availability.endTime).format('hh:mm A')

                        return `${day} ${start} – ${end}`
                    })
                    .join(', ')
            },

            hasProfilePicture() {
                return !!this.pro.profilePicture
            },

            hasAnyCoreExperiences() {
                return this.proExperiences.length > 0
            },

            hasAllCoreExperiences() {
                if (!this.hasAnyCoreExperiences) {
                    return false
                }

                return !this.proSubCategoryIds.some(
                    (subCategoryId) =>
                        !this.proExperienceSubCategoryIds.find((id) => subCategoryId === id),
                )
            },

            hasAvailabilities() {
                return (this.pro.availabilities || []).length > 0
            },

            hasStripe() {
                return this.pro.stripeAccountStatus === 'Enabled'
            },

            hasPortfolio() {
                return (this.pro.portfolio || []).length > 0
            },

            hasLicenses() {
                return (this.licenses || []).length > 0
            },

            hasCancellation() {
                return !!this.pro.cancellationPolicy
            },

            links() {
                return [
                    {
                        route: '/pro/profile/info',
                        heading: this.$t('pro.profile.links.info.heading'),
                        content: [this.pro.firstName, this.pro.lastName, this.pro.email].join(' '),
                        isTruncated: true,
                    },
                    {
                        route: '/pro/profile/intro',
                        heading: this.$t('pro.profile.links.intro.heading'),
                        description: !this.pro.introduction
                            ? this.$t('pro.profile.links.intro.description')
                            : null,
                        content: this.pro.introduction,
                        isSuggested: !this.pro.introduction,
                        isTruncated: true,
                    },
                    {
                        route: '/pro/profile/places',
                        heading: this.$t('pro.profile.links.places.heading'),
                        content: (this.pro.workPlace || [])
                            .map((place) => this.$t(`common.places.${place}`))
                            .join(' / '),
                    },
                    {
                        route: '/pro/profile/experiences',
                        heading: this.$t('pro.profile.links.experiences.heading'),
                        content: this.coreExperiencesPreview,
                        isRequired: !this.hasAllCoreExperiences,
                    },
                    {
                        route: '/pro/profile/availability',
                        heading: this.$t('pro.profile.links.availability.heading'),
                        description: this.hasAvailabilities
                            ? null
                            : this.$t('pro.profile.links.availability.description'),
                        content: this.hasAvailabilities ? this.availabilitiesPreview : null,
                        isRequired: !this.hasAvailabilities,
                        isTruncated: true,
                    },
                    {
                        route: '/pro/profile/stripe',
                        heading: this.$t('pro.profile.links.stripe.heading'),
                        description: this.hasStripe
                            ? null
                            : this.$t('pro.profile.links.stripe.description'),
                        content: this.hasStripe
                            ? this.$t('pro.profile.links.stripe.content')
                            : null,
                        isRequired: !this.hasStripe,
                    },
                    {
                        route: '/pro/profile/addons',
                        heading: this.$t('pro.profile.links.addons.heading'),
                        description: this.addOnsPreview
                            ? null
                            : this.$t('pro.profile.links.addons.description'),
                        content: this.addOnsPreview,
                    },
                    {
                        route: '/pro/profile/link',
                        heading: this.$t('pro.profile.links.link.heading'),
                        description: this.pro.userName
                            ? null
                            : this.$t('pro.profile.links.link.description'),
                        content: this.pro.userName
                            ? `${process.env.VUE_APP_URL}/p/${this.pro.userName}`
                            : null,
                        isTruncated: true,
                    },
                    {
                        route: '/pro/profile/portfolio',
                        heading: this.$t('pro.profile.links.portfolio.heading'),
                        description: this.hasPortfolio
                            ? null
                            : this.$t('pro.profile.links.portfolio.description'),
                        content: this.hasPortfolio ? this.portfolioPreview : null,
                        isSuggested: !this.hasPortfolio,
                    },
                    {
                        route: '/pro/profile/licenses',
                        heading: this.$t('pro.profile.links.licenses.heading'),
                        description: this.hasLicenses
                            ? null
                            : this.$t('pro.profile.links.licenses.description'),
                        content: this.hasLicenses ? this.licensesPreview : null,
                        isSuggested: !this.hasLicenses,
                    },
                    {
                        route: '/pro/profile/cancellation',
                        heading: this.$t('pro.profile.links.cancellation.heading'),
                        description: this.$t('pro.profile.links.cancellation.description'),
                        isRequired: !this.hasCancellation,
                    },
                ]
            },
        },

        watch: {
            isAlertOpen() {
                this.toggleAlert()
            },
        },

        mounted() {
            this.toggleAlert()
        },

        beforeUnmount() {
            this.$ui.alerts.close('required')
        },

        methods: {
            toggleAlert() {
                return this.isAlertOpen
                    ? this.$ui.alerts.open('required', ProProfileAlert)
                    : this.$ui.alerts.close('required')
            },
        },
    }
</script>
