<script>
    import ProProfilePortfolioAdd1 from '@/components/pro/profile/portfolio/ProProfilePortfolioAdd1'

    export default {
        extends: ProProfilePortfolioAdd1,

        computed: {
            isFileSelected() {
                return true
            },

            isPictureDeletable() {
                return false
            },
        },
    }
</script>
