<template>
    <LayoutOverlay back-button="/pro/profile">
        <ProSection
            :heading="$t('pro.profile.places.heading')"
            :description="$t('pro.profile.places.description')"
        >
            <div v-for="place in places" :key="place.label">
                <BaseChoice
                    :icon="place.icon"
                    :label="place.label"
                    :level="2"
                    :is-selected="true"
                    class="mb-20"
                />

                <div :class="placeStatusClass(place)">
                    {{ placeStatusLabel(place) }}

                    <InputSwitch
                        :value="isPlaceActive(place)"
                        class="ml-auto"
                        @change="togglePlaceStatus(place)"
                    />
                </div>

                <component :is="place.links" v-if="isPlaceFilled(place)" :form-data="formData" />
            </div>
        </ProSection>

        <TransitionSlide from="bottom">
            <FormButtons v-if="isDirty">
                <BaseButton
                    :label="$t('common.save')"
                    :is-processing="isSubmitting"
                    @click="saveOrRedirectToWizard"
                />
            </FormButtons>
        </TransitionSlide>
    </LayoutOverlay>
</template>

<script>
    import { getWorkPlaceOptions } from '@/helpers'
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import MixinProProfilePlaces from '@/mixins/MixinProProfilePlaces'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import TransitionSlide from '@/components/transitions/TransitionSlide'
    import BaseChoice from '@/components/base/BaseChoice'
    import BaseButton from '@/components/base/BaseButton'
    import FormButtons from '@/components/form-elements/FormButtons'
    import InputSwitch from '@/components/form-inputs/InputSwitch'
    import ProProfilePlacesProLinks from '@/components/pro/profile/places/pro/ProProfilePlacesProLinks'
    import ProProfilePlacesGuestLinks from '@/components/pro/profile/places/guest/ProProfilePlacesGuestLinks'

    export default {
        components: {
            LayoutOverlay,
            TransitionSlide,
            BaseChoice,
            BaseButton,
            FormButtons,
            InputSwitch,
        },

        mixins: [MixinProProfileSection, MixinProProfilePlaces],

        emits: ['open-wizard'],

        data() {
            return {
                isEditing: false,
            }
        },

        computed: {
            isRedirectingHome() {
                return false
            },

            isOnlyOneActive() {
                return this.formData.workPlace.length === 1
            },

            places() {
                const linksComponent = {
                    pro: ProProfilePlacesProLinks,
                    guest: ProProfilePlacesGuestLinks,
                }

                return getWorkPlaceOptions().map(({ icon, label, value: slug }) => ({
                    icon,
                    label,
                    slug,
                    links: linksComponent[slug],
                }))
            },
        },

        methods: {
            placeStatusClass(place) {
                const isPlaceActive = this.isPlaceActive(place)

                return {
                    'h-60': true,
                    'flex': true,
                    'items-center': true,
                    'transition': true,
                    'duration-250': true,
                    'opacity-30': isPlaceActive && this.isOnlyOneActive,
                    'pointer-events-none': isPlaceActive && this.isOnlyOneActive,
                }
            },

            placeStatusLabel(place) {
                return this.isPlaceActive(place)
                    ? this.$t('pro.profile.places.status.active')
                    : this.$t('pro.profile.places.status.inactive')
            },

            togglePlaceStatus(place) {
                const isPlaceActive = this.isPlaceActive(place)

                if (isPlaceActive && this.isOnlyOneActive) {
                    return
                }

                if (!isPlaceActive) {
                    return this.change({
                        path: 'workPlace',
                        value: [...this.formData.workPlace, place.slug],
                    })
                }

                this.$ui
                    .confirm({
                        message: this.$t('pro.profile.places.confirm.message'),
                        note: this.$t('pro.profile.places.confirm.note'),
                    })
                    .then((isConfirmed) => {
                        if (!isConfirmed) {
                            return
                        }

                        this.change({
                            path: 'workPlace',
                            value: this.formData.workPlace.filter((slug) => place.slug !== slug),
                        })
                    })
            },

            apiCall() {
                return ApiPros.update(this.$auth.getUser().id, 'workPlace', this.formData)
            },

            saveOrRedirectToWizard() {
                const isAnyOfActivePlacesEmpty = this.formData.workPlace.some(
                    (slug) => !this.isPlaceFilled({ slug }),
                )

                if (isAnyOfActivePlacesEmpty) {
                    return this.$emit('open-wizard')
                }

                this.save()
            },
        },
    }
</script>
