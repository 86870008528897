<template>
    <button
        type="button"
        class="border-b-1 border-gray-5 pb-15 block w-full"
        @click="$emit('toggle', experience)"
    >
        <div class="flex items-center">
            <div class="flex space-x-10 items-center pointer-events-none">
                <BaseCheck
                    v-if="isForMultipleWorkPlaces && isOfferedInBothPlaces"
                    :is-selected="isSelected"
                    :is-rounded="true"
                >
                    <template #unselected>
                        <IconPlus class="w-14 h-14 text-black" />
                    </template>
                </BaseCheck>
                <InputCheckbox v-else :value="isSelected" />
                <p class="font-bold">{{ experience.name }}</p>
            </div>

            <p class="ml-auto pl-10 flex items-baseline">
                <span
                    v-if="isForMultipleWorkPlaces && isOfferedInBothPlaces"
                    class="text-gray-3 mr-5"
                >
                    {{ $t('common.from') }}
                </span>
                <span class="text-20 font-bold text-black">${{ offeredPrice }}</span>
            </p>
        </div>
        <p class="mt-10 ml-40 text-gray-3 text-14 font-medium flex">
            <IconTime class="h-18 w-18 mr-10" />
            {{ $tc('common.n-minutes', experience.duration, { n: experience.duration }) }}
            <template v-if="isForMultipleWorkPlaces"> | {{ offeredLocations }}</template>
        </p>
    </button>
</template>

<script>
    import InputCheckbox from '@/components/form-inputs/InputCheckbox'
    import BaseCheck from '@/components/base/BaseCheck'
    import IconTime from '@/assets/vectors/icon-time.svg'
    import IconPlus from '@/assets/vectors/icon-plus.svg'

    export default {
        components: {
            InputCheckbox,
            BaseCheck,
            IconTime,
            IconPlus,
        },

        props: {
            experience: { type: Object, required: true },
            workPlace: { type: Array, required: true },
            isSelected: { type: Boolean, default: false },
        },

        emits: ['toggle'],

        computed: {
            isForMultipleWorkPlaces() {
                return this.workPlace.length > 1
            },

            isOfferdInProsPlace() {
                return this.experience.workPlace?.includes('pro')
            },

            isOfferedInGuestsPlace() {
                return this.experience.workPlace?.includes('guest')
            },

            isOfferedInBothPlaces() {
                return this.isOfferdInProsPlace && this.isOfferedInGuestsPlace
            },

            offeredPrice() {
                if (this.isOfferedInBothPlaces) {
                    if (this.isForMultipleWorkPlaces) {
                        return Math.min(
                            this.experience.prosPlacePrice,
                            this.experience.guestsPlacePrice,
                        )
                    }

                    return this.workPlace.includes('pro')
                        ? this.experience.prosPlacePrice
                        : this.experience.guestsPlacePrice
                }

                return this.isOfferdInProsPlace
                    ? this.experience.prosPlacePrice
                    : this.experience.guestsPlacePrice
            },

            offeredLocations() {
                if (this.isOfferedInBothPlaces) {
                    return this.$t('pro.page.offering.locations.both')
                }

                return this.isOfferdInProsPlace
                    ? this.$t('pro.page.offering.locations.pro')
                    : this.$t('pro.page.offering.locations.guest')
            },
        },
    }
</script>
