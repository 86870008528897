<template>
    <ProSection
        :heading="$t('pro.clients.show.screens.image.heading')"
        :description="$t('pro.clients.show.screens.image.description')"
    >
        <FormWrapper>
            <InputPhoto
                :is-select-displayed-initially="true"
                :value="formData.images?.[0]?.file"
                @change="selectFile"
                @preview="loadPreview"
            >
                <template #preview>
                    <ProWork
                        v-if="formData.images?.[0]?.picture"
                        :picture="formData.images?.[0]?.picture"
                        :is-picture-deletable="true"
                        @delete-picture="removeFile"
                    />
                </template>
            </InputPhoto>

            <slot name="buttons" :is-submittable="isSubmittable" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import InputPhoto from '@/components/form-inputs/InputPhoto'
    import ProWork from '@/components/pro/ProWork'

    export default {
        components: {
            ProSection,
            FormWrapper,
            InputPhoto,
            ProWork,
        },

        mixins: [MixinFormStep],

        computed: {
            isSubmittable() {
                return !!this.formData.images?.[0]?.file
            },
        },

        methods: {
            selectFile(file) {
                this.change({ path: 'images.0.file', value: file })
            },

            removeFile() {
                this.change({ path: 'images.0.file', value: null })
                this.change({ path: 'images.0.picture', value: null })
            },

            loadPreview(picture) {
                this.change({ path: 'images.0.picture', value: picture })
            },
        },
    }
</script>
