import ServiceAuth from '@/services/ServiceAuth'

export default function(to, from, next) {
    ServiceAuth.loadUser().then(() => {
        if (ServiceAuth.isLoggedIn()) {
            ServiceAuth.isAdmin() || ServiceAuth.isSuperAdmin()
                ? next('/admin/dashboard')
                : next('/')
            return
        }

        next()
    })
}
