<template>
    <div class="h-70 bg-white -shadow-md">
        <div
            class="transition-all ease-out duration-150 absolute top-5 flex justify-center text-purple"
            :style="indicatorStyle"
        >
            <IconArrowDown class="h-10" />
        </div>

        <ul class="flex h-full">
            <li :class="stepClass(1)">
                <IconCategoryMultiple class="h-30" />
                <div
                    v-if="subCategoriesCount"
                    class="absolute left-1/2 bottom-1/2 border-white border-3 flex items-center justify-center rounded-full bg-current text-11 tracking-tight leading-none font-bold h-23 min-w-23 px-2 -mb-6 ml-2"
                >
                    <span class="text-white">{{ subCategoriesCount }}</span>
                </div>
            </li>
            <li :class="stepClass(2)">
                <IconLocation class="h-30" />
            </li>
            <li :class="stepClass(3)">
                <IconMenuCalendar class="h-30" />
            </li>
            <li :class="stepClass(4)">
                <div v-if="pro" class="relative">
                    <BaseAvatar :photo="pro.profilePicture" class="h-30 w-30 rounded-full" />
                    <IconTime
                        class="absolute -bottom-4 -right-5 h-18 w-18 bg-white border-2 border-white rounded-full"
                    />
                </div>
                <IconUser v-else class="h-30" />
            </li>
            <li :class="stepClass(5)">
                <IconCard class="h-30" />
            </li>
        </ul>
    </div>
</template>

<script>
    import BaseAvatar from '@/components/base/BaseAvatar'
    import IconArrowDown from '@/assets/vectors/icon-arrow-down.svg'
    import IconLocation from '@/assets/vectors/icon-location.svg'
    import IconTime from '@/assets/vectors/icon-time.svg'
    import IconUser from '@/assets/vectors/icon-user.svg'
    import IconMenuCalendar from '@/assets/vectors/icon-menu-calendar.svg'
    import IconCard from '@/assets/vectors/icon-card.svg'
    import IconCategoryMultiple from '@/assets/vectors/icon-category-multiple.svg'

    export default {
        components: {
            BaseAvatar,
            IconArrowDown,
            IconLocation,
            IconTime,
            IconUser,
            IconMenuCalendar,
            IconCard,
            IconCategoryMultiple,
        },

        props: {
            currentStep: { type: Number, required: true },
            subCategoriesCount: { type: Number, default: null },
            pro: { type: Object, default: null },
        },

        data() {
            const stepsCount = 5

            return {
                stepsCount,
                stepSize: 100 / stepsCount,
            }
        },

        computed: {
            indicatorStyle() {
                const stepPercentage = this.stepSize * (this.currentStep - 1)

                return {
                    'width': `${this.stepSize}%`,
                    'margin-left': `${Math.max(0, stepPercentage)}%`,
                }
            },
        },

        methods: {
            stepClass(step) {
                return {
                    'relative': true,
                    'text-30': true,
                    'font-bold': true,
                    'w-full': true,
                    'flex': true,
                    'pt-5': true,
                    'items-center': true,
                    'justify-center': true,
                    'transition': true,
                    'text-purple': step === this.currentStep,
                    'text-gray-4': step > this.currentStep,
                }
            },
        },
    }
</script>
