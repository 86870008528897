<template>
    <LayoutAdmin @select-period="loadStatistics">
        <div v-if="statistics" class="flex flex-col p-35">
            <DailyInfo :daily-infos="dailyInfos" class="flex-grow mb-25" />

            <div class="flex flex-row flex-wrap">
                <ReservationSummary
                    :profile-views="statistics.profileViews"
                    :total-appointments="statistics.totalAppointments"
                    :total-appointments-percentage="statistics.totalAppointmentsPercentage"
                    :completed-appointments="statistics.completedAppointments"
                    :completed-appointments-percentage="statistics.completedAppointmentsPercentage"
                    :cancelled-appointments="statistics.cancelledAppointments"
                    :cancelled-appointments-percentage="statistics.cancelledAppointmentsPercentage"
                    class="flex-grow mt-25 mb-25"
                />

                <div class="flex-grow flex flex-col items-center mt-25 mb-25">
                    <BaseHeaderDivider
                        :heading="$t('admin.dashboard.proAndGuestPlace')"
                        class="w-11/12 text-16"
                    />
                    <ProDashboardEarned :stats="statistics" />
                    <TopLocations :top-locations="statistics.topLocations" class="mt-45" />
                </div>

                <OverallSatisfaction
                    :average-rating="statistics.overallSatisfaction.averageRating"
                    :total-reviews="statistics.overallSatisfaction.totalReviews"
                    :ratings="ratings"
                    class="flex-grow mt-25 mb-25 cursor-pointer"
                    @click="redirectToOverallSatisfaction"
                />
            </div>

            <div class="flex flex-row flex-wrap">
                <TopCategories
                    :top-categories="statistics.topCategories"
                    class="flex-grow mt-25 mb-25 w-1/4"
                />

                <TopSellers
                    :top-sellers="statistics.topSellers"
                    class="flex-grow mt-25 mb-25 w-3/4"
                />
            </div>
        </div>
    </LayoutAdmin>
</template>

<script>
    import ApiAdmins from '@/apis/ApiAdmins'
    import LayoutAdmin from '@/components/layouts/LayoutAdmin'
    import TopSellers from '@/components/admin/dashboard/TopSellers'
    import IconCart from '@/assets/vectors/icon-cart.svg'
    import IconTime from '@/assets/vectors/icon-time.svg'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import IconMenuClients from '@/assets/vectors/icon-menu-clients.svg'
    import TopLocations from '@/components/admin/dashboard/TopLocations'
    import DailyInfo from '@/components/admin/dashboard/DailyInfo.vue'
    import TopCategories from '@/components/admin/dashboard/TopCategories'
    import IconMenuPayments from '@/assets/vectors/icon-menu-payments.svg'
    import IconProfile from '@/assets/vectors/icon-profile.svg'
    import ProDashboardEarned from '@/components/pro/dashboard/ProDashboardEarned'
    import ReservationSummary from '@/components/admin/dashboard/ReservationSummary.vue'
    import OverallSatisfaction from '@/components/admin/dashboard/OverallSatisfaction.vue'

    export default {
        components: {
            DailyInfo,
            TopSellers,
            LayoutAdmin,
            TopLocations,
            TopCategories,
            BaseHeaderDivider,
            ReservationSummary,
            ProDashboardEarned,
            OverallSatisfaction,
        },

        data() {
            return {
                statistics: null,
            }
        },

        computed: {
            dailyInfos() {
                return [
                    {
                        label: this.$t('admin.dashboard.newGuests.label'),
                        icon: IconMenuClients,
                        value: this.statistics.newGuests,
                        isMoney: false,
                        percentage: this.statistics.newGuetsIncreasePercentage,
                        handleClick: this.redirectToNewGuests,
                    },
                    {
                        label: this.$t('admin.dashboard.newPros.label'),
                        icon: IconProfile,
                        value: this.statistics.newStylists,
                        isMoney: false,
                        percentage: this.statistics.newStylistsIncreasePercentage,
                        handleClick: this.redirectToNewPros,
                    },
                    {
                        label: this.$t('admin.dashboard.reservations.label'),
                        icon: IconTime,
                        value: this.statistics.newAppointments,
                        isMoney: false,
                        percentage: this.statistics.newAppointmentIncreasePercentage,
                        handleClick: this.redirectToReservations,
                    },
                    {
                        label: this.$t('admin.dashboard.totalSales'),
                        icon: IconCart,
                        value: this.statistics.totalSales,
                        isMoney: true,
                        percentage: this.statistics.totalSalesIncreasePercentage,
                        handleClick: this.redirectToSales,
                    },
                    {
                        label: this.$t('admin.dashboard.totalFees'),
                        icon: IconMenuPayments,
                        value: this.statistics.totalFees,
                        isMoney: true,
                        percentage: this.statistics.totalFeesIncreasePercentage,
                    },
                ]
            },

            ratings() {
                return [
                    {
                        label: this.$t('admin.dashboard.fiveStars'),
                        percentage: this.statistics.overallSatisfaction.rating5,
                    },
                    {
                        label: this.$t('admin.dashboard.fourStars'),
                        percentage: this.statistics.overallSatisfaction.rating4,
                    },
                    {
                        label: this.$t('admin.dashboard.threeStars'),
                        percentage: this.statistics.overallSatisfaction.rating3,
                    },
                    {
                        label: this.$t('admin.dashboard.twoStars'),
                        percentage: this.statistics.overallSatisfaction.rating2,
                    },
                    {
                        label: this.$t('admin.dashboard.oneStars'),
                        percentage: this.statistics.overallSatisfaction.rating1,
                    },
                ]
            },

            startDate() {
                return this.$store.getters['admin/getStartDate']
            },

            endDate() {
                return this.$store.getters['admin/getEndDate']
            },
        },

        methods: {
            async loadStatistics({ startDate, endDate }) {
                this.statistics = await ApiAdmins.getStatistics({ startDate, endDate })
            },

            redirectToNewGuests() {
                this.$router.push('/admin/dashboard/new-guests')
            },

            redirectToNewPros() {
                this.$router.push('/admin/dashboard/new-pros')
            },

            redirectToReservations() {
                this.$router.push('/admin/dashboard/reservations')
            },

            redirectToOverallSatisfaction() {
                this.$router.push('/admin/dashboard/overall-satisfaction')
            },

            redirectToSales() {
                this.$router.push('/admin/dashboard/sales')
            },
        },
    }
</script>
