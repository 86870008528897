<template>
    <div class="space-y-20 flex flex-col items-center">
        <BaseHeaderDivider :heading="heading" class="w-full" />

        <BaseText
            v-if="!isSetup"
            :content="$t('guest.profile.payment.setup.description')"
            class="text-center"
        />

        <template v-if="isEditing || !isSetup">
            <div class="w-full space-y-20">
                <div id="stripe-card" />

                <div
                    v-if="error"
                    class="relative text-md font-medium text-14 text-white bg-red p-10"
                >
                    <div
                        class="absolute transform rotate-45 origin-bottom-left bottom-full -mb-3 left-5 h-15 w-15 bg-red"
                    />
                    <p class="relative">{{ error }}</p>
                </div>
            </div>

            <BaseButton
                :label="$t('guest.profile.payment.save')"
                :is-submitting="false"
                :is-processing="isSubmitting"
                class="mt-10"
                @click="save"
            />
        </template>

        <template v-else>
            <div class="flex w-full">
                <span class="text-14">{{ $t('guest.profile.payment.card') }}</span>

                <span class="h-20 flex items-center px-5 bg-gray-6 rounded-sm ml-5">
                    <span class="uppercase text-10 font-bold leading-none">
                        USD
                    </span>
                </span>

                <span class="text-16 font-medium ml-auto">
                    **** **** **** {{ method?.card.last4 }}
                </span>
            </div>

            <BaseButton
                v-if="!isPreview"
                :label="$t('guest.profile.payment.change')"
                :level="2"
                class="mt-10"
                @click="isEditing = true"
            />
        </template>
    </div>
</template>

<script>
    import BaseButton from '@/components/base/BaseButton'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseText from '@/components/base/BaseText'
    import ApiGuests from '@/apis/ApiGuests'
    import { loadStripe } from '@stripe/stripe-js'

    export default {
        components: {
            BaseButton,
            BaseHeaderDivider,
            BaseText,
        },

        props: {
            isPreview: { type: Boolean, default: false },
        },

        emits: ['saved'],

        data() {
            return {
                isEditing: false,
                isSubmitting: false,
                stripe: null,
                card: null,
                error: null,
                method: null,
            }
        },

        computed: {
            isSetup() {
                return this.$auth.getUser().stripeCustomerId
            },

            heading() {
                return this.isSetup
                    ? this.$t('guest.profile.payment.edit.heading')
                    : this.$t('guest.profile.payment.setup.heading')
            },
        },

        watch: {
            isEditing() {
                this.initializeStripe()
            },
        },

        mounted() {
            this.initializeStripe()
            this.loadPaymentMethod()
        },

        methods: {
            async initializeStripe() {
                if (this.isSetup && !this.isEditing) {
                    return
                }

                this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_API_KEY)
                this.card = this.stripe.elements().create('card')

                this.card.mount('#stripe-card')
                this.card.on('change', (event) => (this.error = event.error?.message))
            },

            loadPaymentMethod() {
                if (!this.isSetup) {
                    return
                }

                return ApiGuests.getStripePaymentMethod(this.$auth.getUser().id).then(
                    (method) => (this.method = method),
                )
            },

            async save() {
                this.isSubmitting = true

                const { clientSecret } = await ApiGuests.getnerateStripeClientSecret(
                    this.$auth.getUser().id,
                )

                this.stripe
                    .confirmCardSetup(clientSecret, {
                        payment_method: { card: this.card },
                    })
                    .then((result) => {
                        this.isSubmitting = false

                        if (result.error) {
                            return (this.error = result.error.message)
                        }

                        if (result.setupIntent.status === 'succeeded') {
                            this.isEditing = false
                        }
                    })
                    .then(() => this.$auth.loadUser(true))
                    .then(() => this.loadPaymentMethod())
            },
        },
    }
</script>
