<template>
    <LayoutOverlay :back-button="true" @back="back">
        <ProSection :heading="heading">
            <FormWrapper>
                <FieldTextarea
                    :placeholder="placeholder"
                    :has-bottom-border="false"
                    :has-autofocus="true"
                    :max-length="maxCharacters"
                    :rows="6"
                    :form-data="formData"
                    :form-errors="formErrors"
                    :value-path="valuePath"
                    @change="change"
                />

                <BaseLimit
                    :text="formData[valuePath]"
                    :limit="maxCharacters"
                    class="mt-10 ml-auto"
                />

                <slot name="buttons" :is-submittable="isSubmittable">
                    <FormButtons>
                        <BaseLink
                            v-if="isSkippable"
                            :label="$t('common.skip')"
                            @click="$emit('skip')"
                        />

                        <BaseButton
                            :label="submitLabel"
                            :is-disabled="!isSubmittable"
                            :is-processing="isSubmitting"
                            @click="save"
                        />
                    </FormButtons>
                </slot>
            </FormWrapper>
        </ProSection>
    </LayoutOverlay>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import BaseLimit from '@/components/base/BaseLimit'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'
    import BaseButton from '@/components/base/BaseButton'
    import BaseLink from '@/components/base/BaseLink'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'

    export default {
        components: {
            BaseLimit,
            FieldTextarea,
            BaseButton,
            BaseLink,
            FormWrapper,
            FormButtons,
        },

        mixins: [MixinProProfileSection],

        emits: ['skip'],

        computed: {
            maxCharacters() {
                return parseInt(process.env.VUE_APP_PRO_MAXLENGTH)
            },

            isRedirectingHome() {
                return false
            },

            isSubmittable() {
                return true
            },

            isSkippable() {
                return false
            },

            heading() {
                return this.$t('pro.profile.places.pro.questions.heading')
            },

            placeholder() {
                return this.$t('pro.profile.places.pro.questions.placeholder')
            },

            submitLabel() {
                return this.$t('common.save')
            },

            valuePath() {
                return 'prosPlaceLocationQuestion'
            },

            apiPath() {
                return 'prosPlaceData'
            },
        },

        methods: {
            apiCall() {
                return ApiPros.update(this.$auth.getUser().id, this.apiPath, this.formData)
            },
        },
    }
</script>
