<template>
    <BaseOverlay :is-open="isOpen" class="flex items-center justify-center" @close="close">
        <TransitionScale>
            <div class="p-50 bg-white shadow-md rounded-md">
                <ProClientsImportUploading />
            </div>
        </TransitionScale>
    </BaseOverlay>
</template>

<script>
    import BaseOverlay from '@/components/base/BaseOverlay'
    import TransitionScale from '@/components/transitions/TransitionScale'
    import ProClientsImportUploading from '@/components/pro/clients/import/ProClientsImportUploading'

    export default {
        components: {
            BaseOverlay,
            TransitionScale,
            ProClientsImportUploading,
        },

        props: {
            isOpen: { type: Boolean, default: false },
        },

        emits: ['close'],

        methods: {
            close() {
                this.$emit('close')
            },
        },
    }
</script>
