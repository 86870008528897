<template>
    <BaseText>
        <p>{{ $t('guest.verify.alert.message') }}</p>
        <p><BaseButton :label="$t('guest.verify.alert.cta')" route="/guest/verify" /></p>
    </BaseText>
</template>

<script>
    import BaseText from '@/components/base/BaseText'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            BaseText,
            BaseButton,
        },
    }
</script>
