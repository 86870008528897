<template>
    <div class="space-y-60">
        <div v-for="(section, index) in sections" :key="index">
            <component
                :is="section"
                :is-editing="editingSection === index"
                :is-disabled="editingSection !== null && editingSection !== index"
                @edit="editingSection = index"
                @save="editingSection = null"
                @cancel="editingSection = null"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            sections: { type: Array, required: true },
        },

        data() {
            return {
                editingSection: null,
            }
        },
    }
</script>
