<template>
    <div>
        <div class="flex items-center justify-center space-x-10 font-bold text-gray-1">
            <IconTime class="h-30 w-30" />

            <template v-if="value">
                <p>{{ formattedValue }}</p>

                <button type="button" @click="$emit('change', null)">
                    <IconDelete class="h-20 w-20" />
                </button>
            </template>

            <template v-else>
                <p>{{ $t('guest.book.when.dates.any-time') }}</p>
            </template>
        </div>

        <div
            class="flex items-center justify-center space-x-10 opacity-50 text-9 text-center font-bold text-gray-2"
        >
            <div class="whitespace-nowrap w-30 flex-shink-0 space-y-3">
                <IconDay class="h-25 w-25" />
                <p>{{ formatHour(startHour) }}</p>
            </div>

            <InputRange
                :range-from="startHour"
                :range-to="endHour"
                :has-tooltip="false"
                :value="value"
                class="w-full"
                @change="change"
            />

            <div class="whitespace-nowrap w-30 flex-shink-0 space-y-3">
                <IconNight class="h-20" />
                <p>{{ formatHour(endHour) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import InputRange from '@/components/form-inputs/InputRange'
    import IconDelete from '@/assets/vectors/icon-delete.svg'
    import IconTime from '@/assets/vectors/icon-time.svg'
    import IconDay from '@/assets/vectors/icon-day.svg'
    import IconNight from '@/assets/vectors/icon-night.svg'

    export default {
        components: {
            InputRange,
            IconDelete,
            IconTime,
            IconDay,
            IconNight,
        },

        props: {
            value: { type: Number, default: null },
            startHour: { type: Number, default: 6 },
            endHour: { type: Number, default: 23 },
        },

        emits: ['change'],

        data() {
            return {
                hadInitialChange: false,
            }
        },

        computed: {
            formattedValue() {
                return dayjs()
                    .hour(this.value)
                    .startOf('hour')
                    .format('h:mm A')
            },
        },

        methods: {
            change(event) {
                if (this.hadInitialChange) {
                    return this.$emit('change', event)
                }

                this.hadInitialChange = true
            },

            formatHour(hour) {
                return dayjs()
                    .hour(hour)
                    .format('h A')
            },
        },
    }
</script>
