<template>
    <header :class="headerClass">
        <RouterLink to="/" :class="logoClass">
            <LogoFreeByrdz v-if="hasSearch" class="h-full" />
            <LogoFreeByrdzFull v-else class="h-full" />
        </RouterLink>

        <template v-if="hasSearch">
            <LandingBook
                :is-inverted="true"
                class="max-w-800 w-full hidden lg:flex ml-50 mr-auto"
            />

            <RouterLink to="/book" class="block ml-auto lg:hidden px-10">
                <IconSearch class="w-30 h-30" />
            </RouterLink>
        </template>

        <nav class="hidden md:block whitespace-nowrap">
            <RouterLink
                v-for="{ label, route } in navLinks"
                :key="label"
                :to="route"
                class="text-14 font-bold tracking-wider uppercase md:text-white px-20 py-10"
            >
                {{ label }}
            </RouterLink>
        </nav>

        <button class="ml-10 md:ml-20 rounded-md md:bg-white" @click="isMenuOpen = true">
            <IconMenuAlt class="h-50 w-50 text-white md:text-purple" />
        </button>

        <div v-if="hasProCta" :class="proCtaClass">
            <div class="w-full mx-auto flex px-20 h-45 space-x-10 items-center justify-center">
                <p class="font-medium">{{ $t('landing.header.pro.question') }}</p>

                <LandingLink
                    :label="$t('landing.header.pro.join')"
                    route="/auth/register/pro"
                    class="inline-block font-bold"
                />
            </div>
        </div>
    </header>

    <p :class="sloganClass">
        <span class="w-50 h-1 bg-black mr-30" />
        <span class="text-12 uppercase font-medium tracking-widester">
            {{ $t('landing.header.slogan') }}
        </span>
    </p>

    <AppMenu :is-open="isMenuOpen" @close="isMenuOpen = false" />
</template>

<script>
    import AppMenu from '@/components/app/AppMenu'
    import LandingBook from '@/components/landing/LandingBook'
    import LandingLink from '@/components/landing/LandingLink'
    import IconMenuAlt from '@/assets/vectors/icon-menu-alt.svg'
    import IconSearch from '@/assets/vectors/icon-search.svg'
    import LogoFreeByrdzFull from '@/assets/vectors/logo-freebyrdz-full.svg'
    import LogoFreeByrdz from '@/assets/vectors/logo-freebyrdz.svg'

    export default {
        components: {
            AppMenu,
            LandingBook,
            LandingLink,
            IconMenuAlt,
            IconSearch,
            LogoFreeByrdzFull,
            LogoFreeByrdz,
        },

        props: {
            hasSearch: { type: Boolean, default: false },
            hasProCta: { type: Boolean, default: false },
            hasTransitionBg: { type: Boolean, default: false },
            isTransparent: { type: Boolean, default: false },
        },

        data() {
            return {
                isSticky: false,
                isMenuOpen: false,
            }
        },

        computed: {
            navLinks() {
                return [
                    {
                        label: this.$t('common.sign-in'),
                        route: '/auth/login',
                    },
                    {
                        label: this.$t('common.sign-up'),
                        route: '/auth/register',
                    },
                ]
            },

            headerClass() {
                return {
                    'sticky': !this.isTransparent,
                    'fixed': this.isTransparent,
                    'z-layout': true,
                    'top-0': true,
                    'inset-x-0': true,
                    'flex': true,
                    'items-center': true,
                    'h-70': true,
                    'pl-20': true,
                    'pr-10': true,
                    'md:h-auto': true,
                    'md:py-20': true,
                    'md:px-30': true,
                    'transition-colors': true,
                    'duration-250': true,
                    'text-white': this.isSticky || !this.hasTransitionBg || this.isTransparent,
                    'bg-purple': this.isSticky || !this.hasTransitionBg,
                }
            },

            logoClass() {
                return {
                    'h-40': true,
                    'md:h-50': true,
                    'mr-auto': !this.hasSearch,
                    'transition-colors': true,
                    'duration-250': true,
                    'text-purple': !this.isTransparent && (!this.isSticky || this.hasTransitionBg),
                    'text-white': this.isTransparent || this.isSticky || !this.hasTransitionBg,
                }
            },

            proCtaClass() {
                return {
                    'absolute': true,
                    'top-full': true,
                    'inset-x-0': true,
                    'bg-yellow-naples': true,
                    'text-purple': true,
                    'shadow-md': true,
                    'transition-opacity': true,
                    'opacity-0': !this.isSticky,
                    'opacity-100': this.isSticky,
                }
            },

            sloganClass() {
                return {
                    'absolute': true,
                    'z-above': true,
                    'top-100': true,
                    'mt-160': true,
                    'left-30': true,
                    'items-center': true,
                    'transform': true,
                    '-rotate-90': true,
                    'origin-top-right': true,
                    '-translate-x-full': true,
                    'text-white': this.isTransparent,
                    'text-black': !this.isTransparent,
                    'hidden': true,
                    'xl:flex': true,
                }
            },
        },

        mounted() {
            // eslint-disable-next-line
            new window.IntersectionObserver(
                (entries) => (this.isSticky = !entries[0].isIntersecting),
                { threshold: 1 },
            ).observe(document.body)
        },
    }
</script>
