<template>
    <div class="flex">
        <component
            :is="starComponent(index)"
            v-for="index in 5"
            :key="index"
            :class="starClass(index)"
        />
    </div>
</template>

<script>
    import IconStarFull from '@/assets/vectors/icon-star-full.svg'

    export default {
        components: {
            IconStarFull,
        },

        props: {
            rating: { type: Number, required: true },
            level: { type: Number, default: 1 },
        },

        methods: {
            starComponent() {
                return IconStarFull
            },

            starClass(index) {
                return {
                    'w-19': this.level === 1,
                    'h-19': this.level === 1,
                    'w-12': this.level === 2,
                    'h-12': this.level === 2,
                    'w-9': this.level === 3,
                    'h-9': this.level === 3,
                    'text-gold': true,
                    'opacity-30': !this.isStarFull(index),
                }
            },

            isStarFull(index) {
                return Math.round(this.rating) >= index
            },
        },
    }
</script>
