<template>
    <LayoutBase>
        <template #top>
            <AppHeader :back-button="backButton" @back="$emit('back')" />
        </template>

        <template #default>
            <slot />
        </template>
    </LayoutBase>
</template>

<script>
    import LayoutBase from '@/components/layouts/LayoutBase'
    import AppHeader from '@/components/app/AppHeader'

    export default {
        components: {
            LayoutBase,
            AppHeader,
        },

        props: {
            backButton: { type: [Boolean, String, Object], default: false },
        },

        emits: ['back'],
    }
</script>
