<template>
    <GuestAppointmentsRateThanks :appointment="appointment" />
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import GuestAppointmentsRateThanks from '@/components/guest/appointments/rate/GuestAppointmentsRateThanks'

    export default {
        components: {
            GuestAppointmentsRateThanks,
        },

        mixins: [MixinFormStep],

        props: {
            appointment: { type: Object, required: true },
        },
    }
</script>
