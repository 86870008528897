<template>
    <LayoutOverlay :back-button="backButton">
        <component
            :is="currentStep.component"
            :step-data="formData"
            @change="changeData"
            @go-to="goToStep"
            @go-to-start="goToStart"
        />
    </LayoutOverlay>
</template>

<script>
    import ApiProLicenses from '@/apis/ApiProLicenses'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import ProProfileLicensesStep1 from '@/components/pro/profile/licenses/ProProfileLicensesStep1'
    import ProProfileLicensesStep2 from '@/components/pro/profile/licenses/ProProfileLicensesStep2'
    import ProProfileLicensesStep3 from '@/components/pro/profile/licenses/ProProfileLicensesStep3'

    export default {
        components: {
            LayoutOverlay,
            FormButtons,
            BaseButton,
        },

        mixins: [MixinForm, MixinFormSteppable],

        async loadData({ $auth }) {
            // TODO: Replace with loading licenses to global session store.
            const licenses = await ApiProLicenses.get($auth.getUser().id)

            return { licenses }
        },

        computed: {
            steps() {
                return [
                    { component: ProProfileLicensesStep1 },
                    { component: ProProfileLicensesStep2 },
                    { component: ProProfileLicensesStep3 },
                ]
            },

            backButton() {
                return (this.licenses || []).length ? '/pro/profile/licenses' : '/pro/profile'
            },
        },
    }
</script>
