<template>
    <GuestBookStep
        :heading="$t('guest.book.payment.heading')"
        :subheading="$t('guest.book.payment.subheading')"
        :has-progress="hasProgress"
        @submit="saveAndGoToNextStep"
    >
        <template #default>
            <div class="flex items-center border-b-1 border-gray-5 pb-10 mt-20 mb-20">
                <p class="font-black uppercase text-16">{{ $t('common.total') }}</p>
                <p class="font-bold text-24 ml-auto">${{ totalPrice }}</p>
            </div>

            <BaseNote :content="$t('guest.book.charge-info')" class="mb-40" />

            <GuestStripe />
        </template>

        <template #bottom>
            <BaseButton
                :label="confirmLabel"
                :is-disabled="!isSubmittable"
                :is-processing="isSubmitting"
            />
        </template>
    </GuestBookStep>
</template>

<script>
    import ApiGuestAppointments from '@/apis/ApiGuestAppointments'
    import MixinBookStep from '@/mixins/MixinBookStep'
    import BaseButton from '@/components/base/BaseButton'
    import BaseNote from '@/components/base/BaseNote'
    import GuestStripe from '@/components/guest/GuestStripe'

    export default {
        components: {
            BaseButton,
            BaseNote,
            GuestStripe,
        },

        mixins: [MixinBookStep],

        computed: {
            totalPrice() {
                return this.appointment.price + this.$store.getters['dictionaries/getServiceFee']
            },

            isSubmittable() {
                return !!this.$auth.getUser().stripeCustomerId
            },

            confirmLabel() {
                return this.$t('guest.book.payment.confirm', { price: this.totalPrice })
            },
        },

        methods: {
            saveAndGoToNextStep() {
                this.handleSubmit(
                    ApiGuestAppointments.create(this.$auth.getUser(), this.appointment),
                ).then(this.goToNextStep)
            },
        },
    }
</script>
