<template>
    <div class="space-y-20">
        <ProAppointmentsShowHeader :appointment="appointment" :is-compact="true" />
        <ProAppointmentsShowDatetimes :appointment="appointment" />
        <BaseReceipt :positions="appointmentPrices" :has-separators="false">
            <template #position="{ position }">
                <FieldAmount
                    v-if="position.isField"
                    :form-data="formData"
                    :value-path="position.path"
                    type="number"
                    class="w-70"
                    @change="change"
                />

                <div v-if="position.isDiscount" class="flex items-center space-x-10">
                    <template v-if="formData.discountType">
                        <BaseButtonEdit @click="isDiscountPanelOpen = true" />

                        <span>
                            {{
                                formData.discountType === 'percent'
                                    ? `- ${formData.discountAmount}%`
                                    : `- $ ${formData.discountAmount}`
                            }}
                        </span>
                    </template>

                    <BaseLink
                        v-else
                        :label="discountLabel"
                        class="text-16"
                        @click="isDiscountPanelOpen = true"
                    />
                </div>
            </template>
        </BaseReceipt>
    </div>

    <FormButtons class="-shadow-md">
        <p class="-mx-20 -mt-15 mb-15 py-15 px-20 bg-sand-light text-14 text-gray-2">
            {{ $t('pro.appointments.finish.charge.revise') }}
        </p>

        <BaseButton
            :label="confirmLabel"
            :is-disabled="!isSubmittable"
            :is-processing="isSubmitting"
            @click="confirmCharge"
        />
    </FormButtons>

    <ProAppointmentsChargeDiscount
        :is-open="isDiscountPanelOpen"
        :discount-data="formData"
        @save="updateDiscount"
        @close="isDiscountPanelOpen = false"
    />
</template>

<script>
    import { formatAmount } from '@/helpers'
    import ApiProAppointments from '@/apis/ApiProAppointments'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import MixinProAppointmentsCharge from '@/mixins/MixinProAppointmentsCharge'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldAmount from '@/components/form-fields/FieldAmount'
    import BaseButton from '@/components/base/BaseButton'
    import BaseButtonEdit from '@/components/base/BaseButtonEdit'
    import BaseReceipt from '@/components/base/BaseReceipt'
    import BaseLink from '@/components/base/BaseLink'
    import ProAppointmentsShowHeader from '@/components/pro/appointments/show/ProAppointmentsShowHeader'
    import ProAppointmentsShowDatetimes from '@/components/pro/appointments/show/ProAppointmentsShowDatetimes'
    import ProAppointmentsChargeDiscount from '@/components/pro/appointments/charge/ProAppointmentsChargeDiscount'

    export default {
        components: {
            FormButtons,
            FieldAmount,
            BaseButton,
            BaseButtonEdit,
            BaseReceipt,
            BaseLink,
            ProAppointmentsShowHeader,
            ProAppointmentsShowDatetimes,
            ProAppointmentsChargeDiscount,
        },

        mixins: [MixinFormStep, MixinFormSubmittable, MixinAppointment, MixinProAppointmentsCharge],

        props: {
            appointment: { type: Object, required: true },
        },

        data() {
            return {
                isDiscountPanelOpen: false,
            }
        },

        computed: {
            isSubmittable() {
                return !this.appointmentPrices.some(
                    ({ price, isDiscount }) => !isDiscount && price < 0,
                )
            },

            confirmLabel() {
                return this.$t('pro.appointments.finish.charge.confirm', {
                    amount: formatAmount(this.totalAmount),
                })
            },

            discountLabel() {
                return this.formData.discountType
                    ? this.$t('pro.appointments.finish.charge.discount.edit')
                    : this.$t('pro.appointments.finish.charge.discount.add')
            },
        },

        methods: {
            updateDiscount(formData) {
                this.change({ path: 'discountType', value: formData.discountType })
                this.change({ path: 'discountAmount', value: formData.discountAmount })
                this.isDiscountPanelOpen = false
            },

            confirmCharge() {
                this.handleSubmit(
                    ApiProAppointments.charge(
                        this.$auth.getUser(),
                        this.appointment.id,
                        this.formData,
                    ),
                ).then(this.goToNextStep)
            },
        },
    }
</script>
