<template>
    <ProOnboardingStep :heading="$t('pro.onboarding.experiences.heading')" @submit="goToNextStep">
        <FormFields>
            <FormLegend :label="$t('common.choose-one')" />

            <FieldChoices
                :options="options"
                :form-data="formData"
                value-path="categories"
                @change="change"
            />
        </FormFields>

        <FormButtons>
            <BaseButton :label="$t('common.next')" :is-disabled="!isSubmittable" />
        </FormButtons>
    </ProOnboardingStep>
</template>

<script>
    import { getCategoryIcon } from '@/helpers'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProOnboardingStep from '@/components/pro/onboarding/ProOnboardingStep'
    import FormFields from '@/components/form-elements/FormFields'
    import FormLegend from '@/components/form-elements/FormLegend'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldChoices from '@/components/form-fields/FieldChoices'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            ProOnboardingStep,
            FormFields,
            FormLegend,
            FormButtons,
            FieldChoices,
            BaseButton,
        },

        mixins: [MixinFormStep],

        computed: {
            isSubmittable() {
                return (this.formData.categories || []).length
            },

            categories() {
                return this.$store.getters['dictionaries/getCategories']
            },

            options() {
                return this.categories.map((category) => ({
                    value: category.id,
                    label: category.name,
                    icon: getCategoryIcon(category),
                }))
            },
        },
    }
</script>
