<template>
    <div class="col-count-1 md:col-count-2 col-gap-50 -mb-30 lg:-mb-50">
        <section
            v-for="(attribute, index) in attributes"
            :key="index"
            class="bi-avoid relative pb-30 lg:pb-50 pl-60 lg:pl-80"
        >
            <component :is="attribute.icon" class="absolute -top-10 -left-30 w-90 lg:w-100" />

            <h3 class="text-18 leading-heading lg:text-20 font-bold pb-10 lg:lg-15">
                {{ attribute.heading }}
            </h3>

            <!-- eslint-disable vue/no-v-html -->
            <div
                class="text-14 lg:text-16 lg:font-medium space-y-10 text-gray-1 leading-loose"
                v-html="sanitizeAndFormatText(attribute.description)"
            />
        </section>
    </div>
</template>

<script>
    import { sanitizeAndFormatText } from '@/helpers'

    export default {
        props: {
            attributes: { type: Array, required: true },
        },

        methods: {
            sanitizeAndFormatText,
        },
    }
</script>
