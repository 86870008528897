<template>
    <LayoutOverlay :back-button="backButton" @back="handleBack">
        <component
            :is="currentStep.component"
            :step-data="formData"
            @change="changeData"
            @go-to="handleGoTo"
            @go-to-start="goToStart"
        >
            <template v-if="submitLabel" #buttons="{ isSubmittable }">
                <FormButtons>
                    <BaseButton
                        :label="submitLabel"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                        @click="handleNext"
                    />
                </FormButtons>
            </template>
        </component>
    </LayoutOverlay>
</template>

<script>
    import ApiProAddOns from '@/apis/ApiProAddOns'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import ProProfileAddOnsStep1 from '@/components/pro/profile/addons/ProProfileAddOnsStep1'
    import ProProfileAddOnsStep2 from '@/components/pro/profile/addons/ProProfileAddOnsStep2'
    import ProProfileAddOnsStep3 from '@/components/pro/profile/addons/ProProfileAddOnsStep3'
    import ProProfileAddOnsStep4 from '@/components/pro/profile/addons/ProProfileAddOnsStep4'
    import ProProfileAddOnsStep5 from '@/components/pro/profile/addons/ProProfileAddOnsStep5'
    import ProProfileAddOnsStep6 from '@/components/pro/profile/addons/ProProfileAddOnsStep6'
    import ProProfileAddOnsStep7 from '@/components/pro/profile/addons/ProProfileAddOnsStep7'
    import ProProfileAddOnsStep8 from '@/components/pro/profile/addons/ProProfileAddOnsStep8'
    import ProProfileAddOnsStep9 from '@/components/pro/profile/addons/ProProfileAddOnsStep9'
    import ProProfileAddOnsStep10 from '@/components/pro/profile/addons/ProProfileAddOnsStep10'

    export default {
        components: {
            LayoutOverlay,
            FormButtons,
            BaseButton,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormSteppable, MixinProExperiences],

        data() {
            return {
                rememberedStep: null,
            }
        },

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },

            steps() {
                return [
                    { component: ProProfileAddOnsStep1 },
                    { component: ProProfileAddOnsStep2 },
                    { component: ProProfileAddOnsStep3 },
                    { component: ProProfileAddOnsStep4 },
                    { component: ProProfileAddOnsStep5 },
                    { component: ProProfileAddOnsStep6 },
                    { component: ProProfileAddOnsStep7 },
                    { component: ProProfileAddOnsStep8 },
                    { component: ProProfileAddOnsStep9 },
                    { component: ProProfileAddOnsStep10 },
                ]
            },

            backButton() {
                const backLink = (this.$auth.getUser().addOns || []).length
                    ? '/pro/profile/addOns'
                    : '/pro/profile'

                return this.isFirstStep || this.isLastStep ? backLink : true
            },

            submitLabel() {
                if (this.rememberedStep) {
                    return this.$t('common.confirm')
                }

                if (this.step === 9) {
                    return this.$t('common.save')
                }

                return this.$t('common.next')
            },
        },

        methods: {
            handleGoTo() {},

            handleBack() {
                if (this.rememberedStep) {
                    this.goToStep(this.rememberedStep)
                    return (this.rememberedStep = null)
                }

                if (this.step === 7) {
                    return this.formData.isOfferedInPro ? this.goToStep(6) : this.goToStep(4)
                }

                if (this.step === 8) {
                    return this.formData.isOfferedInGuest ? this.goToStep(7) : this.goToStep(6)
                }

                this.goToPreviousStep()
            },

            handleNext() {
                if (this.rememberedStep) {
                    this.goToStep(this.rememberedStep)
                    return (this.rememberedStep = null)
                }

                if (this.step === 4) {
                    if (this.formData.isOfferedInPro) {
                        return this.goToStep(5)
                    }

                    this.change({ path: 'isOfferedInGuest', value: true })
                    this.change({ path: 'workPlace', value: ['guest'] })

                    return this.goToStep(7)
                }

                if (this.step === 6) {
                    return this.formData.isOfferedInGuest ? this.goToStep(7) : this.goToStep(8)
                }

                if (this.step === 9) {
                    return this.save()
                }

                this.goToNextStep()
            },

            save() {
                return this.handleSubmit(
                    ApiProAddOns.create(this.$auth.getUser().id, this.formData),
                ).then(() => this.goToStep(10))
            },
        },
    }
</script>
