<template>
    <TransitionSlide from="top">
        <div
            v-if="message && !message.isPostponed"
            class="fixed top-0 right-0 left-0 z-layout shadow-md"
        >
            <BaseMessage
                :icon="messageIcon"
                :message="message.text"
                :type="message.type"
                class="safe-top"
                @click="clearMessage($event)"
            />
        </div>
    </TransitionSlide>
</template>

<script>
    import { mapGetters } from 'vuex'
    import TransitionSlide from '@/components/transitions/TransitionSlide'
    import BaseMessage from '@/components/base/BaseMessage'
    import IconSuccess from '@/assets/vectors/icon-success.svg'
    import IconError from '@/assets/vectors/icon-error.svg'

    export default {
        components: {
            TransitionSlide,
            BaseMessage,
        },

        computed: {
            ...mapGetters({
                message: 'ui/flash/getMessage',
            }),

            messageIcon() {
                return {
                    success: IconSuccess,
                    error: IconError,
                }[this.message.type]
            },
        },

        methods: {
            clearMessage(event) {
                event.preventDefault()
                event.stopPropagation()

                this.$ui.flash.clear()
            },
        },
    }
</script>
