<template>
    <div class="space-y-30">
        <BaseChoice :icon="icon" :label="category.name" :level="1" :is-selected="true" />

        <FormLegend :label="$t('common.choose-one')" />

        <FieldPills
            :options="subCategories"
            :form-data="formData"
            :value-path="valuePath"
            @change="change"
        />
    </div>
</template>

<script>
    import { getCategoryIcon, getCategoryOptions } from '@/helpers'
    import MixinFormChangable from '@/mixins/MixinFormChangable'
    import MixinFormFragment from '@/mixins/MixinFormFragment'
    import BaseChoice from '@/components/base/BaseChoice'
    import FieldPills from '@/components/form-fields/FieldPills'
    import FormLegend from '@/components/form-elements/FormLegend'

    export default {
        components: {
            BaseChoice,
            FieldPills,
            FormLegend,
        },

        mixins: [MixinFormChangable, MixinFormFragment],

        props: {
            category: { type: Object, required: true },
        },

        computed: {
            icon() {
                return getCategoryIcon(this.category)
            },

            subCategories() {
                return getCategoryOptions(
                    this.$store.getters['dictionaries/getCategories'],
                    this.category,
                )
            },
        },
    }
</script>
