<template>
    <ProSection>
        <template #heading>
            <div class="flex">
                <p class="mr-10">{{ formData.name }}</p>

                <button type="button" class="p-5 block ml-auto" @click="goToStep('name')">
                    <IconModify class="h-20 w-20" />
                </button>
            </div>
        </template>

        <template #description>
            <div class="flex">
                <p class="mr-10">{{ formData.description }}</p>

                <button type="button" class="p-5 block ml-auto" @click="goToStep('description')">
                    <IconModify class="h-20 w-20" />
                </button>
            </div>
        </template>

        <template #default>
            <FormWrapper>
                <FormFields class="flex-grow">
                    <ProProfileAddOnsDetails :add-on="addOn" :is-editable="true" @edit="goToStep" />

                    <ProProfileDelete
                        v-if="isEdited"
                        :name="formData.name"
                        :is-pro-enabled="isOfferedAt('pro')"
                        :is-guest-enabled="isOfferedAt('guest')"
                        @remove-place="removePlace"
                        @delete="deleteAddOn"
                    />
                </FormFields>

                <slot name="buttons" :is-submittable="true" />
            </FormWrapper>
        </template>
    </ProSection>
</template>

<script>
    import ApiProAddOns from '@/apis/ApiProAddOns'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import IconModify from '@/assets/vectors/icon-modify.svg'
    import ProProfileAddOnsDetails from '@/components/pro/profile/addons/ProProfileAddOnsDetails'
    import ProProfileDelete from '@/components/pro/profile/ProProfileDelete'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormFields,
            IconModify,
            ProProfileAddOnsDetails,
            ProProfileDelete,
        },

        mixins: [MixinFormStep, MixinProProfileSection, MixinProExperiences],

        emits: ['save'],

        computed: {
            addOn() {
                return {
                    ...this.formData,
                    experiences:
                        this.formData.experiences ||
                        this.proExperiences.filter(({ id }) =>
                            (this.formData.experienceIds || []).includes(id),
                        ),
                }
            },

            isEdited() {
                return !!this.formData.id
            },
        },

        methods: {
            isOfferedAt(place) {
                return this.formData.workPlace.includes(place)
            },

            deleteAddOn() {
                ApiProAddOns.delete(this.$auth.getUser().id, this.formData.id).then(() => {
                    this.$router.push('/pro/profile/addons')
                })
            },

            removePlace(place) {
                const isPro = place === 'pro'

                this.change({ path: isPro ? 'prosPlacePrice' : 'guestsPlacePrice', value: null })
                this.change({ path: 'workPlace', value: isPro ? ['guest'] : ['pro'] })

                ApiProAddOns.update(
                    this.$auth.getUser().id,
                    this.formData.id,
                    this.formData,
                ).then((addOn) => this.initForm(addOn))
            },
        },
    }
</script>
.
