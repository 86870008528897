<template>
    <LayoutOverlay back-button="/pro/clients">
        <ProClientsImportSelect v-if="!isSubmitting && !isSubmitted" @select="selectAndUpload" />
        <ProClientsImportUploading v-else-if="isSubmitting" />
        <ProClientsImportDone v-else-if="isSubmitted" :imported-count="importedCount" />
    </LayoutOverlay>
</template>

<script>
    import ApiProClients from '@/apis/ApiProClients'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import ProClientsImportSelect from '@/components/pro/clients/import/ProClientsImportSelect'
    import ProClientsImportUploading from '@/components/pro/clients/import/ProClientsImportUploading'
    import ProClientsImportDone from '@/components/pro/clients/import/ProClientsImportDone'

    export default {
        components: {
            LayoutOverlay,
            ProClientsImportSelect,
            ProClientsImportUploading,
            ProClientsImportDone,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        data() {
            return {
                importedCount: 0,
            }
        },

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },
        },

        methods: {
            selectAndUpload(file) {
                this.change({ path: 'file', value: file })
                this.upload()
            },

            upload() {
                const formData = new FormData()
                formData.append('file', this.formData.file)

                this.handleSubmit(ApiProClients.import(this.$auth.getUser().id, formData))
                    .then(({ numberOfClients }) => (this.importedCount = numberOfClients))
                    .catch(() => this.$ui.flash.error(this.$t('pro.clients.import.error')))
            },
        },
    }
</script>
