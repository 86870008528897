<template>
    <LandingContainer :level="2" class="pt-60 pb-40 md:pt-80 text-center md:pb-0">
        <LandingSplit v-bind="placeIcons" class="mb-30 xs:mb-40" />

        <LandingHeading :level="2" class="text-purple mb-20 md:mb-30">
            {{ $t('landing.index.places.heading.1') }}
            <br />
            {{ $t('landing.index.places.heading.2') }}
        </LandingHeading>

        <LandingText :content="$t('landing.index.places.description')" />
    </LandingContainer>

    <!-- TODO: Add carousel -->
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingText from '@/components/landing/LandingText'
    import LandingSplit from '@/components/landing/LandingSplit'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import IconProThin from '@/assets/vectors/icon-pro-thin.svg'
    import IconGuestThin from '@/assets/vectors/icon-guest-thin.svg'
    import IconGuest from '@/assets/vectors/icon-guest.svg'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingText,
            LandingSplit,
        },

        computed: {
            placeIcons() {
                return {
                    left: { thick: IconPro, thin: IconProThin },
                    right: { thick: IconGuest, thin: IconGuestThin },
                }
            },
        },
    }
</script>
