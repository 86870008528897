<template>
    <div class="flex flex-row items-center">
        <div class="relative flex items-center justify-center h-40 w-40">
            <IconCalendarDay class="absolute inset-0" />
            <p class="pt-10 font-bold text-22">{{ formattedDayNumber }}</p>
        </div>

        <div class="font-bold text-20 ml-15">
            {{ dayOfWeek + ',' }}
            <br />
            {{ month + ' ' + year }}
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import IconCalendarDay from '@/assets/vectors/icon-calendar-day.svg'

    export default {
        components: {
            IconCalendarDay,
        },

        props: {
            date: { type: Object, default: dayjs() },
        },

        computed: {
            formattedDayNumber() {
                return this.date.format('D')
            },

            dayOfWeek() {
                return this.date.format('dddd')
            },

            month() {
                return this.date.format('MMMM')
            },

            year() {
                return this.date.format('YYYY')
            },
        },
    }
</script>
