<template>
    <LayoutPro back-button="/auth/detect" class="bg-gray-6">
        <div class="sticky z-floating top-70 bg-white -mx-20 px-20 mb-20">
            <BaseHeaderSection
                :heading="$t('pro.appointments.index.heading')"
                :is-center="false"
                class="mb-20"
            />

            <BaseTabs :items="listTabs" />
        </div>

        <div class="flex-grow flex flex-col space-y-20 pb-20">
            <template v-if="appointments?.length > 0">
                <ProAppointmentsAppointment
                    v-for="appointment in appointments"
                    :key="appointment.id"
                    :appointment="appointment"
                />

                <BaseButton
                    v-if="!isPaginationFinished"
                    :label="$t('common.load-more')"
                    :level="2"
                    :is-disabled="isPaging"
                    @click="loadNextPage"
                />
            </template>

            <BaseBlankSlate v-else :message="$t('pro.appointments.index.blank')" />
        </div>
    </LayoutPro>

    <ProAppointmentsUnread
        :is-open="isUnreadPanelOpen"
        :appointments="unreadAppointments"
        @close="isUnreadPanelOpen = false"
    />
</template>

<script>
    import ApiProAppointments from '@/apis/ApiProAppointments'
    import MixinPaginate from '@/mixins/MixinPaginate'
    import MixinProAppointmentsUnread from '@/mixins/MixinProAppointmentsUnread'
    import LayoutPro from '@/components/layouts/LayoutPro'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseTabs from '@/components/base/BaseTabs'
    import BaseButton from '@/components/base/BaseButton'
    import BaseBlankSlate from '@/components/base/BaseBlankSlate'
    import ProAppointmentsAppointment from '@/components/pro/appointments/ProAppointmentsAppointment'

    export default {
        components: {
            LayoutPro,
            BaseHeaderSection,
            BaseTabs,
            BaseButton,
            BaseBlankSlate,
            ProAppointmentsAppointment,
        },

        mixins: [MixinPaginate, MixinProAppointmentsUnread],

        async loadData({ $auth, $route }) {
            const appointments = await ApiProAppointments.get($auth.getUser(), {
                type: $route.params.type,
                page: 1,
            })

            return { appointments }
        },

        computed: {
            listTabs() {
                return ['future', 'previous', 'cancelled'].map((type) => ({
                    label: this.$t(`common.appointment-types.${type}`),
                    route: { path: `/pro/appointments/${type}` },
                    isSelected: this.$route.params.type === type,
                }))
            },

            paginatedListProp() {
                return 'appointments'
            },

            paginatedCall() {
                return ApiProAppointments.get(this.$auth.getUser(), {
                    type: this.$route.params.type,
                    page: this.page,
                })
            },
        },
    }
</script>
