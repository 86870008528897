<template>
    <LayoutApp back-button="/">
        <header class="pb-30 space-y-15">
            <BaseHeaderSection
                :heading="$t('auth.login.heading')"
                :subheading="$t('auth.login.subheading')"
            />

            <BaseText class="text-center">
                <i18n-t keypath="auth.login.new.text">
                    <template #link>
                        <BaseLink :label="$t('auth.login.new.link')" route="/auth/register" />
                    </template>
                </i18n-t>
            </BaseText>
        </header>

        <FormWrapper @submit="submitForm">
            <FormFields>
                <FieldText
                    :label="$t('common.fields.email')"
                    :is-required="true"
                    :has-autofocus="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    :error="generalError"
                    value-path="email"
                    type="email"
                    @change="change"
                />

                <FieldText
                    :label="$t('common.fields.password')"
                    :is-required="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="password"
                    type="password"
                    @change="change"
                />

                <p>
                    <BaseLink
                        :label="$t('auth.login.forgot-password')"
                        route="/auth/forgot-password"
                    />
                </p>
            </FormFields>

            <FormButtons>
                <BaseButton
                    :label="$t('auth.login.submit')"
                    :is-processing="isSubmitting"
                    :is-disabled="!isSubmittable"
                />
            </FormButtons>
        </FormWrapper>
    </LayoutApp>
</template>

<script>
    import ApiAuth from '@/apis/ApiAuth'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldText from '@/components/form-fields/FieldText'

    export default {
        components: {
            LayoutApp,
            BaseHeaderSection,
            BaseText,
            BaseLink,
            BaseButton,
            FormWrapper,
            FormFields,
            FormButtons,
            FieldText,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        computed: {
            isSubmittable() {
                return this.formData.email && this.formData.password
            },

            generalError() {
                if (this.formErrors?.message && !this.formErrors?.errors) {
                    return this.formErrors?.message
                }

                return null
            },
        },

        methods: {
            submitForm() {
                this.handleSubmit(ApiAuth.login(this.formData)).then(({ token }) => {
                    this.$auth.logIn(token)
                    this.$router.push(
                        this.$route.query.redirect ? this.$route.query.redirect : '/auth/detect',
                    )
                })
            },
        },
    }
</script>
