<template>
    <div>
        <button type="button" class="text-gray-3" @click="isActionsPanelOpen = true">
            <IconDots class="h-30 w-30" />
        </button>

        <BaseDrawer
            :is-open="isActionsPanelOpen"
            slide-from="bottom"
            @close="isActionsPanelOpen = false"
        >
            <BaseLinks
                :items="links"
                class="uppercase text-14 px-20 py-15"
                @click-item="clickItem"
                @close="isActionsPanelOpen = false"
            />
        </BaseDrawer>
    </div>
</template>

<script>
    import { composeFullName } from '@/helpers'
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseLinks from '@/components/base/BaseLinks'
    import IconDots from '@/assets/vectors/icon-dots.svg'

    export default {
        components: {
            BaseDrawer,
            BaseLinks,
            IconDots,
        },

        props: {
            client: { type: Object, required: true },
        },

        emits: ['edit', 'delete', 'close'],

        data() {
            return {
                isActionsPanelOpen: false,
            }
        },

        computed: {
            links() {
                return [
                    {
                        label: this.$t('common.modify'),
                        event: () => {
                            this.$emit('edit')
                            this.isActionsPanelOpen = false
                        },
                    },
                    {
                        label: this.$t('common.delete'),
                        event: () =>
                            this.$ui
                                .confirm({
                                    message: this.$t('pro.clients.show.delete-client', {
                                        name: composeFullName(this.client),
                                    }),
                                })
                                .then((isConfirmed) => isConfirmed && this.$emit('delete')),
                    },
                    {
                        label: this.$t('common.close'),
                        event: () => (this.isActionsPanelOpen = false),
                    },
                ]
            },
        },

        methods: {
            clickItem({ event }) {
                event && event()
            },
        },
    }
</script>
