<template>
    <div class="-mt-40 flex justify-center">
        <GuestVerificationStatus :guest="$auth.getUser()" />
    </div>
</template>

<script>
    import GuestVerificationStatus from '@/components/guest/GuestVerificationStatus'

    export default {
        components: {
            GuestVerificationStatus,
        },
    }
</script>
