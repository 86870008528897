<template>
    <section class="space-y-10">
        <BaseCalendarWeekdays />

        <section v-for="{ year, month } in months" :key="[year, month]" class="space-y-10">
            <header class="font-bold text-center text-gray-2">
                {{ monthLabel(year, month) }}
            </header>

            <BaseCalendarDays
                :year="year"
                :month="month"
                :selected-dates="selectedDates"
                :disabled-dates="disabledDates"
                @click-date="$emit('click-date', $event)"
            />
        </section>
    </section>
</template>

<script>
    import dayjs from 'dayjs'
    import BaseCalendarWeekdays from '@/components/base/BaseCalendarWeekdays'
    import BaseCalendarDays from '@/components/base/BaseCalendarDays'

    export default {
        components: {
            BaseCalendarWeekdays,
            BaseCalendarDays,
        },

        props: {
            startDate: { type: Object, default: dayjs() },
            endDate: { type: Object, default: dayjs().add(4, 'M') },
            selectedDates: { type: Array, default: () => [] },
            disabledDates: { type: Array, default: () => [] },
        },

        emits: ['click-date'],

        computed: {
            months() {
                return Array(this.endDate.diff(this.startDate, 'month'))
                    .fill(0)
                    .map((month, index) => {
                        const date = this.startDate.startOf('month').add(index, 'months')
                        return {
                            year: date.year(),
                            month: date.month(),
                        }
                    })
            },
        },

        methods: {
            monthLabel(year, month) {
                return dayjs()
                    .year(year)
                    .month(month)
                    .format('MMMM')
            },
        },
    }
</script>
