<template>
    <div class="col-count-1 md:col-count-2 col-gap-50 -mb-30">
        <section
            v-for="(feature, index) in features"
            :key="index"
            class="bi-avoid relative pb-30 pl-45"
        >
            <IconSuccess class="absolute w-25 top-2 left-0" />

            <!-- eslint-disable vue/no-v-html -->
            <div
                class="text-14 lg:text-16 space-y-10 text-gray-1 leading-loose"
                v-html="sanitizeAndFormatText(feature)"
            />
        </section>
    </div>
</template>

<script>
    import { sanitizeAndFormatText } from '@/helpers'
    import IconSuccess from '@/assets/vectors/icon-success.svg'

    export default {
        components: {
            IconSuccess,
        },

        props: {
            features: { type: Array, required: true },
        },

        methods: {
            sanitizeAndFormatText,
        },
    }
</script>
