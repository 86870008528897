<template>
    <LayoutLanding>
        <LandingHeader :has-search="true" />
        <div class="flex-grow flex flex-col overflow-x-hidden">
            <LandingSafetyIntro />
            <LandingSafetyTech />
            <LandingSafetyFaq />
            <LandingSafetySearch />
        </div>
    </LayoutLanding>
</template>

<script>
    import LayoutLanding from '@/components/layouts/LayoutLanding'
    import LandingHeader from '@/components/landing/LandingHeader'
    import LandingSafetyIntro from '@/components/landing/safety/LandingSafetyIntro'
    import LandingSafetyTech from '@/components/landing/safety/LandingSafetyTech'
    import LandingSafetyFaq from '@/components/landing/safety/LandingSafetyFaq'
    import LandingSafetySearch from '@/components/landing/safety/LandingSafetySearch'

    export default {
        components: {
            LayoutLanding,
            LandingHeader,
            LandingSafetyIntro,
            LandingSafetyTech,
            LandingSafetyFaq,
            LandingSafetySearch,
        },
    }
</script>
