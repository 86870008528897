<template>
    <BaseMap ref="map" class="flex-grow w-full z-below" />
    <GuestBookStep4Reel :pros="pros" class="mt-10" @click-pro="$emit('click-pro', $event)" />
</template>

<script>
    import Leaflet from 'leaflet'
    import BaseMap from '@/components/base/BaseMap'
    import GuestBookStep4Reel from '@/components/guest/book/step-4/GuestBookStep4Reel'

    export default {
        components: {
            BaseMap,
            GuestBookStep4Reel,
        },

        props: {
            pros: { type: Array, default: null },
        },

        emits: ['click-pro'],

        watch: {
            pros(pros) {
                pros?.length && this.showPinsAndFitToBounds()
            },
        },

        mounted() {
            this.pros?.length && this.showPinsAndFitToBounds()
        },

        methods: {
            showPinsAndFitToBounds() {
                const map = this.$refs.map.leaflet
                const normalPin = Leaflet.icon({
                    iconUrl: require('@/assets/images/icon-pin.png').default,
                    iconSize: [26, 36],
                    iconAnchor: [13, 36],
                })
                const activePin = Leaflet.icon({
                    iconUrl: require('@/assets/images/icon-pin-active.png').default,
                    iconSize: [26, 36],
                    iconAnchor: [13, 36],
                })

                const markers = Leaflet.featureGroup(
                    this.pros
                        .map((pro) => {
                            if (!pro.address?.latitude || !pro.address?.longitude) {
                                return
                            }

                            const marker = Leaflet.marker(
                                [pro.address?.latitude, pro.address?.longitude],
                                { icon: normalPin },
                            ).addTo(map)

                            marker.on('mouseover', function() {
                                this.setIcon(activePin)
                            })
                            marker.on('mouseout', function() {
                                this.setIcon(normalPin)
                            })

                            return marker
                        })
                        .filter((marker) => marker),
                ).addTo(map)

                map.fitBounds(markers.getBounds(), { padding: [60, 60] })
            },
        },
    }
</script>
