<template>
    <transition
        :enter-from-class="transitionClosedClass"
        :leave-to-class="transitionClosedClass"
        enter-active-class="transform transition duration-250"
        leave-active-class="transform transition duration-500 pointer-events-none"
        enter-to-class="translate-y-0 translate-x-0 opacity-100"
        leave-from-class="translate-y-0 translate-x-0 opacity-100"
        :appear="isAppear"
    >
        <slot />
    </transition>
</template>

<script>
    export default {
        props: {
            from: { type: String, required: true },
            isAppear: { type: Boolean, default: true },
        },

        computed: {
            transitionClosedClass() {
                return {
                    top: '-translate-y-full opacity-0',
                    right: 'translate-x-full opacity-0',
                    bottom: 'translate-y-full opacity-0',
                    left: '-translate-x-full opacity-0',
                }[this.from]
            },
        },
    }
</script>
