<template>
    <div class="flex flex-row min-h-full" @click="closeContextMenus($event)">
        <div class="bg-purple min-w-120 min-h-full">
            <RouterLink class="cursor-pointer" :to="'/admin/dashboard'">
                <LogoFreeByrdz class="mt-30 text-white w-50 m-auto mb-60" />
            </RouterLink>

            <BaseNavigation :items="navigationItems" :styles="'flex-col space-y-50'" />
        </div>
        <div class="flex-grow min-h-full flex flex-col">
            <div class="bg-white shadow-md h-80 flex flex-row justify-between pl-30 pr-30">
                <CalendarDateLabel
                    v-if="startDate.isSame(endDate)"
                    :date="startDate"
                    class="cursor-pointer"
                    @click="openDateRangePicker($event)"
                />

                <div
                    v-else
                    class="flex items-center cursor-pointer"
                    @click="openDateRangePicker($event)"
                >
                    <ProDashboardDates
                        :view="'custom'"
                        :start-date="startDate"
                        :end-date="endDate"
                    />
                </div>

                <div
                    ref="profile"
                    class="flex flex-row cursor-pointer"
                    @click="openContextMenu($event)"
                >
                    <div class="flex items-center mr-10 ml-10">
                        <p class="text-14 uppercase font-bold">
                            {{ $t('admin.welcome', { name: $auth.getUser().firstName }) }}
                        </p>
                    </div>

                    <div class="flex items-center ml-10">
                        <BaseAvatar
                            :photo="$auth.getUser().profilePicture"
                            class="h-60 w-60 rounded-full"
                        />
                    </div>
                </div>
            </div>

            <div class="flex-grow">
                <BaseContextMenu
                    ref="dateRangePicker"
                    :width="'750px'"
                    :top="'5.1rem'"
                    :left="'7.5rem'"
                >
                    <BaseCalendarDateRange
                        @select-period="selectPeriod"
                        @cancel-period="cancelPeriod"
                    />
                </BaseContextMenu>
                <slot />
            </div>
        </div>
    </div>

    <BaseContextMenu ref="menu" :width="'300px'" :top="contextMenuTop" :left="contextMenuLeft">
        <ul>
            <BaseButton
                :label="$t('common.log-out')"
                :level="2"
                :is-submitting="false"
                @mousedown="logOut()"
            />
        </ul>
    </BaseContextMenu>

    <!-- This is to enable this.$ui.confirm() modal functionality. -->
    <AppConfirm />
</template>

<script>
    import dayjs from 'dayjs'
    import BaseContextMenu from '@/components/base/BaseContextMenu'
    import BaseButton from '@/components/base/BaseButton'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import AppConfirm from '@/components/app/AppConfirm'
    import BaseNavigation from '@/components/base/BaseNavigation'
    import IconMenuProgress from '@/assets/vectors/icon-menu-progress.svg'
    import IconMenuProgressFull from '@/assets/vectors/icon-menu-progress-full.svg'
    import IconMenuNotifications from '@/assets/vectors/icon-menu-notifications.svg'
    import IconMenuNotificationsFull from '@/assets/vectors/icon-menu-notifications-full.svg'
    import IconMenuPayments from '@/assets/vectors/icon-menu-payments.svg'
    import IconMenuPaymentsFull from '@/assets/vectors/icon-menu-payments-full.svg'
    import IconMenuClients from '@/assets/vectors/icon-menu-clients.svg'
    import IconMenuClientsFull from '@/assets/vectors/icon-menu-clients-full.svg'
    import LogoFreeByrdz from '@/assets/vectors/logo-freebyrdz.svg'
    import BaseCalendarDateRange from '../base/BaseCalendarDateRange.vue'
    import CalendarDateLabel from '@/components/admin/CalendarDateLabel'
    import ProDashboardDates from '@/components/pro/dashboard/ProDashboardDates'

    export default {
        components: {
            BaseAvatar,
            BaseButton,
            AppConfirm,
            LogoFreeByrdz,
            BaseNavigation,
            BaseContextMenu,
            CalendarDateLabel,
            ProDashboardDates,
            BaseCalendarDateRange,
        },

        emits: ['select-period', 'click'],

        data() {
            return {
                contextMenuLeft: '0px',
                contextMenuTop: '0px',
            }
        },

        computed: {
            navigationItems() {
                return [
                    {
                        label: this.$t('admin.dashboard.navLabel'),
                        route: '/admin/dashboard',
                        icons: {
                            default: IconMenuProgress,
                            selected: IconMenuProgressFull,
                        },
                    },
                    {
                        label: this.$t('admin.issues.navLabel'),
                        route: '/admin/issues',
                        icons: {
                            default: IconMenuNotifications,
                            selected: IconMenuNotificationsFull,
                        },
                    },
                    {
                        label: this.$t('admin.payments.navLabel'),
                        route: '/admin/payments',
                        icons: {
                            default: IconMenuPayments,
                            selected: IconMenuPaymentsFull,
                        },
                    },
                    {
                        label: this.$t('admin.usersAndRoles.navLabel'),
                        route: '/admin/users',
                        icons: {
                            default: IconMenuClients,
                            selected: IconMenuClientsFull,
                        },
                    },
                ].map((item) => ({ ...item, isSelected: this.$route.path === item.route }))
            },

            startDate() {
                return this.$store.getters['admin/getStartDate']
            },

            endDate() {
                return this.$store.getters['admin/getEndDate']
            },
        },

        mounted() {
            this.$emit('select-period', { startDate: this.startDate, endDate: this.endDate })
        },

        methods: {
            logOut() {
                this.$auth.logOut()
                this.$router.push('/admin/login')
            },

            openContextMenu(event) {
                event.stopPropagation()

                this.contextMenuLeft = this.getContextMenuLeft()
                this.contextMenuTop = this.getContextMenuTop()

                if (this.$refs.menu.show) {
                    this.$refs.menu.close()
                } else {
                    this.$refs.menu.open()
                }
            },

            showNavItem(route) {
                return this.$auth.getUser().role !== 'Admin' || route !== '/admin/users'
            },

            openDateRangePicker(event) {
                event.stopPropagation()

                if (this.$refs.dateRangePicker.show) {
                    this.$refs.dateRangePicker.close()
                } else {
                    this.$refs.dateRangePicker.open()
                }
            },

            getContextMenuTop() {
                const el = this.$refs?.profile
                const top =
                    window.pageYOffset + el?.getBoundingClientRect().top + el?.offsetHeight + 20
                return top + 'px'
            },

            getContextMenuLeft() {
                const el = this.$refs?.profile
                let left =
                    window.pageXOffset +
                    el?.getBoundingClientRect().left +
                    this.$refs?.profile?.offsetWidth -
                    300
                if (left + 330 > window.innerWidth) {
                    left = window.innerWidth - 330
                }

                return left + 'px'
            },

            closeContextMenus(event) {
                if (!this.$refs.menu.$el.contains(event.target)) {
                    this.$refs.menu.close()
                }

                if (!this.$refs.dateRangePicker.$el.contains(event.target)) {
                    this.$refs.dateRangePicker.close()
                }

                this.$emit('click', event)
            },

            async cancelPeriod() {
                this.$store.commit('admin/setStartDate', dayjs())
                this.$store.commit('admin/setEndDate', dayjs())

                this.$refs.dateRangePicker.close()
                this.$emit('select-period', {
                    startDate: this.startDate(),
                    endDate: this.endDate(),
                })
            },

            async selectPeriod({ startDate, endDate }) {
                this.$store.commit('admin/setStartDate', startDate)
                this.$store.commit('admin/setEndDate', endDate)

                this.$refs.dateRangePicker.close()
                this.$emit('select-period', { startDate: startDate, endDate: endDate })
            },
        },
    }
</script>
