<template>
    <div class="flex flex-col flex-grow">
        <div class="flex-grow">
            <table class="w-full text-14">
                <tr class="border-b-1 border-gray-4">
                    <th
                        v-for="header in headers"
                        :key="header.label"
                        :class="header.class"
                        class="p-5"
                    >
                        {{ header.label }}
                    </th>
                </tr>

                <slot />
            </table>
        </div>

        <div class="flex flex-row items-center justify-center mt-30">
            <div
                class="mr-20 font-bold cursor-pointer select-none"
                @click="goToPage(currentPage - 1)"
            >
                &lt; Show previous
            </div>
            <div class="flex flex-row space-x-10 h-50 text-14">
                <div v-for="page in pages" :key="page" class="w-50">
                    <BaseButton
                        :level="page === currentPage ? 1 : 2"
                        :label="page.toString()"
                        :height="'h-50'"
                        @click="goToPage(page)"
                    />
                </div>
                <div
                    v-if="maxPage > 3 && currentPage < maxPage - 1"
                    class="flex items-center text-20"
                >
                    ... {{ maxPage }}
                </div>
            </div>
            <div
                class="ml-20 font-bold cursor-pointer select-none"
                @click="goToPage(currentPage + 1)"
            >
                Show next &gt;
            </div>
        </div>
    </div>
</template>

<script>
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            BaseButton,
        },

        props: {
            headers: { type: Array, required: true },
        },

        emits: ['goToPage'],

        data() {
            return {
                currentPage: 1,
                maxPage: 1,
                pages: [1],
            }
        },

        computed: {},

        methods: {
            async goToPage(page) {
                if (page < 1 || page > this.maxPage) {
                    return
                }
                this.currentPage = page

                this.$emit('goToPage', { page: this.currentPage })
            },

            calculatePages() {
                if (this.maxPage <= 1) {
                    this.pages = [1]
                    return
                }

                if (this.maxPage == 2) {
                    this.pages = [1, 2]
                    return
                }

                if (this.currentPage == 1) {
                    this.pages = [1, 2, 3]
                    return
                }

                if (this.currentPage == this.maxPage) {
                    this.pages = [this.maxPage - 2, this.maxPage - 1, this.maxPage]
                    return
                }

                this.pages = [this.currentPage - 1, this.currentPage, this.currentPage + 1]
            },
        },
    }
</script>
