<template>
    <div class="flex-grow flex flex-col justify-center items-center text-center space-y-30">
        <BaseHeading :heading="$t('pro.clients.import.uploading')" :level="4" />
        <img :src="require('@/assets/images/icon-loading.gif').default" class="h-70" />
    </div>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'

    export default {
        components: {
            BaseHeading,
        },
    }
</script>
