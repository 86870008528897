export default {
    methods: {
        isPlaceActive({ slug }) {
            return this.formData.workPlace.includes(slug)
        },

        isPlaceFilled({ slug }) {
            switch (slug) {
                case 'pro':
                    return (
                        this.formData.address?.address &&
                        this.formData.address?.city &&
                        this.formData.address?.state &&
                        this.formData.address?.zipCode
                    )
                case 'guest':
                    return this.formData.areaZipCode && this.formData.areaRadius
                default:
                    return true
            }
        },
    },
}
