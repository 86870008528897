<template>
    <div :class="circleClass">
        <span :class="labelClass">
            <slot>{{ label }}</slot>
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            label: { type: [String, Number], default: null },
            level: { type: Number, default: 2 },
            isSelected: { type: Boolean, default: false },
            isHoverable: { type: Boolean, default: false },
        },

        computed: {
            circleClass() {
                return {
                    'text-16': true,
                    'flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'rounded-full': true,
                    'whitespace-nowrap': true,
                    'leading-none': true,
                    'transition': true,
                    'border-1': true,
                    'hoverable:hover:border-2': this.isHoverable,
                    'hoverable:focus:border-2': this.isHoverable,
                    'hoverable:group-hover:border-2': this.isHoverable,
                    'hoverable:group-focus:border-2': this.isHoverable,
                    'h-25': this.level === 3,
                    'w-25': this.level === 3,
                    'h-30': this.level === 2,
                    'w-30': this.level === 2,
                    'h-40': this.level === 1,
                    'w-40': this.level === 1,
                    'border-current': true,
                    'bg-current': this.isSelected,
                    'text-current': !this.isSelected,
                }
            },

            labelClass() {
                return {
                    'text-white': this.isSelected,
                }
            },
        },
    }
</script>
