<template>
    <LayoutApp back-button="/pro/profile">
        <ProSection :heading="$t('pro.profile.intro.heading')">
            <FormWrapper @submit="save">
                <FieldTextarea
                    :placeholder="$t('pro.profile.intro.placeholder')"
                    :has-bottom-border="false"
                    :has-autofocus="true"
                    :max-length="maxCharacters"
                    :rows="6"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="introduction"
                    @change="change"
                />

                <BaseLimit
                    :text="formData.introduction"
                    :limit="maxCharacters"
                    class="mt-10 ml-auto"
                />

                <FormButtons>
                    <BaseButton
                        :label="$t('common.save')"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                    />
                </FormButtons>
            </FormWrapper>
        </ProSection>
    </LayoutApp>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseLimit from '@/components/base/BaseLimit'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'

    export default {
        components: {
            LayoutApp,
            BaseLimit,
            FieldTextarea,
            BaseButton,
            FormWrapper,
            FormButtons,
        },

        mixins: [MixinProProfileSection],

        computed: {
            maxCharacters() {
                return parseInt(process.env.VUE_APP_PRO_MAXLENGTH)
            },

            isSubmittable() {
                return true
            },
        },

        created() {
            this.initForm(this.$auth.getUser())
        },

        methods: {
            apiCall() {
                return ApiPros.update(this.$auth.getUser().id, 'introduction', this.formData)
            },
        },
    }
</script>
