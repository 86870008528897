<template>
    <ProSection :heading="heading">
        <FormWrapper>
            <FormFields>
                <ProProfileCancellationTime
                    :form-data="formData"
                    :time-path="timePath"
                    @change="change"
                />
            </FormFields>

            <slot name="buttons" :is-submittable="isSubmittable" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import get from 'lodash/get'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import ProProfileCancellationTime from '@/components/pro/profile/cancellation/ProProfileCancellationTime'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormFields,
            ProProfileCancellationTime,
        },

        mixins: [MixinFormStep],

        props: {
            isInitialSetUp: { type: Boolean, required: true },
        },

        computed: {
            placeSlug() {
                return 'pro'
            },

            timePath() {
                return 'cancellationPolicy.prosPlaceCancellationTime'
            },

            heading() {
                return this.$t(`pro.profile.cancellation.time.heading.${this.placeSlug}`)
            },

            isSubmittable() {
                return !!get(this.formData, this.timePath)
            },
        },
    }
</script>
