<template>
    <article class="space-y-30">
        <header class="flex">
            <BaseHeading :heading="license.name" :level="4" />

            <BaseLink :route="editRoute" class="flex space-x-10 block ml-auto">
                <IconModify class="h-20 w-20" />
                <span>{{ $t('common.modify') }}</span>
            </BaseLink>
        </header>

        <BaseText v-if="license.description" :content="license.description" />
    </article>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'
    import IconModify from '@/assets/vectors/icon-modify.svg'

    export default {
        components: {
            BaseHeading,
            BaseText,
            BaseLink,
            IconModify,
        },

        props: {
            license: { type: Object, required: true },
        },

        computed: {
            editRoute() {
                return `/pro/profile/licenses/${this.license.id}/edit`
            },
        },
    }
</script>
