export default {
    'next': 'Next',
    'continue': 'Continue',
    'confirm': 'Confirm',
    'submit': 'Submit',
    'cancel': 'Cancel',
    'cancelled': 'Cancelled',
    'close': 'Close',
    'extras': 'Other',
    'discount': 'Discount',
    'total': 'Total',
    'skip': 'Skip',
    'done': 'Done',
    'apply': 'Apply',
    'delete': 'Delete',
    'specify': 'Specify',
    'sign-up': 'Sign up',
    'sign-in': 'Sign in',
    'sign-out': 'Sign out',
    'choose-one': 'Choose at least one.',
    'log-out': 'Log out',
    'n-minutes': '{n} minute | {n} minutes',
    'n-hours': '{n} hour | {n} hours',
    'n-miles': '{n} mile | {n} miles',
    'n-characters-left': '{n} character left | {n} characters left',
    'tip': 'Gratuity',
    'go-back': 'Go back',
    'go-to-profile': 'Go to profile',
    'from': 'From',
    'to': 'To',
    'map': 'Map',
    'miles': 'Miles',
    'grid': 'List',
    'modify': 'Modify',
    'save': 'Save',
    'or': 'or',
    'yes': 'Yes',
    'no': 'No',
    'datetime-format': '{date} at {time}',
    'time': 'Time',
    'week': 'Week',
    'day': 'Day',
    'note': 'Note',
    'select-all': 'Select all',
    'deselect-all': 'Deselect all',
    'load-more': 'Load more',
    'fees': {
        'no-show': 'No-show fee',
        'cancellation': 'Cancellation fee',
        'service': 'FreeByrdz booking fee',
    },
    'menu': {
        'about': 'About FreeByrdz',
        'are-you-pro': 'Are you a pro?',
        'appointments': 'My appointments',
        'marketing-tools': 'Marketing tools',
        'masterclass': 'Masterclass',
        'my-community': 'My Community',
        'ecommerce': 'E-commerce',
        'support': 'Support',
        'coming-soon': 'Coming soon',
    },
    'fields': {
        'first-name': 'First Name',
        'last-name': 'Last Name',
        'brand-name': 'Brand Name',
        'email': 'Email',
        'phone': 'Phone Number',
        'password': 'Password',
        'address': 'Address',
        'city': 'City',
        'state': 'State',
        'zip-code': 'Zip Code',
    },
    'places': {
        pro: 'My place',
        guest: 'Clients’ place',
    },
    'appointment-types': {
        future: 'Future',
        previous: 'Previous',
        cancelled: 'Cancelled',
    },
    'google': {
        errors: {
            'no-zip-code':
                'We couldn’t find a zip code for this address. Please enter a more specific address.',
            'no-results': 'We couldn’t find this address. Please try again.',
            'other':
                'An unknown error occurred when getting results from Google. Please try again.',
        },
    },
    'errors': {
        'not-found': {
            heading: 'Ooops!',
            subheading: `
                Something escaped?
                A 404 error, as they call it.
            `,
        },
        'no-access': {
            heading: 'No access',
            subheading: `
                Looks like you don’t have
                access to this page.
            `,
        },
        'internal': {
            heading: 'Something went wrong',
            subheading: 'We ran into an unexpected error.',
        },
    },
}
