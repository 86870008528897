<template>
    <section>
        <header class="flex">
            <LandingHeading :level="5" class="cursor-pointer" @click="isOpen = !isOpen">
                {{ heading }}
            </LandingHeading>
        </header>

        <div :class="resizableWrapperClass" :style="resizableWrapperStyle">
            <div ref="resizable" :class="resizableContentClass">
                <LandingText ref="resizable" :content="content" />
            </div>
        </div>
    </section>
</template>

<script>
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingText from '@/components/landing/LandingText'

    export default {
        components: {
            LandingHeading,
            LandingText,
        },

        props: {
            heading: { type: String, required: true },
            content: { type: String, required: true },
        },

        data() {
            return {
                isOpen: false,
                resizableHeight: 0,
            }
        },

        computed: {
            resizableWrapperStyle() {
                return {
                    height: `${this.resizableHeight}px`,
                }
            },

            resizableWrapperClass() {
                return {
                    'transition-all': true,
                    'duration-500': true,
                    'ease-in-out': true,
                    'overflow-hidden': true,
                    'mt-10': this.isOpen,
                    'md:mt-15': this.isOpen,
                    'lg:mt-20': this.isOpen,
                }
            },

            resizableContentClass() {
                return {
                    'transition-all': true,
                    'ease-in-out': true,
                    'opacity-0': !this.isOpen,
                    'transition-300': !this.isOpen,
                    'opacity-100': this.isOpen,
                    'delay-300': this.isOpen,
                    'transition-500': this.isOpen,
                }
            },
        },

        watch: {
            isOpen() {
                this.calculateResizableHeight()
            },
        },

        mounted() {
            window.addEventListener('resize', this.calculateResizableHeight)
            window.addEventListener('orientationchange', this.calculateResizableHeight)
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.calculateResizableHeight)
            window.removeEventListener('orientationchange', this.calculateResizableHeight)
        },
        methods: {
            calculateResizableHeight() {
                this.resizableHeight = this.isOpen ? this.$refs.resizable.clientHeight : 0
            },
        },
    }
</script>
