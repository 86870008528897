<template>
    <div v-click-outside="close" :class="suggestClass">
        <div :class="wrapperClass">
            <slot name="prefix" />
            <input
                ref="input"
                :placeholder="placeholder"
                :autofocus="hasAutofocus"
                :disabled="isDisabled"
                :required="isRequired"
                :readonly="isReadonly"
                :class="inputClass"
                @focus="focus"
                @blur="blurAndClear"
                @keydown.up.prevent="handleUpArrow"
                @keydown.down.prevent="handleDownArrow"
                @keydown.enter.prevent="handleEnter"
                @input="input"
            />
            <slot name="suffix" />
        </div>

        <div ref="scrollable" :class="suggestOptionsClass" @mouseleave="hoverOption(-1)">
            <button
                v-for="(option, index) in options"
                :key="index"
                :ref="(el) => el && (optionRefs[index] = el)"
                :class="optionClass(index)"
                type="button"
                tabindex="-1"
                @mouseover="hoverOption(index)"
                @click="select(option)"
            >
                <slot name="option" :option="option" :is-highlighted="highlightedOption === index">
                    {{ option.label }}
                </slot>
            </button>
        </div>
    </div>
</template>

<script>
    import { isNodeDescendant } from '@/helpers'
    import MixinFormInput from '@/mixins/MixinFormInput'
    import MixinFormOptionable from '@/mixins/MixinFormOptionable'
    import MixinIndicateScroll from '@/mixins/MixinIndicateScroll'
    import InputSelect from '@/components/form-inputs/InputSelect'

    export default {
        extends: InputSelect,

        mixins: [MixinFormInput, MixinFormOptionable, MixinIndicateScroll],

        props: {
            value: { type: [Object], default: null },
            placeholder: { type: String, default: null },
            isSuggested: { type: Boolean, default: false },
        },

        emits: ['input', 'select'],

        computed: {
            suggestClass() {
                return {
                    'relative': true,
                    'min-h-50': true,
                    'h-full': true,
                }
            },

            wrapperClass() {
                return {
                    'flex': true,
                    'items-center': true,
                    'h-full': true,
                    'w-full': true,
                    'border-b-1': true,
                    'transition': true,
                    'opacity-30': this.isDisabled,
                    'cursor-not-allowed': this.isDisabled,
                    'text-black': !this.isInvalid && !this.isInverted,
                    'border-black': !this.isInvalid && !this.isReadonly && !this.isInverted,
                    'focus-within:text-purple': !this.isInvalid && !this.isInverted,
                    'focus-within:border-purple': !this.isInvalid && !this.isInverted,
                    'text-red': this.isInvalid,
                    'border-transparent': this.isInvalid || this.isReadonly || this.isInverted,
                }
            },

            inputClass() {
                return {
                    'block': true,
                    'w-full': true,
                    'h-50': true,
                    'leading-none': true,
                    'tracking-wide': true,
                    'appearance-none': true,
                    'rounded-none': true,
                    'text-18': true,
                    'text-inherit': true,
                    'bg-transparent': true,
                    'focus:font-bold': true,
                }
            },

            suggestOptionsClass() {
                return {
                    ...this.optionsClass,
                    'mt-1': true,
                }
            },
        },

        watch: {
            value(value) {
                !this.isFocused && this.setInputText(value?.label || '')
            },

            options() {
                this.indicateScrollability()
            },
        },

        mounted() {
            this.setInputText(this.value?.label || '')
        },

        methods: {
            setInputText(text) {
                this.$refs.input.value = text
            },

            input(event) {
                this.open()
                this.$emit('input', event.target.value)
            },

            select(option) {
                this.change(option)
                this.$emit('select', option)
                this.setInputText(null)
                this.close()
            },

            blurAndClear(event) {
                this.blur(event)

                if (
                    !event.relatedTarget ||
                    (event.relatedTarget && !isNodeDescendant(event.relatedTarget, this.$el))
                ) {
                    !this.value && this.setInputText(null)
                }
            },

            open() {
                !this.isOpen && (this.highlightedOption = null)
                this.isOpen = true
            },

            handleUpArrow() {
                this.hasOptions && this.switchHighlightedOption(-1)
            },

            handleDownArrow() {
                this.hasOptions && this.switchHighlightedOption(1)
            },

            handleEnter() {
                this.isOpen &&
                    this.hasOptions &&
                    this.highlightedOption !== null &&
                    this.select(this.options[this.highlightedOption])
            },
        },
    }
</script>
