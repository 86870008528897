<template>
    <LayoutPro :back-button="backButton">
        <div class="space-y-20 pb-20">
            <header v-if="isCancelled || isNoShow" class="space-y-20 flex flex-col items-center">
                <BaseHeading :heading="noShowOrCancelledHeading" :level="3" />
                <IconMenuCalendarCancelled class="h-50 text-gray-3" />
            </header>

            <ProAppointmentsShowHeader
                :appointment="appointment"
                :has-actions="!isPaid && !isCancelled && !isNoShow"
                :is-compact="isCancelled || isNoShow"
                @open-actions="isActionsPanelOpen = true"
            />

            <ProAppointmentsShowDatetimes :appointment="appointment" />
            <ProAppointmentsShowAddress :appointment="appointment" />

            <BaseReceipt v-if="isCancelled || isNoShow" :positions="noShowOrCancellationPrices">
                <template #position="{ position }">
                    <span v-if="position.crossedPrice" class="line-through">
                        $ {{ formatAmount(position.crossedPrice) }}
                    </span>
                </template>
            </BaseReceipt>

            <template v-else>
                <BaseReceipt :positions="reservedOrConcludedPrices" />
                <ProAppointmentsShowCommunication :appointment="appointment" />
            </template>
        </div>
    </LayoutPro>

    <ProAppointmentsShowActions
        :is-open="isActionsPanelOpen"
        :appointment="appointment"
        @close="isActionsPanelOpen = false"
    />
</template>

<script>
    import ApiProAppointments from '@/apis/ApiProAppointments'
    import { formatAmount } from '@/helpers'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import LayoutPro from '@/components/layouts/LayoutPro'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseReceipt from '@/components/base/BaseReceipt'
    import ProAppointmentsShowHeader from '@/components/pro/appointments/show/ProAppointmentsShowHeader'
    import ProAppointmentsShowDatetimes from '@/components/pro/appointments/show/ProAppointmentsShowDatetimes'
    import ProAppointmentsShowAddress from '@/components/pro/appointments/show/ProAppointmentsShowAddress'
    import ProAppointmentsShowCommunication from '@/components/pro/appointments/show/ProAppointmentsShowCommunication'
    import ProAppointmentsShowActions from '@/components/pro/appointments/show/ProAppointmentsShowActions'
    import IconMenuCalendarCancelled from '@/assets/vectors/icon-menu-calendar-cancelled.svg'

    export default {
        components: {
            LayoutPro,
            BaseHeading,
            BaseReceipt,
            ProAppointmentsShowHeader,
            ProAppointmentsShowDatetimes,
            ProAppointmentsShowAddress,
            ProAppointmentsShowCommunication,
            ProAppointmentsShowActions,
            IconMenuCalendarCancelled,
        },

        mixins: [MixinAppointment],

        async loadData({ $auth, $route }) {
            const appointment = await ApiProAppointments.getOne($auth.getUser(), $route.params.id)

            return { appointment }
        },

        data() {
            return {
                isActionsPanelOpen: false,
            }
        },

        computed: {
            backButton() {
                if (this.isCancelled) {
                    return '/pro/appointments/cancelled'
                }

                return this.isFuture ? '/pro/appointments/future' : '/pro/appointments/previous'
            },

            reservedOrConcludedPrices() {
                return [
                    ...this.appointmentPrices(),
                    ...(this.isConcluded
                        ? [
                              {
                                  name: this.$t('common.extras'),
                                  price: this.appointment.extras,
                              },
                              {
                                  name: this.$t('common.discount'),
                                  price: -this.appointment.discountAmount,
                              },
                              {
                                  name: this.$t('common.tip'),
                                  price: this.appointment.tip,
                              },
                          ]
                        : []),
                    {
                        name: this.$t('common.fees.service'),
                        price: this.$store.getters['dictionaries/getServiceFee'],
                    },
                ]
            },

            noShowOrCancellationPrices() {
                return [
                    ...this.appointmentPrices().map((data) => ({
                        ...data,
                        crossedPrice: data.price,
                        price: 0,
                    })),
                    ...(this.isGuestNoShow ? this.noShowPrices(true) : []),
                    ...(this.isCancelled && this.isCancellationFeeActive
                        ? this.cancellationPrices(true)
                        : []),
                ]
            },

            noShowOrCancelledHeading() {
                return this.isNoShow
                    ? this.$t('pro.appointments.no-show')
                    : this.$t('pro.appointments.cancelled')
            },
        },

        mounted() {
            this.showFinishModal()
        },

        methods: {
            formatAmount,

            showFinishModal() {
                if (this.isChargable) {
                    this.$ui
                        .confirm({
                            message: this.$t('pro.appointments.finish.question.how-went', {
                                name: this.appointment.guest.firstName,
                            }),
                            confirm: this.$t('pro.appointments.finish.question.all-good'),
                            cancel: this.inProsPlace
                                ? this.$t('pro.appointments.finish.question.no-show.pro')
                                : this.$t('pro.appointments.finish.question.no-show.guest'),
                        })
                        .then((isConfirmed) =>
                            this.$router.push(
                                isConfirmed
                                    ? `/pro/appointments/${this.appointment.id}/charge`
                                    : `/pro/appointments/${this.appointment.id}/no-show`,
                            ),
                        )
                }
            },
        },
    }
</script>
