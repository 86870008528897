<template>
    <div class="overflow-x-auto no-scrollbar w-full">
        <div class="flex space-x-10">
            <ProWork
                v-for="(item, index) in items"
                :key="index"
                :picture="item.picture"
                :title="item.title"
                :level="2"
                :is-picture-editable="isEditable"
                :is-picture-deletable="isDeletable"
                :is-title-editable="isEditable"
                :class="itemClass"
                @edit-picture="$emit('edit', { index, item })"
                @delete-picture="$emit('delete', { index, item })"
                @edit-title="$emit('edit', { index, item })"
            />
        </div>
    </div>
</template>

<script>
    import ProWork from '@/components/pro/ProWork'

    export default {
        components: {
            ProWork,
        },

        props: {
            items: { type: Array, required: true },
            isEditable: { type: Boolean, default: false },
            isDeletable: { type: Boolean, default: false },
        },

        emits: ['edit', 'delete'],

        computed: {
            itemClass() {
                return {
                    'w-full': this.items.length === 1,
                    'w-9/12': this.items.length > 1,
                    'flex-shrink-0': true,
                }
            },
        },
    }
</script>
