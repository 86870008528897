<template>
    <ProSection>
        <header class="space-y-20 flex flex-col items-center text-center">
            <IconSuccess class="h-35 w-35 text-green animate-pop" />

            <BaseHeading :heading="heading" :level="3" />
        </header>

        <ProProfileExperiencesIndexSubCategories
            :sub-categories="subCategories"
            :experiences="[]"
            class="flex-grow"
        />

        <FormButtons>
            <BaseButton route="/pro/profile/experiences" :label="$t('common.go-to-profile')" />
        </FormButtons>
    </ProSection>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import ProSection from '@/components/pro/ProSection'
    import ProProfileExperiencesIndexSubCategories from '@/components/pro/profile/experiences/index/ProProfileExperiencesIndexSubCategories'
    import IconSuccess from '@/assets/vectors/icon-success.svg'

    export default {
        components: {
            FormButtons,
            BaseHeading,
            BaseButton,
            ProSection,
            ProProfileExperiencesIndexSubCategories,
            IconSuccess,
        },

        mixins: [MixinFormStep],

        computed: {
            category() {
                return this.$store.getters['dictionaries/getCategories'].find(
                    (category) => category.id === this.formData.category,
                )
            },

            subCategories() {
                return this.category.subCategories.filter((subCategory) =>
                    this.formData.subCategoryIds.includes(subCategory.id),
                )
            },

            heading() {
                return this.$t('pro.profile.experiences.category.category-added', {
                    name: this.category.name,
                })
            },
        },
    }
</script>
