import { normalizeFormData } from '@/axios/helpers'

export const transformParams = (params) => {
    return {
        ...params,
        type: {
            future: 'Future',
            previous: 'Previous',
            cancelled: 'Cancelled',
        }[params.type],
        page: params.page || 1,
    }
}

export const transformWorkPlace = (workPlace) => {
    return {
        pro: 'ProsPlace',
        guest: 'GuestsPlace',
    }[workPlace]
}

export default (data) => {
    data = normalizeFormData(data || {})

    return {
        ...data,
        pro: undefined,
        experiences: undefined,
        addOns: undefined,
        experienceIds: data.experiences && data.experiences.map(({ id }) => id),
        addOnIds: data.addOns && data.addOns.map(({ id }) => id),
        stylistId: data.pro?.id,
        workPlace: data.workPlace && transformWorkPlace(data.workPlace),
        message: data.message || '',
    }
}
