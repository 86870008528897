<template>
    <LayoutOverlay :back-button="true" @back="back">
        <ProSection :heading="$t('pro.profile.places.guest.radius.heading')">
            <FieldRange
                :label="$t('pro.onboarding.area.within-radius')"
                :step="1"
                :range-from="0"
                :range-to="100"
                :label-from="$t('pro.onboarding.area.x-miles', { number: 0 })"
                :label-to="$t('pro.onboarding.area.x-miles', { number: 100 })"
                :form-data="formData"
                :form-errors="formErrors"
                value-path="areaRadius"
                @change="change"
            />

            <BaseMap ref="map" :is-draggable="false" class="h-300" />

            <FormWrapper>
                <slot name="buttons" :is-submittable="isSubmittable">
                    <FormButtons>
                        <BaseButton
                            :label="submitLabel"
                            :is-disabled="!isSubmittable"
                            :is-processing="isSubmitting"
                            @click="save"
                        />
                    </FormButtons>
                </slot>
            </FormWrapper>
        </ProSection>
    </LayoutOverlay>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import MixinProProfileArea from '@/mixins/MixinProProfileArea'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldRange from '@/components/form-fields/FieldRange'
    import BaseMap from '@/components/base/BaseMap'

    export default {
        components: {
            BaseButton,
            FormWrapper,
            FormButtons,
            FieldRange,
            BaseMap,
        },

        mixins: [MixinProProfileSection, MixinProProfileArea],

        computed: {
            isSubmittable() {
                return true
            },

            isRedirectingHome() {
                return false
            },

            submitLabel() {
                return this.$t('common.save')
            },
        },

        methods: {
            apiCall() {
                return ApiPros.update(this.$auth.getUser().id, 'guestsPlaceData', this.formData)
            },
        },
    }
</script>
