<template>
    <BaseText :content="$t('pro.profile.cancellation.time.question')" />

    <FieldRange
        :range-from="0"
        :range-to="24"
        :label-from="$tc('common.n-hours', 1, { n: 1 })"
        :label-to="$tc('common.n-hours', 24, { n: 24 })"
        :form-data="formData"
        :value-path="timePath"
        @change="change"
    />

    <BaseNote v-if="hasNote" :level="1" :has-heading="true">
        <i18n-t tag="p" keypath="pro.profile.cancellation.time.note.1">
            <template #before>
                <b>{{ hoursBefore }}</b>
            </template>
        </i18n-t>

        <p>{{ $t('pro.profile.cancellation.time.note.2') }}</p>
    </BaseNote>
</template>

<script>
    import get from 'lodash/get'
    import BaseText from '@/components/base/BaseText'
    import BaseNote from '@/components/base/BaseNote'
    import FieldRange from '@/components/form-fields/FieldRange'

    export default {
        components: {
            BaseText,
            BaseNote,
            FieldRange,
        },

        props: {
            formData: { type: Object, required: true },
            timePath: { type: String, required: true },
            hasNote: { type: Boolean, default: true },
        },

        emits: ['change'],

        computed: {
            hoursBefore() {
                const n = get(this.formData, this.timePath)
                return this.$tc('pro.profile.cancellation.time.hours-before', n, { n })
            },
        },

        methods: {
            change({ path, value }) {
                this.$emit('change', { path, value })
            },
        },
    }
</script>
