<template>
    <FormField v-bind="suggestFieldProps">
        <InputSuggest
            v-bind="suggestInputProps"
            @focus="focus"
            @blur="blur"
            @input="saveQueryAndGetSuggestions"
            @change="change"
            @select="selectSuggestion"
        >
            <template #prefix>
                <slot name="prefix" />
            </template>

            <template #option="{ option, isHighlighted }">
                <slot name="option" :option="option" :is-highlighted="isHighlighted" />
            </template>

            <template #suffix>
                <slot name="suffix" />
            </template>
        </InputSuggest>
    </FormField>
</template>

<script>
    import debounce from 'lodash/debounce'
    import MixinFormField from '@/mixins/MixinFormField'
    import FormField from '@/components/form-elements/FormField'
    import InputSuggest from '@/components/form-inputs/InputSuggest'

    export default {
        components: {
            FormField,
            InputSuggest,
        },

        mixins: [MixinFormField],

        props: {
            placeholder: { type: [String, Number], default: null },
            suggestionsCall: { type: Function, required: true },
            minQueryLength: { type: Number, default: 2 },
        },

        emits: ['select'],

        data() {
            return {
                internalQuery: null,
                suggestions: [],
            }
        },

        computed: {
            suggestFieldProps() {
                return {
                    ...this.fieldProps,
                    value: this.internalQuery,
                    isLabelFixed: this.isLabelFixed,
                }
            },

            suggestInputProps() {
                return {
                    ...this.inputProps,
                    placeholder: this.placeholder,
                    options: this.suggestions,
                }
            },
        },

        watch: {
            value(value) {
                this.internalQuery = value?.label
            },
        },

        mounted() {
            this.internalQuery = this.value?.label
        },

        methods: {
            selectSuggestion(option) {
                this.internalQuery = null
                this.$emit('select', option)
            },

            saveQueryAndGetSuggestions(query) {
                this.internalQuery = query
                this.getSuggestions(query)
            },

            getSuggestions: debounce(function(query) {
                if (!query || query.length < this.minQueryLength) {
                    this.suggestions = []
                    return
                }

                this.suggestionsCall(query).then((suggestions) => {
                    this.suggestions = suggestions
                })
            }, 250),
        },
    }
</script>
