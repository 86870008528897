<template>
    <template v-if="isCancellationFeeActive">
        <BaseText :content="$t('guest.appointments.cancel.note')" :level="2" :is-light="true" />
        <BaseReceipt :positions="cancellationPrices(true)" />
    </template>
</template>

<script>
    import MixinAppointment from '@/mixins/MixinAppointment'
    import BaseText from '@/components/base/BaseText'
    import BaseReceipt from '@/components/base/BaseReceipt'

    export default {
        components: {
            BaseText,
            BaseReceipt,
        },

        mixins: [MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
        },
    }
</script>
