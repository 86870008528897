<template>
    <BaseOverlay :is-open="isOpen" class="flex items-center justify-center" @close="closeMessage">
        <TransitionScale>
            <BaseMessage v-if="isOpen" :message="message" :type="type">
                <template #cta>
                    <BaseLink :label="close || $t('common.close')" @click="closeMessage" />
                </template>
            </BaseMessage>
        </TransitionScale>
    </BaseOverlay>
</template>

<script>
    import { mapState } from 'vuex'
    import TransitionScale from '@/components/transitions/TransitionScale'
    import BaseOverlay from '@/components/base/BaseOverlay'
    import BaseMessage from '@/components/base/BaseMessage'
    import BaseLink from '@/components/base/BaseLink'

    export default {
        components: {
            TransitionScale,
            BaseOverlay,
            BaseMessage,
            BaseLink,
        },

        computed: mapState('ui/message', ['isOpen', 'type', 'message', 'close']),

        methods: {
            closeMessage() {
                this.$store.dispatch('ui/message/close')
            },
        },
    }
</script>
