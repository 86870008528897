<template>
    <header class="flex sticky top-0 -mx-20 h-70 bg-white px-20 z-layout">
        <AppBack v-if="backButton === true" @click="$emit('back')" />
        <AppBack v-else-if="backButton !== false" :route="backButton" />

        <RouterLink
            :to="logoRoute"
            class="p-10 mx-auto absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
            <LogoFreeByrdz class="w-35" />
        </RouterLink>

        <button class="px-20 -mx-20 ml-auto hoverable:hover:text-purple" @click="isMenuOpen = true">
            <IconMenu class="w-30 h-30" />
        </button>
    </header>

    <AppMenu :is-open="isMenuOpen" @close="isMenuOpen = false" />
</template>

<script>
    import AppBack from '@/components/app/AppBack'
    import AppMenu from '@/components/app/AppMenu'
    import IconMenu from '@/assets/vectors/icon-menu.svg'
    import LogoFreeByrdz from '@/assets/vectors/logo-freebyrdz.svg'

    export default {
        components: {
            AppBack,
            AppMenu,
            IconMenu,
            LogoFreeByrdz,
        },

        props: {
            backButton: { type: [Boolean, String, Object], default: false },
        },

        emits: ['back'],

        data() {
            return {
                isMenuOpen: false,
            }
        },

        computed: {
            logoRoute() {
                return this.$auth.isPro() ? '/auth/detect' : '/'
            },
        },
    }
</script>
