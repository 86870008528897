<template>
    <GuestBookStep
        :heading="$t('guest.book.when.heading')"
        :subheading="$t('guest.book.when.subheading')"
        :has-progress="hasProgress"
    >
        <template #default>
            <BaseCalendarSwitch
                :year="year"
                :month="month"
                :disabled-dates="disabledDates"
                @change-month="changeMonth"
                @click-date="saveAndGoToNextStep"
            />
        </template>
    </GuestBookStep>
</template>

<script>
    import dayjs from 'dayjs'
    import ApiProAvailabilities from '@/apis/ApiProAvailabilities'
    import MixinBookStep from '@/mixins/MixinBookStep'
    import BaseCalendarSwitch from '@/components/base/BaseCalendarSwitch'

    export default {
        components: {
            BaseCalendarSwitch,
        },

        mixins: [MixinBookStep],

        data() {
            return {
                disabledDates: [],
                year: dayjs().year(),
                month: dayjs().month(),
            }
        },

        mounted() {
            this.loadMonthAvailability()
        },

        methods: {
            changeMonth({ year, month }) {
                this.year = year
                this.month = month
                this.loadMonthAvailability()
            },

            saveAndGoToNextStep(date) {
                this.change({ path: 'search.startDate', value: date })
                this.change({ path: 'search.endDate', value: date })
                this.goToNextStep()
            },

            disableWholeMonth() {
                this.disabledDates = [
                    {
                        from: dayjs()
                            .year(this.year)
                            .month(this.month)
                            .startOf('month'),
                        to: dayjs()
                            .year(this.year)
                            .month(this.month)
                            .endOf('month'),
                    },
                ]
            },

            disableDaysBasedOnAvailability(days) {
                this.disabledDates = days
                    .filter(
                        ({ date, hasAvailableTimeSlot }) =>
                            !hasAvailableTimeSlot || dayjs(date).isBefore(dayjs(), 'day'),
                    )
                    .map(({ date }) => dayjs(date))
            },

            async loadMonthAvailability() {
                this.disableWholeMonth()

                const availabilityPerMonth = await ApiProAvailabilities.getMonth(
                    this.formData.appointment.pro.id,
                    {
                        year: this.year,
                        month: this.month,
                        experienceIds: this.appointment.experiences.map(({ id }) => id),
                        addOnIds: this.appointment.addOns.map(({ id }) => id),
                        address: this.appointment.address,
                        workPlace: this.appointment.workPlace,
                    },
                )

                this.disableDaysBasedOnAvailability(availabilityPerMonth)
            },
        },
    }
</script>
