<template>
    <div>
        <ProProfileLink
            v-for="(link, index) in links"
            :key="index"
            :is-truncated="true"
            v-bind="link"
        />
    </div>
</template>

<script>
    import ProProfileLink from '@/components/pro/profile/ProProfileLink'
    import ProProfilePlacesGuestArea from '@/components/pro/profile/places/guest/ProProfilePlacesGuestArea'
    import ProProfilePlacesGuestRadius from '@/components/pro/profile/places/guest/ProProfilePlacesGuestRadius'
    import ProProfilePlacesGuestPadding from '@/components/pro/profile/places/guest/ProProfilePlacesGuestPadding'
    import ProProfilePlacesGuestQuestions from '@/components/pro/profile/places/guest/ProProfilePlacesGuestQuestions'

    export default {
        components: {
            ProProfileLink,
        },

        props: {
            formData: { type: Object, required: true },
        },

        computed: {
            links() {
                return [
                    {
                        heading: this.$t('pro.profile.places.guest.links.area.heading'),
                        content: this.formData.areaZipCode,
                        overlayComponent: ProProfilePlacesGuestArea,
                        overlayProps: { initialFormData: this.formData },
                    },
                    {
                        heading: this.$t('pro.profile.places.guest.links.radius.heading'),
                        content: this.$tc('common.n-miles', this.formData.areaRadius, {
                            n: this.formData.areaRadius,
                        }),
                        overlayComponent: ProProfilePlacesGuestRadius,
                        overlayProps: { initialFormData: this.formData },
                    },
                    {
                        heading: this.$t('pro.profile.places.guest.links.padding.heading'),
                        content: this.$tc('common.n-minutes', this.formData.preparationTime, {
                            n: this.formData.preparationTime,
                        }),
                        overlayComponent: ProProfilePlacesGuestPadding,
                        overlayProps: { initialFormData: this.formData },
                    },
                    {
                        heading: this.$t('pro.profile.places.guest.links.questions.heading'),
                        content: this.formData.guestsPlaceLocationQuestion,
                        description: this.formData.guestsPlaceLocationQuestion
                            ? null
                            : this.$t('pro.profile.places.guest.links.questions.heading'),
                        overlayComponent: ProProfilePlacesGuestQuestions,
                        overlayProps: { initialFormData: this.formData },
                        isSuggested: !this.formData.guestsPlaceLocationQuestion,
                    },
                ]
            },
        },
    }
</script>
