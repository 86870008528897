<template>
    <LayoutPro back-button="/auth/detect">
        <div class="flex-grow flex flex-col space-y-20">
            <div class="relative">
                <BaseHeaderSection
                    :heading="$t('pro.clients.heading')"
                    :subheading="$t('pro.clients.index.subheading')"
                />

                <ProClientsIndexActions class="absolute top-0 right-0" />
            </div>

            <ProClientsIndexSearch v-if="hasClients || wasSearching" @search="searchClients" />

            <ProClientsIndexList v-if="hasClients" :clients="clients" />

            <BaseBlankSlate
                v-else-if="wasSearching"
                :message="$t('pro.clients.index.search.no-results.message', { query: searchQuery })"
                :cta-label="$t('pro.clients.index.search.no-results.cta')"
                cta-route="/pro/clients/add"
            />

            <ProClientsIndexBlank v-else />
        </div>
    </LayoutPro>
</template>

<script>
    import ApiProClients from '@/apis/ApiProClients'
    import LayoutPro from '@/components/layouts/LayoutPro'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseBlankSlate from '@/components/base/BaseBlankSlate'
    import ProClientsIndexActions from '@/components/pro/clients/index/ProClientsIndexActions'
    import ProClientsIndexSearch from '@/components/pro/clients/index/ProClientsIndexSearch'
    import ProClientsIndexList from '@/components/pro/clients/index/ProClientsIndexList'
    import ProClientsIndexBlank from '@/components/pro/clients/index/ProClientsIndexBlank'

    export default {
        components: {
            LayoutPro,
            BaseHeaderSection,
            BaseBlankSlate,
            ProClientsIndexActions,
            ProClientsIndexSearch,
            ProClientsIndexList,
            ProClientsIndexBlank,
        },

        data() {
            return {
                wasSearching: false,
                searchQuery: null,
            }
        },

        async loadData({ $auth }) {
            const clients = await ApiProClients.get($auth.getUser().id, { page: 1 })

            return { clients }
        },

        computed: {
            hasClients() {
                return this.clients.length > 0
            },
        },

        methods: {
            searchClients(query) {
                ApiProClients.get(this.$auth.getUser().id, { name: query, page: 1 }).then(
                    (clients) => {
                        this.wasSearching = true
                        this.searchQuery = query
                        this.clients = clients
                    },
                )
            },
        },
    }
</script>
