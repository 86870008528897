<template>
    <div :class="checkClass">
        <div :class="iconClass">
            <slot />
        </div>
        <slot v-if="!isSelected" name="unselected" />
    </div>
</template>

<script>
    export default {
        props: {
            isSelected: { type: Boolean, default: false },
            isRounded: { type: Boolean, default: false },
        },

        computed: {
            checkClass() {
                return {
                    'flex': true,
                    'text-white': true,
                    'items-center': true,
                    'justify-center': true,
                    'border-2': true,
                    'hoverable:group-hover:border-3': true,
                    'hoverable:group-focus:border-3': true,
                    'border-black': true,
                    'h-28': true,
                    'w-28': true,
                    'transition-colors': true,
                    'rounded-full': this.isRounded,
                    'border-purple': this.isSelected,
                    'bg-purple': this.isSelected,
                }
            },

            iconClass() {
                return {
                    'transition': true,
                    'transform': true,
                    'opacity-0': !this.isSelected,
                    'scale-90': !this.isSelected,
                    'opacity-100': this.isSelected,
                    'scale-100': this.isSelected,
                }
            },
        },
    }
</script>
