<template>
    <header class="relative flex justify-center">
        <div class="bg-gray-5 absolute left-0 right-0 h-1 bottom-1/2" />
        <div class="bg-white px-10 relative">
            <slot>
                <BaseHeading :heading="heading" :level="5" />
            </slot>
        </div>
    </header>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'

    export default {
        components: {
            BaseHeading,
        },

        props: {
            heading: { type: String, default: null },
        },
    }
</script>
