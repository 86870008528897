<template>
    <div class="flex flex-col">
        <BaseHeading :heading="$t('admin.dashboard.newPros.stripe.heading')" :level="4" />

        <BaseText :content="status" class="text-gray-2 mt-30" />
    </div>
</template>
<script>
    import BaseText from '@/components/base/BaseText'
    import BaseHeading from '@/components/base/BaseHeading'

    export default {
        components: {
            BaseText,
            BaseHeading,
        },

        props: {
            stripeAccountStatus: { type: String, default: null },
        },

        computed: {
            status() {
                if (!this.stripeAccountStatus) {
                    return this.$t('admin.dashboard.newPros.stripe.initial')
                }

                switch (this.stripeAccountStatus) {
                    case 'AccountCreated':
                        return this.$t('admin.dashboard.newPros.stripe.accountCreated')
                    case 'DoneOnBoarding':
                        return this.$t('admin.dashboard.newPros.stripe.doneOnboarding')
                    case 'PendingVerification':
                        return this.$t('admin.dashboard.newPros.stripe.pendingVerification')
                    case 'MissingInformation':
                        return this.$t('admin.dashboard.newPros.stripe.missingInformation')
                    case 'Enabled':
                        return this.$t('admin.dashboard.newPros.stripe.enabled')
                    default:
                        return this.$t('admin.dashboard.newPros.stripe.initial')
                }
            },
        },
    }
</script>
