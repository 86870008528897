<template>
    <div class="flex flex-col items-center">
        <BaseHeaderDivider
            :heading="$t('admin.dashboard.overallSatisfaction.label')"
            class="w-11/12 text-16 mx-auto"
        />

        <div class="flex flex-col items-center p-10">
            <div class="flex flex-row items-center">
                <p class="text-35 font-bold text-black">{{ averageRating }}</p>
                <IconStarFull class="w-25 h-25 text-black ml-5" />
            </div>

            <p class="text-14">
                {{ totalReviews + ' ' + $t('admin.dashboard.reviews') }}
            </p>
        </div>

        <BaseHorizontalBarChart class="w-300" :items="ratings" />
    </div>
</template>

<script>
    import IconStarFull from '@/assets/vectors/icon-star-full.svg'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseHorizontalBarChart from '@/components/base/BaseHorizontalBarChart'

    export default {
        components: {
            IconStarFull,
            BaseHeaderDivider,
            BaseHorizontalBarChart,
        },

        props: {
            averageRating: { type: Number, required: true },
            totalReviews: { type: Number, required: true },
            ratings: { type: Array, required: true },
        },
    }
</script>
