<template>
    <BaseText>
        <p>{{ $t('pro.profile.hint.top') }}</p>

        <i18n-t tag="p" keypath="pro.profile.hint.bottom">
            <template #required>
                <ProProfileRequired />
            </template>
        </i18n-t>
    </BaseText>
</template>

<script>
    import BaseText from '@/components/base/BaseText'
    import ProProfileRequired from '@/components/pro/profile/ProProfileRequired'

    export default {
        components: {
            BaseText,
            ProProfileRequired,
        },
    }
</script>
