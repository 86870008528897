import axios from 'axios'
import router from '@/router'
import ServiceAuth from '@/services/ServiceAuth'
import { normalizeFormData } from '@/axios/helpers'

function createResponse({ data, headers, status }) {
    let response
    if ((headers['content-type'] || '').indexOf('text/csv') !== -1) {
        response = { data: data }
    } else {
        response =
            (headers['content-type'] || '').indexOf('application/json') !== -1 &&
            typeof data === 'object'
                ? data
                : {}
    }

    response._headers = headers
    response._status = status

    return response
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use((config) => {
    if (ServiceAuth.isLoggedIn()) {
        config.headers.Authorization = [`Bearer ${ServiceAuth.getToken()}`]
    }

    if (config.params) {
        config.params = normalizeFormData(config.params)
    }

    return config
})

axios.interceptors.response.use(
    (response) => createResponse(response),
    (error) => {
        error.response = createResponse(error.response)

        if (error.response._status === 401) {
            ServiceAuth.logOut()
            return router.push('/')
        }

        return Promise.reject(error)
    },
)

export default axios
