<template>
    <p :class="tagClass">
        <span>{{ label }}</span>
    </p>
</template>

<script>
    export default {
        props: {
            label: { type: String, required: true },
        },

        computed: {
            tagClass() {
                return {
                    'inline-flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'h-21': true,
                    'rounded-sm': true,
                    'uppercase': true,
                    'text-11': true,
                    'leading-none': true,
                    'tracking-widest': true,
                    'font-medium': true,
                    'px-10': true,
                    'border-1': true,
                    'border-current': true,
                }
            },
        },
    }
</script>
