<template>
    <article class="space-y-30">
        <header class="flex">
            <BaseHeading :heading="addOn.name" :level="4" />

            <BaseLink :route="editRoute" class="flex space-x-10 block ml-auto">
                <IconModify class="h-20 w-20" />
                <span>{{ $t('common.modify') }}</span>
            </BaseLink>
        </header>

        <BaseText v-if="addOn.description" :content="addOn.description" />

        <ProProfileAddOnsDetails :add-on="addOn" />
    </article>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'
    import ProProfileAddOnsDetails from '@/components/pro/profile/addons/ProProfileAddOnsDetails'
    import IconModify from '@/assets/vectors/icon-modify.svg'

    export default {
        components: {
            BaseHeading,
            BaseText,
            BaseLink,
            ProProfileAddOnsDetails,
            IconModify,
        },

        props: {
            addOn: { type: Object, required: true },
        },

        computed: {
            editRoute() {
                return `/pro/profile/addons/${this.addOn.id}/edit`
            },
        },
    }
</script>
