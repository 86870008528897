<template>
    <LayoutOverlay :back-button="true" @back="back">
        <ProSection :heading="$t('pro.profile.places.guest.area.heading')">
            <FormWrapper>
                <FieldSuggest
                    :label="$t('common.fields.zip-code')"
                    :has-autofocus="true"
                    :suggestions-call="addressSuggestions"
                    :form-data="suggestFormData"
                    :form-errors="formErrors"
                    value-path="areaZipCode"
                    @select="selectAddressAndChange"
                />

                <slot name="buttons" :is-submittable="isSubmittable">
                    <FormButtons>
                        <BaseButton
                            :label="submitLabel"
                            :is-disabled="!isSubmittable"
                            :is-processing="isSubmitting"
                            @click="save"
                        />
                    </FormButtons>
                </slot>
            </FormWrapper>
        </ProSection>
    </LayoutOverlay>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import ApiGoogle from '@/apis/ApiGoogle'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldSuggest from '@/components/form-fields/FieldSuggest'

    export default {
        components: {
            BaseButton,
            FormWrapper,
            FormButtons,
            FieldSuggest,
        },

        mixins: [MixinProProfileSection],

        computed: {
            isSubmittable() {
                return true
            },

            isRedirectingHome() {
                return false
            },

            submitLabel() {
                return this.$t('common.save')
            },

            addressSuggestions() {
                return ApiGoogle.getAddressSuggestions
            },

            suggestFormData() {
                return {
                    areaZipCode: {
                        label: this.formData.areaZipCode,
                        value: this.formData.areaZipCode,
                    },
                }
            },
        },

        methods: {
            selectAddressAndChange({ value: placeId }) {
                ApiGoogle.getAddressZipCode(placeId)
                    .then((zipCode) => {
                        this.change({ path: 'areaZipCode', value: zipCode })
                    })
                    .catch((errors) => {
                        this.changeErrors(errors)
                        this.focusAutofocusInput()
                    })
            },

            apiCall() {
                return ApiPros.update(this.$auth.getUser().id, 'guestsPlaceData', this.formData)
            },
        },
    }
</script>
