<script>
    import ProProfileCancellationStep2 from '@/components/pro/profile/cancellation/ProProfileCancellationStep2'

    export default {
        extends: ProProfileCancellationStep2,

        computed: {
            placeSlug() {
                return 'guest'
            },

            timePath() {
                return 'cancellationPolicy.guestsPlaceCancellationTime'
            },
        },
    }
</script>
