<template>
    <svg viewBox="0 0 20 20">
        <g class="text-purple">
            <circle
                ref="pro"
                v-bind="proProps"
                fill="transparent"
                stroke-width="4"
                class="stroke-current origin-center"
                style="transform: rotate(90deg) scaleX(-1)"
            />
        </g>
        <g class="text-magenta">
            <circle
                ref="guest"
                v-bind="guestProps"
                fill="transparent"
                stroke-width="4"
                class="stroke-current origin-center"
                style="transform: rotate(-90deg)"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        props: {
            pro: { type: Number, required: true },
            guest: { type: Number, required: true },
        },

        data() {
            return {
                radius: 8,
            }
        },

        computed: {
            proProps() {
                return {
                    r: this.radius,
                    // cx: this.pro > this.guest ? 9.5 : 10.5,
                    // cy: this.pro > this.guest ? 10.5 : 10.5,
                    cx: 10,
                    cy: 10,
                }
            },

            guestProps() {
                return {
                    r: this.radius,
                    // cx: this.pro > this.guest ? 10 : 9.5,
                    // cy: this.pro > this.guest ? 10 : 10.5,
                    cx: 10,
                    cy: 10,
                }
            },
        },

        watch: {
            pro() {
                this.showPercentages()
            },

            guest() {
                this.showPercentages()
            },
        },

        mounted() {
            this.showPercentages()
        },

        methods: {
            showPercentages() {
                this.generateChart('pro', this.pro)
                this.generateChart('guest', this.guest)
            },

            generateChart(ref, percentage) {
                const CIRCUMFERENCE = 2 * Math.PI * this.radius

                this.$refs[ref].style.strokeDasharray = CIRCUMFERENCE
                this.$refs[ref].style.strokeDashoffset = CIRCUMFERENCE * (1 - percentage / 100)
            },
        },
    }
</script>
