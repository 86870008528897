<template>
    <GuestBookStep
        :heading="$t('guest.book.where.heading')"
        :subheading="$t('guest.book.where.subheading')"
        :has-progress="hasProgress"
        @submit="saveAndGoToNextStep"
    >
        <template #default>
            <FormFields>
                <IconLocation class="h-60 mx-auto" />

                <div v-if="isAddressSelected" class="flex flex-col items-center relative z-above">
                    <BasePill
                        :label="formData.search?.areaZipCode"
                        :is-selected="true"
                        :is-closable="true"
                        class="-mb-15"
                        @close="clearAddress"
                    />

                    <BaseMap ref="map" :is-draggable="false" class="h-300 w-full z-below" />
                </div>

                <FieldSuggest
                    v-else
                    :label="$t('guest.book.where.enter-zip-code')"
                    :has-autofocus="true"
                    :suggestions-call="getAddressSuggestions"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="search.areaZipCode"
                    @focus="isAddressFocused = true"
                    @blur="isAddressFocused = false"
                    @select="selectAddressAndChange"
                />
            </FormFields>
        </template>

        <template v-if="isSubmittable" #bottom>
            <BaseButton :label="$t('common.continue')" :is-processing="isSubmitting" />
        </template>
    </GuestBookStep>
</template>

<script>
    import ApiGoogle from '@/apis/ApiGoogle'
    import MixinBookStep from '@/mixins/MixinBookStep'
    import BasePill from '@/components/base/BasePill'
    import FormFields from '@/components/form-elements/FormFields'
    import FieldSuggest from '@/components/form-fields/FieldSuggest'
    import BaseButton from '@/components/base/BaseButton'
    import BaseMap from '@/components/base/BaseMap'
    import IconLocation from '@/assets/vectors/icon-location.svg'

    export default {
        components: {
            BasePill,
            FormFields,
            FieldSuggest,
            BaseButton,
            BaseMap,
            IconLocation,
        },

        mixins: [MixinBookStep],

        data() {
            return {
                isAddressFocused: false,
                isPlaceSelectorOpen: false,
            }
        },

        computed: {
            isAddressSelected() {
                return !!this.areaZipCode
            },

            isSubmittable() {
                return this.isAddressSelected
            },

            areaZipCode() {
                return this.formData.search?.areaZipCode
            },

            areaBounds() {
                return this.formData.search?.areaBounds
            },

            getAddressSuggestions() {
                return ApiGoogle.getAddressSuggestions
            },
        },

        watch: {
            areaZipCode() {
                this.areaZipCode && this.calculateCoordinates()
            },

            areaBounds() {
                this.areaBounds && this.fitBounds()
            },
        },

        mounted() {
            this.areaBounds && this.fitBounds()
        },

        methods: {
            getAddressFromGoogleAddressDetails({ address_components: addressComponents }) {
                const { long_name: zipCode } =
                    addressComponents.find(({ types }) => types.includes('postal_code')) || {}
                const { long_name: city } =
                    addressComponents.find(({ types }) => types.includes('locality')) || {}
                const { short_name: state } =
                    addressComponents.find(({ types }) =>
                        types.includes('administrative_area_level_1'),
                    ) || {}

                return {
                    zipCode,
                    city,
                    state,
                }
            },

            calculateCoordinates() {
                ApiGoogle.getZipCodeDetails(this.areaZipCode).then((details) => {
                    const bounds = details.geometry.viewport || details.geometry.bounds

                    this.change({
                        path: 'search.address',
                        value: this.getAddressFromGoogleAddressDetails(details),
                    })
                    this.change({
                        path: 'search.areaBounds',
                        value: [
                            [bounds.getSouthWest().lat(), bounds.getSouthWest().lng()],
                            [bounds.getNorthEast().lat(), bounds.getNorthEast().lng()],
                        ],
                    })
                })
            },

            fitBounds() {
                setTimeout(() => this.$refs.map.leaflet.fitBounds(this.areaBounds))
            },

            selectAddressAndChange({ value: placeId }) {
                this.changeErrors()

                ApiGoogle.getAddressZipCode(placeId)
                    .then((zipCode) => {
                        this.change({ path: 'search.areaZipCode', value: zipCode })
                    })
                    .catch((error) => {
                        this.focusAutofocusInput()
                        this.changeErrors({ errors: { 'search.areaZipCode': error } })
                    })
            },

            clearAddress() {
                this.change({ path: 'search.areaZipCode', value: null })
            },
        },
    }
</script>
