<template>
    <LandingContainer class="pt-50 md:pt-80">
        <header class="text-center space-y-20 md:space-y-30 mb-30 md:mb-60">
            <LandingHeading :level="2">
                {{ $t('landing.pros.values.heading.1') }}<br />
                {{ $t('landing.pros.values.heading.2') }}
            </LandingHeading>

            <LandingHeading :level="4">
                {{ $t('landing.pros.values.subheading') }}
            </LandingHeading>
        </header>

        <LandingAttributes :attributes="attributes" />
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingAttributes from '@/components/landing/LandingAttributes'
    import IconValuesFree from '@/assets/vectors/icon-values-free.svg'
    import IconValuesBoss from '@/assets/vectors/icon-values-boss.svg'
    import IconValuesUnlimit from '@/assets/vectors/icon-values-unlimit.svg'
    import IconValuesPayments from '@/assets/vectors/icon-values-payments.svg'
    import IconValuesDiscovered from '@/assets/vectors/icon-values-discovered.svg'
    import IconValuesSmarter from '@/assets/vectors/icon-values-smarter.svg'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingAttributes,
        },

        computed: {
            attributes() {
                return [
                    {
                        icon: IconValuesFree,
                        heading: this.$t('landing.pros.values.attributes.free.heading'),
                        description: this.$t('landing.pros.values.attributes.free.description'),
                    },
                    {
                        icon: IconValuesBoss,
                        heading: this.$t('landing.pros.values.attributes.boss.heading'),
                        description: this.$t('landing.pros.values.attributes.boss.description'),
                    },
                    {
                        icon: IconValuesUnlimit,
                        heading: this.$t('landing.pros.values.attributes.unlimit.heading'),
                        description: this.$t('landing.pros.values.attributes.unlimit.description'),
                    },
                    {
                        icon: IconValuesPayments,
                        heading: this.$t('landing.pros.values.attributes.payments.heading'),
                        description: this.$t('landing.pros.values.attributes.payments.description'),
                    },
                    {
                        icon: IconValuesDiscovered,
                        heading: this.$t('landing.pros.values.attributes.discovered.heading'),
                        description: this.$t(
                            'landing.pros.values.attributes.discovered.description',
                        ),
                    },
                    {
                        icon: IconValuesSmarter,
                        heading: this.$t('landing.pros.values.attributes.smarter.heading'),
                        description: this.$t('landing.pros.values.attributes.smarter.description'),
                    },
                ]
            },
        },
    }
</script>
