export default {
    namespaced: true,

    state: {
        unread: null,
    },

    getters: {
        getUnread(state) {
            return state.unread
        },
    },

    mutations: {
        setUnread(state, unread) {
            state.unread = unread
        },
    },
}
