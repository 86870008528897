export default {
    data() {
        return {
            page: 1,
            isPaginationFinished: false,
            isPaging: false,
        }
    },

    computed: {
        paginatedListProp() {
            return ''
        },

        perPage() {
            return 10
        },

        paginatedCall() {
            return Promise.resolve([])
        },
    },

    created() {
        if (this[this.paginatedListProp]?.length < this.perPage) {
            this.isPaginationFinished = true
        }
    },

    methods: {
        loadNextPage() {
            this.isLoadingNextPage = true
            this.page += 1

            this.paginatedCall.then((list) => {
                if (list.length < this.perPage) {
                    this.isPaginationFinished = true
                }

                this[this.paginatedListProp] = [...this[this.paginatedListProp], ...list]
            })
        },
    },
}
