<template>
    <LandingContainer class="pt-50 md:pt-80">
        <LandingHeading :level="3" class="text-center text-purple mb-30 md:mb-60">
            {{ $t('landing.safety.tech.heading') }}
        </LandingHeading>

        <LandingAttributes :attributes="attributes" />
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingAttributes from '@/components/landing/LandingAttributes'
    import IconTechIdentity from '@/assets/vectors/icon-tech-identity.svg'
    import IconTechLicenses from '@/assets/vectors/icon-tech-licenses.svg'
    import IconTechBarbicide from '@/assets/vectors/icon-tech-barbicide.svg'
    import IconTechIndependent from '@/assets/vectors/icon-tech-independent.svg'
    import IconTechVerified from '@/assets/vectors/icon-tech-verified.svg'
    import IconTechWe from '@/assets/vectors/icon-tech-we.svg'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingAttributes,
        },

        computed: {
            attributes() {
                return [
                    {
                        icon: IconTechIdentity,
                        heading: this.$t('landing.safety.tech.identity.heading'),
                        description: this.$t('landing.safety.tech.identity.description'),
                    },
                    {
                        icon: IconTechLicenses,
                        heading: this.$t('landing.safety.tech.licenses.heading'),
                        description: this.$t('landing.safety.tech.licenses.description'),
                    },
                    {
                        icon: IconTechBarbicide,
                        heading: this.$t('landing.safety.tech.barbicide.heading'),
                        description: this.$t('landing.safety.tech.barbicide.description'),
                    },
                    {
                        icon: IconTechIndependent,
                        heading: this.$t('landing.safety.tech.independent.heading'),
                        description: this.$t('landing.safety.tech.independent.description'),
                    },
                    {
                        icon: IconTechVerified,
                        heading: this.$t('landing.safety.tech.verified.heading'),
                        description: this.$t('landing.safety.tech.verified.description'),
                    },
                    {
                        icon: IconTechWe,
                        heading: this.$t('landing.safety.tech.we.heading'),
                        description: this.$t('landing.safety.tech.we.description'),
                    },
                ]
            },
        },
    }
</script>
