<template>
    <LayoutApp back-button="/">
        <section
            v-for="({ slug, route, level }, index) in kinds"
            :key="slug"
            :class="kindClass(index)"
        >
            <BaseHeading :heading="$t(`auth.register.choose.${slug}.heading`)" :level="3" />
            <BaseText :content="$t(`auth.register.choose.${slug}.copy`)" class="text-gray-2" />
            <BaseButton
                :label="$t(`auth.register.choose.${slug}.cta`)"
                :route="route"
                :level="level"
            />
        </section>
    </LayoutApp>
</template>

<script>
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            LayoutApp,
            BaseHeading,
            BaseText,
            BaseButton,
        },

        computed: {
            kinds() {
                return [
                    {
                        slug: 'guest',
                        route: '/auth/register/guest',
                        level: 1,
                    },
                    {
                        slug: 'pro',
                        route: '/auth/register/pro',
                        level: 2,
                    },
                ]
            },
        },

        methods: {
            kindClass(index) {
                return {
                    'text-center': true,
                    'flex-grow': true,
                    'flex': true,
                    'flex-col': true,
                    'space-y-30': true,
                    'items-center': true,
                    'justify-center': true,
                    'border-t-1': index > 0,
                    'border-gray-5': index > 0,
                }
            },
        },
    }
</script>
