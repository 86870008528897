import axios from 'axios'
import transformGuestToApi from '@/apis/transforms/transformGuestToApi'
import transformGuestFromApi from '@/apis/transforms/transformGuestFromApi'

const transforms = {
    transformRequest: [transformGuestToApi, ...axios.defaults.transformRequest],
    transformResponse: [...axios.defaults.transformResponse, transformGuestFromApi],
}

export default {
    register(data) {
        return axios.post('/guests', data, transforms)
    },

    get(id) {
        return axios.get(`/guests/${id}`, transforms)
    },

    update(id, section, data) {
        return axios.patch(`/guests/${id}/${section}`, data, transforms)
    },

    updateProfilePicture(id, data) {
        return axios.post(`/guests/${id}/profilePicture`, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            transformResponse: transforms.transformResponse,
        })
    },

    getnerateStripeClientSecret(id) {
        return axios.post(`/guests/${id}/createPaymentMethod`)
    },

    getStripePaymentMethod(id) {
        return axios.get(`/guests/${id}/paymentMethod`)
    },

    getInvitation(token) {
        return axios.get('/auth/getClientInvitationTokenData', { params: { token } })
    },

    verifyId(id, data) {
        return axios.post(`/guests/${id}/identityVerification`, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },
}
