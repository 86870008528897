<template>
    <div class="flex items-center text-left">
        <label :class="labelClass">
            <slot name="label">{{ label }}</slot>
        </label>

        <div :class="valueClass">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            label: { type: String, default: null },
            isValueNarrow: { type: Boolean, default: false },
            isValueAuto: { type: Boolean, default: false },
        },

        computed: {
            labelClass() {
                return {
                    'font-bold': true,
                    'pr-20': true,
                    'w-9/12': !this.isValueAuto && this.isValueNarrow,
                    'w-7/12': !this.isValueAuto && !this.isValueNarrow,
                }
            },

            valueClass() {
                return {
                    'w-4/12': !this.isValueAuto && this.isValueNarrow,
                    'w-5/12': !this.isValueAuto && !this.isValueNarrow,
                    'ml-auto': this.isValueAuto,
                }
            },
        },
    }
</script>
