<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="close">
        <div class="px-20 py-15">
            <BaseLinks :items="links" @click-item="clickItem" />
        </div>
    </BaseDrawer>
</template>

<script>
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseLinks from '@/components/base/BaseLinks'
    import GuestAppointmentsShowCancellation from '@/components/guest/appointments/show/GuestAppointmentsShowCancellation'

    export default {
        components: {
            BaseDrawer,
            BaseLinks,
        },

        props: {
            appointment: { type: Object, required: true },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['call', 'reschedule', 'cancel', 'close'],

        computed: {
            links() {
                const pro = this.appointment?.pro
                const links = [
                    {
                        label: this.$t('guest.appointments.reschedule-appointment'),
                        event: this.reschedule,
                    },
                    { label: this.$t('guest.appointments.cancel-appointment'), event: this.cancel },
                    { label: this.$t('common.close'), event: this.close },
                ]

                return pro.phone
                    ? [
                          {
                              label: this.$t('guest.appointments.call-x', { x: pro.firstName }),
                              href: `tel:${pro.phone}`,
                          },
                          ...links,
                      ]
                    : links
            },
        },

        methods: {
            clickItem(item) {
                item.event && item.event()
            },

            reschedule(event) {
                this.$emit('reschedule', event)
            },

            cancel() {
                this.$ui
                    .confirm({
                        message: this.$t('guest.appointments.cancel.message'),
                        note: {
                            component: GuestAppointmentsShowCancellation,
                            props: { appointment: this.appointment },
                        },
                    })
                    .then((isConfirmed) => isConfirmed && this.$emit('cancel'))
            },

            close(event) {
                this.$emit('close', event)
            },
        },
    }
</script>
