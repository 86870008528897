import { markRaw } from 'vue'

export default {
    namespaced: true,

    state: {
        alerts: [],
    },

    getters: {
        getAlerts(state) {
            return state.alerts
        },
    },

    mutations: {
        open(state, { id, component }) {
            if (state.alerts.find((alert) => alert.id === id)) {
                return
            }

            state.alerts.push({ id, component: markRaw(component) })
        },

        close(state, id) {
            state.alerts = state.alerts.filter((alert) => alert.id !== id)
        },

        closeAll(state) {
            state.alerts = []
        },
    },
}
