<template>
    <ProSection>
        <header class="space-y-20 flex flex-col items-center text-center">
            <IconSuccess class="h-35 w-35 text-green animate-pop" />
            <BaseHeading :heading="heading" :level="3" />
        </header>

        <div class="flex-grow">
            <ProProfileAdd
                v-if="isAddNewDisplayed"
                :label="$t('pro.profile.licenses.add-new')"
                class="mx-auto"
                @click="goToStart"
            />
        </div>

        <FormButtons>
            <BaseButton :label="$t('common.go-to-profile')" route="/pro/profile" />
            <!-- <BaseButton
                :label="$t('pro.profile.licenses.go-to-licenses')"
                route="/pro/profile/licenses"
            /> -->
        </FormButtons>
    </ProSection>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import BaseHeading from '@/components/base/BaseHeading'
    import ProProfileAdd from '@/components/pro/profile/ProProfileAdd'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import IconSuccess from '@/assets/vectors/icon-success.svg'

    export default {
        components: {
            ProSection,
            BaseHeading,
            ProProfileAdd,
            FormButtons,
            BaseButton,
            IconSuccess,
        },

        mixins: [MixinFormStep],

        computed: {
            isAddNewDisplayed() {
                return false
            },

            heading() {
                return this.$t('pro.profile.licenses.steps.2.added', { name: this.formData.name })
            },
        },
    }
</script>
