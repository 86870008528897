<template>
    <section v-if="isEditable && hasAddress" :class="wrapperClass">
        <header class="flex items-center border-t-1 border-gray-5 pt-30 -mt-10">
            <BaseHeading :heading="$t('pro.clients.show.sections.address')" :level="5" />
            <BaseButtonEdit class="ml-auto" @click="$emit('edit')" />
        </header>

        <BaseText class="break-words">
            {{ client.address?.address }},
            {{ client.address?.zipCode }}
            {{ client.address?.city }},
            {{ client.address?.state }}
        </BaseText>
    </section>

    <BaseLink
        v-else-if="!hasAddress"
        class="flex items-center space-x-15 text-left"
        @click="$emit('add')"
    >
        <IconGuest class="h-28" />
        <span>{{ $t('pro.clients.show.add-address') }}</span>
    </BaseLink>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButtonEdit from '@/components/base/BaseButtonEdit'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'
    import IconGuest from '@/assets/vectors/icon-guest.svg'

    export default {
        components: {
            BaseHeading,
            BaseButtonEdit,
            BaseText,
            BaseLink,
            IconGuest,
        },

        props: {
            client: { type: Object, required: true },
            isEditable: { type: Boolean, default: false },
            isEdited: { type: Boolean, default: false },
        },

        emits: ['add', 'edit'],

        computed: {
            hasAddress() {
                return !!this.client.address
            },

            wrapperClass() {
                return {
                    'space-y-15': true,
                    'border-t-2': this.isEdited && this.hasAddress,
                    'border-gray-5': this.isEdited && this.hasAddress,
                    'pt-30': this.isEdited && this.hasAddress,
                }
            },
        },
    }
</script>
