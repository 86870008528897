<template>
    <LandingContainer
        :level="2"
        class="pt-60 md:pt-80 text-center flex flex-col-reverse md:flex-col"
    >
        <div>
            <LandingHeading :level="2" class="text-purple mb-20 md:mb-30">
                {{ $t('landing.pros.places.heading') }}
            </LandingHeading>

            <LandingText :content="$t('landing.pros.places.description')" />
        </div>

        <div class="mb-30 xs:mb-40 md:mb-0 md:mt-1/12 md:pt-20">
            <LandingSplit v-bind="placeIcons" />
        </div>
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingText from '@/components/landing/LandingText'
    import LandingSplit from '@/components/landing/LandingSplit'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import IconProThin from '@/assets/vectors/icon-pro-thin.svg'
    import IconGuestThin from '@/assets/vectors/icon-guest-thin.svg'
    import IconGuest from '@/assets/vectors/icon-guest.svg'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingText,
            LandingSplit,
        },

        computed: {
            placeIcons() {
                return {
                    left: { thick: IconPro, thin: IconProThin },
                    right: { thick: IconGuest, thin: IconGuestThin },
                }
            },
        },
    }
</script>
