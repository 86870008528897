<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProProfilePlacesGuestPadding from '@/components/pro/profile/places/guest/ProProfilePlacesGuestPadding'

    export default {
        extends: ProProfilePlacesGuestPadding,

        mixins: [MixinFormStep],
    }
</script>
