<template>
    <LayoutApp :back-button="backButton" @back="step -= 1">
        <template v-if="validationStatus === 'pending'">
            <div class="sticky bg-white top-70 pb-20">
                <BaseHeaderSection
                    :heading="$t('guest.verify.heading')"
                    :is-center="false"
                    class="mb-30"
                />

                <GuestVerifySteps :current-step="step" />
            </div>

            <component
                :is="currentStep.component"
                :step-data="formData"
                @change="changeData"
                @go-to-start="goToStart"
                @go-to="goToStep"
                @go-to-next="goToNextStep"
                @failed="changeValidationStatus('failed')"
                @succeeded="changeValidationStatus('succeeded')"
            />
        </template>

        <GuestVerifyResult v-else v-bind="resultProps" />
    </LayoutApp>
</template>

<script>
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import GuestVerifySteps from '@/components/guest/verify/GuestVerifySteps'
    import GuestVerifyStep1 from '@/components/guest/verify/GuestVerifyStep1'
    import GuestVerifyStep2 from '@/components/guest/verify/GuestVerifyStep2'
    import GuestVerifyStep3 from '@/components/guest/verify/GuestVerifyStep3'
    import GuestVerifyResult from '@/components/guest/verify/GuestVerifyResult'
    import IconVerifyValid from '@/assets/vectors/icon-verify-valid.svg'
    import IconVerifyInvalid from '@/assets/vectors/icon-verify-invalid.svg'

    export default {
        components: {
            LayoutApp,
            BaseHeaderSection,
            GuestVerifySteps,
            GuestVerifyResult,
        },

        mixins: [MixinFormSteppable],

        data() {
            return {
                validationStatus: 'pending',
            }
        },

        computed: {
            backButton() {
                return this.isFirstStep ? '/' : true
            },

            steps() {
                return [
                    { component: GuestVerifyStep1 },
                    { component: GuestVerifyStep2 },
                    { component: GuestVerifyStep3 },
                ]
            },

            resultProps() {
                return {
                    succeeded: {
                        heading: this.$t('guest.verify.succeeded.heading'),
                        icon: IconVerifyValid,
                        iconColor: 'text-green-2',
                        copy: this.$t('guest.verify.succeeded.copy'),
                        ctaLabel: this.$t('guest.verify.succeeded.cta'),
                        ctaRoute: '/guest/appointments/future',
                    },
                    failed: {
                        heading: this.$t('guest.verify.failed.heading'),
                        icon: IconVerifyInvalid,
                        iconColor: 'text-red',
                        copy: this.$t('guest.verify.failed.copy'),
                        ctaLabel: this.$t('guest.verify.failed.cta'),
                        ctaRoute: '/guest/profile',
                    },
                }[this.validationStatus]
            },
        },

        methods: {
            changeValidationStatus(status) {
                this.validationStatus = status
            },
        },
    }
</script>
