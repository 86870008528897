<template>
    <div :class="containerClass">
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            level: { type: Number, default: 1 },
        },

        computed: {
            containerClass() {
                return {
                    'relative': true,
                    'w-full': true,
                    'mx-auto': true,
                    'md:px-50': true,
                    'lg:px-80': true,
                    'px-20': true,
                    'text-gray-1': true,
                    'max-w-1300': this.level === 1,
                    'max-w-900': this.level === 2,
                }
            },
        },
    }
</script>
