import dayjs from 'dayjs'
import { normalizeFormData } from '@/axios/helpers'

export const transformParams = (params) => {
    const startDate =
        params.startDate &&
        dayjs(params.startDate)
            .startOf('day')
            .format('YYYY-MM-DD')

    const endDate =
        params.endDate &&
        dayjs(params.endDate)
            .startOf('day')
            .format('YYYY-MM-DD')

    return {
        ...params,
        startDate: startDate,
        endDate: endDate,
    }
}

export default (data) => {
    data = normalizeFormData(data || {})

    return {
        ...data,
    }
}
