<template>
    <ProSection
        :heading="$t('pro.profile.addons.steps.8.heading')"
        :description="$t('pro.profile.addons.steps.8.description')"
    >
        <FormWrapper>
            <ProProfileChecklist
                :options="experienceOptions"
                :value="formData.experienceIds"
                @change="changeExperienceIds"
            />

            <slot name="buttons" :is-submittable="isSubmittable" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import ProSection from '@/components/pro/ProSection'
    import ProProfileChecklist from '@/components/pro/profile/ProProfileChecklist'
    import FormWrapper from '@/components/form-elements/FormWrapper'

    export default {
        components: {
            ProSection,
            ProProfileChecklist,
            FormWrapper,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep, MixinProExperiences],

        computed: {
            isSubmittable() {
                return (this.formData.experienceIds || []).length > 0
            },

            experienceOptions() {
                return this.proExperiences.map((experience) => ({
                    label: experience.name,
                    value: experience.id,
                }))
            },
        },

        methods: {
            changeExperienceIds(value) {
                this.change({ path: 'experienceIds', value })
            },
        },
    }
</script>
