<template>
    <BaseTabs :items="viewTabs" class="pt-10" @change="changeView" />

    <component
        :is="viewComponent"
        :date="date"
        :appointments="appointments"
        :events="events"
        class="-mx-20 flex-grow"
        @click-slot="$emit('click-slot', $event)"
        @click-event="$emit('click-event', $event)"
    />
</template>

<script>
    import BaseTabs from '@/components/base/BaseTabs'
    import ProCalendarTimeline from '@/components/pro/calendar/ProCalendarTimeline'
    import ProCalendarMap from '@/components/pro/calendar/ProCalendarMap'
    import IconTime from '@/assets/vectors/icon-time.svg'
    import IconMap from '@/assets/vectors/icon-map.svg'

    export default {
        components: {
            BaseTabs,
        },

        props: {
            date: { type: Object, required: true },
            appointments: { type: Array, required: true },
            events: { type: Array, required: true },
        },

        emits: ['click-slot', 'click-event'],

        data() {
            return {
                view: 'time',
            }
        },

        computed: {
            viewTabs() {
                return [
                    {
                        label: this.$t('pro.calendar.view.time'),
                        slug: 'time',
                        icon: IconTime,
                        isSelected: this.view === 'time',
                    },
                    {
                        label: this.$t('pro.calendar.view.map'),
                        slug: 'map',
                        icon: IconMap,
                        isSelected: this.view === 'map',
                    },
                ]
            },

            viewComponent() {
                return {
                    time: ProCalendarTimeline,
                    map: ProCalendarMap,
                }[this.view]
            },
        },

        methods: {
            changeView({ slug: view }) {
                this.view = view
            },
        },
    }
</script>
