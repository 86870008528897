<template>
    <LayoutLanding :is-home="true">
        <template #default>
            <LandingHeader :has-transition-bg="true" :has-pro-cta="true" />
            <LandingIndexIntro />
            <LandingIndexExplore />
            <LandingIndexSafety />
            <LandingIndexPlaces />
        </template>

        <template #footer>
            <LandingIndexPros />
        </template>
    </LayoutLanding>
</template>

<script>
    import LayoutLanding from '@/components/layouts/LayoutLanding'
    import LandingHeader from '@/components/landing/LandingHeader'
    import LandingIndexIntro from '@/components/landing/index/LandingIndexIntro'
    import LandingIndexExplore from '@/components/landing/index/LandingIndexExplore'
    import LandingIndexSafety from '@/components/landing/index/LandingIndexSafety'
    import LandingIndexPlaces from '@/components/landing/index/LandingIndexPlaces'
    import LandingIndexPros from '@/components/landing/index/LandingIndexPros'

    export default {
        components: {
            LayoutLanding,
            LandingHeader,
            LandingIndexIntro,
            LandingIndexExplore,
            LandingIndexSafety,
            LandingIndexPlaces,
            LandingIndexPros,
        },
    }
</script>
