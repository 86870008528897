<template>
    <div class="flex flex-col w-full">
        <div class="flex flex-col items-center">
            {{ $t('admin.dashboard.topLocations') }}
        </div>
        <div class="flex flex-row items-center justify-center mt-25">
            <div v-for="(location, index) in topLocations" :key="index" class="flex flex-row ml-20">
                <IconPin class="h-22 text-gray-2" />
                <span class="text-16 text-gray-2 ml-10">{{ location }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import IconPin from '@/assets/vectors/icon-pin.svg'

    export default {
        components: {
            IconPin,
        },

        props: {
            topLocations: { type: Array, required: true },
        },
    }
</script>
