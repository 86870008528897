<template>
    <div class="space-y-30">
        <ProProfileSetting
            v-for="(detail, index) in details"
            :key="index"
            :icon="detail.icon"
            :heading="detail.heading"
            :subheading="detail.subheading"
            :value="detail.value"
            :is-editable="isEditable"
            @edit="$emit('edit', detail.slug)"
        />
    </div>
</template>

<script>
    import ProProfileSetting from '@/components/pro/profile/ProProfileSetting'
    import IconTime from '@/assets/vectors/icon-time.svg'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import IconGuest from '@/assets/vectors/icon-guest.svg'

    export default {
        components: {
            ProProfileSetting,
        },

        props: {
            addOn: { type: Object, required: true },
            isEditable: { type: Boolean, default: false },
        },

        emits: ['edit'],

        computed: {
            details() {
                const details = [
                    {
                        icon: IconTime,
                        heading: this.$tc('common.n-minutes', this.addOn.duration, {
                            n: this.addOn.duration,
                        }),
                        slug: 'duration',
                    },
                ]

                if ((this.addOn.workPlace || []).includes('pro')) {
                    details.push({
                        icon: IconPro,
                        heading: this.addOn.name,
                        subheading: this.$t('pro.profile.place-names.pro'),
                        value: `$${this.addOn.prosPlacePrice}`,
                        slug: 'price-pro',
                    })
                }

                if ((this.addOn.workPlace || []).includes('guest')) {
                    details.push({
                        icon: IconGuest,
                        heading: this.addOn.name,
                        subheading: this.$t('pro.profile.place-names.guest'),
                        value: `$${this.addOn.guestsPlacePrice}`,
                        slug: 'price-guest',
                    })
                }

                return details.concat([
                    {
                        heading: this.$t('pro.profile.addons.steps.9.offered-with'),
                        subheading: this.addOn.experiences.map(({ name }) => name).join(', '),
                        slug: 'experiences',
                    },
                ])
            },
        },
    }
</script>
