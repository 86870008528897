<template>
    <p class="text-14 font-medium">{{ label }}</p>
</template>

<script>
    export default {
        props: {
            text: { type: String, default: '' },
            limit: { type: Number, required: true },
        },

        computed: {
            label() {
                return this.$tc('common.n-characters-left', this.limit - (this.text?.length || 0))
            },
        },
    }
</script>
