<template>
    <BaseOverlay :is-open="isOpen" class="flex items-center justify-center" @close="close">
        <TransitionScale>
            <div v-if="isOpen" class="relative p-50 bg-white shadow-md flex flex-col">
                <button class="ml-auto -mt-30 -mr-30" @click="close">
                    <IconClose class="w-25 h-25" />
                </button>

                <div class="flex justify-center -mt-15">
                    <IconSuccess class="h-35 w-35 text-green" />
                </div>

                <BaseHeading
                    class="text-center text-black mt-20"
                    :heading="$t('admin.dashboard.newPros.proInvitationSent.heading')"
                />

                <div class="w-300 text-center font-bold text-gray-2 mt-20">
                    <span v-if="pro.firstName || pro.lastName">
                        {{ pro.firstName }} {{ pro.lastName }}
                    </span>
                    <span v-else>{{ $t('admin.dashboard.newPros.invitePro.user') }}</span>
                    {{ $t('admin.dashboard.newPros.proInvitationSent.text') }}
                </div>

                <BaseButton
                    class="w-300 mt-20"
                    :label="$t('common.close')"
                    :level="1"
                    @click="close"
                />
            </div>
        </TransitionScale>
    </BaseOverlay>
</template>

<script>
    import BaseButton from '@/components/base/BaseButton'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseOverlay from '@/components/base/BaseOverlay'
    import IconClose from '@/assets/vectors/icon-close.svg'
    import IconSuccess from '@/assets/vectors/icon-success.svg'
    import TransitionScale from '@/components/transitions/TransitionScale'

    export default {
        components: {
            IconClose,
            BaseButton,
            BaseOverlay,
            BaseHeading,
            IconSuccess,
            TransitionScale,
        },

        props: {
            pro: { type: Object, default: null },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['close'],

        methods: {
            close() {
                this.$emit('close')
            },
        },
    }
</script>
