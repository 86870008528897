<template>
    <div :class="fieldClass">
        <label v-if="label" :class="labelClass">
            {{ label }}
        </label>

        <div :class="inputClass">
            <slot></slot>
        </div>

        <div v-if="error" class="relative text-md font-medium text-14 text-white bg-red p-10">
            <div
                class="absolute transform rotate-45 origin-bottom-left bottom-full -mb-3 left-5 h-15 w-15 bg-red"
            />
            <p class="relative">{{ error }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            label: { type: String, default: null },
            value: { default: null },
            error: { type: [Object, String], default: null },
            isLabelFixed: { type: Boolean, default: true },
            isErrorLower: { type: Boolean, default: true },
            isDisabled: { type: Boolean, default: false },
            isFocused: { type: Boolean, default: false },
            isInvalid: { type: Boolean, default: false },
        },

        computed: {
            fieldClass() {
                return {
                    'text-left': true,
                }
            },

            labelClass() {
                const isLabelSmall =
                    this.isLabelFixed || (!this.isLabelFixed && this.isFocused) || !!this.value

                return {
                    'block': true,
                    'h-15': true,
                    'transition-all': true,
                    'bg-white': true,
                    'relative': true,
                    'transform': true,
                    'text-gray-4': true,
                    'font-medium': true,
                    'top-0': isLabelSmall,
                    'text-14': isLabelSmall,
                    'text-18': !isLabelSmall,
                    'top-25': !isLabelSmall,
                    'pt-2': !isLabelSmall,
                    'pointer-events-none': !isLabelSmall,
                }
            },

            inputClass() {
                return {
                    'h-full': true,
                    'mt-10': this.isLabelFixed && this.label,
                    'mb-20': this.isErrorLower && this.error,
                }
            },
        },
    }
</script>
