<template>
    <RouterLink v-if="route" :to="route" :class="linkClass">{{ label }} &rarr;</RouterLink>

    <a v-else :href="href" :class="linkClass">{{ label }} &rarr;</a>
</template>

<script>
    export default {
        props: {
            label: { type: String, required: true },
            route: { type: [Object, String], default: null },
            href: { type: String, default: null },
            isInverted: { type: Boolean, default: false },
        },

        computed: {
            linkClass() {
                return {
                    'block': true,
                    'text-purple': !this.isInverted,
                    'text-white': this.isInverted,
                    'font-bold': true,
                    'hoverable:hover:underline': true,
                }
            },
        },
    }
</script>
