<template>
    <LayoutBase :back-button="backButton">
        <div class="flex-grow flex flex-col items-center justify-center space-y-50">
            <BaseHeaderSection
                :heading="$t('common.errors.not-found.heading')"
                :subheading="$t('common.errors.not-found.subheading')"
            />

            <Picture404 class="h-170 -ml-70" />

            <BaseButton :label="$t('common.go-back')" :route="backButton" :is-full-width="false" />
        </div>
    </LayoutBase>
</template>

<script>
    import LayoutBase from '@/components/layouts/LayoutBase'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseButton from '@/components/base/BaseButton'
    import Picture404 from '@/assets/vectors/picture-404.svg'

    export default {
        components: {
            LayoutBase,
            BaseHeaderSection,
            BaseButton,
            Picture404,
        },

        computed: {
            backButton() {
                return this.$auth.isLoggedIn() ? '/auth/detect' : '/'
            },
        },
    }
</script>
