<template>
    <LayoutOverlay back-button="/pro/profile">
        <header class="sticky z-layout top-70 bg-white">
            <BaseHeading
                :heading="$t('pro.profile.licenses.your-licenses')"
                :level="5"
                class="text-center py-30"
            />
        </header>

        <div class="pb-20">
            <div v-for="(license, index) in licenses" :key="index" :class="licenseClass(index)">
                <ProProfileLicensesLicense :license="license" />
            </div>
        </div>

        <FormButtons class="-shadow-md">
            <ProProfileAdd
                :label="$t('pro.profile.licenses.add-another')"
                route="/pro/profile/licenses/add"
            />
        </FormButtons>
    </LayoutOverlay>
</template>

<script>
    import ApiProLicenses from '@/apis/ApiProLicenses'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import BaseHeading from '@/components/base/BaseHeading'
    import ProProfileAdd from '@/components/pro/profile/ProProfileAdd'
    import FormButtons from '@/components/form-elements/FormButtons'
    import ProProfileLicensesLicense from '@/components/pro/profile/licenses/ProProfileLicensesLicense'

    export default {
        components: {
            LayoutOverlay,
            BaseHeading,
            ProProfileAdd,
            FormButtons,
            ProProfileLicensesLicense,
        },

        async loadData({ $router, $auth }) {
            // TODO: Replace with loading licenses to global session store.
            const licenses = await ApiProLicenses.get($auth.getUser().id)

            if (licenses.length === 0) {
                $router.push('/pro/profile/licenses/add')
            }

            // TODO: Remove when we support adding multiple licenses.
            $router.push(`/pro/profile/licenses/${licenses?.[0].id}/edit`)

            return { licenses }
        },

        methods: {
            addOnClass(index) {
                return {
                    'mt-30': index > 0,
                    'pt-30': index > 0,
                    'border-t-1': index > 0,
                    'border-gray-5': index > 0,
                }
            },
        },
    }
</script>
