<template>
    <div class="flex items-center space-x-15 text-16 font-bold">
        <div class="relative flex items-center justify-center h-35 w-35">
            <IconCalendarDay class="absolute inset-0" />
            <p class="pt-10">{{ formattedDay }}</p>
        </div>
        <p class="pt-7">
            <slot>{{ formattedDatetime }}</slot>
        </p>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import IconCalendarDay from '@/assets/vectors/icon-calendar-day.svg'

    export default {
        components: {
            IconCalendarDay,
        },

        props: {
            datetime: { type: [Object, String], required: true },
        },

        computed: {
            formattedDay() {
                return dayjs(this.datetime).format('D')
            },

            formattedDatetime() {
                return this.$t('common.datetime-format', {
                    date: dayjs(this.datetime).format('MMMM YYYY'),
                    time: this.datetime.format('H:mm A'),
                })
            },
        },
    }
</script>
