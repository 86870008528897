<template>
    <ProSection>
        <FormWrapper @submit="goToNextStep">
            <FormFields>
                <FormLegend :label="$t('pro.profile.experiences.category.choose-category')" />

                <InputChoices
                    :options="categoryOptions"
                    :value="[formData.category]"
                    @select="selectCategory"
                />
            </FormFields>

            <TransitionSlide from="bottom">
                <FormButtons v-if="isSubmittable">
                    <BaseButton :label="$t('common.next')" />
                </FormButtons>
            </TransitionSlide>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import { getCategoryIcon } from '@/helpers'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormLegend from '@/components/form-elements/FormLegend'
    import FormButtons from '@/components/form-elements/FormButtons'
    import InputChoices from '@/components/form-inputs/InputChoices'
    import BaseButton from '@/components/base/BaseButton'
    import TransitionSlide from '@/components/transitions/TransitionSlide'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormFields,
            FormLegend,
            FormButtons,
            InputChoices,
            BaseButton,
            TransitionSlide,
        },

        mixins: [MixinFormStep],

        computed: {
            isSubmittable() {
                return !!this.formData.category
            },

            proCategories() {
                return (this.$auth.getUser().subCategories || []).map(({ category }) => category.id)
            },

            categoryOptions() {
                return this.$store.getters['dictionaries/getCategories']
                    .filter((category) => !this.proCategories.includes(category.id))
                    .map((category) => ({
                        value: category.id,
                        label: category.name,
                        icon: getCategoryIcon(category),
                    }))
            },
        },

        methods: {
            selectCategory({ value }) {
                this.change({ path: 'category', value })
            },
        },
    }
</script>
