import store from '@/store'
import ServiceAuth from '@/services/ServiceAuth'
import ApiDictionaries from '@/apis/ApiDictionaries'
import ApiProAppointments from '@/apis/ApiProAppointments'

export default function(to, from, next) {
    ServiceAuth.loadUser(true).then(() => {
        const isLoadingCategories = !store.getters['dictionaries/getCategories']
        const isLoadingStates = !store.getters['dictionaries/getCategories']
        const isLoadingServiceFee =
            ServiceAuth.isLoggedIn() && !store.getters['dictionaries/getServiceFee']
        const isLoadingUnreadAppointments = ServiceAuth.isPro()

        Promise.all([
            isLoadingCategories ? ApiDictionaries.getCategories() : null,
            isLoadingStates ? ApiDictionaries.getStates() : null,
            isLoadingServiceFee ? ApiDictionaries.getServiceFee() : null,
            isLoadingUnreadAppointments
                ? ApiProAppointments.get(ServiceAuth.getUser(), { unread: true })
                : null,
        ])
            .then((responses) => {
                isLoadingCategories && store.commit('dictionaries/setCategories', responses[0])
                isLoadingStates && store.commit('dictionaries/setStates', responses[1])
                isLoadingServiceFee && store.commit('dictionaries/setServiceFee', responses[2])
                isLoadingUnreadAppointments && store.commit('appointments/setUnread', responses[3])
            })
            .then(next)
    })
}
