<template>
    <div :class="choiceClass">
        <div class="relative">
            <div :class="selectionClass" />
            <component :is="icon" :class="iconClass" />
        </div>
        <BaseLabel v-if="label" :content="label" class="relative mt-10" />
    </div>
</template>

<script>
    import BaseLabel from '@/components/base/BaseLabel'

    export default {
        components: {
            BaseLabel,
        },

        props: {
            icon: { type: Object, required: true },
            label: { type: String, default: null },
            level: { type: Number, required: true },
            isSelected: { type: Boolean, default: null },
        },

        computed: {
            choiceClass() {
                return {
                    'relative': true,
                    'w-full': true,
                    'flex': true,
                    'flex-col': true,
                    'text-center': true,
                    'items-center': true,
                    'justify-center': true,
                    'pt-15': true,
                }
            },

            selectionClass() {
                return {
                    'absolute': true,
                    'transform': true,
                    '-inset-15': true,
                    'bg-gold-light-3': true,
                    'rounded-full': true,
                    'transition': true,
                    'opacity-0': !this.isSelected,
                    'scale-75': !this.isSelected,
                    'opacity-100': this.isSelected,
                    'scale-100': this.isSelected,
                }
            },

            iconClass() {
                return {
                    'relative': true,
                    'w-50': this.level === 1,
                    'h-50': this.level === 1,
                    'w-40': this.level === 2,
                    'h-40': this.level === 2,
                    'w-30': this.level === 3,
                    'h-30': this.level === 3,
                }
            },
        },
    }
</script>
