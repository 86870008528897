export const parseRoutes = (routesToParse, parentRoute) =>
    routesToParse.reduce(
        (routes, route) =>
            routes.concat([
                {
                    path: route.path || '',
                    component: route.view && require(`@/pages/${route.view}.vue`).default,
                    redirect: route.redirect,
                    children: parseRoutes(route.children || [], route),
                    beforeEnter: route.beforeEnter,
                    meta: {
                        default: route.default,
                        middlewares: [].concat(
                            parentRoute?.middlewares || [],
                            route.middlewares || [],
                        ),
                    },
                },
            ]),
        [],
    )

export const applyDefaults = (route) => {
    const matchedRoute = route.matched[route.matched.length - 1]

    if (matchedRoute && matchedRoute.meta.default) {
        const defaults = matchedRoute.meta.default
        const items = ['params', 'query']

        items.forEach((name) => {
            if (!defaults[name]) {
                return
            }

            Object.keys(defaults[name]).forEach((key) => {
                route[name][key] = route[name][key] || defaults[name][key]
            })
        })
    }

    return route
}

export const runMiddlewares = (middlewares, { to, from, next }) => {
    const runMiddleware = (middlewares, index) => {
        const currentMiddleware = middlewares?.[index]

        return currentMiddleware
            ? () =>
                  currentMiddleware(to, from, (route) => {
                      route ? next(route) : runMiddleware(middlewares, index + 1)()
                  })
            : next
    }

    return runMiddleware(middlewares, 0)()
}
