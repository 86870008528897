<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="$emit('close')">
        <div class="px-20 pt-30 pb-15 space-y-30">
            <BaseHeading
                :heading="$t('pro.appointments.finish.charge.discount.apply')"
                :level="4"
                class="text-center"
            />

            <template v-if="isQuantityOpen">
                <BaseRow
                    :label="$t('pro.appointments.finish.charge.discount.quantity')"
                    :is-value-auto="true"
                >
                    <div
                        v-if="formData.discountType === 'percent'"
                        class="flex items-center space-x-5 text-24"
                    >
                        <FieldText
                            :has-autofocus="true"
                            :form-data="formData"
                            value-path="discountAmount"
                            class="w-70 text-right ml-auto"
                            @change="change"
                        />
                        <span>%</span>
                    </div>

                    <FieldAmount
                        v-else
                        :has-autofocus="true"
                        :form-data="formData"
                        value-path="discountAmount"
                        class="w-70 text-right ml-auto"
                        @change="change"
                    />
                </BaseRow>
            </template>

            <FieldRadio
                v-else
                :form-data="formData"
                value-path="discountType"
                :options="discountTypes"
                @change="changeType"
            />

            <BaseButton :label="buttonLabel" :is-disabled="!isSubmittable" @click="handleSubmit" />
        </div>
    </BaseDrawer>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import FieldRadio from '@/components/form-fields/FieldRadio'
    import FieldText from '@/components/form-fields/FieldText'
    import FieldAmount from '@/components/form-fields/FieldAmount'
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import BaseRow from '@/components/base/BaseRow'

    export default {
        components: {
            FieldRadio,
            FieldText,
            FieldAmount,
            BaseDrawer,
            BaseHeading,
            BaseButton,
            BaseRow,
        },

        mixins: [MixinForm],

        props: {
            discountData: { type: Object, required: true },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['save', 'close'],

        data() {
            return {
                isQuantityOpen: false,
            }
        },

        computed: {
            isSubmittable() {
                const areFieldsFilled = this.isQuantityOpen
                    ? !!this.formData.discountAmount
                    : !!this.formData.discountType
                const isAmountCorrect =
                    this.formData.discountType === 'flat'
                        ? this.formData.discountAmount >= 0
                        : this.formData.discountAmount >= 0 && this.formData.discountAmount <= 100

                return areFieldsFilled && isAmountCorrect
            },

            buttonLabel() {
                return this.isQuantityOpen ? this.$t('common.apply') : this.$t('common.continue')
            },

            discountTypes() {
                return [
                    {
                        label: this.$t('pro.appointments.finish.charge.discount.flat'),
                        value: 'flat',
                    },
                    {
                        label: this.$t('pro.appointments.finish.charge.discount.percentage'),
                        value: 'percent',
                    },
                ]
            },
        },

        watch: {
            isOpen(isOpen) {
                if (isOpen) {
                    this.initForm(this.discountData)
                    this.isQuantityOpen = false
                }
            },
        },

        methods: {
            changeType(payload) {
                this.change(payload)
                this.change({ path: 'discountAmount', value: null })
            },

            handleSubmit() {
                this.isQuantityOpen
                    ? this.$emit('save', this.formData)
                    : (this.isQuantityOpen = true)
            },
        },
    }
</script>
