<template>
    <div v-if="isEnabled && method" class="flex w-full mb-30">
        <span class="text-14">{{ paymentMethod }}</span>

        <span class="h-20 flex items-center px-5 bg-gray-6 rounded-sm ml-5">
            <span class="uppercase text-10 font-bold leading-none">
                USD
            </span>
        </span>

        <span class="text-16 font-medium ml-auto">
            {{ paymentDetails }}
        </span>
    </div>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'

    export default {
        data() {
            return {
                method: null,
            }
        },

        computed: {
            isEnabled() {
                return this.$auth.getUser().stripeAccountStatus === 'Enabled'
            },

            paymentMethod() {
                return {
                    card: this.$t('pro.profile.stripe.card'),
                    bank_account: this.$t('pro.profile.stripe.wire'),
                }[this.method?.type]
            },

            paymentDetails() {
                return {
                    card: `•••• •••• •••• ${this.method?.last4}`,
                    bank_account: `•••• … ${this.method?.last4}`,
                }[this.method?.type]
            },
        },

        mounted() {
            this.loadPaymentMethod()
        },

        methods: {
            loadPaymentMethod() {
                if (!this.isEnabled) {
                    return
                }

                ApiPros.getStripePayoutMethod(this.$auth.getUser().id).then(
                    (method) => (this.method = method),
                )
            },
        },
    }
</script>
