<template>
    <div class="flex space-x-10 items-center">
        <InputText
            :placeholder="$t('pro.clients.index.search.placeholder')"
            type="search"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @change="triggerSearch"
        >
            <template #suffix>
                <IconSearch class="h-30 w-30 ml-10 flex-shrink-0" />
            </template>
        </InputText>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce'
    import InputText from '@/components/form-inputs/InputText'
    import IconSearch from '@/assets/vectors/icon-search.svg'

    export default {
        components: {
            InputText,
            IconSearch,
        },

        props: {
            minQueryLength: { type: Number, default: 2 },
        },

        emits: ['search'],

        data() {
            return {
                query: null,
                isFocused: false,
            }
        },

        computed: {
            buttonClass() {
                return {
                    'absolute': true,
                    'transition-all': true,
                    'transform': true,
                    '-translate-y-6/12': true,
                    'top-6/12': true,
                    'left-6/12': !this.isFocused,
                    'text-gray-5': !this.isFocused,
                    'pointer-events-none': !this.isFocused,
                    '-translate-x-6/12': !this.isFocused,
                    '-translate-x-full': this.isFocused,
                    'left-full': this.isFocused,
                }
            },
        },

        methods: {
            triggerSearch: debounce(function(query) {
                if (query.length > 0 && query.length < this.minQueryLength) {
                    return
                }

                this.$emit('search', query)
            }, 250),
        },
    }
</script>
