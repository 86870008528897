import ProTile from '@/components/pro/ProTile'
import ProGallery from '@/components/pro/ProGallery'
import ProTestimonials from '@/components/pro/ProTestimonials'
import ProLocations from '@/components/pro/ProLocations'
import ProOffering from '@/components/pro/ProOffering'
import ProPolicies from '@/components/pro/ProPolicies'
import ProLicense from '@/components/pro/ProLicense'
import BaseMapAddress from '@/components/base/BaseMapAddress'

export default {
    components: {
        ProTile,
        ProGallery,
        ProTestimonials,
        ProLocations,
        ProOffering,
        ProPolicies,
        ProLicense,
        BaseMapAddress,
    },

    computed: {
        brandName() {
            return this.pro.brandName || `${this.pro.firstName} ${this.pro.lastName}`
        },

        portfolio() {
            return this.pro.portfolio || []
        },

        testimonials() {
            return this.pro.ratings || []
        },
    },
}
