<template>
    <LayoutPro back-button="/auth/detect">
        <BaseHeaderSection
            :heading="$t('pro.calendar.heading', { name: $auth.getUser().firstName })"
            :subheading="$t(`pro.calendar.subheading.${range}`)"
            class="mb-20"
        />

        <ProCalendarSwitch :date="today" :range="range" class="mb-20" @switch-range="changeRange" />

        <component
            :is="rangeComponent"
            :appointments="appointments"
            :events="events"
            :date="today"
            @click-slot="openEventPanel"
            @click-event="openEventPanel"
        />
    </LayoutPro>

    <ProAppointmentsUnread
        :is-open="isUnreadPanelOpen"
        :appointments="unreadAppointments"
        @close="isUnreadPanelOpen = false"
    />

    <ProCalendarEventForm
        :is-open="isEventPanelOpen"
        :appointments="appointments"
        :event-data="eventData"
        @save="closeEventPanelAndReloadEvents"
        @close="isEventPanelOpen = false"
    />
</template>

<script>
    import dayjs from 'dayjs'
    import MixinProAppointmentsUnread from '@/mixins/MixinProAppointmentsUnread'
    import ApiProAppointments from '@/apis/ApiProAppointments'
    import ApiProEvents from '@/apis/ApiProEvents'
    import LayoutPro from '@/components/layouts/LayoutPro'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import ProCalendarSwitch from '@/components/pro/calendar/ProCalendarSwitch'
    import ProCalendarDay from '@/components/pro/calendar/ProCalendarDay'
    import ProCalendarWeek from '@/components/pro/calendar/ProCalendarWeek'
    import ProCalendarEventForm from '@/components/pro/calendar/ProCalendarEventForm'

    const generateRangeDates = (range) => {
        return {
            startDate: dayjs(),
            endDate: range === 'day' ? dayjs() : dayjs().add(7, 'days'),
        }
    }

    export default {
        components: {
            LayoutPro,
            BaseHeaderSection,
            ProCalendarSwitch,
            ProCalendarEventForm,
        },

        mixins: [MixinProAppointmentsUnread],

        data() {
            return {
                isEventPanelOpen: false,
                range: 'day',
                eventData: null,
            }
        },

        async loadData({ $auth, range }) {
            const appointments = await ApiProAppointments.get(
                $auth.getUser(),
                generateRangeDates(range),
            )
            const events = await ApiProEvents.get($auth.getUser().id, generateRangeDates(range))

            return { appointments, events }
        },

        computed: {
            today() {
                return dayjs()
            },

            rangeComponent() {
                return {
                    day: ProCalendarDay,
                    week: ProCalendarWeek,
                }[this.range]
            },
        },

        watch: {
            range() {
                this.loadAppointments()
                this.loadEvents()
            },
        },

        methods: {
            loadAppointments() {
                ApiProAppointments.get(this.$auth.getUser(), generateRangeDates(this.range)).then(
                    (appointments) => (this.appointments = appointments),
                )
            },

            loadEvents() {
                ApiProEvents.get(this.$auth.getUser().id, generateRangeDates(this.range)).then(
                    (events) => (this.events = events),
                )
            },

            changeRange(range) {
                this.range = range
            },

            openEventPanel(data) {
                this.eventData = data
                this.isEventPanelOpen = true
            },

            closeEventPanelAndReloadEvents() {
                this.isEventPanelOpen = false
                setTimeout(this.loadEvents, 500)
            },
        },
    }
</script>
