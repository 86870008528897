<template>
    <LayoutApp back-button="/guest/appointments/previous">
        <div class="pt-20 flex-grow flex flex-col">
            <component
                :is="currentStep.component"
                :appointment="appointment"
                :step-data="formData"
                @change="changeData"
                @go-to-start="goToStart"
                @go-to="goToStep"
                @go-to-next="goToNextStep"
            />
        </div>
    </LayoutApp>
</template>

<script>
    import * as HelperAppointment from '@/helpers/appointment'
    import ApiGuestAppointments from '@/apis/ApiGuestAppointments'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import GuestAppointmentsRatePaid1 from '@/components/guest/appointments/rate/GuestAppointmentsRatePaid1'
    import GuestAppointmentsRatePaid2 from '@/components/guest/appointments/rate/GuestAppointmentsRatePaid2'
    import GuestAppointmentsRatePaid3 from '@/components/guest/appointments/rate/GuestAppointmentsRatePaid3'
    import GuestAppointmentsRatePaid4 from '@/components/guest/appointments/rate/GuestAppointmentsRatePaid4'
    import GuestAppointmentsRateUnpaid1 from '@/components/guest/appointments/rate/GuestAppointmentsRateUnpaid1'
    import GuestAppointmentsRateUnpaid2 from '@/components/guest/appointments/rate/GuestAppointmentsRateUnpaid2'
    import GuestAppointmentsRateUnpaid3 from '@/components/guest/appointments/rate/GuestAppointmentsRateUnpaid3'
    import GuestAppointmentsRateUnpaid4 from '@/components/guest/appointments/rate/GuestAppointmentsRateUnpaid4'
    import GuestAppointmentsRateUnpaid5 from '@/components/guest/appointments/rate/GuestAppointmentsRateUnpaid5'

    export default {
        components: {
            LayoutApp,
        },

        mixins: [MixinFormSteppable, MixinAppointment],

        data() {
            return {
                step: 1,
            }
        },

        async loadData({ $auth, $route, $router }) {
            const appointment = await ApiGuestAppointments.getOne($auth.getUser(), $route.params.id)

            if (!HelperAppointment.isRatable(appointment)) {
                $router.push(`/guest/appointments/${$route.params.id}`)
            }

            return { appointment }
        },

        computed: {
            steps() {
                if (this.isPaid) {
                    return [
                        { component: GuestAppointmentsRatePaid1 },
                        { component: GuestAppointmentsRatePaid2 },
                        { component: GuestAppointmentsRatePaid3 },
                        { component: GuestAppointmentsRatePaid4 },
                    ]
                }

                return [
                    { component: GuestAppointmentsRateUnpaid1 },
                    { component: GuestAppointmentsRateUnpaid2 },
                    { component: GuestAppointmentsRateUnpaid3 },
                    { component: GuestAppointmentsRateUnpaid4 },
                    { component: GuestAppointmentsRateUnpaid5 },
                ]
            },
        },
    }
</script>
