<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="$emit('close')">
        <BaseLinks :items="links" class="uppercase text-14 px-20 py-15" @click-item="clickItem" />
    </BaseDrawer>
</template>

<script>
    import MixinAppointment from '@/mixins/MixinAppointment'
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseLinks from '@/components/base/BaseLinks'

    export default {
        components: {
            BaseDrawer,
            BaseLinks,
        },

        mixins: [MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['close'],

        computed: {
            googleMapsLink() {
                const query = [
                    this.appointmentAddress.address,
                    this.appointmentAddress.zipCode,
                    this.appointmentAddress.city,
                    this.appointmentAddress.state,
                ].join(' ')

                return `http://maps.google.com/?q=${query}`
            },

            appleMapsLink() {
                const query = [
                    this.appointmentAddress.address,
                    this.appointmentAddress.zipCode,
                    this.appointmentAddress.city,
                    this.appointmentAddress.state,
                ].join(' ')

                return `http://maps.apple.com/?q=${query}`
            },

            links() {
                const guest = this.appointment?.guest

                return [
                    ...(guest.phone
                        ? [
                              {
                                  label: this.$t('pro.appointments.show.call-x', {
                                      x: guest.firstName,
                                  }),
                                  href: `tel:${guest.phone}`,
                              },
                          ]
                        : []),
                    {
                        label: this.$t('pro.appointments.show.view-google-maps'),
                        href: this.googleMapsLink,
                    },
                    {
                        label: this.$t('pro.appointments.show.view-apple-maps'),
                        href: this.appleMapsLink,
                    },
                    { label: this.$t('common.close'), event: 'close' },
                ]
            },
        },

        methods: {
            clickItem({ event }) {
                event && this.$emit(event)
            },
        },
    }
</script>
