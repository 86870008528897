<template>
    <ProSection :heading="$t('pro.profile.availability.heading.edit')">
        <FormWrapper class="flex-grow" @submit="save">
            <div class="space-y-20">
                <ProProfileAvailabilityDay
                    v-for="(availability, index) in formData.availabilities"
                    :key="index"
                    :availability="availability"
                    class="relative"
                    :has-labels="index === 0"
                >
                    <template v-if="!isProvided(index)" #startTime>
                        <FieldSelect
                            :placeholder="$t('pro.profile.availability.set.start')"
                            :options="timeOptionsTo(index)"
                            :form-data="formData"
                            :form-errors="formErrors"
                            :value-path="`availabilities.${index}.startTime`"
                            class="w-full"
                            @change="change"
                        />
                    </template>

                    <template v-if="!isProvided(index)" #endTime>
                        <FieldSelect
                            :placeholder="$t('pro.profile.availability.set.end')"
                            :options="timeOptionsFrom(index)"
                            :form-data="formData"
                            :form-errors="formErrors"
                            :value-path="`availabilities.${index}.endTime`"
                            class="w-full"
                            @change="change"
                        />
                    </template>

                    <template v-if="isProvided(index)" #end>
                        <button
                            type="button"
                            class="absolute right-0 my-10 h-30 w-30 flex items-center justify-center self-end"
                            @click="clearWeekday(index)"
                        >
                            <IconDelete class="h-18 w-18" />
                        </button>
                    </template>
                </ProProfileAvailabilityDay>
            </div>

            <TransitionSlide from="bottom">
                <FormButtons v-if="isDirty && isSubmittable">
                    <BaseButton :label="$t('common.save')" :is-processing="isSubmitting" />
                </FormButtons>
            </TransitionSlide>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import dayjs from 'dayjs'
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import ProProfileAvailabilityDay from '@/components/pro/profile/availability//ProProfileAvailabilityDay'
    import TransitionSlide from '@/components/transitions/TransitionSlide'
    import FieldSelect from '@/components/form-fields/FieldSelect'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'
    import IconDelete from '@/assets/vectors/icon-delete.svg'

    // Number of hours * 15 minute slots (4 in hour.)
    const timeOptions = Array(24 * 4)
        .fill(1)
        .map((value, index) => {
            const time = dayjs()
                .hour(parseInt(index / 4))
                .minute((index % 4) * 15)

            return {
                label: time.format('hh:mm A'),
                value: time.format('HH:mm'),
            }
        })

    export default {
        components: {
            ProProfileAvailabilityDay,
            TransitionSlide,
            FieldSelect,
            BaseButton,
            FormWrapper,
            FormButtons,
            IconDelete,
        },

        mixins: [MixinProProfileSection],

        props: {
            availabilities: { type: Array, required: true },
        },

        emits: ['save'],

        computed: {
            stopProcessingAfterSubmitted() {
                return true
            },

            isSubmittable() {
                return !(this.formData.availabilities || []).some((availability) => {
                    const areIncomplete =
                        (availability?.startTime && !availability?.endTime) ||
                        (!availability?.startTime && availability?.endTime)

                    const areIncorrect =
                        availability?.startTime &&
                        availability?.endTime &&
                        availability?.startTime >= availability?.endTime

                    return areIncomplete || areIncorrect
                })
            },
        },

        created() {
            this.initForm({
                availabilities: Array(7)
                    .fill(1)
                    .map(
                        (value, index) =>
                            this.availabilities.find(({ dayOfWeek }) => dayOfWeek === index) || {
                                dayOfWeek: index,
                            },
                    ),
            })
        },

        methods: {
            timeOptionsTo(dayOfWeek) {
                const endTime = this.formData?.availabilities?.[dayOfWeek]?.endTime
                const index = timeOptions.findIndex(({ value }) => value === endTime)

                return index !== -1 ? timeOptions.slice(0, index) : timeOptions
            },

            timeOptionsFrom(dayOfWeek) {
                const startTime = this.formData?.availabilities?.[dayOfWeek]?.startTime
                const index = timeOptions.findIndex(({ value }) => value === startTime)

                return index !== -1 ? timeOptions.slice(index + 1) : timeOptions
            },

            isProvided(index) {
                return (
                    this.formData.availabilities[index]?.startTime &&
                    this.formData.availabilities[index]?.endTime
                )
            },

            clearWeekday(index) {
                this.change({ path: `availabilities.${index}.startTime`, value: null })
                this.change({ path: `availabilities.${index}.endTime`, value: null })
            },

            save() {
                return this.handleSubmit(
                    ApiPros.update(this.$auth.getUser().id, 'availabilities', {
                        availabilities: this.formData.availabilities.filter(
                            ({ startTime, endTime }) => startTime && endTime,
                        ),
                    }),
                ).then((user) => {
                    this.$store.commit('auth/setUser', user)
                    this.$emit('save')
                })
            },
        },
    }
</script>
