import { createI18n } from 'vue-i18n'
import set from 'lodash/set'

export default createI18n({
    locale: 'en',
    messages: require
        .context('@/i18n/locales/', true, /^\.\/.*\.js$/)
        .keys()
        .reduce((messages, file) => {
            const locale = file.replace('./', '').replace('.js', '')
            set(messages, locale.split('/'), require(`@/i18n/locales/${locale}`).default)
            return messages
        }, {}),
})
