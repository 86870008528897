export default {
    namespaced: true,

    state: {
        globalErrorCode: null,
    },

    mutations: {
        setGlobalErrorCode(state, error) {
            state.globalErrorCode = error
        },
    },

    getters: {
        getGlobalErrorCode(state) {
            return state.globalErrorCode
        },
    },
}
