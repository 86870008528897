<template>
    <div class="space-y-10 flex flex-col items-center">
        <div class="relative">
            <BaseAvatar :photo="picture" class="h-100 w-100 rounded-full" />
            <BaseButtonEdit
                v-if="isEditable && picture"
                :has-bg="true"
                class="absolute -bottom-10 -right-10 p-10 rounded-full"
                @click="$emit('edit')"
            />
        </div>

        <BaseLink
            v-if="isEditable && !picture"
            :label="$t('pro.clients.show.add-picture')"
            @click="$emit('add')"
        />
    </div>

    <BaseHeading v-if="!isEditable" :heading="fullName" :level="5" class="text-center" />
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseButtonEdit from '@/components/base/BaseButtonEdit'
    import BaseLink from '@/components/base/BaseLink'
    import { composeFullName } from '@/helpers'

    export default {
        components: {
            BaseHeading,
            BaseAvatar,
            BaseButtonEdit,
            BaseLink,
        },

        props: {
            client: { type: Object, required: true },
            isEditable: { type: Boolean, default: false },
        },

        emits: ['add', 'edit'],

        computed: {
            picture() {
                return this.client.profilePicturePreview || this.client.profilePicture
            },

            fullName() {
                return composeFullName(this.client)
            },
        },
    }
</script>
