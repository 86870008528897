<template>
    <nav class="bg-purple safe-bottom">
        <ul class="flex" :class="styles">
            <li v-for="item in items" :key="item.label" class="w-full">
                <RouterLink v-if="item.route" :to="item.route" :class="itemClass(item)">
                    <component :is="iconComponent(item)" class="h-30 mb-5" />
                    <BaseLabel :content="item.label" />
                    <span v-if="item.badge" :class="badgeClass(item)">
                        {{ badgeText(item) }}
                    </span>
                </RouterLink>
                <button v-else :class="itemClass(item)" @click="$emit('click-item', item)">
                    <component :is="iconComponent(item)" class="h-30 mb-5" />
                    <BaseLabel :content="item.label" />
                    <span v-if="item.badge" :class="badgeClass(item)">
                        {{ badgeText(item) }}
                    </span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
    import BaseLabel from '@/components/base/BaseLabel'

    export default {
        components: {
            BaseLabel,
        },

        props: {
            items: { type: Array, required: true },
            styles: { type: String, required: false, default: '' },
        },

        emits: ['click-item'],

        computed: {
            pinnedItems() {
                return this.items.filter(({ isPinned }) => isPinned)
            },
        },

        methods: {
            itemClass({ isSelected }) {
                return {
                    'h-60': true,
                    'pt-4': true,
                    'relative': true,
                    'w-full': true,
                    'flex': true,
                    'flex-col': true,
                    'items-center': true,
                    'justify-center': true,
                    'text-white': !isSelected,
                    'hoverable:hover:text-gold': !isSelected,
                    'hoverable:focus:text-gold': !isSelected,
                    'text-gold': isSelected,
                }
            },

            iconComponent({ isSelected, icons }) {
                return isSelected ? icons.selected : icons.default
            },

            badgeText({ badge }) {
                return badge === true ? null : badge
            },

            badgeClass(item) {
                const hasText = !!this.badgeText(item)

                return {
                    'absolute': true,
                    'left-1/2': true,
                    'bottom-1/2': true,
                    'border-purple': true,
                    'border-3': true,
                    'flex': true,
                    'tracking-tight': true,
                    'items-center': true,
                    'justify-center': true,
                    'rounded-full': true,
                    'bg-notification': true,
                    'text-white': true,
                    'text-11': true,
                    'font-bold': true,
                    'h-23': hasText,
                    'min-w-23': hasText,
                    'px-2': hasText,
                    'mb-2': hasText,
                    'ml-2': hasText,
                    'h-18': !hasText,
                    'w-18': !hasText,
                    'mb-5': !hasText,
                    'ml-5': !hasText,
                }
            },
        },
    }
</script>
