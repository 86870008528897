<template>
    <div class="space-y-30">
        <ProProfileSetting
            v-for="(detail, index) in details"
            :key="index"
            :icon="detail.icon"
            :heading="detail.heading"
            :subheading="detail.subheading"
            :value="detail.value"
            :is-editable="isEditable"
            @edit="$emit('edit', detail.slug)"
        />
    </div>
</template>

<script>
    import ProProfileSetting from '@/components/pro/profile/ProProfileSetting'
    import IconTime from '@/assets/vectors/icon-time.svg'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import IconGuest from '@/assets/vectors/icon-guest.svg'

    export default {
        components: {
            ProProfileSetting,
        },

        props: {
            experience: { type: Object, required: true },
            isEditable: { type: Boolean, default: false },
        },

        emits: ['edit'],

        computed: {
            details() {
                const details = [
                    {
                        icon: IconTime,
                        heading: this.$tc('common.n-minutes', this.experience.duration, {
                            n: this.experience.duration,
                        }),
                        slug: 'duration',
                    },
                ]

                if ((this.experience.workPlace || []).includes('pro')) {
                    details.push({
                        icon: IconPro,
                        heading: this.experience.name,
                        subheading: this.$t('pro.profile.place-names.pro'),
                        value: `$${this.experience.prosPlacePrice}`,
                        slug: 'price-pro',
                    })
                }

                if ((this.experience.workPlace || []).includes('guest')) {
                    details.push({
                        icon: IconGuest,
                        heading: this.experience.name,
                        subheading: this.$t('pro.profile.place-names.guest'),
                        value: `$${this.experience.guestsPlacePrice}`,
                        slug: 'price-guest',
                    })
                }

                const allSubCategories = this.$store.getters['dictionaries/getCategories'].reduce(
                    (subCategories, category) => subCategories.concat(category.subCategories),
                    [],
                )

                const experienceSubCategoryIds =
                    this.experience.subCategoryIds ||
                    this.experience.subCategories.map(({ id }) => id)

                return details.concat([
                    {
                        heading: this.$t(
                            'pro.profile.experiences.experience.steps.10.offered-with',
                        ),
                        subheading: allSubCategories
                            .filter(({ id }) => experienceSubCategoryIds.includes(id))
                            .map(({ name }) => name)
                            .join(', '),
                        slug: 'subcategories',
                    },
                ])
            },
        },
    }
</script>
