<template>
    <ProSection
        :heading="$t('pro.profile.experiences.experience.steps.9.heading', { name: formData.name })"
    >
        <FormWrapper>
            <FormLegend :label="$t('common.choose-one')" class="mb-30" />

            <FormFields class="space-y-60">
                <div v-for="category in proCategories" :key="category.id" class="space-y-30">
                    <BaseChoice
                        :icon="category.icon"
                        :label="category.label"
                        :level="1"
                        :is-selected="true"
                    />

                    <FieldPills
                        :options="subCategoryOptionsFor(category)"
                        :form-data="formData"
                        value-path="subCategoryIds"
                        @change="change"
                    />
                </div>
            </FormFields>

            <slot name="buttons" :is-submittable="isSubmittable">
                <FormButtons>
                    <BaseButton
                        :label="$t('common.save')"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                        @click="save"
                    />
                </FormButtons>
            </slot>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import { getCategoryIcon, getCategoryOptions } from '@/helpers'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormLegend from '@/components/form-elements/FormLegend'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import BaseChoice from '@/components/base/BaseChoice'
    import FieldPills from '@/components/form-fields/FieldPills'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormFields,
            FormLegend,
            FormButtons,
            BaseButton,
            BaseChoice,
            FieldPills,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isSubmittable() {
                return (this.formData.subCategoryIds || []).length > 0
            },

            proSubCategoryIds() {
                return (this.$auth.getUser().subCategories || []).map(({ id }) => id)
            },

            proCategories() {
                return this.$store.getters['dictionaries/getCategories']
                    .map((category) => ({
                        ...category,
                        value: category.id,
                        label: category.name,
                        icon: getCategoryIcon(category),
                        subCategories: category.subCategories.filter(({ id }) =>
                            this.proSubCategoryIds.includes(id),
                        ),
                    }))
                    .filter(({ subCategories }) => subCategories.length > 0)
            },
        },

        methods: {
            subCategoryOptionsFor({ value: id }) {
                return getCategoryOptions(this.proCategories, { id })
            },
        },
    }
</script>
