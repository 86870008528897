<template>
    <GuestBookStep :heading="proName" :has-progress="hasProgress" @submit="openCorrespondingPanel">
        <template #default>
            <article class="space-y-40">
                <ProLocations
                    v-if="areBothWorkPlacesSelected(formData.search.workPlace)"
                    :pro="pro"
                    class="-mb-20"
                />

                <ProTile :pro="pro" :level="1" class="pt-1/12 pb-full" />

                <BaseText v-if="pro.introduction" :content="pro.introduction" />

                <section v-if="licenses.length" class="space-y-30">
                    <BaseHeading
                        :heading="$t('pro.page.x-licenses', { name: pro.firstName })"
                        :level="5"
                    />

                    <div v-for="(license, index) in licenses" :key="index">
                        <ProLicense :license="license" />
                    </div>
                </section>

                <ProOffering
                    :experiences="experiences"
                    :selected-ids="formData.appointment?.experienceIds"
                    :work-place="experiencesWorkPlace"
                    @toggle="handleClickExperience"
                />

                <section v-if="pro.address" class="space-y-20">
                    <BaseHeading
                        :heading="$t('pro.page.x-salon', { name: pro.firstName })"
                        :level="4"
                    />

                    <BaseMapAddress
                        id="salon"
                        :address="pro.address"
                        :is-icon-highlighted="true"
                        class="pb-8/12"
                    >
                        <template #address>
                            <b class="uppercase font-bold">{{ brandName }}</b>
                            <br />
                        </template>
                    </BaseMapAddress>
                </section>

                <section v-if="portfolio.length > 0" class="space-y-20">
                    <BaseHeading
                        :heading="$t('pro.page.x-work', { name: pro.firstName })"
                        :level="4"
                    />

                    <ProGallery :items="portfolio" />
                </section>

                <ProTestimonials v-if="testimonials.length" :testimonials="testimonials" />

                <ProPolicies :pro="pro" />
            </article>
        </template>

        <template v-if="areAnyExperiencesSelected" #bottom>
            <BaseButton :label="bookWithLabel" />
        </template>
    </GuestBookStep>

    <GuestBookStep5Address
        :form-data="formData"
        :appointment="appointment"
        :is-open="isAddressPanelOpen"
        @confirm="saveAddressAndGoNext"
        @close="isAddressPanelOpen = false"
    />

    <GuestBookStep5AddOns
        :appointment="appointment"
        :is-open="isAddOnsPanelOpen"
        @confirm="saveAddOnsAndGoNext"
        @close="isAddOnsPanelOpen = false"
    />

    <GuestBookStep5Place
        :experience="temporaryExperience"
        :is-open="isPlacePanelOpen"
        @confirm="savePlaceAndGoNext"
        @close="isPlacePanelOpen = false"
    />
</template>

<script>
    import { composeFullName } from '@/helpers'
    import intersection from 'lodash/intersection'
    import ApiPros from '@/apis/ApiPros'
    import ApiProLicenses from '@/apis/ApiProLicenses'
    import MixinBookStep from '@/mixins/MixinBookStep'
    import MixinProPreview from '@/mixins/MixinProPreview'
    import BaseButton from '@/components/base/BaseButton'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'
    import GuestBookStep5Address from '@/components/guest/book/step-5/GuestBookStep5Address'
    import GuestBookStep5AddOns from '@/components/guest/book/step-5/GuestBookStep5AddOns'
    import GuestBookStep5Place from '@/components/guest/book/step-5/GuestBookStep5Place'

    export default {
        components: {
            BaseButton,
            BaseHeading,
            BaseText,
            GuestBookStep5Address,
            GuestBookStep5AddOns,
            GuestBookStep5Place,
        },

        mixins: [MixinBookStep, MixinProPreview],

        data() {
            return {
                isAddressPanelOpen: false,
                isAddOnsPanelOpen: false,
                isPlacePanelOpen: false,
                temporaryExperience: null,
                licenses: [],
            }
        },

        computed: {
            pro() {
                return this.appointment.pro
            },

            proName() {
                return composeFullName(this.appointment.pro)
            },

            experiencesWorkPlace() {
                return this.appointment.workPlace
                    ? [this.appointment.workPlace]
                    : this.formData.search.workPlace
            },

            hasAnyAddOnsForSelectedExperiencesAndWorkPlace() {
                const selectedExperienceIds = this.appointment.experiences.map(({ id }) => id)
                const addOnsMeetingRequirements = (this.pro.addOns || [])
                    .filter(({ workPlace }) =>
                        (workPlace || []).includes(this.appointment.workPlace),
                    )
                    .filter(({ experiences }) =>
                        intersection(
                            selectedExperienceIds,
                            experiences.map(({ id }) => id),
                        ),
                    )

                return addOnsMeetingRequirements.length > 0
            },

            experiences() {
                const experiences =
                    this.formData.search.subCategoryIds.length > 0
                        ? this.pro.experiences.filter(({ subCategories }) =>
                              subCategories.some(({ id }) =>
                                  this.formData.search.subCategoryIds.includes(id),
                              ),
                          )
                        : this.pro.experiences

                return experiences.filter(
                    ({ workPlace }) =>
                        intersection(workPlace, this.experiencesWorkPlace).length > 0,
                )
            },

            areAnyExperiencesSelected() {
                return this.appointment.experiences.length > 0
            },

            bookWithLabel() {
                return this.$t('guest.book.who.book-with', {
                    name: this.appointment.pro.firstName,
                })
            },
        },

        mounted() {
            ApiPros.addView(this.appointment.pro.id)

            // TODO: Replace with loading it inside loadData() when we refactor form steps
            // and split each step as separate route.
            ApiProLicenses.get(this.pro.id).then((licenses) => (this.licenses = licenses))
        },

        methods: {
            isExperienceSelected(experience) {
                return (this.formData.appointment?.experienceIds || []).includes(experience.id)
            },

            areBothWorkPlacesSelected(workPlace) {
                return workPlace.includes('pro') && workPlace.includes('guest')
            },

            handleClickExperience(experience) {
                if (
                    !this.areAnyExperiencesSelected &&
                    !this.isExperienceSelected(experience) &&
                    this.areBothWorkPlacesSelected(this.formData.search.workPlace) &&
                    this.areBothWorkPlacesSelected(experience.workPlace)
                ) {
                    this.temporaryExperience = experience
                    this.isPlacePanelOpen = true
                    return
                }

                this.toggleExperience(experience)
            },

            toggleExperience(experience) {
                const experienceIds = this.formData.appointment?.experienceIds || []
                const wasSelected = this.isExperienceSelected(experience)
                const isExperienceEitherPlace = this.areBothWorkPlacesSelected(experience.workPlace)
                const isSearchEitherPalce = this.areBothWorkPlacesSelected(
                    this.formData.search.workPlace,
                )

                if (
                    !wasSelected &&
                    !this.areAnyExperiencesSelected &&
                    (!isExperienceEitherPlace || !isSearchEitherPalce)
                ) {
                    this.change({ path: 'appointment.workPlace', value: experience.workPlace?.[0] })
                }

                this.change({
                    path: 'appointment.experienceIds',
                    value: wasSelected
                        ? experienceIds.filter((id) => id !== experience.id)
                        : [...experienceIds, experience.id],
                })

                if (!this.areAnyExperiencesSelected) {
                    this.change({ path: 'appointment.workPlace', value: undefined })
                }
            },

            openCorrespondingPanel() {
                if (this.hasAnyAddOnsForSelectedExperiencesAndWorkPlace) {
                    return (this.isAddOnsPanelOpen = true)
                }

                this.formData.appointment.workPlace === 'guest'
                    ? (this.isAddressPanelOpen = true)
                    : this.goToNextStep()
            },

            savePlaceAndGoNext(place) {
                this.toggleExperience(this.temporaryExperience)
                this.change({ path: 'appointment.workPlace', value: place })
                this.isPlacePanelOpen = false
            },

            saveAddressAndGoNext(data) {
                Object.keys(data).forEach((path) => this.change({ path, value: data[path] }))
                this.goToNextStep()
            },

            saveAddOnsAndGoNext(value) {
                this.change({ path: 'appointment.addOnIds', value })
                this.isAddOnsPanelOpen = false

                this.formData.appointment.workPlace === 'guest'
                    ? (this.isAddressPanelOpen = true)
                    : this.goToNextStep()
            },
        },
    }
</script>
