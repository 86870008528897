<template>
    <LayoutApp :back-button="backButton" @back="isEditing = false">
        <ProProfileAvailabilityEdit
            v-if="isEditing"
            :availabilities="availabilities"
            @save="isAvailabilitySet && (isEditing = false)"
        />
        <ProProfileAvailabilityPreview
            v-else
            :availabilities="availabilities"
            @edit="isEditing = true"
        />
    </LayoutApp>
</template>

<script>
    import LayoutApp from '@/components/layouts/LayoutApp'
    import ProProfileAvailabilityPreview from '@/components/pro/profile/availability/ProProfileAvailabilityPreview'
    import ProProfileAvailabilityEdit from '@/components/pro/profile/availability/ProProfileAvailabilityEdit'

    export default {
        components: {
            LayoutApp,
            ProProfileAvailabilityPreview,
            ProProfileAvailabilityEdit,
        },

        data() {
            return {
                isEditing: false,
            }
        },

        computed: {
            availabilities() {
                return (this.$auth.getUser().availabilities || []).sort(
                    ({ dayOfWeek: a }, { dayOfWeek: b }) => a - b,
                )
            },

            isAvailabilitySet() {
                return (this.availabilities || []).length > 0
            },

            backButton() {
                return this.isEditing && this.isAvailabilitySet ? true : '/pro/profile'
            },
        },

        mounted() {
            this.isEditing = !this.isAvailabilitySet
        },
    }
</script>
