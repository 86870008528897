<template>
    <div :class="messageClass">
        <div :class="contentClass">
            <slot name="icon">
                <component :is="icon" v-if="icon" class="h-35 w-35" />
            </slot>

            <BaseText :content="message" class="text-black">
                <slot />
            </BaseText>
        </div>

        <div v-if="$slots.cta" class="pb-10">
            <slot name="cta" />
        </div>
    </div>
</template>

<script>
    import BaseText from '@/components/base/BaseText'

    export default {
        components: {
            BaseText,
        },

        props: {
            icon: { type: Object, default: null },
            message: { type: String, default: null },
            type: { type: String, required: true },
        },

        computed: {
            messageClass() {
                return {
                    'relative': true,
                    'px-15': true,
                    'bg-white': true,
                    'border-l-4': true,
                    'border-current': true,
                    'text-green': this.type === 'success',
                    'text-orange': this.type === 'warning',
                    'text-red': this.type === 'error',
                }
            },

            contentClass() {
                return {
                    'flex': true,
                    'items-center': true,
                    'space-x-10': true,
                    'py-10': !this.icon,
                    'py-15': this.icon,
                }
            },
        },
    }
</script>
