<template>
    <ProSection :heading="$t('pro.profile.addons.steps.1.heading')">
        <FormWrapper>
            <FieldText
                :placeholder="$t('pro.profile.addons.steps.1.placeholder')"
                :has-autofocus="true"
                :form-data="formData"
                :form-errors="formErrors"
                value-path="name"
                @change="change"
            />

            <slot name="buttons" :is-submittable="isSubmittable" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FieldText from '@/components/form-fields/FieldText'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FieldText,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isSubmittable() {
                return !!this.formData.name
            },
        },
    }
</script>
