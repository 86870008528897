import axios from 'axios'
import transformProToApi from '@/apis/transforms/transformProToApi'
import transformProFromApi from '@/apis/transforms/transformProFromApi'
import transformProSearchToApi from '@/apis/transforms/transformProSearchToApi'
import transformProAvailabilityToApi from '@/apis/transforms/transformProAvailabilityToApi'

const transforms = {
    transformRequest: [transformProToApi, ...axios.defaults.transformRequest],
    transformResponse: [...axios.defaults.transformResponse, transformProFromApi],
}

export default {
    getInvitation(token) {
        return axios.get('/auth/getStylistInvitationTokenData', { params: { token } })
    },

    register(data) {
        return axios.post('/stylists', data, transforms)
    },

    get(id) {
        return axios.get(`/stylists/${id}`, transforms)
    },

    getByUsername(username) {
        return axios.get(`/stylists/userName/${username}`, transforms)
    },

    onboard(id, data) {
        return axios.patch(`/stylists/${id}`, data, transforms)
    },

    update(id, section, data) {
        return axios.patch(`/stylists/${id}/${section}`, data, transforms)
    },

    updateCancellationPolicy(id, data) {
        return axios.post(`/stylists/${id}/cancellationPolicy`, data, transforms)
    },

    updateProfilePicture(id, data) {
        return axios.post(`/stylists/${id}/profilePicture`, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            transformResponse: transforms.transformResponse,
        })
    },

    search(data) {
        return axios.post('/search/stylistByZipCode', data, {
            ...transforms,
            transformRequest: [transformProSearchToApi, ...axios.defaults.transformRequest],
        })
    },

    searchByName(name) {
        return axios.post('/search/stylistByName', { name })
    },

    getMonthAvailability(id, data) {
        return axios.post(`/stylists/${id}/availabilityPerMonth`, data, {
            transformRequest: [transformProAvailabilityToApi, ...axios.defaults.transformRequest],
        })
    },

    getAvailabilitySlots(id, data) {
        return axios.post(`/stylists/${id}/availableTimeSlots`, data, {
            transformRequest: [transformProAvailabilityToApi, ...axios.defaults.transformRequest],
        })
    },

    getStripePayoutMethod(id) {
        return axios.get(`/stylists/${id}/payoutMethod`)
    },

    getStripeOnboardingLink(id) {
        return axios.post(`/stylists/${id}/paymentInfo`)
    },

    getStripeLoginLink(id) {
        return axios.get(`/stylists/${id}/stripeLoginLink`)
    },

    getStats(id, params) {
        return axios.get(`/stylists/${id}/statistics`, { params })
    },

    addView(id) {
        return axios.post(`/stylists/${id}/profileViews`)
    },
}
