<template>
    <div v-if="inProsPlace" class="flex-grow flex flex-col space-y-30">
        <BaseText
            :content="
                $t('guest.appointments.rate.no-show-note', { name: appointment.pro.firstName })
            "
            class="text-center"
        />

        <div class="space-y-10">
            <BaseHeaderDivider :heading="$t('guest.appointments.rate.payment-details')" />
            <BaseReceipt :positions="noShowPrices(true)" />
        </div>

        <GuestStripe :is-preview="true" />
    </div>

    <template v-else>
        <BaseHeading
            :heading="$t('guest.appointments.rate.wont-be-charged')"
            :level="3"
            class="text-center"
        />

        <section class="mt-50 mb-40 space-y-40">
            <BaseAvatar
                :photo="appointment.pro.profilePicture"
                class="rounded-full h-70 w-70 mx-auto"
            />

            <BaseHeading
                :heading="$t('guest.appointments.rate.want-book-again')"
                :level="2"
                class="text-center"
            />

            <BaseButton
                :label="$t('guest.appointments.rate.check-availability')"
                :level="2"
                :route="repeatRoute"
            />
        </section>
    </template>

    <FormButtons>
        <BaseButton
            :label="$t('guest.appointments.my-appointments')"
            route="/guest/appointments/future"
        />
    </FormButtons>
</template>

<script>
    import ApiGuestAppointments from '@/apis/ApiGuestAppointments'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseReceipt from '@/components/base/BaseReceipt'
    import BaseButton from '@/components/base/BaseButton'
    import FormButtons from '@/components/form-elements/FormButtons'
    import GuestStripe from '@/components/guest/GuestStripe'

    export default {
        components: {
            BaseAvatar,
            BaseHeading,
            BaseText,
            BaseHeaderDivider,
            BaseReceipt,
            BaseButton,
            FormButtons,
            GuestStripe,
        },

        mixins: [MixinFormStep, MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
        },

        computed: {
            repeatRoute() {
                return `/guest/appointments/${this.appointment.id}/repeat`
            },
        },

        mounted() {
            this.saveNoShow()
        },

        methods: {
            saveNoShow() {
                ApiGuestAppointments.markAsNoShow(
                    this.$auth.getUser(),
                    this.appointment.id,
                    this.inProsPlace ? 'guest' : 'pro',
                )
            },
        },
    }
</script>
