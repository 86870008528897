<template>
    <header class="relative mb-20">
        <button
            v-if="hasActions"
            class="absolute top-0 right-0 h-30 w-30 flex items-center justify-center"
            @click="$emit('open-actions')"
        >
            <IconDots class="h-25 text-gray-3" />
        </button>

        <div class="text-center flex flex-col items-center space-y-10">
            <BaseAvatar :photo="appointment.guest.profilePicture" class="h-80 w-80 rounded-full" />

            <BaseHeading :heading="guestName" :level="5" />

            <template v-if="!isCompact">
                <GuestVerificationStatus v-if="inGuestsPlace" :guest="appointment.guest" />

                <BaseText
                    v-if="appointment.guest.preferences"
                    :content="appointment.guest.preferences"
                />
            </template>
        </div>
    </header>
</template>

<script>
    import { composeFullName } from '@/helpers'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'
    import GuestVerificationStatus from '@/components/guest/GuestVerificationStatus'
    import IconDots from '@/assets/vectors/icon-dots.svg'

    export default {
        components: {
            BaseAvatar,
            BaseHeading,
            BaseText,
            GuestVerificationStatus,
            IconDots,
        },

        mixins: [MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
            isCompact: { type: Boolean, default: false },
            hasActions: { type: Boolean, default: false },
        },

        emits: ['open-actions'],

        computed: {
            guestName() {
                return composeFullName(this.appointment.guest)
            },
        },
    }
</script>
