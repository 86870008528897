<template>
    <BaseText :content="$t('pro.profile.cancellation.policy.question')" />

    <FieldTiles
        :options="types"
        :form-data="formData"
        :value-path="typePath"
        @change="changeType"
    />

    <template v-if="typeValue">
        <BaseNote
            v-if="hasNote"
            :content="$t(`pro.profile.cancellation.types.${typeValue}.note`)"
            :level="1"
        />

        <BaseRow
            v-if="typeValue === 'flat'"
            :label="$t('pro.profile.cancellation.types.flat.input')"
        >
            <FieldAmount
                :form-data="formData"
                :value-path="feePath"
                class="ml-auto"
                @change="change"
            />
        </BaseRow>

        <div v-if="typeValue === 'percent'">
            <p class="font-bold mb-20 text-center">
                {{ $t(`pro.profile.cancellation.types.percent.input`) }}
            </p>

            <FieldRange
                :range-from="0"
                :range-to="100"
                :form-data="formData"
                :value-path="feePath"
                label-from="0%"
                label-to="100%"
                @change="change"
            />
        </div>
    </template>
</template>

<script>
    import get from 'lodash/get'
    import { getWorkPlaceOptions } from '@/helpers'
    import BaseText from '@/components/base/BaseText'
    import BaseNote from '@/components/base/BaseNote'
    import BaseRow from '@/components/base/BaseRow'
    import FieldTiles from '@/components/form-fields/FieldTiles'
    import FieldAmount from '@/components/form-fields/FieldAmount'
    import FieldRange from '@/components/form-fields/FieldRange'

    export default {
        components: {
            BaseText,
            BaseNote,
            BaseRow,
            FieldTiles,
            FieldAmount,
            FieldRange,
        },

        props: {
            formData: { type: Object, required: true },
            placeSlug: { type: String, required: true },
            typePath: { type: String, required: true },
            feePath: { type: String, required: true },
            hasNote: { type: Boolean, default: true },
        },

        emits: ['change'],

        computed: {
            place() {
                return getWorkPlaceOptions()
                    .map(({ icon, label, value: slug }) => ({ icon, label, slug }))
                    .find(({ slug }) => slug === this.placeSlug)
            },

            types() {
                return [
                    {
                        label: this.$t('pro.profile.cancellation.types.flat.label'),
                        value: 'flat',
                    },
                    {
                        label: this.$t('pro.profile.cancellation.types.percent.label'),
                        value: 'percent',
                    },
                ]
            },

            typeValue() {
                return get(this.formData, this.typePath)
            },
        },

        methods: {
            change({ path, value }) {
                this.$emit('change', { path, value })
            },

            changeType({ path, value }) {
                this.change({ path, value })

                switch (value) {
                    case 'flat':
                        this.change({ path: this.feePath, value: null })
                        break
                    case 'percent':
                        this.change({ path: this.feePath, value: 10 })
                        break
                }
            },
        },
    }
</script>
