<template>
    <ProSection :heading="heading">
        <FormWrapper>
            <FormFields>
                <ProProfileCancellationIcon :place-slug="placeSlug" />

                <BaseRow v-if="isSameAsDisplayed" :label="sameAsLabel" :is-value-auto="true">
                    <FieldSwitch
                        :form-data="formData"
                        :value-path="sameAsPath"
                        class="ml-auto"
                        @change="changeSameAs"
                    />
                </BaseRow>

                <ProProfileCancellationPolicy
                    v-if="!isSameAsDisplayed || !formData[sameAsPath]"
                    :form-data="formData"
                    :type-path="typePath"
                    :fee-path="feePath"
                    :place-slug="placeSlug"
                    @change="change"
                />
            </FormFields>

            <slot name="buttons" :is-submittable="isSubmittable" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import get from 'lodash/get'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import BaseRow from '@/components/base/BaseRow'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FieldSwitch from '@/components/form-fields/FieldSwitch'
    import ProSection from '@/components/pro/ProSection'
    import ProProfileCancellationIcon from '@/components/pro/profile/cancellation/ProProfileCancellationIcon'
    import ProProfileCancellationPolicy from '@/components/pro/profile/cancellation/ProProfileCancellationPolicy'

    export default {
        components: {
            BaseRow,
            FormWrapper,
            FormFields,
            FieldSwitch,
            ProSection,
            ProProfileCancellationIcon,
            ProProfileCancellationPolicy,
        },

        mixins: [MixinFormStep],

        props: {
            isInitialSetUp: { type: Boolean, required: true },
        },

        computed: {
            placeSlug() {
                return 'pro'
            },

            typePath() {
                return 'cancellationPolicy.prosPlaceFeeType'
            },

            feePath() {
                return 'cancellationPolicy.prosPlaceFee'
            },

            sameAsLabel() {
                return ''
            },

            sameAsPath() {
                return ''
            },

            isSameAsDisplayed() {
                return false
            },

            heading() {
                return this.$t(`pro.profile.cancellation.policy.heading.${this.placeSlug}`)
            },

            isSubmittable() {
                return get(this.formData, this.typePath) && get(this.formData, this.feePath)
            },
        },

        methods: {
            changeSameAs() {},
        },
    }
</script>
