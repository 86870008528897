export default {
    login: {
        heading: 'Sign in',
        subheading: 'Access the Chez.moi staff area ',
    },
    dashboard: {
        navLabel: 'Dashboard',
        fromPreviousDay: 'From previous day',
        totalSales: 'Total Sales',
        totalFees: 'Total Fees',
        reservationSummary: 'Reservation Summary',
        proAndGuestPlace: 'Pro Place | Guest Place',
        topLocations: 'Top Locations',
        reviews: 'Reviews',
        proPlace: 'Pro Place',
        guetsPlace: 'Guest Place',
        profileViews: 'Profile Views',
        completed: 'Completed',
        cancellations: 'Cancellations',
        topCategories: 'Top Categories',
        topSellers: 'Top Sellers',
        fiveStars: '5 Stars',
        fourStars: '4 Stars',
        threeStars: '3 Stars',
        twoStars: '2 Stars',
        oneStars: '1 Stars',
        selectCategory: 'Select category',
        all: 'All',
        showAll: 'Show All',
        selectRating: 'Select Rating',
        haveBooked: 'Have Booked',
        haveNotBooked: 'Have NOT Booked',
        total: 'Total',
        ticketAverage: 'Ticket AVG',
        joinedOn: 'Joined on',
        dateRangePicker: {
            when: {
                options: {
                    lastWeek: 'Last Week',
                    lastMonth: 'Last Month',
                    lastYear: 'Last Year',
                    yearToDate: 'Year to Date',
                    allTime: 'All time',
                },
            },
        },
        newPros: {
            label: 'New Pros',
            invitePro: {
                user: 'The user',
                label: 'Invite Pro',
                importCsv: 'Import CSV',
                addManually: 'Add manually',
            },
            proInvitationSent: {
                heading: 'Invite sent!',
                text: ' has been invited to join Freebyrdz.',
            },
            proInvitationFailed: {
                text: ' is already on Freebyrdz.',
            },
            proInvitationDone: {
                heading: 'File uploaded!',
                contactsInvited: ' contacts invited.',
                text:
                    'Any contacts not already members of FreeByrdz have been automatically invited on your behalf.',
            },
            requiredInfo: {
                heading: 'Required info missing',
            },
            stripe: {
                heading: 'Stripe account not set up',
                initial: 'The account set up is not started',
                accountCreated: 'The onboarding is not done',
                doneOnboarding: 'The Onboarding is done',
                pendingVerification: 'The account is pending verification',
                missingInformation: 'The account is missing infromation',
                enabled: 'The account is enabled',
            },
        },
        newGuests: {
            label: 'New Guests',
        },
        overallSatisfaction: {
            label: 'Overall Satisfaction',
            ratedBy: 'Rated By',
        },
        reservations: {
            label: 'Reservations',
        },
        sales: {
            label: 'Sales',
            fees: 'Fees',
        },
        table: {
            pro: 'Pro',
            guest: 'Guest',
            email: 'Email',
            other: 'Other',
            total: 'Total',
            fbFee: 'FB fee',
            offers: 'Offers',
            rating: 'Rating',
            review: 'Review',
            ticket: 'Ticket',
            proName: 'Pro name',
            gratuity: 'Gratuity',
            services: 'Services',
            location: 'Location',
            completedBookings: 'Completed bookings',
            phone: 'Phone number',
            chargedOn: 'Charged on',
            guestName: 'Guest name',
            hasBooked: 'Has booked',
            experiences: 'Experiences',
            appointmentDate: 'Appointment date',
        },
    },
    issues: {
        navLabel: 'Issues',
        heading: 'Manual Verification',
        uploadedPhoto: 'Uploaded photo',
        uploadedPhotoId: 'Uploaded photo ID',
        reject: 'Reject',
        verify: 'verify',
        userVerified: 'User verified!',
        userRejected: 'User rejected!',
    },
    payments: {
        navLabel: 'Payments',
        serviceFee: 'Service Fee',
        transactionFee: 'Transaction Fee',
    },
    usersAndRoles: {
        navLabel: 'Users & Roles',
        heading: 'Actual Users',
        modify: 'Modify',
        you: 'You',
    },
    welcome: 'Hi {name}!',
    role: 'Role (optional)',
    editUser: 'Edit user',
    inviteUser: 'Invite user',
    sendInvite: 'Send invite',
}
