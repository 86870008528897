<template>
    <a :href="href" :class="buttonClass">
        <span>{{ label }}</span>
        <LogoStripe class="w-80" />
    </a>
</template>

<script>
    import LogoStripe from '@/assets/vectors/logo-stripe.svg'

    export default {
        components: {
            LogoStripe,
        },

        props: {
            label: { type: String, required: true },
            href: { type: String, default: null },
            isDark: { type: Boolean, default: false },
        },

        computed: {
            buttonClass() {
                return {
                    'flex': true,
                    'mx-auto': true,
                    'items-center': true,
                    'h-50': true,
                    'px-15': true,
                    'space-x-10': true,
                    'rounded-md': true,
                    'border-1': true,
                    'border-black': true,
                    'text-black': !this.isDark,
                    'bg-white': !this.isDark,
                    'text-white': this.isDark,
                    'bg-black': this.isDark,
                }
            },
        },
    }
</script>
