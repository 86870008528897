<template>
    <component
        :is="currentStep.component"
        :step-data="formData"
        @back="handleBack"
        @change="changeData"
        @go-to-start="goToStart"
        @go-to="goToStep"
        @go-to-next="goToNextStep"
    >
        <template #buttons="{ isSubmittable }">
            <FormButtons v-if="isSubmittable">
                <BaseButton
                    :label="submitLabel"
                    :is-disabled="!isSubmittable"
                    :is-processing="isSubmitting"
                    @click="submit"
                />
            </FormButtons>
        </template>
    </component>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import MixinProProfilePlaces from '@/mixins/MixinProProfilePlaces'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import ProProfilePlacesWizard1 from '@/components/pro/profile/places/wizard/ProProfilePlacesWizard1'
    import ProProfilePlacesWizard2 from '@/components/pro/profile/places/wizard/ProProfilePlacesWizard2'
    import ProProfilePlacesWizard3 from '@/components/pro/profile/places/wizard/ProProfilePlacesWizard3'
    import ProProfilePlacesWizard4 from '@/components/pro/profile/places/wizard/ProProfilePlacesWizard4'
    import ProProfilePlacesWizard5 from '@/components/pro/profile/places/wizard/ProProfilePlacesWizard5'
    import ProProfilePlacesWizard6 from '@/components/pro/profile/places/wizard/ProProfilePlacesWizard6'

    export default {
        components: {
            FormButtons,
            BaseButton,
        },

        mixins: [MixinProProfileSection, MixinFormSteppable, MixinProProfilePlaces],

        data() {
            return {
                emptyPlace: null,
            }
        },

        computed: {
            steps() {
                // There can be only one empty place at a time.
                return this.emptyPlace === 'pro'
                    ? [
                          { component: ProProfilePlacesWizard1 },
                          { component: ProProfilePlacesWizard2 },
                      ]
                    : [
                          { component: ProProfilePlacesWizard3 },
                          { component: ProProfilePlacesWizard4 },
                          { component: ProProfilePlacesWizard5 },
                          { component: ProProfilePlacesWizard6 },
                      ]
            },

            isLastStep() {
                return this.step === this.steps.length
            },

            submitLabel() {
                return this.isLastStep ? this.$t('common.save') : this.$t('common.confirm')
            },

            isRedirectingHome() {
                return false
            },
        },

        created() {
            this.emptyPlace = !this.isPlaceFilled({ slug: 'pro' }) ? 'pro' : 'guest'
        },

        methods: {
            apiCall() {
                const updateData = ApiPros.update(
                    this.$auth.getUser().id,
                    this.emptyPlace === 'pro' ? 'prosPlaceData' : 'guestsPlaceData',
                    this.formData,
                )
                const updatePlace = ApiPros.update(this.$auth.getUser().id, 'workPlace', {
                    workPlace: ['pro', 'guest'],
                })

                return updateData.then(updatePlace)
            },

            handleBack() {
                this.isFirstStep ? this.back() : (this.step -= 1)
            },

            submit() {
                this.isLastStep ? this.save() : this.goToNextStep()
            },
        },
    }
</script>
