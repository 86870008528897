<template>
    <span class="text-10 font-bold tracking-widest uppercase">
        {{ content }}
    </span>
</template>

<script>
    export default {
        props: {
            content: { type: String, required: true },
        },
    }
</script>
