/* global google */
import { Loader } from '@googlemaps/js-api-loader'
import i18n from '@/i18n'

let sessionToken

new Loader({
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    version: 'weekly',
    libraries: ['places'],
})
    .load()
    .then(() => (sessionToken = new google.maps.places.AutocompleteSessionToken()))

export default {
    getAddressSuggestions(input) {
        return new Promise((resolve) => {
            new google.maps.places.AutocompleteService().getPlacePredictions(
                {
                    input,
                    types: ['geocode'],
                    componentRestrictions: { country: 'us' },
                    sessionToken: sessionToken,
                },
                (predictions) =>
                    resolve(
                        (predictions || []).map(({ description: label, place_id: value }) => ({
                            label,
                            value,
                        })),
                    ),
            )
        })
    },

    getAddressDetails(placeId) {
        return new Promise((resolve, reject) => {
            new google.maps.Geocoder().geocode({ placeId }, ([result], status) => {
                if (status !== 'OK') {
                    return reject(i18n.global.t('common.google.errors.other'))
                }

                result?.address_components
                    ? resolve(result)
                    : reject(i18n.global.t('common.google.errors.no-results'))
            })
        })
    },

    getAddressZipCode(placeId) {
        return this.getAddressDetails(placeId).then(
            ({ address_components: addressComponents }) =>
                new Promise((resolve, reject) => {
                    const { long_name: zipCode } =
                        addressComponents.find(({ types }) => types.includes('postal_code')) || {}

                    zipCode
                        ? resolve(zipCode)
                        : reject(i18n.global.t('common.google.errors.no-zip-code'))
                }),
        )
    },

    getZipCodeDetails(zipCode) {
        return new Promise((resolve, reject) => {
            new google.maps.Geocoder().geocode(
                { address: zipCode, componentRestrictions: { country: 'us' } },
                ([result], status) => {
                    if (status !== 'OK') {
                        return reject(i18n.global.t('common.google.errors.other'))
                    }

                    result?.address_components
                        ? resolve(result)
                        : reject(i18n.global.t('common.google.errors.no-results'))
                },
            )
        })
    },
}
