<template>
    <LayoutApp back-button="/">
        <div v-if="isWelcomeOpen" class="flex-grow flex flex-col">
            <BaseHeaderSection
                :heading="$t('auth.register.guest.invitation.welcome.heading')"
                :subheading="welcomeSubheading"
                class="space-y-30"
            />

            <FormButtons>
                <BaseButton
                    :label="$t('auth.register.guest.invitation.welcome.discover')"
                    route="/"
                />
            </FormButtons>
        </div>
        <template v-else>
            <header class="pb-30 space-y-15">
                <BaseHeaderSection
                    :heading="$t('auth.register.guest.heading')"
                    :subheading="formSubheading"
                />

                <BaseText v-if="!isInvitation" class="text-center">
                    <i18n-t keypath="auth.register.guest.signup.member.text">
                        <template #link>
                            <BaseLink
                                :label="$t('auth.register.guest.signup.member.link')"
                                route="/auth/login"
                            />
                        </template>
                    </i18n-t>
                </BaseText>
            </header>

            <FormWrapper @submit="submitForm">
                <FormFields>
                    <FieldText
                        :label="$t('common.fields.first-name')"
                        :has-autofocus="!isInvitation"
                        :is-required="true"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="firstName"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.last-name')"
                        :is-required="true"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="lastName"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.email')"
                        :is-required="true"
                        :is-disabled="isInvitation"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="email"
                        type="email"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.password')"
                        :has-autofocus="isInvitation"
                        :is-required="true"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="password"
                        type="password"
                        @change="change"
                    />

                    <FieldText
                        :label="$t('common.fields.phone')"
                        :is-required="true"
                        :form-data="formData"
                        :form-errors="formErrors"
                        value-path="phone"
                        type="tel"
                        @change="change"
                    />

                    <BaseText :level="2">
                        <i18n-t keypath="auth.register.acknowledgements.text">
                            <template #privacy>
                                <BaseLink
                                    :label="$t('auth.register.acknowledgements.privacy')"
                                    href="/privacy"
                                />
                            </template>
                            <template #terms>
                                <BaseLink
                                    :label="$t('auth.register.acknowledgements.terms')"
                                    href="/terms"
                                />
                            </template>
                        </i18n-t>
                    </BaseText>
                </FormFields>

                <FormButtons>
                    <BaseButton
                        :label="$t('common.sign-up')"
                        :is-processing="isSubmitting"
                        :is-disabled="!isSubmittable"
                    />
                </FormButtons>
            </FormWrapper>
        </template>
    </LayoutApp>
</template>

<script>
    import { composeFullName } from '@/helpers'
    import ApiGuests from '@/apis/ApiGuests'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldText from '@/components/form-fields/FieldText'

    export default {
        components: {
            LayoutApp,
            BaseHeaderSection,
            BaseText,
            BaseLink,
            BaseButton,
            FormWrapper,
            FormFields,
            FormButtons,
            FieldText,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        async loadData({ $route }) {
            if (!$route.query.token) {
                return
            }

            const invitation = await ApiGuests.getInvitation($route.query.token)

            return { invitation }
        },

        data() {
            return {
                isWelcomeOpen: false,
            }
        },

        computed: {
            isSubmittable() {
                return (
                    this.formData.firstName &&
                    this.formData.lastName &&
                    this.formData.email &&
                    this.formData.password &&
                    this.formData.phone
                )
            },

            isInvitation() {
                return !!this.invitation
            },

            formSubheading() {
                return this.isInvitation
                    ? this.$t('auth.register.guest.invitation.subheading')
                    : this.$t('auth.register.guest.signup.subheading')
            },

            welcomeSubheading() {
                return this.$t('auth.register.guest.invitation.welcome.thanks', {
                    name: composeFullName({
                        firstName: this.invitation.stylistFirstName,
                        lastName: this.invitation.stylistLastName,
                    }),
                })
            },
        },

        created() {
            if (this.$route.query.token && !this.isInvitation) {
                this.$ui.flash.error(this.$t('auth.register.guest.invitation.error'))
            }

            if (this.isInvitation) {
                this.initForm({ ...this.invitation, token: this.$route.query.token })
            }
        },

        methods: {
            submitForm() {
                this.handleSubmit(ApiGuests.register(this.formData)).then(({ token }) => {
                    this.$auth.logIn(token)
                    this.isInvitation
                        ? (this.isWelcomeOpen = true)
                        : this.$router.push('/auth/detect')
                })
            },
        },
    }
</script>
