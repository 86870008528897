<template>
    <div v-if="appointment.message" class="space-y-30 flex flex-col items-center">
        <BaseHeaderDivider
            :heading="$t('guest.appointments.x-question', { x: appointment.pro?.firstName })"
            class="w-full"
        />
        <BaseNote :content="appointment.message" class="w-full" />

        <template v-if="appointment.locationQuestionAnswer">
            <BaseHeading :heading="$t('guest.appointments.your-answer')" :level="5" />
            <BaseText :content="appointment.locationQuestionAnswer" />
        </template>
    </div>
</template>

<script>
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseNote from '@/components/base/BaseNote'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'

    export default {
        components: {
            BaseHeaderDivider,
            BaseNote,
            BaseHeading,
            BaseText,
        },

        props: {
            appointment: { type: Object, required: true },
        },
    }
</script>
