<template>
    <LayoutLanding>
        <LandingHeader :has-search="true" />
        <LandingContactForm />
    </LayoutLanding>
</template>

<script>
    import LayoutLanding from '@/components/layouts/LayoutLanding'
    import LandingHeader from '@/components/landing/LandingHeader'
    import LandingContactForm from '@/components/landing/contact/LandingContactForm'

    export default {
        components: {
            LayoutLanding,
            LandingHeader,
            LandingContactForm,
        },
    }
</script>
