import store from '@/store'

const openMessage = (options, type) => {
    return new Promise((resolve) => {
        options.resolve = resolve
        options.type = type
        store.commit('ui/message/open', options)
    })
}

export default {
    flash: {
        showPostponed() {
            return store.dispatch('ui/flash/showPostponed')
        },

        clear() {
            return store.dispatch('ui/flash/setMessage', undefined)
        },

        error(text, isPostponed = false) {
            return store.dispatch('ui/flash/setMessage', { text, isPostponed, type: 'error' })
        },

        success(text, isPostponed = false) {
            return store.dispatch('ui/flash/setMessage', { text, isPostponed, type: 'success' })
        },
    },

    confirm(options) {
        return new Promise((resolve) => {
            options.resolve = resolve
            options.cancellable = true
            store.commit('ui/confirm/open', options)
        })
    },

    alerts: {
        open(id, component) {
            store.commit('ui/alerts/open', { id, component })
        },

        close(id) {
            store.commit('ui/alerts/close', id)
        },
    },

    message: {
        success(options) {
            return openMessage(options, 'success')
        },

        warning(options) {
            return openMessage(options, 'warning')
        },

        error(options) {
            return openMessage(options, 'error')
        },
    },
}
