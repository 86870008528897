<template>
    <ol class="flex flex-wrap justify-between">
        <li v-for="(day, index) in days" :key="index" class="w-1/7">
            <button
                v-if="day"
                type="button"
                :disabled="isDateWithinAnyOfRanges(day.date, disabledDates)"
                class="relative w-full py-5 group flex items-center justify-center"
                @click="$emit('click-date', day.date)"
            >
                <div :class="dayBackgroundClass(day)" />
                <p :class="dayClass(day)">{{ day.number }}</p>
            </button>
        </li>
    </ol>
</template>

<script>
    import dayjs from 'dayjs'

    export default {
        props: {
            year: { type: Number, required: true },
            month: { type: Number, required: true },
            selectedDates: { type: Array, default: () => [] },
            disabledDates: { type: Array, default: () => [] },
        },

        emits: ['click-date'],

        computed: {
            date() {
                return dayjs()
                    .year(this.year)
                    .month(this.month)
                    .startOf('month')
            },

            days() {
                const startOfCurrentMonth = this.date.startOf('month')

                const startPadding = Array((startOfCurrentMonth.day() || 7) - 1).fill(null)
                const actualDays = Array(startOfCurrentMonth.daysInMonth())
                    .fill(null)
                    .map((day, index) => ({
                        date: this.date.add(index, 'days'),
                        number: index + 1,
                    }))
                const endPadding =
                    (startPadding.length + actualDays.length) % 7
                        ? Array(7 - ((startPadding.length + actualDays.length) % 7)).fill(null)
                        : []

                return [...startPadding, ...actualDays, ...endPadding]
            },
        },

        methods: {
            isDateWithinAnyOfRanges(date, ranges) {
                return (ranges || []).some((rangeOrDate) => {
                    return rangeOrDate.from && rangeOrDate.to
                        ? dayjs(date).isBetween(rangeOrDate.from, rangeOrDate.to, 'day', '[]')
                        : dayjs(date).isSame(rangeOrDate, 'day')
                })
            },

            isDateStartingAnyOfRanges(date, ranges) {
                return (ranges || []).some((rangeOrDate) =>
                    rangeOrDate.from
                        ? dayjs(date).isSame(rangeOrDate.from, 'day')
                        : dayjs(date).isSame(rangeOrDate, 'day'),
                )
            },

            isDateEndingAnyOfRanges(date, ranges) {
                return (ranges || []).some((rangeOrDate) =>
                    rangeOrDate.to
                        ? dayjs(date).isSame(rangeOrDate.to, 'day')
                        : dayjs(date).isSame(rangeOrDate, 'day'),
                )
            },

            dayClass({ date }) {
                const isCurrent = dayjs().isSame(date, 'day')
                const isDisabled = this.isDateWithinAnyOfRanges(date, this.disabledDates)
                const isSelected = this.isDateWithinAnyOfRanges(date, this.selectedDates)

                return {
                    'relative': true,
                    'h-40': true,
                    'w-40': true,
                    'flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'rounded-full': true,
                    'whitespace-nowrap': true,
                    'leading-none': true,
                    'transition-none': true,
                    'border-purple': true,
                    'border-1': isCurrent,
                    'text-purple': isCurrent,
                    'hoverable:group-hover:border-2': !isDisabled,
                    'hoverable:group-hover:text-purple': !isDisabled && !isSelected,
                    'hoverable:group-focus:border-2': !isDisabled,
                    'hoverable:group-focus:text-purple': !isDisabled && !isSelected,
                    'opacity-30': isDisabled,
                    'cursor-not-allowed': isDisabled,
                    'bg-purple': isSelected,
                    'text-white': isSelected,
                }
            },

            dayBackgroundClass({ date }) {
                const isStarting = this.isDateStartingAnyOfRanges(date, this.selectedDates)
                const isWithin = this.isDateWithinAnyOfRanges(date, this.selectedDates)
                const isEnding = this.isDateEndingAnyOfRanges(date, this.selectedDates)
                const isSingle = isStarting && isEnding

                return isSingle
                    ? 'hidden'
                    : {
                          'absolute': true,
                          'inset-y-5': true,
                          'bg-purple': true,
                          'transition-none': true,
                          'left-6/12': isStarting,
                          'right-0': isStarting,
                          'left-0': isEnding,
                          'right-6/12': isEnding,
                          'inset-x-0': isWithin,
                      }
            },
        },
    }
</script>
[]
