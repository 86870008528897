<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProProfilePlacesProAddress from '@/components/pro/profile/places/pro/ProProfilePlacesProAddress'

    export default {
        extends: ProProfilePlacesProAddress,

        mixins: [MixinFormStep],
    }
</script>
