<template>
    <div :class="wrapperClass">
        <!-- eslint-disable vue/no-textarea-mustache -->
        <textarea
            ref="input"
            :placeholder="placeholder"
            :autofocus="hasAutofocus"
            :disabled="isDisabled"
            :required="isRequired"
            :readonly="isReadonly"
            :rows="rows"
            :maxlength="maxLength"
            :class="inputClass"
            @focus="focus"
            @blur="blur"
            @input="change"
            >{{ value }}</textarea
        >
    </div>
</template>

<script>
    import MixinFormInput from '@/mixins/MixinFormInput'

    export default {
        mixins: [MixinFormInput],

        props: {
            placeholder: { type: [String, Number], default: null },
            rows: { type: Number, default: 2 },
            maxLength: { type: Number, default: null },
            hasBottomBorder: { type: Boolean, default: true },
        },

        computed: {
            wrapperClass() {
                return {
                    'w-full': true,
                    'flex': true,
                    'items-center': true,
                    'transition': true,
                    'opacity-30': this.isDisabled,
                    'cursor-not-allowed': this.isDisabled,
                    'border-b-1': this.hasBottomBorder,
                    'border-black': !this.isInvalid,
                    'text-black': !this.isInvalid,
                    'text-red': this.isInvalid,
                    'focus-within:text-purple': !this.isInvalid,
                    'focus-within:border-purple': !this.isInvalid,
                }
            },

            inputClass() {
                return {
                    'block': true,
                    'w-full': true,
                    'h-50': true,
                    'tracking-wide': true,
                    'appearance-none': true,
                    'resize-none': true,
                    'rounded-none': true,
                    'bg-transparent': true,
                    'border-transparent': this.isInvalid,
                    'font-medium': true,
                    'text-gray-1': true,
                    'text-inherit': true,
                    'outline-none': true,
                }
            },
        },

        mounted() {
            const input = this.$refs.input
            const offset = input.offsetHeight - input.clientHeight
            const calculateHeight = () => {
                input.style.height = 'auto'
                input.style.height = input.scrollHeight + offset + 'px'
            }

            input.addEventListener('input', calculateHeight)

            calculateHeight()
        },
    }
</script>
