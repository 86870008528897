<template>
    <ProOnboardingStep
        :heading="$t('pro.onboarding.places.heading')"
        :description="$t('pro.onboarding.places.instruction')"
        @submit="goToNextStep"
    >
        <FormFields>
            <BaseHeading
                :heading="$t('pro.onboarding.places.question')"
                :level="2"
                class="text-center"
            />

            <FormLegend :label="$t('common.choose-one')" />

            <FieldChoices
                :options="options"
                :form-data="formData"
                value-path="workPlace"
                @change="change"
            />
        </FormFields>

        <FormButtons>
            <BaseButton :label="$t('common.next')" :is-disabled="!isSubmittable" />
        </FormButtons>
    </ProOnboardingStep>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProOnboardingStep from '@/components/pro/onboarding/ProOnboardingStep'
    import BaseHeading from '@/components/base/BaseHeading'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FormLegend from '@/components/form-elements/FormLegend'
    import FieldChoices from '@/components/form-fields/FieldChoices'
    import BaseButton from '@/components/base/BaseButton'
    import { getWorkPlaceOptions } from '@/helpers'

    export default {
        components: {
            ProOnboardingStep,
            BaseHeading,
            FormFields,
            FormButtons,
            FormLegend,
            FieldChoices,
            BaseButton,
        },

        mixins: [MixinFormStep],

        computed: {
            isSubmittable() {
                return (this.formData.workPlace || []).length
            },

            options() {
                return getWorkPlaceOptions()
            },
        },
    }
</script>
