<template>
    <footer class="bg-sand-light text-purple">
        <LandingContainer class="px-20 md:px-30 md:pt-60 relative">
            <LogoFreeByrdzType class="w-150 hidden md:block" />

            <section class="flex flex-wrap md:flex-nowrap md:space-x-20 md:mt-50">
                <div
                    v-for="section in sections"
                    :key="section.heading"
                    class="text-18 space-y-10 w-6/12 md:w-auto pt-40 md:pt-0 md:min-w-250"
                >
                    <h5 class="text-16 md:text-24 font-bold">
                        {{ section.heading }}
                    </h5>

                    <RouterLink
                        v-for="link in section.links"
                        :key="link.label"
                        :to="link.route"
                        class="text-14 md:text-18 flex items-center h-25"
                    >
                        <span>{{ link.label }}</span>
                    </RouterLink>
                </div>
            </section>

            <section
                class="flex flex-col items-center mt-40 md:flex-row md:justify-start md:space-x-20 lg:space-x-0 lg:block lg:space-y-30 md:mt-50 lg:absolute lg:top-10 lg:right-30"
            >
                <h5 class="text-14 md:text-22 mb-20 md:mb-0">
                    {{ $t('landing.footer.sections.follow') }}
                </h5>

                <div class="flex space-x-15">
                    <template v-for="(social, index) in socials" :key="index">
                        <a v-if="social.href" :href="social.href">
                            <component :is="social.icon" class="h-40 w-40" />
                        </a>
                    </template>
                </div>
            </section>
        </LandingContainer>

        <section
            class="mx-20 py-20 border-t-1 border-current flex justify-center mt-40 md:mt-50 lg:mt-100"
        >
            <small>{{ $t('landing.footer.copyright') }}</small>
        </section>
    </footer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import BaseHeading from '@/components/base/BaseHeading'
    import LogoFreeByrdzType from '@/assets/vectors/logo-freebyrdz-type.svg'
    import IconSocialFacebook from '@/assets/vectors/icon-social-facebook.svg'
    import IconSocialInstagram from '@/assets/vectors/icon-social-instagram.svg'
    import IconSocialLinkedin from '@/assets/vectors/icon-social-linkedin.svg'
    import IconSocialTwitter from '@/assets/vectors/icon-social-twitter.svg'
    import IconSocialYoutube from '@/assets/vectors/icon-social-youtube.svg'

    export default {
        components: {
            LandingContainer,
            BaseHeading,
            LogoFreeByrdzType,
        },

        computed: {
            sections() {
                return [
                    {
                        heading: this.$t('landing.footer.sections.company'),
                        links: [
                            {
                                label: this.$t('landing.footer.links.contact'),
                                route: '/contact',
                            },
                            {
                                label: this.$t('landing.footer.links.privacy'),
                                route: '/privacy',
                            },
                            {
                                label: this.$t('landing.footer.links.terms'),
                                route: '/terms',
                            },
                        ],
                    },
                    {
                        heading: this.$t('landing.footer.sections.pros'),
                        links: [
                            {
                                label: this.$t('landing.footer.links.biz'),
                                route: '/for-pros',
                            },
                        ],
                    },
                ]
            },

            socials() {
                return [
                    {
                        icon: IconSocialFacebook,
                        href: false,
                    },
                    {
                        icon: IconSocialInstagram,
                        href: 'http://instagram.com/freebyrdzofficial',
                    },
                    {
                        icon: IconSocialTwitter,
                        href: false,
                    },
                    {
                        icon: IconSocialYoutube,
                        href: false,
                    },
                    {
                        icon: IconSocialLinkedin,
                        href: false,
                    },
                ]
            },
        },
    }
</script>
