<template>
    <div class="flex flex-col relative">
        <div class="bg-gray-5 z-above absolute inset-0">
            <BaseMap ref="map" class="absolute inset-0" />
        </div>
        <p class="absolute z-above bottom-0 inset-x-0 text-13 bg-black text-white p-10">
            <slot name="address" />
            {{ address?.address }}
            <br />
            {{ address?.zipCode }}
            {{ address?.city }},
            {{ address?.state }}
        </p>
    </div>
</template>

<script>
    import Leaflet from 'leaflet'
    import BaseMap from '@/components/base/BaseMap'

    export default {
        components: {
            BaseMap,
        },

        props: {
            address: { type: Object, required: true },
            isIconHighlighted: { type: Boolean, default: false },
        },

        mounted() {
            this.showPinsAndFitToBounds()
        },

        methods: {
            showPinsAndFitToBounds() {
                // TODO: Detect lat/long if it's not present in the address.

                if (!this.address?.latitude || !this.address?.longitude) {
                    return
                }

                const map = this.$refs.map.leaflet
                const marker = Leaflet.marker([this.address.latitude, this.address.longitude], {
                    icon: Leaflet.icon({
                        iconUrl: this.isIconHighlighted
                            ? require('@/assets/images/icon-pin-active.png').default
                            : require('@/assets/images/icon-pin.png').default,
                        iconSize: [26, 36],
                        iconAnchor: [13, 36],
                    }),
                }).addTo(map)

                map.setView(marker.getLatLng())
            },
        },
    }
</script>
