<template>
    <div class="flex items-center mx-auto">
        <span class="text-24 pr-5">$</span>

        <InputText
            :placeholder="placeholder"
            :has-autofocus="hasAutofocus"
            :value="value"
            type="number"
            class="text-24 text-right"
            @change="change"
        />
    </div>
</template>

<script>
    import MixinFormInput from '@/mixins/MixinFormInput'
    import InputText from '@/components/form-inputs/InputText'

    export default {
        components: {
            InputText,
        },

        mixins: [MixinFormInput],

        props: {
            placeholder: { type: [String, Number], default: 0 },
        },

        emits: ['blur', 'focus'],
    }
</script>
