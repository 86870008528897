<template>
    <BaseTag
        :label="$t('pro.profile.required')"
        class="bg-notification border-notification text-white"
    />
</template>

<script>
    import BaseTag from '@/components/base/BaseTag'

    export default {
        components: {
            BaseTag,
        },
    }
</script>
