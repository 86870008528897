<template>
    <div class="sticky z-floating top-95 bg-white pb-20">
        <BaseHeaderSection
            :heading="heading"
            :subheading="subheading"
            :description="description"
            :is-center="false"
        />
    </div>

    <FormWrapper class="pt-40" @submit="$emit('submit')">
        <slot />
    </FormWrapper>
</template>

<script>
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'

    export default {
        components: {
            BaseHeaderSection,
            FormWrapper,
        },

        props: {
            heading: { type: String, default: null },
            subheading: { type: String, default: null },
            description: { type: String, default: null },
        },

        emits: ['submit'],
    }
</script>
