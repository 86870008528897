<template>
    <ProSection>
        <FormWrapper>
            <ProProfileCancellationDetails
                :cancellation-policy="formData.cancellationPolicy"
                class="mb-60"
            />

            <FormFields>
                <BaseHeading
                    :heading="$t('pro.profile.cancellation.same-as-cancellation.question')"
                    :level="4"
                />

                <FieldTiles
                    :options="booleanOptions"
                    :form-data="formData"
                    value-path="isNoShowSameAsCancellation"
                    @change="changeSameAs"
                />

                <BaseNote
                    :content="$t('pro.profile.cancellation.same-as-cancellation.note')"
                    :level="1"
                />
            </FormFields>

            <slot name="buttons" :is-submittable="isSubmittable" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import { getBooleanOptions } from '@/helpers'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseNote from '@/components/base/BaseNote'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FieldTiles from '@/components/form-fields/FieldTiles'
    import ProProfileCancellationDetails from '@/components/pro/profile/cancellation/ProProfileCancellationDetails'

    export default {
        components: {
            ProSection,
            BaseHeading,
            BaseNote,
            FormWrapper,
            FormFields,
            FieldTiles,
            ProProfileCancellationDetails,
        },

        mixins: [MixinFormStep],

        props: {
            isInitialSetUp: { type: Boolean, required: true },
        },

        computed: {
            isSubmittable() {
                return this.formData.isNoShowSameAsCancellation !== undefined
            },

            booleanOptions() {
                return getBooleanOptions()
            },
        },

        methods: {
            changeSameAs({ path, value }) {
                this.change({
                    path: 'noShowPolicy',
                    value: value ? this.formData.cancellationPolicy : null,
                })
                this.change({ path, value })
            },
        },
    }
</script>
