<template>
    <LayoutOverlay back-button="/pro/profile">
        <header class="flex flex-col">
            <BaseHeading
                :heading="$t('pro.profile.experiences.index.heading')"
                :level="5"
                class="text-center mt-30"
            />

            <div class="flex items-center relative">
                <ProProfileExperiencesIndexCategories
                    :categories="proCategories"
                    :selected-category="selectedCategory"
                    @select-category="selectedCategory = $event"
                />

                <ProProfileExperiencesIndexActions
                    :categories="proCategories"
                    class="absolute right-0"
                    @delete-category="deleteCategory"
                />
            </div>

            <ProProfileExperiencesIndexEdit :category="selectedCategory" class="mb-20" />
        </header>

        <ProProfileExperiencesIndexSubCategories
            v-if="selectedCategory"
            :sub-categories="selectedCategorySubCategories"
            :experiences="proExperiences"
        />
    </LayoutOverlay>
</template>

<script>
    import ApiProExperiences from '@/apis/ApiProExperiences'
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import BaseHeading from '@/components/base/BaseHeading'
    import ProProfileExperiencesIndexCategories from '@/components/pro/profile/experiences/index/ProProfileExperiencesIndexCategories'
    import ProProfileExperiencesIndexEdit from '@/components/pro/profile/experiences/index/ProProfileExperiencesIndexEdit'
    import ProProfileExperiencesIndexActions from '@/components/pro/profile/experiences/index/ProProfileExperiencesIndexActions'
    import ProProfileExperiencesIndexSubCategories from '@/components/pro/profile/experiences/index/ProProfileExperiencesIndexSubCategories'
    import ProProfileExperiencesIndexAlertNone from '@/components/pro/profile/experiences/index/ProProfileExperiencesIndexAlertNone'
    import ProProfileExperiencesIndexAlertSome from '@/components/pro/profile/experiences/index/ProProfileExperiencesIndexAlertSome'

    export default {
        components: {
            LayoutOverlay,
            BaseHeading,
            ProProfileExperiencesIndexCategories,
            ProProfileExperiencesIndexEdit,
            ProProfileExperiencesIndexActions,
            ProProfileExperiencesIndexSubCategories,
        },

        mixins: [MixinProExperiences],

        data() {
            return {
                selectedCategory: null,
            }
        },

        loadData({ $auth, $router }) {
            if ($auth.getUser().subCategories.length === 0) {
                $router.push('/pro/profile/experiences/categories/add')
            }

            return {}
        },

        computed: {
            selectedCategorySubCategories() {
                return this.selectedCategory.subCategories.filter(({ id }) =>
                    this.proSubCategoryIds.includes(id),
                )
            },

            areAllSubCategoriesFilled() {
                const proExperiencesubCategoryIds = this.proExperiences.reduce(
                    (ids, { subCategories }) => ids.concat(subCategories.map(({ id }) => id)),
                    [],
                )

                return !this.proSubCategoryIds.some(
                    (id) => !proExperiencesubCategoryIds.includes(id),
                )
            },
        },

        watch: {
            proExperiences() {
                this.detectAlert()
            },
        },

        created() {
            this.selectFirstCategory()
            this.detectAlert()
        },

        beforeUnmount() {
            this.$ui.alerts.close('add-experience')
        },

        methods: {
            selectFirstCategory() {
                this.selectedCategory = this.proCategories?.[0]
            },

            detectAlert() {
                if (this.proExperiences.length === 0) {
                    return this.$ui.alerts.open(
                        'add-experience',
                        ProProfileExperiencesIndexAlertNone,
                    )
                }

                if (!this.areAllSubCategoriesFilled) {
                    return this.$ui.alerts.open(
                        'add-experience',
                        ProProfileExperiencesIndexAlertSome,
                    )
                }

                this.$ui.alerts.close('add-experience')
            },

            deleteCategory(category) {
                const subcategoryIdsWithoutOnesFromDeletedCategory = this.proSubCategories
                    .filter((subCategory) => subCategory.category?.id !== category.id)
                    .map(({ id }) => id)

                ApiProExperiences.updateSubCategories(this.$auth.getUser().id, {
                    subCategoryIds: subcategoryIdsWithoutOnesFromDeletedCategory,
                }).then((user) => {
                    this.$store.commit('auth/setUser', user)

                    this.proCategories.length > 0
                        ? this.selectFirstCategory()
                        : this.$router.push('/pro/profile/experiences/categories/add')
                })
            },
        },
    }
</script>
