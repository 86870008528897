<template>
    <BaseChoice :icon="place.icon" :label="place.label" :level="2" />
</template>

<script>
    import { getWorkPlaceOptions } from '@/helpers'
    import BaseChoice from '@/components/base/BaseChoice'

    export default {
        components: {
            BaseChoice,
        },

        props: {
            placeSlug: { type: String, required: true },
        },

        computed: {
            place() {
                return getWorkPlaceOptions()
                    .map(({ icon, label, value: slug }) => ({ icon, label, slug }))
                    .find(({ slug }) => slug === this.placeSlug)
            },
        },
    }
</script>
