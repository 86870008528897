<template>
    <ProSection :heading="heading">
        <FormWrapper>
            <FieldTiles
                :options="booleanOptions"
                :form-data="formData"
                :value-path="valuePath"
                @change="changeWorkPlace"
            />

            <slot name="buttons" :is-submittable="isSubmittable" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import { getBooleanOptions } from '@/helpers'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FieldTiles from '@/components/form-fields/FieldTiles'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FieldTiles,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isSubmittable() {
                return typeof this.formData[this.valuePath] === 'boolean'
            },

            booleanOptions() {
                return getBooleanOptions()
            },

            heading() {
                return this.$t('pro.profile.addons.steps.4.heading', {
                    name: this.formData.name,
                })
            },

            valuePath() {
                return 'isOfferedInPro'
            },

            placeSlug() {
                return 'pro'
            },
        },

        methods: {
            changeWorkPlace({ path, value }) {
                this.change({ path, value })

                this.change({
                    path: 'workPlace',
                    value: (this.formData.workPlace || []).filter(
                        (slug) => slug !== this.placeSlug,
                    ),
                })

                if (value === true) {
                    this.change({
                        path: 'workPlace',
                        value: [...(this.formData.workPlace || []), this.placeSlug],
                    })
                }
            },
        },
    }
</script>
