<template>
    <transition
        enter-active-class="transition-opacity duration-250"
        leave-active-class="transition-opacity duration-500"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div v-if="internalIsOpen" ref="overlay" :class="overlayClass">
            <div class="opacity-30 absolute inset-0 bg-gray-1" @click="$emit('close')" />
            <slot />
        </div>
    </transition>
</template>

<script>
    import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

    export default {
        props: {
            isOpen: { type: Boolean, default: false },
            zIndex: { type: String, default: 'overlay' },
        },

        emits: ['close'],

        data() {
            return {
                internalIsOpen: this.isOpen,
            }
        },

        computed: {
            overlayClass() {
                return {
                    'fixed': true,
                    'inset-0': true,
                    'overflow-hidden': true,
                    [`z-${this.zIndex}`]: true,
                    'p-20': true,
                }
            },
        },

        watch: {
            isOpen(isOpen) {
                if (isOpen) {
                    this.internalIsOpen = isOpen
                    this.$nextTick(() => disableBodyScroll(this.$refs.overlay))
                } else {
                    this.$nextTick(() => (this.internalIsOpen = isOpen))
                    enableBodyScroll(this.$refs.overlay)
                }
            },
        },

        beforeUnmount() {
            clearAllBodyScrollLocks()
        },
    }
</script>
