<template>
    <LayoutDesktop>
        <header class="pb-30 space-y-15 mt-50">
            <BaseHeaderSection
                :heading="$t('admin.login.heading')"
                :subheading="$t('admin.login.subheading')"
            />
        </header>

        <FormWrapper class="w-auto" @submit="submitForm">
            <FormFields class="flex flex-col">
                <FieldText
                    :label="$t('common.fields.email')"
                    :is-required="true"
                    :has-autofocus="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    :error="generalError"
                    value-path="email"
                    type="email"
                    class="flex-grow"
                    @change="change"
                />

                <FieldText
                    :label="$t('common.fields.password')"
                    :is-required="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="password"
                    type="password"
                    class="flex-grow"
                    @change="change"
                />
            </FormFields>

            <div class="flex-grow flex flex-col space-y-40 mt-50">
                <BaseButton
                    :label="$t('auth.login.submit')"
                    :is-processing="isSubmitting"
                    :is-disabled="!isSubmittable"
                />
            </div>
        </FormWrapper>
    </LayoutDesktop>
</template>

<script>
    import ApiAdmins from '@/apis/ApiAdmins'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import LayoutDesktop from '@/components/layouts/LayoutDesktop'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FieldText from '@/components/form-fields/FieldText'

    export default {
        components: {
            LayoutDesktop,
            BaseHeaderSection,
            BaseButton,
            FormWrapper,
            FormFields,
            FieldText,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        computed: {
            isSubmittable() {
                return this.formData.email && this.formData.password
            },

            generalError() {
                if (this.formErrors?.message && !this.formErrors?.errors) {
                    return this.formErrors?.message
                }

                return null
            },
        },

        methods: {
            submitForm() {
                this.handleSubmit(ApiAdmins.login(this.formData)).then(({ token }) => {
                    this.$auth.logIn(token)
                    this.$router.push(
                        this.$route.query.redirect
                            ? this.$route.query.redirect
                            : '/admin/dashboard',
                    )
                })
            },
        },
    }
</script>
