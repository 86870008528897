import axios from 'axios'
import transformAppointmentToApi, {
    transformParams,
} from '@/apis/transforms/transformAppointmentToApi'
import transformAppointmentFromApi from '@/apis/transforms/transformAppointmentFromApi'

const transforms = (data) => ({
    transformRequest: [transformAppointmentToApi, ...axios.defaults.transformRequest],
    transformResponse: [...axios.defaults.transformResponse, transformAppointmentFromApi(data)],
})

export default {
    get(guest, params) {
        return axios.get(`/guests/${guest.id}/appointments`, {
            params: transformParams(params),
            transformResponse: transforms({ guest }).transformResponse,
        })
    },

    getOne(guest, appointmentId) {
        return axios.get(`/guests/${guest.id}/appointments/${appointmentId}`, transforms({ guest }))
    },

    cancel(guest, appointmentId) {
        return axios.post(
            `/guests/${guest.id}/appointments/${appointmentId}/cancel`,
            {},
            { transformResponse: transforms({ guest }).transformResponse },
        )
    },

    create(guest, data) {
        return axios.post(`/guests/${guest.id}/appointments`, data, transforms({ guest }))
    },

    reschedule(guest, appointmentId, data) {
        return axios.post(
            `/guests/${guest.id}/appointments/${appointmentId}/reschedule`,
            data,
            transforms({ guest }),
        )
    },

    rate(guest, appointmentId, data) {
        return axios.post(`/guests/${guest.id}/appointments/${appointmentId}/rate`, data)
    },

    markAsNoShow(guest, appointmentId, who) {
        const endpoint = {
            guest: `/guests/${guest.id}/appointments/${appointmentId}/guestNotShowedUp`,
            pro: `/guests/${guest.id}/appointments/${appointmentId}/stylistNotShowedUp`,
        }[who]

        return axios.post(endpoint, null, transforms({ guest }))
    },

    answerLocationQuestion(guest, appointmentId, locationQuestionAnswer) {
        return axios.post(
            `guests/${guest.id}/appointments/${appointmentId}/answerLocationQuestion`,
            { locationQuestionAnswer },
        )
    },
}
