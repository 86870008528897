<template>
    <div class="flex space-x-10">
        <component
            :is="starComponent(index)"
            v-for="index in 5"
            :key="index"
            class="w-30 h-30 text-gold"
            @click="change(index)"
        />
    </div>
</template>

<script>
    import MixinFormInput from '@/mixins/MixinFormInput'
    import IconStarFull from '@/assets/vectors/icon-star-full.svg'
    import IconStar from '@/assets/vectors/icon-star.svg'

    export default {
        components: {
            IconStarFull,
            IconStar,
        },

        mixins: [MixinFormInput],

        methods: {
            starComponent(index) {
                return index > this.value ? IconStar : IconStarFull
            },
        },
    }
</script>
