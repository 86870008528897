<template>
    <div class="flex items-stretch">
        <ProTile :pro="pro" :level="3" :is-price-displayed="false" class="w-6/12 pt-7/12" />
        <BaseMapAddress :address="address" class="w-6/12 min-h-full" />
    </div>
</template>

<script>
    import ProTile from '@/components/pro/ProTile'
    import BaseMapAddress from '@/components/base/BaseMapAddress'

    export default {
        components: {
            ProTile,
            BaseMapAddress,
        },

        props: {
            pro: { type: Object, required: true },
            address: { type: Object, required: true },
        },
    }
</script>
