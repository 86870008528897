<template>
    <ProSection
        :heading="$t('pro.profile.experiences.experience.steps.2.heading', { name: formData.name })"
    >
        <FormWrapper>
            <FieldTextarea
                :placeholder="$t('pro.profile.experiences.experience.steps.2.placeholder')"
                :has-bottom-border="false"
                :rows="5"
                :has-autofocus="true"
                :max-length="maxCharacters"
                :form-data="formData"
                :form-errors="formErrors"
                value-path="description"
                @change="change"
            />

            <BaseLimit :text="formData.description" :limit="maxCharacters" class="mt-10 ml-auto" />

            <slot name="buttons" :is-submittable="isSubmittable">
                <FormButtons>
                    <BaseButton
                        :label="$t('common.save')"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                        @click="save"
                    />
                </FormButtons>
            </slot>
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'
    import BaseButton from '@/components/base/BaseButton'
    import BaseLimit from '@/components/base/BaseLimit'

    export default {
        components: {
            ProSection,
            FormWrapper,
            FormButtons,
            FieldTextarea,
            BaseButton,
            BaseLimit,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormStep],

        computed: {
            isSubmittable() {
                return this.formData.description
            },

            maxCharacters() {
                return parseInt(process.env.VUE_APP_PRO_MAXLENGTH)
            },
        },
    }
</script>
