<script>
    import ApiProPortfolio from '@/apis/ApiProPortfolio'
    import ProProfilePortfolioAdd3 from '@/components/pro/profile/portfolio/ProProfilePortfolioAdd3'

    export default {
        extends: ProProfilePortfolioAdd3,

        computed: {
            work() {
                return this.formData
            },
        },

        methods: {
            deletePicture() {
                this.$ui
                    .confirm({
                        message: this.$t('pro.profile.portfolio.steps.3.delete.message'),
                    })
                    .then((isConfrmed) => {
                        if (!isConfrmed) {
                            return
                        }

                        ApiProPortfolio.delete(this.$auth.getUser().id, this.formData.id).then(() =>
                            this.$router.push('/pro/profile/portfolio'),
                        )
                    })
            },
        },
    }
</script>
