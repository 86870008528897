<template>
    <LayoutAdmin>
        <div class="flex flex-row p-30">
            <div class="flex flex-col w-250">
                <div class="flex flex-row">
                    <IconMenuPayments class="text-black w-25" />
                    <p class="ml-5 text-14 font-bold flex items-center uppercase">
                        {{ $t('admin.payments.serviceFee') }}
                    </p>
                </div>
                <div>
                    <div v-if="isEditingServiceFee" class="flex flex-row">
                        <div class="flex items-center">
                            <span class="text-35 font-bold mb-2 pr-5">$</span>
                            <input
                                v-model="serviceFee"
                                type="number"
                                class="w-80 text-35 font-bold border-b-1 border-transparent border-black"
                            />
                        </div>
                        <div class="ml-15 w-100">
                            <BaseButton
                                :label="$t('common.save')"
                                :level="1"
                                :is-disabled="!serviceFee"
                                :height="'h-50'"
                                @click="saveServiceFee"
                            />
                        </div>
                    </div>
                    <div v-else class="flex flex-row">
                        <span class="text-35 font-bold mb-2 pr-5">$</span>
                        <p class="text-35 font-bold">{{ serviceFee }}</p>
                        <div class="flex justify-center items-center cursor-pointer ml-20">
                            <IconModify class="h-20 w-20" @click="isEditingServiceFee = true" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-250">
                <div class="flex flex-row">
                    <IconMenuPayments class="text-black w-25" />
                    <p class="ml-5 text-14 font-bold flex items-center uppercase">
                        {{ $t('admin.payments.transactionFee') }}
                    </p>
                </div>
                <div>
                    <div v-if="isEditingTransactionFee" class="flex flex-row">
                        <div class="flex items-center">
                            <span class="text-35 font-bold mb-2 pr-5">$</span>
                            <input
                                v-model="transactionFee"
                                type="number"
                                class="w-80 text-35 font-bold border-b-1 border-transparent border-black"
                            />
                        </div>
                        <div class="ml-15 w-100">
                            <BaseButton
                                :label="$t('common.save')"
                                :level="1"
                                :is-disabled="!transactionFee"
                                :height="'h-50'"
                                @click="saveTransactionFee"
                            />
                        </div>
                    </div>
                    <div v-else class="flex flex-row">
                        <span class="text-35 font-bold mb-2 pr-5">$</span>
                        <p class="text-35 font-bold">{{ transactionFee }}</p>
                        <div class="flex justify-center items-center cursor-pointer ml-20">
                            <IconModify class="h-20 w-20" @click="isEditingTransactionFee = true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutAdmin>
</template>

<script>
    import ApiAdmins from '@/apis/ApiAdmins'
    import BaseButton from '@/components/base/BaseButton'
    import LayoutAdmin from '@/components/layouts/LayoutAdmin'
    import IconModify from '@/assets/vectors/icon-modify.svg'
    import IconMenuPayments from '@/assets/vectors/icon-menu-payments.svg'

    export default {
        components: {
            BaseButton,
            LayoutAdmin,
            IconModify,
            IconMenuPayments,
        },

        data() {
            return {
                isEditingServiceFee: false,
                isEditingTransactionFee: false,
            }
        },

        computed: {},

        async loadData() {
            const serviceFee = await ApiAdmins.getServiceFee()
            const transactionFee = await ApiAdmins.getTransactionFee()
            return {
                serviceFee: serviceFee.serviceFee,
                transactionFee: transactionFee.transactionFee,
            }
        },

        methods: {
            async saveServiceFee() {
                this.isEditingServiceFee = false
                await ApiAdmins.updateServiceFee(this.serviceFee)
            },

            async saveTransactionFee() {
                this.isEditingTransactionFee = false
                await ApiAdmins.updateTransactionFee(this.transactionFee)
            },
        },
    }
</script>

<style>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
