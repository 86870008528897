<template>
    <BaseBlankSlate :message="$t('pro.clients.index.empty')">
        <template #cta>
            <div class="flex flex-col items-center space-y-5">
                <IconPeople class="w-85 text-gray-5" />

                <button
                    type="button"
                    class="block mx-auto h-90 w-90 flex items-center justify-center rounded-md border-5 border-current text-purple"
                    @click="isPanelOpen = true"
                >
                    <IconPlus class="h-35 w-35" />
                </button>
            </div>

            <BaseDrawer :is-open="isPanelOpen" slide-from="bottom" @close="isPanelOpen = false">
                <div class="p-20 space-y-30 text-center">
                    <BaseHeading
                        :heading="$t('pro.clients.index.actions.choose')"
                        :level="2"
                        class="px-30"
                    />

                    <BaseButton
                        :label="$t('pro.clients.index.actions.import')"
                        :level="1"
                        route="/pro/clients/import"
                    />

                    <BaseButton
                        :label="$t('pro.clients.index.actions.manually')"
                        :level="2"
                        route="/pro/clients/add"
                    />
                </div>
            </BaseDrawer>
        </template>
    </BaseBlankSlate>
</template>

<script>
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import BaseBlankSlate from '@/components/base/BaseBlankSlate'
    import IconPlus from '@/assets/vectors/icon-plus.svg'
    import IconPeople from '@/assets/vectors/icon-people.svg'

    export default {
        components: {
            BaseDrawer,
            BaseHeading,
            BaseButton,
            BaseBlankSlate,
            IconPlus,
            IconPeople,
        },

        data() {
            return {
                isPanelOpen: false,
            }
        },
    }
</script>
