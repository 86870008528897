<template>
    <button type="button" :class="buttonClass" @click="$emit('click')">
        <component :is="iconComponent" class="h-20 w-20" />
    </button>
</template>

<script>
    import IconModify from '@/assets/vectors/icon-modify.svg'

    export default {
        props: {
            hasBg: { type: Boolean, default: false },
        },

        emits: ['click'],

        computed: {
            iconComponent() {
                return IconModify
            },

            buttonClass() {
                return {
                    'p-5': this.hasBg,
                    'bg-white': this.hasBg,
                }
            },
        },
    }
</script>
