<template>
    <LayoutAdmin @select-period="loadNewReservations">
        <div class="h-full p-30 flex flex-col justfy-between">
            <div class="flex flex-col">
                <div class="text-center text-16 font-black tracking-widest uppercase">
                    {{ totalReservations }} {{ $t('admin.dashboard.reservations.label') }}
                </div>
                <div class="flex flex-row justify-between items-center mt-30">
                    <InputMultiSelect
                        :options="options"
                        :placeholder="$t('admin.dashboard.selectCategory')"
                        :value="filter"
                        :select-all-option="selectAllOption"
                        class="w-250"
                        @change="onFilterChange"
                    />

                    <div class="flex flex-col justify-center items-center">
                        <div class="flex flex-row tracking-wider">
                            <IconMenuPayments class="color-black w-20" />
                            <span class="ml-10 uppercase">
                                {{ $t('admin.dashboard.total') }}
                            </span>
                        </div>
                        <span class="text-35 font-bold">$ {{ totalAmount }}</span>
                    </div>

                    <div class="flex justify-end w-250 cursor-pointer">
                        <IconExport class="w-40" @click="exportNewReservations" />
                    </div>
                </div>
            </div>

            <BaseTable
                ref="table"
                :headers="tableHeaders"
                class="mt-30"
                @goToPage="loadNewReservations"
            >
                <tr
                    v-for="reservation in reservations"
                    :key="reservation.id"
                    class="border-b-1 border-gray-4"
                >
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <BaseAvatar
                                :photo="reservation.guest.profilePicture"
                                class="h-50 min-w-50 w-50 rounded-full"
                            />
                            <div class="ml-10 font-bold uppercase">
                                {{ reservation.guest.name }}
                            </div>
                        </div>
                    </td>
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <IconGuest
                                v-if="reservation.workPlace === 'GuestsPlace'"
                                class="h-22"
                            />
                            <IconPro v-else class="h-22" />
                            <span class="ml-10">{{ reservation.location }}</span>
                        </div>
                    </td>
                    <td class="p-5">{{ reservation.experiences.join(', ') }}</td>
                    <td class="p-5">{{ reservation.date }}</td>
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <BaseAvatar
                                :photo="reservation.pro.profilePicture"
                                class="h-50 min-w-50 w-50 rounded-full"
                            />
                            <div class="flex flex-col ml-10">
                                <div class="font-bold uppercase">{{ reservation.pro.name }}</div>
                                <div class="text-gray-3">{{ reservation.pro.profileUrl }}</div>
                            </div>
                        </div>
                    </td>
                    <td class="p-5 text-center">${{ reservation.ticket }}</td>
                </tr>
            </BaseTable>
        </div>
    </LayoutAdmin>
</template>

<script>
    import dayjs from 'dayjs'
    import ApiAdmins from '@/apis/ApiAdmins'
    import BaseTable from '@/components/base/BaseTable'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import IconGuest from '@/assets/vectors/icon-guest.svg'
    import IconExport from '@/assets/vectors/icon-export.svg'
    import LayoutAdmin from '@/components/layouts/LayoutAdmin'
    import IconMenuPayments from '@/assets/vectors/icon-menu-payments.svg'
    import InputMultiSelect from '@/components/form-inputs/InputMultiSelect'

    export default {
        components: {
            IconPro,
            IconGuest,
            BaseTable,
            IconExport,
            BaseAvatar,
            LayoutAdmin,
            IconMenuPayments,
            InputMultiSelect,
        },

        data() {
            return {
                filter: [],
                totalAmount: 0,
                totalReservations: 0,
                reservations: [],
                startDate: dayjs(),
                endDate: dayjs(),
            }
        },

        computed: {
            selectAllOption() {
                return {
                    label: this.$t('admin.dashboard.all'),
                    value: 'all',
                }
            },

            tableHeaders() {
                return [
                    { label: this.$t('admin.dashboard.table.guest'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.location'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.experiences'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.appointmentDate'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.pro'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.ticket') },
                ]
            },

            categories() {
                return this.$store.getters['dictionaries/getCategories'].map((category) => ({
                    value: category.id,
                    label: category.name,
                }))
            },

            options() {
                return [this.selectAllOption, ...this.categories]
            },
        },

        created() {
            this.filter = [this.selectAllOption, ...this.categories]
        },

        methods: {
            async onFilterChange(options) {
                await this.loadNewReservations({ filter: options })
            },

            async loadNewReservations({ startDate, endDate, page, filter }) {
                if (startDate) {
                    this.startDate = startDate
                }
                if (endDate) {
                    this.endDate = endDate
                }
                if (filter) {
                    this.filter = filter
                }

                this.$refs.table.currentPage = page ? page : 1
                const result = await ApiAdmins.getNewReservations({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    page: this.$refs.table.currentPage,
                    categoryIds: this.filter?.filter(
                        (item) => item.value != this.selectAllOption.value,
                    ),
                })

                this.$refs.table.maxPage = result.maxPage

                this.totalAmount = result.totalAmount
                this.reservations = result.newReservations
                this.totalReservations = result.totalReservations

                this.$refs.table.calculatePages()
            },

            async exportNewReservations() {
                const response = await ApiAdmins.exportNewReservations({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    categoryIds: this.filter?.filter(
                        (item) => item.value != this.selectAllOption.value,
                    ),
                })
                var fileLink = document.createElement('a')

                fileLink.href =
                    'data:text/csv;charset=utf-8, ' + escape(Object.values(response.data).join(''))

                const format = 'DD.MM.YY'
                const start = this.startDate.format(format)
                const end = this.endDate.format(format)
                const fileName = `New_Reservations_${start}-${end}.csv`
                fileLink.setAttribute('download', fileName)
                document.body.appendChild(fileLink)

                fileLink.click()
            },
        },
    }
</script>
