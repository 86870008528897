<template>
    <ProOnboardingStep
        :heading="$t('pro.onboarding.address.header.heading')"
        @submit="goToNextStep"
    >
        <FormFields>
            <UserFormAddress
                :form-data="formData"
                :form-errors="formErrors"
                :has-autofocus="true"
                @change="change"
            />
        </FormFields>

        <FormButtons>
            <BaseButton :label="$t('common.next')" :is-disabled="!isSubmittable" />
        </FormButtons>
    </ProOnboardingStep>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProOnboardingStep from '@/components/pro/onboarding/ProOnboardingStep'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import UserFormAddress from '@/components/user/UserFormAddress'

    export default {
        components: {
            ProOnboardingStep,
            FormFields,
            FormButtons,
            BaseButton,
            UserFormAddress,
        },

        mixins: [MixinFormStep],

        computed: {
            isSubmittable() {
                return (
                    this.formData.address &&
                    this.formData.city &&
                    this.formData.state &&
                    this.formData.zipCode
                )
            },
        },
    }
</script>
