<template>
    <div class="flex-grow flex flex-col justify-center text-center space-y-30">
        <BaseSubheading :subheading="message" />

        <div v-if="$slots.cta || ctaLabel">
            <slot name="cta">
                <BaseButton v-if="ctaLabel" :label="ctaLabel" :route="ctaRoute" :level="2" />
            </slot>
        </div>
    </div>
</template>

<script>
    import BaseSubheading from '@/components/base/BaseSubheading'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            BaseSubheading,
            BaseButton,
        },

        props: {
            message: { type: String, required: true },
            ctaLabel: { type: String, default: null },
            ctaRoute: { type: [Object, String], default: null },
        },
    }
</script>
