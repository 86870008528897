<template>
    <div class="space-y-40">
        <BaseHeading :level="3">
            <span>{{ $t('guest.appointments.rate.payment-processed') }}</span>
            <IconSuccess
                class="inline-block h-35 w-35 text-green animate-pop ml-15 align-baseline -mb-5"
            />
        </BaseHeading>

        <section class="space-y-20">
            <BaseHeaderDivider :heading="$t('guest.appointments.rate.payment-details')" />

            <div class="flex w-full items-center">
                <label class="text-16 uppercase font-black">
                    {{ $t('guest.appointments.rate.total-paid') }}
                </label>

                <div class="ml-auto text-24 font-bold">$ {{ formData.tip }}</div>
            </div>
        </section>

        <GuestStripe :is-preview="true" />

        <!-- INFO: Below component is hidden as we do not yet support recurrent appointments. -->
        <GuestAppointmentsRateRecurrent v-if="false" :appointment="appointment" />
    </div>

    <FormButtons>
        <BaseButton
            :label="$t('guest.appointments.my-appointments')"
            route="/guest/appointments/previous"
        />
    </FormButtons>
</template>

<script>
    import GuestAppointmentsRatePaid3 from '@/components/guest/appointments/rate/GuestAppointmentsRatePaid3'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import GuestStripe from '@/components/guest/GuestStripe'
    import FormButtons from '@/components/form-elements/FormButtons'
    import GuestAppointmentsRateRecurrent from '@/components/guest/appointments/rate/GuestAppointmentsRateRecurrent'
    import IconSuccess from '@/assets/vectors/icon-success.svg'

    export default {
        components: {
            BaseHeading,
            BaseButton,
            BaseHeaderDivider,
            GuestStripe,
            GuestAppointmentsRateRecurrent,
            FormButtons,
            IconSuccess,
        },

        extends: GuestAppointmentsRatePaid3,
    }
</script>
