export const transformGuest = (data) => {
    return {
        ...data,
        profilePicture: data.profilePicture?.pictureLocation,
    }
}

export default (data) => {
    return Array.isArray(data) ? data.map(transformGuest) : transformGuest(data)
}
