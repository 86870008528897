<template>
    <div class="space-y-40">
        <BaseHeading
            :heading="$t('guest.appointments.rate.how-rate', { name: appointment.pro.firstName })"
            :level="2"
            class="text-center"
        />

        <section class="space-y-20 flex flex-col items-center">
            <BaseAvatar
                :photo="appointment.pro.profilePicture"
                class="rounded-full h-70 w-70 mx-auto"
            />

            <BaseHeading
                :heading="$t('guest.appointments.rate.rate-x', { name: appointment.pro.firstName })"
                :level="5"
                class="text-center"
            />

            <FieldRating :form-data="formData" value-path="rating" @change="change" />
        </section>

        <section :class="noteClass">
            <BaseHeaderDivider :heading="$t('guest.appointments.rate.write-review.heading')" />

            <FieldTextarea
                :placeholder="$t('guest.appointments.rate.write-review.hint')"
                :has-autofocus="true"
                :rows="5"
                :has-bottom-border="false"
                :form-data="formData"
                value-path="note"
                @change="change"
            />
        </section>
    </div>

    <TransitionSlide from="bottom">
        <FormButtons v-if="isSubmittable">
            <BaseButton
                :label="$t('common.confirm')"
                :is-processing="isSubmitting"
                @click="goToNextStep"
            />
        </FormButtons>
    </TransitionSlide>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import FieldRating from '@/components/form-fields/FieldRating'
    import FieldTextarea from '@/components/form-fields/FieldTextarea'
    import FormButtons from '@/components/form-elements/FormButtons'
    import TransitionSlide from '@/components/transitions/TransitionSlide'

    export default {
        components: {
            BaseAvatar,
            BaseHeading,
            BaseButton,
            BaseHeaderDivider,
            FieldRating,
            FieldTextarea,
            FormButtons,
            TransitionSlide,
        },

        mixins: [MixinFormStep, MixinFormSubmittable],

        props: {
            appointment: { type: Object, required: true },
        },

        computed: {
            isSubmittable() {
                return this.formData.rating
            },

            noteClass() {
                return {
                    'space-y-20': true,
                    'transition-opacity': true,
                    'opacity-30': !this.isSubmittable,
                }
            },
        },
    }
</script>
