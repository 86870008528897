<template>
    <ul class="space-y-10">
        <li v-for="(item, index) in items" :key="index">
            <button
                v-if="item.event"
                :class="itemClass(item)"
                :disabled="item.isDisabled"
                type="button"
                @click="emitClick(item)"
            >
                <slot name="label" :item="item">
                    <span>{{ item.label }}</span>
                </slot>

                <span v-if="item.badge" :class="badgeClass">
                    {{ item.badge }}
                </span>
            </button>

            <RouterLink v-else-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a
                    :href="href"
                    :class="itemClass(item)"
                    @click="emitClickAndNavigate(item, navigate, $event)"
                >
                    <slot name="label" :item="item">
                        <span>{{ item.label }}</span>
                    </slot>

                    <span v-if="item.badge" :class="badgeClass">
                        {{ item.badge }}
                    </span>
                </a>
            </RouterLink>

            <a
                v-else-if="item.href"
                :href="item.href"
                :class="itemClass(item)"
                @click="emitClick(item)"
            >
                <slot name="label" :item="item">
                    <span>{{ item.label }}</span>
                </slot>

                <span v-if="item.badge" :class="badgeClass">
                    {{ item.badge }}
                </span>
            </a>

            <slot v-if="item.slot" :name="item.slot" />
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            items: { type: Array, required: true },
        },

        emits: ['click-item'],

        computed: {
            badgeClass() {
                return {
                    'flex': true,
                    'ml-10': true,
                    'tracking-tight': true,
                    'items-center': true,
                    'justify-center': true,
                    'rounded-full': true,
                    'bg-notification': true,
                    'text-white': true,
                    'text-11': true,
                    'font-bold': true,
                    'h-23': true,
                    'min-w-23': true,
                    'px-2': true,
                    'mb-2': true,
                    'ml-2': true,
                }
            },
        },

        methods: {
            itemClass({ isDisabled }) {
                return {
                    'h-40': true,
                    'w-full': true,
                    'flex': true,
                    'items-center': true,
                    'font-medium': true,
                    // TODO: Add hover effect?
                    'cursor-pointer': isDisabled,
                    'text-gray-4': isDisabled,
                }
            },

            emitClick(item) {
                this.$emit('click-item', item)
            },

            emitClickAndNavigate(item, navigate, $event) {
                this.emitClick(item)
                navigate($event)
            },
        },
    }
</script>
