import MiddlewareRequireLoggedIn from '@/router/middlewares/MiddlewareRequireLoggedIn'
import MiddlewareRequireGuest from '@/router/middlewares/MiddlewareRequireGuest'

const usualMiddlewares = [MiddlewareRequireLoggedIn, MiddlewareRequireGuest]

export default [
    {
        path: '/book',
        view: 'book',
    },
    ...['future', 'previous', 'cancelled'].map((type) => ({
        path: `/guest/appointments/:type(${type})`,
        view: `guest/appointments/${type}`,
        middlewares: usualMiddlewares,
    })),
    {
        path: '/guest/appointments/:id(\\d+)',
        view: 'guest/appointments/_id/index',
        middlewares: usualMiddlewares,
    },
    ...['reschedule', 'repeat', 'rate'].map((slug) => ({
        path: `/guest/appointments/:id(\\d+)/${slug}`,
        view: `guest/appointments/_id/${slug}`,
        middlewares: usualMiddlewares,
    })),
    {
        path: '/guest/profile',
        view: 'guest/profile',
        middlewares: usualMiddlewares,
    },
    {
        path: '/guest/verify',
        view: 'guest/verify',
        middlewares: usualMiddlewares,
    },
]
