<template>
    <GuestVerifyUpload
        :copy-default="$t('guest.verify.provide-id')"
        :copy-provided="$t('guest.verify.your-id')"
        :photo="formData.ID"
        @select="selectId"
        @confirm="goToNextStep"
    >
        <template #select>
            <img
                :src="require('@/assets/images/picture-verify-id.png').default"
                class="h-170 object-contain mb-20"
            />
        </template>
    </GuestVerifyUpload>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import GuestVerifyUpload from '@/components/guest/verify/GuestVerifyUpload'

    export default {
        components: {
            GuestVerifyUpload,
        },

        mixins: [MixinFormStep],

        methods: {
            selectId(value) {
                this.change({ path: 'ID', value })
            },
        },
    }
</script>
