<template>
    <LayoutOverlay back-button="/pro/profile/experiences">
        <header class="sticky z-layout top-70 bg-white">
            <BaseHeading :heading="subCategory.name" :level="5" class="text-center py-30" />
        </header>

        <div class="pb-20">
            <div
                v-for="(experience, index) in experiences"
                :key="index"
                :class="experienceClass(index)"
            >
                <ProProfileExperiencesSubCategoriesExperience :experience="experience" />
            </div>
        </div>

        <FormButtons class="-shadow-md">
            <ProProfileAdd
                :label="$t('pro.profile.experiences.add-another')"
                route="/pro/profile/experiences/add"
            />
        </FormButtons>
    </LayoutOverlay>
</template>

<script>
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import BaseHeading from '@/components/base/BaseHeading'
    import ProProfileAdd from '@/components/pro/profile/ProProfileAdd'
    import FormButtons from '@/components/form-elements/FormButtons'
    import ProProfileExperiencesSubCategoriesExperience from '@/components/pro/profile/experiences/subcategories/ProProfileExperiencesSubCategoriesExperience'

    export default {
        components: {
            LayoutOverlay,
            BaseHeading,
            ProProfileAdd,
            FormButtons,
            ProProfileExperiencesSubCategoriesExperience,
        },

        mixins: [MixinProExperiences],

        loadData({ $router, $route, $auth }) {
            const user = $auth.getUser()
            const subCategory = user.subCategories.find(({ id }) => id == $route.params.id)

            if (!subCategory) {
                // TODO: Throw error if subCategory does not exist.
            }

            const experiences = user.experiences.filter(({ subCategories }) =>
                subCategories.some(({ id }) => id === subCategory?.id),
            )

            if (experiences.length === 0) {
                $router.push('/pro/profile/experiences/add')
            }

            return {
                subCategory,
                experiences,
            }
        },

        methods: {
            experienceClass(index) {
                return {
                    'mt-30': index > 0,
                    'pt-30': index > 0,
                    'border-t-1': index > 0,
                    'border-gray-5': index > 0,
                }
            },
        },
    }
</script>
