<template>
    <section class="space-y-30">
        <template v-if="hasImages">
            <BaseHeaderDivider :heading="$t('pro.clients.show.sections.images')" />

            <ProGallery :items="images" :is-deletable="isEditable" @delete="confirmDelete" />
        </template>

        <BaseLink
            class="flex items-center space-x-15 text-left"
            @click="$emit('add', { index: images.length, image: {} })"
        >
            <IconImage class="h-28" />
            <span>{{ $t('pro.clients.show.add-images') }}</span>
        </BaseLink>
    </section>
</template>

<script>
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseLink from '@/components/base/BaseLink'
    import ProGallery from '@/components/pro/ProGallery'
    import IconImage from '@/assets/vectors/icon-image.svg'

    export default {
        components: {
            BaseHeaderDivider,
            BaseLink,
            ProGallery,
            IconImage,
        },

        props: {
            client: { type: Object, required: true },
            isEditable: { type: Boolean, default: false },
        },

        emits: ['add', 'edit', 'delete'],

        computed: {
            hasImages() {
                return this.images.length > 0
            },

            images() {
                // Using Object.values here as images are set by index, so instead of array,
                // it creates an object with property names 0, 1, etc.
                return Object.values(this.client.images || [])
            },
        },

        methods: {
            confirmDelete({ index, item: image }) {
                this.$ui
                    .confirm({ message: this.$t('pro.clients.show.delete-image') })
                    .then((isConfirmed) => isConfirmed && this.$emit('delete', { index, image }))
            },
        },
    }
</script>
