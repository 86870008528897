<template>
    <RouterLink
        v-if="isRepeatable"
        :to="repeatRoute"
        class="mt-auto relative z-above flex flex-col items-center text-purple hoverable:hover:text-black"
    >
        <IconRepeat class="w-25 h-20 mb-5" />
        <span class="text-9 font-bold tracking-widest">
            {{ $t('guest.appointments.repeat') }}
        </span>
    </RouterLink>

    <RouterLink
        v-else-if="isRatable"
        :to="rateRoute"
        class="mt-auto w-40 text-center relative z-above flex flex-col items-center text-purple hoverable:hover:text-black"
    >
        <IconRate class="w-25 h-20 mb-5" />
        <span class="text-9 font-bold tracking-widest">
            {{ $t('guest.appointments.rate-x', { name: appointment.pro.firstName }) }}
        </span>
    </RouterLink>
</template>

<script>
    import MixinAppointment from '@/mixins/MixinAppointment'
    import IconRepeat from '@/assets/vectors/icon-repeat.svg'
    import IconRate from '@/assets/vectors/icon-rate.svg'

    export default {
        components: {
            IconRepeat,
            IconRate,
        },

        mixins: [MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
        },

        computed: {
            repeatRoute() {
                return `/guest/appointments/${this.appointment.id}/repeat`
            },

            rateRoute() {
                return `/guest/appointments/${this.appointment.id}/rate`
            },
        },
    }
</script>
