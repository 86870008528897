export default [
    {
        path: '/',
        view: 'landing/index',
    },
    {
        path: '/safety',
        view: 'landing/safety',
    },
    {
        path: '/for-pros',
        view: 'landing/for-pros',
    },
    {
        path: '/contact',
        view: 'landing/contact',
    },
    ...(process.env.VUE_APP_SENTRY_ENV === 'development'
        ? [
              {
                  path: '/privacy',
                  view: 'landing/privacy',
              },
              {
                  path: '/terms',
                  view: 'landing/terms',
              },
          ]
        : []),
]
