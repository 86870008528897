<template>
    <LayoutLanding>
        <LandingHeader :has-transition-bg="true" :is-transparent="true" />
        <LandingProsIntro />
        <LandingProsVisionaries />
        <LandingProsPlaces />
        <LandingProsValues />
        <LandingProsStart />
        <LandingProsTools />
        <LandingProsFaq />
        <LandingProsEnough />
    </LayoutLanding>
</template>

<script>
    import LayoutLanding from '@/components/layouts/LayoutLanding'
    import LandingHeader from '@/components/landing/LandingHeader'
    import LandingProsIntro from '@/components/landing/pros/LandingProsIntro'
    import LandingProsVisionaries from '@/components/landing/pros/LandingProsVisionaries'
    import LandingProsPlaces from '@/components/landing/pros/LandingProsPlaces'
    import LandingProsValues from '@/components/landing/pros/LandingProsValues'
    import LandingProsStart from '@/components/landing/pros/LandingProsStart'
    import LandingProsTools from '@/components/landing/pros/LandingProsTools'
    import LandingProsFaq from '@/components/landing/pros/LandingProsFaq'
    import LandingProsEnough from '@/components/landing/pros/LandingProsEnough'

    export default {
        components: {
            LayoutLanding,
            LandingHeader,
            LandingProsIntro,
            LandingProsVisionaries,
            LandingProsPlaces,
            LandingProsValues,
            LandingProsStart,
            LandingProsTools,
            LandingProsFaq,
            LandingProsEnough,
        },
    }
</script>
