<template>
    <BaseOverlay :is-open="isOpen" class="flex items-center justify-center" @close="close">
        <TransitionScale>
            <div
                v-if="appointment"
                class="relative p-50 bg-white shadow-md flex flex-col space-y-20 max-h-vh overflow-y-auto overflow-x-hidden"
            >
                <button class="ml-auto -mt-30 -mr-30" @click="close">
                    <IconClose class="w-25 h-25" />
                </button>
                <p class="w-300 font-bold text-30 text-center">
                    {{ $t('admin.editUser') }}
                </p>

                <ProAddress :pro="appointment.pro" :address="appointmentAddress" />

                <BaseDatetime :datetime="appointmentStartDateTime" />
                <BaseReceipt :positions="appointmentPrices(true)" />
            </div>
        </TransitionScale>
    </BaseOverlay>
</template>

<script>
    import ApiAdmins from '@/apis/ApiAdmins'
    import ProAddress from '@/components/pro/ProAddress'
    import IconClose from '@/assets/vectors/icon-close.svg'
    import BaseOverlay from '@/components/base/BaseOverlay'
    import BaseReceipt from '@/components/base/BaseReceipt'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import BaseDatetime from '@/components/base/BaseDatetime'
    import TransitionScale from '@/components/transitions/TransitionScale'

    export default {
        components: {
            IconClose,
            ProAddress,
            BaseReceipt,
            BaseOverlay,
            BaseDatetime,
            TransitionScale,
        },

        mixins: [MixinAppointment],

        props: {
            isOpen: { type: Boolean, default: false },
            appointmentId: { type: Number, required: true },
        },

        emits: ['close'],

        data() {
            return {
                appointment: null,
            }
        },

        watch: {
            isOpen: {
                immediate: true,
                async handler(isOpen) {
                    if (!isOpen) {
                        return
                    }

                    this.appointment = await ApiAdmins.getAppointmentInfo(this.appointmentId)
                },
            },
        },

        methods: {
            close() {
                this.$emit('close')
            },
        },
    }
</script>
