<template>
    <BaseOverlay :is-open="isOpen" class="flex items-center justify-center">
        <TransitionScale>
            <div v-if="isOpen" class="relative p-20 bg-white space-y-20 shadow-md max-w-400">
                <p class="uppercase font-black text-14 text-center tracking-widest">
                    {{ message }}
                </p>

                <div v-if="note">
                    <component :is="note?.component" v-if="note?.component" v-bind="note?.props" />
                    <BaseNote v-else :content="note" />
                </div>

                <div class="space-y-40">
                    <BaseButton
                        v-if="confirm !== false"
                        :label="confirm || $t('common.confirm')"
                        :is-submitting="false"
                        @click="$store.dispatch('ui/confirm/confirm')"
                    />

                    <BaseButton
                        v-if="cancel !== false"
                        :label="cancel || $t('common.cancel')"
                        :level="2"
                        :is-submitting="false"
                        @click="$store.dispatch('ui/confirm/cancel')"
                    />
                </div>
            </div>
        </TransitionScale>
    </BaseOverlay>
</template>

<script>
    import { mapState } from 'vuex'
    import TransitionScale from '@/components/transitions/TransitionScale'
    import BaseNote from '@/components/base/BaseNote'
    import BaseOverlay from '@/components/base/BaseOverlay'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            TransitionScale,
            BaseNote,
            BaseOverlay,
            BaseButton,
        },

        computed: mapState('ui/confirm', ['isOpen', 'message', 'note', 'cancel', 'confirm']),
    }
</script>
