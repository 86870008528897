<script>
    import BaseButtonEdit from '@/components/base/BaseButtonEdit'
    import IconDelete from '@/assets/vectors/icon-delete.svg'

    export default {
        extends: BaseButtonEdit,

        computed: {
            iconComponent() {
                return IconDelete
            },
        },
    }
</script>
