<template>
    <div>
        <div :class="topClass">
            <PictureLandingQuote :class="quoteClass" />
            <div :class="borderClass" />
        </div>

        <blockquote class="space-y-10 md:space-y-20 leading-loose">
            <p class="text-purple text-12 xs:text-15 sm:text-17 md:text-20">
                {{ content }}
            </p>

            <small class="block text-gray-3 text-12 xs:text-15 sm:text-17 md:text-20">
                {{ author }}
            </small>
        </blockquote>
    </div>
</template>

<script>
    import PictureLandingQuote from '@/assets/vectors/picture-landing-quote.svg'

    export default {
        components: {
            PictureLandingQuote,
        },

        props: {
            content: { type: String, required: true },
            author: { type: String, required: true },
            level: { type: Number, required: true },
        },

        computed: {
            topClass() {
                return {
                    'flex': true,
                    'items-center': true,
                    'mb-15': true,
                    'xs:mb-20': true,
                    'w-6/12': true,
                    'md:w-7/12': true,
                    'lg:mb-40': this.level === 1,
                }
            },

            quoteClass() {
                return {
                    'flex-shrink-0': true,
                    'text-purple': true,
                    'w-50': this.level === 1,
                    'sm:w-3/12': this.level === 1,
                    'lg:w-5/12': this.level === 1,
                    'w-4/12': this.level === 2,
                }
            },

            borderClass() {
                return {
                    'bg-purple': true,
                    'h-1': true,
                    'w-full': true,
                    'ml-20': true,
                    'xs:ml-30': true,
                    'md:ml-40': true,
                }
            },
        },
    }
</script>
