import axios from 'axios'
import transformProClientFromApi from '@/apis/transforms/transformProClientFromApi'
import transformProClientToApi from '@/apis/transforms/transformProClientToApi'
import transformProClientImagesToApi from '@/apis/transforms/transformProClientImagesToApi'

const transforms = {
    transformRequest: [transformProClientToApi, ...axios.defaults.transformRequest],
    transformResponse: [...axios.defaults.transformResponse, transformProClientFromApi],
}

export default {
    get(proId, params) {
        return axios.get(`/stylists/${proId}/clientBook`, {
            params,
            transformResponse: transforms.transformResponse,
        })
    },

    getOne(proId, id) {
        return axios.get(`/stylists/${proId}/clientBook/${id}`, {
            transformResponse: transforms.transformResponse,
        })
    },

    create(proId, data) {
        return axios.post(`/stylists/${proId}/clientBook`, data, transforms)
    },

    update(proId, clientId, section, data) {
        return axios.patch(`/stylists/${proId}/clientBook/${clientId}/${section}`, data)
    },

    updateProfilePicture(proId, clientId, data) {
        const formData = new FormData()
        formData.append('profilePicture', data.profilePicture)

        return axios.patch(`/stylists/${proId}/clientBook/${clientId}/profilePicture`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    updateNote(proId, clientId, noteId, data) {
        return axios.patch(`/stylists/${proId}/clientBook/${clientId}/notes/${noteId}`, data)
    },

    createNote(proId, clientId, data) {
        return axios.post(`/stylists/${proId}/clientBook/${clientId}/notes`, data)
    },

    createImage(proId, clientId, data) {
        return axios.post(`/stylists/${proId}/clientBook/${clientId}/images`, [data], {
            transformRequest: [transformProClientImagesToApi, ...axios.defaults.transformRequest],
        })
    },

    delete(proId, clientId) {
        return axios.delete(`/stylists/${proId}/clientBook/${clientId}`)
    },

    deleteNote(proId, clientId, noteId) {
        return axios.delete(`/stylists/${proId}/clientBook/${clientId}/notes/${noteId}`)
    },

    deleteImage(proId, clientId, imageId) {
        return axios.delete(`/stylists/${proId}/clientBook/${clientId}/images/${imageId}`)
    },

    import(proId, data) {
        return axios.post(`/stylists/${proId}/clientBook/importClient`, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    checkIfGuestInClientBook(proId, guestId) {
        return axios
            .get(`/stylists/${proId}/clientBook/isClient/${guestId}`)
            .then(({ isClient }) => isClient)
    },
}
