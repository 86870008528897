import axios from 'axios'

export default {
    get(proId, params) {
        return axios.get(`/stylists/${proId}/calendarEvents`, { params })
    },

    create(proId, data) {
        return axios.post(`/stylists/${proId}/calendarEvents`, data)
    },

    update(proId, eventId, data) {
        return axios.put(`/stylists/${proId}/calendarEvents/${eventId}`, data)
    },

    delete(proId, eventId) {
        return axios.delete(`/stylists/${proId}/calendarEvents/${eventId}`)
    },
}
