<template>
    <component :is="`h${level}`" :class="headingClass" class="leading-heading">
        <slot />
    </component>
</template>

<script>
    export default {
        props: {
            level: { type: Number, required: true },
        },

        computed: {
            headingClass() {
                return {
                    1: 'text-24 md:text-40 lg:text-55 font-bold',
                    2: 'text-24 md:text-35 lg:text-45 font-bold',
                    3: 'text-24 md:text-30 lg:text-35 font-bold md:font-semibold',
                    4: 'text-22 md:text-30 lg:text-35',
                    5: 'text-16 xs:text-18 sm:text-20 md:text-24 font-semibold',
                }[this.level]
            },
        },
    }
</script>
