<template>
    <TransitionSlide from="top">
        <section
            v-if="alerts.length"
            class="fixed top-70 right-0 left-0 z-alert bg-white shadow-md border-l-4 border-orange p-15 space-y-15"
        >
            <component :is="alerts[currentAlert].component" />

            <footer v-if="alerts.length > 1" class="flex items-center justify-center space-x-10">
                <template v-for="(alert, index) in alerts" :key="alert.id">
                    <BaseCircle
                        :label="index + 1"
                        :is-selected="index === currentAlert"
                        @click="currentAlert = index"
                    />
                </template>
            </footer>
        </section>
    </TransitionSlide>
</template>

<script>
    import TransitionSlide from '@/components/transitions/TransitionSlide'
    import BaseCircle from '@/components/base/BaseCircle'

    export default {
        components: {
            TransitionSlide,
            BaseCircle,
        },

        data() {
            return {
                currentAlert: 0,
            }
        },

        computed: {
            alerts() {
                return this.$store.getters['ui/alerts/getAlerts']
            },
        },
    }
</script>
