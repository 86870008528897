export default {
    book: {
        subcategory: 'Search for…',
        address: 'Where?',
        cta: 'Book experience',
    },
    header: {
        slogan: 'Beauty, Grooming & Wellness on demand',
        pro: {
            question: 'Are you a pro?',
            join: 'Join the revolution',
        },
    },
    index: {
        more: 'Learn More',
        intro: {
            heading: {
                1: 'Beauty,',
                2: 'Grooming',
                3: '&',
                4: 'Wellness,',
                5: 'on your terms.',
            },
            description:
                'Your place or theirs, your neighborhood’s most talented freelance pros are waiting to serve up beauty, grooming and wellness experiences.',
            pro: {
                question: 'Want to be a Freebyrdz Pro?',
                join: 'Join the revolution',
            },
        },
        explore: {
            heading: 'Explore popular experiences',
            experiences: {
                massage: 'Massage',
                makeup: 'Makeup',
                lashes: 'Lashes',
                skincare: 'Skincare',
                waxing: 'Waxing',
                yoga: 'Yoga',
                blowout: 'Blowout',
                haircut: 'Men’s haircuts',
                training: 'Personal training',
                cycling: 'Cycling',
                nutrition: 'Nutrition/Dietician',
            },
        },
        safety: {
            heading: 'Safety matters.',
            description:
                'We take safety seriously. For instance, both clients and pros are required to verify their identity.',
            book: 'Book your experience',
            quote: {
                content:
                    'Completing an identity verification made me feel confident booking my reservation.',
                author: 'Ella Ming · Boston',
            },
        },
        places: {
            heading: {
                1: 'Your place or theirs:',
                2: 'you decide.',
            },
            description:
                'Talented people live and work in your neighborhood. We’re here to help you connect with them, when you want, where you want.',
        },
        pros: {
            heading: {
                1: 'Pros, Join now:',
                2: 'it’s FREE',
            },
            description: 'The revolution is here. Start growing your business NOW.',
            signup: 'Sign up',
        },
    },
    safety: {
        intro: {
            heading: 'Safety starts with us.',
            description:
                'We’re providing the technology to power safe experiences for all. Take a look at how we’re helping our Freebyrdz community do their best work in the safest way possible.',
        },
        tech: {
            heading: 'Some of the tech that helps power your experiences.',
            identity: {
                heading: 'Identity verification.',
                description:
                    'You’ll notice, as a Pro or a Client, you’ll have to verify your identity. Pros go through an additional identity check when they set up their payment processing account through our payments partner, STRIPE.',
            },
            licenses: {
                heading: 'Licenses Listed.',
                description:
                    'Some services require state-issued licenses. That’s why we’ve given Pros the option to list their state-issued license number on their profile. You can even look them up on your local, state website.',
            },
            barbicide: {
                heading: 'Barbicide Certified.',
                description:
                    'Since its launch in 1947, Barbicide has become the beauty and barber industry’s standard for safe services. Look for Pros who have completed Barbicide’s safety and sanitation certification programs.',
            },
            independent: {
                heading: 'Freebyrdz are Independent.',
                description: `
                    We provide tools for a lot of different types of Pro, but they all have one thing in common: they are running independent businesses. While they are not Freebyrdz employees, they are vested in their craft and in their brand, so they want to WOW you with safety, quality and amazing experiences.

                    After all, their livelihood depends on it.
                `,
            },
            verified: {
                heading: 'Verified Badge.',
                description:
                    'Look for the Verified Badge which signifies that your Pro has completed a paid background check (at their cost) through our third-party vendor, Checkr. Checkr uses AI-powered technology to help us run background checks.',
            },
            we: {
                heading: 'The Power of WE.',
                description:
                    'You give feedback and review each and every experience to create powerful, transparent info shared with all of our users.',
            },
        },
        faq: {
            heading: 'Frequently Asked Questions',
            questions: [
                {
                    q: 'What should I do to prepare for my in-home services?',
                    a: `
                        This depends on the services you are requesting. For personal services, make sure you have a comfortable, convenient area with plenty of natural light and space for your Pro to place their tools and/or products. Your Pro might mention additional accommodations in their booking instructions.

                        Lastly, remember you can always add a note to your reservation. You might want to make your Pro aware of any special considerations you want them to be aware of.
                    `,
                },
                {
                    q: 'Can the Pros use the beauty tools I provide?',
                    a: `
                        Freebyrdz provides software for independent Pros and a marketplace for you to find and enjoy your favorite experiences. Freebyrdz Pros are not our employees so we can't tell them how to run their business. That's their call.

                        We recommend communicating any expectations or special considerations at the time of booking using reservation notes. Drop them a note letting them know what you need or what you have in mind ahead of time. Communication is always the key!
                    `,
                },
            ],
        },
        search: {
            heading: 'Ready to find the best Pros in your neighborhood?',
        },
    },
    pros: {
        join: 'Join the revolution',
        intro: {
            heading: {
                1: 'We’re more than software.',
                2: 'We’re a community.',
            },
            description:
                'Join the fastest growing beauty, grooming and wellness community and start growing your business.',
        },
        visionaries: {
            heading: 'Visionaries, this is for you.',
            description:
                'We’re dedicated to the visionaries, the artists, the dreamers… freelance barbers, stylists, coaches, healers, and more, just like you. We’re leveling the playing field for the lone wolf.',
            best: 'The best part? We’re absolutely free.',
            quote: {
                content:
                    'It’s not easy to balance between being behind the chair and managing your business. Freebyrdz helps me manage my business 24/7, so I have the freedom focus on my clients, and do what I love.',
                author: 'Michaela · Stylist',
            },
        },
        places: {
            heading: 'Your place or theirs. You choose.',
            description:
                'You don’t have to limit yourself to 4 walls anymore. Now, your business can be anywhere you want it to be.',
        },
        values: {
            heading: {
                1: 'We help you run',
                2: 'AND grow your business.',
            },
            subheading: 'Here’s what we’re about',
            attributes: {
                free: {
                    heading: 'Always FREE.',
                    description: `
                        We don’t charge subscription fees. Ever. That’s right. Manage and grow your business 100% free.

                        No commissions for sending you new clients either. Nada. Zero. Zilch. No hooks or gimmicks.
                    `,
                },
                boss: {
                    heading: 'You’re the boss.',
                    description:
                        'Work when you want, where you want. We give you the tools. You set the when, where and how.',
                },
                unlimit: {
                    heading: 'Un-limit yourself.',
                    description:
                        'We believe in building businesses without boundaries. Mobile business? No problem. Freebyrdz allows you to offer reservations at your place or your clients’ place. Visit your clients at their home, their office, wherever. (Yep, we’ll provide directions too)',
                },
                payments: {
                    heading: 'Touch-Free Payments.',
                    description:
                        'No swiping or fumbling for credit cards. Guests pay seamlessly through our site. Plus, you can accept payments securely from anywhere, anytime.',
                },
                discovered: {
                    heading: 'Get discovered.',
                    description:
                        'When you get listed in the Freebyrdz community, you’ll meet new clients.',
                },
                smarter: {
                    heading: 'Work smarter, not harder.',
                    description:
                        'Do more with 24/7, online booking. Spend less time managing reservations and responding to clients. We’ll do the heavy lifting so you can spend your time doing what you do best. (your business will be working even when you’re sleeping) Welcome to self-service.',
                },
            },
        },
        start: {
            heading: 'Start now!',
            description:
                'Pour a cup of coffee, set up your profile, and start growing your business. ',
        },
        tools: {
            heading: 'Tools to power your business, give you independence and free up your time. ',
            features: [
                'Reporting & Analytics. Use powerful data to analyze your business and take action, just like the big guys do.',
                'Confirmations and appointment communications to manage your reservations,and make sure clients show up. ',
                'Your time is valuable. Prevent no shows and last-minute cancellations by setting your custom cancellation policy. ',
                '24/7 Online booking through your dedicated page.',
                'Bomb booking experience for your clients. Your brand and your clients deserve it.',
                'You’re in charge. We give you the ability to customize your offerings. You decide where, when, how and who.',
                'Customer Management. Manager your book of business, all in one place.',
                'Collect reviews and brag about your dope skills.',
            ],
        },
        faq: {
            heading: 'Frequently Asked Questions',
            questions: [
                {
                    q: 'How do I get paid?',
                    a:
                        'We use STRIPE to process all of our payments. You’ll tell STRIPE where you want your money deposited.',
                },
                {
                    q: 'If you are free, how do you make money?',
                    a:
                        'As is common, the guest pays a very small fee at the time of booking, just $0.99.',
                },
                {
                    q: 'Is free just an intro offer where you’ll try to sell me stuff later?',
                    a:
                        'Nope. Never. We’re built by industry pros, for industry pros. No tricks, no upgrades, no add-ons. Just the tools you need.',
                },
                {
                    q: 'Do you take a commission for introducing me to new clients?',
                    a: 'Nope. Never.',
                },
            ],
        },
        enough: {
            heading: 'Heard enough?',
        },
    },
    contact: {
        heading: 'How can we help?',
    },
    privacy: {
        heading: 'Privacy Policy',
        content: `
            Lorem ipsum dolor sit amet, <i>consectetuer adipiscing elit. Phasellus hendrerit.</i> Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti.

            <h3>Heading</h3>

            Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero eu augue. Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus sed, urna.
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti.

            <h3>Heading</h3>

            Morbi interdum mollis sapien. <b>Sed ac risus. Phasellus lacinia, magna a ullamcorper laoreet, lectus arcu pulvinar risus, vitae facilisis libero dolor a purus. Sed vel lacus. Mauris nibh felis, adipiscing varius, adipiscing in, lacinia vel, tellus. Suspendisse ac urna. Etiam pellentesque mauris ut lectus. Nunc tellus ante, mattis eget, gravida vitae, ultricies ac, leo. Integer leo pede, ornare a, lacinia eu, vulputate vel, nisl.</b>

            <ol>
                <li>Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat</li>
                <li>Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</li>
                <li>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.</li>
                <li>Pellentesque fermentum dolor. Aliquam quam lectus, facilisis auctor, ultrices ut, elementum vulputate, nunc.</li>
                <li>Sed adipiscing ornare risus. Morbi est est, blandit sit amet, sagittis vel, euismod vel, velit. Pellentesque egestas sem. Suspendisse commodo ullamcorper magna.</li>
                <li>Nulla sed leo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</li>
            </ol>

            Suspendisse mauris. Fusce accumsan mollis eros. Pellentesque a diam sit amet mi ullamcorper vehicula. Integer adipiscing risus a sem. Nullam quis massa sit amet nibh viverra malesuada. Nunc sem lacus, accumsan quis, faucibus non, congue vel, arcu. Ut scelerisque hendrerit tellus. Integer sagittis. Vivamus a mauris eget arcu gravida tristique. Nunc iaculis mi in ante. Vivamus imperdiet nibh feugiat est.

            Ut convallis, sem sit amet interdum consectetuer, odio augue aliquam leo, nec dapibus tortor nibh sed augue. Integer eu magna sit amet metus fermentum posuere. Morbi sit amet nulla sed dolor elementum imperdiet. Quisque fermentum. Cum sociis natoque penatibus et magnis xdis parturient montes, nascetur ridiculus mus. Pellentesque adipiscing eros ut libero. Ut condimentum mi vel tellus. Suspendisse laoreet. Fusce ut est sed dolor gravida convallis. Morbi vitae ante. Vivamus ultrices luctus nunc. Suspendisse et dolor. Etiam dignissim. Proin malesuada adipiscing lacus. Donec metus.
        `,
    },
    terms: {
        heading: 'Terms & Conditions',
        content: `
            Lorem ipsum dolor sit amet, <i>consectetuer adipiscing elit. Phasellus hendrerit.</i> Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti.

            <h3>Heading</h3>

            Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero eu augue. Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus sed, urna.
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti.

            <h3>Heading</h3>

            Morbi interdum mollis sapien. <b>Sed ac risus. Phasellus lacinia, magna a ullamcorper laoreet, lectus arcu pulvinar risus, vitae facilisis libero dolor a purus. Sed vel lacus. Mauris nibh felis, adipiscing varius, adipiscing in, lacinia vel, tellus. Suspendisse ac urna. Etiam pellentesque mauris ut lectus. Nunc tellus ante, mattis eget, gravida vitae, ultricies ac, leo. Integer leo pede, ornare a, lacinia eu, vulputate vel, nisl.</b>

            <ol>
                <li>Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat</li>
                <li>Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</li>
                <li>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.</li>
                <li>Pellentesque fermentum dolor. Aliquam quam lectus, facilisis auctor, ultrices ut, elementum vulputate, nunc.</li>
                <li>Sed adipiscing ornare risus. Morbi est est, blandit sit amet, sagittis vel, euismod vel, velit. Pellentesque egestas sem. Suspendisse commodo ullamcorper magna.</li>
                <li>Nulla sed leo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</li>
            </ol>

            Suspendisse mauris. Fusce accumsan mollis eros. Pellentesque a diam sit amet mi ullamcorper vehicula. Integer adipiscing risus a sem. Nullam quis massa sit amet nibh viverra malesuada. Nunc sem lacus, accumsan quis, faucibus non, congue vel, arcu. Ut scelerisque hendrerit tellus. Integer sagittis. Vivamus a mauris eget arcu gravida tristique. Nunc iaculis mi in ante. Vivamus imperdiet nibh feugiat est.

            Ut convallis, sem sit amet interdum consectetuer, odio augue aliquam leo, nec dapibus tortor nibh sed augue. Integer eu magna sit amet metus fermentum posuere. Morbi sit amet nulla sed dolor elementum imperdiet. Quisque fermentum. Cum sociis natoque penatibus et magnis xdis parturient montes, nascetur ridiculus mus. Pellentesque adipiscing eros ut libero. Ut condimentum mi vel tellus. Suspendisse laoreet. Fusce ut est sed dolor gravida convallis. Morbi vitae ante. Vivamus ultrices luctus nunc. Suspendisse et dolor. Etiam dignissim. Proin malesuada adipiscing lacus. Donec metus.
        `,
    },
    footer: {
        sections: {
            company: 'Company',
            pros: 'For Pros',
            follow: 'Follow Us',
        },
        links: {
            contact: 'Contact',
            privacy: 'Privacy policy',
            terms: 'Terms & Conditions',
            biz: 'Software for your Biz',
        },
        copyright: '© 2021 FreeByrdz',
    },
}
