<template>
    <!-- TODO: Add some nice transitions of switching between ranges. -->
    <div v-if="range === 'day'" :class="rangeClass">
        <div class="relative flex items-center justify-center h-40 w-40">
            <IconCalendarDay class="absolute inset-0" />
            <p class="pt-10">{{ formattedDayNumber }}</p>
        </div>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="ml-15" v-html="formattedDayDate" />

        <button type="button" :class="switchClass" @click="$emit('switch-range', 'week')">
            <IconCalendarWeek class="h-30 w-30" />
            <p>{{ $t('common.week') }}</p>
        </button>
    </div>

    <div v-else :class="rangeClass">
        <div class="relative flex items-center justify-center h-40 w-40">
            <IconCalendarWeek class="absolute inset-0" />
        </div>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="ml-15" v-html="formattedWeekDate" />

        <button type="button" :class="switchClass" @click="$emit('switch-range', 'day')">
            <div class="h-30 w-30 relative">
                <IconCalendarDay class="absolute inset-0" />
                <p class="pt-10 text-13">{{ formattedDayNumber }}</p>
            </div>
            <p>{{ $t('common.day') }}</p>
        </button>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import IconCalendarDay from '@/assets/vectors/icon-calendar-day.svg'
    import IconCalendarWeek from '@/assets/vectors/icon-calendar-week.svg'

    export default {
        components: {
            IconCalendarDay,
            IconCalendarWeek,
        },

        props: {
            date: { type: Object, required: true },
            range: { type: String, required: true },
        },

        emits: ['switch-range'],

        computed: {
            rangeClass() {
                return 'flex items-center text-20 font-bold leading-heading text-purple h-50'
            },

            switchClass() {
                return 'space-y-4 text-gray-4 hoverable:hover:text-gray-2 flex flex-col items-center ml-auto text-9 uppercase font-bold text-center'
            },

            formattedDayNumber() {
                return dayjs(this.date).format('D')
            },

            formattedDayDate() {
                return dayjs(this.date).format('dddd,<br /> MMMM YYYY')
            },

            formattedWeekDate() {
                return [this.$t('common.week'), dayjs(this.date).format('w,<br /> MMMM YYYY')].join(
                    ' ',
                )
            },
        },
    }
</script>
