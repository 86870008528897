<template>
    <GuestBookStep
        :heading="$t('guest.book.at.heading', { name: appointment.pro.firstName })"
        :has-progress="hasProgress"
    >
        <ProSlots :slots="slots" :price="appointment.price" @click-slot="saveAndGoToNextStep" />
    </GuestBookStep>
</template>

<script>
    import ApiProAvailabilities from '@/apis/ApiProAvailabilities'
    import MixinBookStep from '@/mixins/MixinBookStep'
    import ProSlots from '@/components/pro/ProSlots'

    export default {
        components: {
            ProSlots,
        },

        mixins: [MixinBookStep],

        data() {
            return {
                slots: [],
            }
        },

        mounted() {
            this.loadAvailabilitySlots()
        },

        methods: {
            async loadAvailabilitySlots() {
                this.slots = await ApiProAvailabilities.getSlots(this.appointment.pro.id, {
                    startDate: this.formData.search.startDate,
                    endDate: this.formData.search.endDate,
                    experienceIds: this.appointment.experiences.map(({ id }) => id),
                    addOnIds: this.appointment.addOns.map(({ id }) => id),
                    address: this.appointment.address,
                    workPlace: this.appointment.workPlace,
                })
            },

            saveAndGoToNextStep({ date, slot: { startTime, endTime } }) {
                this.change({ path: 'appointment.date', value: date })
                this.change({ path: 'appointment.startTime', value: startTime })
                this.change({ path: 'appointment.endTime', value: endTime })
                this.goToNextStep()
            },
        },
    }
</script>
