<script>
    import store from '@/store'
    import i18n from '@/i18n'
    import ServiceAuth from '@/services/ServiceAuth'
    import ServiceUi from '@/services/ServiceUi'

    export default {
        beforeRouteEnter({ query }, from, next) {
            ServiceAuth.loadUser().then(() => {
                if (query.emailConfirmed === 'true') {
                    ServiceUi.flash.success(i18n.global.t('auth.register.email.success'), true)
                }

                if (query.emailConfirmed === 'false') {
                    ServiceUi.flash.error(i18n.global.t('auth.register.email.error'), true)
                }

                if (!ServiceAuth.isLoggedIn()) {
                    return next('/auth/login')
                }

                if (ServiceAuth.isPro()) {
                    return ServiceAuth.getUser()?.isOnBoarded
                        ? next('/pro/dashboard')
                        : next('/pro/onboarding')
                }

                if (ServiceAuth.isGuest()) {
                    const isBookStarted = !!store.getters['continuity/getBook']
                    return isBookStarted ? next('/book') : next('/guest/appointments/future')
                }

                if (ServiceAuth.isSuperAdmin() || ServiceAuth.isAdmin()) {
                    next('/admin/dashboard')
                }
            })
        },
    }
</script>
