<template>
    <LayoutPro>
        <header class="pb-10 -mx-20 px-20 sticky z-floating top-70 bg-white space-y-30 shadow-md">
            <BaseHeaderSection :heading="$t('pro.payments.heading')" />

            <div class="flex items-center space-x-30">
                <div class="space-y-5 width-full">
                    <h5 class="text-14 text-gray-2">{{ $t('pro.payments.total') }}</h5>
                    <p class="text-24 font-bold text-purple">$ {{ totalEarnings }}</p>
                </div>

                <div class="space-y-5 width-full">
                    <h5 class="text-14 text-gray-2">{{ $t('pro.payments.future') }}</h5>
                    <p class="text-24 font-bold text-magenta">$ {{ futureEarnings }}</p>
                </div>
            </div>
        </header>

        <template v-if="hasPayments">
            <div class="flex-grow flex flex-col">
                <div
                    v-for="(payment, index) in payments"
                    :key="index"
                    class="flex items-center h-50 border-b-1 border-gray-5"
                >
                    <p class="text-gray-2">{{ formatPaymentDate(payment) }}</p>
                    <p class="ml-auto font-bold text-purple">
                        $ {{ formatAmount(payment.amount) }}
                    </p>
                </div>

                <ProStripeButton
                    :label="$t('pro.payments.view')"
                    :href="stripeLink"
                    class="mt-20"
                />
            </div>
        </template>

        <BaseBlankSlate v-else :message="$t('pro.payments.blank')" />

        <p class="text-14 text-gray-3 my-20">{{ $t('pro.payments.details') }}</p>
    </LayoutPro>
</template>

<script>
    import dayjs from 'dayjs'
    import { formatAmount } from '@/helpers'
    import ApiPros from '@/apis/ApiPros'
    import ApiProPayments from '@/apis/ApiProPayments'
    import LayoutPro from '@/components/layouts/LayoutPro'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseBlankSlate from '@/components/base/BaseBlankSlate'
    import ProStripeButton from '@/components/pro/ProStripeButton'
    import ProPaymentsInactiveAlert from '@/components/pro/payments/ProPaymentsInactiveAlert'

    export default {
        components: {
            LayoutPro,
            BaseHeaderSection,
            BaseBlankSlate,
            ProStripeButton,
        },

        async loadData({ $auth }) {
            const { totalEarnings, futureEarnings, payments } = await ApiProPayments.getHistory(
                $auth.getUser().id,
            )
            const { url: stripeLink } = await ApiPros.getStripeLoginLink($auth.getUser().id)

            return { totalEarnings, futureEarnings, payments, stripeLink }
        },

        computed: {
            isStripeInactive() {
                return this.$auth.getUser().stripeAccountStatus !== 'Enabled'
            },

            hasPayments() {
                return this.payments.length > 0
            },
        },

        mounted() {
            this.isStripeInactive &&
                this.$ui.alerts.open('stripe-inactive', ProPaymentsInactiveAlert)
        },

        beforeUnmount() {
            this.$ui.alerts.close('stripe-inactive')
        },

        methods: {
            formatPaymentDate({ date }) {
                return dayjs(date).format('MMM D, YYYY')
            },

            formatAmount,
        },
    }
</script>
