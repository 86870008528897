<script>
    import ApiProAddOns from '@/apis/ApiProAddOns'
    import PagesProProfileAddOnsAdd from '@/pages/pro/profile/addons/add'

    const INITIAL_STEP = 9

    export default {
        extends: PagesProProfileAddOnsAdd,

        loadData({ $auth, $route }) {
            const addOn = $auth.getUser().addOns.find(({ id }) => id == $route.params.id)

            if (!addOn) {
                // TODO: Throw error if category does not exist.
            }

            return { addOn }
        },

        data() {
            return {
                step: INITIAL_STEP,
            }
        },

        computed: {
            backButton() {
                return this.rememberedStep ? true : '/pro/profile/addons'
            },

            submitLabel() {
                return this.step !== INITIAL_STEP ? this.$t('common.save') : null
            },
        },

        created() {
            this.initForm({
                ...this.addOn,
                experienceIds: this.addOn.experiences.map(({ id }) => id),
            })
        },

        methods: {
            handleGoTo(slug) {
                const step = {
                    'name': 1,
                    'description': 2,
                    'duration': 3,
                    'price-pro': 5,
                    'price-guest': 7,
                    'experiences': 8,
                }[slug]

                this.rememberedStep = this.step
                this.goToStep(step)
            },

            handleBack() {
                this.goToStep(this.rememberedStep)
                this.rememberedStep = null
            },

            handleNext() {
                this.save()
                this.rememberedStep = null
            },

            save() {
                return this.handleSubmit(
                    ApiProAddOns.update(
                        this.$auth.getUser().id,
                        this.addOn.id,
                        this.formData,
                    ).then(() => this.$auth.loadUser(true)),
                ).then(() => this.goToStep(INITIAL_STEP))
            },
        },
    }
</script>
