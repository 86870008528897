<template>
    <LayoutBase>
        <template #top>
            <header class="flex sticky top-0 -mx-20 h-70 bg-white px-20 z-layout">
                <AppBack v-if="backButton === true" @click="$emit('back')" />
                <AppBack v-else-if="backButton !== false" :route="backButton" />
            </header>
        </template>

        <template #default>
            <slot />
        </template>
    </LayoutBase>
</template>

<script>
    import LayoutBase from '@/components/layouts/LayoutBase'
    import AppBack from '@/components/app/AppBack'

    export default {
        components: {
            LayoutBase,
            AppBack,
        },

        props: {
            backButton: { type: [Boolean, String, Object], default: false },
        },

        emits: ['back'],
    }
</script>
