<template>
    <div class="flex flex-row justify-between flex-wrap">
        <div
            v-for="info in dailyInfos"
            :key="info.label"
            class="flex flex-col border-l-1 border-gray-4 pl-10 p-5 m-5 cursor-pointer"
            @click="info.handleClick"
        >
            <div class="flex flex-row">
                <component :is="info.icon" class="text-black w-20" />
                <p class="ml-5 text-14 font-bold flex items-center uppercase">
                    {{ info.label }}
                </p>
            </div>
            <div class="flex flex-row">
                <span v-if="info.isMoney" class="text-35 mb-2 pr-5">$</span>
                <span class="text-35 font-bold">{{ info.value }}</span>
            </div>
            <div v-if="info.percentage !== undefined && info.percentage !== null" class="text-14">
                <span v-if="info.percentage >= 0" class="text-green">+</span>
                <span :class="info.percentage >= 0 ? 'text-green' : 'text-red'">
                    {{ info.percentage + '% ' }}
                </span>
                <span>{{ $t('admin.dashboard.fromPreviousDay') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import IconPin from '@/assets/vectors/icon-pin.svg'

    export default {
        components: {
            IconPin,
        },

        props: {
            dailyInfos: { type: Array, required: true },
        },
    }
</script>
