<template>
    <LayoutApp :back-button="backButton" @back="step -= 1">
        <component
            :is="currentStep.component"
            :step-data="formData"
            @change="changeData"
            @go-to-start="goToStart"
            @go-to="goToStep"
            @go-to-next="goToNextStep"
        />
    </LayoutApp>
</template>

<script>
    import * as HelperAppointment from '@/helpers/appointment'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import dayjs from 'dayjs'
    import ApiGuestAppointments from '@/apis/ApiGuestAppointments'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import GuestAppointmentsRepeatStep1 from '@/components/guest/appointments/repeat/GuestAppointmentsRepeatStep1'
    import GuestAppointmentsRepeatStep2 from '@/components/guest/appointments/repeat/GuestAppointmentsRepeatStep2'
    import GuestAppointmentsRepeatStep3 from '@/components/guest/appointments/repeat/GuestAppointmentsRepeatStep3'
    import GuestAppointmentsRepeatStep4 from '@/components/guest/appointments/repeat/GuestAppointmentsRepeatStep4'
    import GuestAppointmentsRepeatStep5 from '@/components/guest/appointments/repeat/GuestAppointmentsRepeatStep5'
    import GuestAppointmentsRepeatStep6 from '@/components/guest/appointments/repeat/GuestAppointmentsRepeatStep6'

    export default {
        components: {
            LayoutApp,
        },

        mixins: [MixinForm, MixinFormSteppable],

        async loadData({ $auth, $store, $route, $router }) {
            let appointment = $store.getters['continuity/getAppointment']

            if (!appointment) {
                appointment = await ApiGuestAppointments.getOne($auth.getUser(), $route.params.id)
            }

            if (!HelperAppointment.isRepeatable(appointment)) {
                $router.push(`/guest/appointments/${$route.params.id}`)
            }

            return { appointment }
        },

        computed: {
            backButton() {
                return [1, 6].includes(this.step)
                    ? `/guest/appointments/${this.appointment.id}`
                    : true
            },

            steps() {
                return [
                    { component: GuestAppointmentsRepeatStep1 },
                    { component: GuestAppointmentsRepeatStep2 },
                    { component: GuestAppointmentsRepeatStep3 },
                    { component: GuestAppointmentsRepeatStep4 },
                    { component: GuestAppointmentsRepeatStep5 },
                    { component: GuestAppointmentsRepeatStep6 },
                ]
            },
        },

        created() {
            this.initForm({
                search: {
                    year: dayjs().year(),
                    month: dayjs().month(),
                },
                appointment: {
                    ...this.appointment,
                    experiences: this.appointment.experiences.map((experience) => ({
                        ...experience,
                        // This is because 'id' in this list of experiences is for record connecting
                        // given appointment with actual experience (experienceId, appointmentId).
                        id: experience.experienceId,
                    })),
                    addOns: this.appointment.addOns.map((addOn) => ({
                        ...addOn,
                        // This is because 'id' in this list of add-ons is for record connecting
                        // given appointment with actual addOn (addOnId, appointmentId).
                        id: addOn.addOnId,
                    })),
                },
            })
        },
    }
</script>
