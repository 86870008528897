import { htmlEscape } from 'escape-goat'
import { autop } from '@wordpress/autop'
import dayjs from 'dayjs'
import i18n from '@/i18n'
import store from '@/store'
import IconCategoryHair from '@/assets/vectors/icon-category-hair.svg'
import IconCategoryFaceBody from '@/assets/vectors/icon-category-face-body.svg'
import IconCategoryNails from '@/assets/vectors/icon-category-nails.svg'
import IconCategoryMensGrooming from '@/assets/vectors/icon-category-men-s-grooming.svg'
import IconCategoryWellness from '@/assets/vectors/icon-category-wellness.svg'
import IconCategoryMultiple from '@/assets/vectors/icon-category-multiple.svg'
import IconPro from '@/assets/vectors/icon-pro.svg'
import IconGuest from '@/assets/vectors/icon-guest.svg'

export const isNodeDescendant = (child, parent) => {
    let node = child.parentNode

    while (node !== null) {
        if (node === parent) {
            return true
        }

        node = node.parentNode
    }

    return false
}

export const getCategoryIcon = (category) => {
    // INFO: This is very fragile fragment of the code, dependent heavily on the names
    // of categories. Should be kept in sync with list of categories in the backend.
    return (
        {
            'Hair': IconCategoryHair,
            'Nails': IconCategoryNails,
            'Face & Body': IconCategoryFaceBody,
            "Men's Grooming": IconCategoryMensGrooming,
            'Wellness & Fitness': IconCategoryWellness,
        }[category?.name] || IconCategoryMultiple
    )
}

export const getCategoryOptions = (categories, { id: categoryId }) => {
    return categories
        .find(({ id }) => categoryId === id)
        ?.subCategories.map((option) => ({
            value: option.id,
            label: option.name,
        }))
}

export const getWorkPlaceOptions = (labels = {}) => {
    return [
        {
            label: labels?.pro || i18n.global.t('common.places.pro'),
            icon: IconPro,
            value: 'pro',
        },
        {
            label: labels?.guest || i18n.global.t('common.places.guest'),
            icon: IconGuest,
            value: 'guest',
        },
    ]
}

export const getBooleanOptions = () => {
    return [
        {
            label: i18n.global.t('common.yes'),
            value: true,
        },
        {
            label: i18n.global.t('common.no'),
            value: false,
        },
    ]
}

export const timeToDateTime = (time) => {
    const [hour, minutes] = time.split(':')
    return dayjs()
        .startOf('day')
        .hour(hour)
        .minute(minutes)
}

export const composeFullName = ({ firstName, lastName }) => {
    return [firstName, lastName].join(' ')
}

export const sanitizeAndFormatText = (text) => {
    return autop(htmlEscape(text))
}

export const formatAmount = (amount) => {
    const hasDecimals = amount - Math.floor(amount) !== 0
    return hasDecimals ? amount.toFixed(2) : parseInt(amount)
}

export const getStateNameByCode = (code) => {
    return (
        store.getters['dictionaries/getStates'].find(({ value }) => value === code)?.label || code
    )
}
