<template>
    <section class="space-y-30">
        <template v-if="hasNotes">
            <BaseHeaderDivider :heading="$t('pro.clients.show.sections.notes')" />

            <article v-for="(note, index) in notes" :key="index" class="space-y-20">
                <header v-if="isEditable" class="flex items-center">
                    <h5 class="text-14 text-md font-bold">{{ noteDate(note.date) }}</h5>

                    <div class="ml-auto flex space-x-10">
                        <BaseButtonEdit @click="$emit('edit', { index, note })" />
                        <BaseButtonDelete @click="confirmDelete({ index, note })" />
                    </div>
                </header>

                <header v-else class="flex flex-col items-center space-y-5">
                    <div class="relative flex items-center justify-center h-35 w-35">
                        <IconCalendarDay class="absolute inset-0" />
                        <p class="pt-10 font-bold">{{ noteDay(note.date) }}</p>
                    </div>
                    <BaseLabel :content="noteDate(note.date, true)" />
                </header>

                <BaseText :content="note.note" />
            </article>
        </template>

        <BaseLink
            class="flex items-center space-x-15 text-left"
            @click="$emit('add', { index: notes.length, note: {} })"
        >
            <IconDescription class="h-28" />
            <span>{{ $t('pro.clients.show.add-notes') }}</span>
        </BaseLink>
    </section>
</template>

<script>
    import dayjs from 'dayjs'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'
    import BaseLabel from '@/components/base/BaseLabel'
    import BaseButtonEdit from '@/components/base/BaseButtonEdit'
    import BaseButtonDelete from '@/components/base/BaseButtonDelete'
    import IconDescription from '@/assets/vectors/icon-description.svg'
    import IconCalendarDay from '@/assets/vectors/icon-calendar-day.svg'

    export default {
        components: {
            BaseHeaderDivider,
            BaseText,
            BaseLink,
            BaseLabel,
            BaseButtonEdit,
            BaseButtonDelete,
            IconDescription,
            IconCalendarDay,
        },

        props: {
            client: { type: Object, required: true },
            isEditable: { type: Boolean, default: false },
        },

        emits: ['add', 'edit', 'delete'],

        computed: {
            hasNotes() {
                return this.notes.length > 0
            },

            notes() {
                // Using Object.values here as notes are set by index, so instead of array,
                // it creates an object with property names 0, 1, etc.
                return Object.values(this.client.notes || [])
            },
        },

        methods: {
            confirmDelete({ index, note }) {
                this.$ui
                    .confirm({ message: this.$t('pro.clients.show.delete-note') })
                    .then((isConfirmed) => isConfirmed && this.$emit('delete', { index, note }))
            },

            noteDate(date, isShorten = false) {
                return dayjs(date).format(isShorten ? 'MMM YYYY' : 'MMMM M, YYYY')
            },

            noteDay(date) {
                return dayjs(date).format('D')
            },
        },
    }
</script>
