<template>
    <div class="flex space-x-20">
        <div class="flex-shrink-0 h-50 flex items-center self-end">
            <BaseCircle
                :label="dayOfWeekLabel"
                :level="1"
                :is-selected="true"
                class="text-purple"
            />
        </div>

        <div v-for="({ label, time, slot }, index) in times" :key="index" class="w-full">
            <p v-if="hasLabels" class="pb-10 flex align-bottom text-14 leading-none">
                {{ label }}
            </p>

            <div class="min-h-50 flex items-center">
                <slot :name="slot">
                    {{ timeLabel(time) }}
                </slot>
            </div>
        </div>

        <slot name="end" />
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import { timeToDateTime } from '@/helpers'
    import BaseCircle from '@/components/base/BaseCircle'

    export default {
        components: {
            BaseCircle,
        },

        props: {
            availability: { type: Object, required: true },
            isEditing: { type: Boolean, default: true },
            isDeletable: { type: Boolean, default: false },
            hasLabels: { type: Boolean, default: true },
        },

        emits: ['change', 'delete'],

        computed: {
            dayOfWeekLabel() {
                return dayjs()
                    .day(this.availability.dayOfWeek + 1)
                    .format('dd')
            },

            times() {
                return [
                    {
                        label: this.$t('common.from'),
                        time: this.availability.startTime,
                        slot: 'startTime',
                    },
                    {
                        label: this.$t('common.to'),
                        time: this.availability.endTime,
                        slot: 'endTime',
                    },
                ]
            },
        },

        methods: {
            timeLabel(time) {
                return timeToDateTime(time).format('hh:mm A')
            },
        },
    }
</script>
