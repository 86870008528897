<script>
    import ApiProPortfolio from '@/apis/ApiProPortfolio'
    import PagesProProfilePortfolioAdd from '@/pages/pro/profile/portfolio/add'
    import ProProfilePortfolioEdit1 from '@/components/pro/profile/portfolio/ProProfilePortfolioEdit1'
    import ProProfilePortfolioEdit2 from '@/components/pro/profile/portfolio/ProProfilePortfolioEdit2'
    import ProProfilePortfolioEdit3 from '@/components/pro/profile/portfolio/ProProfilePortfolioEdit3'

    const INITIAL_STEP = 3

    export default {
        extends: PagesProProfilePortfolioAdd,

        loadData({ $auth, $route }) {
            const work = $auth.getUser().portfolio.find(({ id }) => id == $route.params.id)

            if (!work) {
                // TODO: Throw error if category does not exist.
            }

            return { work }
        },

        data() {
            return {
                step: INITIAL_STEP,
            }
        },

        computed: {
            steps() {
                return [
                    { component: ProProfilePortfolioEdit1 },
                    { component: ProProfilePortfolioEdit2 },
                    { component: ProProfilePortfolioEdit3 },
                ]
            },

            backButton() {
                return this.rememberedStep ? true : '/pro/profile/portfolio'
            },

            submitLabel() {
                return this.step !== INITIAL_STEP ? this.$t('common.save') : null
            },
        },

        created() {
            this.initForm({
                ...this.work,
                experienceIds: this.work.experiences.map(({ id }) => id),
            })
        },

        methods: {
            handleBack() {
                this.goToStep(this.rememberedStep)
                this.rememberedStep = null
            },

            handleNext() {
                this.save().then(() => (this.rememberedStep = null))
            },

            save() {
                return this.handleSubmit([
                    ApiProPortfolio.update(
                        this.$auth.getUser().id,
                        this.work.id,
                        this.formData,
                    ).then(() => this.$auth.loadUser(true)),
                ]).then(() => this.goToStep(INITIAL_STEP))
            },
        },
    }
</script>
