<template>
    <LayoutApp v-if="isCancelled || isNoShow" back-button="/guest/appointments/cancelled">
        <div class="space-y-20 flex-grow flex flex-col">
            <header class="space-y-20 flex flex-col items-center">
                <BaseHeading :heading="noShowOrCancelledHeading" :level="3" />
                <IconMenuCalendarCancelled class="h-50 text-gray-3" />
            </header>

            <ProAddress :pro="appointment.pro" :address="appointmentAddress" />
            <BaseDatetime :datetime="appointmentStartDateTime" />
            <BaseReceipt :positions="noShowOrCancellationPrices">
                <template #position="{ position }">
                    <span v-if="position.crossedPrice" class="line-through">
                        $ {{ position.crossedPrice }}
                    </span>
                </template>
            </BaseReceipt>
        </div>

        <FormButtons>
            <BaseButton
                :label="$t('guest.appointments.my-appointments')"
                route="/guest/appointments/cancelled"
            />
        </FormButtons>
    </LayoutApp>

    <GuestAppointmentsIndex v-else :current-type="type" :back-button="backButton">
        <template v-if="isFuture" #actions>
            <button class="ml-auto" @click="toggleActionsMenu">
                <IconDots class="h-25 text-gray-3 px-10 -mr-10" />
            </button>
        </template>

        <template #default>
            <div class="space-y-20">
                <ProAddress :pro="appointment.pro" :address="appointmentAddress" />

                <BaseReceipt
                    v-if="isEdited"
                    :positions="repeatPrices"
                    :is-removable="isEdited"
                    @remove-position="removePosition"
                />

                <template v-else>
                    <BaseDatetime :datetime="appointmentStartDateTime" />
                    <BaseReceipt :positions="reservedOrConcludedPrices" />
                    <GuestAppointmentsShowCommunication :appointment="appointment" class="mt-20" />
                </template>
            </div>
        </template>

        <template #bottom>
            <FormButtons v-if="repeatButtonLabel">
                <BaseButton
                    :label="repeatButtonLabel"
                    :is-disabled="!isRepeatable"
                    @click="handleButtonClick"
                />
            </FormButtons>
        </template>
    </GuestAppointmentsIndex>

    <GuestAppointmentsShowActions
        :is-open="isActionsMenuOpen"
        :appointment="appointment"
        @reschedule="reschedule"
        @cancel="cancel"
        @close="toggleActionsMenu"
    />
</template>

<script>
    import * as HelperAppointment from '@/helpers/appointment'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import ApiGuestAppointments from '@/apis/ApiGuestAppointments'
    import GuestAppointmentsIndex from '@/components/guest/appointments/GuestAppointmentsIndex'
    import GuestAppointmentsShowCommunication from '@/components/guest/appointments/show/GuestAppointmentsShowCommunication'
    import GuestAppointmentsShowActions from '@/components/guest/appointments/show/GuestAppointmentsShowActions'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import FormButtons from '@/components/form-elements/FormButtons'
    import ProAddress from '@/components/pro/ProAddress'
    import BaseDatetime from '@/components/base/BaseDatetime'
    import BaseReceipt from '@/components/base/BaseReceipt'
    import IconDots from '@/assets/vectors/icon-dots.svg'
    import IconMenuCalendarCancelled from '@/assets/vectors/icon-menu-calendar-cancelled.svg'

    export default {
        components: {
            GuestAppointmentsIndex,
            GuestAppointmentsShowCommunication,
            GuestAppointmentsShowActions,
            LayoutApp,
            BaseHeading,
            BaseButton,
            FormButtons,
            ProAddress,
            BaseDatetime,
            BaseReceipt,
            IconDots,
            IconMenuCalendarCancelled,
        },

        mixins: [MixinAppointment],

        data() {
            return {
                isEdited: false,
                isActionsMenuOpen: false,
            }
        },

        async loadData({ $auth, $route, $router }) {
            const appointment = await ApiGuestAppointments.getOne($auth.getUser(), $route.params.id)

            if (HelperAppointment.isRatable(appointment)) {
                $router.push(`/guest/appointments/${appointment.id}/rate`)
            }

            return { appointment }
        },

        computed: {
            type() {
                if (this.isCancelled) {
                    return 'cancelled'
                }

                return this.isFuture ? 'future' : 'previous'
            },

            reservedOrConcludedPrices() {
                return [
                    ...this.appointmentPrices(),
                    ...(this.isConcluded
                        ? [
                              {
                                  name: this.$t('common.extras'),
                                  price: this.appointment.extras,
                              },
                              {
                                  name: this.$t('common.discount'),
                                  price: -this.appointment.discountAmount,
                              },
                              {
                                  name: this.$t('common.tip'),
                                  price: this.appointment.tip,
                              },
                          ]
                        : []),
                    {
                        name: this.$t('common.fees.service'),
                        price: this.$store.getters['dictionaries/getServiceFee'],
                    },
                ]
            },

            repeatPrices() {
                return [
                    ...this.appointment.experiences.map((experience) => ({
                        name: experience.name,
                        price: this.addOnOrExperiencePrice(experience, this.appointment.workPlace),
                        isRemovable: true,
                        id: experience.experienceId,
                        type: 'experiences',
                    })),
                    ...this.appointment.addOns.map((addOn) => ({
                        name: addOn.name,
                        price: this.addOnOrExperiencePrice(addOn, this.appointment.workPlace),
                        isRemovable: true,
                        id: addOn.addOnId,
                        type: 'addOns',
                    })),
                    {
                        name: this.$t('common.fees.service'),
                        price: this.$store.getters['dictionaries/getServiceFee'],
                    },
                ]
            },

            noShowOrCancellationPrices() {
                return [
                    ...this.appointmentPrices().map((data) => ({
                        ...data,
                        crossedPrice: data.price,
                        price: 0,
                    })),
                    ...(this.isGuestNoShow ? this.noShowPrices(true) : []),
                    ...(this.isCancelled && this.isCancellationFeeActive
                        ? this.cancellationPrices(true)
                        : []),
                ]
            },

            isRepeatable() {
                return this.isConcluded && this.appointment.experiences?.length > 0
            },

            repeatButtonLabel() {
                if (!this.isPrevious) {
                    return null
                }

                return this.isEdited
                    ? this.$t('guest.appointments.check-availability')
                    : this.$t('guest.appointments.repeat-experience')
            },

            noShowOrCancelledHeading() {
                return this.isNoShow
                    ? this.$t('guest.appointments.no-show')
                    : this.$t('guest.appointments.cancelled')
            },

            backButton() {
                return `/guest/appointments/${this.type}`
            },
        },

        mounted() {
            this.isEdited = this.$route.query.repeat && this.isRepeatable
        },

        methods: {
            handleButtonClick() {
                this.isEdited ? this.repeat() : (this.isEdited = true)
            },

            repeat() {
                this.$store.commit('continuity/setAppointment', this.appointment)
                this.$router.push(`/guest/appointments/${this.appointment.id}/repeat`)
            },

            reschedule() {
                this.$router.push(`/guest/appointments/${this.appointment.id}/reschedule`)
            },

            cancel() {
                ApiGuestAppointments.cancel(this.$auth.getUser(), this.appointment.id).then(
                    (appointment) => {
                        this.isActionsMenuOpen = false

                        // Append changed info to appointment.
                        this.appointment.status = appointment.status
                        this.appointment.cancellationFee = appointment.cancellationFee
                    },
                )
            },

            removePosition({ position: { type, id: idToRemove } }) {
                this.appointment[type] = this.appointment[type].filter(
                    ({ id }) => id !== idToRemove,
                )
            },

            toggleActionsMenu() {
                this.isActionsMenuOpen = !this.isActionsMenuOpen
            },
        },
    }
</script>
