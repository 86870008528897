<template>
    <BaseTag :label="$t('pro.profile.suggested')" class="text-purple" />
</template>

<script>
    import BaseTag from '@/components/base/BaseTag'

    export default {
        components: {
            BaseTag,
        },
    }
</script>
