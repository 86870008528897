<template>
    <ul :class="tagsClass">
        <li v-for="option in options" :key="option.label" :class="optionClass">
            <BasePill
                :label="option.label"
                :is-selected="isSelected(option)"
                :is-closable="isSelected(option)"
                @click.stop="select(option)"
                @close.stop="deselect(option)"
            />
        </li>
    </ul>
</template>

<script>
    import MixinFormInput from '@/mixins/MixinFormInput'
    import MixinFormOptionable from '@/mixins/MixinFormOptionable'
    import BasePill from '@/components/base/BasePill'

    export default {
        components: {
            BasePill,
        },

        mixins: [MixinFormInput, MixinFormOptionable],

        emits: ['select', 'deselect'],

        computed: {
            tagsClass() {
                return {
                    'flex': true,
                    'flex-wrap': true,
                    'items-center': true,
                    '-mx-4': true,
                    '-my-5': true,
                    'cursor-not-allowed': this.isDisabled,
                }
            },

            optionClass() {
                return {
                    'px-4': true,
                    'py-5': true,
                    'max-w-full': true,
                    'transition-opacity': true,
                    'pointer-events-none': this.isDisabled,
                    'opacity-30': this.isDisabled,
                }
            },

            computedValue() {
                return this.value || []
            },
        },

        methods: {
            isSelected({ value }) {
                return this.computedValue.includes(value)
            },

            select({ label, value }) {
                if (this.isSelected({ value })) {
                    return
                }

                this.change([...this.computedValue, value])
                this.$emit('select', { label, value })
            },

            deselect({ label, value }) {
                this.change(this.computedValue.filter((existingValue) => existingValue !== value))
                this.$emit('deselect', { label, value })
            },
        },
    }
</script>
