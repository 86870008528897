<template>
    <article class="relative">
        <div class="absolute inset-0">
            <BaseAvatar :photo="pro.profilePicture" class="h-full w-full" />
        </div>

        <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-70" />

        <div :class="bodyClass">
            <div class="space-y-5">
                <h4 :class="nameClass">
                    <span class="block">{{ pro.firstName }}</span>
                    <span class="block">{{ pro.lastName }}</span>
                </h4>
                <BaseRating v-if="pro.rating" :rating="pro.rating" :level="level" />
            </div>

            <div
                v-if="isPriceDisplayed && pro.lowestPrice"
                class="flex flex-col items-end space-y-2"
            >
                <span :class="fromClass">{{ $t('common.from') }}</span>
                <span :class="priceClass">${{ pro.lowestPrice }}</span>
            </div>
        </div>
    </article>
</template>

<script>
    import BaseRating from '@/components/base/BaseRating'
    import BaseAvatar from '@/components/base/BaseAvatar'

    export default {
        components: {
            BaseRating,
            BaseAvatar,
        },

        props: {
            pro: { type: Object, required: true },
            level: { type: Number, required: true },
            isPriceDisplayed: { type: Boolean, default: true },
        },

        computed: {
            bodyClass() {
                return {
                    'absolute': true,
                    'inset-0': true,
                    'p-15': this.level === 1,
                    'p-10': this.level !== 1,
                    'flex': true,
                    'items-end': true,
                    'text-white': true,
                    'space-x-10': true,
                    'justify-between': true,
                }
            },

            nameClass() {
                return {
                    'text-20': this.level === 1,
                    'text-16': this.level !== 1,
                    'font-bold': true,
                    'leading-heading': true,
                }
            },

            fromClass() {
                return {
                    'text-14': this.level === 1,
                    'text-12': this.level !== 1,
                }
            },

            priceClass() {
                return {
                    'text-24': this.level === 1,
                    'text-18': this.level !== 1,
                    'font-bold': true,
                    'leading-heading': true,
                }
            },
        },
    }
</script>
