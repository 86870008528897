<template>
    <GuestAppointmentsIndex :current-type="$route.params.type" class="bg-gray-6">
        <BaseBlankSlate
            v-if="appointments?.length === 0"
            :message="$t('guest.appointments.blank.message')"
            :cta-label="$t('guest.appointments.blank.cta')"
            cta-route="/book"
        />

        <div v-else class="space-y-20">
            <GuestAppointmentsIndexExport
                v-if="$route.params.type === 'future'"
                :appointments="appointments"
            />

            <GuestAppointmentsIndexAppointment
                v-for="(appointment, index) in appointments"
                :key="index"
                :appointment="appointment"
                :is-link="true"
                class="bg-white shadow-md"
            />

            <BaseButton
                v-if="!isPaginationFinished"
                :label="$t('common.load-more')"
                :level="2"
                :is-disabled="isPaging"
                @click="loadNextPage"
            />
        </div>
    </GuestAppointmentsIndex>
</template>

<script>
    import ApiGuestAppointments from '@/apis/ApiGuestAppointments'
    import MixinPaginate from '@/mixins/MixinPaginate'
    import GuestAppointmentsIndex from '@/components/guest/appointments/GuestAppointmentsIndex'
    import GuestAppointmentsIndexExport from '@/components/guest/appointments/index/GuestAppointmentsIndexExport'
    import GuestAppointmentsIndexAppointment from '@/components/guest/appointments/index/GuestAppointmentsIndexAppointment'
    import BaseButton from '@/components/base/BaseButton'
    import BaseBlankSlate from '@/components/base/BaseBlankSlate'

    export default {
        components: {
            GuestAppointmentsIndex,
            GuestAppointmentsIndexExport,
            GuestAppointmentsIndexAppointment,
            BaseButton,
            BaseBlankSlate,
        },

        mixins: [MixinPaginate],

        async loadData({ $auth, $route }) {
            const appointments = await ApiGuestAppointments.get($auth.getUser(), {
                type: $route.params.type,
                page: 1,
            })

            return { appointments }
        },

        computed: {
            paginatedListProp() {
                return 'appointments'
            },

            paginatedCall() {
                return ApiGuestAppointments.get(this.$auth.getUser(), {
                    type: this.$route.params.type,
                    page: this.page,
                })
            },
        },
    }
</script>
