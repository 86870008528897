<template>
    <component :is="headingElement" :class="headingClass" class="break-words w-full">
        <slot>{{ heading }}</slot>
    </component>
</template>

<script>
    export default {
        props: {
            heading: { type: String, default: '' },
            level: { type: Number, default: 1 },
        },

        computed: {
            headingElement() {
                return `h${this.level}`
            },

            headingClass() {
                return {
                    1: 'text-40 leading-heading font-bold font-serif tracking-tight text-purple',
                    2: 'text-36 leading-normal text-purple',
                    3: 'text-40 leading-heading font-bold text-purple',
                    4: 'text-24 leading-heading font-bold',
                    5: 'text-14 leading-heading font-bold tracking-widest text-md uppercase',
                }[this.level]
            },
        },
    }
</script>
