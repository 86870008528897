<template>
    <LayoutOverlay back-button="/pro/profile">
        <header class="sticky z-layout top-70 bg-white">
            <BaseHeading
                :heading="$t('pro.profile.addons.your-addons')"
                :level="5"
                class="text-center py-30"
            />
        </header>

        <div class="pb-20">
            <div v-for="(addOn, index) in addOns" :key="index" :class="addOnClass(index)">
                <ProProfileAddOnsAddOn :add-on="addOn" />
            </div>
        </div>

        <FormButtons class="-shadow-md">
            <ProProfileAdd
                :label="$t('pro.profile.addons.add-another')"
                route="/pro/profile/addons/add"
            />
        </FormButtons>
    </LayoutOverlay>
</template>

<script>
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import BaseHeading from '@/components/base/BaseHeading'
    import ProProfileAdd from '@/components/pro/profile/ProProfileAdd'
    import FormButtons from '@/components/form-elements/FormButtons'
    import ProProfileAddOnsAddOn from '@/components/pro/profile/addons/ProProfileAddOnsAddOn'

    export default {
        components: {
            LayoutOverlay,
            BaseHeading,
            ProProfileAdd,
            FormButtons,
            ProProfileAddOnsAddOn,
        },

        mixins: [MixinProExperiences],

        loadData({ $router, $auth }) {
            const addOns = $auth.getUser().addOns

            if (addOns.length === 0) {
                $router.push('/pro/profile/addons/add')
            }

            return {
                addOns,
            }
        },

        methods: {
            addOnClass(index) {
                return {
                    'mt-30': index > 0,
                    'pt-30': index > 0,
                    'border-t-1': index > 0,
                    'border-gray-5': index > 0,
                }
            },
        },
    }
</script>
