<template>
    <LandingContainer class="pt-40 md:pt-60 lg:pt-80">
        <section class="ml-auto md:w-8/12 lg:w-6/12 space-y-20 md:space-y-30">
            <LandingHeading :level="3">
                {{ $t('landing.pros.visionaries.heading') }}
            </LandingHeading>

            <LandingText :content="$t('landing.pros.visionaries.description')" />

            <LandingHeading :level="5">
                {{ $t('landing.pros.visionaries.best') }}
            </LandingHeading>
        </section>

        <div
            class="
                flex
                items-center
                justify-center
                mt-30
                md:mt-0
                md:absolute
                z-above
                md:transform
                md:top-6/12
                md:-translate-y-6/12
                md:w-5/12
                md:-left-2/12
                lg:left-60
                xl:left-70
                xl:translate-y-0
                xl:-top-2/12
            "
        >
            <PictureLandingProsApp />
        </div>

        <div class="ml-auto md:w-8/12 lg:w-6/12 flex pt-30 md:pt-60">
            <div class="w-4/12 flex-shrink-0">
                <img :src="quotePicture" class="rounded-xl" />
            </div>

            <LandingQuote
                :content="$t('landing.pros.visionaries.quote.content')"
                :author="$t('landing.pros.visionaries.quote.author')"
                :level="1"
                class="w-full ml-30 sm:ml-40 md:ml-1/12 md:w-7/12"
            />
        </div>
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'
    import LandingText from '@/components/landing/LandingText'
    import LandingQuote from '@/components/landing/LandingQuote'
    import PictureLandingProsApp from '@/assets/vectors/picture-landing-pros-app.svg'

    export default {
        components: {
            LandingContainer,
            LandingHeading,
            LandingText,
            LandingQuote,
            PictureLandingProsApp,
        },

        computed: {
            quotePicture() {
                return require('@/assets/images/picture-landing-pros-person.jpg').default
            },
        },
    }
</script>
