import store from '@/store'
import ServiceAuth from '@/services/ServiceAuth'
import ServiceUi from '@/services/ServiceUi'
import GuestAlertConfirmEmail from '@/components/guest/alerts/GuestAlertConfirmEmail'

const PAGES_WITHOUT_ALERTS = ['/guest/verify']

const toggleConfirmEmailAlert = (route) => {
    setTimeout(() => {
        const isOnPageWithoutAlert = PAGES_WITHOUT_ALERTS.includes(route.path)
        const isEmailAlreadyConfirmed = ServiceAuth.getUser().isEmailVerified
        const isHiddenInBookingAfterLogin = !!store.getters['continuity/getBook']

        if (isOnPageWithoutAlert || isEmailAlreadyConfirmed || isHiddenInBookingAfterLogin) {
            return ServiceUi.alerts.close('confirm-email')
        }

        ServiceUi.alerts.open('confirm-email', GuestAlertConfirmEmail)
    })
}

const toggleVerifyIdAlert = () => {
    setTimeout(() => {
        // Always close ID verification alert when switching the page.
        ServiceUi.alerts.close('verify-id')
    })
}

export default function(to, from, next) {
    if (ServiceAuth.isGuest()) {
        toggleConfirmEmailAlert(to)
        toggleVerifyIdAlert(to)
    }

    next()
}
