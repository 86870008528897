function setupExpiration(store) {
    store.commit(
        'setTimeout',
        setTimeout(() => store.dispatch('setMessage', undefined), 3000),
    )
}

export default {
    namespaced: true,

    state: {
        message: undefined,
        timeout: undefined,
    },

    getters: {
        getMessage(state) {
            return state.message
        },

        getTimeout(state) {
            return state.timeout
        },
    },

    mutations: {
        setMessage(state, message) {
            state.message = message
        },

        setTimeout(state, timeout) {
            state.timeout = timeout
        },
    },

    actions: {
        showPostponed(store) {
            if (store.getters.getMessage?.isPostponed) {
                store.commit('setMessage', {
                    ...store.getters.getMessage,
                    isPostponed: false,
                })

                setupExpiration(store)
            }
        },

        setMessage(store, message) {
            clearTimeout(store.getters.getTimeout)
            store.commit('setMessage', message)

            if (!message) {
                return
            }

            if (!message.isPostponed) {
                setupExpiration(store)
            }
        },
    },
}
