<template>
    <div>
        <ProProfileLink
            v-for="(link, index) in links"
            :key="index"
            :is-truncated="true"
            v-bind="link"
        />
    </div>
</template>

<script>
    import ProProfileLink from '@/components/pro/profile/ProProfileLink'
    import ProProfilePlacesProAddress from '@/components/pro/profile/places/pro/ProProfilePlacesProAddress'
    import ProProfilePlacesProQuestions from '@/components/pro/profile/places/pro/ProProfilePlacesProQuestions'

    export default {
        components: {
            ProProfileLink,
        },

        props: {
            formData: { type: Object, required: true },
        },

        computed: {
            links() {
                return [
                    {
                        heading: this.$t('pro.profile.places.pro.links.address.heading'),
                        content: `
                            ${this.formData.address?.address},
                            ${this.formData.address?.zipCode}
                            ${this.formData.address?.city},
                            ${this.formData.address?.state}
                        `,
                        overlayComponent: ProProfilePlacesProAddress,
                        overlayProps: { initialFormData: this.formData },
                    },
                    {
                        heading: this.$t('pro.profile.places.pro.links.questions.heading'),
                        content: this.formData.prosPlaceLocationQuestion,
                        description: this.formData.prosPlaceLocationQuestion
                            ? null
                            : this.$t('pro.profile.places.pro.links.questions.heading'),
                        overlayComponent: ProProfilePlacesProQuestions,
                        overlayProps: { initialFormData: this.formData },
                    },
                ]
            },
        },
    }
</script>
