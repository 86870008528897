<template>
    <div :class="avatarClass">
        <img v-if="photo" :src="photo" class="absolute h-full w-full object-cover" />
        <p v-else-if="initials" class="font-serif text-24 leading-none tracking-tight">
            {{ initials }}
        </p>
        <IconProfile v-else class="h-6/12 w-6/12" />
    </div>
</template>

<script>
    import IconProfile from '@/assets/vectors/icon-profile.svg'

    export default {
        components: {
            IconProfile,
        },

        props: {
            photo: { type: String, default: null },
            name: { type: Object, default: null },
        },

        computed: {
            initials() {
                return [this.name?.firstName[0], this.name?.lastName[0]].join('')
            },

            avatarClass() {
                return {
                    'relative': true,
                    'flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'overflow-hidden': true,
                    'bg-gray-5': !this.initials,
                    'text-gray-4': !this.initials && !this.photo,
                    'bg-magenta': this.initials && !this.photo,
                    'text-white': this.initials && !this.photo,
                }
            },
        },
    }
</script>
