<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import ProProfilePlacesGuestQuestions from '@/components/pro/profile/places/guest/ProProfilePlacesGuestQuestions'

    export default {
        extends: ProProfilePlacesGuestQuestions,

        mixins: [MixinFormStep],
    }
</script>
