<template>
    <article class="flex flex-col items-center text-center space-y-10">
        <BaseAvatar :photo="guest.profilePicture" class="h-80 w-80 rounded-full mb-10" />
        <BaseHeading :heading="guestName" :level="5" />
        <BaseText
            :content="$t('pro.clients.not-in', { name: guest.firstName })"
            :is-light="true"
            class="w-190"
        />
        <BaseLink :label="$t('pro.clients.add-to', { name: guest.firstName })" :route="addRoute" />
    </article>
</template>

<script>
    import { composeFullName } from '@/helpers'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'

    export default {
        components: {
            BaseAvatar,
            BaseHeading,
            BaseText,
            BaseLink,
        },

        props: {
            guest: { type: Object, required: true },
        },

        computed: {
            guestName() {
                return composeFullName(this.guest)
            },

            addRoute() {
                return { path: `/pro/clients/add`, query: { guest: this.guest.id } }
            },
        },
    }
</script>
