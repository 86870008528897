<template>
    <ul>
        <template v-if="!isTotalOnly">
            <li v-for="(position, index) in positions" :key="index" :class="positionClass(index)">
                <p class="text-16 font-bold w-full">{{ position.name }}</p>
                <p class="text-24 text-right whitespace-nowrap">
                    <slot name="position" :position="position" :index="index">
                        {{
                            position.price >= 0
                                ? `$ ${formatAmount(position.price)}`
                                : `- $ ${Math.abs(formatAmount(position.price))}`
                        }}
                    </slot>
                </p>
                <button
                    v-if="isRemovable && position.isRemovable"
                    class="p-10 -my-10 -mr-10"
                    @click="removePosition(index, position)"
                >
                    <IconCloseAlt class="h-15 w-15" />
                </button>
            </li>
        </template>

        <li :class="positionClass(isTotalOnly ? 0 : positions.length)">
            <p class="text-16 uppercase w-full font-black">{{ $t('common.total') }}</p>
            <p class="text-24 text-right whitespace-nowrap font-bold">
                $ {{ formatAmount(totalPrice) }}
            </p>
        </li>
    </ul>
</template>

<script>
    import { formatAmount } from '@/helpers'
    import IconCloseAlt from '@/assets/vectors/icon-close-alt.svg'

    export default {
        components: {
            IconCloseAlt,
        },

        props: {
            positions: { type: Array, default: () => [] },
            isTotalOnly: { type: Boolean, default: false },
            isRemovable: { type: Boolean, default: false },
            hasSeparators: { type: Boolean, default: true },
        },

        emits: ['remove-position'],

        computed: {
            totalPrice() {
                return this.positions.reduce(
                    (total, { price }) => total + parseFloat(price || 0),
                    0,
                )
            },
        },

        methods: {
            formatAmount,

            positionClass(index) {
                return {
                    'flex': true,
                    'min-h-60': !this.isTotalOnly,
                    'pt-10': index > 0,
                    'border-t-1': index > 0 && this.hasSeparators,
                    'border-gray-5': this.hasSeparators,
                    'items-center': true,
                }
            },

            removePosition(index, position) {
                this.$emit('remove-position', { index, position })
            },
        },
    }
</script>
