<template>
    <article :class="appointmentClass">
        <div :class="borderClass" />
        <div class="w-full truncate">
            <h4 class="font-bold text-13">
                {{ clientName }}
            </h4>
            <p v-if="isDetailedView" :class="detailsClass">
                <template v-if="isCancelled">
                    <p>{{ $t('common.cancelled') }}</p>
                </template>
                <template v-else>
                    {{ appointmentAddress?.address }}
                    <br v-if="size === 'default'" />
                    {{ appointmentAddress?.zipCode }}
                    {{ appointmentAddress?.city }}
                    <br v-if="size === 'default'" />
                    {{ appointmentAddress?.state }}
                </template>
            </p>
        </div>
        <component
            :is="appointmentCategoryIcon"
            v-if="isCategoryDisplayed"
            :class="experienceClass"
        />
        <p v-if="isDetailedView" class="flex items-center ml-auto pt-2 pr-10 pl-5">
            <em class="text-20 not-italic mr-3">$</em>
            <strong class="font-bold text-22">{{ appointment.price }}</strong>
        </p>
    </article>
</template>

<script>
    import { composeFullName } from '@/helpers'
    import MixinAppointment from '@/mixins/MixinAppointment'

    export default {
        mixins: [MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
            size: { type: String, default: 'default' },
            isCancelled: { type: Boolean, default: false },
        },

        computed: {
            clientName() {
                const guest = this.appointment?.guest

                return this.isDetailedView
                    ? composeFullName(guest)
                    : [guest.firstName?.[0], guest.lastName?.[0]].join('')
            },

            isDetailedView() {
                return ['default', 'medium'].includes(this.size)
            },

            isCategoryDisplayed() {
                return ['default', 'medium', 'small'].includes(this.size)
            },

            appointmentClass() {
                return {
                    'flex': true,
                    'items-start': true,
                    'w-full': this.isDetailedView,
                    'flex-col': !this.isDetailedView,
                    'items-center': !this.isDetailedView,
                    'relative': true,
                    'px-5': true,
                    'py-5': this.appointmentDuration >= 30,
                    'pl-10': true,
                    'border-1': true,
                    'border-gray-4': true,
                    'bg-white': true,
                    'text-gray-4': this.isCancelled,
                    'line-through': this.isCancelled && !this.isDetailedView,
                }
            },

            experienceClass() {
                return {
                    'flex-shrink-0': true,
                    'mt-3': this.isDetailedView,
                    'mr-10': this.isDetailedView,
                    'w-25': this.isDetailedView,
                    'h-25': this.isDetailedView,
                    'order-first': this.isDetailedView,
                    'w-20': !this.isDetailedView,
                    'h-20': !this.isDetailedView,
                    'mx-1': !this.isDetailedView,
                }
            },

            detailsClass() {
                return {
                    'text-12': true,
                    'text-gray-2': !this.isCancelled,
                    'truncate': this.size === 'medium',
                    'whitespace-nowrap': this.size === 'medium',
                }
            },

            borderClass() {
                return {
                    'absolute': true,
                    '-inset-y-1': true,
                    '-left-1': true,
                    'w-4': true,
                    'bg-green': !this.isCancelled,
                    'bg-red': this.isCancelled,
                }
            },
        },
    }
</script>
