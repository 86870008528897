export default {
    computed: {
        appointmentPrices() {
            return [
                ...this.appointment.experiences.map((experience, index) => ({
                    name: experience.name,
                    path: `experiences.${index}.price`,
                    price: this.formData.experiences?.[index]?.price,
                    isField: true,
                })),
                ...this.appointment.addOns.map((addOn, index) => ({
                    name: addOn.name,
                    path: `addOns.${index}.price`,
                    price: this.formData.addOns?.[index]?.price,
                    isField: true,
                })),
                {
                    name: this.$t('common.extras'),
                    path: 'extras',
                    price: this.formData.extras,
                    isField: true,
                },
                {
                    name: this.$t('common.discount'),
                    price: -this.discountAmount,
                    isDiscount: true,
                },
                {
                    name: this.$t('common.tip'),
                    path: 'tip',
                    price: this.formData.tip || 0,
                    isField: true,
                },
                {
                    name: this.$t('common.fees.service'),
                    price: this.$store.getters['dictionaries/getServiceFee'],
                },
            ]
        },

        discountableAmount() {
            // If discount is percentage based, count all the items from receipt
            // (experiences, addons, extras) and get percentage based amount.
            const experiencesAmount = Object.values(this.formData.experiences || {}).reduce(
                (sum, { price }) => sum + parseFloat(price || 0),
                0,
            )
            const addOnsAmount = Object.values(this.formData.addOns || {}).reduce(
                (sum, { price }) => sum + parseFloat(price || 0),
                0,
            )
            const extrasAmount = parseFloat(this.formData.extras || 0)

            return experiencesAmount + addOnsAmount + extrasAmount
        },

        discountAmount() {
            if (!this.formData.discountType || !this.formData.discountAmount) {
                return 0
            }

            return this.formData.discountType === 'flat'
                ? Math.min(this.discountableAmount, this.formData.discountAmount || 0)
                : this.discountableAmount * (parseFloat(this.formData.discountAmount) / 100)
        },

        totalAmount() {
            return this.appointmentPrices.reduce(
                (total, { price }) => total + parseFloat(price || 0),
                0,
            )
        },
    },
}
