<template>
    <LayoutPro :back-button="backButton" @back="isCustomPeriodOpen = false">
        <ProDashboardCustomPeriod v-if="isCustomPeriodOpen" @select-period="selectCustomPeriod" />

        <template v-else>
            <BaseHeaderSection
                :heading="$t('pro.dashboard.heading', { name: $auth.getUser().firstName })"
                :subheading="$t('pro.dashboard.subheading')"
                class="pb-10 mb-10 sticky z-floating top-70 bg-white"
            />

            <div class="space-y-40 pb-20">
                <div class="relative">
                    <button
                        class="absolute right-0 top-6/12 transform -translate-y-6/12"
                        @click="isViewsPanelOpen = true"
                    >
                        <IconDots class="h-25 text-gray-3 px-10 -mr-10" />
                    </button>

                    <ProDashboardDates
                        :view="view"
                        :start-date="startDate"
                        :end-date="endDate"
                        @click-custom-period="isCustomPeriodOpen = true"
                    />
                </div>

                <BaseHeaderDivider :heading="$t('pro.dashboard.earned')" />

                <ProDashboardEarned :stats="stats" />

                <BaseHeaderDivider :heading="$t('pro.dashboard.performance')" />

                <ProDashboardPerformance :stats="stats" />

                <ProDashboardChart :stats="stats" />
            </div>
        </template>
    </LayoutPro>

    <ProDashboardActions
        :is-open="isViewsPanelOpen"
        @switch-view="switchView"
        @close="isViewsPanelOpen = false"
    />
</template>

<script>
    import dayjs from 'dayjs'
    import ApiPros from '@/apis/ApiPros'
    import LayoutPro from '@/components/layouts/LayoutPro'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import ProDashboardCustomPeriod from '@/components/pro/dashboard/ProDashboardCustomPeriod'
    import ProDashboardDates from '@/components/pro/dashboard/ProDashboardDates'
    import ProDashboardEarned from '@/components/pro/dashboard/ProDashboardEarned'
    import ProDashboardPerformance from '@/components/pro/dashboard/ProDashboardPerformance'
    import ProDashboardChart from '@/components/pro/dashboard/ProDashboardChart'
    import ProDashboardActions from '@/components/pro/dashboard/ProDashboardActions'
    import IconDots from '@/assets/vectors/icon-dots.svg'

    const DEFAULT_VIEW = 'week'

    export default {
        components: {
            LayoutPro,
            BaseHeaderSection,
            BaseHeaderDivider,
            ProDashboardCustomPeriod,
            ProDashboardDates,
            ProDashboardEarned,
            ProDashboardPerformance,
            ProDashboardChart,
            ProDashboardActions,
            IconDots,
        },

        data() {
            return {
                isCustomPeriodOpen: false,
                isViewsPanelOpen: false,
                view: DEFAULT_VIEW,
                startDate: null,
                endDate: null,
            }
        },

        async loadData({ $auth, getStartEndDates }) {
            const stats = await ApiPros.getStats($auth.getUser().id, getStartEndDates(DEFAULT_VIEW))

            return { stats }
        },

        computed: {
            startEndDates() {
                return this.getStartEndDates(this.view)
            },

            backButton() {
                return this.isCustomPeriodOpen ? true : false
            },
        },

        watch: {
            isCustomPeriodOpen() {
                window.scrollTo({ top: 0, left: 0 })
            },
        },

        methods: {
            getStartEndDates(view) {
                switch (view) {
                    case 'day':
                        return {
                            startDate: dayjs(),
                            endDate: dayjs(),
                        }
                    case 'week':
                        return {
                            startDate: dayjs().startOf('week'),
                            endDate: dayjs().endOf('week'),
                        }
                    case 'month':
                        return {
                            startDate: dayjs().startOf('month'),
                            endDate: dayjs().endOf('month'),
                        }
                    case 'custom':
                        return {
                            startDate: this.startDate,
                            endDate: this.endDate,
                        }
                }
            },

            async loadStatsForView() {
                this.stats = await ApiPros.getStats(this.$auth.getUser().id, this.startEndDates)
            },

            switchView(view) {
                this.isViewsPanelOpen = false

                if (view === 'custom') {
                    return (this.isCustomPeriodOpen = true)
                }

                this.view = view

                this.loadStatsForView()
            },

            selectCustomPeriod({ startDate, endDate }) {
                this.isCustomPeriodOpen = false
                this.view = 'custom'
                this.startDate = startDate
                this.endDate = endDate

                this.loadStatsForView()
            },
        },
    }
</script>
