<template>
    <BaseAvatar :photo="appointment.pro.profilePicture" class="rounded-full h-70 w-70 mx-auto" />

    <div class="border-b-1 border-gray-4 w-60 mx-auto my-30" />

    <BaseHeading
        :heading="
            $t('guest.appointments.rate.question.how-went', { name: appointment.pro.firstName })
        "
        :level="2"
        class="text-center"
    />

    <div class="mt-50 space-y-40">
        <BaseButton :label="$t('guest.appointments.rate.question.all-good')" @click="goToStep(3)" />
        <BaseButton :label="noShowLabel" :level="2" @click="goToStep(2)" />
    </div>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            BaseAvatar,
            BaseHeading,
            BaseButton,
        },

        mixins: [MixinFormStep, MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
        },

        computed: {
            noShowLabel() {
                return this.inGuestsPlace
                    ? this.$t('guest.appointments.rate.question.no-show.pro')
                    : this.$t('guest.appointments.rate.question.no-show.guest')
            },
        },
    }
</script>
