<template>
    <LayoutOverlay :back-button="backButton" @back="handleBack">
        <component :is="currentStep.component" :step-data="formData" @change="changeData">
            <template #buttons="{ isSubmittable }">
                <FormButtons>
                    <BaseLink
                        v-if="!isSubmittable"
                        :label="$t('common.skip')"
                        class="p-15 block mx-auto"
                        @click="handleNext"
                    />

                    <BaseButton
                        v-else-if="submitLabel"
                        :label="submitLabel"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                        @click="handleNext"
                    />
                </FormButtons>
            </template>
        </component>
    </LayoutOverlay>
</template>

<script>
    import i18n from '@/i18n'
    import ApiProAppointments from '@/apis/ApiProAppointments'
    import ApiProClients from '@/apis/ApiProClients'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import FormButtons from '@/components/form-elements/FormButtons'
    import BaseButton from '@/components/base/BaseButton'
    import BaseLink from '@/components/base/BaseLink'
    import ProClientsAddGuest1 from '@/components/pro/clients/add-guest/ProClientsAddGuest1'
    import ProClientsAddGuest2 from '@/components/pro/clients/add-guest/ProClientsAddGuest2'

    export default {
        components: {
            LayoutOverlay,
            FormButtons,
            BaseButton,
            BaseLink,
            ProClientsAddGuest1,
            ProClientsAddGuest2,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormSteppable],

        async loadData({ $auth, $route, $router, $ui }) {
            const { guest } = await ApiProAppointments.getOne($auth.getUser(), $route.params.id)
            const isClient = await ApiProClients.checkIfGuestInClientBook(
                $auth.getUser().id,
                guest.id,
            )

            if (isClient) {
                $ui.flash.error(
                    i18n.global.t('pro.clients.add-guest.error', { name: guest.firstName }),
                )
                $router.push(`/pro/appointments/${$route.params.id}`)
            }

            return { guest }
        },

        computed: {
            steps() {
                return [{ component: ProClientsAddGuest1 }, { component: ProClientsAddGuest2 }]
            },

            backButton() {
                return this.isFirstStep ? '/pro/appointments/previous' : true
            },

            submitLabel() {
                return this.isFirstStep ? this.$t('common.next') : this.$t('common.save')
            },
        },

        mounted() {
            this.initForm({
                firstName: this.guest?.firstName,
                lastName: this.guest?.lastName,
                email: this.guest?.email,
                phone: this.guest?.phone,
                address: this.guest?.address,
                profilePicture: this.guest?.profilePicture,
            })
        },

        methods: {
            handleBack() {
                this.goToPreviousStep()
            },

            handleNext() {
                this.isLastStep ? this.save() : this.goToNextStep()
            },

            save() {
                return this.handleSubmit(
                    ApiProClients.create(this.$auth.getUser().id, this.formData),
                ).then((client) => {
                    this.$ui.flash.success(
                        this.$t('pro.clients.add-guest.success', { name: client.firstName }),
                    )
                    this.$router.push(`/pro/clients/${client.id}`)
                })
            },
        },
    }
</script>
