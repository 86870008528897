<template>
    <div>
        <BaseLink :label="toggleLabel" @click="toggleAllOptions" />

        <div v-for="(option, index) in options" :key="index" :class="optionClass(index)">
            <InputCheckbox
                :label="option.label"
                :value="isOptionSelected(option)"
                @change="toggleOption(option)"
            >
                <template #label>
                    <slot name="label" :option="option" />
                </template>
            </InputCheckbox>
        </div>
    </div>
</template>

<script>
    import MixinFormOptionable from '@/mixins/MixinFormOptionable'
    import BaseLink from '@/components/base/BaseLink'
    import InputCheckbox from '@/components/form-inputs/InputCheckbox'

    export default {
        components: {
            BaseLink,
            InputCheckbox,
        },

        mixins: [MixinFormOptionable],

        props: {
            value: { type: Array, default: null },
            selectLabel: { type: String, default: null },
            deselectLabel: { type: String, default: null },
            hasSeparators: { type: Boolean, default: true },
        },

        emits: ['change'],

        computed: {
            areAllOptionsSelected() {
                return !this.options.some((option) => !this.isOptionSelected(option))
            },

            toggleLabel() {
                return this.areAllOptionsSelected
                    ? this.deselectLabel || this.$t('common.deselect-all')
                    : this.selectLabel || this.$t('common.select-all')
            },
        },

        methods: {
            change(value) {
                this.$emit('change', value)
            },

            toggleAllOptions() {
                this.change(
                    this.areAllOptionsSelected ? [] : this.options.map(({ value }) => value),
                )
            },

            toggleOption(option) {
                this.isOptionSelected(option)
                    ? this.change((this.value || []).filter((value) => value !== option.value))
                    : this.change([...(this.value || []), option.value])
            },

            isOptionSelected({ value }) {
                return (this.value || []).includes(value)
            },

            optionClass(index) {
                return {
                    'mt-20': index === 0 || !this.hasSeparators,
                    'py-20': this.hasSeparators,
                    'border-t-1': this.hasSeparators && index > 0,
                    'border-gray-5': this.hasSeparators && index > 0,
                }
            },
        },
    }
</script>
