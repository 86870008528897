<template>
    <LayoutApp back-button="/pro/profile">
        <ProSection
            :heading="$t('pro.profile.picture.heading')"
            :description="$t('pro.profile.picture.description')"
        >
            <FormWrapper @submit="save">
                <ProProfilePicture :file="file" :is-editable="true" @select-file="file = $event" />

                <FormButtons>
                    <BaseButton
                        :label="$t('common.save')"
                        :is-disabled="!isSubmittable"
                        :is-processing="isSubmitting"
                    />
                </FormButtons>
            </FormWrapper>
        </ProSection>
    </LayoutApp>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'
    import ProProfilePicture from '@/components/pro/profile/ProProfilePicture'

    export default {
        components: {
            LayoutApp,
            BaseButton,
            FormWrapper,
            FormButtons,
            ProProfilePicture,
        },

        mixins: [MixinProProfileSection],

        data() {
            return {
                file: null,
            }
        },

        computed: {
            isSubmittable() {
                return this.file
            },
        },

        methods: {
            apiCall() {
                const formData = new FormData()
                formData.append('file', this.file)

                return ApiPros.updateProfilePicture(this.$auth.getUser().id, formData)
            },
        },
    }
</script>
