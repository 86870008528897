<template>
    <div class="space-y-40">
        <BaseHeaderSection :is-center="false">
            <template #heading>
                <span class="inline-flex items-center">
                    <span>{{ $t('pro.onboarding.confirm.heading') }}</span>
                    <IconSuccess class="h-35 w-35 text-green animate-pop ml-15" />
                </span>
            </template>
        </BaseHeaderSection>

        <BaseMessage type="success">
            <i18n-t tag="p" keypath="pro.onboarding.confirm.confirm-email.text-1">
                <template #email>
                    <BaseLink :label="$auth.getUser()?.email" />
                </template>
            </i18n-t>

            <i18n-t tag="p" keypath="pro.onboarding.confirm.confirm-email.text-2">
                <template #send>
                    <template v-if="isSendingVerificationEmail || isVerificationEmailSent">
                        <b>{{ sendButtonLabel }}</b>
                    </template>
                    <template v-else>
                        <BaseLink :label="sendButtonLabel" @click="sendVerificationEmail" />
                    </template>
                </template>
            </i18n-t>
        </BaseMessage>

        <div class="flex items-center space-x-15 font-bold">
            <IconEmail class="h-25" />
            <span>{{ $t('pro.onboarding.confirm.check-inbox') }}</span>
        </div>
    </div>
</template>

<script>
    import ApiAuth from '@/apis/ApiAuth'
    import MixinFormStep from '@/mixins/MixinFormSubmittable'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseMessage from '@/components/base/BaseMessage'
    import BaseLink from '@/components/base/BaseLink'
    import IconSuccess from '@/assets/vectors/icon-success.svg'
    import IconEmail from '@/assets/vectors/icon-email.svg'

    export default {
        components: {
            BaseHeaderSection,
            BaseMessage,
            BaseLink,
            IconSuccess,
            IconEmail,
        },

        mixins: [MixinFormStep],

        data() {
            return {
                isSendingVerificationEmail: false,
                isVerificationEmailSent: false,
            }
        },

        computed: {
            isSubmittable() {
                return (
                    this.formData.firstName &&
                    this.formData.lastName &&
                    this.formData.email &&
                    this.formData.password
                )
            },

            sendButtonLabel() {
                if (this.isSendingVerificationEmail) {
                    return this.$t('pro.onboarding.confirm.confirm-email.sending')
                }

                if (this.isVerificationEmailSent) {
                    return this.$t('pro.onboarding.confirm.confirm-email.sent')
                }

                return this.$t('pro.onboarding.confirm.confirm-email.send')
            },
        },

        methods: {
            sendVerificationEmail() {
                if (this.isSendingVerificationEmail || this.isVerificationEmailSent) {
                    return
                }

                this.isSendingVerificationEmail = true

                ApiAuth.sendVerificationEmailToPro(this.$auth.getUser().id).then(() => {
                    this.isSendingVerificationEmail = false
                    this.isVerificationEmailSent = true
                })
            },
        },
    }
</script>
