<template>
    <div class="flex align-top items-center space-x-30 mx-auto h-150 whitespace-nowrap">
        <BaseChoice
            v-for="(categoryItem, index) in categoryItems"
            :key="index"
            :icon="categoryItem.icon"
            :label="categoryItem.label"
            :level="categoryItem.level"
            :is-selected="categoryItem.isSelected"
            @click="$emit('select-category', categoryItem.category)"
        />
    </div>
</template>

<script>
    import { getCategoryIcon } from '@/helpers'
    import BaseChoice from '@/components/base/BaseChoice'

    export default {
        components: {
            BaseChoice,
        },

        props: {
            categories: { type: Array, required: true },
            selectedCategory: { type: Object, default: null },
        },

        emits: ['select-category'],

        computed: {
            categoryItems() {
                return this.categories.map((category) => {
                    const isSelected = category.id === this.selectedCategory?.id

                    return {
                        icon: getCategoryIcon(category),
                        level: isSelected ? 1 : 3,
                        label: isSelected ? category.name : null,
                        isSelected,
                        category,
                    }
                })
            },
        },
    }
</script>
