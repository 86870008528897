export default {
    book: {
        'charge-info':
            'This amount will be charged at the time of service. This does not include any gratuity you might want to provide.',
        'what': {
            'heading': 'What?',
            'subheading': {
                find: 'Find an experience',
                choose: 'Choose your experience',
            },
            'search-experience': 'Search for experiences or Pros',
            'add-experience': 'Add experience',
            'search': 'Search',
        },
        'where': {
            'heading': 'Where?',
            'subheading': 'Tell us where you’d like us to look.',
            'enter-zip-code': 'Enter Zip or Address',
        },
        'when': {
            heading: 'When?',
            subheading: 'Pick an option',
            options: {
                'now': 'Now',
                'tomorrow': 'Tomorrow',
                'next-days': 'Next 7 days',
                'date-range': 'Select a date range',
                'flexible': 'I’m flexible',
            },
            dates: {
                'tomorrow': 'Tomorrow',
                'start-date': 'Start date',
                'end-date': 'End date',
                'select': 'Select',
                'any-time': 'Any time',
            },
            place: {
                'whos-place': 'Your place or theirs?',
                'my-place': 'My Place',
                'your-place': 'Their Place',
                'either': 'Either',
            },
        },
        'who': {
            'heading': 'Who?',
            'subheading': 'Choose your pro',
            'grid': 'Grid',
            'map': 'Map',
            'book-with': 'Book with {name}',
            'place': {
                headings: {
                    pro: '{name} in salon',
                    guest: '{name} on location',
                },
                details: {
                    pro: 'In salon',
                    guest: 'Guest’s place',
                },
                confirm: 'Confirm',
            },
            'addons': {
                heading: 'By the way...',
                subheading: 'Mary also offers some nice add-ons!',
                thanks: 'No thanks',
                confirm: 'Confirm',
            },
            'address': {
                confirm: 'Confirm',
                address: {
                    enter: 'Enter your address',
                    confirm: 'Confirm your address',
                },
            },
            'empty': {
                heading: 'Sorry!',
                subheading: `
                    No results found :-(

                    Please change your search criteria and try again.
                `,
                cta: 'Start Search again',
            },
        },
        'at': {
            heading: '{name}’s availability',
        },
        'message': {
            'heading': 'Anything else you’d like {name} to know?',
            'label': 'Useful info for {name}',
            'placeholder':
                'Feel free to enter any notes or questions. For example, parking suggestions or notes on your service preferences.',
            'book-with': 'Book with {name}',
        },
        'summary': {
            heading: 'Order summary',
            subheading: 'Take one last look to confirm the details of your reservation',
            disclaimer: {
                text:
                    'Remember, by confirming this order you accept {name}’s {cancellation} and ours.',
                cancellation: 'Cancellation Policy',
            },
            confirm: 'Book',
            policies: {
                'heading': 'Please read',
                'subheading': 'Cancellation Policy',
                'cancellation': {
                    percent: `
                        You may cancel without charge any time up to {hours} before the appointment.

                        After you will need to pay {amount} of the total booking price.

                        In both cases the FreeByrdz platform service fee of {fee} is due and will be charged.`,
                    flat: `
                        You may cancel without charge any time up to {hours} before the appointment.

                        After you will need to pay a cancellation charge of {amount}.

                        In both cases the FreeByrdz platform service fee of {fee} is due and will be charged.
                    `,
                },
                'no-show': {
                    percent:
                        'In case of a no-show, you will be charged {amount} of the total booking price, plus the {fee} FreeByrdz platform service fee.',
                    flat:
                        'In case of a no-show, you will need to pay a no-show charge of {amount}, plus the {fee} FreeByrdz platform service fee.',
                },
            },
        },
        'payment': {
            heading: 'Payment',
            subheading: 'Set up your payment method',
            confirm: '${price} Confirm payment',
        },
        'confirmed': {
            'heading': 'Booked!',
            'note':
                'We’ve sent you an email with your book details and a link to <b>confirm your email address</b>.',
            'questions': {
                heading: '{name} has some questions for you.',
                enter: 'Enter your response',
                type: 'Type your response here…',
                send: 'Send response',
            },
            'go-to': 'Go to your bookings',
        },
    },
    appointments: {
        'heading': 'Your experiences',
        'blank': {
            message: `
                No experiences to show.

                Want to book one?
            `,
            cta: 'Book now',
        },
        'rate-x': 'Rate {name}',
        'add-to-calendar': {
            cta: 'Add to calendar',
            summary: '[FreeByrdz] Appointment with {pro}',
        },
        'repeat': 'Rebook',
        'reschedule': 'Reschedule',
        'x-question': '{x}’s question',
        'your-answer': 'Your response',
        'check-availability': 'Check availability',
        'repeat-experience': 'Repeat experience',
        'reschedule-appointment': 'Reschedule appointment',
        'call-x': 'Call {x}',
        'cancel-appointment': 'Cancel appointment',
        'my-appointments': 'My appointments',
        'no-show': 'Appointment did not occur!',
        'cancelled': 'Appointment cancelled!',
        'rescheduled': 'Appointment rescheduled!',
        'repeated': 'Appointment rebooked!',
        'when': {
            heading: 'When?',
            subheading: 'Pick your time',
        },
        'rate': {
            'question': {
                'how-went': 'How did the appointment with {name} go?',
                'all-good': 'All good',
                'no-show': {
                    pro: 'Didn’t show up',
                    guest: 'I didn’t go',
                },
            },
            'no-show-note':
                'In accordance with {name}’s no-show policy we’ve charged the no-show fee.',
            'total-paid': 'Total paid',
            'already-debited': 'Experience already debited',
            'add-tip': 'Would you like to add a gratuity?',
            'no-thanks': 'No thank you',
            'payment-processed': 'Your payment has been processed',
            'payment-details': 'Payment details',
            'how-rate': 'How do you rate {name}',
            'rate-x': 'Rate {name}',
            'write-review': {
                heading: 'Write a review',
                hint: 'Tell us more about your experience with Mary',
            },
            'thank-you': 'Thank you!',
            'make-recurrent': 'Want to make this appointment as recurrent?',
            'start-now': 'Start now',
            'wont-be-charged': 'You won’t be charged for this experience.',
            'want-book-again': 'Would you like to book again?',
            'check-availability': 'Check availability',
        },
        'cancel': {
            message: 'Are you sure to cancel the appointment with Mary?',
            note:
                'As it’s less than {hours} hrs before the appointment time, in accordance with Mary’s cancellation policy you will be charged the cancellation fee plus the platform service fee.',
        },
    },
    profile: {
        heading: 'Your FreeByrdz profile',
        picture: {
            add: 'Add picture',
            edit: 'Change picture',
            save: 'Save picture',
            upload: 'Upload from your library',
            take: 'Take a picture',
        },
        info: {
            heading: 'Account info',
            email: {
                text: 'If you need to change your primary email, please {contact}',
                contact: 'contact our staff',
                message: {
                    subject: 'Email address change request',
                    body:
                        'I’d like to change the email address I use to log in to my account. Please contact me to organise this. Thanks!',
                },
            },
            save: {
                info: 'Save account info',
                password: 'Save password',
            },
            password: {
                old: 'Enter old password',
                new: 'Enter new password',
            },
        },
        payment: {
            setup: {
                heading: 'Setup a Stripe account',
                description: 'We use Stripe to process payments',
            },
            edit: {
                heading: 'Your Stripe account',
            },
            change: 'Change payment method',
            save: 'Save',
            card: 'Credit card',
        },
        preferences: {
            heading: 'My preferences',
            description: 'What do you like',
            add: 'Add preferences',
            save: 'Save preferences',
        },
    },
    verify: {
        'heading': 'Verify your identity',
        'steps': ['Provide photo', 'Provide ID', 'Verify Id'],
        'provide-photo': 'Provide a clear picture of your face',
        'your-photo': 'Your picture',
        'change-photo': 'Change photo',
        'provide-id': 'Upload a photo of your official ID',
        'your-id': 'Your official ID',
        'verifying': 'Verifying ID…',
        'succeeded': {
            heading: 'Thank you!',
            copy: 'ID successfully verified!',
            cta: 'Go to your bookings',
        },
        'failed': {
            heading: 'ID not verified',
            copy: `
                Oops! It looks like our algorithm could not verify your ID.

                Not to worry though, we'll have a real human take a look. Please be patient while our team reviews your ID.
            `,
            cta: 'Go to your profile',
        },
        'status-label': {
            required: 'ID verification required',
            reviewing: 'ID under review',
            verified: 'ID verified',
        },
        'details': {
            reviewing: {
                message:
                    'We are manually reviewing your identity. you should receive confirmation shortly',
                note:
                    'We take your security very seriously. The identity of all our Pros is verified.',
                close: 'Understood',
            },
            required: {
                message:
                    'We need to verify your identity before the next appointment at your place',
                note:
                    'We take your security very seriously. The identity of all our Pros is verified. Verify yours and help us keep FreeByrdz a safe place.',
                start: 'Verify now',
                close: 'Ignore',
            },
        },
        'alert': {
            message:
                'To keep our community of clients and pros safe, we kindly ask you to verify your identity.',
            cta: 'Verify now',
        },
    },
    confirm: {
        'message': 'Your email address {email} has not been confirmed yet.',
        'cant-find': 'Can’t find the welcome email?',
        'resend': {
            text:
                'If you haven’t received our confirmation email with the link to activate your account, {resend}',
            send: 'Resend it now',
            sending: 'Sending…',
            sent: 'Sent! Check your inbox',
        },
    },
}
