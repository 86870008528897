<template>
    <LandingContainer class="mt-50 md:mt-100">
        <div class="px-20 py-30 text-center rounded-xl bg-sand-light">
            <h3 class="font-bold leading-heading text-20 mb-20">
                {{ $t('landing.pros.start.heading') }}
            </h3>

            <p class="font-medium mb-30">
                {{ $t('landing.pros.start.description') }}
            </p>

            <LandingButton
                :label="$t('landing.pros.join')"
                route="/auth/register/pro"
                class="md:inline-flex"
            />
        </div>
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingButton from '@/components/landing/LandingButton'

    export default {
        components: {
            LandingContainer,
            LandingButton,
        },
    }
</script>
