<template>
    <LayoutAdmin @select-period="loadNewReservations" @click="closeReviewDetails($event)">
        <div class="h-full p-30 flex flex-col justfy-between">
            <div class="flex flex-col">
                <div class="text-center text-16 font-black tracking-widest uppercase">
                    {{ $t('admin.dashboard.overallSatisfaction.label') }}
                </div>
                <div class="flex flex-row justify-between items-center mt-30">
                    <InputMultiSelect
                        :options="options"
                        :placeholder="$t('admin.dashboard.selectRating')"
                        :value="filter"
                        :select-all-option="selectAllOption"
                        class="w-250"
                        @change="onFilterChange"
                    />

                    <div class="flex flex-col justify-center items-center">
                        <div class="flex flex-row items-center">
                            <p class="text-35 font-bold text-black">{{ averageRating }}</p>
                            <IconStarFull class="w-25 h-25 text-black ml-5" />
                        </div>
                    </div>

                    <div class="flex justify-end w-250 cursor-pointer">
                        <IconExport class="w-40" @click="exportOverallSatisfactions" />
                    </div>
                </div>
            </div>

            <BaseTable
                ref="table"
                :headers="tableHeaders"
                class="mt-30"
                @goToPage="loadNewReservations"
            >
                <tr
                    v-for="(rating, index) in ratings"
                    :key="rating.id"
                    class="border-b-1 border-gray-4 h-60"
                >
                    <td class="p-5">
                        <div :class="overflowClass" class="flex flex-row items-center">
                            <BaseAvatar
                                :photo="rating.pro.profilePicture"
                                class="h-50 min-w-50 w-50 rounded-full"
                            />
                            <div :class="overflowClass" class="flex flex-col flex-wrap ml-5">
                                <div class="font-bold uppercase">{{ rating.pro.name }}</div>
                                <span :class="overflowClass" class="text-gray-3">
                                    {{ rating.pro.profileUrl }}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <IconGuest v-if="rating.workPlace === 'GuestsPlace'" class="h-22" />
                            <IconPro v-else class="h-22" />
                            <span class="ml-10">{{ rating.location }}</span>
                        </div>
                    </td>
                    <td class="p-5">
                        <div class="flex flex-col">
                            <div>{{ rating.experiences.join(', ') }}</div>
                            <div class="text-gray-3">On {{ rating.appointmentDate }}</div>
                        </div>
                    </td>
                    <td class="p-5 text-center">
                        <BaseRating v-if="rating.rating" :rating="rating.rating" :level="1" />
                        <span v-else>-</span>
                    </td>
                    <td
                        :ref="`review_${index}`"
                        :class="overflowClass"
                        class="max-w-300 p-5 cursor-pointer text-gray-3"
                        @click="showReviewDetails($event, index)"
                    >
                        {{ rating.note }}
                    </td>
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <BaseAvatar
                                :photo="rating.guest.profilePicture"
                                class="h-50 min-w-50 w-50 rounded-full"
                            />
                            <div class="flex flex-col flex-wrap ml-5">
                                <div class="text-gray-3">
                                    {{ $t('admin.dashboard.overallSatisfaction.ratedBy') }}
                                </div>
                                <div class="font-bold uppercase">{{ rating.guest.name }}</div>
                            </div>
                        </div>
                    </td>
                </tr>
            </BaseTable>
        </div>

        <BaseContextMenu
            ref="reviewMenu"
            :width="'250px'"
            :top="contextMenuTop"
            :left="contextMenuLeft"
            :bottom="contextMenuBottom"
        >
            <RatingDetails v-if="currentRating" :rating="currentRating" />
        </BaseContextMenu>
    </LayoutAdmin>
</template>

<script>
    import dayjs from 'dayjs'
    import ApiAdmins from '@/apis/ApiAdmins'
    import BaseTable from '@/components/base/BaseTable'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import BaseRating from '@/components/base/BaseRating'
    import IconGuest from '@/assets/vectors/icon-guest.svg'
    import RatingDetails from '@/components/admin/dashboard/RatingDetails'
    import IconExport from '@/assets/vectors/icon-export.svg'
    import LayoutAdmin from '@/components/layouts/LayoutAdmin'
    import IconStarFull from '@/assets/vectors/icon-star-full.svg'
    import BaseContextMenu from '@/components/base/BaseContextMenu'
    import InputMultiSelect from '@/components/form-inputs/InputMultiSelect'

    export default {
        components: {
            RatingDetails,
            IconPro,
            IconGuest,
            BaseTable,
            BaseRating,
            IconExport,
            BaseAvatar,
            LayoutAdmin,
            IconStarFull,
            BaseContextMenu,
            InputMultiSelect,
        },

        data() {
            return {
                filter: [],
                averageRating: 0,
                ratings: {},
                startDate: dayjs(),
                endDate: dayjs(),
                contextMenuLeft: '',
                contextMenuTop: '',
                contextMenuBottom: '',
                currentRating: null,
            }
        },

        computed: {
            selectAllOption() {
                return {
                    label: this.$t('admin.dashboard.all'),
                    value: 'all',
                }
            },

            tableHeaders() {
                return [
                    { label: this.$t('admin.dashboard.table.pro'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.location'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.experiences'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.rating'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.review'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.guest'), class: 'text-left' },
                ]
            },

            options() {
                return [
                    this.selectAllOption,
                    { label: this.$t('admin.dashboard.fiveStars'), value: 5 },
                    { label: this.$t('admin.dashboard.fourStars'), value: 4 },
                    { label: this.$t('admin.dashboard.threeStars'), value: 3 },
                    { label: this.$t('admin.dashboard.twoStars'), value: 2 },
                    { label: this.$t('admin.dashboard.oneStars'), value: 1 },
                ]
            },

            overflowClass() {
                return 'whitespace-nowrap overflow-hidden overflow-ellipsis'
            },
        },

        created() {
            this.filter = [...this.options]
        },

        methods: {
            async onFilterChange(options) {
                await this.loadNewReservations({ filter: options })
            },

            async loadNewReservations({ startDate, endDate, page, filter }) {
                if (startDate) {
                    this.startDate = startDate
                }
                if (endDate) {
                    this.endDate = endDate
                }
                if (filter) {
                    this.filter = filter
                }

                this.$refs.table.currentPage = page ? page : 1
                const result = await ApiAdmins.getOverallSatisfaction({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    page: this.$refs.table.currentPage,
                    stars: this.filter?.filter((item) => item.value != this.selectAllOption.value),
                })

                this.$refs.table.maxPage = result.maxPage

                this.averageRating = result.averageRating
                this.ratings = result.ratings

                this.$refs.table.calculatePages()
            },

            async exportOverallSatisfactions() {
                const response = await ApiAdmins.exportOverallSatisfaction({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    stars: this.filter?.filter((item) => item.value != this.selectAllOption.value),
                })
                var fileLink = document.createElement('a')

                fileLink.href =
                    'data:text/csv;charset=utf-8, ' + escape(Object.values(response.data).join(''))

                const format = 'DD.MM.YY'
                const start = this.startDate.format(format)
                const end = this.endDate.format(format)
                // TODO: add filtering in name?
                const fileName = `Overall_Satisfaction_${start}-${end}.csv`
                fileLink.setAttribute('download', fileName)
                document.body.appendChild(fileLink)

                fileLink.click()
            },

            showReviewDetails(event, index) {
                event.stopPropagation()

                this.currentRating = this.ratings[index]

                this.getContextMenuLeft(index)
                this.getContextMenuTop(index)

                if (this.$refs.reviewMenu.show) {
                    this.$refs.reviewMenu.close()
                } else {
                    this.$refs.reviewMenu.open()
                }
            },

            getContextMenuTop(index) {
                const el = this.$refs[`review_${index}`]
                const top = window.pageYOffset + el.getBoundingClientRect().top
                if (top + 400 > window.innerHeight + window.pageYOffset) {
                    this.contextMenuBottom =
                        window.innerHeight -
                        el.getBoundingClientRect().bottom -
                        window.pageYOffset +
                        'px'
                    this.contextMenuTop = ''
                    return
                }

                this.contextMenuTop = top + 'px'
                this.contextMenuBottom = ''
            },

            getContextMenuLeft(index) {
                const el = this.$refs[`review_${index}`]
                let left = el.getBoundingClientRect().left
                if (left + 250 + 30 > window.innerWidth) {
                    left = window.innerWidth - 250 - 30
                }

                this.contextMenuLeft = left + 'px'
            },

            closeReviewDetails(event) {
                if (!this.$refs.reviewMenu.$el.contains(event.target)) {
                    this.$refs.reviewMenu.close()
                }
            },
        },
    }
</script>
