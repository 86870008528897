<template>
    <LayoutApp back-button="/">
        <header class="pb-30 space-y-15">
            <BaseHeaderSection :subheading="formSubheading">
                <template #heading>
                    <i18n-t keypath="auth.register.pro.signup.heading.text">
                        <template #free>
                            <em
                                class="not-italic text-white bg-gold-dark uppercase text-18 py-5 px-10 relative -top-3 tracking-widest align-middle"
                            >
                                {{ $t('auth.register.pro.signup.heading.free') }}
                            </em>
                        </template>
                    </i18n-t>
                </template>
            </BaseHeaderSection>

            <BaseText v-if="!isInvitation" class="text-center">
                <i18n-t keypath="auth.register.pro.member.text">
                    <template #link>
                        <BaseLink
                            :label="$t('auth.register.pro.member.link')"
                            route="/auth/login"
                        />
                    </template>
                </i18n-t>
            </BaseText>
        </header>

        <FormWrapper @submit="submitForm">
            <FormFields>
                <FieldText
                    :label="$t('common.fields.first-name')"
                    :has-autofocus="!isInvitation"
                    :is-required="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="firstName"
                    @change="change"
                />

                <FieldText
                    :label="$t('common.fields.last-name')"
                    :is-required="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="lastName"
                    @change="change"
                />

                <FieldText
                    :label="$t('common.fields.email')"
                    :is-required="true"
                    :is-disabled="isInvitation"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="email"
                    type="email"
                    @change="change"
                />

                <FieldText
                    :label="$t('common.fields.password')"
                    :has-autofocus="isInvitation"
                    :is-required="true"
                    :form-data="formData"
                    :form-errors="formErrors"
                    value-path="password"
                    type="password"
                    @change="change"
                />

                <BaseText :level="2">
                    <i18n-t keypath="auth.register.acknowledgements.text">
                        <template #privacy>
                            <BaseLink
                                :label="$t('auth.register.acknowledgements.privacy')"
                                href="/privacy"
                            />
                        </template>
                        <template #terms>
                            <BaseLink
                                :label="$t('auth.register.acknowledgements.terms')"
                                href="/terms"
                            />
                        </template>
                    </i18n-t>
                </BaseText>
            </FormFields>

            <FormButtons>
                <BaseButton
                    :label="$t('common.sign-up')"
                    :is-processing="isSubmitting"
                    :is-disabled="!isSubmittable"
                />
            </FormButtons>
        </FormWrapper>
    </LayoutApp>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import BaseText from '@/components/base/BaseText'
    import BaseLink from '@/components/base/BaseLink'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldText from '@/components/form-fields/FieldText'

    export default {
        components: {
            LayoutApp,
            BaseHeaderSection,
            BaseText,
            BaseLink,
            BaseButton,
            FormWrapper,
            FormFields,
            FormButtons,
            FieldText,
        },

        mixins: [MixinForm, MixinFormSubmittable],

        async loadData({ $route }) {
            if (!$route.query.token) {
                return
            }

            const invitation = await ApiPros.getInvitation($route.query.token)

            return { invitation }
        },

        computed: {
            isSubmittable() {
                return (
                    this.formData.firstName &&
                    this.formData.lastName &&
                    this.formData.email &&
                    this.formData.password
                )
            },

            isInvitation() {
                return !!this.invitation
            },

            formSubheading() {
                return this.isInvitation
                    ? this.$t('auth.register.pro.invitation.subheading')
                    : this.$t('auth.register.pro.signup.subheading')
            },
        },

        created() {
            if (this.$route.query.token && !this.isInvitation) {
                this.$ui.flash.error(this.$t('auth.register.pro.invitation.error'))
            }

            if (this.isInvitation) {
                this.initForm({ ...this.invitation, invitationToken: this.$route.query.token })
            }
        },

        methods: {
            submitForm() {
                this.handleSubmit(ApiPros.register(this.formData)).then(({ token }) => {
                    this.$auth.logIn(token)
                    this.$router.push('/auth/detect')
                })
            },
        },
    }
</script>
