<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="close">
        <div class="p-20 pt-30 space-y-30 bg-gray-7">
            <BaseHeaderSection :heading="heading" :subheading="subheading" />

            <div class="space-y-20">
                <ProAppointmentsAppointment
                    v-for="appointment in appointments"
                    :key="appointment.id"
                    :appointment="appointment"
                />
            </div>
        </div>
    </BaseDrawer>
</template>

<script>
    import ApiProAppointments from '@/apis/ApiProAppointments'
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import ProAppointmentsAppointment from '@/components/pro/appointments/ProAppointmentsAppointment'

    export default {
        components: {
            BaseDrawer,
            BaseHeaderSection,
            ProAppointmentsAppointment,
        },

        props: {
            isOpen: { type: Boolean, required: true },
            appointments: { type: Array, required: true },
        },

        emits: ['close'],

        computed: {
            heading() {
                return this.$t('pro.appointments.unread.heading', {
                    name: this.$auth.getUser().firstName,
                })
            },

            subheading() {
                const number = this.appointments?.length || 0
                return this.$tc('pro.appointments.unread.subheading', number, { number: number })
            },
        },

        watch: {
            isOpen(isOpen) {
                isOpen &&
                    ApiProAppointments.markAsRead(
                        this.$auth.getUser(),
                        this.appointments.map(({ id }) => id),
                    )
            },
        },

        methods: {
            close(event) {
                this.$emit('close', event)
            },
        },
    }
</script>
