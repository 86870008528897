import ProAppointmentsUnread from '@/components/pro/appointments/ProAppointmentsUnread'

export default {
    components: {
        ProAppointmentsUnread,
    },

    data() {
        return {
            isUnreadPanelOpen: false,
        }
    },

    computed: {
        unreadAppointments() {
            return this.$store.getters['appointments/getUnread']
        },
    },

    mounted() {
        this.isUnreadPanelOpen = this.unreadAppointments?.length > 0
    },
}
