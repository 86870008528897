<template>
    <RouterLink v-if="route" :to="route" :class="buttonClass">
        <span>{{ label }}</span>
    </RouterLink>

    <a v-else :href="href" :class="buttonClass">
        <span>{{ label }}</span>
    </a>
</template>

<script>
    export default {
        props: {
            label: { type: String, required: true },
            level: { type: Number, default: 1 },
            route: { type: [Object, String], default: null },
            href: { type: String, default: null },
            isInverted: { type: Boolean, default: false },
        },

        computed: {
            buttonClass() {
                return {
                    'appearance-none': true,
                    'flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'relative': true,
                    'tracking-widest': true,
                    'px-20': true,
                    'md:px-50': true,
                    'rounded-md': true,
                    'transition-all': true,
                    'font-semibold': true,
                    'md:font-medium': true,
                    'h-60': true,
                    'text-18': true,
                    'md:text-20': true,
                    'lg:h-70': true,
                    'lg:text-24': true,
                    ...(this.level === 1 && {
                        'bg-purple': !this.isInverted,
                        'border-purple': !this.isInverted,
                        'text-white': !this.isInverted,
                        'bg-white': this.isInverted,
                        'border-white': this.isInverted,
                        'text-purple': this.isInverted,
                    }),
                    ...(this.level === 2 && {
                        'border-2': true,
                        'hoverable:hover:border-4': true,
                        'border-current': true,
                        'text-current': true,
                    }),
                }
            },
        },
    }
</script>
