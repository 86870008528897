<template>
    <LayoutApp back-button="/pro/profile">
        <ProSection :description="statusData.description">
            <template #heading>
                <div class="flex space-x-10">
                    <component
                        :is="statusData.icon?.component"
                        v-if="statusData.icon"
                        :class="statusData.icon?.color"
                        class="h-25 w-25"
                    />

                    <span>{{ statusData.heading }}</span>
                </div>
            </template>

            <template #default>
                <ProPayoutMethod v-if="isEnabled" />

                <ProStripeButton
                    :label="$t('pro.profile.stripe.goto')"
                    :href="stripeLink"
                    :is-dark="statusData.isButtonDark"
                />
            </template>
        </ProSection>
    </LayoutApp>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinProProfileSection from '@/mixins/MixinProProfileSection'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import ProPayoutMethod from '@/components/pro/ProPayoutMethod'
    import ProStripeButton from '@/components/pro/ProStripeButton'
    import IconSuccess from '@/assets/vectors/icon-success.svg'
    import IconTime from '@/assets/vectors/icon-time.svg'
    import IconError from '@/assets/vectors/icon-error.svg'

    export default {
        components: {
            LayoutApp,
            ProPayoutMethod,
            ProStripeButton,
        },

        mixins: [MixinProProfileSection],

        data() {
            return {
                method: null,
                stripeLink: null,
            }
        },

        computed: {
            statusData() {
                switch (this.$auth.getUser().stripeAccountStatus) {
                    case 'AccountCreated':
                        return {
                            heading: this.$t('pro.profile.stripe.account-created.heading'),
                            description: this.$t('pro.profile.stripe.account-created.description'),
                            isButtonDark: false,
                        }
                    case 'DoneOnBoarding':
                        return {
                            heading: this.$t('pro.profile.stripe.done-onboarding.heading'),
                            description: this.$t('pro.profile.stripe.done-onboarding.description'),
                            isButtonDark: false,
                        }
                    case 'PendingVerification':
                        return {
                            heading: this.$t('pro.profile.stripe.pending-verification.heading'),
                            description: this.$t(
                                'pro.profile.stripe.pending-verification.description',
                            ),
                            icon: { component: IconTime },
                            isButtonDark: false,
                        }
                    case 'MissingInformation':
                        return {
                            heading: this.$t('pro.profile.stripe.missing-information.heading'),
                            description: this.$t(
                                'pro.profile.stripe.missing-information.description',
                            ),
                            icon: { component: IconError, color: 'text-orange' },
                            isButtonDark: true,
                        }
                    case 'Enabled':
                        return {
                            heading: this.$t('pro.profile.stripe.enabled.heading'),
                            description: this.$t('pro.profile.stripe.enabled.description'),
                            icon: { component: IconSuccess, color: 'text-green' },
                            isButtonDark: false,
                        }
                    default:
                        return {
                            heading: this.$t('pro.profile.stripe.initial.heading'),
                            description: this.$t('pro.profile.stripe.initial.description'),
                            isButtonDark: true,
                        }
                }
            },

            isEnabled() {
                return this.$auth.getUser().stripeAccountStatus === 'Enabled'
            },

            isOnboardingDone() {
                return (
                    this.$auth.getUser().stripeAccountStatus &&
                    this.$auth.getUser().stripeAccountStatus !== 'AccountCreated'
                )
            },

            heading() {
                return this.isEnabled
                    ? this.$t('pro.profile.stripe.edit.heading')
                    : this.$t('pro.profile.stripe.setup.heading')
            },
        },

        mounted() {
            this.loadStripeLink()
        },

        methods: {
            loadStripeLink() {
                if (this.isOnboardingDone) {
                    return ApiPros.getStripeLoginLink(this.$auth.getUser().id).then(
                        ({ url }) => (this.stripeLink = url),
                    )
                }

                ApiPros.getStripeOnboardingLink(this.$auth.getUser().id).then(
                    ({ url }) => (this.stripeLink = url),
                )
            },
        },
    }
</script>
