<template>
    <LayoutApp :back-button="backButton" @back="handleBack">
        <div class="pb-20 sticky z-layout top-70 bg-white">
            <Progress v-if="currentStep.hasProgress" :percentage="stepPercentage" />
        </div>

        <component
            :is="currentStep.component"
            :step-data="formData"
            @change="changeData"
            @go-to-start="goToStart"
            @go-to="goToStep"
            @go-to-next="goToNextStep"
        />
    </LayoutApp>
</template>

<script>
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import Progress from '@/components/pro/Progress'
    import ProOnboardingStep1 from '@/components/pro/onboarding/ProOnboardingStep1'
    import ProOnboardingStep2 from '@/components/pro/onboarding/ProOnboardingStep2'
    import ProOnboardingStep3 from '@/components/pro/onboarding/ProOnboardingStep3'
    import ProOnboardingStep4 from '@/components/pro/onboarding/ProOnboardingStep4'
    import ProOnboardingStep5 from '@/components/pro/onboarding/ProOnboardingStep5'
    import ProOnboardingStep6 from '@/components/pro/onboarding/ProOnboardingStep6'
    import ProOnboardingStep7 from '@/components/pro/onboarding/ProOnboardingStep7'

    export default {
        components: {
            LayoutApp,
            Progress,
        },

        mixins: [MixinFormSteppable],

        computed: {
            steps() {
                return [
                    { component: ProOnboardingStep1, hasProgress: false },
                    { component: ProOnboardingStep2, hasProgress: true },
                    ...(this.formData.workPlace?.includes('pro')
                        ? [{ component: ProOnboardingStep3, hasProgress: true }]
                        : []),
                    ...(this.formData.workPlace?.includes('guest')
                        ? [{ component: ProOnboardingStep4, hasProgress: true }]
                        : []),
                    { component: ProOnboardingStep5, hasProgress: true },
                    { component: ProOnboardingStep6, hasProgress: true },
                    { component: ProOnboardingStep7, hasProgress: false },
                ]
            },

            stepsWithProgress() {
                return this.steps.filter(({ hasProgress }) => hasProgress).length
            },

            stepsWithProgressBeforeCurrent() {
                return this.steps.slice(0, this.step - 1).filter(({ hasProgress }) => hasProgress)
                    .length
            },

            stepPercentage() {
                const stepSize = 100 / this.stepsWithProgress

                return this.stepsWithProgressBeforeCurrent * stepSize
            },

            isEmailVerified() {
                return this.$auth.getUser().isEmailVerified
            },

            backButton() {
                return this.isFirstStep ||
                    this.isLastStep ||
                    (this.isEmailVerified && this.step === 2)
                    ? '/'
                    : true
            },
        },

        created() {
            this.detectInitialStep()
        },

        methods: {
            handleBack() {
                this.goToPreviousStep()
            },

            detectInitialStep() {
                this.isEmailVerified && (this.step = 2)
            },
        },
    }
</script>
