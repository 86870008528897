<template>
    <BaseMap ref="map" :has-zoom-control="true" class="flex-grow border-t-1 border-gray-5" />
</template>

<script>
    import Leaflet from 'leaflet'
    import ApiGoogle from '@/apis/ApiGoogle'
    import BaseMap from '@/components/base/BaseMap'
    import { timeToDateTime } from '@/helpers'

    export default {
        components: {
            BaseMap,
        },

        props: {
            appointments: { type: Array, required: true },
        },

        computed: {
            hasProPlace() {
                return this.$auth.getUser().workPlace?.includes('pro')
            },
        },

        mounted() {
            if (this.appointments.length) {
                return this.showAppointmentPinsAndFitToBounds()
            }

            this.hasProPlace ? this.showSalonAndFitToBounds() : this.fitToAreaZipCodeBounds()
        },

        methods: {
            appointmentAddress(appointment) {
                return appointment.workPlace === 'guest'
                    ? appointment.address
                    : this.$auth.getUser().address
            },

            createPinWithAppointments(appointments) {
                const bgColor = appointments[0]?.workPlace === 'guest' ? 'black' : 'purple'

                let html = [
                    `<div class="absolute bottom-15 whitespace-nowrap transform -translate-x-6/12 bg-${bgColor} text-white text-10 font-medium px-7 py-4">`,
                    `<ul>`,
                ]

                appointments.forEach((appointment) => {
                    const startTime = timeToDateTime(appointment.startTime).format('H:mm a')
                    const endTime = timeToDateTime(appointment.endTime).format('H:mm a')

                    html.push(`<li>${startTime} – ${endTime}</li>`)
                })

                html = html.concat([
                    `</ul>`,
                    `</div>`,
                    `<div class="absolute bottom-0 left-0 h-15 w-1 bg-${bgColor}" />`,
                    `<div class="absolute bottom-0 -left-2 h-5 w-5 bg-${bgColor} rounded-full" />`,
                ])

                return Leaflet.divIcon({
                    html: html.join(''),
                    iconSize: [1, 1],
                    iconAnchor: [1, 1],
                })
            },

            createSalonPin(address) {
                return Leaflet.marker([address.latitude, address.longitude], {
                    icon: Leaflet.divIcon({
                        html: `
                            <div class="h-9 w-9 rounded-full bg-magenta" />
                            <div class="animate-ping h-9 w-9 rounded-full bg-magenta" />
                        `,
                        iconSize: [9, 9],
                        iconAnchor: [5, 5],
                    }),
                })
            },

            showAppointmentPinsAndFitToBounds() {
                const groups = this.appointments.reduce((groups, appointment) => {
                    if (appointment.status === 'Cancelled') {
                        return groups
                    }

                    const address = this.appointmentAddress(appointment)

                    // If not address specified, ignore.
                    if (!address?.latitude || !address?.longitude) {
                        return groups
                    }

                    // Check if there's already a group with given address.
                    const index = groups.findIndex(
                        (group) =>
                            group.address?.latitude == address.latitude &&
                            group.address?.longitude == address.longitude,
                    )

                    if (index !== -1) {
                        groups[index]?.appointments.push(appointment)
                    } else {
                        groups.push({ address, appointments: [appointment] })
                    }

                    return groups
                }, [])

                if (groups.length === 0) {
                    return
                }

                const map = this.$refs.map.leaflet
                const markers = groups.map(({ address, appointments }) =>
                    Leaflet.marker([address.latitude, address.longitude], {
                        icon: this.createPinWithAppointments(appointments),
                    }).addTo(map),
                )

                if (this.$auth.getUser().workPlace?.includes('pro')) {
                    markers.push(this.createSalonPin(this.$auth.getUser().address).addTo(map))
                }

                const group = Leaflet.featureGroup(markers).addTo(map)

                map.fitBounds(group.getBounds(), { padding: [60, 60] })
            },

            showSalonAndFitToBounds() {
                const map = this.$refs.map.leaflet
                const markers = [this.createSalonPin(this.$auth.getUser().address).addTo(map)]
                const group = Leaflet.featureGroup(markers).addTo(map)

                map.fitBounds(group.getBounds(), { padding: [60, 60] })
            },

            fitToAreaZipCodeBounds() {
                ApiGoogle.getZipCodeDetails(this.$auth.getUser().areaZipCode)
                    .then((details) => [
                        details.geometry.location.lat(),
                        details.geometry.location.lng(),
                    ])
                    .then((coordinates) =>
                        setTimeout(() => {
                            const radiusInMiles = this.$auth.getUser().areaRadius * 1.60934 * 1000
                            const map = this.$refs.map.leaflet
                            const circle = Leaflet.circle(coordinates, radiusInMiles).addTo(map)
                            map.fitBounds(circle.getBounds())
                            map.removeLayer(circle)
                        }),
                    )
            },
        },
    }
</script>
