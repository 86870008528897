<template>
    <section :class="sectionClass">
        <BaseHeaderDivider v-if="heading" :heading="heading" class="w-full" />

        <slot name="top" />

        <TransitionSlide from="top">
            <div
                v-if="isEditing && isCancelable"
                class="fixed z-layout top-50 mt-0 inset-x-0 px-20 pb-15 bg-white flex justify-end shadow-md"
            >
                <BaseLink :label="$t('common.cancel')" @click="$emit('cancel')" />
            </div>
        </TransitionSlide>

        <TransitionSlide from="bottom">
            <FormButtons v-if="isEditing && saveLabel" :is-fixed="true" class="-shadow-md w-full">
                <BaseButton
                    :label="saveLabel"
                    :is-processing="isSubmitting"
                    :is-disabled="!isSubmittable"
                    @click="$emit('save')"
                />
            </FormButtons>
        </TransitionSlide>

        <template v-if="isEditing">
            <FormWrapper v-if="$slots.edit" class="w-full" @submit="$emit('save')">
                <slot name="edit" />
            </FormWrapper>
        </template>

        <template v-else-if="$slots.preview">
            <BaseLink v-if="editLabel" :label="editLabel" @click="$emit('edit')" />
            <slot name="preview" />
        </template>

        <template v-else>
            <BaseLink v-if="addLabel" :label="addLabel" @click="$emit('edit')" />
            <BaseText v-if="description" :content="description" class="text-center" />
            <slot name="blank" />
        </template>

        <slot name="bottom" />
    </section>
</template>

<script>
    import TransitionSlide from '@/components/transitions/TransitionSlide'
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseLink from '@/components/base/BaseLink'
    import BaseText from '@/components/base/BaseText'
    import BaseButton from '@/components/base/BaseButton'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormButtons from '@/components/form-elements/FormButtons'

    export default {
        components: {
            TransitionSlide,
            BaseHeaderDivider,
            BaseLink,
            BaseText,
            BaseButton,
            FormWrapper,
            FormButtons,
        },

        props: {
            heading: { type: String, default: null },
            description: { type: String, default: null },
            addLabel: { type: String, default: null },
            editLabel: { type: String, default: null },
            saveLabel: { type: String, default: null },
            isEditing: { type: Boolean, default: false },
            isSubmitting: { type: Boolean, default: false },
            isDisabled: { type: Boolean, default: false },
            isSubmittable: { type: Boolean, default: true },
            isCancelable: { type: Boolean, default: true },
        },

        emits: ['edit', 'save', 'cancel'],

        computed: {
            sectionClass() {
                return {
                    'space-y-20': true,
                    'flex': true,
                    'flex-col': true,
                    'items-center': true,
                    'relative': true,
                    'transition': true,
                    'duration-500': true,
                    'opacity-30': this.isDisabled,
                    'pointer-events-none': this.isDisabled,
                }
            },
        },
    }
</script>
