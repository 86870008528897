<template>
    <LayoutAdmin @select-period="loadSales">
        <div class="h-full p-30 flex flex-col justfy-between">
            <div class="flex flex-col">
                <div class="text-center text-16 font-black tracking-widest uppercase">
                    {{ $t('admin.dashboard.sales.label') }}
                </div>
                <div class="flex flex-row justify-between items-center mt-30">
                    <InputMultiSelect
                        :options="options"
                        :placeholder="$t('admin.dashboard.selectCategory')"
                        :value="filter"
                        :select-all-option="selectAllOption"
                        class="w-250"
                        @change="onFilterChange"
                    />

                    <div class="flex flex-col justify-center items-center">
                        <div class="flex flex-row tracking-wider">
                            <IconMenuPayments class="color-black w-20" />
                            <span class="ml-10 uppercase">{{ $t('admin.dashboard.total') }}</span>
                        </div>
                        <div class="flex flex-row space-x-10 items-center">
                            <span class="text-35 font-bold">$ {{ totalAmount }}</span>
                            <span class="text-24 text-gray-2">
                                | $ {{ totalFees }} {{ $t('admin.dashboard.sales.fees') }}
                            </span>
                        </div>
                    </div>

                    <div class="flex justify-end w-250 cursor-pointer">
                        <IconExport class="w-40" @click="exportSales" />
                    </div>
                </div>
            </div>

            <BaseTable ref="table" :headers="tableHeaders" class="mt-30" @goToPage="loadSales">
                <tr
                    v-for="sale in sales"
                    :key="sale.id"
                    class="border-b-1 border-gray-4 cursor-pointer hover:bg-sand-light"
                    @click="openOrderReceiptModal(sale.id)"
                >
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <BaseAvatar
                                :photo="sale.guest.profilePicture"
                                class="h-50 min-w-50 w-50 rounded-full"
                            />
                            <div class="ml-10 font-bold uppercase">
                                {{ sale.guest.name }}
                            </div>
                        </div>
                    </td>
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <IconGuest v-if="sale.workPlace === 'GuestsPlace'" class="h-22" />
                            <IconPro v-else class="h-22" />
                            <span class="ml-10">{{ sale.location }}</span>
                        </div>
                    </td>
                    <td class="p-5">{{ sale.chargedOn }}</td>
                    <td class="p-5">
                        <div class="flex flex-row items-center">
                            <BaseAvatar
                                :photo="sale.pro.profilePicture"
                                class="h-50 min-w-50 w-50 rounded-full"
                            />
                            <div class="flex flex-col ml-10">
                                <div class="font-bold uppercase">{{ sale.pro.name }}</div>
                                <div class="text-gray-3">{{ sale.pro.profileUrl }}</div>
                            </div>
                        </div>
                    </td>
                    <td class="p-5">${{ sale.services }}</td>
                    <td class="p-5">${{ sale.other }}</td>
                    <td class="p-5">${{ sale.gratuity }}</td>
                    <td class="p-5">${{ sale.serviceFee }}</td>
                    <td class="p-5 text-center">${{ sale.total }}</td>
                </tr>
            </BaseTable>
        </div>
    </LayoutAdmin>

    <OrderReceipt
        v-if="appointmentId"
        :appointment-id="appointmentId"
        :is-open="isOrderReceiptOpen"
        @close="closeOrderReceiptModal"
    />
</template>

<script>
    import dayjs from 'dayjs'
    import ApiAdmins from '@/apis/ApiAdmins'
    import BaseTable from '@/components/base/BaseTable'
    import IconPro from '@/assets/vectors/icon-pro.svg'
    import BaseAvatar from '@/components/base/BaseAvatar'
    import IconGuest from '@/assets/vectors/icon-guest.svg'
    import IconExport from '@/assets/vectors/icon-export.svg'
    import LayoutAdmin from '@/components/layouts/LayoutAdmin'
    import OrderReceipt from '@/components/admin/dashboard/OrderReceipt'
    import IconMenuPayments from '@/assets/vectors/icon-menu-payments.svg'
    import InputMultiSelect from '@/components/form-inputs/InputMultiSelect'

    export default {
        components: {
            IconPro,
            IconGuest,
            BaseTable,
            IconExport,
            BaseAvatar,
            LayoutAdmin,
            OrderReceipt,
            IconMenuPayments,
            InputMultiSelect,
        },

        data() {
            return {
                filter: [],
                totalAmount: 0,
                totalFees: 0,
                sales: [],
                startDate: dayjs(),
                endDate: dayjs(),
                isOrderReceiptOpen: false,
                appointmentId: null,
            }
        },

        computed: {
            selectAllOption() {
                return {
                    label: this.$t('admin.dashboard.all'),
                    value: 'all',
                }
            },

            tableHeaders() {
                return [
                    { label: this.$t('admin.dashboard.table.guest'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.location'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.chargedOn'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.pro'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.services'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.other'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.gratuity'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.fbFee'), class: 'text-left' },
                    { label: this.$t('admin.dashboard.table.total') },
                ]
            },

            categories() {
                return this.$store.getters['dictionaries/getCategories'].map((category) => ({
                    value: category.id,
                    label: category.name,
                }))
            },

            options() {
                return [this.selectAllOption, ...this.categories]
            },
        },

        created() {
            this.filter = [this.selectAllOption, ...this.categories]
        },

        methods: {
            async onFilterChange(options) {
                await this.loadSales({ filter: options })
            },

            async loadSales({ startDate, endDate, page, filter }) {
                if (startDate) {
                    this.startDate = startDate
                }
                if (endDate) {
                    this.endDate = endDate
                }
                if (filter) {
                    this.filter = filter
                }

                this.$refs.table.currentPage = page ? page : 1
                const result = await ApiAdmins.getSales({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    page: this.$refs.table.currentPage,
                    categoryIds: this.filter?.filter(
                        (item) => item.value != this.selectAllOption.value,
                    ),
                })

                this.$refs.table.maxPage = result.maxPage

                this.sales = result.sales
                this.totalFees = result.totalFees
                this.totalAmount = result.totalAmount

                this.$refs.table.calculatePages()
            },

            async exportSales() {
                const response = await ApiAdmins.exportSales({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    categoryIds: this.filter?.filter(
                        (item) => item.value != this.selectAllOption.value,
                    ),
                })
                var fileLink = document.createElement('a')

                fileLink.href =
                    'data:text/csv;charset=utf-8, ' + escape(Object.values(response.data).join(''))

                const format = 'DD.MM.YY'
                const start = this.startDate.format(format)
                const end = this.endDate.format(format)
                const fileName = `Sales_Report_${start}-${end}.csv`
                fileLink.setAttribute('download', fileName)
                document.body.appendChild(fileLink)

                fileLink.click()
            },

            openOrderReceiptModal(appointmentId) {
                this.appointmentId = appointmentId
                this.isOrderReceiptOpen = true
            },

            closeOrderReceiptModal() {
                this.isOrderReceiptOpen = false
            },
        },
    }
</script>
