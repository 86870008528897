<template>
    <figure :class="workClass">
        <div class="relative">
            <div class="pb-9/12 relative">
                <img :src="picture" class="absolute inset-0 h-full w-full object-cover" />
            </div>

            <button
                v-if="isPictureDeletable"
                type="button"
                class="bg-white absolute top-0 right-0"
                @click="$emit('delete-picture')"
            >
                <IconDelete class="m-10 h-20 w-20" />
            </button>

            <button
                v-if="isPictureEditable"
                type="button"
                class="bg-white absolute bottom-0 right-0"
                @click="$emit('edit-picture')"
            >
                <IconModify class="m-10 h-20 w-20" />
            </button>
        </div>

        <figcaption v-if="title" class="flex items-start">
            <h3 :class="titleClass">{{ title }}</h3>
            <button
                v-if="isTitleEditable"
                type="button"
                class="ml-auto"
                @click="$emit('edit-title')"
            >
                <IconModify class="mx-10 h-20 w-20" />
            </button>
        </figcaption>
    </figure>
</template>

<script>
    import IconDelete from '@/assets/vectors/icon-delete.svg'
    import IconModify from '@/assets/vectors/icon-modify.svg'

    export default {
        components: {
            IconDelete,
            IconModify,
        },

        props: {
            picture: { type: [Object, String], required: true },
            title: { type: String, default: null },
            isPictureEditable: { type: Boolean, default: false },
            isPictureDeletable: { type: Boolean, default: false },
            isTitleEditable: { type: Boolean, default: false },
            level: { type: Number, default: 1 },
        },

        emits: ['edit-picture', 'delete-picture', 'edit-title'],

        computed: {
            workClass() {
                return {
                    'space-y-20': this.level === 1,
                    'space-y-15': this.level === 2,
                }
            },

            titleClass() {
                return {
                    'font-serif': true,
                    'text-40': this.level === 1,
                    'text-28': this.level === 2,
                }
            },
        },
    }
</script>
