<template>
    <LayoutOverlay back-button="/pro/profile/experiences">
        <ProSection>
            <FormWrapper @submit="save">
                <FormFields>
                    <FormLegend
                        :label="$t('pro.profile.experiences.category.choose-subcategories')"
                    />

                    <BaseChoice
                        :icon="categoryIcon"
                        :label="category.name"
                        :level="1"
                        :is-selected="true"
                    />

                    <div>
                        <FieldPills
                            :options="subCategoryOptions"
                            :form-data="formData"
                            value-path="subCategoryIds"
                            @change="change"
                        />
                    </div>
                </FormFields>

                <FormButtons>
                    <BaseButton
                        :label="$t('common.save')"
                        :is-processing="isSubmitting"
                        :is-disabled="!isSubmittable"
                    />
                </FormButtons>
            </FormWrapper>
        </ProSection>
    </LayoutOverlay>
</template>

<script>
    import intersection from 'lodash/intersection'
    import { getCategoryIcon, getCategoryOptions } from '@/helpers'
    import ApiProExperiences from '@/apis/ApiProExperiences'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinProExperiences from '@/mixins/MixinProExperiences'
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import ProSection from '@/components/pro/ProSection'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import FormFields from '@/components/form-elements/FormFields'
    import FormLegend from '@/components/form-elements/FormLegend'
    import FormButtons from '@/components/form-elements/FormButtons'
    import FieldPills from '@/components/form-fields/FieldPills'
    import BaseButton from '@/components/base/BaseButton'
    import BaseChoice from '@/components/base/BaseChoice'

    export default {
        components: {
            LayoutOverlay,
            ProSection,
            FormWrapper,
            FormFields,
            FormLegend,
            FormButtons,
            FieldPills,
            BaseButton,
            BaseChoice,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinProExperiences],

        loadData({ $route, $auth }) {
            const user = $auth.getUser()
            const category = user.subCategories.find(
                ({ category }) => category.id == $route.params.id,
            )?.category

            if (!category) {
                // TODO: Throw error if category does not exist.
            }

            return { category }
        },

        computed: {
            isSubmittable() {
                const selectedIds = this.formData.subCategoryIds
                const allIds = this.subCategoryOptions.map(({ value: id }) => id)

                return intersection(selectedIds, allIds).length > 0
            },

            subCategoryOptions() {
                return getCategoryOptions(
                    this.$store.getters['dictionaries/getCategories'],
                    this.category,
                )
            },

            categoryIcon() {
                return getCategoryIcon(this.category)
            },
        },

        created() {
            this.change({
                path: 'subCategoryIds',
                value: this.proSubCategoryIds,
            })
        },

        methods: {
            save() {
                this.handleSubmit(
                    ApiProExperiences.updateSubCategories(this.$auth.getUser().id, this.formData),
                ).then(() => this.$router.push('/pro/profile/experiences'))
            },
        },
    }
</script>
