<template>
    <div :class="wrapperClass">
        <slot name="prefix" />
        <input
            ref="input"
            :type="type"
            :value="value"
            :placeholder="placeholder"
            :autofocus="hasAutofocus"
            :disabled="isDisabled"
            :required="isRequired"
            :readonly="isReadonly"
            :maxlength="maxLength"
            :class="inputClass"
            @focus="focus"
            @blur="blur"
            @input="change"
        />
        <slot name="suffix" />
    </div>
</template>

<script>
    import MixinFormInput from '@/mixins/MixinFormInput'

    export default {
        mixins: [MixinFormInput],

        props: {
            placeholder: { type: [String, Number], default: null },
            type: { type: String, default: 'text' },
            maxLength: { type: Number, default: null },
            isInverted: { type: Boolean, default: false },
        },

        computed: {
            wrapperClass() {
                return {
                    'h-full': true,
                    'w-full': true,
                    'flex': true,
                    'items-center': true,
                    'transition': true,
                    'text-18': true,
                    'opacity-30': this.isDisabled,
                    'cursor-not-allowed': this.isDisabled,
                    'border-b-1': true,
                    'border-transparent': this.isInvalid || this.isReadonly || this.isInverted,
                    'border-black': !this.isInvalid && !this.isReadonly && !this.isInverted,
                    'text-black': !this.isInvalid && !this.isInverted,
                    'text-red': this.isInvalid,
                    'focus-within:text-purple':
                        !this.isInvalid && !this.isReadonly && !this.isInverted,
                    'focus-within:border-purple':
                        !this.isInvalid && !this.isReadonly && !this.isInverted,
                }
            },

            inputClass() {
                return {
                    'block': true,
                    'w-full': true,
                    'h-50': true,
                    'leading-none': true,
                    'tracking-wide': true,
                    'appearance-none': true,
                    'rounded-none': true,
                    'bg-transparent': true,
                    'text-inherit': true,
                    'focus:font-bold': !this.isReadonly,
                }
            },
        },
    }
</script>
