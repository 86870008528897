<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="$emit('close')">
        <div class="p-20 space-y-20">
            <BaseHeading :heading="panelHeading" :level="3" class="text-center" />

            <UserFormAddress
                :form-data="address"
                :is-city-locked="hasSearchAddress"
                @change="changeAddress"
            />

            <BaseButton :label="confirmLabel" :is-disabled="!isSubmittable" @click="confirm" />
        </div>
    </BaseDrawer>
</template>

<script>
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseButton from '@/components/base/BaseButton'
    import UserFormAddress from '@/components/user/UserFormAddress'

    export default {
        components: {
            BaseDrawer,
            BaseHeading,
            BaseButton,
            UserFormAddress,
        },

        props: {
            formData: { type: Object, required: true },
            appointment: { type: Object, required: true },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['confirm', 'close'],

        data() {
            return {
                address: {},
            }
        },

        computed: {
            isSubmittable() {
                return (
                    this.address.address &&
                    this.address.city &&
                    this.address.state &&
                    this.address.zipCode
                )
            },

            doesUserHaveAnyAddressSaved() {
                return this.$auth.isLoggedIn() && this.$auth.getUser().address
            },

            hasSearchAddress() {
                return !!this.formData.search?.address
            },

            panelHeading() {
                return this.doesUserHaveAnyAddressSaved
                    ? this.$t('guest.book.who.address.address.confirm')
                    : this.$t('guest.book.who.address.address.enter')
            },

            confirmLabel() {
                return this.$t('guest.book.who.address.confirm')
            },
        },

        watch: {
            isOpen() {
                this.prepopulateAddress()
            },
        },

        methods: {
            changeAddress({ path, value }) {
                this.address[path] = value
            },

            prepopulateAddress() {
                if (
                    this.doesUserHaveAnyAddressSaved &&
                    this.$auth.getUser().address?.zipCode === this.formData.search.areaZipCode
                ) {
                    this.address = this.$auth.getUser().address
                } else if (this.hasSearchAddress) {
                    this.address = this.formData.search.address
                }
            },

            confirm() {
                this.$emit('confirm', { 'appointment.address': this.address })
            },
        },
    }
</script>
