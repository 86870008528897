export default {
    data() {
        return {
            step: 1,
            formData: {},
        }
    },

    computed: {
        currentStep() {
            return this.steps[this.step - 1]
        },

        isFirstStep() {
            return this.step === 1
        },

        isLastStep() {
            return this.step === this.steps.length
        },
    },

    watch: {
        step() {
            window.scrollTo({ top: 0, left: 0 })
        },
    },

    methods: {
        goToStart() {
            this.step = 1
            this.formData = {}
        },

        goToStep(step) {
            this.step = step
        },

        goToPreviousStep() {
            this.step -= 1
        },

        goToNextStep() {
            this.step += 1
        },

        changeData(formData) {
            this.formData = formData
        },
    },
}
