<script>
    import ProProfileAddOnsStep4 from '@/components/pro/profile/addons/ProProfileAddOnsStep4'

    export default {
        extends: ProProfileAddOnsStep4,

        computed: {
            heading() {
                return this.$t('pro.profile.addons.steps.6.heading', {
                    name: this.formData.name,
                })
            },

            valuePath() {
                return 'isOfferedInGuest'
            },

            placeSlug() {
                return 'guest'
            },
        },
    }
</script>
