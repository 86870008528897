<template>
    <FormField v-bind="rangeFieldProps">
        <InputRange v-bind="rangeInputProps" @focus="focus" @blur="blur" @change="change" />
    </FormField>
</template>

<script>
    import MixinFormField from '@/mixins/MixinFormField'
    import FormField from '@/components/form-elements/FormField'
    import InputRange from '@/components/form-inputs/InputRange'

    export default {
        components: {
            FormField,
            InputRange,
        },

        mixins: [MixinFormField],

        props: {
            step: { type: Number, default: 1 },
            rangeFrom: { type: Number, required: true },
            rangeTo: { type: Number, required: true },
            labelFrom: { type: String, default: null },
            labelTo: { type: String, default: null },
            hasTooltip: { type: Boolean, default: true },
        },

        computed: {
            rangeFieldProps() {
                return {
                    ...this.fieldProps,
                    isLabelFixed: true,
                }
            },

            rangeInputProps() {
                return {
                    ...this.inputProps,
                    step: this.step,
                    rangeFrom: this.rangeFrom,
                    rangeTo: this.rangeTo,
                    labelFrom: this.labelFrom,
                    labelTo: this.labelTo,
                    hasTooltip: this.hasTooltip,
                }
            },
        },
    }
</script>
