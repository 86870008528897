<template>
    <LayoutApp :back-button="backButton">
        <component
            :is="currentStep.component"
            :appointment="appointment"
            :step-data="formData"
            @change="changeData"
            @go-to-next="goToNextStep"
        />
    </LayoutApp>
</template>

<script>
    import * as HelperAppointment from '@/helpers/appointment'
    import ApiProAppointments from '@/apis/ApiProAppointments'
    import MixinForm from '@/mixins/MixinForm'
    import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import LayoutApp from '@/components/layouts/LayoutApp'
    import ProAppointmentsChargeStep1 from '@/components/pro/appointments/charge/ProAppointmentsChargeStep1'
    import ProAppointmentsChargeStep2 from '@/components/pro/appointments/charge/ProAppointmentsChargeStep2'

    export default {
        components: {
            LayoutApp,
            ProAppointmentsChargeStep1,
            ProAppointmentsChargeStep2,
        },

        mixins: [MixinForm, MixinFormSubmittable, MixinFormSteppable, MixinAppointment],

        async loadData({ $auth, $route, $router }) {
            const appointment = await ApiProAppointments.getOne($auth.getUser(), $route.params.id)

            if (!HelperAppointment.isChargable(appointment)) {
                $router.push(`/pro/appointments/${appointment.id}`)
            }

            return { appointment }
        },

        computed: {
            backButton() {
                return `/pro/appointments/${this.appointment.id}`
            },

            steps() {
                return [
                    { component: ProAppointmentsChargeStep1 },
                    { component: ProAppointmentsChargeStep2 },
                ]
            },
        },

        created() {
            if (this.isConlcuded) {
                return this.$router.push(this.backButton)
            }

            this.setupFormData()
        },

        methods: {
            setupFormData() {
                this.initForm({
                    experiences: this.appointment.experiences.map(({ id, price }) => ({
                        id,
                        price,
                    })),
                    addOns: this.appointment.addOns.map(({ id, price }) => ({ id, price })),
                    extras: null,
                    discountType: false,
                    discountAmount: null,
                })
            },
        },
    }
</script>
