<template>
    <section>
        <div class="flex items-center space-x-15 text-16 font-bold h-60 border-b-1 border-gray-5">
            <IconClose v-if="isMapOpen" class="flex-shrink-0 h-35 w-35" />
            <IconLocation v-else class="flex-shrink-0 h-35 w-35" />

            <BaseLink class="truncate overflow-ellipsis underline text-purple" @click="toggleMap">
                {{ appointmentAddress.address }},
                {{ appointmentAddress.zipCode }}
                {{ appointmentAddress.city }}
            </BaseLink>
        </div>

        <div :class="mapClass">
            <BaseMap ref="map" class="h-170" />
        </div>
    </section>
</template>

<script>
    import Leaflet from 'leaflet'
    import MixinAppointment from '@/mixins/MixinAppointment'
    import BaseLink from '@/components/base/BaseLink'
    import BaseMap from '@/components/base/BaseMap'
    import IconLocation from '@/assets/vectors/icon-location.svg'
    import IconClose from '@/assets/vectors/icon-close.svg'

    export default {
        components: {
            BaseLink,
            BaseMap,
            IconLocation,
            IconClose,
        },

        mixins: [MixinAppointment],

        props: {
            appointment: { type: Object, required: true },
        },

        data() {
            return {
                isMapOpen: false,
            }
        },

        computed: {
            mapClass() {
                return {
                    'relative': true,
                    'mt-10': true,
                    'transition-all': true,
                    'overflow-hidden': true,
                    'h-0': !this.isMapOpen,
                    '-mb-10': !this.isMapOpen,
                    'h-170': this.isMapOpen,
                    'mb-0': this.isMapOpen,
                }
            },
        },

        mounted() {
            this.showPinsAndFitToBounds()
        },

        methods: {
            toggleMap() {
                return (this.isMapOpen = !this.isMapOpen)
            },

            showPinsAndFitToBounds() {
                if (!this.appointmentAddress?.latitude || !this.appointmentAddress?.longitude) {
                    return
                }

                const map = this.$refs.map.leaflet
                const marker = Leaflet.marker(
                    [this.appointmentAddress?.latitude, this.appointmentAddress?.longitude],
                    {
                        icon: Leaflet.icon({
                            iconUrl: require('@/assets/images/icon-pin.png').default,
                            iconSize: [26, 36],
                            iconAnchor: [13, 36],
                        }),
                    },
                ).addTo(map)

                map.setView(marker.getLatLng())
            },
        },
    }
</script>
