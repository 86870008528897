<template>
    <BaseHeaderSection
        :heading="$t('guest.appointments.when.heading')"
        :subheading="$t('guest.appointments.when.subheading')"
        class="mb-40"
    />

    <BaseCalendarSwitch
        :year="year"
        :month="month"
        :disabled-dates="disabledDates"
        @change-month="changeMonth"
        @click-date="saveAndGoToNextStep"
    />
</template>

<script>
    import GuestBookStep6 from '@/components/guest/book/GuestBookStep6'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'

    export default {
        components: {
            BaseHeaderSection,
        },

        extends: GuestBookStep6,

        methods: {
            saveAndGoToNextStep(date) {
                this.change({ path: 'search.date', value: date })
                this.goToNextStep()
            },
        },
    }
</script>
