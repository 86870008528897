<template>
    <ProSection :heading="heading">
        <header v-if="isInitialSetUp" class="space-y-20 flex flex-col items-center text-center">
            <IconSuccess class="h-35 w-35 text-green animate-pop" />
            <BaseHeading :heading="$t('pro.profile.cancellation.index.all-set')" :level="3" />
        </header>

        <FormWrapper>
            <ProProfileCancellationDetails
                :cancellation-policy="formData.cancellationPolicy"
                :no-show-policy="formData.noShowPolicy"
                :is-editable="true"
                @edit="goToStep"
            />

            <slot name="buttons" :is-submittable="true" />
        </FormWrapper>
    </ProSection>
</template>

<script>
    import MixinFormStep from '@/mixins/MixinFormStep'
    import BaseHeading from '@/components/base/BaseHeading'
    import FormWrapper from '@/components/form-elements/FormWrapper'
    import ProSection from '@/components/pro/ProSection'
    import ProProfileCancellationDetails from '@/components/pro/profile/cancellation/ProProfileCancellationDetails'
    import IconSuccess from '@/assets/vectors/icon-success.svg'

    export default {
        components: {
            BaseHeading,
            FormWrapper,
            ProSection,
            ProProfileCancellationDetails,
            IconSuccess,
        },

        mixins: [MixinFormStep],

        props: {
            isInitialSetUp: { type: Boolean, required: true },
        },

        computed: {
            heading() {
                return this.isInitialSetUp
                    ? null
                    : this.$t('pro.profile.cancellation.index.heading')
            },
        },

        methods: {},
    }
</script>
