<template>
    <div class="-mx-15">
        <ul :class="optionsClass">
            <li v-for="option in options" :key="option.label">
                <button type="button" :class="optionClass(option)" @click="change(option.value)">
                    {{ option.label }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
    import InputRadio from '@/components/form-inputs/InputRadio'

    export default {
        extends: InputRadio,

        computed: {
            optionsClass() {
                return {
                    'flex': true,
                    'flex-wrap': true,
                    'justify-center': true,
                    'cursor-not-allowed': this.isDisabled,
                }
            },
        },

        methods: {
            optionClass(option) {
                const isChecked = this.isOptionChecked(option)

                return {
                    'transition-opacity': true,
                    'h-70': true,
                    'px-40': true,
                    'mx-15': true,
                    'flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'text-11': true,
                    'uppercase': true,
                    'border-1': true,
                    'border-purple': true,
                    'rounded-md': true,
                    'font-bold': true,
                    'tracking-widest': true,
                    'text-purple': !isChecked,
                    'bg-purple': isChecked,
                    'text-white': isChecked,
                    'pointer-events-none': this.isDisabled,
                    'opacity-30': this.isDisabled,
                }
            },
        },
    }
</script>
