<template>
    <div class="flex flex-col items-center">
        <BaseHeaderDivider
            :heading="$t('admin.dashboard.topCategories')"
            class="w-11/12 text-16 mx-auto"
        />
        <BaseHorizontalBarChart class="w-300 mt-20" :items="topCategories" />
    </div>
</template>

<script>
    import BaseHeaderDivider from '@/components/base/BaseHeaderDivider'
    import BaseHorizontalBarChart from '@/components/base/BaseHorizontalBarChart'

    export default {
        components: {
            BaseHeaderDivider,
            BaseHorizontalBarChart,
        },

        props: {
            topCategories: { type: Array, required: true },
        },
    }
</script>
