<template>
    <div>
        <button type="button" class="text-gray-3" @click="isActionsPanelOpen = true">
            <IconDots class="h-30 w-30" />
        </button>

        <BaseDrawer
            :is-open="isActionsPanelOpen"
            slide-from="bottom"
            @close="isActionsPanelOpen = false"
        >
            <BaseLinks
                :items="links"
                class="uppercase text-14 px-20 py-15"
                @click-item="clickItem"
                @close="isActionsPanelOpen = false"
            >
                <template #label="{ item }">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="item.label" />
                </template>
            </BaseLinks>
        </BaseDrawer>
    </div>
</template>

<script>
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseLinks from '@/components/base/BaseLinks'
    import IconDots from '@/assets/vectors/icon-dots.svg'

    export default {
        components: {
            BaseDrawer,
            BaseLinks,
            IconDots,
        },

        props: {
            categories: { type: Object, required: true },
        },

        emits: ['delete-category', 'close'],

        data() {
            return {
                isActionsPanelOpen: false,
            }
        },

        computed: {
            links() {
                const hasAllAvailableCategories =
                    this.categories.length ===
                    this.$store.getters['dictionaries/getCategories'].length

                return [
                    ...(hasAllAvailableCategories
                        ? []
                        : [
                              {
                                  label: this.$t('pro.profile.experiences.index.actions.add'),
                                  route: '/pro/profile/experiences/categories/add',
                              },
                          ]),
                    ...this.categories.map((category) => ({
                        label: this.$t('pro.profile.experiences.index.actions.delete', {
                            name: category.name,
                        }),
                        event: () => this.confirmDelete(category),
                    })),
                    {
                        label: this.$t('common.close'),
                        event: () => (this.isActionsPanelOpen = false),
                    },
                ]
            },
        },

        methods: {
            clickItem({ event }) {
                event && event()
            },

            confirmDelete(category) {
                this.$ui
                    .confirm({
                        message: this.$t('pro.profile.experiences.index.delete-category.message'),
                        note: this.$t('pro.profile.experiences.index.delete-category.note'),
                    })
                    .then((isConfirmed) => {
                        if (!isConfirmed) {
                            return
                        }

                        this.isActionsPanelOpen = false
                        this.$emit('delete-category', category)
                    })
            },
        },
    }
</script>
