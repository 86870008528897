<template>
    <LandingContainer :level="2" class="py-30 md:py-50">
        <LandingHeading :level="1" class="mb-20 md:mb-40">
            {{ $t('landing.contact.heading') }}
        </LandingHeading>

        <div v-once id="hubspot" />
    </LandingContainer>
</template>

<script>
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'

    export default {
        components: {
            LandingHeading,
            LandingContainer,
        },

        mounted() {
            this.initializeHubspot()
        },

        methods: {
            initializeHubspot() {
                const script = document.createElement('script')
                script.src = 'https://js.hsforms.net/forms/v2.js'
                document.body.appendChild(script)

                script.addEventListener('load', () => {
                    if (!window.hbspt) {
                        return
                    }

                    window.hbspt.forms.create({
                        region: 'na1',
                        portalId: '20034369',
                        formId: '63cd2147-b9c9-4459-8c09-d2f902faec25',
                        target: '#hubspot',
                    })
                })
            },
        },
    }
</script>
