<script>
    import ProProfileExperiencesExperiencesStep5 from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesStep5'

    export default {
        extends: ProProfileExperiencesExperiencesStep5,

        computed: {
            heading() {
                return this.$t('pro.profile.experiences.experience.steps.7.heading', {
                    name: this.formData.name,
                })
            },

            valuePath() {
                return 'isOfferedInGuest'
            },

            placeSlug() {
                return 'guest'
            },
        },
    }
</script>
