import MixinForm from '@/mixins/MixinForm'
import MixinFormSubmittable from '@/mixins/MixinFormSubmittable'
import GuestProfileSection from '@/components/guest/GuestProfileSection'

export default {
    components: {
        GuestProfileSection,
    },

    mixins: [MixinForm, MixinFormSubmittable],

    emits: ['edit', 'save', 'cancel'],

    props: {
        isEditing: { type: Boolean, default: false },
        isDisabled: { type: Boolean, default: false },
    },

    computed: {
        isSubmittable() {
            return false
        },

        stopProcessingAfterSubmitted() {
            return true
        },
    },

    watch: {
        isEditing() {
            this.initForm(this.$auth.getUser())
        },
    },

    created() {
        this.initForm(this.$auth.getUser())
    },

    methods: {
        edit() {
            this.$emit('edit')
        },

        preview() {
            this.isEditing = false
        },

        save() {
            return this.handleSubmit(this.apiCall()).then(() => this.$emit('save'))
        },

        cancel() {
            this.$emit('cancel')
        },
    },
}
