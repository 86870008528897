<template>
    <FormField v-bind="textFieldProps">
        <InputTextarea v-bind="textareaInputProps" @focus="focus" @blur="blur" @change="change" />
    </FormField>
</template>

<script>
    import MixinFormField from '@/mixins/MixinFormField'
    import FormField from '@/components/form-elements/FormField'
    import InputTextarea from '@/components/form-inputs/InputTextarea'

    export default {
        components: {
            FormField,
            InputTextarea,
        },

        mixins: [MixinFormField],

        props: {
            placeholder: { type: [String, Number], default: null },
            rows: { type: Number, default: 2 },
            maxLength: { type: Number, default: null },
            hasBottomBorder: { type: Boolean, default: true },
        },

        computed: {
            textFieldProps() {
                return {
                    ...this.fieldProps,
                    isLabelFixed: false,
                    isErrorLower: false,
                }
            },

            textareaInputProps() {
                return {
                    ...this.inputProps,
                    placeholder: this.placeholder,
                    rows: this.rows,
                    maxLength: this.maxLength,
                    hasBottomBorder: this.hasBottomBorder,
                }
            },
        },
    }
</script>
