<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="content" class="autop" v-html="formattedContent" />
    <div v-else class="autop"><slot /></div>
</template>

<script>
    import { sanitizeAndFormatText } from '@/helpers'

    export default {
        props: {
            content: { type: String, default: null },
        },

        computed: {
            formattedContent() {
                return sanitizeAndFormatText(this.content)
            },
        },
    }
</script>
