<template>
    <article class="space-y-30">
        <header class="flex">
            <BaseHeading :heading="experience.name" :level="4" />

            <BaseLink :route="editRoute" class="flex space-x-10 block ml-auto">
                <IconModify class="h-20 w-20" />
                <span>{{ $t('common.modify') }}</span>
            </BaseLink>
        </header>

        <ProProfileExperiencesExperiencesDetails :experience="experience" />
    </article>
</template>

<script>
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseLink from '@/components/base/BaseLink'
    import ProProfileExperiencesExperiencesDetails from '@/components/pro/profile/experiences/experiences/ProProfileExperiencesExperiencesDetails'
    import IconModify from '@/assets/vectors/icon-modify.svg'

    export default {
        components: {
            BaseHeading,
            BaseLink,
            ProProfileExperiencesExperiencesDetails,
            IconModify,
        },

        props: {
            experience: { type: Object, required: true },
        },

        computed: {
            editRoute() {
                return `/pro/profile/experiences/${this.experience.id}/edit`
            },
        },
    }
</script>
