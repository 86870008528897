<template>
    <label :class="checkboxClass">
        <input
            :checked="value"
            :data-checked="value"
            :required="isRequired"
            :disabled="isDisabled"
            type="checkbox"
            tabindex="-1"
            class="absolute opacity-0 pointer-events-none"
            @change="change(!value)"
        />

        <BaseSwitch :is-selected="value" class="pointer-events-none" />

        <span v-if="label" class="font-medium ml-15">
            {{ label }}
        </span>
    </label>
</template>

<script>
    import InputCheckbox from '@/components/form-inputs/InputCheckbox'
    import BaseSwitch from '@/components/base/BaseSwitch'

    export default {
        components: {
            BaseSwitch,
        },

        extends: InputCheckbox,
    }
</script>
