import Leaflet from 'leaflet'
import ApiGoogle from '@/apis/ApiGoogle'

export default {
    data() {
        return {
            coordinates: null,
        }
    },

    computed: {
        areaZipCode() {
            return this.formData.areaZipCode
        },

        areaRadius() {
            return this.formData.areaRadius
        },

        isAreaSelected() {
            return !!this.formData.areaZipCode
        },
    },

    watch: {
        areaZipCode() {
            this.areaZipCode && this.calculateCoordinatesAndFitBounds()
        },

        areaRadius() {
            this.areaZipCode && this.coordinates && this.fitBounds()
        },
    },

    mounted() {
        this.areaZipCode && this.calculateCoordinatesAndFitBounds()
    },

    methods: {
        calculateCoordinates() {
            return ApiGoogle.getZipCodeDetails(this.areaZipCode).then((details) => {
                this.coordinates = [
                    details.geometry.location.lat(),
                    details.geometry.location.lng(),
                ]
            })
        },

        fitBounds() {
            setTimeout(() => {
                const radiusInMiles = this.areaRadius * 1.60934 * 1000
                const map = this.$refs.map.leaflet
                const circle = Leaflet.circle(this.coordinates, radiusInMiles).addTo(map)
                map.fitBounds(circle.getBounds())
                map.removeLayer(circle)
            })
        },

        calculateCoordinatesAndFitBounds() {
            this.calculateCoordinates().then(this.fitBounds)
        },
    },
}
