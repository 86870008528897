<template>
    <BaseHeaderSection
        :heading="$t('guest.appointments.when.heading')"
        :subheading="$t('guest.appointments.when.subheading')"
        class="mb-40"
    />

    <ProSlots :slots="slots" :price="formData.appointment.price" @click-slot="selectSlot" />
</template>

<script>
    import ApiProAvailabilities from '@/apis/ApiProAvailabilities'
    import MixinBookStep from '@/mixins/MixinBookStep'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import ProSlots from '@/components/pro/ProSlots'

    export default {
        components: {
            BaseHeaderSection,
            ProSlots,
        },

        mixins: [MixinBookStep],

        data() {
            return {
                slots: [],
            }
        },

        mounted() {
            this.loadAvailabilitySlots()
        },

        methods: {
            changeMonth({ year, month }) {
                this.change({ path: 'search.year', value: year })
                this.change({ path: 'search.month', value: month })
            },

            selectSlot({ date, slot: { startTime, endTime } }) {
                this.change({ path: 'appointment.date', value: date })
                this.change({ path: 'appointment.startTime', value: startTime })
                this.change({ path: 'appointment.endTime', value: endTime })
                this.goToNextStep()
            },

            async loadAvailabilitySlots() {
                this.slots = await ApiProAvailabilities.getSlots(this.formData.appointment.pro.id, {
                    startDate: this.formData.search.date,
                    endDate: this.formData.search.date,
                    experienceIds: this.appointment.experiences.map(({ id }) => id),
                    addOnIds: this.appointment.addOns.map(({ id }) => id),
                    address: this.appointment.address,
                    workPlace: this.appointment.workPlace,
                })
            },
        },
    }
</script>
