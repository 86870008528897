<template>
    <GuestBookStep
        :heading="$t('guest.book.what.heading')"
        :subheading="subheading"
        :has-progress="hasProgress"
        @submit="saveAndGoToNextStep"
    >
        <template #default>
            <GuestCategoryPills
                v-if="isCategoryView"
                :category="category"
                :form-data="formData"
                value-path="search.subCategoryIds"
                @change="change"
            />

            <FormFields v-else>
                <FieldSuggest
                    :label="searchPlaceholder"
                    :has-autofocus="true"
                    :suggestions-call="getSubCategoryAndProSuggestions"
                    :form-data="formData"
                    value-path="subCategory"
                    @select="selectSubCategoryOrPro"
                >
                    <template #option="{ option, isHighlighted }">
                        <GuestBookStep1Suggestion
                            :option="option"
                            :is-highlighted="isHighlighted"
                        />
                    </template>

                    <template #suffix>
                        <IconSearch class="w-25 h-25" />
                    </template>
                </FieldSuggest>

                <FieldPills
                    :options="selectedSubCategoryIds"
                    :form-data="formData"
                    value-path="search.subCategoryIds"
                    @change="change"
                />
            </FormFields>
        </template>

        <template v-if="isSubmittable" #bottom>
            <BaseButton :label="$t('guest.book.what.search')" />
        </template>
    </GuestBookStep>
</template>

<script>
    import ApiPros from '@/apis/ApiPros'
    import MixinBookStep from '@/mixins/MixinBookStep'
    import BaseButton from '@/components/base/BaseButton'
    import FormFields from '@/components/form-elements/FormFields'
    import FieldSuggest from '@/components/form-fields/FieldSuggest'
    import FieldPills from '@/components/form-fields/FieldPills'
    import GuestCategoryPills from '@/components/guest/GuestCategoryPills'
    import GuestBookStep1Suggestion from '@/components/guest/book/step-1/GuestBookStep1Suggestion'
    import IconSearch from '@/assets/vectors/icon-search.svg'

    export default {
        components: {
            BaseButton,
            FormFields,
            FieldSuggest,
            FieldPills,
            GuestCategoryPills,
            GuestBookStep1Suggestion,
            IconSearch,
        },

        mixins: [MixinBookStep],

        props: {
            category: { type: Object, default: null },
        },

        computed: {
            availableSubCategories() {
                return this.$store.getters['dictionaries/getCategories']
                    .reduce(
                        (subCategories, category) => subCategories.concat(category.subCategories),
                        [],
                    )
                    .map(({ id: value, name: label }) => ({ label, value }))
            },

            selectedSubCategoryIds() {
                return (this.formData.search?.subCategoryIds || []).map((id) =>
                    this.availableSubCategories.find(({ value }) => value === id),
                )
            },

            isSubmittable() {
                return this.formData.search?.subCategoryIds?.length > 0
            },

            isCategoryView() {
                return !!this.category
            },

            subheading() {
                return this.isCategoryView
                    ? this.$t('guest.book.what.subheading.choose')
                    : this.$t('guest.book.what.subheading.find')
            },

            searchPlaceholder() {
                return this.formData.search?.subCategoryIds?.length > 0
                    ? this.$t('guest.book.what.add-experience')
                    : this.$t('guest.book.what.search-experience')
            },
        },

        methods: {
            selectSubCategoryOrPro({ pro, value: subCategoryId }) {
                if (pro) {
                    return this.$router.push(`/p/${pro.id}`)
                }

                this.change({
                    path: 'search.subCategoryIds',
                    value: [...(this.formData?.search?.subCategoryIds || []), subCategoryId],
                })
                this.focusAutofocusInput()
            },

            async getSubCategoryAndProSuggestions(query) {
                const pros = await ApiPros.searchByName(query)

                const subCategories = this.availableSubCategories.filter(
                    ({ label, value }) =>
                        // Show options that contain searched phrase…
                        label.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
                        // … and are not already selected.
                        !this.formData?.search?.subCategoryIds.includes(value),
                )

                return Promise.resolve([...subCategories, ...pros.map((pro) => ({ pro }))])
            },
        },
    }
</script>
