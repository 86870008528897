<template>
    <LayoutOverlay :back-button="backButton" @back="goToPreviousStep">
        <component
            :is="currentStep.component"
            :step-data="formData"
            @change="changeData"
            @go-to-start="goToStart"
            @go-to="goToStep"
            @go-to-next="goToNextStep"
        />
    </LayoutOverlay>
</template>

<script>
    import LayoutOverlay from '@/components/layouts/LayoutOverlay'
    import MixinFormSteppable from '@/mixins/MixinFormSteppable'
    import MixinProProfilePlaces from '@/mixins/MixinProProfilePlaces'
    import ProProfileExperiencesCategoriesStep1 from '@/components/pro/profile/experiences/categories/ProProfileExperiencesCategoriesStep1'
    import ProProfileExperiencesCategoriesStep2 from '@/components/pro/profile/experiences/categories/ProProfileExperiencesCategoriesStep2'
    import ProProfileExperiencesCategoriesStep3 from '@/components/pro/profile/experiences/categories/ProProfileExperiencesCategoriesStep3'

    export default {
        components: {
            LayoutOverlay,
        },

        mixins: [MixinFormSteppable, MixinProProfilePlaces],

        computed: {
            steps() {
                return [
                    { component: ProProfileExperiencesCategoriesStep1 },
                    { component: ProProfileExperiencesCategoriesStep2 },
                    { component: ProProfileExperiencesCategoriesStep3 },
                ]
            },

            backButton() {
                return this.isFirstStep || this.isLastStep ? '/pro/profile/experiences' : true
            },
        },
    }
</script>
