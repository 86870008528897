import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs
    .extend(weekday)
    .extend(weekOfYear)
    .extend(isBetween)
    .extend(isSameOrBefore)
    .extend(utc)
    .extend(timezone)
    .extend(advancedFormat)
