<template>
    <BaseDrawer :is-open="isOpen" slide-from="bottom" @close="$emit('close')">
        <div class="p-20 space-y-30 text-center">
            <BaseHeaderSection
                :heading="$t('guest.book.who.addons.heading')"
                :subheading="$t('guest.book.who.addons.subheading')"
            />

            <ul class="space-y-20">
                <button
                    v-for="(addOn, index) in addOnsForSelectedExperiencesAndWorkPlace"
                    :key="index"
                    :class="addOnClass(addOn)"
                    type="button"
                    @click="toggleAddOn(addOn)"
                >
                    <p class="font-bold">{{ addOn.name }}</p>
                    <p class=" ml-auto text-24">$ {{ addOnPrice(addOn) }}</p>
                </button>
            </ul>

            <BaseButton :label="confirmLabel" @click="$emit('confirm', selectedAddOnIds)" />
        </div>
    </BaseDrawer>
</template>

<script>
    import intersection from 'lodash/intersection'
    import BaseDrawer from '@/components/base/BaseDrawer'
    import BaseHeaderSection from '@/components/base/BaseHeaderSection'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        components: {
            BaseDrawer,
            BaseHeaderSection,
            BaseButton,
        },

        props: {
            appointment: { type: Object, required: true },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['confirm', 'close'],

        data() {
            return {
                selectedAddOnIds: [],
            }
        },

        computed: {
            addOnsForSelectedExperiencesAndWorkPlace() {
                const selectedExperienceIds = this.appointment.experiences.map(({ id }) => id)

                return (this.appointment.pro.addOns || [])
                    .filter(({ workPlace }) =>
                        (workPlace || []).includes(this.appointment.workPlace),
                    )
                    .filter(({ experiences }) =>
                        intersection(
                            selectedExperienceIds,
                            experiences.map(({ id }) => id),
                        ),
                    )
            },

            areAnyAddOnsSelected() {
                return this.selectedAddOnIds.length > 0
            },

            confirmLabel() {
                return this.$t(
                    this.areAnyAddOnsSelected
                        ? 'guest.book.who.addons.confirm'
                        : 'guest.book.who.addons.thanks',
                )
            },
        },

        methods: {
            isAddOnSelected({ id }) {
                return this.selectedAddOnIds.includes(id)
            },

            toggleAddOn(addOn) {
                this.selectedAddOnIds = this.isAddOnSelected(addOn)
                    ? this.selectedAddOnIds.filter((id) => id !== addOn.id)
                    : [...this.selectedAddOnIds, addOn.id]
            },

            addOnPrice(addOn) {
                if (this.appointment.workPlace === 'pro') {
                    return addOn.prosPlacePrice
                }

                if (this.appointment.workPlace === 'guest') {
                    return addOn.guestsPlacePrice
                }

                return 0
            },

            addOnClass(addOn) {
                const isSelected = this.isAddOnSelected(addOn)

                return {
                    'transition-none': true,
                    'border-box': true,
                    'w-full': true,
                    'flex': true,
                    'items-center': true,
                    'px-10': true,
                    'h-50': true,
                    'pl-13': !isSelected,
                    'border-b-1': !isSelected,
                    'border-gray-5': !isSelected,
                    'pb-1': isSelected,
                    'mb-1': isSelected,
                    'bg-sand-light': isSelected,
                    'border-l-3': isSelected,
                    'border-gold': isSelected,
                }
            },
        },
    }
</script>
