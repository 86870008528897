<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="content" :class="textClass" v-html="formattedContent" />
    <div v-else :class="textClass"><slot /></div>
</template>

<script>
    import { sanitizeAndFormatText } from '@/helpers'

    export default {
        props: {
            content: { type: String, default: '' },
            level: { type: Number, default: 1 },
            isLight: { type: Boolean, default: false },
        },

        computed: {
            textClass() {
                return {
                    'font-medium': true,
                    'leading-copy': true,
                    'text-gray-3': this.isLight,
                    'space-y-10': this.level === 1,
                    'space-y-5': this.level === 2,
                    'text-14': this.level === 2,
                }
            },

            formattedContent() {
                return sanitizeAndFormatText(this.content)
            },
        },
    }
</script>
