import { transformPro } from '@/apis/transforms/transformProFromApi'
import { transformGuest } from '@/apis/transforms/transformGuestFromApi'
import { transformWorkPlace } from '@/apis/transforms/transformAppointmentFromApi'

export const transformAdmin = (data) => {
    return {
        ...data,
        idPicture: data.idPicture?.pictureLocation,
        profilePicture: data.profilePicture?.pictureLocation,
        portraitPicture: data.portraitPicture?.pictureLocation,
        stylist: undefined,
        pro: data.stylist && transformPro(data.stylist),
        guest: data.guest && transformGuest(data.guest),
        experiences: data.experiences || [],
        addOns: data.addOns || [],
        workPlace: data.workPlace && transformWorkPlace(data.workPlace),
        // Any additional changes here.
    }
}

export default (data) => {
    return Array.isArray(data) ? data.map(transformAdmin) : transformAdmin(data)
}
