<script>
    import ProProfilePlacesProQuestions from '@/components/pro/profile/places/pro/ProProfilePlacesProQuestions'

    export default {
        extends: ProProfilePlacesProQuestions,

        computed: {
            heading() {
                return this.$t('pro.profile.places.guest.questions.heading')
            },

            placeholder() {
                return this.$t('pro.profile.places.guest.questions.placeholder')
            },

            valuePath() {
                return 'guestsPlaceLocationQuestion'
            },

            apiPath() {
                return 'guestsPlaceData'
            },
        },
    }
</script>
