export default {
    namespaced: true,

    state: {
        categories: null,
        states: null,
        serviceFee: null,
    },

    getters: {
        getCategories(state) {
            return state.categories
        },

        getStates(state) {
            return state.states
        },

        getServiceFee(state) {
            return state.serviceFee
        },
    },

    mutations: {
        setCategories(state, categories) {
            state.categories = categories
        },

        setStates(state, states) {
            state.states = states
        },

        setServiceFee(state, serviceFee) {
            state.serviceFee = serviceFee
        },
    },
}
