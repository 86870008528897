<template>
    <BaseOverlay :is-open="isOpen" class="flex items-center justify-center" @close="close">
        <TransitionScale>
            <div v-if="isOpen" class="relative p-50 bg-white shadow-md flex flex-col">
                <button class="ml-auto -mt-30 -mr-30" @click="close">
                    <IconClose class="w-25 h-25" />
                </button>

                <BaseHeading class="text-center text-black mt-20 max-w-300">
                    <span v-if="pro.firstName || pro.lastName">
                        {{ pro.firstName }} {{ pro.lastName }}
                    </span>
                    <span v-else>{{ $t('admin.dashboard.newPros.invitePro.user') }}</span>
                    {{ $t('admin.dashboard.newPros.proInvitationFailed.text') }}
                </BaseHeading>

                <BaseButton
                    class="w-300 mt-20"
                    :label="$t('common.close')"
                    :level="1"
                    @click="close"
                />
            </div>
        </TransitionScale>
    </BaseOverlay>
</template>

<script>
    import BaseButton from '@/components/base/BaseButton'
    import BaseHeading from '@/components/base/BaseHeading'
    import BaseOverlay from '@/components/base/BaseOverlay'
    import IconClose from '@/assets/vectors/icon-close.svg'
    import TransitionScale from '@/components/transitions/TransitionScale'

    export default {
        components: {
            IconClose,
            BaseButton,
            BaseOverlay,
            BaseHeading,
            TransitionScale,
        },

        props: {
            pro: { type: Object, default: null },
            isOpen: { type: Boolean, default: false },
        },

        emits: ['close'],

        methods: {
            close() {
                this.$emit('close')
            },
        },
    }
</script>
