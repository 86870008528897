<template>
    <div :class="inputClass">
        <input ref="input" type="range" @focus="focus" @blur="blur" @change="changeRange" />

        <div
            v-if="labelFrom || labelTo"
            class="flex justify-between text-gray-3 font-medium text-14 mt-10"
        >
            <p v-if="labelFrom">{{ labelFrom }}</p>
            <p v-if="labelTo">{{ labelTo }}</p>
        </div>

        <div v-if="hasTooltip" :class="tooltipClass" :style="tooltipStyle">
            <span
                class="absolute left-6/12 transform -translate-x-6/12 bottom-8 px-10 py-4 rounded-sm bg-purple text-20 leading-none font-bold text-white"
            >
                {{ tooltipValue }}
            </span>
            <IconArrowDown
                class="absolute left-6/12 transform -translate-x-6/12 bottom-2 h-8 text-purple"
            />
        </div>
    </div>
</template>

<script>
    import rangesliderJs from 'rangeslider-js'
    import MixinFormInput from '@/mixins/MixinFormInput'
    import IconArrowDown from '@/assets/vectors/icon-arrow-down.svg'

    export default {
        components: {
            IconArrowDown,
        },

        mixins: [MixinFormInput],

        props: {
            step: { type: Number, default: 1 },
            rangeFrom: { type: Number, required: true },
            rangeTo: { type: Number, required: true },
            labelFrom: { type: String, default: null },
            labelTo: { type: String, default: null },
            hasTooltip: { type: Boolean, default: true },
        },

        data() {
            return {
                tooltipOffset: null,
                tooltipValue: 0,
            }
        },

        computed: {
            inputClass() {
                return {
                    'relative': true,
                    'pt-50': this.hasTooltip,
                }
            },

            tooltipClass() {
                return {
                    'absolute': true,
                    'top-35': true,
                    'h-10': true,
                    'w-25': true,
                }
            },

            tooltipStyle() {
                return {
                    left: `${this.tooltipOffset}px`,
                }
            },
        },

        watch: {
            value() {
                // TODO: Update rangeslider to display 0 when value is set to null.
            },
        },

        mounted() {
            rangesliderJs.create(this.$refs.input, {
                min: this.rangeFrom,
                max: this.rangeTo,
                value: this.value,
                step: this.step,
                onInit: this.calculateLabelPosition,
                onSlide: this.calculateLabelPosition,
            })
        },

        methods: {
            changeRange(event) {
                this.change(parseInt(event.target.value))
            },

            calculateLabelPosition(value, percent, position) {
                this.tooltipOffset = position
                this.tooltipValue = value
            },
        },
    }
</script>
