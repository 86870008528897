<template>
    <GuestAppointmentsRateThanks :appointment="appointment" />
</template>

<script>
    import ApiGuestAppointments from '@/apis/ApiGuestAppointments'
    import MixinFormStep from '@/mixins/MixinFormStep'
    import GuestAppointmentsRateThanks from '@/components/guest/appointments/rate/GuestAppointmentsRateThanks'

    export default {
        components: {
            GuestAppointmentsRateThanks,
        },

        mixins: [MixinFormStep],

        props: {
            appointment: { type: Object, required: true },
        },

        mounted() {
            ApiGuestAppointments.rate(this.$auth.getUser(), this.appointment.id, this.formData)
        },
    }
</script>
