import * as HelperAppointment from '@/helpers/appointment'
import MixinFormStep from '@/mixins/MixinFormStep'
import GuestBookStep from '@/components/guest/book/GuestBookStep'

export default {
    components: {
        GuestBookStep,
    },

    mixins: [MixinFormStep],

    props: {
        hasProgress: { type: Boolean, default: false },
    },

    computed: {
        appointment() {
            const pro = this.formData.appointment.pro
            const appointment = {
                id: this.formData.appointment.id,
                date: this.formData.appointment.date,
                startTime: this.formData.appointment.startTime,
                endTime: this.formData.appointment.endTime,
                message: this.formData.appointment.message,
                locationQuestionAnswer: this.formData.appointment.locationQuestionAnswer,
                workPlace: this.formData.appointment.workPlace,
                experiences:
                    this.formData.appointment.experiences ||
                    (pro.experiences
                        ? pro.experiences.reduce((experiences, experience) => {
                              return this.formData.appointment.experienceIds?.includes(
                                  experience.id,
                              )
                                  ? experiences.concat([experience])
                                  : experiences
                          }, [])
                        : []),
                addOns:
                    this.formData.appointment.addOns ||
                    (pro.addOns
                        ? pro.addOns.reduce((addOns, addOn) => {
                              return this.formData.appointment.addOnIds?.includes(addOn.id)
                                  ? addOns.concat([addOn])
                                  : addOns
                          }, [])
                        : []),
                address:
                    this.formData.appointment.workPlace === 'pro'
                        ? pro.address
                        : this.formData.appointment.address,
                pro,
            }

            return { ...appointment, price: HelperAppointment.appointmentPrice(appointment) }
        },
    },

    methods: {
        saveAndGoToNextStep() {
            this.goToNextStep()
        },
    },
}
