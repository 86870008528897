<template>
    <div v-click-outside="close" :class="selectClass">
        <select
            ref="input"
            class="absolute pointer-events-none top-0 opacity-0"
            :value="value"
            :autofocus="hasAutofocus"
            :required="isRequired"
            :disabled="isDisabled"
            @keydown.up.prevent="handleUpArrow"
            @keydown.down.prevent="handleDownArrow"
            @keydown.enter.prevent="handleEnter"
            @keydown.esc.prevent="close"
            @keydown.space.prevent="toggle"
            @click.prevent="toggle"
            @blur="blur"
            @focus="focus"
            @change="select"
        >
            <option v-if="isNullable"></option>

            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.label }}
            </option>
        </select>

        <div :class="valueClass" @click="click">
            <span class="inline-block whitespace-nowrap overflow-hidden overflow-ellipsis">
                {{ inputLabel }}
            </span>
            <IconArrows class="w-19 pl-10 ml-auto" />
        </div>

        <div ref="scrollable" :class="optionsClass" @mouseleave="hoverOption(null)">
            <button
                v-for="(option, index) in options"
                :key="option.value"
                :ref="(el) => el && (optionRefs[index] = el)"
                :class="optionClass(index)"
                type="button"
                tabindex="-1"
                class="flex flex-row items-center space-x-10"
                @mouseover="hoverOption(index)"
                @click="select(option)"
            >
                <BaseCheck :is-selected="isSelected(option)" class="pointer-events-none">
                    <IconCheck class="w-17" />
                </BaseCheck>
                <span>{{ option.label }}</span>
            </button>
        </div>
    </div>
</template>

<script>
    import BaseCheck from '@/components/base/BaseCheck'
    import MixinFormInput from '@/mixins/MixinFormInput'
    import IconCheck from '@/assets/vectors/icon-check.svg'
    import IconArrows from '@/assets/vectors/icon-arrows.svg'
    import MixinFormOptionable from '@/mixins/MixinFormOptionable'
    import MixinIndicateScroll from '@/mixins/MixinIndicateScroll'

    const isDescendant = (child, parent) => {
        let node = child.parentNode

        while (node !== null) {
            if (node === parent) {
                return true
            }

            node = node.parentNode
        }

        return false
    }

    export default {
        components: {
            IconCheck,
            BaseCheck,
            IconArrows,
        },

        mixins: [MixinFormInput, MixinFormOptionable, MixinIndicateScroll],

        props: {
            placeholder: { type: String, default: null },
            value: { type: Array, default: null },
            options: { type: Array, required: true },
            isNullable: { type: Boolean, default: false },
            isInverted: { type: Boolean, default: false },
            selectAllOption: { type: [Number, String, Object], required: true },
        },

        emits: ['blur', 'focus'],

        data() {
            return {
                isOpen: false,
                isFocused: false,
                highlightedOption: null,
                optionRefs: [],
                selectedIndexes: [],
            }
        },

        computed: {
            selectClass() {
                return {
                    'relative': true,
                    'cursor-pointer': true,
                    'pointer-events-none': this.isDisabled,
                    'opacity-30': this.isDisabled,
                    'cursor-not-allowed': this.isDisabled,
                }
            },

            optionsClass() {
                const hasOptions = !!this.options.length

                return {
                    'absolute': true,
                    'z-floating': true,
                    'top-full': true,
                    'left-0': true,
                    'w-full': true,
                    'border-1': true,
                    'border-t-0': true,
                    'border-gray-4': true,
                    'bg-white': true,
                    'max-h-225': true,
                    'overflow-y-auto': true,
                    'transition': true,
                    'shadow-lg': true,
                    'pointer-events-none': !this.isOpen || !hasOptions,
                    'opacity-0': !this.isOpen || !hasOptions,
                    'opacity-100': this.isOpen && hasOptions,
                }
            },

            valueClass() {
                return {
                    'px-10': true,
                    'flex': true,
                    'items-center': true,
                    'transition': true,
                    'bg-gray-6': !this.isFocused && !this.isOpen,
                    'bg-white': this.isFocused || this.isOpen,
                    'border-1': true,
                    'border-gray-4': true,
                    'h-50': true,
                    'hoverable:hover:shadow-lg': !this.isOpen,
                }
            },

            inputLabel() {
                if (this.isSelected(this.selectAllOption)) {
                    return this.selectAllOption.label
                }

                return this.value?.length > 0
                    ? this.value.map((item) => item.label).join(', ')
                    : this.placeholder
            },

            hasOptions() {
                return this.optionRefs?.length
            },
        },

        methods: {
            optionClass(index) {
                return {
                    'block': true,
                    'min-h-45': true,
                    'w-full': true,
                    'text-left': true,
                    'p-10': true,
                    'border-t-1': true,
                    'first:border-t-0': true,
                    'border-gray-4': true,
                    'transition-none': true,
                    'bg-purple': index === this.highlightedOption,
                    'text-white': index === this.highlightedOption,
                    'text-black': index !== this.highlightedOption,
                }
            },

            isSelected(option) {
                return this.value.filter((item) => item.value == option.value).length > 0
            },

            hoverOption(index) {
                this.highlightedOption = index
            },

            scrollToHighlightedOption() {
                if (!this.hasOptions) {
                    return
                }

                this.optionRefs[Math.max(this.highlightedOption, 0)].scrollIntoView({
                    block: 'nearest',
                })
            },

            switchHighlightedOption(direction = 1) {
                if (this.highlightedOption === null) {
                    this.highlightedOption = direction === 1 ? 0 : this.options.length - 1
                } else {
                    const position = this.highlightedOption + direction

                    if (position < 0) {
                        this.highlightedOption = this.options.length - 1
                    } else {
                        this.highlightedOption = position % this.options.length
                    }
                }

                this.scrollToHighlightedOption()
            },

            blur(event) {
                if (event.relatedTarget && !isDescendant(event.relatedTarget, this.$el)) {
                    this.close()
                }

                this.$emit('blur', event)
            },

            focus() {
                this.isFocused = true
                this.scrollToHighlightedOption()

                this.$refs.input.focus()
                this.$emit('focus')
            },

            click() {
                this.$refs.input.focus()
                this.$refs.input.click()
            },

            close() {
                this.isOpen = false
                this.isFocused = false
            },

            toggle() {
                this.isOpen = !this.isOpen
            },

            select(option) {
                let selectedOptions
                if (option.value == this.selectAllOption.value) {
                    selectedOptions = this.isSelected(option) ? [] : this.options
                } else if (this.isSelected(option)) {
                    selectedOptions = this.value.filter(
                        (item) =>
                            item.value !== option.value &&
                            item.value !== this.selectAllOption.value,
                    )
                } else {
                    selectedOptions = this.value.concat([option])
                }

                this.change(selectedOptions)
                this.close()

                this.$refs.input.focus()
            },

            handleUpArrow() {
                if (this.isOpen) {
                    this.switchHighlightedOption(-1)
                } else {
                    this.toggle()
                }
            },

            handleDownArrow() {
                if (this.isOpen) {
                    this.switchHighlightedOption(1)
                } else {
                    this.toggle()
                }
            },

            handleEnter() {
                if (this.isOpen && this.hasOptions) {
                    this.select(this.options[this.highlightedOption])
                } else {
                    this.toggle()
                }
            },
        },
    }
</script>
