import axios from 'axios'
import transformAppointmentToApi, {
    transformParams,
} from '@/apis/transforms/transformAppointmentToApi'
import transformAppointmentFromApi from '@/apis/transforms/transformAppointmentFromApi'
import transformAppointmentChargeToApi from '@/apis/transforms/transformAppointmentChargeToApi'

const transforms = (data) => ({
    transformRequest: [transformAppointmentToApi, ...axios.defaults.transformRequest],
    transformResponse: [...axios.defaults.transformResponse, transformAppointmentFromApi(data)],
})

export default {
    get(pro, params) {
        return axios.get(`/stylists/${pro.id}/appointments`, {
            params: transformParams(params),
            ...transforms({ pro }),
        })
    },

    getOne(pro, appointmentId) {
        return axios.get(`/stylists/${pro.id}/appointments/${appointmentId}`, transforms({ pro }))
    },

    markAsRead(pro, appointmentIds) {
        return axios.post(
            `/stylists/${pro.id}/appointments/markAsRead`,
            { appointmentIds },
            transforms({ pro }),
        )
    },

    markAsNoShow(pro, appointmentId, who) {
        const endpoint = {
            guest: `/stylists/${pro.id}/appointments/${appointmentId}/guestNotShowedUp`,
            pro: `/stylists/${pro.id}/appointments/${appointmentId}/stylistNotShowedUp`,
        }[who]

        return axios.post(endpoint, null, transforms({ pro }))
    },

    charge(pro, appointmentId, data) {
        return axios.post(`/stylists/${pro.id}/appointments/${appointmentId}/finish`, data, {
            transformRequest: [transformAppointmentChargeToApi, ...axios.defaults.transformRequest],
            transformResponse: transforms({ pro }).transformResponse,
        })
    },
}
