<template>
    <label :class="checkboxClass">
        <input
            :checked="value"
            :data-checked="value"
            :required="isRequired"
            :disabled="isDisabled"
            type="checkbox"
            class="absolute opacity-0 pointer-events-none"
            @focus="focus"
            @blur="blur"
            @change="change(!value)"
        />

        <BaseCheck :is-selected="value" class="pointer-events-none">
            <IconCheck class="w-17" />
        </BaseCheck>

        <div v-if="label || $slots.label" class="ml-10">
            <slot name="label">
                <span class="font-bold">{{ label }}</span>
            </slot>
        </div>

        <div v-if="hasBigTapArea" class="absolute inset-0" />
    </label>
</template>

<script>
    import MixinFormInput from '@/mixins/MixinFormInput'
    import IconCheck from '@/assets/vectors/icon-check.svg'
    import BaseCheck from '@/components/base/BaseCheck'

    export default {
        components: {
            IconCheck,
            BaseCheck,
        },

        mixins: [MixinFormInput],

        props: {
            label: { type: String, default: null },
            value: { type: Boolean, default: false },
            hasBigTapArea: { type: Boolean, default: false },
        },

        computed: {
            checkboxClass() {
                return {
                    'flex': true,
                    'items-center': true,
                    'transition-opacity': true,
                    'group': !this.isDisabled,
                    'cursor-pointer': !this.isDisabled,
                    'cursor-not-allowed': this.isDisabled,
                    'opacity-30': this.isDisabled,
                }
            },
        },
    }
</script>
