export default {
    'login': {
        'heading': 'Sign in',
        'subheading': 'Please sign in to FreeByrdz',
        'new': {
            text: 'New to FreeByrdz? {link}',
            link: 'Register',
        },
        'submit': 'Sign in',
        'forgot-password': 'Forgot password?',
    },
    'forgot-password': {
        index: {
            heading: 'Reset password',
            subheading: 'We’ll email you instructions to reset your password.',
            submit: 'Send instructions',
            done: 'Done!',
            sent: 'We’ve sent an email to {email} with password reset instructions.',
            spam: 'If the email doesn’t show up soon, check your spam folder.',
        },
        reset: {
            heading: 'Reset password',
            subheading: 'Please enter your new password',
            new: 'New password',
            submit: 'Reset password',
            changed: 'New password confirmed!',
        },
        invalid: {
            heading: 'Something went wrong…',
            info: 'The reset password link doesn’t seem to work anymore.',
            resend: {
                text: 'Try to {resend} a new one.',
                link: 'resend',
            },
        },
        back: 'Back to login',
    },
    'register': {
        email: {
            success: 'Email confirmed!',
            error: 'Ooops! We could not confirm your email.',
        },
        acknowledgements: {
            text: 'By creating an account, you agree to FreeByrdz’s {privacy} and {terms}',
            privacy: 'Privacy Policy',
            terms: 'Terms of Use',
        },
        choose: {
            guest: {
                heading: 'Sign Up for Clients',
                copy: 'Register now to book with the best Pros in your city',
                cta: 'Sign Up',
            },
            pro: {
                heading: 'Are you a Pro?',
                copy: 'Join the FreeByrdz marketplace and start receiving bookings',
                cta: 'Sign up for pros',
            },
        },
        guest: {
            heading: 'Register',
            signup: {
                subheading: 'To book, you’ll need to register. ',
                member: {
                    text: 'Have an account already? {link}',
                    link: 'Sign in',
                },
            },
            invitation: {
                subheading: `
                    Please complete your
                    FreeByrdz profile
                `,
                error: 'Invitation link has expired or is invalid.',
                welcome: {
                    heading: 'Welcome to FreeByrds!',
                    thanks: `
                        Thank you for accepting {name} invitation.

                        You can now book Beauty & Wellness experiences with the Top Pros in your city.
                    `,
                    discover: 'Discover experiences',
                },
            },
        },
        pro: {
            signup: {
                heading: {
                    text: 'Start now-it’s {free}',
                    free: 'Free',
                },
                subheading: 'Set up your profile to start recieving books',
            },
            invitation: {
                subheading: `
                    Please complete your
                    FreeByrdz profile
                `,
                error: 'Invitation link has expired or is invalid.',
            },
            member: {
                text: 'Already a member? {link}',
                link: 'Sign in',
            },
        },
    },
}
