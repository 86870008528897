<template>
    <ul class="flex flex-wrap -mx-5">
        <li v-for="option in options" :key="option.value" class="m-5">
            <button
                type="button"
                :class="optionClass(option)"
                @click="$emit('select', option.value)"
            >
                <span>{{ option.label }}</span>
            </button>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            options: { type: Array, required: true },
            current: { type: String, default: null },
        },

        emits: ['select'],

        methods: {
            optionClass({ value }) {
                const isCurrent = this.current === value

                return {
                    'text-12': true,
                    'font-bold': true,
                    'tracking-widest': true,
                    'uppercase': true,
                    'px-10': true,
                    'py-7': true,
                    'flex': true,
                    'items-center': true,
                    'justify-center': true,
                    'border-1': true,
                    'border-current': true,
                    'rounded-sm': true,
                    'text-purple': true,
                    'bg-purple': isCurrent,
                    'text-white': isCurrent,
                }
            },
        },
    }
</script>
