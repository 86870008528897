<template>
    <LandingContainer class="py-30 md:py-50">
        <LandingHeading :level="1" class="mb-20 md:mb-40">
            {{ heading }}
        </LandingHeading>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="autop" v-html="formattedContent" />
    </LandingContainer>
</template>

<script>
    import { autop } from '@wordpress/autop'
    import LandingContainer from '@/components/landing/LandingContainer'
    import LandingHeading from '@/components/landing/LandingHeading'

    export default {
        components: {
            LandingHeading,
            LandingContainer,
        },

        props: {
            heading: { type: String, required: true },
            content: { type: String, required: true },
        },

        computed: {
            formattedContent() {
                return autop(this.content)
            },
        },
    }
</script>
