import router from '@/router'
import store from '@/store'
import ServiceAuth from '@/services/ServiceAuth'
import ServiceUi from '@/services/ServiceUi'

const performLoadData = (route, to) => {
    const page = route?.components?.default
    const methods = page?.methods
    const data = page?.data && page?.data()
    const loadData = page?.loadData || page?.extends?.loadData

    if (!loadData) {
        return Promise.resolve()
    }

    return Promise.resolve(
        loadData({
            ...methods,
            ...data,
            $store: store,
            $router: router,
            $route: to,
            $auth: ServiceAuth,
            $ui: ServiceUi,
        }),
    )
        .then((data) => {
            // TODO: Fix bug with not refreshing data when multiple routes point to the same page.

            // Make sure to only include mixin with data once.
            if (!page._originalMixins) {
                page._originalMixins = page.mixins || []
            }

            page.mixins = [...page._originalMixins, { data: () => data || {} }]
        })
        .catch((error) => store.commit('ui/errors/setGlobalErrorCode', error?.response?._status))
}

export default async function(to, from, next) {
    ServiceAuth.loadUser().then(() => {
        Promise.all(to.matched.map((route) => performLoadData(route, to))).finally(next)
    })
}
