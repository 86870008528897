import { createStore } from 'vuex'

export default createStore({
    modules: require
        .context('@/store/modules/', true, /^\.\/.*\.js$/)
        .keys()
        .reduce((modules, file) => {
            const module = file.replace('./', '').replace('.js', '')
            modules[module] = require(`./modules/${module}`).default
            return modules
        }, {}),
})
