<template>
    <section class="space-y-30 text-center">
        <IconRecurrent class="h-40 mx-auto" />

        <BaseText :content="$t('guest.appointments.rate.make-recurrent')" :is-light="true" />

        <BaseButton
            :label="$t('guest.appointments.rate.start-now')"
            :route="recurrentRoute"
            :level="2"
        />
    </section>
</template>

<script>
    import BaseText from '@/components/base/BaseText'
    import BaseButton from '@/components/base/BaseButton'
    import IconRecurrent from '@/assets/vectors/icon-recurrent.svg'

    export default {
        components: {
            BaseText,
            BaseButton,
            IconRecurrent,
        },

        props: {
            appointment: { type: Object, required: true },
        },

        computed: {
            recurrentRoute() {
                return `/guest/appointments/${this.appointment.id}/make-recurrent`
            },
        },
    }
</script>
