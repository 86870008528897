<template>
    <div class="space-y-10">
        <ProWork
            :picture="work.picture"
            :title="work.title"
            :is-picture-deletable="isExperiencesEditable"
            :is-picture-editable="isPictureEditable"
            :is-title-editable="isTitleEditable"
            @edit-title="$emit('edit-title')"
            @edit-picture="$emit('edit-picture')"
            @delete-picture="$emit('delete-picture')"
        />

        <div v-if="experiences.length" class="flex items-start">
            <BaseText :content="experiences" :is-light="true" />

            <button
                v-if="isExperiencesEditable"
                type="button"
                class="ml-auto"
                @click="$emit('edit-experiences')"
            >
                <IconModify class="mx-10 h-20 w-20" />
            </button>
        </div>
    </div>
</template>

<script>
    import ProWork from '@/components/pro/ProWork'
    import BaseText from '@/components/base/BaseText'
    import IconModify from '@/assets/vectors/icon-modify.svg'

    export default {
        components: {
            ProWork,
            BaseText,
            IconModify,
        },

        props: {
            work: { type: Object, required: true },
            isPictureDeletable: { type: Boolean, default: false },
            isPictureEditable: { type: Boolean, default: false },
            isTitleEditable: { type: Boolean, default: false },
            isExperiencesEditable: { type: Boolean, default: false },
        },

        emits: ['edit-picture', 'edit-title', 'edit-experiences', 'delete-picture'],

        computed: {
            experiences() {
                return (this.work.experiences || []).map(({ name }) => name).join(', ')
            },
        },
    }
</script>
